import { useEffect, useRef, useState } from "react";
import L, { Map as LeafletMap } from "leaflet";
import { FeatureGroup } from "react-leaflet";
import "leaflet-draw";
import { EditControl } from "react-leaflet-draw";
import { MapContainer, Marker, TileLayer, Tooltip, useMapEvents, ZoomControl } from "react-leaflet";
import { MenuItem, Select, Table, TableBody, TableCell, TableHead, TableRow, Grid, Card, Box, Typography, Button, ButtonGroup, Divider, Tooltip as MUIToolTip, IconButton } from "@mui/material";
import "leaflet/dist/leaflet.css";
import _ from "lodash";
import { getDecodeURI, getEncodedURI } from '../../utils/util';
import { useHistory, withRouter } from 'react-router-dom';
import { connect, useDispatch } from "react-redux";
import { getStarlinkMapTelemetry } from "../StarlinkDashboard/slice"
// import LayersIcon from '@mui/icons-material/Troubleshoot';
import PerformanceIcon from "../../asset/image/PerformanceIcon.svg"
import { YYYYMMDD_HHmmss } from "../../utils/constants";
import Control from "react-leaflet-custom-control";
import { VITE_ACCESS_TOKEN, VITE_USERNAME, VITE_STYLE_ID } from "../../config";
import moment from "moment";
import EditActionsPopup from "../StarlinkAdmin/ServiceLines/EditActionsPopup";
import { SERVICE_FUNCTION_TO_FEATURE } from "../../config";
import SettingsIcon from '@mui/icons-material/Settings';
import { bulkUpdateServiceLineOrganization, getServiceLines, subscribeToServiceLines, optInForServiceLine, pauseResumeServiceLine, getSubscriptionForAccount } from "../StarlinkAdmin/slice";
import authReducer from "../../reducers/authReducer";

const StarlinkCards = (props) => {
    const { location, cardContent } = props;
    const history = useHistory();
    const queryParams = new Map<string, string>(Object.entries(getDecodeURI(location.search)));

   
    return (
        <Grid container spacing={2} className="analytics-cards-container">
            {cardContent?.map((card, index) => {
                const { heading, leftValue, rightHeading, rightValue,  leftHeading} = card;

                return (
                    <Grid item md={12 / cardContent?.length} lg={12 / cardContent?.length} key={index}>
                        <Grid item className="card-box-parent">
                            <Card className="card-widget_NewSummary" id="addBoxShadow">
                                <Box className="cardHeader cardHeaderTile_NewSummary">
                                    <Typography className="cardTitle_NewSummary">{heading}</Typography>
                                </Box>
                                <Box className="cardLeftContent_NewSummary analytics-card-contents">
                                    {leftHeading === "" ? 
                                    <Typography className="content_NewSummary">
                                        {leftValue}
                                    </Typography> : 
                                      <Typography className="content_bottom_ns">
                                      <p className="no-padding-margin">{leftHeading}</p>
                                      <p className="no-padding-margin right-content-value">
                                          {leftValue}
                                      </p>
                                  </Typography> }
                                    <Typography className="content_bottom_ns">
                                        <p className="no-padding-margin">{rightHeading}</p>
                                        <p className="no-padding-margin right-content-value">
                                            {rightValue}
                                        </p>
                                    </Typography>
                                </Box>
                            </Card>
                        </Grid>
                    </Grid>
                );
            })}
        </Grid>
    )
}

const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
    errorReducer: state.errorReducer,
    dpIds: state.starlinkCloud.dpIds,
    warnings: state.starlinkDashboard.warnings,



});

export default withRouter(
    connect(mapStateToProps, {
      
    })(StarlinkCards)
);