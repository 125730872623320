import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from "@mui/material";
import { useEffect, useState } from "react";
import { getDecodeURI, redirectToTopolgyTabs, readableBytesAsGB, getDataFromLocalStorageParsed } from "../../../utils/util";
import { getTopologyHubTableData, getHubDashboardClietTableData } from '../../../actions/Users/authenticateHub';
import _ from "lodash";
import CircleIcon from '@mui/icons-material/Circle';
import EdgeLinkIcon from "../../../asset/image/EdgeLinkIcon.svg"
import EdgeLinkIconDisabled from "../../../asset/image/linkDisabled.svg";
import { clearData, getGeneratedLinks } from "../../../components/RemoteConnections/remoteConnectionSlice";
import { displayToastError } from "../../../server/request";
import ClientsData from '.././ClientPopup';
import { INTERVALS } from "../../../constants/Constants";

const TopologyHub = (props) => {

    const { selectedOu, startDate, endDate, getHubVesselListing, hubFilteredVessels, getTopologyHubTableData, getTopologyHubTable, setTopologyHubTableLoading, location, history,
        getHubDashboardClietTableData, getGeneratedLinks, setClientTableLoading, userTimezone, carrierHubDataClientTable } = props;

    let _q = getDecodeURI(location?.search);
    const [tableData, setData] = useState<any>([]);
    const [page, setPage] = useState(_q.hasOwnProperty('pageStart') ? _q.pageStart : 1);
    const [limit, setLimit] = useState(_q.hasOwnProperty('pageLimit') ? _q.pageLimit : 10);
    const [selectedOrg, setSelectedOu] = useState<any>({});
    const [vessels, setVessels] = useState([]);
    const USAGE = 'totalUsage';
    const DESC_ORDER = "desc";
    const [sort, setSort] = useState<any>(_q.hasOwnProperty("sort") ? _q.sort : USAGE);
    const [sortOrder, setSortOrder] = useState<any>(_q.hasOwnProperty("sortOrder") ? _q.sortOrder : DESC_ORDER);
    const [interval, setInterval] = useState(_q.hasOwnProperty('interval') && _q.interval == '15mins' ? '15m' : _q.interval ? _q.interval : '1h');
    const [searchValue, setSearchValue] = useState(_q.hasOwnProperty('search') ? _q.search : '');
    const [clientData, setClientData] = useState([]);
    const [clientDataPopup, setClientDataPopup] = useState(false);
    const [selectedSite, setSelectedSite] = useState({});
    const [_interval, setIntervalValue] = useState(_q.hasOwnProperty('interval') ? {label: _q.interval == '15mins' ? '15m' : _q.interval, value: _q.interval} : INTERVALS[1]);
    const [agentId, setAgentId] = useState(_q.hasOwnProperty('agentId') ? _q.agentId : '')
    const [selectedDevice, setSelectedDevice] = useState(_q.hasOwnProperty('device_id') ? _q.device_id : '');

    useEffect(() => {
        if (!_.isEmpty(selectedOu) && selectedOu?.id) {
            setSelectedOu(selectedOu)
        }
    }, [selectedOu])

    useEffect(() => {
        if (_.isEmpty(getHubVesselListing))
            return;
        let Vessels = [];
        const filteredVessels = hubFilteredVessels;
        if (filteredVessels && filteredVessels.length > 0) {
            Vessels = filteredVessels;
        } else {
            Vessels = getHubVesselListing?.data;
        }
        setVessels(Vessels);
    }, [getHubVesselListing, hubFilteredVessels])

    useEffect(() => {
        const _interval = _q.interval == '15mins' ? '15m' : _q.interval ? _q.interval : '1h';
        const siteId = _q.siteId;
        setInterval(_interval);
        if (selectedOrg?.id && !_.isEmpty(vessels)) {
            getTopologyHubTableData(selectedOrg.id, startDate, endDate, page, limit, _interval, vessels, sort, sortOrder, searchValue, siteId, agentId, selectedDevice);
        }
    }, [vessels, startDate, endDate])

    useEffect(() => {
        if (!_.isEmpty(getTopologyHubTable)) {
            const data = getTopologyHubTable.hasOwnProperty('data') ? getTopologyHubTable.data : {};
            if (!_.isEmpty(data)) {
                setData(data);
            } else {
                setData([]);
            }
        }
    }, [getTopologyHubTable])

    useEffect(() => {
        if(!_.isEmpty(carrierHubDataClientTable)) {
           const data = carrierHubDataClientTable.hasOwnProperty('data') ? carrierHubDataClientTable.data : {};
           setClientData(data);
        }
     }, [carrierHubDataClientTable])

    const handleOpenClientData = (row) => {
        const _interval = _q.interval == '15mins' ? '15m' : _q.interval ? _q.interval : '1h';
        getHubDashboardClietTableData(row.server_location_id, row.client_device_id, _interval);
        setClientDataPopup(true);
        setSelectedSite({name: row.sitename, id: row.k4Id});
    }

    const EDGE_PORTAL_ADDRESS = "127.0.0.1";
    const EDGE_PORTAL_PORT = 80;

    const onConnectToEdge = async (e, deviceId: any, selectedVessel) => {
        e.preventDefault()
        let resp:any = await getGeneratedLinks(selectedVessel, deviceId, { address: [EDGE_PORTAL_ADDRESS] })
        if (!_.isEmpty(resp?.result)) {
            let [edgeLink] = resp.result?.filter(rc => {
                return rc?.address === EDGE_PORTAL_ADDRESS && rc.port === EDGE_PORTAL_PORT && rc.alias.includes("Portal")
            });
            if (edgeLink && edgeLink.url) {
              let url = edgeLink.url;
              const loggedInUser = getDataFromLocalStorageParsed("loggedInUser");
              let edgeToken = loggedInUser?.data?.edge_token;
              if (!_.isEmpty(edgeToken)) {
                let objUrl = new URL(`/login?token=${edgeToken}`, `${url}`)
                url = objUrl?.href
              }
              window.open(url)
              clearData()
            }
        } else {
            displayToastError('No remote connection link found')
        }
    }

    return (
        <div className="config-container" style={{ margin: '0px 25px' }}>
            <Grid container spacing={0.5} className="reports-login-history trends-usage-table-container">
                <Grid item xs={12} sm={12} md={12} lg={12} className="search-dropdown-limit-div trends-usage-search-container topbarMarginPaddingReset alignTopbarItemsCenter">
                    <Grid item xs={4} sm={4} md={4} lg={4} className="site-details-header usage-site-details-header">Agent Table</Grid>
                </Grid>
                <Paper className="audit-container-data hub-container-data" style={{ height: 'auto'}}>
                    <TableContainer className="audit-table-root hub-table-container" component={Paper}>
                        <Table size="small" aria-label="collapsible sticky table" stickyHeader style={{ tableLayout: 'fixed' }}>
                            <TableHead className="audit-data-head hub-table-header topology-agent-table-table-row">
                                <TableRow>
                                    <TableCell className="fleetDataTable audit-data-header background-header-cell">AGENT</TableCell>
                                    <TableCell className="fleetDataTable audit-data-header background-header-cell">Hub</TableCell>
                                    <TableCell className="fleetDataTable audit-data-header background-header-cell">DEVICE Name</TableCell>
                                    <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell new-summary-left background-header-cell">
                                        {/* <span style={{ whiteSpace: "nowrap" }} className="align-items-center"> */}
                                        <span className="hub-sorting-columns">AGENT USAGE(GB)</span>
                                        {/* <Grid className="sort-icon-margin">
                                                <img src={sort === USAGE && sortOrder === ASC_ORDER ? ascSortingSelected : AscSort}
                                                    className="ascendingOrderIcon sortingImages" onClick={(event) => setSorting(event, USAGE, ASC_ORDER)} />
                                                <img src={sort === USAGE && sortOrder === DESC_ORDER ? descSortingSelected : DescSort}
                                                    className="sortingImages" onClick={(event) => setSorting(event, USAGE, DESC_ORDER)} />
                                            </Grid>
                                        </span> */}
                                    </TableCell>
                                    <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell new-summary-left background-header-cell">
                                        <Tooltip title={'(Mbps)'}>
                                            {/* <span className="align-items-center"> */}
                                            <span className="hub-sorting-columns">THROUGHPUT (Mbps)</span>
                                            {/* <Grid className="sort-icon-margin">
                                                    <img src={sort === THROUGHPUTY && sortOrder === ASC_ORDER ? ascSortingSelected : AscSort}
                                                        className="ascendingOrderIcon sortingImages" onClick={(event) => setSorting(event, THROUGHPUTY, ASC_ORDER)} />
                                                    <img src={sort === THROUGHPUTY && sortOrder === DESC_ORDER ? descSortingSelected : DescSort}
                                                        className="sortingImages" onClick={(event) => setSorting(event, THROUGHPUTY, DESC_ORDER)} />
                                                </Grid>
                                            </span> */}
                                        </Tooltip>
                                    </TableCell>
                                    <TableCell className="fleetDataTable audit-data-header background-header-cell">INTERNET STATUS</TableCell>
                                    <TableCell align="center" className="fleetDataTable audit-data-header background-header-cell">MPK/VPN STATUS</TableCell>
                                    <TableCell align="center" className="fleetDataTable audit-data-header background-header-cell">TUNNELS CONNECTED</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody className="audit-data-header audit-logs-font">
                                {!setTopologyHubTableLoading && tableData?.length > 0 ? tableData?.map((row, i) => (
                                    <TableRow key={i} className="loginHistory-tableRow new-summary-usage-tr Table-Row-Opacity hub-topology-agent-view-table-row">
                                        <TableCell className="login-left-align inter-font Table-Cell-Opacity">
                                            <Tooltip title={row.device_name}>
                                                <span>{row.device_name ? row.device_name : '-'}</span>
                                            </Tooltip>
                                        </TableCell>
                                        <TableCell className="login-left-align inter-font Table-Cell-Opacity">
                                            <Tooltip title={row.hubName}><span>{row.hubName ? row.hubName : '-'}</span></Tooltip>
                                        </TableCell>
                                        <TableCell className="login-left-align inter-font Table-Cell-Opacity">
                                            <Tooltip title={row.device_name}><span>{row.device_name ? row.device_name : '-'}</span></Tooltip>
                                        </TableCell>
                                        <TableCell className="login-left-align inter-font Table-Cell-Opacity">
                                            {readableBytesAsGB(row.totalUsage, false)}
                                        </TableCell>
                                        <TableCell className="login-left-align inter-font Table-Cell-Opacity">
                                            <Tooltip title={row.throughput ? row.throughput.toFixed(2) : null}><span>{row.throughput ? row.throughput.toFixed(2) : '-'}</span></Tooltip>
                                        </TableCell>
                                        <TableCell className="login-left-align inter-font Table-Cell-Opacity">
                                            <div className="each-row-content-status">
                                                <div>
                                                    <CircleIcon id={row.client_status == 1 ? "greenDotSymbol" : row.client_status == 0 ? "redDotSymbol" : "greyDotSymbol"} />
                                                    {row.client_status == 1 ? 'Online' : row.client_status == 0 ? 'Offline' : 'Unknown'}
                                                </div>
                                            </div>
                                        </TableCell>
                                        <TableCell className="login-left-align inter-font Table-Cell-Opacity">
                                            <div className="each-row-content-status">
                                                <div className="hub-row-internetStatus hub-redirectToOtherTabs" onClick={() => handleOpenClientData(row)}>
                                                    <CircleIcon id={row.client_status == 1 ? "greenDotSymbol" : row.client_status == 0 ? "redDotSymbol" : "greyDotSymbol"} />
                                                    {row.client_status == 1 ? 'Online' : row.client_status == 0 ? 'Offline' : 'Unknown'}
                                                </div>
                                            </div>
                                        </TableCell>
                                        <TableCell className="login-left-align inter-font Table-Cell-Opacity">
                                            <span>{row.tunnels_status ? row.tunnels_status : 0}</span>
                                        </TableCell>
                                    </TableRow>
                                )) : <TableRow>
                                    <TableCell colSpan={8} align='center' className="audit-logs-no-data">
                                        No Data Available
                                    </TableCell>
                                </TableRow>}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
            </Grid>
            {clientDataPopup && <ClientsData clientData={clientData} open={clientDataPopup} setOpen={setClientDataPopup} loading={setClientTableLoading} selectedSite={selectedSite} location={location} userTimezone={userTimezone} />}
        </div>
    )
}

const mapStateToProps = (state) => ({
    selectedOu: state.authReducer?.selectedOu,
    getTopologyHubTable: state.authReducer.getTopologyHubTable,
    setTopologyHubTableLoading: state.authReducer.setTopologyHubTableLoading,
    selectedOrg: state.authReducer.selectedOrg,
    startDate: state.authReducer.newSummaryStartDate,
    endDate: state.authReducer.newSummaryEndDate,
    getHubVesselListing: state.authReducer.getHubVesselListing,
    hubFilteredVessels: state.authReducer.hubFilteredVessels,
    setClientTableLoading: state.authReducer.setHubDataClientTableLoading,
    userTimezone: state.authReducer.userTimezone,
    carrierHubDataClientTable: state.authReducer.carrierHubDataClientTable
});

export default withRouter(
    connect(mapStateToProps, { getTopologyHubTableData, getHubDashboardClietTableData, getGeneratedLinks })(TopologyHub)
);