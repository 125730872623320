import { withRouter } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { Button, Dialog, DialogContent, DialogTitle, Divider, Grid, List, ListItemButton, Stack, Tooltip, Typography, Autocomplete, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import close from "../../../asset/image/close.svg";
import _ from "lodash";
import "./AgentDialog.css";
import OrgSelection from "../AgentConfigOrgSelection";
import AgentConfigPopup from "../AgentConfigSummaryPopup";
import ServiceVlansPopup from "../AccessNetworkPopup";
import { GET_AGENT_CONFIG_SUMMARY } from "../../../actions/types";

const AGENT_CONFIG = "Agent Config Summary"
const VLANS = "Access Network"

const ActionsPopup = (props) => {
    const { authReducer, open, setIsActionPopup, isEditActionPopup, setIsEditActionPopup, existingAgentName, existingAgentConfigName, currentAgentId, agentOrganizationId, agentOrganizationName, setAgentOrganizationId, setAgentOrganizationName} = props;

    const actionsList = [VLANS, AGENT_CONFIG]

    const [selectedAction, setSelectedAction] = useState(actionsList[0]);
    const [agentId, setAgentId] = useState("")
    const [selectedDp, setSelectedDp] = useState<any>(null)

    const dispatch = useDispatch();

    useEffect(() => {
        if (isEditActionPopup && agentOrganizationId) {
            setAgentId(currentAgentId)
            setSelectedDp({id: agentOrganizationId, name: agentOrganizationName, level: 0, hasChildren: true})
        }
    }, [isEditActionPopup, agentOrganizationId])

    useEffect(() => {
        if (!selectedDp && !isEditActionPopup) {
            setSelectedDp({...authReducer?.selectedOu, level: 0, hasChildren: true})
        }
    }, [authReducer?.selectedOu?.id])

    const handleChangeDp = (ou: any) => {
        setSelectedDp(ou)
    }

    const handleChangeActionType = (actionType) => {
        setSelectedAction(actionType);
    }

    const handleClose = () => {
        setIsActionPopup(false)
        setIsEditActionPopup(false)
        setAgentOrganizationId("")
        setAgentOrganizationName("")
        dispatch({ type: GET_AGENT_CONFIG_SUMMARY, payload: {} });
    }

    useEffect(() => {
        if (isEditActionPopup) {setSelectedAction(VLANS)}
    }, [isEditActionPopup])

    return (
        <div className="Form">
            <Dialog
                disableEnforceFocus
                open={open}
                onClose={handleClose}
                aria-labelledby='form-dialog-title'
                fullWidth={true}
                maxWidth={"xl"}
                className="ac--dialog-container menuScroll ac--popup-fixed-position  ac--dialog-paper-container ac--edit-actions-container"
            >
                <div>
                    <DialogTitle className='ac--create-default-config-title ac--edit-actions-title' style={{ height: "22px" }}>
                        <div style={{ width: "100%", display: "flex", alignItems: "center" }}>
                            <div style={{ width: "19%", }}>
                                <Grid container >
                                    <Grid item lg={12} style={{ display: "flex", alignItems: "center", alignContent: "center", justifyContent: "flex-start", fontSize: "14px" }}><span >Agent Config</span></Grid>
                                </Grid>
                            </div>
                            <div style={{ width: "81%",  }}>
                                <Grid container >
                                    <Grid item lg={3} >
                                        <OrgSelection label={"Organization"} value={selectedDp} onChange={handleChangeDp} disabled={isEditActionPopup ? true : false} isEditActionPopup={isEditActionPopup}/>
                                    </Grid>
                                    <Grid item lg={7} ></Grid>
                                    <Grid item lg={2} style={{ display: "flex", justifyContent: "flex-end", alignItems: "flex-start", }}>
                                        <img src={close} title="Close" onClick={handleClose} />
                                    </Grid>
                                </Grid>
                            </div>
                        </div>

                    </DialogTitle>
                    <DialogContent className="ac--edit-actions-content">
                        <Grid container spacing={1}>
                            <Grid className="menuScroll ac--device-border-right ac--config-list-menu-container ac--service-hub-list-menu-container" item>
                                <Grid className="ac--service-hub-data-body ac--service-hub-actions-data-body">
                                    <List>
                                        {actionsList && actionsList.map((identifier: any, index) => {
                                            return <ListItemButton className={identifier == selectedAction ? "ac--status-lists ac--selectedAction " : "ac--status-lists"} key={`device-${index}`} selected={identifier === selectedAction}
                                                onClick={() => handleChangeActionType(identifier)}>
                                                <Tooltip title={identifier?.isParent ? 'Parent' : null}>
                                                    <Typography className={identifier === selectedAction ? "ac--font-status-pop-up ac--selectedAction" : "ac--font-status-pop-up ac--NotselectedAction"} style={{ }} >{identifier}</Typography>
                                                </Tooltip>
                                            </ListItemButton>
                                        })}
                                    </List>
                                </Grid>
                            </Grid>
                            <Grid className="menuScroll ac--service-hub-list-menu-content">
                                <Grid className="ac--service-hub-data-body ac--edit-actions-data-body">
                                    <Stack className="ac--sub-tab-parent" direction="column" spacing={1}>
                                        {selectedAction === AGENT_CONFIG && <AgentConfigPopup setIsActionPopup={setIsActionPopup} isEditActionPopup={isEditActionPopup} setIsEditActionPopup={setIsEditActionPopup} setSelectedAction={setSelectedAction} selectedAction={selectedAction} existingAgentName={existingAgentConfigName} agentId={agentId} setAgentId={setAgentId} selectedDp={selectedDp} handleClose={handleClose}/>}
                                        {selectedAction === VLANS && <ServiceVlansPopup setIsActionPopup={setIsActionPopup} isEditActionPopup={isEditActionPopup} setIsEditActionPopup={setIsEditActionPopup} setSelectedAction={setSelectedAction} selectedAction={selectedAction} agentId={agentId} setAgentId={setAgentId} selectedDp={selectedDp} handleClose={handleClose}/>}
                                    </Stack>
                                </Grid>
                            </Grid>
                        </Grid>
                    </ DialogContent>
                </div>
            </Dialog>
        </div>
    )
}

const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
    getDrawerOpen: state.authReducer?.getDrawerOpen,
    inventoryAssignDeviceToWarehouse: state.authReducer?.inventoryAssignDeviceToWarehouse,
    setInventoryAssignDeviceToWarehouse: state.authReducer.setInventoryAssignDeviceToWarehouse,
});

export default withRouter(
    connect(mapStateToProps, {
    })(ActionsPopup)
);