import { withRouter } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { Box, Button, Grid, Menu, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip, Dialog} from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import TuneIcon from '@mui/icons-material/Tune';
import close from "../../asset/image/close.svg";
import { getDecodeURI, getEncodedURI, getIntervalCheck } from "../../utils/util";
import { getOperationalUsageDataSingleSitePerWanUsage,getOperationalUsageTimeseriesTable, getOperationalUsageTimeseriesTableCount } from '../../actions/Users/authenticateUsageReports';
import _ from "lodash";
import { Pagination } from '../Pagination';
import Download from '../../asset/image/DownloadUsage.svg';
import SortArrows, { SortOrder } from "../SortArrows";
import OperationalTimeSeriesTable from "../OperationalUsageTimeseriesTable";
import * as XLSX from "xlsx";
import { readableBytesAsGB } from "../../utils/util";

const OperationalUsagePerWanWise = (props) => {
    const {
        ids, setIds, usageWanWiseOpen, setUsageWanWiseOpen, site, setSite, dwn, setDwn,
        authReducer,
        getOperationalUsageDataSingleSitePerWanUsage,
        newSummaryStartDate,
        newSummaryEndDate,
        reports,
        total_count,
        location,history
    } = props;
    let _q = new Map<string, string>(Object.entries(getDecodeURI(location.search)));

    const location_id=`'${ids}'`;      

    useEffect ( () => {
        if(location_id){
            getOperationalUsageDataSingleSitePerWanUsage({
                vessels: location_id,
                start_time: newSummaryStartDate,
                end_time: newSummaryEndDate,
            })
        }
    },[location_id, newSummaryStartDate, newSummaryEndDate])

    const handleViewdetailsClose = () => {
        setUsageWanWiseOpen(false);
    }
    const interf="";
    const handleDownloadClick = () => {
        setDwn(true);
        setIds(ids);
    }

    return (
        <Dialog className="menuScroll " onClose={handleViewdetailsClose} open={usageWanWiseOpen} maxWidth="lg" fullWidth>
          <div className="operationalTopbar perSiteperWanUsageTableMargin">
                <div>{site}</div>
                <Grid item xs={4} sm={4} md={4} lg={4} className="timeseriesDwn">
                    <div className="download-summary-btn marg-left-auto" onClick={handleDownloadClick}><img className="downloadImg" src={Download} alt="" /><Button>Download</Button></div>
                </Grid>
                <div className='perSiteperWanUsageClose'> <img className="perSiteperWanUsageCloseIcon"
                    src={close}
                    alt='close'
                    onClick ={handleViewdetailsClose}>
                    </img>
                </div>
            </div>  
          <Grid item xs={12} sm={12} md={12} lg={12} className="TopUsersConatiner generated-reports-table perSiteperWanUsageTableMargin">

            <TableContainer className="usageReports-FleetDashboard reports-gene-container">
                    <Table aria-label="simple sticky table" stickyHeader>
                        <TableHead className="usageReports-tableHead config-tableRow">
                            <TableRow>

                                <TableCell className="usage-fleetDataTable generated-reports-head font-wt-900">INTERFACE NAME</TableCell>
                                <TableCell className="usage-fleetDataTable generated-reports-head font-wt-900">WAN USAGE (GB)</TableCell>
                                <TableCell className="usage-fleetDataTable generated-reports-head font-wt-900">OPERATIONAL USAGE (GB)</TableCell>
                                <TableCell className="usage-fleetDataTable generated-reports-head font-wt-900">MPK OVERHEAD (GB)</TableCell>
                            </TableRow>
                        </TableHead>
                        {reports?.data?.rows?.length > 0 ? (  
                            <TableBody className="tableBody usage-tableBody operationalTanbleOverflow">
                                 {reports?.data?.rows.map((row:any, rowIndex:number) => (
                                    <TableRow key={rowIndex}>
                                        {row.map((cell: any, cellIndex: number) => (
                                            <TableCell className="operationalTableData" key={cellIndex}>{typeof cell === 'number' ? (Number.isInteger(cell)? readableBytesAsGB(cell).replace(" GB", "") : cell.toFixed(3)) : cell} </TableCell>
                                        ))}
                                    </TableRow>
                                 ))}
                            </TableBody>
                        ) : (
                            <TableBody>
                                <TableRow>
                                    <TableCell colSpan={6} className="noDataAvailableCss" align="center">No data available</TableCell>
                                </TableRow>
                            </TableBody>
                        )}
                    </Table>
                </TableContainer>
          </Grid>
        </Dialog>
    )
} 

const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
    errorReducer: state.errorReducer,
    newSummaryStartDate: state.authReducer.newSummaryStartDate,
    newSummaryEndDate: state.authReducer.newSummaryEndDate,
    reports: state.authReducer.getOperationalUsageDataSingleSitePerWanWise
});

export default withRouter(
    connect(mapStateToProps, {
        getOperationalUsageDataSingleSitePerWanUsage
    })(OperationalUsagePerWanWise)
);