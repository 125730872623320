import { FormControl, Grid, MenuItem, Select, Switch, Tooltip } from "@mui/material";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

const SiteUsagePie = (props) => {
    const { title, pieLimit, handleChangePieLimit, toggleBtn, handleChangeToggle, wanOptions, usagePerSite, handleSiteClick, colors, chartRef={}} = props;

    return (
        <Grid>
            <Grid className="new-summary-control-limit title-toggle-btn-div">
                <Grid className="title-dropdown-pie-chart">
                    <Tooltip title={title=='Usage' ? 'Source : WAN Report' : null}><div className="title-text">{title}</div></Tooltip>
                    <Grid className="pieChat-selectTag">
                        <FormControl variant="standard" className='selectEntry-pie'>
                            <Select
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                value={pieLimit}
                                onChange={handleChangePieLimit}
                            >
                                <MenuItem value={10}>10</MenuItem>
                                <MenuItem value={20}>20</MenuItem>
                                <MenuItem value={30}>30</MenuItem>
                                <MenuItem value={50}>50</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid>
                    <span className="toggle-wan">{title=='Alerts' ? 'ALERT TYPE' : 'WAN'}</span>
                    <Switch
                        checked={toggleBtn}
                        onChange={handleChangeToggle}
                        inputProps={{ 'aria-label': 'controlled' }}
                        size="small"
                    />
                    <span className="toggle-site">SITE</span>
                </Grid>
            </Grid>
            <HighchartsReact
                highcharts={Highcharts}
                options={wanOptions}
                ref={chartRef}
            />
            <Grid container className={usagePerSite?.length > 1 && title=='Usage' ? "usage-details-pie-perSite wan-type-pie" : "usage-details-pie-perSite remove-margin-left"} style={title=='Alerts' && usagePerSite?.length > 1 ? {justifyContent:'flex-start'} : {justifyContent:'center'}}>
                {usagePerSite && usagePerSite.map((usage, i) => (
                    <Grid item xs={12} sm={12} md={6} lg={6} className={usagePerSite?.length == 1 ? 'single-value' : ''}>
                        <div className="summary-pie-usage-dot" style={{ background: `${colors[i]}` }}></div>
                        <Tooltip title={usage.x}><span className="summary-usage-value selected-filter" onClick={() => handleSiteClick(usage)}>{usage.x}</span></Tooltip>
                    </Grid>
                ))}
            </Grid>
        </Grid>
    )
}

export default SiteUsagePie;