import React, { useState, useEffect, useRef } from "react";
import Tree, { RawNodeDatum, RenderCustomNodeElementFn } from "react-d3-tree";
import { PathFunction } from "react-d3-tree";

import './TreeGraph.css';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import InfoIcon from '@mui/icons-material/Info';
import { Dialog, DialogContent, DialogTitle, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";

interface CustomTreeNode extends RawNodeDatum {
  image?: string;
  depth?: number; // Manually added depth property
  parent?: CustomTreeNode; // Added parent reference for tracking parent hierarchy
  k4id?: string;
  isSite?: boolean
  status?: any
}

interface TreeGraphProps {
  data: CustomTreeNode;
}

// Function to assign depth values to nodes and add parent reference
const assignDepths = (node: CustomTreeNode, depth = 0, parent?: CustomTreeNode): CustomTreeNode => ({
  ...node,
  depth,
  parent, // Add parent reference
  children: node.children?.map(child => assignDepths(child as CustomTreeNode, depth + 1, node)),
});

// Function to determine depth-based spacing
const getDepthSpacing = (depth: number) => {
  const baseX = 50; // Base width
  const baseY = 150; // Base height
  const spacingFactor = 200; // Increase gap per level

  return {
    nodeSize: { x: baseX + depth * spacingFactor, y: baseY },
  };
};



const TreeGraph = (props: any) => {
  const { data, handleLeafNodeClick, isPopUpOpen, handleOrgClick } = props;
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [dimensions, setDimensions] = useState({ width: 800, height: 600 });
  const [treeData, setTreeData] = useState<CustomTreeNode | null>(null);
  const [selectedNode, setSelectedNode] = useState<CustomTreeNode | null>(null); // Track selected node
  const [zoomScale, setZoomScale] = useState(1);
  const treeRef = useRef<any>(null);
  const [depth, setDepth] = useState<number>(1);
  const [openInfo, setOpenInfo] = useState<boolean>(false);

  useEffect(() => {
    if (containerRef.current) {
      const observer = new ResizeObserver(() => {
        if (containerRef.current) {
          setDimensions({
            width: containerRef.current.offsetWidth,
            height: containerRef.current.offsetHeight,
          });
        }
      });
      observer.observe(containerRef.current);
      return () => observer.disconnect();
    }
  }, []);

  useEffect(() => {
    if (!isPopUpOpen) {
      setSelectedNode(null);
    }
  }, [isPopUpOpen])

  useEffect(() => {
    if (data) {
      console.log("alldata", data)
      setTreeData(assignDepths(data)); // Assign depth and parent reference before setting tree data
    }
  }, [data, depth]);

  // Function to get the parent hierarchy of a leaf node as a comma-separated string
  const getParentHierarchy = (node: CustomTreeNode): string => {
    let currentNode = node;
    const parentHierarchy: string[] = [];

    while (currentNode.parent) {
      parentHierarchy.push(currentNode.parent.name); // Add the parent node name
      currentNode = currentNode.parent;
    }

    parentHierarchy.push(node.name); // Add the clicked node name itself
    return parentHierarchy.reverse().join(', '); // Reverse to show from top to bottom
  };

  // Callback function to handle the label click event (selection and showing parent hierarchy)
  const handleCiecleLabelClick = (node: CustomTreeNode, event: React.MouseEvent) => {
    event.stopPropagation(); // Prevent collapsing
    console.log("dp Label clicked:", node.name);
    const hierarchy = getParentHierarchy(node); // Get parent hierarchy as a comma-separated string
    console.log("Parent Hierarchy:", hierarchy); // Display the comma-separated hierarchy
    handleOrgClick(node, hierarchy)
    toggleSelection(node); // Toggle the selection and border color
  };

  const handleReactLabelClick = (node: CustomTreeNode, event: React.MouseEvent) => {
    event.stopPropagation(); // Prevent collapsing
    console.log("Label clicked:", node.name);
    const hierarchy = getParentHierarchy(node); // Get parent hierarchy as a comma-separated string
    console.log("Parent Hierarchy:", hierarchy); // Display the comma-separated hierarchy

    toggleSelection(node); // Toggle the selection and border color
  };

  // Callback function to handle the circle click event (open/close collapse)
  const handleCircleClick = (node: CustomTreeNode, event: React.MouseEvent) => {
    event.stopPropagation(); // Allow collapsing/expanding
    console.log("Circle clicked:", node.name);
  };

  // Callback function for leaf rectangle click (select and show parent hierarchy)
  const handleLeafRectClick = (node: CustomTreeNode, event: React.MouseEvent) => {
    event.stopPropagation(); // Prevent any other event propagation
    console.log("Leaf Rectangle clicked:", node.name);
    console.log("node isSite",node?.isSite)
    const hierarchy = getParentHierarchy(node);
    console.log("Parent Hierarchy:", hierarchy);
    toggleSelection(node); // Toggle the selection and border color
    handleLeafNodeClick(hierarchy, node)
  };

  // Toggle the blue border for selected nodes
  const toggleSelection = (node: CustomTreeNode) => {
    if (selectedNode === node) {
      setSelectedNode(null); // Deselect if clicked again
    } else {
      setSelectedNode(node); // Select the new node
    }
  };

  const getTreeDepth = (node: CustomTreeNode): number => {
    if (!node.children || node.children.length === 0) return 1;
    return 1 + Math.max(...node.children.map(getTreeDepth));
  };



  useEffect(() => {
    if (!treeData || !containerRef.current) return;

    const depth = getTreeDepth(treeData);
    const minZoom = 0.05; // Allow more zoom out
    const maxZoom = 1.5; // Adjust max zoom if needed

    const idealZoom = Math.min(
      dimensions.width / (depth * 100), // Increase divisor to zoom out further
      dimensions.height / (depth * 800) // Increase divisor for vertical scaling
    );

    setZoomScale(Math.max(minZoom, Math.min(idealZoom, maxZoom))); // Clamp zoom within range
  }, [treeData]); // Runs whenever treeData updates  

  const handleClose = () => {
    setOpenInfo(false);
  }

  const handleInfoClick = () => {
    setOpenInfo(true);
  }



  const handleZoomIn = () => {
    setZoomScale((prev) => Math.min(prev * 1.2, 5)); // Max scale 5
  };

  const handleZoomOut = () => {
    setZoomScale((prev) => Math.max(prev / 1.2, 0.1)); // Min scale 0.1
  };

  const handleExpand = () => {
    if (depth<maxDepth-1) setDepth(depth+1);
  }
  const handleContract = () => {
    if (depth>0) setDepth(depth-1);
  }
  const maxDepth = treeData ? getTreeDepth(treeData) : 1;
  const handleDoubleClick = (event: any) => {
    event.preventDefault();  // Prevent default zoom behavior
    event.stopPropagation(); // Stop event bubbling
  };
  useEffect(() => {
    const svgElement = document.querySelector(".rd3t-svg");
    if (svgElement) {
      svgElement.addEventListener("dblclick", (event) => {
        event.preventDefault();
        event.stopPropagation();
      });
    }
  }, []);
  
  // const getLinkColor = (linkData: any) => {
  
  //   const customNode = linkData as CustomTreeNode;
  //   console.log("linkData",customNode)
  //   return linkData.source.data.status === "STATUS_CONNECTED" ? "green-link" : "red-link";
  // };


  // Custom node rendering function
  const renderCustomNodeElement: RenderCustomNodeElementFn = ({ nodeDatum, toggleNode }) => {
    const customNode = nodeDatum as CustomTreeNode;
    const isMasterNode = !customNode.parent;
    console.log("customNode",customNode?.isSite)
    // const hasChildren = customNode.children && customNode.children.length > 0;
    const isSelected = selectedNode === customNode; // Check if the node is selected

    // console.log("customNode",customNode)

    const hasChildren = customNode.children && customNode.children.length > 0;
    const hasNoK4id = !customNode.k4id; // Check if `k4id` is missing
    const isCircle = hasChildren || hasNoK4id; // Determine shape
    const isSite = customNode.isSite ?? false;

    console.log("customNode?.status",customNode?.status)
    return (
      <g cursor="pointer">
        {/* Parent Nodes: Circle (Click to toggle children) */}

        {isMasterNode ? (
          <>
          <circle
            r={15} // Larger size for master node
            fill="lightblue" // Different color
            stroke="blue"
            strokeWidth={isSelected ? "5" : "2"}
            onClick={toggleNode}
          />
          <g>
              {/* Define the glow effect */}
              <defs>
                <filter id="textGlow" x="-100%" y="-100%" width="400%" height="250%">
                  <feGaussianBlur stdDeviation="8 5" result="blur" /> {/* Increased blur for a smoother effect */}
                  <feMerge>
                    <feMergeNode in="blur" />
                    <feMergeNode in="SourceGraphic" />
                  </feMerge>
                </filter>
              </defs>
              {isSelected && (
              <text
                x={hasChildren ? 0 : 20}
                y={hasChildren ? 20 : 0}
                fill="blue"
                fontSize="18"
                dy=".35em"
                textAnchor="start"
                filter="url(#textGlow)" // Apply glow effect
                opacity="1" // Adjust opacity for better visibility
              >
                {customNode.name}
              </text>
            )}
            <text
              x={hasChildren ? 0 : 20}
              y={hasChildren ? 20 : 0}
              fill={isSelected ? "hsl(200, 100%, 50%)" : "blue"}
              fontSize="18"
              dy=".35em"
              textAnchor={hasChildren ? "start" : "start"}
              onClick={(e) => handleCiecleLabelClick(customNode, e)} // Separate click for text
              className="textColor"
            >
              {customNode.name} - ({customNode?.children?.length})
            </text>
            </g>
          </>
        ) : isCircle ? (
          <>
            {/* <circle r={10} fill="lightblue" stroke="blue" strokeWidth={isSelected ? "5":"2"} onClick={toggleNode} /> */}
            <polygon
              points="-10,5 0,10 10,5 10,-5 0,-10 -10,-5"
              fill="lightblue"
              stroke={"blue"}
              strokeWidth={isSelected ? "5" : "2"}
              onClick={toggleNode}
            />
            <g>
              {/* Define the glow effect */}
              <defs>
                <filter id="textGlow" x="-100%" y="-100%" width="400%" height="250%">
                  <feGaussianBlur stdDeviation="8 5" result="blur" /> {/* Increased blur for a smoother effect */}
                  <feMerge>
                    <feMergeNode in="blur" />
                    <feMergeNode in="SourceGraphic" />
                  </feMerge>
                </filter>
              </defs>
              {isSelected && (
              <text
                x={hasChildren ? 0 : 20}
                y={hasChildren ? 20 : 0}
                fill="blue"
                fontSize="18"
                dy=".35em"
                textAnchor="start"
                filter="url(#textGlow)" // Apply glow effect
                opacity="1" // Adjust opacity for better visibility
              >
                {customNode.name}
              </text>
            )}
            <text
              x={hasChildren ? 0 : 20}
              y={hasChildren ? 20 : 0}
              fill={isSelected ? "hsl(200, 100%, 50%)" : "blue"}
              fontSize="18"
              dy=".35em"
              textAnchor={hasChildren ? "start" : "start"}
              onClick={(e) => handleCiecleLabelClick(customNode, e)} // Separate click for text
              className="textColor"
            >
              {customNode.name} - ({customNode?.children?.length})
            </text>
            </g>
          </>
        ) : (
          <>
            {customNode.isSite ? (
              (customNode?.status == "true" || customNode?.status == "STATUS_CONNECTED") ? 
              <polygon
              points="-5, -2 5,-12 15,-2 15,8 -5,8"
              fill={"#6ffc03"}
              stroke={isSelected ? "blue" : (customNode?.status == "true" || customNode?.status == "STATUS_CONNECTED") ? "#6ffc03" : "red"} 
              strokeWidth={isSelected ? "3" : "2"}
              /> :
              <polygon
              points="-5,2 5,12 15,2 15,-8 -5,-8"
              fill={"red"}
              stroke={isSelected ? "blue" : (customNode?.status == "true" || customNode?.status == "STATUS_CONNECTED") ? "#6ffc03" : "red"} 
              strokeWidth={isSelected ? "3" : "2"}
              />
          
              // Rectangle for isSite = true
              // <rect
              //   x="-5"
              //   y="-5"
              //   width="18"
              //   height="10"
              //   fill={(customNode?.status == "true" || customNode?.status == "STATUS_CONNECTED") ? "#6ffc03" : "red"}
              //   stroke={isSelected ? "blue" : (customNode?.status == "true" || customNode?.status == "STATUS_CONNECTED") ? "#6ffc03" : "red"} // Blue border if selected
              //   strokeWidth={isSelected ? "5" : "2"}
              //   rx="2"
              //   ry="2"
              // />
            ) : (
              // Diamond for isSite = false
              // <polygon
              //   points="-12,0 0,-12 12,0 0,12"
              //   fill={(customNode?.status == "true" || customNode?.status == "STATUS_CONNECTED") ? "#6ffc03" : "red"}
              //   stroke={isSelected ? "blue" : (customNode?.status == "true" || customNode?.status == "STATUS_CONNECTED") ? "#6ffc03" : "red"}
              //   strokeWidth={isSelected ? "5" : "2"}
              // />
              <polygon
                points="10,-12 15,-5 25,-4 17,2 19,11 10,6 1,11 3,2 -5,-4 5,-5"
                fill={(customNode?.status == "true" || customNode?.status == "STATUS_CONNECTED") ? "#6ffc03" : "red"}
                stroke={isSelected ? "blue" : (customNode?.status == "true" || customNode?.status == "STATUS_CONNECTED") ? "#6ffc03" : "red"}
                strokeWidth={isSelected ? "3" : "2"}
                transform="scale(0.75) translate(-2, -3)"
              />
            )}
            <g>
              {/* Define the glow effect */}
              <defs>
                <filter id="textGlow" x="-100%" y="-100%" width="400%" height="250%">
                  <feGaussianBlur stdDeviation="8 5" result="blur" /> {/* Increased blur for a smoother effect */}
                  <feMerge>
                    <feMergeNode in="blur" />
                    <feMergeNode in="SourceGraphic" />
                  </feMerge>
                </filter>
              </defs>
              {isSelected && (
              <text
                x={hasChildren ? 0 : 20}
                y={hasChildren ? 20 : 0}
                fill="blue"
                fontSize="18"
                dy=".35em"
                textAnchor="start"
                filter="url(#textGlow)" // Apply glow effect
                opacity="1" // Adjust opacity for better visibility
              >
                {customNode.name}
              </text>
            )}
            
            <text
              x={hasChildren ? 0 : 20}
              y={hasChildren ? 20 : 0}
              fill="blue"
              fontSize="18"
              dy=".35em"
              textAnchor={hasChildren ? "start" : "start"}
              onClick={(e) => handleLeafRectClick(customNode, e)} // Separate click for text
            >
              {customNode.name}
            </text>
            </g>
          </>
        )}

      </g>
    );
  };


  if (!treeData) return null;

  return (
    <div
      ref={containerRef}
      onDoubleClick={handleDoubleClick}  // Prevent double-click zoom
      style={{
        width: "100vw",
        height: "100vh",
        border: "1px solid #ccc",
        overflow: "visible",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        position: "relative",
      }}
    >
      <div style={{
          position: "fixed",
          top: 120,
          left: 220,
          zIndex: 1000000,
          display: "flex",
          flexDirection: "row",
          gap: "5px"  // Add space between buttons
        }}>
        <button onClick={handleContract} className="topologyFilterButtons"><ArrowLeftIcon sx={{color: depth == 0 ? "grey" : "black"}}/></button>
        <button onClick={handleExpand} className="topologyFilterButtons"><ArrowRightIcon sx={{color: depth == maxDepth-1 ? "grey" : "black"}}/></button>
      </div>
      <div className="zoomButtons"
        key={zoomScale}
        style={{
          position: "fixed",
          top: 120,
          right: 10,
          zIndex: 1000000,
          display: "flex",
          flexDirection: "column",  // Stack buttons vertically
          gap: "5px"  // Add space between buttons
        }}>
        <button onClick={handleZoomIn} style={{ padding: "5px 10px" }}>+</button>
        <button onClick={handleZoomOut} style={{ padding: "5px 10px" }}>-</button>
      </div>
      <div
        style={{
          position: "fixed",
          bottom: 10,
          right: 10,
          zIndex: 1000000,
          display: "flex",
          flexDirection: "column",  // Stack buttons vertically
          gap: "5px"  // Add space between buttons
        }} title="info">
        <InfoIcon onClick={handleInfoClick} style={{cursor: "pointer"}}/>
      </div>
      {openInfo && <div
      style={{
        position: "fixed",
        bottom: 50, // Adjust position relative to InfoIcon
        right: 10,
        width: "200px",
        background: "white",
        border: "1px solid #ccc",
        boxShadow: "2px 2px 10px rgba(0,0,0,0.2)",
        padding: "10px",
        borderRadius: "5px",
        zIndex: 1000001,
      }}
    >
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        Legend Information
        <button onClick={handleClose} style={{ background: "none", border: "none", cursor: "pointer" }}>✖</button>
      </div>
      <br></br>
            <Table sx={{ border: "1px solid black", borderCollapse: "collapse" }} className="tablehead">
              <TableHead >
                <TableRow>
                  <TableCell className="legendFont" sx={{ border: "1px solid black", padding: "0px" }}></TableCell>
                  <TableCell className="legendFont" sx={{ border: "1px solid black", padding: "0px" }}>Default</TableCell>
                  <TableCell className="legendFont" sx={{ border: "1px solid black", padding: "0px" }}>Selected</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
              <TableRow className="align" >
                    <TableCell className="alignment legendFont" sx={{ border: "1px solid black" }}>{"Master"}</TableCell>
                    <TableCell className="alignment legendFont" sx={{ border: "1px solid black" }}><svg width="30" height="30" viewBox="-10 -10 30 30"><circle r={10}
                      fill={"lightblue"}
                      stroke={"lightblue"}
                      strokeWidth={"2"}
                      transform="scale(0.75) translate(2, 3)"
                    /></svg>
                    </TableCell>
                    <TableCell className="alignment legendFont" sx={{ border: "1px solid black" }}><svg width="30" height="30" viewBox="-10 -10 30 30"><circle r={10}
                      fill={"lightblue"}
                      stroke={"blue"}
                      strokeWidth={"5"}
                      transform="scale(0.75) translate(2, 3)"
                    /></svg></TableCell>
                  </TableRow>
                  <TableRow className="align">
                    <TableCell className="alignment legendFont" sx={{ border: "1px solid black" }}>{"Sub. org"}</TableCell>
                    <TableCell className="alignment legendFont" sx={{ border: "1px solid black" }}><svg width="30" height="30" viewBox="-10 -10 30 30"><polygon
                      points="-10,5 0,10 10,5 10,-5 0,-10 -10,-5"
                      fill={"lightblue"}
                      stroke={"lightblue"}
                      strokeWidth={"2"}
                      transform="scale(0.75) translate(2, 5)"
                    /></svg>
                    </TableCell>
                    <TableCell className="alignment legendFont" sx={{ border: "1px solid black" }}><svg width="30" height="30" viewBox="-10 -10 30 30"><polygon
                      points="-10,5 0,10 10,5 10,-5 0,-10 -10,-5"
                      fill={"lightblue"}
                      stroke={"blue"}
                      strokeWidth={"5"}
                      transform="scale(0.75) translate(2, 5)"
                    /></svg></TableCell>
                  </TableRow>
                  <TableRow className="align">
                    <TableCell className="alignment legendFont" sx={{ border: "1px solid black" }}>{"Site Up"}</TableCell>
                    <TableCell className="alignment legendFont" sx={{ border: "1px solid black" }}><svg width="30" height="30" viewBox="-10 -10 30 30"><polygon
                      points="-5, -2 5,-12 15,-2 15,8 -5,8"
                      fill={"#6ffc03"}
                      stroke={"#6ffc03"}
                      strokeWidth={"2"}
                      transform="scale(0.75) translate(0, 5)"
                    /></svg>
                    </TableCell>
                    <TableCell className="alignment legendFont" sx={{ border: "1px solid black" }}><svg width="30" height="30" viewBox="-10 -10 30 30"><polygon
                      points="-5, -2 5,-12 15,-2 15,8 -5,8"
                      fill={"#6ffc03"}
                      stroke={"blue"}
                      strokeWidth={"5"}
                      transform="scale(0.75) translate(0, 5)"
                    /></svg></TableCell>
                  </TableRow>
                  <TableRow className="align">
                    <TableCell className="alignment legendFont" sx={{ border: "1px solid black" }}>{"Site Down"}</TableCell>
                    <TableCell className="alignment legendFont" sx={{ border: "1px solid black" }}><svg width="30" height="30" viewBox="-10 -10 30 30"><polygon
                      points="-5,2 5,12 15,2 15,-8 -5,-8"
                      fill={"red"}
                      stroke={"red"}
                      strokeWidth={"2"}
                      transform="scale(0.75) translate(0, 5)"
                    /></svg>
                    </TableCell>
                    <TableCell className="alignment legendFont" sx={{ border: "1px solid black" }}><svg width="30" height="30" viewBox="-10 -10 30 30"><polygon
                      points="-5,2 5,12 15,2 15,-8 -5,-8"
                      fill={"red"}
                      stroke={"blue"}
                      strokeWidth={"5"}
                      transform="scale(0.75) translate(0, 5)"
                    /></svg></TableCell>
                  </TableRow>
                  <TableRow className="align">
                    <TableCell className="alignment legendFont" sx={{ border: "1px solid black" }}>{"Starlink Up"}</TableCell>
                    <TableCell className="alignment legendFont" sx={{ border: "1px solid black" }}><svg width="30" height="30" viewBox="-10 -10 30 30"><polygon
                      points="10,-6  15,1  25,2  17,8  19,17  10,12  1,17  3,8  -5,2  5,1"
                      fill={"#6ffc03"}
                      stroke={"#6ffc03"}
                      strokeWidth={"2"}
                      transform="scale(0.75) translate(-2, 1)"
                    /></svg>
                    </TableCell>
                    <TableCell className="alignment legendFont" sx={{ border: "1px solid black" }}><svg width="30" height="30" viewBox="-10 -10 30 30"><polygon
                      points="10,-6  15,1  25,2  17,8  19,17  10,12  1,17  3,8  -5,2  5,1"
                      fill={"#6ffc03"}
                      stroke={"blue"}
                      strokeWidth={"5"}
                      transform="scale(0.75) translate(-2, 1)"
                    /></svg></TableCell>
                  </TableRow>
                  <TableRow className="align">
                    <TableCell className="alignment legendFont" sx={{ border: "1px solid black" }}>{"Starlink Down"}</TableCell>
                    <TableCell className="alignment legendFont" sx={{ border: "1px solid black" }}><svg width="30" height="30" viewBox="-10 -10 30 30"><polygon
                      points="10,-6  15,1  25,2  17,8  19,17  10,12  1,17  3,8  -5,2  5,1"
                      fill={"red"}
                      stroke={"red"}
                      strokeWidth={"2"}
                      transform="scale(0.75) translate(-2, 1)"
                    /></svg>
                    </TableCell>
                    <TableCell className="alignment legendFont" sx={{ border: "1px solid black" }}><svg width="30" height="30" viewBox="-10 -10 30 30"><polygon
                      points="10,-6  15,1  25,2  17,8  19,17  10,12  1,17  3,8  -5,2  5,1"
                      fill={"red"}
                      stroke={"blue"}
                      strokeWidth={"5"}
                      transform="scale(0.75) translate(-2, 1)"
                    /></svg></TableCell>
                  </TableRow>
              </TableBody>
            </Table>
      </div>}
      <Tree
        data={treeData}
        translate={{
          x: dimensions.width / 2,
          y: dimensions.height * 0.75,
        }}
        orientation="horizontal"
        zoom={zoomScale} // Use zoom state here
        collapsible={true}
        zoomable={true}
        scaleExtent={{ min: 0.1, max: 5 }}
        nodeSize={getDepthSpacing(3).nodeSize} // Increase depth dynamically
        separation={{ siblings: 0.4, nonSiblings: 0.5 }}
        pathFunc="diagonal"
        renderCustomNodeElement={renderCustomNodeElement}
        // pathClassFunc={(linkData) => getLinkColor(linkData)}
        pathClassFunc={() => "custom-link"}
        initialDepth={depth}
      />
    </div>
  );
};

export default TreeGraph;
