import { useEffect, useState,CSSProperties } from 'react';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Link from '@mui/material/Link';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import onlineIcon from "../../asset/image/online.svg";
import offlineIcon from "../../asset/image/offline.svg";
import { Button, Grid, Tooltip } from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';
import './BreadCrumbs.css';
import HelpIcon from '@mui/icons-material/Help';
import { useLocation } from "react-router-dom";
import { getDataFromLocalStorage, getUserGuide, removeQueryParamK4Ids } from '../../utils/util';
import SubHeaderSummary from '../SubHeaderSummary';
import FilterChips from '../BreadCrumbs/FilterChips';
import _ from 'lodash';
import SideNavKnowledgeCenter from "../../asset/image/SideNavKnowledgeCenter.svg";
import { MAINTENANCE } from '../../constants/Constants';

var Loader = require("react-loader");

const FiltersConatiner = (props) => {
  const { setBreadcrumbInfo, breadcrumbinfo, isVesselOnline, loading, authReducer, history, setTimePeriod, periodOptions, isCustomPeriodSelectionRequired, handleSiteClick, showWantType, showSummaryPeriod, helpIcon, helpAction, disableOu, disableSite, showDateOnly, userGuide, showSLDropDown,
    hubVesselNames, hubSelectedVesselNames, hubSelectedVesselsDisplayName, siteSearchValue, setSiteSearchValue, handleChangeVesselHub, showSeverity, showAccounts, showLastThreeMonths, showPlans, showAllServiceLines } = props;
  const [breadcrumbs, setBreadcrumbs] = useState<any[]>([{ title: '' }]);
  const location = useLocation();

  const override: CSSProperties = {
    width: "10px !important",
     height: "10px !important",
  };

  useEffect(() => {
    if (breadcrumbinfo) {
      _setBreadcrumbs(breadcrumbinfo);
    }
    if (setBreadcrumbInfo) {
      setBreadcrumbInfo.current = _setBreadcrumbs;
    }
  }, []);

  useEffect(() => {
    if (authReducer?.selectedOu?.name && breadcrumbs && breadcrumbs.length > 0) {
      breadcrumbs[0] = { title: authReducer?.selectedOu.name };
    }
  }, [authReducer.selectedOu]);

  const _setBreadcrumbs = (_breadcrumbs: any[]) => {
    if (authReducer?.selectedOu?.name && _breadcrumbs && _breadcrumbs.length > 0) {
      _breadcrumbs[0] = { title: authReducer?.selectedOu.name };
    }
    setBreadcrumbs(_breadcrumbs);
  }

  const handleSelctedSubDetails = () => {

  }

  return (
    <div className="breadcrumbs_NewSummary commonFilters" style={authReducer?.getDrawerOpen ? {width: '92%'} : {width: '99.1%'}}>
           <SubHeaderSummary newSummary={true} subHeaderSelectedDropdown={handleSelctedSubDetails} setTimePeriod={setTimePeriod} periodOptions={periodOptions} isCustomPeriodSelectionRequired={isCustomPeriodSelectionRequired} showSummaryPeriod={showSummaryPeriod} showWantType={showWantType} disableOu={disableOu} disableSite={disableSite} showDateOnly={showDateOnly} showSLDropDown={showSLDropDown}
           hubVesselNames={hubVesselNames} hubSelectedVesselNames={hubSelectedVesselNames} hubSelectedVesselsDisplayName={hubSelectedVesselsDisplayName} siteSearchValue={siteSearchValue} setSiteSearchValue={setSiteSearchValue} handleChangeVesselHub={handleChangeVesselHub} showSeverity={showSeverity} showAccounts={showAccounts} showLastThreeMonths={showLastThreeMonths} showPlans={showPlans} showAllServiceLines={showAllServiceLines} />
         
           <FilterChips handleSiteClick={handleSiteClick} />
           {helpIcon ? 
            <Grid item container xs={1} className="help_NewSummary">
            <Button className="buttonCssFleet_width" title="Help" onClick={helpAction}><img src={SideNavKnowledgeCenter} alt={"Knowledge Center"} className='ht-wt-kc-icon'/></Button>
            </Grid>
            :
            userGuide && userGuide[0] ? <div className="sport-user-guide" onClick={() => getUserGuide(userGuide[1], authReducer?.domainConfig?.knowldgeHelper)}>
              <Tooltip title='User Guide' placement='left'><HelpIcon /></Tooltip>
            </div>
            : null
            }
           {
            loading ? (
              <div className={showSummaryPeriod != false ? "commonLoader_NewSummary period-commonLoader_NewSummary-right-317" : "commonLoader_NewSummary period-commonLoader_NewSummary-right-65"} 
              style={{
                right: showSummaryPeriod && getDataFromLocalStorage(MAINTENANCE)
                  ? '357px'
                  : showSummaryPeriod && !getDataFromLocalStorage(MAINTENANCE)
                  ? '326px'
                  : showSummaryPeriod == false && getDataFromLocalStorage(MAINTENANCE)
                  ? '94px'
                  : showSummaryPeriod == false && !getDataFromLocalStorage(MAINTENANCE)
                  ? '65px' 
                  : showSummaryPeriod == undefined && getDataFromLocalStorage(MAINTENANCE)
                  ? '357px' : ''
              }}
              >
                <Loader radius={4} length={5} lines={10} width={2} color={"#264C86"} />
              </div>
            ) : null
            }
    </div>

  )
}
const mapStateToProps = (state) => ({
  authReducer: state.authReducer,
  errorReducer: state.errorReducer,
});

export default withRouter(
  connect(mapStateToProps, {})(FiltersConatiner)
);
