import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from "@mui/material";
import { useEffect, useState } from "react";
import { getDecodeURI } from "../../../utils/util";
import { getHubDashboardServiceTableData } from '../../../actions/Users/authenticateHub';
import _ from "lodash";
import CircleIcon from '@mui/icons-material/Circle';

const ServiceTable = (props) => {
    const { startDate, endDate, getHubDashboardServiceTableData, getHubDashboardServiceTable, setHubDashboardCarrierServiceTableLoading, location, history } = props;

    let _q = getDecodeURI(location?.search);
    const [tableData, setData] = useState<any>([]);
    const [selectedDevice, setSelectedDevice] = useState(_q.hasOwnProperty('device_id') ? _q.device_id : '');

    useEffect(() => {
        const _interval = _q.interval == '15mins' ? '15m' : _q.interval ? _q.interval : '1h';
        const siteId = _q.siteId;
        const agentId = _q.agentId;
        if (siteId && agentId) {
            getHubDashboardServiceTableData(siteId, agentId, _interval, selectedDevice);
        }
    }, [startDate, endDate])

    useEffect(() => {
        if (!_.isEmpty(getHubDashboardServiceTable)) {
            const data = getHubDashboardServiceTable.hasOwnProperty('data') ? getHubDashboardServiceTable.data : {};
            if (!_.isEmpty(data)) {
                setData(data);
            } else {
                setData([]);
            }
        }
    }, [getHubDashboardServiceTable])

    return (
        <div className="config-container" style={{margin: '0px 25px'}}>
            <Grid container spacing={0.5} className="reports-login-history trends-usage-table-container">
                <Grid item xs={12} sm={12} md={12} lg={12} className="search-dropdown-limit-div trends-usage-search-container topbarMarginPaddingReset alignTopbarItemsCenter">
                    <Grid item xs={4} sm={4} md={4} lg={4} className="site-details-header usage-site-details-header">Service Table</Grid>
                </Grid>
                <Paper className="audit-container-data hub-container-data" style={{height: 'auto'}}>
                    <TableContainer className="audit-table-root hub-table-container" component={Paper}>
                        <Table size="small" aria-label="collapsible sticky table" stickyHeader style={{ tableLayout: 'fixed' }}>
                            <TableHead className="audit-data-head hub-table-header hub-topology-service-table-headers">
                                <TableRow>
                                    <TableCell className="fleetDataTable audit-data-header background-header-cell">SERVICE NAME</TableCell>
                                    <TableCell className="fleetDataTable audit-data-header background-header-cell">HUB NAME</TableCell>
                                    <TableCell className="fleetDataTable audit-data-header background-header-cell">WAN INTERFACE</TableCell>
                                    <TableCell className="fleetDataTable audit-data-header background-header-cell">PEER STATUS</TableCell>
                                    <TableCell className="fleetDataTable audit-data-header background-header-cell">AGENT ID</TableCell>
                                    <TableCell className="fleetDataTable audit-data-header background-header-cell">ACCESS NETWORK</TableCell>
                                    <TableCell className="fleetDataTable audit-data-header background-header-cell">DESTINATION NETWORK</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody className="audit-data-header audit-logs-font">
                                {!setHubDashboardCarrierServiceTableLoading && tableData?.length > 0 ? tableData?.map((row, i) => (
                                    <TableRow key={i} className="loginHistory-tableRow new-summary-usage-tr Table-Row-Opacity">
                                        <TableCell className="login-left-align inter-font Table-Cell-Opacity">
                                            <Tooltip title={row.service}><span>{row.service ? row.service : '-'}</span></Tooltip>
                                        </TableCell>
                                        <TableCell className="login-left-align inter-font Table-Cell-Opacity">
                                            <Tooltip title={row.hubName}><span>{row.hubName ? row.hubName : '-'}</span></Tooltip>
                                        </TableCell>
                                        <TableCell className="login-left-align inter-font Table-Cell-Opacity">
                                            <Tooltip title={row.wanId}><span>{row.wanId ? row.wanId : '-'}</span></Tooltip>
                                        </TableCell>
                                        <TableCell>
                                            <div className="each-row-content-status">
                                                <div className="hub-row-internetStatus hub-redirectToOtherTabs">
                                                    <CircleIcon id={row.status == 'Online' ? "greenDotSymbol" : row.status == 'Offline' ? "redDotSymbol" : "greyDotSymbol"} />
                                                    {/* {row.Internet_status == 'Online' ? 'Online' : row.Internet_status == 'Offline' ? 'Offline' : 'Unknown'} */}
                                                </div>
                                            </div>
                                        </TableCell>
                                        <TableCell className="login-left-align inter-font Table-Cell-Opacity">
                                            <Tooltip title={row.agent_id}><span>{row.agent_id ? row.agent_id : '-'}</span></Tooltip>
                                        </TableCell>
                                        <TableCell className="login-left-align inter-font Table-Cell-Opacity">
                                            <Tooltip title={row.src_net}><span>{row.src_net ? row.src_net : '-'}</span></Tooltip>
                                        </TableCell>
                                        <TableCell className="login-left-align inter-font Table-Cell-Opacity">
                                            <Tooltip title={row.dest_net}><span>{row.dest_net ? row.dest_net : '-'}</span></Tooltip>
                                        </TableCell>
                                    </TableRow>
                                )) : <TableRow>
                                    <TableCell colSpan={6} align='center' className="audit-logs-no-data">
                                        No Data Available
                                    </TableCell>
                                </TableRow>}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
            </Grid>
        </div>
    )
}

const mapStateToProps = (state) => ({
    getHubDashboardServiceTable: state.authReducer.getHubDashboardServiceTable,
    setHubDashboardCarrierServiceTableLoading: state.authReducer.setHubDashboardCarrierServiceTableLoading,
    startDate: state.authReducer.newSummaryStartDate,
    endDate: state.authReducer.newSummaryEndDate
});

export default withRouter(
    connect(mapStateToProps, { getHubDashboardServiceTableData })(ServiceTable)
);