import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Button, Grid, TextField, Select, Stack, FormGroup, FormControlLabel, Checkbox, ListItem } from "@mui/material";
import MenuItem from '@mui/material/MenuItem';
import { useEffect, useState } from "react";
import _ from "lodash";
import close from "../../../asset/image/close.svg";
import { getDecodeURI, getTimezoneCity } from "../../../utils/util";
import { getCaptiveDashSubscribersList, getCaptiveDashPlansList, getCaptiveDashIpMacsList } from "../../../actions/Users/authenticateCaptiveDahshboard";
import MultipleSelectChip from "./MultipleAutoComplete";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { Moment } from "moment-timezone";
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';

const FilterPopup = (props) => {
    const { authReducer, vessels, values, setValues, setFilterPopup, newSummaryStartDate, newSummaryEndDate, location, getCaptiveDashPlansList, getCaptiveDashSubscribersList, applyFilterClick, getCaptiveDashIpMacsList, getCDIpMacList, clearFilters } = props;
    const _q = getDecodeURI(location?.search);
    const { selectedSubscribers, selectedPlans, selectedUserNames, selectedIps, selectedMacs, selectedEventActions, selectedEventTypes, selectedPlanDurations, selectedPlanQuota, selectedPlanStatus, selectedPlanStartFrom, selectedPlanStartTo, selectedPlanEndFrom, selectedPlanEndTo, planAutoRenewal, excludeOrgEvents } = values;
    const { setSelectedSubscribers, setSelectedPlans, setSelectedUserNames, setSelectedIps, setSelectedMacs, setSelectedEventActions, setSelectedEventTypes, setSelectedPlanDurations, setSelectedPlanQuota, setSelectedPlanStatus, setSelectedPlanStartFrom, setSelectedPlanStartTo, setSelectedPlanEndFrom, setSelectedPlanEndTo, setPlanAutoRenewal, setExcludeOrgEvents } = setValues;
    const [subscriberList, setSubscribersList] = useState<any>([]);
    const [plansList, setPlansList] = useState<any>([]);
    const [planDurationsList, setPlanDurationsList] = useState<any>([]);
    const [planQuotaList, setPlanQuotaList] = useState<any>([]);
    const [userNamesList, setUserNamesList] = useState([])
    const [ipsList, setIpsList] = useState([])
    const [macsList, setMacsList] = useState([])
    const [actionsList, setActionsList] = useState([]);
    const [eventTypesList, setEventTypesList] = useState([]);
    const [isValidDate, setIsValidDate] = useState(true);

    useEffect(() => {
        const tabToTableMap = {
            sites: () => getCaptiveDashSubscribersList(vessels, newSummaryStartDate, newSummaryEndDate),
            subscribers: () => {
                getCaptiveDashSubscribersList(vessels, newSummaryStartDate, newSummaryEndDate);
                getCaptiveDashPlansList(vessels, newSummaryStartDate, newSummaryEndDate);
            },
            plans: () => getCaptiveDashPlansList(vessels, newSummaryStartDate, newSummaryEndDate),
            events: () => getCaptiveDashIpMacsList(vessels, newSummaryStartDate, newSummaryEndDate),
        };
        if (vessels?.length > 0) {
            tabToTableMap[_q.tab]?.();
        }
    }, [vessels, newSummaryStartDate, newSummaryEndDate])

    useEffect(() => {
        if (!_.isEmpty(authReducer.getCDSubscribersList)) {
            const subscribers = authReducer.getCDSubscribersList.hasOwnProperty('data') ? authReducer.getCDSubscribersList?.data : [];
            setSubscribersList(Array.from(new Set(subscribers?.map(value => value.username))))
        }
        if (!_.isEmpty(authReducer.getCDPlansList)) {
            const response = authReducer.getCDPlansList.hasOwnProperty('data') ? authReducer.getCDPlansList?.data : [];
            setPlansList(response[0]?.planName?.split(','));
            setPlanDurationsList(response[0]?.duration?.split(','));
            setPlanQuotaList(response[0]?.quota?.split(','));
        }
        if (!_.isEmpty(getCDIpMacList)) {
            const response = getCDIpMacList.hasOwnProperty('data') ? getCDIpMacList?.data : [];
            setUserNamesList(response[0]?.identifier?.split(','))
            setIpsList(response[0]?.ipAddress?.split(','));
            setMacsList(response[0]?.macAddress?.split(','));
            setActionsList(response[0]?.action?.split(','));
            setEventTypesList(response[0]?.type?.split(','));
        }
    }, [authReducer.getCDSubscribersList, authReducer.getCDPlansList, getCDIpMacList])

    const handleChangeSubscriber = (newValue) => {
        setSelectedSubscribers(newValue);
    }

    const handleChangePlan = (newValue) => {
        setSelectedPlans(newValue);
    }

    const handleChangeUserNames = (newValue) => {
        setSelectedUserNames(newValue);
    }

    const handleChangeIps = (newValue) => {
        setSelectedIps(newValue);
    }

    const handleChangeMacs = (newValue) => {
        setSelectedMacs(newValue);
    }

    const handleChangeEventActions = (event) => {
        const { name, checked } = event.target;
        setSelectedEventActions((prevSelected) =>
            checked ? [...prevSelected, name] : prevSelected.filter((action) => action !== name)
        );
    };

    const handleChangeEventTypes = (event) => {
        const { name, checked } = event.target;
        setSelectedEventTypes((prevSelected) =>
            checked ? [...prevSelected, name] : prevSelected.filter((type) => type !== name)
        );
    };

    const handleChangeDuration = (newValue) => {
        setSelectedPlanDurations(newValue);
    }

    const handleChangeQuota = (newValue) => {
        setSelectedPlanQuota(newValue);
    }

    const handleChangePlanStatus = (event) => {
        const { name, checked } = event.target;
        setSelectedPlanStatus(checked ? name : "");
    };

    const handleChangeStartFrom = (date: Moment | null) => {
        if (date) {
            if (date.isBefore(newSummaryStartDate) || date.isAfter(newSummaryEndDate)) {
                setIsValidDate(false);
            } else {
                setIsValidDate(true);
            }
            setSelectedPlanStartFrom(date);
        }
    }

    const handleChangeStartTo = (date: Moment | null) => {
        if (date) {
            if (date.isBefore(newSummaryStartDate) || date.isAfter(newSummaryEndDate)) {
                setIsValidDate(false);
            } else {
                setIsValidDate(true);
            }
            setSelectedPlanStartTo(date);
        }
    }

    const handleChangeEndFrom = (date: Moment | null) => {
        if (date) {
            if (date.isBefore(newSummaryStartDate) || date.isAfter(newSummaryEndDate)) {
                setIsValidDate(false);
            } else {
                setIsValidDate(true);
            }
            setSelectedPlanEndFrom(date);
        }
    }

    const handleChangeEndTo = (date: Moment | null) => {
        if (date) {
            if (date.isBefore(newSummaryStartDate) || date.isAfter(newSummaryEndDate)) {
                setIsValidDate(false);
            } else {
                setIsValidDate(true);
            }
            setSelectedPlanEndTo(date);
        }
    }

    const handleChangeAutoRenewal = (event) => {
        const { checked } = event.target;
        setPlanAutoRenewal(checked);
    }

    const handleExcludeOrgEvents = (event) => {
        const { checked } = event.target;
        setExcludeOrgEvents(checked);
    }

    const applyFilters = (key) => {
        switch (key) {
            case 'apply': {
                applyFilterClick(selectedSubscribers, selectedPlans, selectedPlanDurations, selectedPlanQuota, selectedPlanStatus, selectedPlanStartFrom, selectedPlanStartTo, selectedPlanEndFrom, selectedPlanEndTo, planAutoRenewal, selectedUserNames, selectedIps, selectedMacs, selectedEventActions, selectedEventTypes, excludeOrgEvents);
                setFilterPopup(false);
                break;
            }
            case 'clear': {
                applyFilterClick([], [], [], [], '', null, null, null, null, false, [], [], [], [], [], false);
                setFilterPopup(false);
                clearFilters();
                setSelectedSubscribers([]);
                setSelectedPlans([]);
                setSelectedUserNames([]);
            }
        }
    }

    return (
        <div className="Form">
            <Grid className="am-table">
                <Grid className="dash-filter-pop">
                    <Grid>
                        <Grid className="filter-clearFilter">
                            <div className="Filters_Title">Filters</div>
                            <img src={close} onClick={() => setFilterPopup(false)} alt="Close" className="activityReportsFilter-close" />
                        </Grid>
                        <Grid className="menuScroll">
                            <Grid sx={{ maxHeight: 'calc(100vh - 360px)', overflowY: 'auto' }}>
                                {_q.hasOwnProperty('tab') && (_q.tab == 'subscribers' || _q.tab == 'sites') && <Grid>
                                    <div className="rc--filter_section">
                                        <div className="rc--filter_header">
                                            <div className="rc--content_sub_headers-select">{'Subscribers'}</div>
                                        </div>
                                        <MultipleSelectChip defaultSelection={selectedSubscribers} handleSelection={handleChangeSubscriber} values={subscriberList} name={''} sx={{ maxWidth: "242px", width: "242px", marginTop: '12px' }} />
                                    </div>
                                </Grid>}
                                {_q.hasOwnProperty('tab') && (_q.tab == 'subscribers' || _q.tab == 'plans') && <Grid>
                                    <div className="rc--filter_section">
                                        <div className="rc--filter_header">
                                            <div className="rc--content_sub_headers-select">{'Plans'}</div>
                                        </div>
                                        <MultipleSelectChip defaultSelection={selectedPlans} handleSelection={handleChangePlan} values={plansList} name={''} sx={{ maxWidth: "242px", width: "242px", marginTop: '12px' }} />
                                    </div>
                                </Grid>}
                                {_q.hasOwnProperty('tab') && (_q.tab == 'plans') && <>
                                    <Grid className='captive-filters-each-field'>
                                        <div className="rc--filter_header">
                                            <div className="rc--content_sub_headers-select">{'Plan Duration'}</div>
                                        </div>
                                        <div className='rc--filter_autoComplete-input'>
                                            <MultipleSelectChip defaultSelection={selectedPlanDurations} handleSelection={handleChangeDuration} values={planDurationsList} name={''} sx={{ maxWidth: "150px", width: "150px" }} />
                                            <Grid item xs={1} className="unit-field-CD">
                                                <Select
                                                    fullWidth
                                                    size="small"
                                                    value={'days'}
                                                    displayEmpty
                                                >
                                                    <MenuItem value="days">Days</MenuItem>
                                                </Select>
                                            </Grid>
                                        </div>
                                    </Grid>
                                    <Grid className='captive-filters-each-field'>
                                        <div className="rc--filter_header">
                                            <div className="rc--content_sub_headers-select">{'Plan Quota'}</div>
                                        </div>
                                        <div className='rc--filter_autoComplete-input'>
                                            <MultipleSelectChip defaultSelection={selectedPlanQuota} handleSelection={handleChangeQuota} values={planQuotaList} name={''} sx={{ maxWidth: "150px", width: "150px" }} />
                                            <Grid item xs={1} className="unit-field-CD">
                                                <Select
                                                    fullWidth
                                                    size="small"
                                                    value={'gb'}
                                                    displayEmpty
                                                >
                                                    <MenuItem value="gb">GB</MenuItem>
                                                </Select>
                                            </Grid>
                                        </div>
                                    </Grid>
                                    <Grid className='captive-filters-each-field'>
                                        <div className="rc--filter_header">
                                            <div className="rc--content_sub_headers-select">{'Plan Status'}</div>
                                        </div>
                                        <div className='rc--filter_autoComplete-input'>
                                            <ListItem className="audit-logs-font auditFilterBorderColor" sx={{ padding: 0 }}>
                                                <Stack spacing={2}>
                                                    <FormGroup sx={{ padding: 0 }}>
                                                        <FormControlLabel sx={{ padding: 0 }} control={<Checkbox checked={selectedPlanStatus === "active"} onChange={handleChangePlanStatus} name="active" />} label="Active" className="auditFilterActions" />
                                                        <FormControlLabel control={<Checkbox checked={selectedPlanStatus === "expired"} onChange={handleChangePlanStatus} name="expired" />} className="auditFilterActions" label="Expired" />
                                                    </FormGroup>
                                                </Stack>
                                            </ListItem>
                                        </div>
                                    </Grid>
                                    <Grid className='captive-filters-each-field'>
                                        <div className="rc--filter_header">
                                            <div className="rc--content_sub_headers-select">{'Plan Start Date'}</div>
                                        </div>
                                        <div className='rc--filter_autoComplete-input'>
                                            <Stack direction='column'>
                                                <div className="siteCharts__filters-date-time-picker">
                                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                                        <DateTimePicker
                                                            ampm={false}
                                                            timezone={getTimezoneCity(authReducer.userTimezone)}
                                                            label='From'
                                                            value={selectedPlanStartFrom}
                                                            onChange={handleChangeStartFrom}
                                                            minDateTime={newSummaryStartDate}
                                                            maxDateTime={newSummaryEndDate}
                                                            className="audit-filter-inputs date-range-captive-filters"
                                                            viewRenderers={{
                                                                hours: renderTimeViewClock,
                                                                minutes: renderTimeViewClock,
                                                                seconds: renderTimeViewClock,
                                                            }}
                                                            slotProps={{
                                                                textField: {
                                                                    placeholder: "MM/DD/YYYY hh:mm",
                                                                    fullWidth: true,
                                                                    InputLabelProps: { shrink: true },
                                                                },
                                                            }}
                                                        />
                                                    </LocalizationProvider>
                                                </div>
                                                <div className="siteCharts__filters-date-time-picker">
                                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                                        <DateTimePicker
                                                            ampm={false}
                                                            timezone={getTimezoneCity(authReducer.userTimezone)}
                                                            label='To'
                                                            value={selectedPlanStartTo}
                                                            onChange={handleChangeStartTo}
                                                            minDateTime={newSummaryStartDate}
                                                            maxDateTime={newSummaryEndDate}
                                                            className="audit-filter-inputs date-range-captive-filters"
                                                            viewRenderers={{
                                                                hours: renderTimeViewClock,
                                                                minutes: renderTimeViewClock,
                                                                seconds: renderTimeViewClock,
                                                            }}
                                                            slotProps={{
                                                                textField: {
                                                                    placeholder: "MM/DD/YYYY hh:mm",
                                                                    fullWidth: true,
                                                                    InputLabelProps: { shrink: true },
                                                                },
                                                            }}
                                                        />
                                                    </LocalizationProvider>
                                                </div>
                                            </Stack>
                                        </div>
                                    </Grid>
                                    <Grid className='captive-filters-each-field'>
                                        <div className="rc--filter_header">
                                            <div className="rc--content_sub_headers-select">{'Plan End Date'}</div>
                                        </div>
                                        <div className='rc--filter_autoComplete-input'>
                                            <Stack direction='column'>
                                                <div className="siteCharts__filters-date-time-picker">
                                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                                        <DateTimePicker
                                                            ampm={false}
                                                            timezone={getTimezoneCity(authReducer.userTimezone)}
                                                            label='From'
                                                            value={selectedPlanEndFrom}
                                                            onChange={handleChangeEndFrom}
                                                            minDateTime={newSummaryStartDate}
                                                            maxDateTime={newSummaryEndDate}
                                                            className="audit-filter-inputs date-range-captive-filters"
                                                            viewRenderers={{
                                                                hours: renderTimeViewClock,
                                                                minutes: renderTimeViewClock,
                                                                seconds: renderTimeViewClock,
                                                            }}
                                                            slotProps={{
                                                                textField: {
                                                                    placeholder: "MM/DD/YYYY hh:mm",
                                                                    fullWidth: true,
                                                                    InputLabelProps: { shrink: true },
                                                                },
                                                            }}
                                                        />
                                                    </LocalizationProvider>
                                                </div>
                                                <div className="siteCharts__filters-date-time-picker">
                                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                                        <DateTimePicker
                                                            ampm={false}
                                                            timezone={getTimezoneCity(authReducer.userTimezone)}
                                                            label='To'
                                                            value={selectedPlanEndTo}
                                                            onChange={handleChangeEndTo}
                                                            minDateTime={newSummaryStartDate}
                                                            maxDateTime={newSummaryEndDate}
                                                            className="audit-filter-inputs date-range-captive-filters"
                                                            viewRenderers={{
                                                                hours: renderTimeViewClock,
                                                                minutes: renderTimeViewClock,
                                                                seconds: renderTimeViewClock,
                                                            }}
                                                            slotProps={{
                                                                textField: {
                                                                    placeholder: "MM/DD/YYYY hh:mm",
                                                                    fullWidth: true,
                                                                    InputLabelProps: { shrink: true },
                                                                },
                                                            }}
                                                        />
                                                    </LocalizationProvider>
                                                </div>
                                            </Stack>
                                        </div>
                                    </Grid>
                                    <Grid className='captive-filters-each-field'>
                                        <div className="rc--filter_header">
                                            <div className="rc--content_sub_headers-select">{'Plan Auto Renewal'}</div>
                                        </div>
                                        <div className='rc--filter_autoComplete-input'>
                                            <ListItem className="audit-logs-font auditFilterBorderColor" sx={{ padding: 0 }}>
                                                <Stack spacing={2}>
                                                    <FormGroup sx={{ padding: 0 }}>
                                                        <FormControlLabel sx={{ padding: 0 }} control={<Checkbox name="yes" checked={planAutoRenewal} onChange={handleChangeAutoRenewal} />} label="Yes" className="auditFilterActions" />
                                                    </FormGroup>
                                                </Stack>
                                            </ListItem>
                                        </div>
                                    </Grid>
                                </>}
                                {_q.hasOwnProperty('tab') && (_q.tab == 'events') && <>
                                    <Grid>
                                        <div className="rc--filter_section">
                                            <div className="rc--filter_header">
                                                <div className="rc--content_sub_headers-select">{'Username'}</div>
                                            </div>
                                            <MultipleSelectChip defaultSelection={selectedUserNames} handleSelection={handleChangeUserNames} values={userNamesList} name={''} sx={{ maxWidth: "242px", width: "242px", marginTop: '12px' }} />
                                        </div>
                                    </Grid>
                                    <Grid>
                                        <div className="rc--filter_section">
                                            <div className="rc--filter_header">
                                                <div className="rc--content_sub_headers-select">{'IP Address'}</div>
                                            </div>
                                            <MultipleSelectChip defaultSelection={selectedIps} handleSelection={handleChangeIps} values={ipsList} name={''} sx={{ maxWidth: "242px", width: "242px", marginTop: '12px' }} />
                                        </div>
                                    </Grid>
                                    <Grid>
                                        <div className="rc--filter_section">
                                            <div className="rc--filter_header">
                                                <div className="rc--content_sub_headers-select">{'MAC Address'}</div>
                                            </div>
                                            <MultipleSelectChip defaultSelection={selectedMacs} handleSelection={handleChangeMacs} values={macsList} name={''} sx={{ maxWidth: "242px", width: "242px", marginTop: '12px' }} />
                                        </div>
                                    </Grid>
                                    <Grid className='captive-filters-each-field'>
                                        <div className="rc--filter_header">
                                            <div className="rc--content_sub_headers-select">Action</div>
                                        </div>
                                        <div className='rc--filter_autoComplete-input'>
                                            <ListItem className="audit-logs-font auditFilterBorderColor" sx={{ padding: 0 }}>
                                                <Stack spacing={2}>
                                                    <FormGroup sx={{ padding: 0 }}>
                                                        {actionsList.map((action) => (
                                                            <FormControlLabel sx={{ padding: 0 }} control={
                                                                <Checkbox name={action}
                                                                    checked={selectedEventActions.includes(action)}
                                                                    onChange={handleChangeEventActions}
                                                                />}
                                                                label={action} className="auditFilterActions" />
                                                        ))}
                                                    </FormGroup>
                                                </Stack>
                                            </ListItem>
                                        </div>
                                    </Grid>
                                    <Grid className='captive-filters-each-field'>
                                        <div className="rc--filter_header">
                                            <div className="rc--content_sub_headers-select">Event Type</div>
                                        </div>
                                        <div className='rc--filter_autoComplete-input'>
                                            <ListItem className="audit-logs-font auditFilterBorderColor" sx={{ padding: 0 }}>
                                                <Stack spacing={2}>
                                                    <FormGroup sx={{ padding: 0 }}>
                                                        {eventTypesList.map((type) => (
                                                            <FormControlLabel sx={{ padding: 0 }} control={
                                                                <Checkbox name={type}
                                                                    checked={selectedEventTypes.includes(type)}
                                                                    onChange={handleChangeEventTypes} />}
                                                                label={type} className="auditFilterActions"
                                                            />
                                                        ))}
                                                    </FormGroup>
                                                </Stack>
                                            </ListItem>
                                        </div>
                                    </Grid>
                                    <Grid className='captive-filters-each-field'>
                                        <div className="rc--filter_header">
                                            <div className="rc--content_sub_headers-select">{'Exclude Org Events'}</div>
                                        </div>
                                        <div className='rc--filter_autoComplete-input'>
                                            <ListItem className="audit-logs-font auditFilterBorderColor" sx={{ padding: 0 }}>
                                                <Stack spacing={2}>
                                                    <FormGroup sx={{ padding: 0 }}>
                                                        <FormControlLabel sx={{ padding: 0 }} control={<Checkbox checked={excludeOrgEvents} onChange={handleExcludeOrgEvents} name="exclude" />} label="Exclude" className="auditFilterActions" />
                                                    </FormGroup>
                                                </Stack>
                                            </ListItem>
                                        </div>
                                    </Grid>
                                </>}
                            </Grid>
                        </Grid>
                    </Grid>
                    <div className="clearApplyFilters">
                        <Button className="clearFiltersButton" onClick={() => applyFilters('clear')}>Clear Filter</Button>
                        <Button className={isValidDate ? "usage-applyFilterBtn" : 'usage-applyFilterBtn-disable'} onClick={() => applyFilters('apply')} disabled={!isValidDate}>Apply Filter</Button>
                    </div>
                </Grid>
            </Grid>
        </div>
    )
}

const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
    newSummaryStartDate: state.authReducer.newSummaryStartDate,
    newSummaryEndDate: state.authReducer.newSummaryEndDate,
    getCDIpMacList: state.authReducer.getCDIpMacList,
});

export default withRouter(
    connect(mapStateToProps, { getCaptiveDashPlansList, getCaptiveDashSubscribersList, getCaptiveDashIpMacsList })(FilterPopup)
);