import React, { Fragment, useState, useEffect } from "react";
import "leaflet-draw";
import { Box, FormGroup, RadioGroup, Menu, Button, Grid, IconButton, InputAdornment, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip, Typography, Divider, FormControlLabel, Radio } from "@mui/material";
import _ from "lodash";
import { getDecodeURI } from "../../utils/util";
import { useHistory, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import customPlanIcon from "../../asset/image/CustomPlanIcon.svg";
import starlinkPlanIcon from "../../asset/image/StarlinkPlanIcon.svg";
import orgPoolIcon from "../../asset/image/OrgPoolIcon.svg";
import { AddCircle } from "@material-ui/icons";
import TuneIcon from "@mui/icons-material/Tune";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import close from "../../asset/image/close.svg";
import "leaflet/dist/leaflet.css";
import "./starlinkPlans.css";
import ArrowCircleDownIcon from '@mui/icons-material/ExpandMore';
import ArrowCircleUpIcon from '@mui/icons-material/ExpandLess';
import CardsContents from "./CardsContents";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { getStarlinkTemplate, getStarlinkPlanHierarchy, getStarlinkPlanTopWidget, clearComponentData } from "../StarlinkPooled/slice";
import EditActionsPopup from "./EditActionsPopup";
import AssignServiceLine from "./AssignServiceLine";
import EditActionsCreatePlanPopup from "./EditActionsCreatePlanPopup";


const StarlinkPlans = (props) => {
    const { location, starlinkLocations, authReducer, handleChangeTab, getStarlinkTemplate, starlinkPlanTemplate, getStarlinkPlanHierarchy, clearComponentData, starlinkPlanHierarchy, servicePlanTopWidget, getStarlinkPlanTopWidget } = props;
    const history = useHistory();

    const queryParams = location?.search
        ? new Map(Object.entries(getDecodeURI(location.search)))
        : new Map();

        const [confirmDialog, setConfirmDialog] = useState({
            isOpen: false,
            title: "",
            subTitle: "",
            onConfirm: {},
          });

    const _q = getDecodeURI(location?.search || "");
    const [createPlanDialog, setCreatePlanDialog] = useState<boolean>(false);
    const [loading, setLoading] = useState(false);
    const [anchorElRef, setAnchorElRef] = useState(null);
    const [searchValue, setSearchValue] = useState("");
    const [isCardExpanded, setIsCardExpanded] = useState(true);
    const [page, setPage] = useState(_q?.page ? parseInt(_q.page, 10) : 1);
    const [limit, setLimit] = useState(_q?.limit ? parseInt(_q.limit, 10) : 10);
    const [filterPopup, setFilterPopup] = useState(false);
    const [openCreateDialog, setOpenCreateDialog] = useState(false);
    const [openEditDialog, setOpenEditDialog] = useState(false);
    const [planPoolToggle, setPlanPoolToggle] = useState("Plan")
    const [openServiceLineDialog, setOpenServiceLineDialog] = useState(false)
    const [cardContent, setCardContent] = useState<any>([])
    const [currentSubPool, setCurrentSubPool] = useState<any>({
        poolName: '',
        poolSize: '',
        unit: 'tb',
        startDate: null,
        duration: 'monthly',
        isRecurring: true,
        selectedDp: null,
        poolInfo: '',
        assignedBy: '',
        dpId: '',
        data: null,
    });
  
    const planData = starlinkPlanHierarchy
    const [assignOrgPopup, setAssignOrgPopup] = useState(false)
    const [selectedDp, setSelectedDp] = useState<any>(null);
    const analyticsData = props.analyticsData || { tabs: [{ tabValue: "pppp", subTabs: [{ tabName: "pp" }] }] };
    const activeTab = props.activeTab || "";
    const subTab = props.subTab || "";
    const [expandedRows, setExpandedRows] = useState({});
    const [open, setOpen] = useState(false)
    const [selectedRow, setSelectedRow] = useState(null)
    const defaultFilters = [
        { name: "All", label: "all", selected: true },
        { name: "Starlink Only", label: "strOnly", selected: false },
        { name: "Custom Only", label: "cstmOnly", selected: false },
      ];

    const [selectedFilter, setSelectedFilter] = useState("all"); 
    const [filter, setFilter] = useState("all")

    const toggleExpand = () => {
        setIsCardExpanded((prev) => !prev);
    };

    const getPlanFilter = (query) =>{
        if(query === 'hierarchy'){
            if(selectedFilter === "strOnly") return "where account_type = 'standard'"
            else if(selectedFilter === "cstmOnly") return "where account_type = 'pooled'"
            else return ""
        }else{
           if(selectedFilter === "strOnly") return "where plan_type = 'standard'"
        else if(selectedFilter === "cstmOnly") return "where plan_type = 'pooled'"
        else return "" 
        }
        
    }

    useEffect(() => {
        clearComponentData()
        getStarlinkTemplate({ dpId: authReducer.selectedOu?.id, plan_filter: getPlanFilter("template"), search: searchValue })
        getStarlinkPlanHierarchy({ dpId: authReducer.selectedOu?.id,plan_filter: getPlanFilter("hierarchy"), search: searchValue })
        getStarlinkPlanTopWidget({dp_id: authReducer.selectedOu?.id, plan_filter: getPlanFilter("widget")})
    }, [authReducer.selectedOu?.id, selectedFilter, searchValue])

    useEffect(()=>{

        if(!_.isEmpty(servicePlanTopWidget)){
           let  cardContent = [
                {
                    heading: "Accounts",
                    leftHeading:"",
                    leftValue: servicePlanTopWidget?.accounts || 0,
                    rightHeading: "",
                    rightValue: "",
                },
        
                {
                    heading: "Pools",
                    leftHeading:"",
                    leftValue: servicePlanTopWidget?.pools || 0,
                    rightHeading: "",
                    rightValue: "",
                },
        
                {
                    heading: "Plans",
                    leftHeading:"Standard",
                    leftValue: servicePlanTopWidget?.standard_plans || 0,
                    rightHeading: "Custom",
                    rightValue: servicePlanTopWidget?.custom_plans || 0,
                },
        
                {
                    heading: "Service Lines",
                    leftHeading:"",
                    leftValue: servicePlanTopWidget?.service_lines || 0,
                    rightHeading: "",
                    rightValue: "",
                },
               
            ];
            setCardContent(cardContent);
        }

    },[servicePlanTopWidget])

    const handleSubPoolChange = (field: any, value: any) => {
        setCurrentSubPool({
            ...currentSubPool,
            [field]: value,
        });
    };

    const handleExpandClick = (id) => {
        setExpandedRows((prev) => ({
            ...prev,
            [id]: !prev[id],
        }));
    };

    const truncateText = (text, maxLength = 36) => {
        if (!text) return '';
        return text.length > maxLength ? `${text.slice(0, maxLength)}...` : text;
      };

    const renderRow = (row, level = 0) => (
        <React.Fragment key={row.id}>
            <TableRow>
                <TableCell style={{ paddingLeft: `${level * 20}px`, whiteSpace: "nowrap", display:"flex", alignItems:"center" }}>
                    { row.children?.length > 0  && row?.children[0] !== null && (
                        <IconButton size="small" style={{paddingTop:"0px", paddingBottom:"0px"}}  onClick={() => {
                            handleExpandClick(row?.uid)}}>
                            {expandedRows[row.uid] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        </IconButton>
                    )}
                    <Grid style={{paddingLeft: `${level * 15}px`, display:"flex", alignItems:'center'}}>
                    {row?.icon ? <img className="icon-cursor" style={{paddingRight:"10px"}} src={row?.icon === "CustomPlanIcon" ?  customPlanIcon : row?.icon === "orgPoolIcon" ? orgPoolIcon : starlinkPlanIcon} /> : null}
                    {row?.account_type ? row?.account_type.charAt(0).toUpperCase() + row?.account_type.slice(1).toLowerCase() + " | " : ""}
                    <Tooltip title={row?.account_name || row?.custom_plan_name || row?.pool_name || row?.plan_name || ''}>
                        <span>
                             {truncateText(row?.account_name || row?.custom_plan_name || row?.pool_name || row?.plan_name || '')}
                        </span>
                    </Tooltip>
               
                    </Grid>
                    </TableCell>
    
                <TableCell align="center">{ row?.unit === 'tb' ?  row?.size*1000 : row?.size}</TableCell>
                <TableCell align="center"  >{row.service_lines}</TableCell>
    
                <TableCell align="right">
                    { (row?.icon === "orgPoolIcon" || row?.account_type === "pooled")  ?
                    <Tooltip title={"Add"}>
                        <span>
                            <IconButton size='small' style={{paddingTop:"0px", paddingBottom:"0px"}} onClick={() => { setOpen(true); setSelectedRow(row) }}>
                                <AddCircle style={{ color: "#244C84" }} fontSize="small" />
                            </IconButton>
                        </span>
                    </Tooltip> : null }
                </TableCell>
            </TableRow>
    
            {expandedRows[row?.uid] && row?.children?.length > 0 && (
                row?.children?.map((child) => renderRow(child, level + 1))
            )}
        </React.Fragment>
    );
    
    const callPlanHierarchyAPI = () =>{
        clearComponentData()
        getStarlinkPlanHierarchy({ dpId: authReducer.selectedOu?.id,plan_filter: getPlanFilter("hierarchy"), search: searchValue })
    }

    const handleCloseCreateDialog = () =>{
        clearComponentData()
        getStarlinkTemplate({ dpId: authReducer.selectedOu?.id, plan_filter: getPlanFilter("template"), search: searchValue })
        setCreatePlanDialog(false)
    }

    const handleFilterChange = (filter) => {
        setSelectedFilter(filter);
        setFilterPopup(false)
    };

    const filterPopClick = (e:any) => {
        setAnchorElRef(e.currentTarget);
        setFilterPopup(true);
      };

    return (
        <div>
            <Grid className="Analytics-Dashboard-Container">
                <Grid container spacing={1}>
                    <Grid item >
                        <Box >
                            <Fragment>
                                <Grid item >
                                    <Button
                                        variant="outlined"
                                        startIcon={<TuneIcon id="filterIcon" />}
                                        onClick={filterPopClick}
                                        id="filterButton"
                                    >
                                        Filters
                                    </Button>
                                </Grid>
                                {filterPopup ? (
                            <Menu
                            className="rc--filter_menu_container_dashboard menuScroll strMaps-filter"
                            anchorEl={anchorElRef}
                                open={filterPopup}
                               
                            >
                                <Grid className="sitesFilterHeaderMargin">
                                    <div className="filter-header">Filters</div>
                                    <img
                                        src={close}
                                        onClick={() => setFilterPopup(false)}
                                        alt="Close"
                                        className="activityReportsFilter-close"
                                    />
                                </Grid>
                                <Grid className="filter-divider-width">
                                    <Divider className="filter-border-color" />
                                </Grid>
                                <Grid className="usageFilterComponent">
                                    <div className="Form">
                                        <Grid className="am-table">
                                            <Grid className="dash-filter-pop">
                                                <Grid>
                                                    <Grid className="sitesFiltersPadding">
                                                        <Grid className="internetr-status-header-paddingBottom">
                                                            <span className="internet-status-header">Plan Type</span>
                                                        </Grid>
                                                        <FormGroup
                                                            sx={{ padding: 0 }}
                                                            className="filterComponents"
                                                        >
                                                            <RadioGroup>
                                                                {defaultFilters?.map((status: any) => (
                                                                    <FormControlLabel
                                                                        key={`form-filter-${status.label}`}
                                                                        className="sitesInternetStatusOptions starlinkMap-filterPopup"
                                                                        control={
                                                                            <Radio
                                                                                name="filterOptions"
                                                                                checked={filter === status.label} 
                                                                                onChange={() => {  setFilter(status?.label)}}
                                                                            />
                                                                        }
                                                                        label={status.name}
                                                                    />
                                                                ))}
                                                            </RadioGroup>
                                                        </FormGroup>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </Grid>
                                <Grid className="apply-filter-divider-width">
                                    <Divider className="filter-border-color" />
                                </Grid>
                                <div className="clearFiletrBtnDiv margin-left-filter">
                                    <Button
                                        className="clearFiletrBtnUsage padding-right-0"
                                        onClick={() => { setFilterPopup(false) }}
                                    >
                                        Cancel
                                    </Button>
                                    <Grid className="dash-filter-pop">
                                        <Button
                                            className="usage-applyFilterBtn"
                                            onClick={() => { handleFilterChange(filter) }}
                                        >
                                            Apply Filter
                                        </Button>
                                    </Grid>
                                </div>
                            </Menu>
                        ) : null}
                            </Fragment>
                        </Box>
                    </Grid>

                  
                    <Grid item md={2} lg={2} className="usage-search-border-color">
                        <Box className="searchbarBox">
                            <TextField
                                id="outlined-basic"
                                variant="outlined"
                                placeholder="Filter Search"
                                autoComplete="off"
                                size="small"
                                value={searchValue}
                                onChange={(e) => setSearchValue(e.target.value)}
                                InputLabelProps={{ className: "serachLabel1" }}
                                InputProps={{
                                    className: "searchbarInputs",
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            {!searchValue ? (
                                                <SearchIcon className="searchbarIconsPosition" />
                                            ) : (
                                                <CloseIcon
                                                    className="searchbarIconsPosition"
                                                    onClick={() => setSearchValue("")}
                                                />
                                            )}
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Box>
                    </Grid>

                    <Grid item md={8} lg={8} className="analytics-sub-tabs-container">
                        <Grid container spacing={1} gap={1}>
                            <Grid item> 
                                <Button
                                // style={{ marginRight: "10px" }}
                                className="analytics-sub-tabs"
                                id={selectedFilter === "all" ? "selectedTab-background" : ""}
                                onClick={() => handleFilterChange("all")}
                            >
                                All
                            </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                // style={{ marginRight: "10px" }} 
                                className="analytics-sub-tabs"
                                id={selectedFilter === "strOnly" ? "selectedTab-background" : ""}
                                onClick={() => handleFilterChange("strOnly")}
                            >
                                Starlink Only
                            </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                className="analytics-sub-tabs"
                                id={selectedFilter === "cstmOnly" ? "selectedTab-background" : ""}
                               onClick={() => handleFilterChange("cstmOnly")}
                            >
                                Custom Only
                            </Button>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item md={0.5} lg={0.5} className="download-expand-icons-container">
                        <div>{isCardExpanded ? <ArrowCircleUpIcon className="starlinkMap-icon" onClick={() => { toggleExpand() }} /> : <ArrowCircleDownIcon className="starlinkMap-icon" onClick={() => { toggleExpand() }} />}</div>
                    </Grid>
                </Grid>

                <Grid style={{ paddingTop: "15px", paddingBottom: "15px" }}>
                    {isCardExpanded && <CardsContents cardContent={cardContent}  />}
                </Grid>

                <Grid container spacing={2} style={{ display: "flex", }}>
                    <Grid item xs={6}>
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", paddingBottom: "10px" }}>
                            <Typography component="div" className="starlink--base--font_1x">
                                Plan Hierarchy
                            </Typography>

                            <div style={{ display: "flex", gap: "8px", alignItems: "center" }}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    className="starlink--button_contained--primary"
                                    style={{ height: "30px", minWidth: "120px" }}
                                    onClick={() => { setOpenServiceLineDialog(true) }}
                                >
                                    Assign Service Line
                                </Button>

                                {/* <div
                                    className="download-summary-btn"
                                    style={{
                                        width: "30px",
                                        height: "30px",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        borderRadius: "4px",
                                        cursor: "pointer"
                                    }}
                                    onClick={() => { }}
                                >
                                    <img className="downloadImg" src={Download} alt="Download" style={{ width: "20px", height: "20px" }} />
                                </div> */}
                            </div>
                           
                        </div>

                        <TableContainer component={Paper} className="starlink--table_container strMap-table" style={{height:"525px"}}>
                            <Table stickyHeader size="small" aria-label="plan-hierarchy-table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            <span className="font-wt-900">Plan</span>
                                        </TableCell>
                                        <TableCell>
                                            <span className="font-wt-900">Size(GB)</span>
                                        </TableCell>
                                        <TableCell>
                                            <span className="font-wt-900">Service Lines</span>
                                        </TableCell>
                                        <TableCell>
                                            <span className="font-wt-900"></span>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {planData?.length > 0 ? (
                                        planData?.map((row) => renderRow(row))
                                    ) : (
                                        <TableRow>
                                            <TableCell colSpan={4} align="center">
                                                No Plans available.
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>

                    <Grid item xs={6}>
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", paddingBottom: "10px" }}>
                            <Typography component="div" className="starlink--base--font_1x">
                                Defined Pools & Plans
                            </Typography>

                            <div style={{ display: "flex", gap: "8px", alignItems: "center" }}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    className="starlink--button_contained--primary"
                                    style={{ height: "30px", minWidth: "120px" }}
                                    onClick={() => { setCreatePlanDialog(true) }}
                                >
                                    CREATE
                                </Button>

                                {/* <div
                                    className="download-summary-btn"
                                    style={{
                                        width: "30px",
                                        height: "30px",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        borderRadius: "4px",
                                        cursor: "pointer"
                                    }}
                                    onClick={() => { }}
                                >
                                    <img className="downloadImg" src={Download} alt="Download" style={{ width: "20px", height: "20px" }} />
                                </div> */}
                            </div>
                        </div>


                        <TableContainer component={Paper} className="starlink--table_container strMap-table" style={{height:"525px"}}>
                            <Table stickyHeader size="small" aria-label="defined-pools-table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell><span className="font-wt-900"></span></TableCell>
                                        <TableCell><span className="font-wt-900">Name</span></TableCell>
                                        <TableCell><span className="font-wt-900">Type</span></TableCell>
                                        <TableCell><span className="font-wt-900">Size(GB)</span></TableCell>
                                        <TableCell><span className="font-wt-900">Duration</span></TableCell>
                                        <TableCell><span className="font-wt-900">Assignments</span></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {starlinkPlanTemplate && starlinkPlanTemplate?.length > 0 ? (
                                        starlinkPlanTemplate?.map((pool: any) => (
                                            <TableRow key={pool.id}>
                                                <TableCell><img className="icon-cursor" src={pool?.type === "customPlan" ?  customPlanIcon : pool?.type === "orgPool" ? orgPoolIcon : starlinkPlanIcon} title={pool?.type === "customPlan" ?  "Custom Plan" : pool?.type === "orgPool" ? "Org Pool" : "Starlink Plan"} /></TableCell>
                                                <TableCell>{pool.plan_name}</TableCell>
                                                <TableCell>{pool?.type?.replace(/([A-Z])/g, " $1").replace(/^./, s => s.toUpperCase()).trim()}</TableCell>
                                                <TableCell>{pool.plan_size_gb}</TableCell>
                                                <TableCell>{pool?.duration ? pool?.duration?.charAt(0).toUpperCase() + pool?.duration?.slice(1).toLowerCase() : "-"}</TableCell>
                                                <TableCell>{pool.assignments}</TableCell>
                                            </TableRow>
                                        ))
                                    ) : (
                                        <TableRow>
                                            <TableCell colSpan={5} align="center">No templates available.</TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
            </Grid>

            <EditActionsPopup open={open} selectedPlan={selectedRow} setOpen={setOpen} setConfirmDialog={setConfirmDialog} confirmDialog={confirmDialog} callPlanHierarchyAPI={callPlanHierarchyAPI} starlinkPlanTemplate={starlinkPlanTemplate} />

            <AssignServiceLine open={openServiceLineDialog} setOpen={setOpenServiceLineDialog}  planData={planData} callPlanHierarchyAPI={callPlanHierarchyAPI}  />

            <EditActionsCreatePlanPopup createPlanDialog={createPlanDialog} setCreatePlanDialog={handleCloseCreateDialog} callPlanHierarchyAPI={callPlanHierarchyAPI}  />

            
        </div>
    );
};



const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
    errorReducer: state.errorReducer,
    dpIds: state.starlinkCloud?.dpIds || [],
    warnings: state.starlinkDashboard?.warnings || [],
    createPlanTemplate: state.starlinkPooled.createPlanTemplate,
    starlinkPlanTemplate: state.starlinkPooled.starlinkPlanTemplate,
    starlinkPlanHierarchy: state.starlinkPooled.starlinkPlanHierarchy,
    servicePlanTopWidget: state.starlinkPooled.servicePlanTopWidget
});

export default withRouter(connect(mapStateToProps, {
    getStarlinkTemplate,
    getStarlinkPlanHierarchy,
    getStarlinkPlanTopWidget, 
    clearComponentData
})(StarlinkPlans));
