import { withRouter } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { useState, useEffect, useRef } from "react";
import { getContentFilterLogs } from "../../../UserScreen/CustomContentFilter/slice";
import _ from "lodash";
import { Grid, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, InputAdornment, TextField, Box, Paper, Tooltip, Button } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import { Pagination } from "../../../components/Pagination";
import { convertDateTimeIntoTimezone, getDecodeURI, getEncodedURI } from "../../../utils/util";
import ascSortingSelected from "../../../asset/image/ascSortingSelected.svg";
import descSortingSelected from "../../../asset/image/descSortingSelected.svg";
import AscSort from "../../../asset/image/AscSort.svg";
import DescSort from "../../../asset/image/DescSort.svg";
import Download from '../../../asset/image/DownloadUsage.svg';
import DownloadCSV, { clearCsvDownload } from "../../DownloadCSV";
import { CsvParameters, downloadAsCSV } from "../../../actions/Users/authenticateCsvDownload";
import { JsonDataTooltip } from "../../JSONTooltip/JsonTooltip";
import { MMDDYYYYHMMSS_DATE_FORMAT_24_HRS } from "../../../utils/constants";

const ContentFilterLogs = (props) => {
    const { getContentFilterLogs, contentFilterLogs, gettingContentFilters, csvDataDownloded, csvDownloadId, downloadAsCSV, location, history, userTimezone, selectedOu } = props;

    const _q = getDecodeURI(location?.search);
    const DEFAULT_PAGE_SIZE = 10;
    const [searchValue, setSearchValue] = useState('');
    const [page, setPage] = useState(_q.hasOwnProperty('page') ? parseInt(_q.page) : 1);
    const [limit, setLimit] = useState(_q.hasOwnProperty('limit') ? parseInt(_q.limit) : DEFAULT_PAGE_SIZE);
    const ENTER_KEY_CODE = "Enter";
    const [tableData, setTableData] = useState<any>([]);
    const [count, setCount] = useState(0);
    const ORG_NAME = 'organization';
    const SITE_NAME = 'site_name';
    const DEVICE_NAME = 'device_name';
    const CONTENT_FILTER_NAME = 'content_filter_name';
    const STATUS = 'status';
    const INITIATED_BY = 'action_initiated_by';
    const CREATED_AT = 'created_at';
    const ACTION = 'action';
    const ASC_ORDER = "asc";
    const DESC_ORDER = "desc";
    const [sort, setSort] = useState<any>(_q.hasOwnProperty("sort") ? _q.sort : CREATED_AT);
    const [sortOrder, setSortOrder] = useState<any>(_q.hasOwnProperty("sortOrder") ? _q.sortOrder : DESC_ORDER);
    const dispatch = useDispatch();
    const searchTimeoutRef = useRef<NodeJS.Timeout>();

    useEffect(() => {
        if(selectedOu?.id) {
            getContentFilterLogs(1, limit, sort, sortOrder, searchValue, selectedOu?.id)
        }
    }, [selectedOu])

    useEffect(() => {
        if (!_.isEmpty(contentFilterLogs)) {
            const data = contentFilterLogs.hasOwnProperty('data') ? contentFilterLogs.data : [];
            setCount(data?.[0]?.['total_count']);
            setTableData(data);
        } else{
            setTableData([]);
        }
    }, [contentFilterLogs])

    const convertToCsv = (data: any[][]) => {
        const [_columns, ...rows] = data;
        const csvRows: any[] = [];
    
        const flattenObject = (obj: any, prefix: string = ''): string[] => {
            let result: string[] = [];
            for (let key in obj) {
                if (obj.hasOwnProperty(key)) {
                    const newKey = prefix ? `${prefix}.${key}` : key;
                    if (typeof obj[key] === 'object' && obj[key] !== null) {
                        result = result.concat(flattenObject(obj[key], newKey));
                    } else {
                        result.push(`${newKey}: '${obj[key]}'`);
                    }
                }
            }
            return result;
        };

        const formatRetry = (retryObj: any): string => {
            if (retryObj && typeof retryObj === 'object') {
                const formattedRetry = Object.entries(retryObj)
                    .map(([key, value]: [string, any]) => {
                        if (typeof value === 'object') {
                            const nestedFormatted = Object.entries(value)
                                .map(([nestedKey, nestedValue]) => `${nestedKey}: '${nestedValue}'`)
                                .join(', ');
                            return `${key} { ${nestedFormatted} }`;
                        }
                        return `${key}: '${value}'`;
                    })
                    .join(', ');
                return `retry: { ${formattedRetry} }`;
            }
            return '';
        };
    
        rows.forEach(row => {
            let osDetails = row[3];
            if (osDetails && typeof osDetails === 'string') {
                try {
                    osDetails = JSON.parse(osDetails);
                } catch (error) {
                    osDetails = {};
                }
            }
    
            const formattedRetry = osDetails && osDetails.retry ? formatRetry(osDetails.retry) : '';
    
            const flattenedOsDetails = osDetails && typeof osDetails === 'object'
                ? flattenObject(osDetails, '').filter(key => !key.startsWith('retry')).join('\n')
                : '';
    
            const retryCount = osDetails?.retry_count ? `retry_count: '${osDetails.retry_count}'` : '';
            const siteId = osDetails?.siteId ? `siteId: '${osDetails.siteId}'` : '';
    
            const formattedOsColumn = flattenedOsDetails || formattedRetry || retryCount || siteId
                ? `"${(flattenedOsDetails + '\n' + formattedRetry + '\n' + retryCount + '\n' + siteId).replace(/"/g, '""')}"`
                : '""';
    
            csvRows.push([convertDateTimeIntoTimezone(row[4], userTimezone, MMDDYYYYHMMSS_DATE_FORMAT_24_HRS), row[11], row[9], row[10], row[8], formattedOsColumn, row[6], row[7], row[2]]);
        });
    
        return [['CREATED AT', 'ORGANIZATION', 'SITE', 'DEVICE', 'CONTENT FILTER NAME', 'TRAFIC POLICY', 'ACTION', 'INITIATES BY', 'STATUS'], ...csvRows];
    };

    useEffect(() => {
        if (csvDataDownloded && csvDataDownloded.length > 0) {
            if (csvDownloadId === 'content-filter-logs') {
                const [columns, _countRow, ...rows] = csvDataDownloded;
                DownloadCSV(convertToCsv([columns, ...rows]), {
                    formatters: {
                        0: 'ESC-COMMA'
                    }
                });
                return () => {
                    dispatch(clearCsvDownload())
                }
            }
        }
    }, [csvDataDownloded])

    const doNavigate = (params) => {
        history.push({ pathname: location.pathname, search: `?${getEncodedURI(params)}` });
    }

    const searchBoxKeyDown = (event: any) => {
        if (event && event.key === ENTER_KEY_CODE) {
            doSearch(searchValue);
        }
    }

    const doSearch = (value: string) => {
        let params: any = getDecodeURI(location?.search);
        value ? params.search = value : delete params.search;
        delete params.page;
        const Page = 1;
        setPage(1);
        doNavigate(params);
        getContentFilterLogs(Page, limit, sort, sortOrder, value, selectedOu?.id)
    };

    const Sorting = (field, order) => {
        let params: any = getDecodeURI(location?.search);
        delete params.page;
        params.sort = field;
        params.sortOrder = order;
        const Page = 1;
        setPage(1);
        setSort(field);
        setSortOrder(order);
        doNavigate(params);
        getContentFilterLogs(Page, limit, field, order, searchValue, selectedOu?.id)
    }

    const setSorting = (event: any, field: any, order: any,) => {
        if (event && event.currentTarget) {
            Sorting(field, order)
        }
    };

    const switchSorting = (event: any, field: any) => {
        let order = ASC_ORDER;
        if (sort === field) {
            order = ASC_ORDER === sortOrder ? DESC_ORDER : ASC_ORDER;
        }
        Sorting(field, order)
    };

    const handleChangePage = (e, value) => {
        setPage(value);
        let params: any = getDecodeURI(location?.search);
        value == 1 ? delete params.page : params.page = value;
        doNavigate(params);
        getContentFilterLogs(value, limit, sort, sortOrder, searchValue, selectedOu?.id)
    }

    const handleChangeRowsPerPage = (e) => {
        const Limit = parseInt(e.target.value);
        const Page = 1;
        let params: any = getDecodeURI(location?.search);
        delete params.page;
        Limit == 10 ? delete params.limit : params.limit = Limit;
        setPage(1);
        setLimit(Limit);
        doNavigate(params);
        getContentFilterLogs(Page, Limit, sort, sortOrder, searchValue, selectedOu?.id)
    }

    const handleDownloadReport = () => {
        const params: CsvParameters = {
            type: "QUERY_PG",
            id: "content-filter-logs",
            queryName: 'GET_CF_LOGS',
            payload: {
                search: searchValue,
                page_offset: 0,
                page_limit: count,
                sort_column: sort,
                sort_order: sortOrder,
                dp_id: selectedOu?.id
            }
        }
        downloadAsCSV(params);
    }

    const rowsOption = [10, 15, 25, 50, 100]

    const handleOnSearchClick = () => {
        doSearch(searchValue);
    }

    const handleOnSearchClear = () => {
        setSearchValue('');
        doSearch('');
    }

    const handleOnSearchChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setSearchValue(e.target.value);
        if (searchTimeoutRef.current) {
            clearTimeout(searchTimeoutRef.current);
        }
        searchTimeoutRef.current = setTimeout(() => {
            searchTimeoutRef.current = undefined;
            doSearch(e.target.value);
        }, 1000)
    }

    return (
        <div className="config-container">
            <Grid container spacing={0.5} className="reports-login-history trends-usage-table-container">
                <Grid item xs={12} sm={12} md={12} lg={12} className="search-dropdown-limit-div trends-usage-search-container topbarMarginPaddingReset alignTopbarItemsCenter">
                    <Grid item xs={3} sm={3} md={3} lg={3} className="site-details-header usage-site-details-header">Logs</Grid>
                    <Grid item xs={9} sm={9} md={9} lg={9} className="usage-table-search-download-div">
                        <Grid className="usage-search-border-color">
                            <Box className="searchbarBox">
                                {<TextField
                                    id="outlined-basic"
                                    variant="outlined"
                                    placeholder="Type something"
                                    autoComplete="off"
                                    size="small"
                                    value={searchValue}
                                    onChange={handleOnSearchChange}
                                    onKeyDown={(event) => searchBoxKeyDown(event)}
                                    InputLabelProps={{ className: "serachLabel1" }}
                                    InputProps={{
                                        className: "searchbarInputs",
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                {!searchValue && <SearchIcon
                                                    className="searchbarIconsPosition"
                                                    onClick={handleOnSearchClick}
                                                />}
                                                {searchValue && <CloseIcon
                                                    className="searchbarIconsPosition"
                                                    onClick={handleOnSearchClear}
                                                />}
                                            </InputAdornment>
                                        ),
                                    }}
                                />}
                            </Box>
                        </Grid>
                        {count > 0 && <Grid>
                            <div className="download-summary-btn" onClick={handleDownloadReport}><img className="downloadImg" src={Download} alt="" /><Button>Download</Button></div>
                        </Grid>}
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} className="TopUsersConatiner tablePaddingReset">
                    <Paper className="pagination-position config-pagination new-usage remove-shadow">
                        <Pagination
                            rowsPerPageOptions={rowsOption}
                            count={count ? Number(count) : 0}
                            rowsPerPage={limit ? limit : 0}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Paper>
                    <TableContainer className="usageReports-FleetDashboard loginHistory-tableContainer">
                        <Table aria-label="simple sticky table" stickyHeader>
                            <TableHead className="usageReports-tableHead">
                                <TableRow className="UsageReports-tableRow login-tableRow">
                                    <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell new-summary-left">
                                        <span className="align-items-center">
                                            <span className="login-history-dp-name font-wt-900" onClick={(event) => switchSorting(event, CREATED_AT)}>CREATED AT</span>
                                            <Grid className="sort-icon-margin">
                                                <img src={sort === CREATED_AT && sortOrder === ASC_ORDER ? ascSortingSelected : AscSort}
                                                    className="ascendingOrderIcon sortingImages" onClick={(event) => setSorting(event, CREATED_AT, ASC_ORDER)} />
                                                <img src={sort === CREATED_AT && sortOrder === DESC_ORDER ? descSortingSelected : DescSort}
                                                    className="sortingImages" onClick={(event) => setSorting(event, CREATED_AT, DESC_ORDER)} />
                                            </Grid>
                                        </span>
                                    </TableCell>
                                    <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell new-summary-left">
                                        <span className="align-items-center">
                                            <span className="login-history-dp-name font-wt-900" onClick={(event) => switchSorting(event, ORG_NAME)}>ORGANIZATION</span>
                                            <Grid className="sort-icon-margin">
                                                <img src={sort === ORG_NAME && sortOrder === ASC_ORDER ? ascSortingSelected : AscSort}
                                                    className="ascendingOrderIcon sortingImages" onClick={(event) => setSorting(event, ORG_NAME, ASC_ORDER)} />
                                                <img src={sort === ORG_NAME && sortOrder === DESC_ORDER ? descSortingSelected : DescSort}
                                                    className="sortingImages" onClick={(event) => setSorting(event, ORG_NAME, DESC_ORDER)} />
                                            </Grid>
                                        </span>
                                    </TableCell>
                                    <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell new-summary-left">
                                        <span className="align-items-center">
                                            <span className="login-history-dp-name font-wt-900" onClick={(event) => switchSorting(event, SITE_NAME)}>SITE</span>
                                            <Grid className="sort-icon-margin">
                                                <img src={sort === SITE_NAME && sortOrder === ASC_ORDER ? ascSortingSelected : AscSort}
                                                    className="ascendingOrderIcon sortingImages" onClick={(event) => setSorting(event, SITE_NAME, ASC_ORDER)} />
                                                <img src={sort === SITE_NAME && sortOrder === DESC_ORDER ? descSortingSelected : DescSort}
                                                    className="sortingImages" onClick={(event) => setSorting(event, SITE_NAME, DESC_ORDER)} />
                                            </Grid>
                                        </span>
                                    </TableCell>
                                    <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell new-summary-left">
                                        <span className="align-items-center">
                                            <span className="login-history-dp-name font-wt-900" onClick={(event) => switchSorting(event, DEVICE_NAME)}>DEVICE</span>
                                            <Grid className="sort-icon-margin">
                                                <img src={sort === DEVICE_NAME && sortOrder === ASC_ORDER ? ascSortingSelected : AscSort}
                                                    className="ascendingOrderIcon sortingImages" onClick={(event) => setSorting(event, DEVICE_NAME, ASC_ORDER)} />
                                                <img src={sort === DEVICE_NAME && sortOrder === DESC_ORDER ? descSortingSelected : DescSort}
                                                    className="sortingImages" onClick={(event) => setSorting(event, DEVICE_NAME, DESC_ORDER)} />
                                            </Grid>
                                        </span>
                                    </TableCell>
                                    <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell new-summary-left">
                                        <span className="align-items-center">
                                            <span className="login-history-dp-name font-wt-900" onClick={(event) => switchSorting(event, CONTENT_FILTER_NAME)}>CONTENT FILTER NAME</span>
                                            <Grid className="sort-icon-margin">
                                                <img src={sort === CONTENT_FILTER_NAME && sortOrder === ASC_ORDER ? ascSortingSelected : AscSort}
                                                    className="ascendingOrderIcon sortingImages" onClick={(event) => setSorting(event, CONTENT_FILTER_NAME, ASC_ORDER)} />
                                                <img src={sort === CONTENT_FILTER_NAME && sortOrder === DESC_ORDER ? descSortingSelected : DescSort}
                                                    className="sortingImages" onClick={(event) => setSorting(event, CONTENT_FILTER_NAME, DESC_ORDER)} />
                                            </Grid>
                                        </span>
                                    </TableCell>
                                    <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell new-summary-left">
                                        <span className="align-items-center">
                                            <span className="login-history-dp-name font-wt-900">TRAFIC POLICY</span>
                                        </span>
                                    </TableCell>
                                    <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell new-summary-left">
                                        <span className="align-items-center">
                                            <span className="login-history-dp-name font-wt-900" onClick={(event) => switchSorting(event, ACTION)}>ACTION</span>
                                            <Grid className="sort-icon-margin">
                                                <img src={sort === ACTION && sortOrder === ASC_ORDER ? ascSortingSelected : AscSort}
                                                    className="ascendingOrderIcon sortingImages" onClick={(event) => setSorting(event, ACTION, ASC_ORDER)} />
                                                <img src={sort === ACTION && sortOrder === DESC_ORDER ? descSortingSelected : DescSort}
                                                    className="sortingImages" onClick={(event) => setSorting(event, ACTION, DESC_ORDER)} />
                                            </Grid>
                                        </span>
                                    </TableCell>
                                    <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell new-summary-left">
                                        <span className="align-items-center">
                                            <span className="login-history-dp-name font-wt-900" onClick={(event) => switchSorting(event, INITIATED_BY)}>INITIATES BY</span>
                                            <Grid className="sort-icon-margin">
                                                <img src={sort === INITIATED_BY && sortOrder === ASC_ORDER ? ascSortingSelected : AscSort}
                                                    className="ascendingOrderIcon sortingImages" onClick={(event) => setSorting(event, INITIATED_BY, ASC_ORDER)} />
                                                <img src={sort === INITIATED_BY && sortOrder === DESC_ORDER ? descSortingSelected : DescSort}
                                                    className="sortingImages" onClick={(event) => setSorting(event, INITIATED_BY, DESC_ORDER)} />
                                            </Grid>
                                        </span>
                                    </TableCell>
                                    <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell new-summary-left">
                                        <span className="align-items-center">
                                            <span className="login-history-dp-name font-wt-900" onClick={(event) => switchSorting(event, STATUS)}>STATUS</span>
                                            <Grid className="sort-icon-margin">
                                                <img src={sort === STATUS && sortOrder === ASC_ORDER ? ascSortingSelected : AscSort}
                                                    className="ascendingOrderIcon sortingImages" onClick={(event) => setSorting(event, STATUS, ASC_ORDER)} />
                                                <img src={sort === STATUS && sortOrder === DESC_ORDER ? descSortingSelected : DescSort}
                                                    className="sortingImages" onClick={(event) => setSorting(event, STATUS, DESC_ORDER)} />
                                            </Grid>
                                        </span>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            {count > 0 && tableData && 1 != tableData?.length && !gettingContentFilters ? (
                                <TableBody className="tableBody usage-tableBody Table-Body-Opacity">
                                    {
                                        tableData && tableData?.map((item, i) => (
                                            i > 0 && <TableRow key={i} className="loginHistory-tableRow new-summary-usage-tr Table-Row-Opacity elipseThreat">
                                                <TableCell className="login-left-align inter-font Table-Cell-Opacity">{convertDateTimeIntoTimezone(item?.created_at, userTimezone, MMDDYYYYHMMSS_DATE_FORMAT_24_HRS)}</TableCell>
                                                <Tooltip title={item.organization}>
                                                    <TableCell className="login-left-align inter-font Table-Cell-Opacity elipseThreat">{item?.organization}</TableCell>
                                                </Tooltip>
                                                <Tooltip title={item.site_name}>
                                                    <TableCell className="login-left-align inter-font Table-Cell-Opacity elipseThreat">{item?.site_name}</TableCell>
                                                </Tooltip>
                                                <Tooltip title={item.device_name}>
                                                    <TableCell className="login-left-align inter-font Table-Cell-Opacity elipseThreat">{item?.device_name}</TableCell>
                                                </Tooltip>
                                                <Tooltip title={item.content_filter_name}>
                                                    <TableCell className="login-left-align inter-font Table-Cell-Opacity elipseThreat">{item?.content_filter_name}</TableCell>
                                                </Tooltip>
                                                <TableCell className="login-left-align inter-font Table-Cell-Opacity">
                                                    <JsonDataTooltip className="labelDEtailsJsonToolt=Tip" data={item?.status_history} />
                                                </TableCell>
                                                <Tooltip title={item.action}>
                                                    <TableCell className="login-left-align inter-font Table-Cell-Opacity elipseThreat">{item?.action}</TableCell>
                                                </Tooltip>
                                                <Tooltip title={item.action_initiated_by}>
                                                    <TableCell className="login-left-align inter-font Table-Cell-Opacity elipseThreat">{item?.action_initiated_by}</TableCell>
                                                </Tooltip>
                                                <TableCell className="login-left-align inter-font Table-Cell-Opacity">{item?.status}</TableCell>
                                            </TableRow>
                                        ))
                                    }
                                </TableBody>
                            ) : (
                                <TableBody>
                                    <TableRow>
                                        <TableCell colSpan={6} className="noDataAvailableCss" align="center">No data available</TableCell>
                                    </TableRow>
                                </TableBody>
                            )}
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        </div>
    )
}


const mapStateToProps = (state) => ({
    userTimezone: state.authReducer.userTimezone,
    contentFilterLogs: state.customContentFilter.contentFilterLogs,
    gettingContentFilters: state.customContentFilter.gettingContentFilters,
    csvDataDownloded: state?.authReducer?.csvDataDownloded,
    csvDownloadId: state?.authReducer?.csvDownloadId,
    selectedOu: state.authReducer.selectedOu
});

export default withRouter(
    connect(mapStateToProps, { getContentFilterLogs, downloadAsCSV })(ContentFilterLogs)
);