import { Box, Button, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from '@mui/material';
import {useState, useEffect} from 'react';
import { Pagination } from '../../components/Pagination';
// import { Pagination, RowPerPageHandler } from "../Pagination/index";
import { useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux"
import _ from "lodash"
import ascSortingSelected from "../../asset/image/ascSortingSelected.svg"
import descSortingSelected from "../../asset/image/descSortingSelected.svg";
import AscSort from "../../asset/image/AscSort.svg";
import DescSort from "../../asset/image/DescSort.svg";
import { PER_PAGE_ITEMS, DEFAULT_PAGE_SIZE, NEW_PER_PAGE_ITEMS } from "../../constants/Constants";
import { convertDateTimeIntoTimezone, getDecodeURI, getEncodedURI } from "../../utils/util";
import { useHistory, useLocation } from "react-router-dom";
import { getFlightSchdeuleTable, getFlightSchdeuleTableCount } from "../../actions/Users/authenticateDashboard";
import CustomisedJsonDataTooltip from "../../UserScreen/ThreatDashboard/ThreatsComponent/CustomisedTooltip";
import Download from '../../asset/image/DownloadUsage.svg';
import { CsvParameters, downloadAsCSV } from "../../actions/Users/authenticateCsvDownload";
import DownloadCSV, {clearCsvDownload} from "../../components/DownloadCSV";
import { MMDDYYYYHMMSS_DATE_FORMAT_24_HRS } from '../../utils/constants';

const DEFAULT_PAGE = 0;
const convertToCsv = (data: any) => {
    const [_columns, ...rows] = data;
    const csvRows: any[] = [];
    rows.forEach(row => {
        csvRows.push([row[20], row[0], row[1],  row[8], row[9], row[11], row[12], `${row[15]} / ${row[14]}`, row[16], row[17], row[18], row[19]] )
    })
    return [['TimeStamp', 'Device Id', 'Tail Number', 'Orgin', 'Destination', 'Depature Time', 'Arrival Time', 'Status', 'Aircraft Type', 'Route Distance', 'AirSpeed', "Altitude"], ...csvRows]
};

const FlightSchedule = (props) => {
    const {selectedVessel, selectedVesselName, authReducer, newSummaryStartDate, newSummaryEndDate, getFlightSchdeuleTable, getFlightSchdeuleTableCount, flightScheduleTable, flightScheduleTableCount} = props;
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    let _q = new Map<string, string>(Object.entries(getDecodeURI(location.search)));
    const [tableParams, setTableParams] = useState<{
        page: number;
        size: number;
        search: string;
    }>({
        page: _q.has('page') ? parseInt(_q.get('page') as string) : 1,
        size: _q.has('size') ? parseInt(_q.get('size') as string) : 10,
        search: ''
    });
    const [flightScheduleDataTable, setFlightScheduleDataTable] = useState<any[]>([]);
    const [count, setCount] = useState<number>(0);
    const [sortOrder, setSortOrder] = useState<string>("desc");
    const [sortColumn, setSortColumn] = useState<string>("scheduled_off");

    const doNavigate = (params) => {
        history.push({ pathname: location.pathname, search: `?${getEncodedURI(params)}` });
    }

    const handleChangePage = (event: any, newPage: number) => {
        setTableParams({ ...tableParams, page: newPage });
    };
    const handleChangeSize = (event: any) => {
        setTableParams({ ...tableParams, size: event.target.value, page: 1 });
    };

    const toggleSortOrder = (column) => {
        const newOrder = sortOrder === "desc" ? "asc" : "desc";
        handleColumnSort(column, newOrder); 
    };

    const handleColumnSort = (column: string, order: string) => {
        setSortColumn(column);
        setSortOrder(order);
    }

    useEffect(() => {
        if(selectedVessel){
            getFlightSchdeuleTable(selectedVessel, newSummaryStartDate, newSummaryEndDate, sortColumn, sortOrder, (tableParams.page-1)*tableParams.size, tableParams.size)
        }
    }, [selectedVessel, newSummaryStartDate, newSummaryEndDate, tableParams, sortColumn, sortOrder])

    useEffect(() => {
        if(!_.isEmpty(flightScheduleTable)) {
            const data = flightScheduleTable?.hasOwnProperty('data') ? flightScheduleTable?.data : {};
            const dataArr:any = [];
            data?.rows && data?.rows?.forEach((item, i) => {
                const itemArr = data?.columns?.reduce((acc, columItem, i) => {
                    acc[columItem] = item[i];
                    return acc;
                }, {})
                dataArr.push(itemArr)
            })
            setFlightScheduleDataTable(dataArr);
        }
    }, [flightScheduleTable])

    useEffect(() => {
        if(selectedVessel){
            getFlightSchdeuleTableCount(selectedVessel, newSummaryStartDate, newSummaryEndDate)
        }
    }, [selectedVessel, newSummaryStartDate, newSummaryEndDate])

    useEffect(() => {
        if(!_.isEmpty(flightScheduleTableCount)) {
            const data = flightScheduleTableCount?.hasOwnProperty('data') ? flightScheduleTableCount?.data : {};
            const len = data?.rows[0]?.[0] ? data.rows[0][0] : 0;
            setCount(len);
        }
    }, [flightScheduleTableCount])

    const handleDownloadReport = () => {
        const params: CsvParameters = {
            type: 'QUERY_CH',
            id : "flight-schedule-table",
            queryName: 'FLIGHT_SCHEDULE_TABLE',
            payload: {
                k4Id: selectedVessel,
                start_time: newSummaryStartDate,
                end_time: newSummaryEndDate,
                sort_column: sortColumn,
                sort_order: sortOrder,
                page_offset: 0,
                page_size: count
            }    
        }
        dispatch(downloadAsCSV(params));
    }

    useEffect(() => {
        if (authReducer.csvDataDownloded && authReducer.csvDataDownloded.length > 0) {
            if (authReducer.csvDownloadId === 'flight-schedule-table') {
                const [columns, ...rows] = authReducer.csvDataDownloded;
                DownloadCSV(convertToCsv([columns, ...rows])
                , {
                    formatters: {
                        3: 'JSON',
                        4: 'JSON'
                    }
                }
                );
                return () => {
                    dispatch(clearCsvDownload())
                }
            }
        }
    }, [authReducer.csvDataDownloded])
    
    return(
        <Grid>
            <Grid className="alignTopbarItemsCenter">
                <Grid className="vessel_summary_product_name productName" style={{margin: '16px auto 16px 16px'}}>
                    {`Flight schedule`}
                </Grid>
                <div className="download-summary-btn margin-left-auto" onClick={handleDownloadReport}><img className="downloadImg" src={Download} alt="" /><Button>Download</Button></div>
            </Grid>
            <Grid>
                <Grid>
                    <Pagination count={count} onPageChange={handleChangePage} page={tableParams.page} rowsPerPage={tableParams.size} onRowsPerPageChange={handleChangeSize} />
                </Grid>
                <TableContainer className="usageReports-FleetDashboard loginHistory-tableContainer">
                    <Table aria-label="simple table">
                        <TableHead className="usageReports-tableHead config-tableRow">
                          <TableRow className="UsageReports-tableRow login-tableRow">
                          <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell new-summary-left">
                                <span style={{ whiteSpace: "nowrap" }} className="align-items-center">
                                    <span className="login-history-dp-name font-wt-900" onClick={() => toggleSortOrder("tx")}>TIMESTAMP</span>
                                    <Grid className="sort-icon-margin">
                                        <img src={sortColumn === "tx" && sortOrder === "asc" ? ascSortingSelected : AscSort}
                                            className="ascendingOrderIcon sortingImages" onClick={() => handleColumnSort("tx", "asc")}/>
                                        <img src={sortColumn === "tx" && sortOrder === "desc" ? descSortingSelected : DescSort}
                                            className="sortingImages" onClick={() => handleColumnSort("tx", "desc")}/>
                                    </Grid>
                                </span>
                            </TableCell>
                            <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell new-summary-left">
                                <span style={{ whiteSpace: "nowrap" }} className="align-items-center">
                                    <span className="login-history-dp-name font-wt-900" onClick={() => toggleSortOrder("device_id")}>Device Id</span>
                                    <Grid className="sort-icon-margin">
                                        <img src={sortColumn === "device_id" && sortOrder === "asc" ? ascSortingSelected : AscSort}
                                            className="ascendingOrderIcon sortingImages" onClick={() => handleColumnSort("device_id", "asc")}/>
                                        <img src={sortColumn === "device_id" && sortOrder === "desc" ? descSortingSelected : DescSort}
                                            className="sortingImages" onClick={() => handleColumnSort("device_id", "desc")}/>
                                    </Grid>
                                </span>
                            </TableCell>
                            <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell new-summary-left">
                                <span style={{ whiteSpace: "nowrap" }} className="align-items-center">
                                    <span className="login-history-dp-name font-wt-900" onClick={() => toggleSortOrder("tail_id")}>Tail Number</span>
                                    <Grid className="sort-icon-margin">
                                        <img src={sortColumn === "tail_id" && sortOrder === "asc" ? ascSortingSelected : AscSort}
                                            className="ascendingOrderIcon sortingImages" onClick={() => handleColumnSort("tail_id", "asc")}/>
                                        <img src={sortColumn === "tail_id" && sortOrder === "desc" ? descSortingSelected : DescSort}
                                            className="sortingImages" onClick={() => handleColumnSort("tail_id", "desc")}/>
                                    </Grid>
                                </span>
                            </TableCell>
                            <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell new-summary-left">
                                <span style={{ whiteSpace: "nowrap" }} className="align-items-center justifyContenetCenter">
                                    <span className="login-history-dp-name font-wt-900" onClick={() => toggleSortOrder("origin")}>Origin</span>
                                    <Grid className="sort-icon-margin">
                                        <img src={sortColumn === "origin" && sortOrder === "asc" ? ascSortingSelected : AscSort}
                                            className="ascendingOrderIcon sortingImages" onClick={() => handleColumnSort("origin", "asc")}/>
                                        <img src={sortColumn === "origin" && sortOrder === "desc" ? descSortingSelected : DescSort}
                                            className="sortingImages" onClick={() => handleColumnSort("origin", "desc")}/>
                                    </Grid>
                                </span>
                            </TableCell>
                            <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell new-summary-left">
                                <span style={{ whiteSpace: "nowrap" }} className="align-items-center">
                                    <span className="login-history-dp-name font-wt-900" onClick={() => toggleSortOrder("destination")}>Destination</span>
                                    <Grid className="sort-icon-margin">
                                        <img src={sortColumn === "destination" && sortOrder === "asc" ? ascSortingSelected : AscSort}
                                            className="ascendingOrderIcon sortingImages" onClick={() => handleColumnSort("destination", "asc")}/>
                                        <img src={sortColumn === "destination" && sortOrder === "desc" ? descSortingSelected : DescSort}
                                            className="sortingImages" onClick={() => handleColumnSort("destination", "desc")}/>
                                    </Grid>
                                </span>
                            </TableCell>
                            <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell new-summary-left">
                                <span style={{ whiteSpace: "nowrap" }} className="align-items-center">
                                    <span className="login-history-dp-name font-wt-900" onClick={() => toggleSortOrder("scheduled_off")}>Depature Time</span>
                                    <Grid className="sort-icon-margin">
                                        <img src={sortColumn === "scheduled_off" && sortOrder === "asc" ? ascSortingSelected : AscSort}
                                            className="ascendingOrderIcon sortingImages" onClick={() => handleColumnSort("scheduled_off", "asc")}/>
                                        <img src={sortColumn === "scheduled_off" && sortOrder === "desc" ? descSortingSelected : DescSort}
                                            className="sortingImages" onClick={() => handleColumnSort("scheduled_off", "desc")}/>
                                    </Grid>
                                </span>
                            </TableCell>
                            <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell new-summary-left">
                                <span style={{ whiteSpace: "nowrap" }} className="align-items-center">
                                    <span className="login-history-dp-name font-wt-900" onClick={() => toggleSortOrder("scheduled_on")}>Arrival Time</span>
                                    <Grid className="sort-icon-margin">
                                        <img src={sortColumn === "scheduled_on" && sortOrder === "asc" ? ascSortingSelected : AscSort}
                                            className="ascendingOrderIcon sortingImages" onClick={() => handleColumnSort("scheduled_on", "asc")}/>
                                        <img src={sortColumn === "scheduled_on" && sortOrder === "desc" ? descSortingSelected : DescSort}
                                            className="sortingImages" onClick={() => handleColumnSort("scheduled_on", "desc")}/>
                                    </Grid>
                                </span>
                            </TableCell>
                            <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell new-summary-left">
                                <span style={{ whiteSpace: "nowrap" }} className="align-items-center">
                                    <span className="login-history-dp-name font-wt-900" onClick={() => toggleSortOrder("status")}>Status</span>
                                    <Grid className="sort-icon-margin">
                                        <img src={sortColumn === "status" && sortOrder === "asc" ? ascSortingSelected : AscSort}
                                            className="ascendingOrderIcon sortingImages" onClick={() => handleColumnSort("status", "asc")}/>
                                        <img src={sortColumn === "status" && sortOrder === "desc" ? descSortingSelected : DescSort}
                                            className="sortingImages" onClick={() => handleColumnSort("status", "desc")}/>
                                    </Grid>
                                </span>
                            </TableCell>
                            <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell new-summary-left">
                                <span style={{ whiteSpace: "nowrap" }} className="align-items-center">
                                    <span className="login-history-dp-name font-wt-900" onClick={() => toggleSortOrder("route_distance")}>Route Distance</span>
                                    <Grid className="sort-icon-margin">
                                        <img src={sortColumn === "route_distance" && sortOrder === "asc" ? ascSortingSelected : AscSort}
                                            className="ascendingOrderIcon sortingImages" onClick={() => handleColumnSort("route_distance", "asc")}/>
                                        <img src={sortColumn === "route_distance" && sortOrder === "desc" ? descSortingSelected : DescSort}
                                            className="sortingImages" onClick={() => handleColumnSort("route_distance", "desc")}/>
                                    </Grid>
                                </span>
                            </TableCell>
                            <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell new-summary-left">
                                <span style={{ whiteSpace: "nowrap" }} className="align-items-center">
                                    <span className="login-history-dp-name font-wt-900" onClick={() => toggleSortOrder("aircraft_type")}>Aircraft Type</span>
                                    <Grid className="sort-icon-margin">
                                        <img src={sortColumn === "aircraft_type" && sortOrder === "asc" ? ascSortingSelected : AscSort}
                                            className="ascendingOrderIcon sortingImages" onClick={() => handleColumnSort("aircraft_type", "asc")}/>
                                        <img src={sortColumn === "aircraft_type" && sortOrder === "desc" ? descSortingSelected : DescSort}
                                            className="sortingImages" onClick={() => handleColumnSort("aircraft_type", "desc")}/>
                                    </Grid>
                                </span>
                            </TableCell>
                            <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell new-summary-left">
                                <span style={{ whiteSpace: "nowrap" }} className="align-items-center">
                                    <span className="login-history-dp-name font-wt-900" onClick={() => toggleSortOrder("filed_airspeed")}>Airspeed</span>
                                    <Grid className="sort-icon-margin">
                                        <img src={sortColumn === "filed_airspeed" && sortOrder === "asc" ? ascSortingSelected : AscSort}
                                            className="ascendingOrderIcon sortingImages" onClick={() => handleColumnSort("filed_airspeed", "asc")}/>
                                        <img src={sortColumn === "filed_airspeed" && sortOrder === "desc" ? descSortingSelected : DescSort}
                                            className="sortingImages" onClick={() => handleColumnSort("filed_airspeed", "desc")}/>
                                    </Grid>
                                </span>
                            </TableCell>
                            <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell new-summary-left">
                                <span style={{ whiteSpace: "nowrap" }} className="align-items-center">
                                    <span className="login-history-dp-name font-wt-900" onClick={() => toggleSortOrder("filed_altitude")}>Altitude</span>
                                    <Grid className="sort-icon-margin">
                                        <img src={sortColumn === "filed_altitude" && sortOrder === "asc" ? ascSortingSelected : AscSort}
                                            className="ascendingOrderIcon sortingImages" onClick={() => handleColumnSort("filed_altitude", "asc")}/>
                                        <img src={sortColumn === "filed_altitude" && sortOrder === "desc" ? descSortingSelected : DescSort}
                                            className="sortingImages" onClick={() => handleColumnSort("filed_altitude", "desc")}/>
                                    </Grid>
                                </span>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        { flightScheduleDataTable && 0 != flightScheduleDataTable.length ? 
                        <TableBody className="tableBody alertsTable-tableBody">
                            {flightScheduleDataTable.map((row: any, index: number)=> (
                            <TableRow className="alertsData-tabelRow">
                                <TableCell className="alertsTable-tableCell">
                                        {convertDateTimeIntoTimezone(row.tx, authReducer.userTimezone, MMDDYYYYHMMSS_DATE_FORMAT_24_HRS)}
                                </TableCell>
                                <TableCell className="alertsTable-tableCell">{row.device_id}</TableCell>
                                <TableCell className="alertsTable-tableCell">{row.tail_id}</TableCell>
                                <TableCell className="alertsTable-tableCell">{JSON.parse(row.origin)?.code || "No data"}<CustomisedJsonDataTooltip 
                                                                        data={row.origin} /></TableCell>
                                <TableCell className="alertsTable-tableCell">{JSON.parse(row.destination)?.code || "No data"}<CustomisedJsonDataTooltip data={row.destination} /></TableCell>
                                <TableCell className="alertsTable-tableCell">
                                        {convertDateTimeIntoTimezone(row.scheduled_off, authReducer.userTimezone, MMDDYYYYHMMSS_DATE_FORMAT_24_HRS)}
                                </TableCell>
                                <TableCell className="alertsTable-tableCell">
                                        {convertDateTimeIntoTimezone(row.scheduled_on, authReducer.userTimezone, MMDDYYYYHMMSS_DATE_FORMAT_24_HRS)}
                                </TableCell>
                                <TableCell className="alertsTable-tableCell">
                                    {row.status} <br/>
                                    {`${row.progress_percent} %`}
                                </TableCell>
                                <TableCell className="alertsTable-tableCell">{row.route_distance}</TableCell>
                                <TableCell className="alertsTable-tableCell">{row.aircraft_type}</TableCell>
                                <TableCell className="alertsTable-tableCell">{row.filed_airspeed}</TableCell>
                                <TableCell className="alertsTable-tableCell">{row.filed_altitude}</TableCell>
                            </TableRow>
                            ))}
                        </TableBody> : 
                        <TableBody>
                            <TableRow>
                                <TableCell colSpan={8} align='center'>
                                    No data available
                                </TableCell>
                            </TableRow>
                        </TableBody>}
                    </Table>
                </TableContainer>
            </Grid>
            
        </Grid>
    )
}

const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
    errorReducer: state.errorReducer,
    newSummaryStartDate: state?.authReducer?.newSummaryStartDate,
    newSummaryEndDate: state?.authReducer?.newSummaryEndDate,
    flightScheduleTable: state.authReducer?.flightScheduleTable,
    flightScheduleTableCount: state.authReducer?.flightScheduleTableCount
});

export default withRouter(
    connect(mapStateToProps, {
        getFlightSchdeuleTable,
        getFlightSchdeuleTableCount
    })(FlightSchedule)
);