import { useEffect, useState, useMemo, Fragment, useRef } from "react";
import { withRouter } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import _ from "lodash";
import { utils, writeFileXLSX } from 'xlsx';

import { Box, Divider, FormGroup, Grid, InputAdornment, Menu, MenuItem, Paper, Radio, RadioGroup, Select, Tooltip, Typography } from "@mui/material";
import moment, { Moment } from "moment-timezone";
import QuestionMarkOutlinedIcon from '@mui/icons-material/Help';

import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { Button, Checkbox, FormControlLabel, TextField, Dialog } from '@mui/material';
import { Search, Download, DataUsage } from '@mui/icons-material';
import TuneIcon from '@mui/icons-material/Tune';
import AssetUsageBarChart from "./AssetUsageBarChart";
import AssetsTable from "./AssetsTable";
import { Pagination } from "../Pagination";

import { parentDpAndSubDpList, updatePermission } from '../../actions/Users/authenticate';
import { checkLoggedInUserAuthorizedToViewPage, convertDateTimeIntoTimezone, getDecodeURI, getEncodedURI } from '../../utils/util';
import { Asset, AssetSortBy, ServiceLine, StarlinkAvailabilityAlert, StarlinkQuotaAlert, StarlinkReportsProps } from "../StarlinkReports/types";
import { clearCSV, clearComponentData, getAssetUsage, getAssetPlanUsageCharts, getAssetsPlanUsage, getAssetsCSV, setQuotaAlert, setAvailabilityAlert, getAllServiceLines, getStarlinkPlanUsageHierarchy } from "../StarlinkReports/slice";

import "./index.css";
import { SortOrder } from "../SortArrows";
import QuotaAlertDialog from "../StarlinkQuota/AddEditQuotaDialog";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from '@mui/icons-material/Close';
import NotificationAddIcon from '@mui/icons-material/NotificationAdd';
import DownloadIcon from '../../asset/image/DownloadUsage.svg';
import { CsvParameters, downloadAsCSV } from "../../actions/Users/authenticateCsvDownload";
import { GET_CSV_DATA_TO_DOWNLOAD } from "../../actions/types";
import DownloadCSV, { clearCsvDownload } from "../DownloadCSV";
import AvailabiltyAlertDialog from "../StarlinkReports/AvailabiltyAlertDialog";
import { STARLINK_DATAUSAGE_CATEGORIES, STARLINK_DATAUSAGE_CATEGORIES_DATA } from "../../UserScreen/Starlink/types";
import { DEFAULT_DATE_FORMAT, YYYYMMDD_HHmmss } from "../../utils/constants";
import { setSelectedAccount } from "../../UserScreen/Starlink/slice";
import { getLastThreeMonths } from "../../constants/Constants";
import { SERVICE_FUNCTION_TO_FEATURE } from "../../config";
import close from "../../asset/image/close.svg";
import upArrow from '../../asset/image/newUpArrow.svg';
import NewDownloadButton from '../../asset/image/NewDownloadButton.svg';

import Filters from "./Filters";

function StarlinkReports(props: StarlinkReportsProps) {
    const { history, location, dpIds, assetsPlanUsage, assetsCSV, assetUsage, assetUsageSplit, getAssetPlanUsageCharts, selectedServiceAccount, totalAssetsPlanUsage, setSelectedAccount, getAssetsPlanUsage, serviceAccounts, getAssetUsage, getAssetsCSV, setQuotaAlert, setAvailabilityAlert, clearComponentData, clearCSV, newSummaryStartDate, newSummaryEndDate, newSummaryServiceLine, authReducer, downloadAsCSV, getAllServiceLines, getStarlinkPlanUsageHierarchy, starlinkPlanUsageHierarchy } = props;

    const queryParams = new Map<string, string>(Object.entries(getDecodeURI(location.search)));

    const [searchText, setSearchText] = useState<string>(queryParams.get('search') || '');
    const [openBillingDialog, setOpenBillingDialog] = useState(false);
    const STARLINK_ADMIN_SF = "starlink-admin";
    const starlinkAdminService = SERVICE_FUNCTION_TO_FEATURE[STARLINK_ADMIN_SF]["service"];
    const starlinkAdminFunction = SERVICE_FUNCTION_TO_FEATURE[STARLINK_ADMIN_SF]["function"];
    const lastThreeMonths = getLastThreeMonths()
    const currentMonth = {
        label: moment().format('MMMM'),
        value: moment().format('YYYY-MM'),
        startDate: moment().startOf('month').format('YYYY-MM-DD 00:00:00'),
        endDate: moment().endOf('month').format('YYYY-MM-DD 23:59:59')
    };

    const [filterPopup, setFilterPopup] = useState(false);
    const [anchorElRef, setAnchorElRef] = useState<HTMLElement>();
    const defaultFilters = [
        { name: "Opt In Priority GB", label: "optIn", selected: true },
        { name: "Total Usage", label: "totalUsage", selected: false },
    ];

    const [selectedFilter, setSelectedFilter] = useState("optIn");
    const [usagePageFilters, setUsagePageFilters] = useState(false);
    const [parentPoolId, setParentPoolId] = useState('');

    const handleFilterChange = (filter) => {
        setSelectedFilter(filter);
    };

    const [searchValue, setSearchValue] = useState('');
    const subTabs = [
        { tabName: 'All', tabValue: 'all' },
        { tabName: 'Starlink Only', tabValue: 'starlink_only' },
        { tabName: 'Custom Only', tabValue: 'custom_only' }
    ]
    const [subTab, setSubTab] = useState('all');

    const prevServiceLinesRef = useRef();
    const prevValuesRef = useRef({
        newSummaryServiceLine: null,
        newSummaryEndDate: moment(new Date()),
        newSummaryStartDate: moment(new Date()),
    });

    const [selectedDownloadPeriod, setSelectedDownloadPeriod] = useState<any>(currentMonth)
    const lastThreeMonthsWithCurrent = [currentMonth, ...lastThreeMonths];

    const [tableParams, setTableParams] = useState<{
        page: number;
        size: number;
        sortBy: AssetSortBy;
        sortOrder: SortOrder;
        search: string;
        startDate: moment.Moment;
        endDate: moment.Moment;
        serviceLines: any;
    }>({
        page: queryParams.get('page') ? Number(queryParams.get('page')) : 1,
        size: queryParams.get('size') ? Number(queryParams.get('size')) : 10,
        sortBy: queryParams.get('sortBy') as AssetSortBy || 'curr_billing_cycle_usage_percent',
        sortOrder: queryParams.get('sortOrder') as SortOrder || 'desc',
        search: queryParams.get('search') || '',
        startDate: newSummaryStartDate,
        endDate: newSummaryEndDate,
        serviceLines: newSummaryServiceLine
    });
    const [selectedAssets, setSelectedAssets] = useState<Asset[]>([])
    const [showCurrentBillingCycleUsage, setShowCurrentBillingCycleUsage] = useState(false);
    const [disableExport, setDisableExport] = useState(false);

    const [openQuotaAlertDialog, setOpenQuotaAlertDialog] = useState(false);
    const [serviceLineQuotaToEdit, setServiceLineQuotaToEdit] = useState<ServiceLine | null>(null);
    const [openAvailabilityAlertDialog, setOpenAvailabilityAlertDialog] = useState(false);
    const [serviceLineAvailabilityToEdit, setServiceLineAvailabilityToEdit] = useState<ServiceLine | null>(null);
    const [expand, setExpand] = useState({ isCardExpanded: true, isChartsExpanded: true, isTableExpanded: false })
    const [planFilter, setPlanFilter] = useState<any>({ plan_id: '', type: '', account_number: '' });
    const [tableData, setTableData] = useState<any>();
    const searchTimeoutRef = useRef<NodeJS.Timeout>();
    const [planData, setPlanData] = useState<any>([]);
    const [expandedRows, setExpandedRows] = useState({});
    const [selectedRow, setSelectedRow] = useState(null);
    const dispatch = useDispatch();

    const CsvHeaders = ['Service Line', 'Organization', 'Sub Organization', 'Service Plan', 'Billing Usage', 'Billing Date', 'Chart Usage', 'Site'];

    const mapToCsvRow = (row: any) => {
        const values: any[] = Object.values(row);
        const billingUsage = row.curr_billing_cycle_usage_split.replace(/'/g, '"');
        const chartUsage = row.chart_usage_split.replace(/'/g, '"');
        const billingUsageSplit: any[] = row.curr_billing_cycle_usage_split != '' && JSON.parse(billingUsage) || [];
        const chartUsageSplit: any[] = row.chart_usage_split != '' && JSON.parse(chartUsage) || [];
        return [values[9], values[1], values[5], values[15], billingUsageSplit, convertDateTimeIntoTimezone(moment(values[14]).add(1, 'months').toString(), authReducer.userTimezone, DEFAULT_DATE_FORMAT), chartUsageSplit, values[3]]
    }

    const getSource = () => {
        const endDate = moment(tableParams.endDate, 'YYYY-MM-DD HH:mm:ss');
        const startDate = moment(tableParams.startDate, 'YYYY-MM-DD HH:mm:ss');

        const hoursDifference = Math.floor(moment.duration(endDate.diff(startDate)).asHours());
        if (hoursDifference <= 24) {
            return 'aggregation_table';
        } else if (hoursDifference > 24) {
            return 'direct_source';
        } else {
            return ''
        }
    }

    const updateQueryParams = () => {
        queryParams.set('page', tableParams.page.toString())
        queryParams.set('size', tableParams.size.toString())
        queryParams.set('sortBy', tableParams.sortBy)
        queryParams.set('sortOrder', tableParams.sortOrder)
        queryParams.set('search', tableParams.search)
        // queryParams.set('startDate', tableParams.startDate.valueOf().toString())
        // queryParams.set('endDate', tableParams.endDate.valueOf().toString())
        history.replace({ pathname: location.pathname, search: `?${getEncodedURI(Object.fromEntries(queryParams.entries()))}` });
    }

    const transformAssetsCSV = (assetsCSV: Asset[]) => {
        // change column names
        const data = assetsCSV.map(asset => {
            const sulStart = moment(asset.subscription_line_start_date)
            const currBillingStart = moment(asset.current_billing_start_date)
            return {
                "DP ID": asset.dp_id,
                "DP Name": asset.dp_name,
                "Account Number": asset.account_number,
                "Account Name": asset.account_name,
                "Asset Number": asset.asset_number,
                "Asset Name": asset.asset_display_name,
                "Starlink ID": asset.user_terminal_id,
                "KIT Number": asset.user_terminal_serial_number,
                "Service Plan": asset.subscription_line_description,
                "Quantity": asset.subscription_line_quantity,
                "Service Plan Start Date": sulStart.isValid() ? sulStart.format('YYYY-MM-DD') : '',
                "Active": asset.active ? 'Yes' : 'No',
                "Billing Date": currBillingStart.isValid() ? currBillingStart.format('YYYY-MM-DD') : '',
                "Billing Usage (GB)": asset.curr_billing_cycle_usage,
            }
        });
        return data;
    }

    useEffect(() => {
        if (assetsCSV?.length > 0) {
            const data = transformAssetsCSV(assetsCSV);
            const ws = utils.json_to_sheet(data, {
                dateNF: 'YYYY-MM-DD'
            });
            const wb = utils.book_new();
            utils.book_append_sheet(wb, ws, "Service Lines");

            const ws2 = utils.json_to_sheet(Object.keys(assetUsage).map(assetNumber => {
                return assetUsage[assetNumber].map(assetUsage => {
                    return {
                        "Asset Number": assetNumber,
                        "Asset Name": assetUsage.asset_name,
                        "Date": moment(assetUsage.x).format('DD MMM YYYY'),
                        "Usage (GB)": assetUsage.y
                    }
                })
            }).flat(), {
                dateNF: 'YYYY-MM-DD'
            });
            utils.book_append_sheet(wb, ws2, "Usage");
            writeFileXLSX(wb, "starlink-report.xlsx");
        }
    }, [assetsCSV]);

    const handleChangePage = (event: any, newPage: number) => {
        setTableParams({ ...tableParams, page: newPage });
    };

    const handleChangeSize = (event: any) => {
        setTableParams({ ...tableParams, size: event.target.value, page: 1 });
    };

    const handleChangeSelectedAssets = (event: any) => {
        const { name, checked } = event.target;
        if (name === 'selectAll') {
            if (checked) {
                setSelectedAssets(assetsPlanUsage);
            } else {
                setSelectedAssets([]);
            }
        } else {
            if (checked) {
                const asset = assetsPlanUsage.find(asset => asset.asset_number === name);
                if (asset) {
                    setSelectedAssets([...selectedAssets, asset]);
                }
            } else {
                setSelectedAssets(selectedAssets.filter(asset => asset.asset_number !== name));
            }
        }
    }

    const handleChangeStartDate = (date: moment.Moment | null) => {
        if (date) {
            setTableParams({
                ...tableParams,
                startDate: date,
                page: 1
            });
            setShowCurrentBillingCycleUsage(false)
        }
    }

    const handleChangeEndDate = (date: moment.Moment | null) => {
        if (date) {
            setTableParams({
                ...tableParams,
                endDate: date,
                page: 1
            });
            setShowCurrentBillingCycleUsage(false)
        }
    }

    const handleOnSearchClick = () => {
        handleSearch(searchText);
    }

    const handleOnSearchClear = () => {
        setSearchText('');
        handleSearch('');
    }

    const handleOnSearchChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setSearchText(e.target.value);
        if (searchTimeoutRef.current) {
            clearTimeout(searchTimeoutRef.current);
        }
        searchTimeoutRef.current = setTimeout(() => {
            searchTimeoutRef.current = undefined;
            handleSearch(e.target.value);
        }, 1000)
    }

    const handleChangeShowCurrentBillingCycleUsage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setShowCurrentBillingCycleUsage(event.target.checked);
    };

    const handleSearch = (value: string) => {
        setTableParams({
            ...tableParams,
            search: value,
            page: 1
        });
    }

    const handleChangeSorting = (sortBy: AssetSortBy, sortOrder: SortOrder) => {
        setTableParams({
            ...tableParams,
            sortBy: sortBy,
            sortOrder: sortOrder,
            page: 1
        });
    }

    const handleExportCSV = async () => {
        setDisableExport(true);
        handleDownloadReport();
        setDisableExport(false);
    }

    const handleOpenQuotaAlertDialog = () => {
        setOpenQuotaAlertDialog(true);
    }

    const handleCloseQuotaAlertDialog = () => {
        setOpenQuotaAlertDialog(false);
        setServiceLineQuotaToEdit(null);
    }

    const handleEditServiceLineQuota = (serviceLine: ServiceLine) => {
        setServiceLineQuotaToEdit(serviceLine);
        setOpenQuotaAlertDialog(true);
    }


    const handleSetQuotaAlert = async (quotaAlerts: StarlinkQuotaAlert[]) => {
        if (quotaAlerts.length > 0) {
            await setQuotaAlert({
                serviceLineNumbers: quotaAlerts.map(quotaAlert => quotaAlert.serviceLineNumber),
                alertingThresholds: quotaAlerts[0].alertingThresholds,
                notificationMethods: quotaAlerts[0].notificationMethods,
                usersToNotify: quotaAlerts[0].usersToNotify
            })
        }
        setOpenQuotaAlertDialog(false);
        setServiceLineQuotaToEdit(null);
        setTableParams({
            ...tableParams,
        });
    }

    const handleOpenAvailabilityAlertDialog = () => {
        setOpenAvailabilityAlertDialog(true);
    }

    const handleCloseAvailabilityAlertDialog = () => {
        setOpenAvailabilityAlertDialog(false);
        setServiceLineAvailabilityToEdit(null);
    }

    const handleEditServiceLineAvailability = (serviceLine: ServiceLine) => {
        setServiceLineAvailabilityToEdit(serviceLine);
        setOpenAvailabilityAlertDialog(true);
    }

    const handleSetAvailabilityAlert = async (availabilityAlerts: StarlinkAvailabilityAlert[]) => {
        if (availabilityAlerts.length > 0) {
            await setAvailabilityAlert({
                serviceLineNumbers: availabilityAlerts.map(quotaAlert => quotaAlert.serviceLineNumber),
                alertingThreshold: availabilityAlerts[0].alertingThreshold,
                notificationMethods: availabilityAlerts[0].notificationMethods,
                usersToNotify: availabilityAlerts[0].usersToNotify
            })
        }
        setOpenAvailabilityAlertDialog(false);
        setServiceLineAvailabilityToEdit(null);
        setTableParams({
            ...tableParams,
        });
    }

    const dataBucketFormat = (buckets: any[]) => {
        return buckets?.length > 0 ? `"${buckets?.map(bucket => {
            return Object.keys(bucket).map(key => {
                if (bucket[key] > 0) {
                    return `${STARLINK_DATAUSAGE_CATEGORIES_DATA[key]} - ${bucket[key]} GB`;
                }
                return null;
            }).filter(Boolean).join(', ');
        }).join(', ')}"` : '';
    };

    useEffect(() => {
        if (authReducer.csvDataDownloded && authReducer.csvDataDownloded.length > 0) {
            if (authReducer.csvDownloadId === 'starlink-usage') {
                const [_columns, status] = authReducer.csvDataDownloded;
                if (status && status.length > 0) {
                    const rows = JSON.parse(status[0]);
                    if (rows.length) {
                        rows.map(row => {
                            row["current_billing_start_date"] = convertDateTimeIntoTimezone(row["current_billing_start_date"], authReducer.userTimezone, DEFAULT_DATE_FORMAT);
                            row['chart_usage_split'] = JSON.parse(row['chart_usage_split'].replace(/'/g, '"'));
                            row['curr_billing_cycle_usage_split'] = JSON.parse(row['curr_billing_cycle_usage_split'].replace(/'/g, '"'));

                            row['curr_billing_cycle - Usage (GB)'] = row['curr_billing_cycle_usage_split']?.[0]?.usage_gb;
                            row['curr_billing_cycle - usagePriority (GB)'] = row['curr_billing_cycle_usage_split']?.[0]?.usagePriorityGB;
                            row['curr_billing_cycle - usageOptInPriority (GB)'] = row['curr_billing_cycle_usage_split']?.[0]?.usageOptInPriorityGB;
                            row['curr_billing_cycle - usageStandard (GB)'] = row['curr_billing_cycle_usage_split']?.[0]?.usageStandardGB;
                            row['curr_billing_cycle - usageNonBillable (GB)'] = row['curr_billing_cycle_usage_split']?.[0]?.usageNonBillableGB;
                            row['Extra Usage (GB)'] = row['curr_billing_cycle_usage_split']?.[0]?.usagePriorityGB > row['subscription_line_usage_limit_gb'] ? (row['curr_billing_cycle_usage_split'][0].usagePriorityGB - row['subscription_line_usage_limit_gb']) : 0;
                            row['chart_usage - Usage (GB)'] = row['chart_usage_split']?.[0]?.usage_gb;
                            row['chart_usage - usagePriority (GB)'] = row['chart_usage_split']?.[0]?.usagePriorityGB;
                            row['chart_usage - usageOptInPriority (GB)'] = row['chart_usage_split']?.[0]?.usageOptInPriorityGB;
                            row['chart_usage - usageStandard (GB)'] = row['chart_usage_split']?.[0]?.usageStandardGB;
                            row['chart_usage - usageNonBillable (GB)'] = row['chart_usage_split']?.[0]?.usageNonBillableGB;

                            delete row['curr_billing_cycle_usage_split'];
                            delete row['chart_usage_split'];
                        });
                        const keys = Object.keys(rows[0]);
                        const values = rows.map(row => Object.values(row));
                        DownloadCSV([keys, ...values], {
                            formatters: {
                                1: 'ESC-COMMA',
                                3: 'ESC-COMMA',
                                7: 'ESC-COMMA',
                                9: 'ESC-COMMA',
                                11: 'ESC-COMMA-All',
                                12: 'ESC-COMMA-All',
                                18: 'ESC-COMMA',
                                10: (quota) => quota?.status === 'active' ? 'Active' : quota?.status === 'over_threshold' ? 'Over Quota Threshold' : quota?.status === 'over_limit' ? 'Over Max Quota' : '',
                                21: 'ESC-COMMA',
                            }
                        });
                    }
                }
                return () => {
                    dispatch(clearCsvDownload())
                }
            }

            if (authReducer.csvDownloadId === 'starlink-billing-usage') {
                DownloadCSV(convertToCsv(authReducer.csvDataDownloded), {
                    formatters: {
                        1: 'ESC-COMMA',
                    }
                });
                return () => {
                    dispatch(clearCsvDownload())
                }
            }
        }
    }, [authReducer.csvDataDownloded])

    const convertToCsv = (data) => {
        const csvData = data && data.length > 0 && data[1] && data[1]?.length > 0 && data[1][0]?.map(item => {
            const kitSerialNumbers = `"${item?.kitSerialNumbers}"`;
            return [item?.billingDate, item?.accountNumber, kitSerialNumbers, item?.serviceLineName, item?.serviceLineStatus, item?.totalLimit, item?.totalOptInPriorityGb, item?.totalStandardGb, item?.totalUsage,]
        })

        const headers: string[] = ['Billing Date', 'Account Number', 'Kit Serial Number', 'Service Line Name', 'Service line status', 'Total Limit GB', 'Total Opt In PriorityGb', 'Total StandardGb', 'Total Usage'];
        return [headers, ...csvData];
    }

    const handleDownloadReport = () => {
        const params: CsvParameters = {
            type: 'QUERY_CH',
            id: 'starlink-usage',
            payload: {
                searchText: tableParams.search,
                sortBy: tableParams.sortBy,
                sortOrder: tableParams.sortOrder,
                limit: totalAssetsPlanUsage,
                offset: 0,
                usage_filter: selectedFilter,
                id: planFilter?.plan_id ?? '',
                type: planFilter?.type ?? '',
                account_number: planFilter?.account_number,
                dp_id: authReducer?.selectedOu?.id
            },
            queryName: 'GET_STARLINK_ASSETS_PLAN_USAGE_CH',
        }
        downloadAsCSV(params);
    }

    const handleDownloadBillingReport = () => {
        const params: CsvParameters = {
            type: 'QUERY_PG',
            id: 'starlink-billing-usage',
            payload: {
                "searchText": "",
                "sortBy": "serviceLineName",
                "sortOrder": "asc",
                "limit": 10000,
                "offset": 0,
                chartStartDate: selectedDownloadPeriod?.startDate,
                chartEndDate: selectedDownloadPeriod?.endDate,
                serviceLines: Array.isArray(newSummaryServiceLine) && newSummaryServiceLine?.filter(sl => sl !== '').map(sl => `'${sl}'`).join(',')
            },
            queryName: 'GET_SERVICE_LINES_BILLINGS',
        }
        downloadAsCSV(params);
        setOpenBillingDialog(false)
        setSelectedDownloadPeriod(currentMonth)
    }

    useEffect(() => {
        return () => {
            clearComponentData();
        }
    }, [])

    useEffect(() => {
        if (!_.isEmpty(dpIds) && !_.isEqual(prevServiceLinesRef.current, newSummaryServiceLine)) {
            getAllServiceLines({
                dpIds: dpIds,
                serviceLines: Array.isArray(newSummaryServiceLine)
                    ? newSummaryServiceLine.filter(sl => sl !== '')
                    : [],
            });

            prevServiceLinesRef.current = newSummaryServiceLine;
        }
    }, [newSummaryServiceLine]);

    useEffect(() => {
        const hasChanged =
            !_.isEqual(prevValuesRef.current.newSummaryServiceLine, newSummaryServiceLine) ||
            !_.isEqual(prevValuesRef.current.newSummaryEndDate, newSummaryEndDate) ||
            !_.isEqual(prevValuesRef.current.newSummaryStartDate, newSummaryStartDate);

        if (hasChanged) {
            setTableParams({
                ...tableParams,
                startDate: newSummaryStartDate,
                endDate: newSummaryEndDate,
                serviceLines: Array.isArray(newSummaryServiceLine)
                    ? newSummaryServiceLine.filter(sl => sl !== '')
                    : [],
            });

            prevValuesRef.current = {
                newSummaryServiceLine,
                newSummaryEndDate,
                newSummaryStartDate,
            };
        }
    }, [newSummaryServiceLine, newSummaryEndDate, newSummaryStartDate, tableParams]);

    useEffect(() => {
        if (!_.isEmpty(dpIds)) {
            if (showCurrentBillingCycleUsage && selectedAssets?.length === 1) {
                const billingDayOfMonth = selectedAssets[0]?.billing_day_of_month;
                if (billingDayOfMonth) {
                    const currentBillingCycleStartDate = moment().date() < billingDayOfMonth ? moment().subtract(1, 'month').startOf('month').date(billingDayOfMonth) : moment().startOf('month').date(billingDayOfMonth);

                    const currentBillingCycleEndDate = moment().date() < billingDayOfMonth ? moment().startOf('month').date(billingDayOfMonth) : moment().add(1, 'month').startOf('month').date(billingDayOfMonth);

                    setTableParams({
                        ...tableParams,
                        startDate: currentBillingCycleStartDate,
                        endDate: currentBillingCycleEndDate
                    });
                }
            }
        }
    }, [showCurrentBillingCycleUsage])

    useEffect(() => {
        setShowCurrentBillingCycleUsage(false);
    }, [selectedAssets])

    useEffect(() => {
        if (queryParams.get('interval') === '1h' || queryParams.get('interval') === '6h' || queryParams.get('interval') === '12h') {
            queryParams.set('interval', '1d')
            queryParams.set('startDate', moment(Date.now()).subtract(1, 'day').valueOf().toString())
            queryParams.set('endDate', moment(Date.now()).valueOf().toString())
        }
    }, [])

    type TableParams = {
        page: number;
        size: number;
        sortBy: string;
        sortOrder: string;
        search: string;
        startDate: Moment;
        endDate: Moment;
        serviceLines: any;
    };

    const prevTableParamsRef = useRef<TableParams | null>(null);

    useEffect(() => {
        const hasChanged = !_.isEqual(prevTableParamsRef.current, tableParams);

        if (hasChanged) {
            updateQueryParams();

            clearComponentData()

            if (planFilter) {
                getAssetPlanUsageCharts({
                    dpIds: authReducer.selectedOu.id,
                    accounts: selectedServiceAccount,
                    startDate: tableParams.startDate.format(YYYYMMDD_HHmmss),
                    endDate: tableParams.endDate.format(YYYYMMDD_HHmmss),
                    source: getSource(),
                    serviceLines: Array.isArray(newSummaryServiceLine) ? newSummaryServiceLine?.filter(sl => sl !== '') : [],
                    parent_pool_id: parentPoolId,
                    id: planFilter?.plan_id ?? '', 
                    type: planFilter?.type ?? '',
                    account_number: planFilter?.account_number
                });
            }

            if(parentPoolId) {
                let _parameters: any = {
                    dpIds: authReducer.selectedOu.id,
                    accounts: selectedServiceAccount,
                    searchText: tableParams.search,
                    chartStartDate: tableParams.startDate.format(YYYYMMDD_HHmmss),
                    chartEndDate: tableParams.endDate.format(YYYYMMDD_HHmmss),
                    sortBy: tableParams.sortBy,
                    sortOrder: tableParams.sortOrder,
                    page: tableParams.page,
                    limit: tableParams.size,
                    usageSource: getSource(),
                    serviceLines: Array.isArray(newSummaryServiceLine) ? newSummaryServiceLine?.filter(sl => sl !== '') : [],
                    usageFilter: selectedFilter, 
                    parent_pool_id: parentPoolId,
                    id: planFilter?.plan_id ?? '', 
                    type: planFilter?.type ?? '',
                    account_number: planFilter?.account_number
                }
                // clearComponentData()
                getAssetsPlanUsage(_parameters);
            }

            prevTableParamsRef.current = tableParams;
        }
    }, [tableParams, parentPoolId]);

    const filterPopClick = (e: React.MouseEvent<HTMLElement>) => {
        setAnchorElRef(e.currentTarget);
        setFilterPopup(true);
    };

    useEffect(() => {
        if(_.isEmpty(parentPoolId) && _.isEmpty(planFilter)) {
            setTableData([]);
            return;
        };
        let _parameters: any = {
            dpIds: authReducer.selectedOu.id,
            accounts: selectedServiceAccount,
            searchText: tableParams.search,
            chartStartDate: tableParams.startDate.format(YYYYMMDD_HHmmss),
            chartEndDate: tableParams.endDate.format(YYYYMMDD_HHmmss),
            sortBy: tableParams.sortBy,
            sortOrder: tableParams.sortOrder,
            page: tableParams.page,
            limit: tableParams.size,
            usageSource: getSource(),
            serviceLines: Array.isArray(newSummaryServiceLine) ? newSummaryServiceLine?.filter(sl => sl !== '') : [],
            usageFilter: selectedFilter,
            parent_pool_id: parentPoolId,
            id: planFilter?.plan_id ?? '',
            type: planFilter?.type ?? '',
            account_number: planFilter?.account_number
        }
        getAssetsPlanUsage(_parameters);

        getAssetPlanUsageCharts({
            dpIds: authReducer.selectedOu.id,
            accounts: selectedServiceAccount,
            startDate: tableParams.startDate.format(YYYYMMDD_HHmmss),
            endDate: tableParams.endDate.format(YYYYMMDD_HHmmss),
            source: getSource(),
            serviceLines: Array.isArray(newSummaryServiceLine) ? newSummaryServiceLine?.filter(sl => sl !== '') : [],
            parent_pool_id: parentPoolId,
            id: planFilter?.plan_id ?? '', 
            type: planFilter?.type ?? '',
            account_number: planFilter?.account_number
        });
    }, [selectedFilter, parentPoolId, planFilter])

    const handleSubTabsClick = (tabValue) => {
        setSubTab(tabValue);
        setUsagePageFilters(false);
        const params = getDecodeURI(location?.search);
        params.tab = tabValue;
        delete params.cardIndexes;
        history.push({ pathname: location.pathname, search: getEncodedURI(params) })
    }

    const toggleExpand = (key) => {
        setExpand((prevState) => {
            if (key === "isCardExpanded") {
                return { isCardExpanded: !prevState.isCardExpanded, isChartsExpanded: prevState.isChartsExpanded, isTableExpanded: prevState.isTableExpanded };
            }
            if (key === "isChartsExpanded") {
                return { isCardExpanded: true, isChartsExpanded: !prevState.isChartsExpanded, isTableExpanded: false };
            }
            if (key === "isTableExpanded") {
                return { isCardExpanded: true, isChartsExpanded: false, isTableExpanded: !prevState.isTableExpanded };
            }
            return prevState;
        });
    };

    useEffect(() => {
        if (_.isEmpty(assetsPlanUsage)) {
            setTableData([]);
            return;
        }
        if (!_.isEmpty(assetsPlanUsage)) {
            setTableData(assetsPlanUsage);
        }
    }, [assetsPlanUsage, totalAssetsPlanUsage])

    const getPlanFilter = (tab) => {
        if (tab == 'starlink_only') {
            return "WHERE account_type = 'standard'";
        } else if (tab == 'custom_only') {
            return "WHERE account_type = 'pooled'";
        } else {
            return '';
        }
    }

    const getStartOfMonth = () => {
        const date = new Date();
        date.setDate(1);
        date.setHours(0, 0, 0, 0);
        return date.toISOString();
    }

    useEffect(() => {
        getStarlinkPlanUsageHierarchy({ dpId: authReducer.selectedOu?.id, plan_filter: getPlanFilter(subTab), startDate: getStartOfMonth() })
    }, [authReducer.selectedOu, subTab])

    const getAccountType = (data) => {
        if (data?.icon) {
            switch (data.icon) {
                case "CustomPlanIcon":
                    return "custom_plan";
                case "orgPoolIcon":
                    return "sub_pool";
                case "starlinkPlanIcon":
                    return "standard_plan";
                default:
                    return "";
            }
        } else if (data?.account_type) {
            return data.account_type === "pooled" ? "pooled_account" : "standard_account";
        }
    }

    const getAccountId = (data) => {
        if (data?.account_type == 'pooled' || data?.account_type == 'standard') {
            return data?.account_number;
        }
        return data?.id;
    }

    useEffect(() => {
        console.log('starlinkPlanUsageHierarchy',starlinkPlanUsageHierarchy)
        if (_.isEmpty(starlinkPlanUsageHierarchy)) {
            setPlanData([]);
            setPlanFilter({});
            setParentPoolId('');
            setSelectedRow(null);
            return;
        }

        setPlanData(starlinkPlanUsageHierarchy);

        // Open the first row by default
        const firstRow = starlinkPlanUsageHierarchy[0];

        if (firstRow) {
            setExpandedRows({ [firstRow.uid]: true });
            setParentPoolId(firstRow?.parent_pool_id);
            setPlanFilter({
                plan_id: getAccountId(firstRow),
                type: getAccountType(firstRow),
                account_number: getAccountId(firstRow),
            });
            setSelectedRow(firstRow?.uid);
        }
    }, [starlinkPlanUsageHierarchy]);

    return (
        <Grid className="Starlink-Usage-Container">
            <Grid container spacing={1} className="analytics-top-filters-container">
                <Grid container item md={2.5} lg={2.5} className="l-shape-container">
                    <Grid item md={3.1} lg={3.1} className={usagePageFilters ? "l-shape upper" : ''}>
                        <Box className="activityReports-Filter" style={{ width: '100%', height: '100%' }}>
                            <Button
                                variant="outlined"
                                startIcon={<TuneIcon id="filterIcon" />}
                                onClick={() => setUsagePageFilters(prev => !prev)}
                                id="filterButton"
                                style={!usagePageFilters ? { border: '1px solid rgba(36, 76, 132, 1)', height: '32px', marginTop: '8px' } : {}}
                            >
                                Filter
                            </Button>
                        </Box>
                    </Grid>
                    <Grid item md={8.9} lg={8.9} className="other">
                        {/* <Box className="searchbarBox">
                            <TextField
                                id="outlined-basic"
                                variant="outlined"
                                placeholder="Filter Search"
                                autoComplete="off"
                                size="small"
                                value={searchValue}
                                InputProps={{
                                    className: "searchbarInputs",
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            {!searchValue ? (
                                                <SearchIcon className="searchbarIconsPosition" />
                                            ) : (
                                                <CloseIcon className="searchbarIconsPosition" />
                                            )}
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Box> */}
                    </Grid>
                    {usagePageFilters && (
                        <Filters subTabs={subTabs} subTab={subTab} setSubTab={setSubTab} defaultFilters={defaultFilters} selectedFilter={selectedFilter} setSelectedFilter={setSelectedFilter} />
                    )}
                </Grid>
                <Grid className="analytics-sub-tabs-container" item md={9} lg={9}>
                    {subTabs.map(subTabValue => (
                        <Button
                            key={subTabValue.tabValue}
                            className="analytics-sub-tabs"
                            id={subTabValue.tabValue === subTab ? 'selectedTab-background' : ''}
                            onClick={() => handleSubTabsClick(subTabValue.tabValue)}
                        >
                            {subTabValue.tabName}
                        </Button>
                    ))}
                </Grid>
                <Grid item md={0.5} lg={0.5} className="download-expand-icons-container" pl={0}>
                    {/* <img src={newDownloadButton} alt="download" /> */}
                    <img src={upArrow} alt={expand.isCardExpanded ? "collapse" : "expand"} onClick={() => toggleExpand('isCardExpanded')} style={!expand.isCardExpanded ? { transform: 'rotate(180deg)' } : { transform: 'rotate(0deg)' }} />
                </Grid>
            </Grid>
            <Grid style={{ height: 'calc(100vh - 195px)', overflow: 'auto' }}>
                <AssetUsageBarChart selectedAssets={selectedAssets} startDate={tableParams.startDate} endDate={tableParams.endDate} expand={expand} toggleExpand={toggleExpand} parentPoolId={parentPoolId} setParentPoolId={setParentPoolId}
                    planFilter={planFilter} setPlanFilter={setPlanFilter} subTab={subTab} setSelectedRow={setSelectedRow} setExpandedRows={setExpandedRows} selectedRow={selectedRow} expandedRows={expandedRows} planData={planData} />
                <Paper elevation={1} classes={{ root: 'starlink--base--mtb_07x' }} style={{ boxShadow: 'none' }}>
                    {/* <div className="download-summary-btn marg-left-auto" onClick={handleDownloadReport}><img className="downloadImg" src={DownloadIcon} alt="" /><Button>Download</Button></div> */}
                    <div style={{ boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)' }}>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', margin: '0 6px 0 15px', height: '46px' }}>
                            <span style={{ fontFamily: 'Inter', fontWeight: '600', fontSize: '14px' }}>Service Lines</span>
                            <Grid display={"flex"} alignItems={"center"}>
                                <Grid display={"flex"}>
                                    <Grid ml={2} className="analytics-sub-tabs-container" style={{ paddingLeft: "0" }}>
                                        <Button
                                            style={{ width: "120px" }}
                                            className="analytics-sub-tabs"
                                            id={selectedFilter === "optIn" ? "selectedTab-background" : ""}
                                            onClick={() => handleFilterChange("optIn")}
                                        >
                                            Opt In Usage
                                        </Button>

                                    </Grid>

                                    <Grid ml={1} className="analytics-sub-tabs-container" style={{ paddingLeft: "0" }}>
                                        <Button
                                            style={{ width: "120px" }}
                                            className="analytics-sub-tabs"
                                            id={selectedFilter === "totalUsage" ? "selectedTab-background" : ""}
                                            onClick={() => handleFilterChange("totalUsage")}
                                        >
                                            Total Usage
                                        </Button>

                                    </Grid>



                                </Grid>
                                <Grid container display={'flex'} alignItems={'center'} justifyContent={'flex-end'}>
                                    <Grid ml={1}>
                                        <TextField
                                            id="search"
                                            variant="outlined"
                                            placeholder="Type something"
                                            autoComplete="off"
                                            classes={{ root: "input-box-starlink-rp" }}
                                            size="small"
                                            value={searchText}
                                            onChange={handleOnSearchChange}
                                            onKeyDown={(e) => { if (e.key === 'Enter') { handleOnSearchClick() } }}
                                            InputLabelProps={{ className: "serachLabel" }}
                                            InputProps={{
                                                className: "serachBar",
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        {!searchText && <SearchIcon
                                                            className="cursorPointer input-search-icons" onClick={handleOnSearchClick}
                                                        />}
                                                        {searchText && <CloseIcon
                                                            className="cursorPointer input-search-icons"
                                                            onClick={handleOnSearchClear}
                                                        />}
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </Grid>
                                    <Grid ml={1}>
                                        <Tooltip classes={{ tooltip: 'starlink--tooltip' }} title={
                                            <Typography component={'div'} className="starlink--base--font_08x starlink--base--padding_05x">
                                                The data is retrieved directly from the Starlink cloud and may be subject to delays in availability.
                                            </Typography>
                                        }>
                                            <QuestionMarkOutlinedIcon sx={{ padding: "15px" }} className="marg-left-auto" />
                                        </Tooltip>
                                    </Grid>
                                    <Grid ml={1}>
                                        <Button variant="contained" size="small" className='starlink--button_contained--primary' onClick={handleOpenAvailabilityAlertDialog} disabled={false} startIcon={<NotificationAddIcon />}>Availability</Button>
                                    </Grid>
                                    {/* <Grid ml={1}>
                                        {checkLoggedInUserAuthorizedToViewPage(starlinkAdminService, starlinkAdminFunction) ? <Button variant="contained" size="small" className='starlink--button_contained--primary' onClick={handleExportCSV} disabled={disableExport || totalAssetsPlanUsage === 0} startIcon={<Download />}>Download</Button> : null}
                                    </Grid> */}
                                    <Grid ml={1}>
                                        {checkLoggedInUserAuthorizedToViewPage(starlinkAdminService, starlinkAdminFunction) ? <Button variant="contained" size="small" className='starlink--button_contained--primary' onClick={() => { setOpenBillingDialog(true) }} disabled={disableExport || totalAssetsPlanUsage === 0} startIcon={<Download />}>Billing</Button> : null}
                                    </Grid>
                                </Grid>
                                {totalAssetsPlanUsage > 0 && <img src={NewDownloadButton} alt="download" onClick={handleDownloadReport} style={{ width: '38px', height: '47px', cursor: 'pointer' }} />}
                                <img src={upArrow} alt={expand.isTableExpanded ? "collapse" : "expand"} onClick={() => toggleExpand('isTableExpanded')} style={!expand.isTableExpanded ? { transform: 'rotate(180deg)', cursor: 'pointer', marginLeft: '10px' } : { transform: 'rotate(0deg)', cursor: 'pointer', marginLeft: '10px' }} />
                                
                            </Grid>
                        </div>
                        {expand.isTableExpanded && <>
                            <Pagination count={totalAssetsPlanUsage} onPageChange={handleChangePage} page={tableParams.page} rowsPerPage={tableParams.size} onRowsPerPageChange={handleChangeSize} />
                            <AssetsTable startDate={tableParams.startDate} endDate={tableParams.endDate} sortBy={tableParams.sortBy} sortOrder={tableParams.sortOrder} selectedAssets={selectedAssets} onChange={handleChangeSelectedAssets} onChangeSorting={handleChangeSorting} onEditServiceLineQuota={handleEditServiceLineQuota} onEditServiceLineAvailability={handleEditServiceLineAvailability} tableData={tableData} />
                        </>}
                    </div>
                </Paper>
            </Grid>

            <QuotaAlertDialog open={openQuotaAlertDialog || serviceLineQuotaToEdit !== null} onClose={handleCloseQuotaAlertDialog} serviceLineToEdit={serviceLineQuotaToEdit} onSave={handleSetQuotaAlert} />

            <AvailabiltyAlertDialog open={openAvailabilityAlertDialog || serviceLineAvailabilityToEdit !== null} onClose={handleCloseAvailabilityAlertDialog} serviceLineToEdit={serviceLineAvailabilityToEdit} onSave={handleSetAvailabilityAlert} />

            <Dialog
                open={openBillingDialog}
                onClose={() => { setOpenBillingDialog(false); setSelectedDownloadPeriod(currentMonth) }}
                aria-labelledby="service_account--dialog"
                maxWidth="sm"
                fullWidth
                PaperProps={{
                    style: {
                        padding: '2em',
                        borderRadius: '10px',
                        minWidth: '400px'
                    }
                }}
            > <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography
                            variant="h5"
                            component="div"
                            align="center"
                            style={{ fontWeight: 'bold', color: "#424141" }}
                        > Download Billing for Month  </Typography>
                    </Grid>

                    <Grid item xs={6}>
                        <Typography fontSize={"0.8rem"}>Select Month</Typography>
                        <Select
                            fullWidth
                            size="small"
                            value={selectedDownloadPeriod?.value || ''}
                            onChange={(e) => {
                                const selectedMonth = lastThreeMonthsWithCurrent.find(month => month.value === e.target.value);
                                setSelectedDownloadPeriod(selectedMonth);
                            }}
                            displayEmpty
                        >
                            <MenuItem value="" disabled>Select Duration</MenuItem>
                            {lastThreeMonthsWithCurrent.map((month) => (
                                <MenuItem key={month.value} value={month.value}>
                                    {month.label} </MenuItem>
                            ))}
                        </Select>
                    </Grid>

                    <Grid item xs={12} display="flex" justifyContent="flex-end">
                        <Button
                            variant="outlined" className='starlink--button--cancel'
                            style={{ marginRight: '10px' }}
                            onClick={() => { setOpenBillingDialog(false); setSelectedDownloadPeriod(currentMonth) }}
                        > Cancel
                        </Button>
                        <Button
                            variant="outlined" className='starlink--button_contained--primary'
                            onClick={() => { handleDownloadBillingReport() }}
                        >Download </Button>
                    </Grid>
                </Grid>
            </Dialog>


        </Grid>
    );
}

const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
    errorReducer: state.errorReducer,
    dpIds: state.starlinkCloud.dpIds,
    commonFilters: state.starlinkCloud.commonFilters,
    assetsPlanUsage: state.starlinkReports.assetsPlanUsage,
    assetsCSV: state.starlinkReports.assetsCSV,
    assetUsage: state.starlinkReports.assetUsage,
    totalAssetsPlanUsage: state.starlinkReports.totalAssetsPlanUsage,
    newSummaryStartDate: state?.authReducer?.newSummaryStartDate,
    newSummaryEndDate: state?.authReducer?.newSummaryEndDate,
    newSummaryServiceLine: state?.authReducer?.newSummaryServiceLine,
    serviceAccounts: state.starlinkAdmin.serviceAccounts,
    selectedAccount: state.starlinkCloud.selectedAccount,
    accountsData: state.starlinkCloud.accountsData,
    assetUsageSplit: state.starlinkReports.assetUsageSplit,
    selectedServiceAccount: state?.authReducer?.selectedServiceAccount,
    starlinkPlanUsageHierarchy: state.starlinkReports.starlinkPlanUsageHierarchy
});

export default withRouter(
    connect(mapStateToProps, {
        updatePermission,
        parentDpAndSubDpList,
        getAssetsPlanUsage,
        getAssetUsage,
        getAssetsCSV,
        setQuotaAlert,
        setAvailabilityAlert,
        clearComponentData,
        clearCSV,
        downloadAsCSV,
        getAllServiceLines,
        setSelectedAccount,
        getAssetPlanUsageCharts,
        getStarlinkPlanUsageHierarchy
    })(StarlinkReports)
);