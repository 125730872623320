import { useState, useEffect, Fragment } from "react";
import { Button, Grid, Tab, Tabs } from "@mui/material";
import AlertTablePage from "../../components/AlertTablePage";
import { SERVICE_FUNCTION_TO_FEATURE } from "../../config";
import { checkLoggedInUserAuthorizedToViewPage, getDecodeURI, getEncodedURI, strToK4List, updateTimePeriod } from "../../utils/util";
import NotAuthorized from "../NotAuthorized"
import { withRouter } from "react-router-dom";
import { useHistory, useLocation } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { FIREWALL_REPORT, SIDE_MENU_ITEM_ALERTS, USER_DATA } from "../../utils/constants";
import BreadCrumbs from "../../components/BreadCrumbs/BreadCrumbs";
import { BC_DASHBOARD_ALERTS } from "../../constants/Constants";
import { updatePermission } from "../../actions/Users/authenticate";
import FirewallReport from "../FirewallReport";
import UserData from "../UserData";
import './alertPage.css';
import EdgeEventsGlobal from '../../components/EdgeEvents/EdgeEventsGlobal';
import SubHeaderSummary from "../../components/SubHeaderSummary";
import _ from "lodash"
import CommonFilters from "../../components/BreadCrumbs/CommonFilters";
import DpLevelAlertsConfig from "../../components/DpLevelAlertsConfig";
import TabsComponent from "../../components/NewTabs";

var Loader = require("react-loader");

const AlertPage = (props) => {
  const ALERTS_CONFIGURATION = "alertsConfiguration"
  const { authReducer, updatePermission, location, match, alertsDashboard } = props;
  const history = useHistory();
  const [isAuthorizedToViewPage, setIsAuthorizedToViewPage] = useState<Boolean>(false);
  const VIEW_ALERTS = "view-alerts";
  const [loading, setLoading] = useState(false);
  const not_authorized_page_title = "Alerts";
  const not_authorized_page_message = "You are not authorised to view alerts";
  const viewAlertService = SERVICE_FUNCTION_TO_FEATURE[VIEW_ALERTS]["service"];
  const viewAlertFunction = SERVICE_FUNCTION_TO_FEATURE[VIEW_ALERTS]["function"];
  const breadcrumbInfo = BC_DASHBOARD_ALERTS;
  const [isAlertsLoading, setAlertsLoading] = useState(false)
  const [activeTab, setActiveTab] = useState(match?.params?.tab ? match.params.tab : "alerts");
  const [vesselIds, setVesselIds] = useState([]);
  const [showSummaryPeriod, setShowSummaryPeriod] = useState<boolean>(() => match?.params?.tab && match.params.tab !== "alerts" && match.params.tab !== ALERTS_CONFIGURATION);
  const [isAuthorizedToViewBetaFeature, setIsAuthorizedToViewBetaFeature] = useState<Boolean>(false);
  const [helpPageName, setHelpPageName] = useState("alerts")
  const betaFeatureService = SERVICE_FUNCTION_TO_FEATURE["beta-feature"]["service"];
  const betaFeatureFunction = SERVICE_FUNCTION_TO_FEATURE["beta-feature"]["function"];
  const dispatch = useDispatch();

  const getDefaultTabValue = () => {
    let tab  = match.params.tab
    return getTabValue(tab)
   }

  const getTabValue = (tab: string) => {
    if (tab.includes(ALERTS_CONFIGURATION)) {
      return 2
    } else if (tab.includes("events")) {
      return 1
    } else {
      return 0
    }
  }
   const [value, setValue] = useState(getDefaultTabValue());
  if (authReducer && (!authReducer.sideMenu || SIDE_MENU_ITEM_ALERTS !== authReducer.sideMenu.menuItem)) {
    authReducer.sideMenu = { menuItem: SIDE_MENU_ITEM_ALERTS };
  }

  useEffect(() => {
    const authorizedBetaUser = checkLoggedInUserAuthorizedToViewPage(betaFeatureService, betaFeatureFunction);
    setIsAuthorizedToViewBetaFeature(authorizedBetaUser)

    const params = getDecodeURI(location?.search);
    const { startDate, endDate } = updateTimePeriod(dispatch, location);
    params.startDate = startDate;
    params.endDate = endDate;
    history.push({ pathname: location.pathname, search: getEncodedURI(params) })
  }, [])

  useEffect(() => {
    let authorized = checkLoggedInUserAuthorizedToViewPage(viewAlertService, viewAlertFunction);
    setIsAuthorizedToViewPage(authorized);
    let info = {
      permission: {
        service: viewAlertService,
        serviceFunction: viewAlertFunction,
      },
      isMultiVessel: false,
      isSingleVessel: false,
      showOu: false,
      ou: authReducer?.selectedOu ? authReducer.selectedOu : ''
    };
    if (authorized) {
      info.isMultiVessel = value === 2 ? false : true;
      info.showOu = true;
    }
    updatePermission(info);
  }, [value]);

  useEffect(() => {
    setAlertsLoading(authReducer?.searchAlertsLoading);
  },[authReducer?.loading])


  const doNavigateSIM = (pathname, tabName) => {
    const currentParams = getDecodeURI(location?.search);
    const params:any = {};
    if(currentParams.ouName) {
        params['ouName'] = currentParams.ouName;
    }
    if(currentParams.ouId) {
        params['ouId'] = currentParams.ouId;
    }
    if(currentParams.k4Ids) {
        params['k4Ids'] = currentParams.k4Ids;
    }
    if(currentParams.sideNav){
      params['sideNav'] = currentParams.sideNav
    }
    if(currentParams.interval) {
      params['interval'] = currentParams.interval
    }
    const { startDate, endDate } = updateTimePeriod(dispatch, location);
    params.startDate = startDate;
    params.endDate = endDate;
    if(currentParams.status) {
      params['status'] = currentParams.status;
    }
    history.push({ pathname, search: getEncodedURI(params) })

    setActiveTab(tabName);
    setValue(getTabValue(tabName));
   if(tabName !== 'alerts' && tabName !== ALERTS_CONFIGURATION) {
      setShowSummaryPeriod(true);
    }
    // help section document page
    // https://kb.kognitive.net/kb/<helpPageName>
    if (tabName.includes(ALERTS_CONFIGURATION)) {
      setHelpPageName("alerts-config-organization")
    } else if (tabName.includes("events")) {
      setHelpPageName("events-1")
    } else {
      setHelpPageName("alerts")
    }
}

  useEffect(() => {
    if (_.isEmpty(authReducer?.getVesselsListing))
      return;
    let vessels = [];
    const filteredVessels = authReducer?.filteredVessels;
    if (filteredVessels && filteredVessels.length > 0) {
      vessels = filteredVessels;
    } else {
      vessels = authReducer?.getVesselsListing?.locations;
    }
    setVesselIds(vessels);
  }, [authReducer?.getVesselsListing, authReducer?.filteredVessels])

  const handleSelctedSubDetails = () => {}

  const onShowSummaryPeriod = (value: boolean) => {
    setShowSummaryPeriod(value)
  }

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);

    if(newValue === 0 ){doNavigateSIM("/alerts/alerts", "alerts")  }
    if(newValue === 1 ){  doNavigateSIM("/alerts/events", "events") }
    if(newValue === 2 ){  doNavigateSIM("/alerts/alertsConfiguration", ALERTS_CONFIGURATION) }
    
  };

  useEffect(() => {
    setLoading( authReducer?.loading || authReducer.getEdgeEventsFilterLoading || authReducer.getEdgeEventsLoading || alertsDashboard.alertsMainInfoLoading || authReducer?.dpAlertsLoading || authReducer?.updateDpAlertLoading || authReducer?.updateDpAlertNotificationLoading || authReducer?.deleteDpAlertLALoading || authReducer?.dpAlertLogsLoading || authReducer?.updateDpAlertStatusLoading || authReducer?.deleteAlertLoading);
  }, [ authReducer?.loading, authReducer.getEdgeEventsFilterLoading, authReducer.getEdgeEventsLoading, alertsDashboard.alertsMainInfoLoading, authReducer?.dpAlertsLoading, authReducer?.updateDpAlertLoading, authReducer?.updateDpAlertNotificationLoading, authReducer?.deleteDpAlertLALoading, authReducer?.dpAlertLogsLoading, authReducer?.updateDpAlertStatusLoading, authReducer?.deleteAlertLoading]); 

  const tabs = [
    { tabName: 'Alerts', tabValue: 'alerts', pathName: '/alerts/alerts' },
    { tabName: 'Events', tabValue: 'events', pathName: '/alerts/events' },
    { tabName: 'Alerts - Config', tabValue: 'alertsConfiguration', pathName: '/alerts/alertsConfiguration' }
  ]
  
  return (
    <div className="Form">
       <CommonFilters loading={loading || authReducer?.userPreferencesLoading} showSummaryPeriod={showSummaryPeriod} showWantType={false} userGuide={[true, helpPageName]} />
       <TabsComponent tabs={tabs} handleChangeTab={doNavigateSIM} activeTab={activeTab} />
      <Grid item container style={{ backgroundColor: "#fff" }} marginTop={0}>
          <Grid key="content" item container className="ReportPagesContainer">
            <Grid key="content1" className="firewall-scroll" item>
                <Grid>
                    <div>
                        <div className="alertsContainerGlobal">
                            {
                              "alerts" === activeTab ? (
                                <Fragment key={"alerts"}>
                                      <AlertTablePage showSummaryPeriod={onShowSummaryPeriod}/>
                                </Fragment>
                            ) : 
                            "events" === activeTab ? (
                                <Fragment key={"events"}>
                                    <EdgeEventsGlobal selectedVesselName={"Alert"} vesselIds={vesselIds} globalEventsLoading={authReducer?.getEdgeEventsLoading} ></EdgeEventsGlobal>
                                    {/* <FirewallReport /> */}
                                </Fragment>
                            )
                              :
                            ALERTS_CONFIGURATION === activeTab ? (
                              <Fragment key={ALERTS_CONFIGURATION}>
                                  <DpLevelAlertsConfig/>
                              </Fragment>
                            )
                              :
                                null}
                        </div>
                    </div>
                </Grid>
            </Grid>
      </Grid>
          {/* {
            isAuthorizedToViewPage ? [
              <Grid style={{ width: "100%" }}>
                <AlertTablePage />
              </Grid>
            ] :
              <Grid item container style={{ position: "fixed", paddingBottom: "16px", backgroundColor: "#ffffff", zIndex: 10 }}>
                <NotAuthorized pageTitle={not_authorized_page_title} PageMessage={not_authorized_page_message} />
              </Grid>
          } */}
      </Grid>
    </div>
  );
}

const mapStateToProps = (state) => ({
  authReducer: state.authReducer,
  alertsDashboard: state.alertsDashboard,
  errorReducer: state.errorReducer,
});

export default withRouter(
  connect(mapStateToProps, { updatePermission })(AlertPage)
);