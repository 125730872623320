import { withRouter } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { Grid, Switch } from "@mui/material";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useState, useEffect } from "react";
import { getCaptiveDashTopSitesUsageBar, getCaptiveDashTopSitesSubscribersBar, getCaptiveDashTopSitesSessionsBar, getCaptiveDashTopSubscribersUsageBar, getCaptiveDashTopSubscribersSessionsBar, getCaptiveDashTopPlansUsageBar, getCaptiveDashTopPlansUsersBar } from "../../../actions/Users/authenticateCaptiveDahshboard";
import _ from "lodash";
import './index.css';
import { getDecodeURI, getEncodedURI, readableBytesAsGB, readableBytesAsMB } from "../../../utils/util";
import { TablePaginationActions } from "../../../components/Pagination";
import { GET_CAPTIVE_DASHBOARD_TOP_PLANS_USERS_BAR, GET_CAPTIVE_DASHBOARD_TOP_SITES_SESSIONS_BAR, GET_CAPTIVE_DASHBOARD_TOP_SITES_SUBSCRIBERS_BAR, GET_CAPTIVE_DASHBOARD_TOP_SITES_USAGE_BAR, GET_CAPTIVE_DASHBOARD_TOP_SUBSCRIBERS_SESSIONS_BAR, GET_CAPTIVE_DASHBOARD_TOP_SUBSCRIBERS_USAGE_BAR, GET_CAPTIVE_DASHBOARD_TOP_PLANS_USAGE_BAR } from "../../../actions/types";

const BarChart = (props) => {
    const { newSummaryStartDate, newSummaryEndDate, getCaptiveDashTopSitesUsageBar, getCDSitesUsageBar, vessels, history, location, getCaptiveDashTopSitesSubscribersBar, getCDTopSitesSubscribersBar, getCaptiveDashTopSitesSessionsBar,
        getCDTopSitesSessionsBar, topcardSelected, getCaptiveDashTopSubscribersUsageBar, getCDTopSubscribersUsageBar, getCaptiveDashTopSubscribersSessionsBar, getCDTopSubscribersSessionsBar, getCaptiveDashTopPlansUsageBar, getCDTopPlansUsageBar, 
        getCaptiveDashTopPlansUsersBar, getCDTopPlansUsersBar, selectedTab, values, setValues, applyFilterClick, chartRef={}, showToggle, chartView, setChartView, isHistogram } = props;
    const _q = getDecodeURI(location?.search);
    const [barChartData, setBarChartData] = useState<any>();
    const [barLimit, setBarLimit] = useState(10);
    const [dataUnit, setDataUnit] = useState('GB');
    const [page, setPage] = useState(1);
    const [count, setCount] = useState<any>(0);
    const rowsPerPage = parseInt('10');
    const dispatch = useDispatch();
    const { selectedSubscribers, selectedPlans, selectedPlanDurations, selectedPlanQuota, selectedPlanStatus, selectedPlanStartFrom, selectedPlanStartTo, selectedPlanEndFrom, selectedPlanEndTo, planAutoRenewal } = values;
    const { setSelectedSubscribers, setSelectedPlans } = setValues;

    const fetchChartData = (page = 1) => {
        const tabToChartMap = {
            'sites': {
                'SUBSCRIBERS': () => getCaptiveDashTopSitesSubscribersBar(vessels, newSummaryStartDate, newSummaryEndDate, 10, page, selectedSubscribers),
                'SESSIONS': () => getCaptiveDashTopSitesSessionsBar(vessels, newSummaryStartDate, newSummaryEndDate, 10, page, selectedSubscribers),
                'TOTAL USAGE': () => getCaptiveDashTopSitesUsageBar(vessels, newSummaryStartDate, newSummaryEndDate, 10, page, selectedSubscribers),
            },
            'subscribers': {
                'TOTAL USAGE': () => getCaptiveDashTopSubscribersUsageBar(vessels, newSummaryStartDate, newSummaryEndDate, 10, page, selectedSubscribers, selectedPlans),
                'SESSIONS': () => getCaptiveDashTopSubscribersSessionsBar(vessels, newSummaryStartDate, newSummaryEndDate, 10, page, selectedSubscribers, selectedPlans)
            },
            'plans': {
                'TOTAL USAGE': () => (chartView === 'subscribers' || isHistogram)
                    ? getCaptiveDashTopPlansUsersBar(vessels, newSummaryStartDate, newSummaryEndDate, 10, page, selectedPlans, selectedPlanDurations, selectedPlanQuota, selectedPlanStatus, selectedPlanStartFrom, selectedPlanStartTo, selectedPlanEndFrom, selectedPlanEndTo, planAutoRenewal)
                    : getCaptiveDashTopPlansUsageBar(vessels, newSummaryStartDate, newSummaryEndDate, 10, page, selectedPlans, selectedPlanDurations, selectedPlanQuota, selectedPlanStatus, selectedPlanStartFrom, selectedPlanStartTo, selectedPlanEndFrom, selectedPlanEndTo, planAutoRenewal)
            }
        };
        setPage(page);
        setBarLimit(10);
        if (vessels?.length > 0) {
            tabToChartMap[selectedTab]?.[topcardSelected]?.();
        } else {
            setBarChartData([]);
        }
    };
    
    useEffect(() => {
        fetchChartData(1);
    }, [vessels, newSummaryStartDate, newSummaryEndDate, selectedTab, topcardSelected, chartView, isHistogram]);

    useEffect(() => {
        if (!_.isEmpty(getCDSitesUsageBar)) {
            const data = getCDSitesUsageBar;
            setCount(data?.['']?.['rows']?.[0]?.[3])
            if (data) {
                const series = data || {};
                const seriesKeys = Object.keys(series);
                const gbValue = Math.pow(1000, 3); 
                const isGBCrossed = seriesKeys.some(key => {
                    const seriesValue = series[key];
                    return _.isArray(seriesValue.rows) && seriesValue.rows.some(item => item[2] > gbValue);
                });
                const convertBytes = isGBCrossed ? readableBytesAsGB : readableBytesAsMB;
                let usage = seriesKeys.map(key => {
                    const quotaUsed = Number(convertBytes(series[key]?.rows?.[0]?.[2], false));
                    const k4Id = series[key]?.rows?.[0]?.[0];
                    return { name: key, y: quotaUsed, k4Id };
                });
                setDataUnit(isGBCrossed ? 'GB' : 'MB');
                setBarChartData(_.orderBy(usage.slice(1), ['y'], ['desc']));
            }
        } 
        if (!_.isEmpty(getCDTopSitesSubscribersBar)) {
            const data = getCDTopSitesSubscribersBar;
            setCount(data?.['']?.['rows']?.[0]?.[3])
            if (data) {
                let subscribersData: any = Object.keys(data).map(key => {
                    const subscribers = data[key]?.rows?.[0]?.[2];
                    const k4Id = data[key]?.rows?.[0]?.[0];
                    return { name: key, y: subscribers, k4Id }
                })
                setBarChartData(_.orderBy(subscribersData.slice(1), ['y'], ['desc']));
            }
        } 
        if (!_.isEmpty(getCDTopSitesSessionsBar)) {
            const data = getCDTopSitesSessionsBar;
            setCount(data?.['']?.['rows']?.[0]?.[3])
            if (data) {
                let sessionsData: any = Object.keys(data).map(key => {
                    const sessions = data[key]?.rows?.[0]?.[2];
                    const k4Id = data[key]?.rows?.[0]?.[0];
                    return { name: key, y: sessions, k4Id }
                })
                setBarChartData(_.orderBy(sessionsData.slice(1), ['y'], ['desc']));
            }
        }
        if (!_.isEmpty(getCDTopSubscribersUsageBar?.rows)) {
            const data = getCDTopSubscribersUsageBar;
            setCount(data?.['rows']?.[0]?.[3])
            const gbValue = Math.pow(1000, 3);
            const isGBCrossed = data?.rows?.some(row => row?.[2] > gbValue);
            const convertBytes = isGBCrossed ? readableBytesAsGB : readableBytesAsMB;
            const usageData = _.orderBy(
                data?.rows?.slice(1)?.map(row => {
                    const usage = Number(convertBytes(row?.[2], false));
                    const name = row?.[0];
                    return { name, y: usage };
                }),
                ['y'],
                ['desc']
            );
            setDataUnit(isGBCrossed ? 'GB' : 'MB');
            setBarChartData(usageData);
        } 
        if (!_.isEmpty(getCDTopSubscribersSessionsBar)) {
            setCount(getCDTopSubscribersSessionsBar?.['rows']?.[0]?.[3])
            const sessionsData = _.orderBy(
                getCDTopSubscribersSessionsBar?.rows?.slice(1)?.map(row => {
                    const sessions = row?.[2];
                    const name = row?.[0];
                    return { name, y: sessions };
                }),
                ['y'],
                ['desc']
            );
            setBarChartData(sessionsData);
        }
        if (!_.isEmpty(getCDTopPlansUsageBar)) {
            const data = getCDTopPlansUsageBar;
            setCount(data?.['']?.['rows']?.[0]?.[3])
            if (data) {
                const series = data || {};
                const seriesKeys = Object.keys(series);
                const gbValue = Math.pow(1000, 3); 
                const isGBCrossed = seriesKeys.some(key => {
                    const seriesValue = series[key];
                    return _.isArray(seriesValue.rows) && seriesValue.rows.some(item => item[2] > gbValue);
                });
                const convertBytes = isGBCrossed ? readableBytesAsGB : readableBytesAsMB;
                let usage = seriesKeys.map(key => {
                    const quotaUsed = Number(convertBytes(series[key]?.rows?.[0]?.[2], false));
                    const k4Id = series[key]?.rows?.[0]?.[0];
                    return { name: key, y: quotaUsed, k4Id };
                });
                setDataUnit(isGBCrossed ? 'GB' : 'MB');
                setBarChartData(_.orderBy(usage.slice(1), ['y'], ['desc']));
            }
        }
        if (!_.isEmpty(getCDTopPlansUsersBar)) {
            const data = getCDTopPlansUsersBar;
            setCount(data?.['']?.['rows']?.[0]?.[3])
            if (data) {
                let subscribersData: any = Object.keys(data).map(key => {
                    const subscribers = data[key]?.rows?.[0]?.[2];
                    return { name: key, y: subscribers }
                })
                setBarChartData(_.orderBy(subscribersData.slice(1), ['y'], ['desc']));
            }
        } 
        dispatch({type: GET_CAPTIVE_DASHBOARD_TOP_SITES_USAGE_BAR, payload: {}})
        dispatch({type: GET_CAPTIVE_DASHBOARD_TOP_SITES_SUBSCRIBERS_BAR, payload: {}})
        dispatch({type: GET_CAPTIVE_DASHBOARD_TOP_SITES_SESSIONS_BAR, payload: {}})
        dispatch({type: GET_CAPTIVE_DASHBOARD_TOP_SUBSCRIBERS_USAGE_BAR, payload: {}})
        dispatch({type: GET_CAPTIVE_DASHBOARD_TOP_SUBSCRIBERS_SESSIONS_BAR, payload: {}})
        dispatch({type: GET_CAPTIVE_DASHBOARD_TOP_PLANS_USAGE_BAR, payload: {}})
        dispatch({type: GET_CAPTIVE_DASHBOARD_TOP_PLANS_USERS_BAR, payload: {}})
    }, [getCDSitesUsageBar, getCDTopSitesSubscribersBar, getCDTopSitesSessionsBar, getCDTopSubscribersUsageBar, getCDTopSubscribersSessionsBar, getCDTopPlansUsageBar, getCDTopPlansUsersBar])

    const pieOptions = {
        chart: {
            type: 'bar',
            height: barLimit * 30,
            style: {
                fontFamily: "Roboto, Nunito Sans, Arial, Verdana, Helvetica, sans-serif",
            },
            backgroundColor: 'none',
            events: {
                render: function (this: any) {
                    if (selectedTab === 'sites' && vessels?.length > 1) {
                        const labels = this.xAxis[0].labelGroup.element.querySelectorAll('text');
                        labels.forEach((label, index) => {
                            const dataPoint = this.series[0].data[index];
                            if (dataPoint && dataPoint.k4Id) {
                                label.setAttribute('data-k4id', dataPoint.k4Id);
                            }
                            // Add click event listener to the label
                            label.addEventListener('click', function () {
                                const siteName = label.innerHTML;
                                const k4Id = label.getAttribute('data-k4id');
                                const row = { siteName: siteName, k4Id: k4Id };
                                handleSiteClick(row);
                            });
                        });
                    }
                    if (selectedTab === 'subscribers') {
                        const labels = this.xAxis[0].labelGroup.element.querySelectorAll('text');
                        labels.forEach((label, index) => {
                            const dataPoint = this.series[0].data[index];
                            if (dataPoint && dataPoint.name) {
                                label.setAttribute('data-subscriber-name', dataPoint.name);
                            }
                            label.addEventListener('click', function () {
                                const subscriberName = label.getAttribute('data-subscriber-name');
                                const row = { name: subscriberName };
                                handleSubscriberClick(row);
                            });
                        });
                    }
                    if (selectedTab === 'plans') {
                        const labels = this.xAxis[0].labelGroup.element.querySelectorAll('text');
                        labels.forEach((label, index) => {
                            const dataPoint = this.series[0].data[index];
                            if (dataPoint && dataPoint.name) {
                                label.setAttribute('data-subscriber-name', dataPoint.name);
                            }
                            label.addEventListener('click', function () {
                                const planName = label.getAttribute('data-subscriber-name');
                                const row = { name: planName };
                                handleClickPlan(row);
                            });
                        });
                    }
                }
            }
        },
        title: {
            text: ''
        },
        xAxis: {
            type: 'category',
            title: {
                text: ''
            },
            gridLineDashStyle: 'Dot',
            gridLineWidth: 1,
            labels: {
                style: {
                    color: '#4c3aff',
                    cursor: 'pointer',
                    width: '100px',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                }
            }
        },
        yAxis: {
            min: 0,
            title: {
                text: ''
            },
            labels: {
                format: (topcardSelected === 'TOTAL USAGE' && chartView != 'subscribers') ? `{value} ${dataUnit}` : `{value}`,
            },
            gridLineDashStyle: 'Dot',
            gridLineWidth: 1,
        },
        tooltip: {
            headerFormat: ``,
            pointFormat: `<br/><span style="color: #7e8089">{point.name}</span> <br/> <b style="color: #fff">{point.y} ${topcardSelected === 'TOTAL USAGE' && chartView != 'subscribers' ? dataUnit : ''}`,
            valueDecimals: topcardSelected === 'TOTAL USAGE' && chartView != 'subscribers' ? 2 : 0,
            borderRadius: 15,
            backgroundColor: '#060606'
        },
        plotOptions: {
            bar: {
                allowPointSelect: true,
                cursor: 'pointer',
                shadow: false,
                dataLabels: {
                    enabled: true,
                    format: topcardSelected === 'TOTAL USAGE' && chartView != 'subscribers' ? '{point.y} GB' : ''
                },
                pointWidth: 15,
            },
        },
        legend: {
            enabled: false
        },
        credits: {
            enabled: false
        },
        series: [{
            data: !_.isEmpty(barChartData) ? barChartData : [],
            dataLabels: {
                enabled: false
            },
            color: '#86c6df'
        }]
    };

    const handleSiteClick = (row) => {
        let params: any = getDecodeURI(location?.search);
        params.k4Ids = `${row.k4Id}:${row.siteName}`;
        delete params.page;
        history.push({ pathname: '/captive', search: `?${getEncodedURI(params)}` });
    }

    const handleSubscriberClick = (row) => {
        let params: any = getDecodeURI(location?.search);
        params.subscribers = `${row.name}`;
        delete params.page;
        history.push({ pathname: '/captive', search: `?${getEncodedURI(params)}` });
        setSelectedSubscribers([row.name])
        applyFilterClick([row.name], selectedPlans)
    }

    const handleClickPlan = (row) => {
        setSelectedPlans([row.name])
        applyFilterClick(selectedSubscribers, [row.name], selectedPlanDurations, selectedPlanQuota, selectedPlanStatus, selectedPlanStartFrom, selectedPlanStartTo, selectedPlanEndFrom, selectedPlanEndTo, planAutoRenewal);
    }

    const handleChangePage = (e, value) => {
        fetchChartData(value);
    }

    const handleDisplayDetails = ({ from, to, count }) => {
        return `${from}-${to} of ${count} items`
    }

    const getLabelDisplayedRowsTo = () => {
        if (count === -1) {
            return page * rowsPerPage;
        }
        return rowsPerPage === -1 ? count : Math.min(count, page * rowsPerPage);
    };

    const getAriaLabel = () => { return '' };

    const handleChangeToggle = (e) => {
        let params: any = getDecodeURI(location?.search);
        params.planLineChartView = e.target.checked ? 'usage' : 'subscribers';
        history.push({ pathname: location.pathname, search: `?${getEncodedURI(params)}` });
        if(e.target.checked) { 
            setChartView('usage')
        }
        else { 
            setChartView('subscribers')
        }
    }

    return (
        <Grid>
            <Grid className="new-summary-control-limit title-toggle-btn-div">
                <Grid className="title-dropdown-pie-chart captive-bar-chart-pagination">
                    <Grid className="captive-line-chart-title">
                        {topcardSelected === "TOTAL USAGE" ? "Usage" : topcardSelected == 'SUBSCRIBERS' ? 'Subscribers' : 'Sessions'}
                    </Grid>
                    
                    <p className='pagination-selectLabel records-select'>
                        {handleDisplayDetails({
                            from: count === 0 ? 0 : (page - 1) * rowsPerPage + 1,
                            to: getLabelDisplayedRowsTo(),
                            count: count === -1 ? -1 : count
                        })}
                    </p>
                    <TablePaginationActions
                        className='pagination-actions'
                        count={count}
                        onPageChange={handleChangePage}
                        page={page}
                        rowsPerPage={10}
                        showFirstButton={false}
                        showLastButton={false}
                        getItemAriaLabel={getAriaLabel}
                    />
                </Grid>
                {selectedTab == 'plans' && showToggle && <Grid>
                    <span className="title-text">Subscribers</span>
                    <Switch
                        checked={chartView == 'subscribers' ? false : true}
                        onChange={handleChangeToggle}
                        inputProps={{ 'aria-label': 'controlled' }}
                        size="small"
                    />
                    <span className="title-text usage-trend">Usage</span>
                </Grid>}
            </Grid>
            <Grid className="captive-sites-bar-chart" style={{ height: '300px', overflowY: 'auto' }}>
                <HighchartsReact
                    highcharts={Highcharts}
                    options={pieOptions}
                    ref={chartRef}
                />
            </Grid>
        </Grid>
    );
}

const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
    newSummaryStartDate: state?.authReducer?.newSummaryStartDate,
    newSummaryEndDate: state?.authReducer?.newSummaryEndDate,
    getCDSitesUsageBar: state?.authReducer?.getCDSitesUsageBar?.data,
    getCDTopSitesSubscribersBar: state?.authReducer?.getCDTopSitesSubscribersBar?.data,
    getCDTopSitesSessionsBar: state?.authReducer?.getCDTopSitesSessionsBar?.data,
    getCDTopSubscribersUsageBar: state?.authReducer?.getCDTopSubscribersUsageBar?.data,
    getCDTopSubscribersSessionsBar: state?.authReducer?.getCDTopSubscribersSessionsBar?.data,
    getCDTopPlansUsageBar: state?.authReducer?.getCDTopPlansUsageBar?.data,
    getCDTopPlansUsersBar: state?.authReducer?.getCDTopPlansUsersBar?.data,
});

export default withRouter(
    connect(mapStateToProps, { getCaptiveDashTopSitesUsageBar, getCaptiveDashTopSitesSubscribersBar, getCaptiveDashTopSitesSessionsBar, getCaptiveDashTopSubscribersUsageBar, getCaptiveDashTopSubscribersSessionsBar, getCaptiveDashTopPlansUsageBar, getCaptiveDashTopPlansUsersBar })(BarChart)
);