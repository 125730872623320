import { useEffect, useState, useRef } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import "./loginHistoryBarChart.css";
import { Button, FormControlLabel, Grid, IconButton } from "@mui/material";
import { getIamLoginHistortTableCount, getIamLoginHistoryTable, getIamLoginHistoryTimeseriesChart, getIamLoginHistoryTopWidget } from "../../../actions/Users/authenticate";
import moment from "moment";
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import { getDecodeURI, getEncodedURI, getTimezoneCity } from "../../../utils/util";
import _ from "lodash";
import Download from '../../../asset/image/DownloadUsage.svg';
import Exporting from 'highcharts/modules/exporting';
import ExportData from 'highcharts/modules/export-data';

const LoginHistoryLineChart = (props) => {
    const { authReducer, getIamLoginHistoryTopWidget, getIamLoginHistoryTable, getIamLoginHistortTableCount, getIamLoginHistoryTimeseriesChart, setValues, values, getInterval, location, history } = props;
    const { setPage } = setValues;
    const { startDate, endDate, searchValue, limit, sort, sortOrder, selectedOu, selectedUsers, selectedApp, selectedDesc, widgetFilt } = values;
    const [ lineChartData, setLineChartData ] = useState<any>([]);
    const [legends, setLegends] = useState([]);
    const [resetDate, setResetDate] = useState<any>([]);
    const timeZone = getTimezoneCity(authReducer?.userTimezone);

    const pinchAndResetChart = (start, end, interval) => {
        let params = getDecodeURI(location?.search);
        params.startDate = start.valueOf();
        params.endDate = end.valueOf();
        params.interval = interval;
        doNavigate(params);
        setPage(1);
    }

    const chartAreaSelection = () => {
        return (event: any) => {
            let start:any = moment(new Date(Math.ceil(event.xAxis[0].min)));
            let end:any = moment(new Date(Math.floor(event.xAxis[0].max)));
            pinchAndResetChart(start, end, 'customDates');

            let arr:any = resetDate;
            arr.push({startDate: start, endDate: end});
            setResetDate(arr);
            return false;
        }
    }

    const HighchartsInstance = { ...Highcharts };
    Exporting(HighchartsInstance);
    ExportData(HighchartsInstance);
    Highcharts.setOptions({
        exporting: { enabled: false },
    });

    useEffect(() => {
        if(!_.isEmpty(authReducer.getIamLoginHistoryTimeseriesChart)) {
            const data = authReducer.getIamLoginHistoryTimeseriesChart?.data;
            if(data) {
                let lineChartData:any = Object.keys(data?.value?.series)?.map(key => {
                    let name = (key == 'tot_login') ? "Total Logins" : (key=='fail_login') ? 'Failed Logins' : (key=='success_login') ? 'Success Logins' : 'Total Logouts';
                    let color = (key == 'tot_login') ? '#E4A971' : (key=='fail_login') ? '#F37171' : (key=='success_login') ? '#59A747' : '#264C86';
                    return {name, data: data?.value?.series[key], marker:{symbol: 'circle'}, visible:true, color}
                })
                let Arr:any = [];
                if(lineChartData[2]){
                    Arr.push(lineChartData[2])
                }
                if(lineChartData[0]){
                    Arr.push(lineChartData[0])
                }
                if(lineChartData[1]){
                    Arr.push(lineChartData[1])
                }
                if(lineChartData[3]){
                    Arr.push(lineChartData[3])
                }
                setLineChartData(Arr);
                if(Arr) {
                    let names:any = [];
                    Arr?.forEach((el)=>{
                        names.push(el.name)
                    })
                    setLegends(names)
                }
            }
            authReducer.getIamLoginHistoryTimeseriesChart = {};
        }
    }, [authReducer.getIamLoginHistoryTimeseriesChart])

    const handleLegendClick = (app, color, index) => {
        let all_true = true;
        let updatedData:any = [...lineChartData];
        let all_off_turn_on = 0;
        let click = 0;
    
        updatedData?.forEach(( ele, i)=>{
          if( !ele.visible ){
            all_true = false;
            all_off_turn_on +=1;
          }
          if(ele.visible){
            click = i;
          }
        })
        
        if(click == index && all_off_turn_on == updatedData.length-1 ){
            updatedData?.forEach((ele,i)=>{
            updatedData[i] = { ...ele, visible:true }
           })
          setLineChartData([...updatedData]);
        }
        else if(all_true){
          updatedData?.forEach((ele,i)=>{
           updatedData[i] = { ...ele, visible:false }
          })
          updatedData[index].visible = true;
          setLineChartData([...updatedData]);
        }
        else{
          updatedData.forEach((ele,i)=>{
            updatedData[i] = ele;
           })
          updatedData[index].visible = !updatedData[index].visible; 
          setLineChartData([...updatedData]);
        }
    }

    const handleResetZoom = () => {
        if(resetDate?.length == 0) {
          return;
        }
        let arr:any = resetDate;
        arr.pop();
        setResetDate(arr);
        if(arr?.length == 0) {
            const start = moment(Date.now()).subtract(1, 'hour');
            const end = moment(Date.now());
            pinchAndResetChart(start, end, '1h');
        }
        else {
            let obj = arr[arr?.length-1];
            let start = obj.startDate;
            let end = obj.endDate;
            pinchAndResetChart(start, end, 'customDates');
        }
    }
    
    const handleDefaultResetZoom = () => {
        setResetDate([]);
        const start = moment(Date.now()).subtract(1, 'hour');
        const end = moment(Date.now());
        pinchAndResetChart(start, end, '1h');
    }

    const doNavigate = (params) => {
        history.push({ pathname: location.pathname, search: `?${getEncodedURI(params)}` });
    }

    const options: any = { 
        time: {
            timezone: timeZone
        },
        chart: {
            type: 'column',
            height: '210px',
            zoomType: 'x',
            events: {
              selection: chartAreaSelection()
            },
            plotBorderWidth: 1,
            resetZoomButton: {
              theme: {
                  style: {
                    display: 'none'
                  }
              }
            },
            style: {
                fontFamily: "Roboto, Nunito Sans, Arial, Verdana, Helvetica, sans-serif",
            },
          },
        title: {
            text: '',
            align: 'left',
            floating: true,
            x: 12,
            y: 32,
            style: {
                fontWeight: '500'
            }
        },
        lang: {
            noData: "No Data",
        },
        noData: {
            style: {
                fontWeight: 'bold',
                fontSize: '15px',
                color: '#303030',
            },
        },
        credits: {
            enabled: false
        },
        legend: {
           enabled: false
        },
        plotOptions: {
            series: {
                turboThreshold: 1000000,
                stickyTracking: false,
                connectNulls: false
            },
            column: {
                stacking: 'normal',
                dataLabels: {
                    enabled: false
                }
            },
            colorByPoint: true
        },
        tooltip: {
            valueSuffix: '',
            pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b><br/>',
            valueDecimals: 0,
            borderWidth: 1
        },
        xAxis: {
            gridLineWidth: 0.5,
            reversed: false,
            type: "datetime",
            maxPadding: 0.05,
            showLastLabel: true,
            min: moment(startDate).utc().toDate().valueOf(),
            max: moment(endDate).utc().toDate().valueOf(),
          },
        yAxis: {
            tickInterval: 1,
            gridLineWidth: 1,
            labels: {
              format: '{value}',
            },
            title: {
                text: null
            }
        },
        series: lineChartData ? lineChartData : []
    }

    const Legends: any = {
        'Total Logins' : {text : 'Total No of Logins'},
        'Failed Logins' : {text: 'No of Failed Logins'},
        'Success Logins' : {text: 'Total Success Logins'},
        'Total Logouts' : {text: 'Total No of Logouts'}
    }

    const chartRef = useRef<HighchartsReact.RefObject>(null);

    const handleDownloadCSV = () => {
        if (chartRef.current?.chart) {
            const csvData = chartRef.current.chart.getCSV();
            const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
            const link = document.createElement('a');
            const url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
            link.setAttribute('download', 'account_activity_data.csv');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } else {
            console.error('Chart reference is null or not initialized.');
        }
    };

    return (
        <div className="login-history-bar-chart-comp">
            <div className="alignTopbarItemsCenter">
            <p>Overview</p>
            <div className="download-summary-btn margin-left-auto" onClick={handleDownloadCSV}><img className="downloadImg" src={Download} alt="" /><Button>Download</Button></div>
            </div>
            <Grid>
                { resetDate.length > 0 ? 
                    <div className="siteCharts__filters-reset-zoom resetZoom-usage">
                        <div>
                            <FormControlLabel
                            value="Zoom Back"
                            control={<IconButton onClick={handleResetZoom}><ZoomOutIcon /></IconButton>}
                            label="Zoom Back"
                            labelPlacement="start" 
                            />
                            <FormControlLabel
                                value="Zoom Reset"
                                control={<IconButton onClick={handleDefaultResetZoom}><RestartAltIcon /></IconButton>}
                                label="Zoom Reset"
                                labelPlacement="start"
                            />
                        </div>
                    </div> :
                    <div className="siteCharts__filters-reset-zoom resetZoom-usage login-resetZoom"></div>
                }
                <HighchartsReact
                    highcharts={Highcharts}
                    options={options}
                    ref={chartRef}
                />
                <Grid className="usageDetails-color login-legends">
                    { legends?.map((name, i)=>(
                        <Grid key={i} className="name-color">
                            <div className="totalUsageColor" style={{background:`${lineChartData[i]?.color}`}}></div>
                            <Grid className= {lineChartData[i]?.visible ? "lineChart-legends" : 'disable-lineChart-legends'} 
                                onClick={() => handleLegendClick(name, lineChartData[i]?.color, i)}>
                                { Legends[name]?.text }
                            </Grid>
                        </Grid>
                    )) }
                </Grid>
            </Grid>
        </div>
    )
}
const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
    errorReducer: state.errorReducer,
});

export default withRouter(
    connect(mapStateToProps, {
        getIamLoginHistoryTimeseriesChart,
        getIamLoginHistoryTopWidget,
        getIamLoginHistoryTable,
        getIamLoginHistortTableCount
    })(LoginHistoryLineChart)
);