import { Fragment, useState, useEffect } from "react";
import { Button, FormControl, Grid, InputLabel } from "@mui/material";
import {  connect } from "react-redux";
import { withRouter} from "react-router-dom";
import _ from "lodash";
import "../usageReportsDetails.css";
import AppUsageCard from "../../AppUsageCard";
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { getDecodeURI } from "../../../utils/util";

const UsageBottomCards = (props: any) => {
    const { selectTab, setSelectTab, setTrackTab, activeTab, setActiveTab, vessels, getTopDownloadUploadBlockedApplication, getTopDownloadUploadBlockedDomain, getTopDownloadUploadBlockedIp, bottomTableLimit, handleChangeBottomTableLimit, getContentFiltersTable, location } = props;
    const _q = getDecodeURI(location?.search);
    const TOP_APPLICATION = "top_aaplication";
    const TOP_DOMAIN = "top_domain";
    const TOP_IP = "top_ip";
    const [applicationData, setApplicationData] = useState<any>({});
    const [domainData, setDomainData] = useState<any>([]);
    const [ipData, setIpData] = useState<any>({});

    useEffect(() => {
      if(vessels?.length == 0) {
        setApplicationData([])
        setDomainData([])
        setIpData([])
      }
    },[vessels])

    useEffect(() => {
      if(activeTab == TOP_IP) {
        setIpData([])
      }
    },[activeTab])

    useEffect(() => {
        if (!_.isEmpty(getTopDownloadUploadBlockedApplication)){
            let obj = { download: [], upload: [], blocked: [] };
            const data = getTopDownloadUploadBlockedApplication?.hasOwnProperty('data') ? getTopDownloadUploadBlockedApplication?.data : {};
            let downloadData  = data?.download?.rows;
            downloadData = downloadData && downloadData.map((item) => {
                return  {application: item[1],download :item[2],percentage:item[3]}
            })
            obj['download'] = downloadData;
            let uploadData = data?.upload?.rows;
            uploadData = uploadData && uploadData.map((item) => {
                return  {application: item[1], download:item[2],percentage:item[3]}
            })
            obj['upload'] = uploadData;
            let blockedData =  data?.blocked?.rows;
            blockedData = blockedData && blockedData.map((item) => {
                return  {application: item[1],hits:item[2], percentage:item[3]}
            })
            obj['blocked'] = blockedData;
            setApplicationData(obj);
        }
    }, [getTopDownloadUploadBlockedApplication])

    useEffect(() => {
        if (!_.isEmpty(getTopDownloadUploadBlockedDomain)){
            let obj = { download: [], upload: [], blocked: [] };
            const data = getTopDownloadUploadBlockedDomain?.hasOwnProperty('data') ? getTopDownloadUploadBlockedDomain?.data : {};
            let downloadData  = data?.download?.rows;
            downloadData = downloadData && downloadData.map((item) => {
                return  {application: item[1],download :item[2],percentage:item[3]}
            })
            obj['download'] = downloadData;
            let uploadData = data?.upload?.rows;
            uploadData = uploadData && uploadData.map((item) => {
                return  {application: item[1], download:item[2],percentage:item[3]}
            })
            obj['upload'] = uploadData;
            let blockedData =  data?.blocked?.rows;
            blockedData = blockedData && blockedData.map((item) => {
                return  {application: item[1],hits:item[2], percentage:item[3]}
            })
            obj['blocked'] = blockedData;
            setDomainData(obj);
        }
    }, [getTopDownloadUploadBlockedDomain])

    useEffect(() => {
        if (!_.isEmpty(getTopDownloadUploadBlockedIp)){
            let obj = { download: [], upload: [], blocked: [] };
            const data = getTopDownloadUploadBlockedIp?.hasOwnProperty('data') ? getTopDownloadUploadBlockedIp?.data : {};
            let downloadData  = data?.download?.rows;
            downloadData = downloadData && downloadData.map((item) => {
                return  {application: item[1],download :item[2],percentage:item[3]}
            })
            obj['download'] = downloadData;
            let uploadData = data?.upload?.rows;
            uploadData = uploadData && uploadData.map((item) => {
                return  {application: item[1], download:item[2],percentage:item[3]}
            })
            obj['upload'] = uploadData;
            let blockedData =  data?.blocked?.rows;
            blockedData = blockedData && blockedData.map((item) => {
                return  {application: item[1],hits:item[2], percentage:item[3]}
            })
            obj['blocked'] = blockedData;
            setIpData(obj);
        }
    }, [getTopDownloadUploadBlockedIp])
    
    return (
        <Fragment>
            <Grid className="usageDetails-cards">
                <Grid className="usage-selectedTabs">
                    <span>
                        <Button className={ selectTab == TOP_APPLICATION ? "usageActiveAppClass" : "usageInactiveAppClass" }
                        onClick={()=> {
                            setActiveTab(TOP_APPLICATION)
                            setSelectTab(TOP_APPLICATION)
                            setTrackTab(true)
                        }}
                        >Top Application</Button>
                        {!_q.hasOwnProperty('topUsage') && <Button className={ selectTab == TOP_DOMAIN ? "usageActiveDomainClass" : "usageInactiveDomainClass" }
                        onClick={()=> {
                            setActiveTab(TOP_DOMAIN)
                            setSelectTab(TOP_DOMAIN)
                            setTrackTab(true)
                        }}
                        >Top Domain</Button>}
                        {!_q.hasOwnProperty('topUsage') && <Button className={ selectTab == TOP_IP ? "usageActiveIpClass" : "usageInactiveIpClass" }
                        onClick={()=> {
                            setActiveTab(TOP_IP)
                            setSelectTab(TOP_IP)
                            setTrackTab(true)
                        }}
                        >Top IP(Network)</Button>}
                    </span>
                    <span className="selectUserTag-bottomTable">
                        <FormControl variant="standard" className='selectEntry-pie'>
                            <Select
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                value={bottomTableLimit}
                                onChange={handleChangeBottomTableLimit}
                                >
                                <MenuItem value={10}>10</MenuItem>
                                <MenuItem value={20}>20</MenuItem>
                                <MenuItem value={30}>30</MenuItem>
                                <MenuItem value={50}>50</MenuItem>
                            </Select>
                        </FormControl>
                    </span>
                </Grid>
                <Grid className="cardsParentComp">
                    {
                        selectTab == TOP_APPLICATION ? 
                        <Grid className="cardParent-three">
                            <Grid container spacing={2} className="appUsageParent">
                                <Grid item xs={12} sm={12} md={6} lg={4} >
                                    <p>Top Downloads</p>
                                    <AppUsageCard appDownloadTableData={applicationData?.['download']} header="DOWNLOAD (GB)" tab={selectTab} />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={4} >
                                    <p>Top Uploads</p>
                                    <AppUsageCard appDownloadTableData={applicationData?.['upload']} header="UPLOAD (GB)" tab={selectTab} />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={4} >
                                    <p>Top Blocked Applications</p>
                                    <AppUsageCard appDownloadTableData={applicationData?.['blocked']} header="HITS" tab={selectTab} />
                                </Grid>
                            </Grid>
                        </Grid>
                        :
                        selectTab == TOP_DOMAIN ? 
                        <Grid className="cardParent-three">
                            <Grid container spacing={2} className="appUsageParent">
                                <Grid item xs={12} sm={12} md={6} lg={4} >
                                    <p>Top Downloads</p>
                                    <AppUsageCard appDownloadTableData={domainData['download']} header="DOWNLOAD (GB)" tab={selectTab} />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={4} >
                                    <p>Top Uploads</p>
                                    <AppUsageCard appDownloadTableData={domainData['upload']} header="UPLOAD (GB)" tab={selectTab} />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={4} >
                                    <p>Top Blocked</p>
                                    <AppUsageCard appDownloadTableData={domainData['blocked']} header="HITS" tab={selectTab} />
                                </Grid>
                            </Grid>
                        </Grid>
                        :
                        <Grid className="cardParent-three">
                            <Grid container spacing={2} className="appUsageParent">
                                <Grid item xs={12} sm={12} md={6} lg={4} >
                                    <p>Top Downloads</p>
                                    <AppUsageCard appDownloadTableData={ipData['download']} header="DOWNLOAD (GB)" tab={selectTab} />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={4} >
                                    <p>Top Uploads</p>
                                    <AppUsageCard appDownloadTableData={ipData['upload']} header="UPLOAD (GB)" tab={selectTab} />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={4} >
                                    <p>Top Blocked</p>
                                    <AppUsageCard appDownloadTableData={ipData['blocked']} header="HITS" tab={selectTab} />
                                </Grid>
                            </Grid>
                        </Grid>
                    }
                </Grid>
            </Grid>
            {_q.hasOwnProperty('topUsage') &&
            <>
                <Grid className="usageDetails-cards">
                    <Grid className="usage-selectedTabs">
                        <span>
                            <Button className='usageActiveAppClass'>Top Domain</Button>
                        </span>
                        <span className="selectUserTag-bottomTable">
                            <FormControl variant="standard" className='selectEntry-pie'>
                                <Select
                                    labelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-standard"
                                    value={bottomTableLimit}
                                    onChange={handleChangeBottomTableLimit}
                                    >
                                    <MenuItem value={10}>10</MenuItem>
                                    <MenuItem value={20}>20</MenuItem>
                                    <MenuItem value={30}>30</MenuItem>
                                    <MenuItem value={50}>50</MenuItem>
                                </Select>
                            </FormControl>
                        </span>
                    </Grid>
                    <Grid className="cardsParentComp">
                        <Grid className="cardParent-three">
                            <Grid container spacing={2} className="appUsageParent">
                                <Grid item xs={12} sm={12} md={6} lg={4} >
                                    <p>Top Downloads</p>
                                    <AppUsageCard appDownloadTableData={domainData['download']} header="DOWNLOAD (GB)" tab={'top_domain'} />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={4} >
                                    <p>Top Uploads</p>
                                    <AppUsageCard appDownloadTableData={domainData['upload']} header="UPLOAD (GB)" tab={'top_domain'} />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={4} >
                                    <p>Top Blocked</p>
                                    <AppUsageCard appDownloadTableData={domainData['blocked']} header="HITS" tab={'top_domain'} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid className="usageDetails-cards">
                    <Grid className="usage-selectedTabs">
                        <span>
                            <Button className='usageActiveAppClass'>Top IP(Network)</Button>
                        </span>
                        <span className="selectUserTag-bottomTable">
                            <FormControl variant="standard" className='selectEntry-pie'>
                                <Select
                                    labelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-standard"
                                    value={bottomTableLimit}
                                    onChange={handleChangeBottomTableLimit}
                                    >
                                    <MenuItem value={10}>10</MenuItem>
                                    <MenuItem value={20}>20</MenuItem>
                                    <MenuItem value={30}>30</MenuItem>
                                    <MenuItem value={50}>50</MenuItem>
                                </Select>
                            </FormControl>
                        </span>
                    </Grid>
                    <Grid className="cardsParentComp">
                        <Grid className="cardParent-three">
                            <Grid container spacing={2} className="appUsageParent">
                                <Grid item xs={12} sm={12} md={6} lg={4} >
                                    <p>Top Downloads</p>
                                    <AppUsageCard appDownloadTableData={ipData['download']} header="DOWNLOAD (GB)" tab={'top_ip'} />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={4} >
                                    <p>Top Uploads</p>
                                    <AppUsageCard appDownloadTableData={ipData['upload']} header="UPLOAD (GB)" tab={'top_ip'} />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={4} >
                                    <p>Top Blocked</p>
                                    <AppUsageCard appDownloadTableData={ipData['blocked']} header="HITS" tab={'top_ip'} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </>}
        </Fragment>
    )
}

const mapStateToProps = (state) => ({
    getTopDownloadUploadBlockedApplication: state?.authReducer?.getTopDownloadUploadBlockedApplication,
    getTopDownloadUploadBlockedDomain: state?.authReducer?.getTopDownloadUploadBlockedDomain,
    getTopDownloadUploadBlockedIp: state?.authReducer?.getTopDownloadUploadBlockedIp
});

export default withRouter(
    connect(mapStateToProps, {
        
    })(UsageBottomCards)
);