import { withRouter } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { useEffect, useState, useRef} from "react";
import { FormControl, FormControlLabel, Grid, IconButton, MenuItem, Select, Button, Switch, Dialog, DialogTitle, DialogContent, Autocomplete, TextField, TableContainer, Table, TableHead, TableCell, TableRow, TableBody } from "@mui/material";
import { getDateForChart, getDecodeURI, getEncodedURI, getMinutes, getSingleRecordChartLable, getTimezoneCity, readableBytesAsGB, readableBytesAsMB } from "../../../utils/util";
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import BarChart from "./BarChart";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import { getCaptiveDashSubscribersLineChart, getCaptiveDashSessionsLineChart, getCaptiveDashUsageLineChart, getCaptiveDashSitesHistogram } from "../../../actions/Users/authenticateCaptiveDahshboard";
import _ from "lodash";
import moment from "moment";
import barIcon from '../../../asset/image/bar_chart_icon.svg';
import lineIcon from '../../../asset/image/line_chart_icon.svg';
import bar_chart from '../../../asset/image/bar_chart_light.svg';
import line_chart from '../../../asset/image/line_chart.svg';
import Download from '../../../asset/image/DownloadUsage.svg';
import Exporting from 'highcharts/modules/exporting';
import ExportData from 'highcharts/modules/export-data';
import { GET_CAPTIVE_DASHBOARD_SESSIONS_LINE_CHART, GET_CAPTIVE_DASHBOARD_SITES_HISTOGRAM, GET_CAPTIVE_DASHBOARD_SUBSCRIBERS_LINE_CHART, GET_CAPTIVE_DASHBOARD_USAGE_LINE_CHART } from "../../../actions/types";
import close from "../../../asset/image/cross.svg";
import ModeIcon from '@mui/icons-material/Mode';
import * as XLSX from 'xlsx';

const CaptiveCharts = (props) => {
    const { authReducer, newSummaryStartDate, newSummaryEndDate, location, history, vessels, getCaptiveDashSubscribersLineChart, getCaptiveDashSessionsLineChart, getCaptiveDashUsageLineChart, getCDSitesSubscribersLineChart, getCDSitesSessionsLineChart, getCDSitesUsageLineChart, getCaptiveDashSitesHistogram, getCDSitesHistogram, topcardSelected, selectedTab, values, setValues, applyFilterClick } = props;
    const _q = getDecodeURI(location?.search);
    const { chartView } = values;
    const { setChartView } = setValues;
    const [subscribertsData, setSubscribertsData] = useState<any>([]);
    const [sessionsData, setSessionsData] = useState<any>([]);
    const [usageData, setUsageData] = useState<any>([]);
    const [histogramData, setHistogramData] = useState<any>([]);
    const [chartType, setChartType] = useState('spline');
    const [binSize, setBinSize] = useState(10);
    const [histogramCategories, setHistogramCategories] = useState<any>([]);
    const [popupOpen, setPopupOpen] = useState(false);
    const [minBucket, setMinBucket] = useState<any>()
    const [maxBucket, setMaxBucket] = useState<any>()
    const [rangeList, setRangeList] = useState<any>([])
    const [dataUnit, setDataUnit] = useState('GB');
    const [selectedSiteDetails, setSelectedSiteDetails] = useState<any>([]);
    const [siteDetailsPopup, setSiteDetailsPopup] = useState(false);

    const [resetDate, setResetDate] = useState<any>([]);
    const timeZone = getTimezoneCity(authReducer?.userTimezone);
    const { selectedSubscribers, selectedPlans } = values;
    const dispatch = useDispatch();

    const getInterval = (minutes) => {
        if (minutes <= 720) {
            return "5 minutes";
        } else if (minutes > 720 && minutes <= 1440) {
            return "15 minutes";
        } else if (minutes > 1440 && minutes <= 10080) {
            return "1 hour";
        } else {
            return "12 hour";
        }
    }
    const [selectedBin, setSelectedBin] = useState(getInterval(Math.ceil(((new Date(newSummaryEndDate)).getTime() - (new Date(newSummaryStartDate)).getTime()) / 60e3)));

    const HighchartsInstance = { ...Highcharts };
    Exporting(HighchartsInstance);
    ExportData(HighchartsInstance);
    Highcharts.setOptions({
        exporting: { enabled: false },
    });

    useEffect(() => {
        const _interval = getInterval(
            Math.ceil(((new Date(newSummaryEndDate)).getTime() - (new Date(newSummaryStartDate)).getTime()) / 60e3)
        );
        setSelectedBin(_interval);
    
        const tabToChartMap = {
            'SUBSCRIBERS': () => getCaptiveDashSubscribersLineChart(vessels, newSummaryStartDate, newSummaryEndDate, _interval, selectedSubscribers, selectedPlans),
            'SESSIONS': () => getCaptiveDashSessionsLineChart(vessels, newSummaryStartDate, newSummaryEndDate, _interval, selectedSubscribers, selectedPlans),
            'TOTAL USAGE': chartView == 'spline' ? 
            () => getCaptiveDashUsageLineChart(vessels, newSummaryStartDate, newSummaryEndDate, _interval, selectedSubscribers, selectedPlans) : 
            () => getCaptiveDashSitesHistogram(vessels, newSummaryStartDate, newSummaryEndDate, selectedSubscribers, binSize, 0, 0)
        };
        if (vessels?.length > 0) {
            tabToChartMap[topcardSelected]?.();
        } else {
            setSubscribertsData([])
            setSessionsData([])
            setUsageData([])
        }
    }, [vessels, newSummaryStartDate, newSummaryEndDate, topcardSelected, chartView]);

    const convertUsageData = (data) => {
        const gbValue = Math.pow(1000, 3);
        const isGBCrossed = Object.keys(data).some(key =>
            _.isArray(data[key]) && data[key].some(item => item.y > gbValue)
        );
    
        const convertBytes = isGBCrossed ? readableBytesAsGB : readableBytesAsMB;
        const usageData = Object.keys(data).map(key => {
            data[key].forEach((el, i) => {
                data[key][i].y = Number(convertBytes(el.y, false));
            });
            return {
                name: 'Usage',
                data: data[key],
                marker: { symbol: 'circle', enabled: false },
                visible: true,
                color: '#86c6df',
                unit: isGBCrossed ? 'GB' : 'MB'
            };
        });
    
        setDataUnit(isGBCrossed ? 'GB' : 'MB');
        return usageData;
    };
    
    const setChartData = (name, chartData, setDataFunction) => {
        if (_.isEmpty(chartData)) {
            setDataFunction([]);
            return;
        }
        const data = chartData?.data?.[`${name}Count`]?.series;
        const formattedData = Object.keys(data).map(key => ({
            name,
            data: data[key],
            marker: { symbol: 'circle' },
            visible: true,
            color: '#86c6df'
        }));
        setDataFunction(formattedData);
    };
    
    useEffect(() => {
        if (['SUBSCRIBERS', 'SESSIONS', 'TOTAL USAGE'].includes(topcardSelected)) {
            setChartData('Subscribers', getCDSitesSubscribersLineChart, setSubscribertsData);
            setChartData('Sessions', getCDSitesSessionsLineChart, setSessionsData);
    
            if (!_.isEmpty(getCDSitesUsageLineChart)) {
                const usageData = convertUsageData(getCDSitesUsageLineChart?.data?.quotaUsed?.series);
                setUsageData(usageData);
            } else {
                setUsageData([]);
            }

            if(getCDSitesHistogram?.hasOwnProperty('data') && _.isEmpty(getCDSitesHistogram.data)) {
                setHistogramData([]);
                setHistogramCategories([]);
                setMinBucket(0);
                setMaxBucket([0]);
                setRangeList([]);
                setPopupOpen(false);
                return;
            }
            if (!_.isEmpty(getCDSitesHistogram)) {
                const data = getCDSitesHistogram.hasOwnProperty('data') ? getCDSitesHistogram.data : [];

                const gbValue = Math.pow(1000, 3);
                const isGBCrossed = data?.some(row => Number(row.lower_range) > gbValue || Number(row.upper_range) > gbValue);
                const convertBytes = isGBCrossed ? readableBytesAsGB : readableBytesAsMB;

                const formattedData = data.map((el,i) => ({
                    // x: (el.lower_range),
                    y: el.sitesCount,
                    name: `${(el.lower_range)} - ${el.upper_range}`,
                    siteDetails: el.siteDetails ? JSON.parse(`[${el.siteDetails.replace(/'/g, "")}]`)?.[0] : []
                }));
                
                const categoriesData = data.map(el => `${convertBytes(el.lower_range, false)} - ${convertBytes(el.upper_range, false)}`);
                setDataUnit(isGBCrossed ? 'GB' : 'MB');
                
                setHistogramData(formattedData);
                setHistogramCategories(categoriesData);
                setPopupOpen(false);

                const minRange = Math.floor(Math.min(...data.map(el => el.lower_range)));
                const maxRange = Math.ceil(Math.max(...data.map(el => el.upper_range)));
                const maxListSize = 10;
                const step = Math.max(1, Math.ceil((maxRange - minRange) / (maxListSize - 1)));
                let uniqueValues = new Set();
                let dynamicRange: any = [];
                for (let i = 0; i < maxListSize; i++) {
                    let rawValue = minRange + i * step;
                    let value = Math.ceil(Number(convertBytes(rawValue, false)));
                    if (!uniqueValues.has(value)) {
                        uniqueValues.add(value);
                        dynamicRange.push({ label: `${value} `, value });
                    }
                }

                let maxConverted = Math.ceil(Number(convertBytes(maxRange, false)));
                if (!uniqueValues.has(maxConverted)) {
                    dynamicRange.push({ label: `${maxConverted} `, value: maxConverted });
                }

                dynamicRange = dynamicRange.slice(0, maxListSize);

                setMinBucket(dynamicRange?.[0]['value'])
                setMaxBucket(dynamicRange?.[dynamicRange.length - 1]['value'])
                setRangeList(dynamicRange);
            }
        }
    }, [getCDSitesSubscribersLineChart, getCDSitesSessionsLineChart, getCDSitesUsageLineChart, getCDSitesHistogram]);    

    const pinchAndResetChart = (start, end, interval) => {
        let params = getDecodeURI(location?.search);
        params.startDate = start.valueOf();
        params.endDate = end.valueOf();
        params.interval = interval;
        history.push({ pathname: location.pathname, search: `?${getEncodedURI(params)}` });
    }

    const chartAreaSelection = () => {
        return (event: any) => {
            let start: any = moment(new Date(Math.ceil(event.xAxis[0].min)));
            let end = moment(new Date(Math.floor(event.xAxis[0].max)));
            pinchAndResetChart(start, end, 'customDates');
            let arr: any = resetDate;
            arr.push({ startDate: start, endDate: end });
            setResetDate(arr);
            return false;
        }
    }

    const handleResetZoom = () => {
        if (resetDate?.length == 0) {
            return;
        }
        let arr: any = resetDate;
        arr.pop();
        setResetDate(arr);
        if (arr?.length == 0) {
            const start = moment(Date.now()).subtract(1, 'hour');
            const end = moment(Date.now());
            pinchAndResetChart(start, end, '1h');
        }
        else {
            let obj = arr[arr?.length - 1];
            let start = obj.startDate;
            let end = obj.endDate;
            pinchAndResetChart(start, end, 'customDates');
        }
    }

    const handleDefaultResetZoom = () => {
        setResetDate([]);
        const start = moment(Date.now()).subtract(1, 'hour');
        const end = moment(Date.now());
        pinchAndResetChart(start, end, '1h');
    }

    const handleBarClick = (event) => {
        if (event.point.siteDetails) {
            setSelectedSiteDetails(event.point.siteDetails);
            setSiteDetailsPopup(true);
        }
    };

    const barOptions: any = {
        time: {
            timezone: timeZone
        },
        chart: {
            type: chartView == 'column' ? 'column' : chartType,
            height: '285px',
            zoomType: chartView == 'spline' && 'x',
            plotBorderWidth: 1,
            resetZoomButton: {
                theme: {
                    style: {
                        display: 'none'
                    }
                }
            },
            events: {
                selection: chartAreaSelection()
            },
            style: {
                fontFamily: "Roboto, Nunito Sans, Arial, Verdana, Helvetica, sans-serif",
            },
        },
        title: {
            text: '',
            align: 'left',
            floating: true,
            x: 12,
            y: 32,
            style: {
                fontWeight: '500'
            }
        },
        lang: {
            noData: "No Data",
        },
        noData: {
            style: {
                fontWeight: 'bold',
                fontSize: '15px',
                color: '#303030',
            },
        },
        credits: {
            enabled: false
        },
        legend: {
            enabled: false
        },
        plotOptions: {
            series: {
                turboThreshold: 1000000,
                stickyTracking: false,
                connectNulls: false,
                point: {
                    events: {
                        click: handleBarClick
                    }
                }
            },
            column: {
                stacking: 'normal',
                dataLabels: {
                    enabled: false
                },
                cursor: 'pointer'
            },
            colorByPoint: true
        },
        tooltip: {
            valueSuffix: topcardSelected === 'TOTAL USAGE' ? ` ${dataUnit}` : '',
            headerFormat: '',
            pointFormat: '<span style="color: #7e8089">{series.name}</span> <br/> <b style="color: #fff">{point.y}</b><br/>',
            valueDecimals: topcardSelected === 'TOTAL USAGE' && chartView == 'spline' ? 2 : undefined,
            borderWidth: 1,
            useHTML: true,
            borderRadius: 15,
            backgroundColor: '#060606',
            cursor: 'pointer',
            style: {
                pointerEvents: 'auto'
            },
            formatter: function (this: any) {
                let unit = '';
                if (topcardSelected === 'TOTAL USAGE' && chartView == 'column') {
                    const siteDetails = this.point.siteDetails || [];
                    const maxSitesToShow = 10;
                
                    let siteNames = siteDetails.map(site => site.siteName).slice(0, maxSitesToShow).join(', ');
                    let remainingCount = siteDetails.length - maxSitesToShow;
                
                    if (remainingCount > 0) {
                        siteNames += ` ( + ${remainingCount} more)`;
                    }

                    setTimeout(() => {
                        const tooltipElement = document.querySelector('.tooltip-clickable');
                        if (tooltipElement) {
                            tooltipElement.addEventListener('click', () => handleBarClick({ point: this.point }));
                        }
                    }, 100);
                
                    return `<div class="tooltip-clickable" data-point-index="${this.point.index}" style="cursor: pointer;">
                            <b style="color: #7e8089">Sites: <b style="color: #fff">${this.y}</b></b><br/>
                            <b style="color: #fff">${siteNames}</b>
                        </div>`;
                } else {
                    unit = topcardSelected === 'TOTAL USAGE' && chartView == 'spline' ? ` ${dataUnit}` : '';
                    const options: Intl.DateTimeFormatOptions = {
                        weekday: 'long',
                        month: 'short',
                        day: 'numeric',
                        hour: 'numeric',
                        minute: 'numeric',
                        hour12: false,
                    };
            
                    const timeZoneFormattedDate = new Intl.DateTimeFormat('en-US', {
                        ...options,
                        timeZone: timeZone,
                    }).format(new Date(this.x));
            
                    return `<b style="color: #7e8089">${timeZoneFormattedDate}</b><br/>
                            <b style="color: #7e8089">${this.series.name} <b style="color: #fff">${this.y}${unit}</b></b>`;
                }
        
            }
        },
        xAxis: {
            categories: topcardSelected === 'TOTAL USAGE' && chartView == 'column' ? histogramCategories : null,
            gridLineWidth: 0.5,
            reversed: false,
            type: topcardSelected === 'TOTAL USAGE' && chartView == 'column' ? 'category' : 'datetime',
            maxPadding: 0.05,
            showLastLabel: true,
            labels: {
                style: {
                    color: '#2F4B82',
                    fontFamily: 'Inter',
                    fontSize: '9px',
                    fontStyle: 'normal',
                    fontWeight: '500',
                },
                format: topcardSelected === 'SUBSCRIBERS' && getDateForChart(subscribertsData) ? getSingleRecordChartLable(subscribertsData, timeZone) :
                        topcardSelected === 'SESSIONS' && getDateForChart(sessionsData) ? getSingleRecordChartLable(sessionsData, timeZone) :
                        topcardSelected === 'TOTAL USAGE' && chartView == 'spline' && getDateForChart(usageData) ? getSingleRecordChartLable(usageData, timeZone) : 
                        topcardSelected === 'TOTAL USAGE' && chartView == 'column' ? `{value} ${dataUnit}` : '',
            }
        },
        yAxis: {
            tickInterval: 1,
            gridLineWidth: 1,
            title: {
                text: ''
            },
            labels: {
                format: '{value}',
                style: {
                    color: '#2F4B82',
                    fontFamily: 'Inter',
                    fontSize: '9px',
                    fontWeight: '500',
                },
                rotation: 0
            }
        },
        exporting: {
            enabled: false,
            csv: {
                columnHeaderFormatter: (item, key) => {
                    if (item.isXAxis) {
                        return 'Time';
                    }
                    else if(topcardSelected === 'TOTAL USAGE'){
                        return `${item.name} (${dataUnit})`
                    }
                    return `${item.name}`;
                }
            }
        },
        series: topcardSelected === 'SUBSCRIBERS' && !_.isEmpty(subscribertsData) ? subscribertsData :
               topcardSelected === 'SESSIONS' && !_.isEmpty(sessionsData) ? sessionsData : 
               !_.isEmpty(usageData) && chartView == 'spline' ? usageData : !_.isEmpty(histogramData) && chartView == 'column' ? [{ data: histogramData, showInLegend: false, color: "#86c6df" }] : [],
        colorByPoint: true
    };

    const handleBinChange = (e) => {
        const value = e.target.value;
        setSelectedBin(value);
    
        if (vessels?.length > 0) {
            switch (topcardSelected) {
                case 'SUBSCRIBERS':
                    getCaptiveDashSubscribersLineChart(vessels, newSummaryStartDate, newSummaryEndDate, value, selectedSubscribers, selectedPlans);
                    break;
                case 'SESSIONS':
                    getCaptiveDashSessionsLineChart(vessels, newSummaryStartDate, newSummaryEndDate, value, selectedSubscribers, selectedPlans);
                    break;
                case 'TOTAL USAGE':
                    getCaptiveDashUsageLineChart(vessels, newSummaryStartDate, newSummaryEndDate, value, selectedSubscribers, selectedPlans);
                    break;
                default:
                    break;
            }
        }
    };

    const chartRef = useRef<HighchartsReact.RefObject>(null);

    const handleDownloadCSV = () => {
        if (chartRef.current?.chart) {
            const csvData = chartRef.current.chart.getCSV();
            const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
            const link = document.createElement('a');
            const url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
            link.setAttribute('download', 'captive_usage_data.csv');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } else {
            console.error('Chart reference is null or not initialized.');
        }
    };

    const handleDownloadExcel = () => {
        if (chartRef.current?.chart) {
            const csvData = chartRef.current.chart.getCSV();
            let csvLines = csvData.split('\n');

            let usageRangeSheet: any = [];

            if (chartView === 'column') {
                const headers = csvLines[0].split(',');
                headers[0] = `Usage Range (${dataUnit})`;
                headers[1] = 'Sites Count';
                headers.push('Sites');
                csvLines[0] = headers.join(',');

                csvLines.forEach((line, index) => {
                    if (index === 0) {
                        usageRangeSheet.push(line.split(','));
                        return;
                    }

                    const columns = line.split(',');
                    const [minStr, maxStr] = columns[0].split(' - ').map(value => value.replace(/"/g, '').trim());
                    const minRange = parseInt(minStr, 10);
                    const maxRange = parseInt(maxStr, 10);
                    columns[0] = dataUnit === 'GB'
                        ? `${readableBytesAsGB(minRange, false)} - ${readableBytesAsGB(maxRange, false)}`
                        : `${readableBytesAsMB(minRange, false)} - ${readableBytesAsMB(maxRange, false)}`;

                    const site = histogramData[index - 1];
                    const siteDetails = site?.siteDetails?.map(detail => detail.siteName).join('; ') || '';

                    usageRangeSheet.push([...columns, siteDetails]);
                });
            }

            const sitesUsageSheet = [['Site Name', `Usage (${dataUnit})`]];

            histogramData.forEach(site => {
                site.siteDetails?.forEach(detail => {
                    sitesUsageSheet.push([detail.siteName, dataUnit === 'GB' ? readableBytesAsGB(detail.quotaUsed, false) : readableBytesAsMB(detail.quotaUsed, false)]);
                });
            });

            const wb = XLSX.utils.book_new();
            const ws1 = XLSX.utils.aoa_to_sheet(usageRangeSheet);
            const ws2 = XLSX.utils.aoa_to_sheet(sitesUsageSheet);

            XLSX.utils.book_append_sheet(wb, ws1, 'Usage Range');
            XLSX.utils.book_append_sheet(wb, ws2, 'Sites Usage');

            XLSX.writeFile(wb, 'captive_usage_data.xlsx');
        } else {
            console.error('Chart reference is null or not initialized.');
        }
    };   

    const handleChangeToggle = (e) => {
        let params: any = getDecodeURI(location?.search);
        params.chartView = e.target.checked ? 'spline' : 'column';
        history.push({ pathname: location.pathname, search: `?${getEncodedURI(params)}` });
        if(e.target.checked) { 
            setChartView('spline');
        }
        else { 
            setChartView('column')
        }
    }

    const handleBucket = (e) => {
        setBinSize(e.target.value);
    }

    const handleHistogramClick = () => {
        const GB_TO_BYTES = Math.pow(1000, 3);
        const MB_TO_BYTES = Math.pow(1000, 2);

        const minRangeInBytes = dataUnit === 'GB' ? minBucket * GB_TO_BYTES : minBucket * MB_TO_BYTES;
        const maxRangeInBytes = dataUnit === 'GB' ? maxBucket * GB_TO_BYTES : maxBucket * MB_TO_BYTES;
        getCaptiveDashSitesHistogram(vessels, newSummaryStartDate, newSummaryEndDate, selectedSubscribers, binSize, minRangeInBytes, maxRangeInBytes )
    }

    const handleHistogramResetClick = () => {
        getCaptiveDashSitesHistogram(vessels, newSummaryStartDate, newSummaryEndDate, selectedSubscribers, binSize, 0, 0)
    }

    return (
        <div className="new-summary-chart-container charts-container-captive-dashboard" style={{ marginTop: '15px' }}>
            <div className="alignTopbarItemsCenter">
                <div className="download-summary-btn margin-left-auto" onClick={chartView == 'column' ? handleDownloadExcel : handleDownloadCSV}><img className="downloadImg" src={Download} alt="" /><Button>Download</Button></div>
            </div>
            <Grid container spacing={2} className='new-summary-chart-component'>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Grid container spacing={0} className="usage-per-site-pie">
                        <Grid item xs={5} sm={5} md={5} lg={5} className="pie-chart-summary">
                            <BarChart vessels={vessels} topcardSelected={topcardSelected} selectedTab={selectedTab} values={values} setValues={setValues} applyFilterClick={applyFilterClick} chartRef={chartRef}/>
                        </Grid>
                        {<Grid item xs={7} sm={7} md={7} lg={7}>
                            <Grid className={resetDate.length > 0 ? "legends-pie-new-container justify-content-tabs removeMarginTop" : "legends-pie-new-container justify-content-tabs"}>
                                {selectedTab== 'sites' && topcardSelected == 'TOTAL USAGE' ? <Grid>
                                    <span className="title-text">Histogram</span>
                                    <Switch
                                        checked={chartView == 'spline' ? true : false}
                                        onChange={handleChangeToggle}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                        size="small"
                                    />
                                    <span className="title-text usage-trend">Usage Over Time</span>
                                </Grid> : <Grid className="captive-line-chart-title">
                                    {topcardSelected == 'TOTAL USAGE' ? 'Usage Over Time' : topcardSelected == 'SUBSCRIBERS' ? 'Subscribers over Time' : 'Sessions over Time'}
                                </Grid>}
                                <Grid className="time-series-legends-new">
                                    {topcardSelected == 'TOTAL USAGE' && chartView == 'column' && <>
                                        <Grid className="summary-pie-bar-legends">
                                            <div className="summary-pie-usage-dot" style={{ background: '#86c6df' }}></div>
                                            <span className="barChart-legends">Sites</span>
                                        </Grid>
                                        <FormControlLabel
                                            value="Zoom Reset"
                                            control={<IconButton onClick={() => { setPopupOpen(true) }}><ModeIcon style={{ height: "18px", width: "24px" }} /></IconButton>}
                                            label=""
                                            labelPlacement="start"
                                        />
                                    </>}
                                    {chartView == 'spline' && <>
                                        <Grid className="summary-pie-bar-legends">
                                            <div className="summary-pie-usage-dot" style={{ background: '#86c6df' }}></div>
                                            <span className="barChart-legends">{topcardSelected === "TOTAL USAGE" ? "Usage" : topcardSelected == 'SUBSCRIBERS' ? 'Subscribers' : 'Sessions'}</span>
                                        </Grid>
                                        <Grid className="pieChat-selectTag">
                                            <FormControl variant="standard" className='selectEntry-pie'>
                                                <Select
                                                    labelId="demo-simple-select-standard-label"
                                                    id="demo-simple-select-standard"
                                                    value={selectedBin}
                                                    onChange={handleBinChange}
                                                >
                                                    {getMinutes(newSummaryStartDate, newSummaryEndDate) >= 5 && <MenuItem value={'5 minutes'}>5m</MenuItem>}
                                                    {getMinutes(newSummaryStartDate, newSummaryEndDate) >= 10 && <MenuItem value={'10 minutes'}>10m</MenuItem>}
                                                    {getMinutes(newSummaryStartDate, newSummaryEndDate) >= 15 && <MenuItem value={'15 minutes'}>15m</MenuItem>}
                                                    {getMinutes(newSummaryStartDate, newSummaryEndDate) >= 30 && <MenuItem value={'30 minutes'}>30m</MenuItem>}
                                                    {getMinutes(newSummaryStartDate, newSummaryEndDate) >= 60 && <MenuItem value={'1 hour'}>1h</MenuItem>}
                                                    {getMinutes(newSummaryStartDate, newSummaryEndDate) >= 120 && <MenuItem value={'2 hour'}>2h</MenuItem>}
                                                    {getMinutes(newSummaryStartDate, newSummaryEndDate) >= 180 && <MenuItem value={'3 hour'}>3h</MenuItem>}
                                                    {getMinutes(newSummaryStartDate, newSummaryEndDate) >= 360 && <MenuItem value={'6 hour'}>6h</MenuItem>}
                                                    {getMinutes(newSummaryStartDate, newSummaryEndDate) >= 720 && <MenuItem value={'12 hour'}>12h</MenuItem>}
                                                    {getMinutes(newSummaryStartDate, newSummaryEndDate) >= 1440 && <MenuItem value={'1 day'}>1d</MenuItem>}
                                                    {getMinutes(newSummaryStartDate, newSummaryEndDate) >= 10080 && <MenuItem value={'7 day'}>7d</MenuItem>}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <img className={chartType == 'spline' ? "chart-view-icons-enable" : ''} onClick={() => { setChartType('column') }} src={chartType == 'column' ? barIcon : bar_chart} />
                                        <img className={chartType == 'column' ? "chart-view-icons-enable" : ''} onClick={() => { setChartType('spline') }} src={chartType == 'spline' ? line_chart : lineIcon} />
                                        {resetDate.length > 0 &&
                                            <div className="siteCharts__filters-reset-zoom resetZoom-usage">
                                                <div>
                                                    <FormControlLabel
                                                        value="Zoom Back"
                                                        control={<IconButton onClick={handleResetZoom}><ZoomOutIcon /></IconButton>}
                                                        label=""
                                                        labelPlacement="start"
                                                    />
                                                    <FormControlLabel
                                                        value="Zoom Reset"
                                                        control={<IconButton onClick={handleDefaultResetZoom}><RestartAltIcon /></IconButton>}
                                                        label=""
                                                        labelPlacement="start"
                                                    />
                                                </div>
                                            </div>}
                                    </>}
                                </Grid>
                            </Grid>
                            <HighchartsReact
                                highcharts={Highcharts}
                                options={barOptions}
                                ref={chartRef}
                            />
                        </Grid>}
                    </Grid>
                </Grid>
            </Grid>
            {popupOpen ? <Dialog onClose={() => setPopupOpen(false)} open={popupOpen} className="" >
                <DialogTitle className="histogramDialogTitle">
                    <Grid item container style={{ color: "#45464E" }}>
                        Filters
                        <Grid style={{ marginLeft: "auto" }}>
                            <img src={close} onClick={() => setPopupOpen(false)} className="viewUserCloseIcon" />
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent className="viewUserDialogContent menuScroll histogram-dialog" >
                    <Grid>
                        <div className="bucket-outer">
                            <div className="bucket-text">{`Min (${dataUnit})`}</div>
                            <Autocomplete
                                disablePortal
                                value={minBucket}
                                id="combo-box-demo"
                                onInputChange={(e, newValue) => {
                                    if (Number(newValue) >= 0 && rangeList.findIndex((o) => o.value === Number(newValue)) === -1) {
                                        setRangeList((o) => [...o, { label: `${newValue}`, value: Number(newValue) }]);
                                    }
                                }}
                                ListboxProps={{ style: { maxHeight: 150 } }}
                                options={rangeList}
                                sx={{ width: "150px", marginRight: "10px" }}
                                renderInput={(params) => (
                                    <TextField
                                        variant="standard"
                                        {...params}
                                    />
                                )}
                                getOptionDisabled={(option) => option.value >= maxBucket}
                                onChange={(e, newValue: any) => {
                                    if (newValue && newValue?.value) {
                                        setMinBucket(newValue?.value);
                                    }

                                }}
                            />
                        </div>
                    </Grid>
                    <Grid>
                        <div className="bucket-outer">
                            <div className="bucket-text">{`Max (${dataUnit})`}</div>
                            <Autocomplete
                                value={maxBucket}
                                disablePortal
                                id="combo-box-demo"
                                onInputChange={(e, newValue) => {
                                    if (Number(newValue) >= 0 && rangeList.findIndex((o) => o.value === Number(newValue)) === -1) {
                                        setRangeList((o) => [...o, { label: `${newValue}`, value: Number(newValue) }]);
                                    }
                                }}
                                options={rangeList}
                                ListboxProps={{ style: { maxHeight: 150 } }}
                                sx={{ width: "150px", marginRight: "10px" }}
                                renderInput={(params) => (
                                    <TextField
                                        variant="standard"
                                        {...params}
                                    />
                                )}
                                getOptionDisabled={(option) => option.value <= minBucket}
                                onChange={(e, newValue: any) => {
                                    if (newValue && newValue?.value) {
                                        setMaxBucket(newValue?.value);
                                    }
                                }}
                            />
                        </div>
                    </Grid>
                    <Grid >
                        <Grid>
                            <div className="bucket-outer">
                                <div className="bucket-text">Bins</div>
                                <div className="bucket-padding">
                                    <FormControl variant="standard" >
                                        <Select
                                            labelId="demo-simple-select-standard-label"
                                            id="demo-simple-select-standard"
                                            value={binSize ? binSize : 5}
                                            onChange={handleBucket}
                                            sx={{ width: "150px" }}
                                        >
                                            <MenuItem value={5}>5</MenuItem>
                                            <MenuItem value={10}>10</MenuItem>
                                            <MenuItem value={15}>15</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>
                        </Grid>
                        <Grid>
                            <div className="bucket-outer">
                                <div className="bucket-text">Reset</div>
                                <FormControlLabel
                                    value="Reset"
                                    control={<IconButton sx={{ marginRight: "20px" }} onClick={handleHistogramResetClick}><RestartAltIcon /></IconButton>}
                                    label=""
                                    labelPlacement="start"
                                />
                            </div>
                        </Grid>
                    </Grid>
                    <Button
                        sx={{ float: "right" }}
                        className="audit-filter-apply"
                        id="captive-histogram-filter-btn"
                        onClick={handleHistogramClick}
                        variant="contained">
                        OK
                    </Button>
                </DialogContent>
            </Dialog> : null}
            {siteDetailsPopup && <Dialog
                open={siteDetailsPopup}
                onClose={() => setSiteDetailsPopup(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className="menuScroll"
            >
                <DialogTitle className='delete-content-filter-title'>
                    <span className="content-filter-title-name">Site Usage</span>
                    <img src={close} title="Close" onClick={() => setSiteDetailsPopup(false)} className="create-content-filter-close-btn" />
                </DialogTitle>
                <DialogContent className="dialog-list-content-filter-dialog-content">
                    <TableContainer className="content_filter--domain-names-table_container">
                        <Table sx={{ minWidth: 600, maxHeight: 200 }} size="small" aria-label="content_filter--service_accounts">
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <span>S. No.</span>
                                    </TableCell>
                                    <TableCell>
                                        <span>Site Name</span>
                                    </TableCell>
                                    <TableCell>
                                        <span>{`Usage (${dataUnit})`}</span>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody className="tableBody alertsTable-tableBody">
                                {
                                    selectedSiteDetails.length == 0 ?
                                        <></>
                                        :
                                        <>
                                            {
                                                selectedSiteDetails.map((site, index) => {
                                                    return (
                                                        <TableRow key={index}>
                                                            <TableCell>
                                                                {index + 1}
                                                            </TableCell>
                                                            <TableCell>
                                                                {site.siteName}
                                                            </TableCell>
                                                            <TableCell>
                                                                {dataUnit == 'GB' ? readableBytesAsGB(site.quotaUsed, false) : readableBytesAsMB(site.quotaUsed, false)}
                                                            </TableCell>
                                                        </TableRow>
                                                    )
                                                })
                                            }
                                        </>
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </DialogContent>
            </Dialog>}
        </div>
    )
}

const mapStateToProps = (state) => ({
    authReducer: state?.authReducer,
    newSummaryStartDate: state?.authReducer?.newSummaryStartDate,
    newSummaryEndDate: state?.authReducer?.newSummaryEndDate,
    getCaptiveDashSubscribersLineChart: state?.authReducer?.getCaptiveDashSubscribersLineChart,
    getCDSitesSubscribersLineChart: state?.authReducer?.getCDSitesSubscribersLineChart,
    getCDSitesSessionsLineChart: state?.authReducer?.getCDSitesSessionsLineChart,
    getCDSitesUsageLineChart: state?.authReducer?.getCDSitesUsageLineChart,
    getCDSitesHistogram: state?.authReducer?.getCDSitesHistogram,
});

export default withRouter(
    connect(mapStateToProps, { getCaptiveDashSubscribersLineChart, getCaptiveDashSessionsLineChart, getCaptiveDashUsageLineChart, getCaptiveDashSitesHistogram })(CaptiveCharts)
);