import _ from "lodash";
import { useEffect, useState, useRef } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Button, Grid } from "@mui/material";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { getAlertPiechartByName, getAlertPiechartBySite, getAlertLinechartByName, getAlertLinechartBySite, getAlertTopWidget } from '../../../actions/Users/authenticateTrends';
import './alertCharts.css';
import SiteUsagePie from "../ChartsComponent/SiteUsagePie";
import { getDateForChart, getDecodeURI, getEncodedURI, getSingleRecordChartLable, getTimezoneCity } from "../../../utils/util";
import WanUsagePie from "../ChartsComponent/WanUsagePie";
import WanLineChart from "../ChartsComponent/WanLineChart";
import SiteLineChart from "../ChartsComponent/SiteLineChart";
import moment from "moment";
import { getAlertsMain } from '../../../components/AlertsDashboard/slice';
import Download from '../../../asset/image/DownloadUsage.svg';
import Exporting from 'highcharts/modules/exporting';
import ExportData from 'highcharts/modules/export-data';

const AlertCharts = (props) => {
    const { authReducer, handleSiteClick, alertStatus, setAlertsPage, getAlertPiechartByName, getAlertPiechartBySite, getAlertLinechartByName, getAlertLinechartBySite, getAlertTopWidget, getAlertsMain, newSummaryStartDate, newSummaryEndDate, newSummaryWanType, alertPieChartByName, alertPieChartBySite, alertLineChartByName, alertLineChartBySite, location, history } = props;

    const _q = getDecodeURI(location?.search);
    const [vessels, setVessels] = useState([]);
    const [pieChartByName, setPieChartByName] = useState<any>([]);
    const [pieChartBySite, setPieChartBySite] = useState<any>([]);
    const [lineChartByName, setLineChartByName] = useState<any>([]);
    const [lineChartBySite, setLineChartBySite] = useState<any>([]);
    const [toggleBtn, setToggleBtn] = useState(false);
    const [pieLimit, setPieLimit] = useState(_q.hasOwnProperty('pieLimit') ? _q.pieLimit : 10);
    const [chartView, setChartView] = useState('column');
    const [resetDate, setResetDate] = useState([]);
    const [isHistogram, setIsHistogram] = useState(false);
    const getIntervalAlerts = (minutes) => {
        if (minutes <= 1440) {
            return "10m";
        } else if (minutes > 1440 && minutes <= 10080) {
            return "2h";
        } else {
            return "6h";
        }
    }
    const [selectedBin, setSelectedBin] = useState(getIntervalAlerts(Math.ceil(((new Date(newSummaryEndDate)).getTime() - (new Date(newSummaryStartDate)).getTime()) / 60e3)));
    const timeZone = getTimezoneCity(authReducer?.userTimezone);

    const getAlertStatus = (alertStatus) => {
        if(alertStatus?.isCurrentAlert) {
            return ['isCurrentAlert']
        }
        else if(alertStatus?.Alerting && !alertStatus?.Normal) {
            return ['Alerting'];
        } else if(!alertStatus?.Alerting && alertStatus?.Normal) {
            return ['Normal'];
        } else {
            return ['Alerting', 'Normal'];
        }
    }

    const HighchartsInstance = { ...Highcharts };
    Exporting(HighchartsInstance);
    ExportData(HighchartsInstance);
    Highcharts.setOptions({
        exporting: { enabled: false },
    });
    
    useEffect(() => {
        if (_.isEmpty(authReducer?.getVesselsListing))
            return;
        let vessels = [];
        const filteredVessels = authReducer?.filteredVessels;
        if (filteredVessels && filteredVessels.length > 0) {
            vessels = filteredVessels;
        } else {
            vessels = authReducer?.getVesselsListing?.locations;
        }
        setVessels(vessels);
    }, [authReducer?.getVesselsListing, authReducer?.filteredVessels]);

    useEffect(() => {
        if(vessels?.length > 0) {
            if(!toggleBtn) {
                getAlertPiechartByName(vessels, newSummaryStartDate, newSummaryEndDate, newSummaryWanType?.value, alertStatus);
                getAlertLinechartByName(vessels, newSummaryStartDate, newSummaryEndDate, newSummaryWanType?.value, alertStatus, selectedBin);
            } else{
                getAlertPiechartBySite(vessels, newSummaryStartDate, newSummaryEndDate, newSummaryWanType?.value, alertStatus, pieLimit);
                getAlertLinechartBySite(vessels, newSummaryStartDate, newSummaryEndDate, newSummaryWanType?.value, alertStatus, pieLimit, selectedBin);
            }
            getAlertTopWidget(vessels, newSummaryStartDate, newSummaryEndDate, newSummaryWanType?.value, alertStatus);
        } else {
            setPieChartByName([]);
            setPieChartBySite([]);
            setLineChartByName([]);
            setLineChartBySite([]);
        }
    }, [vessels, newSummaryWanType, alertStatus])

    useEffect(() => {
        const interval = getIntervalAlerts(Math.ceil(((new Date(newSummaryEndDate)).getTime() - (new Date(newSummaryStartDate)).getTime()) / 60e3));
        setSelectedBin(interval);
        if(vessels?.length > 0) {
            if(!toggleBtn) {
                getAlertPiechartByName(vessels, newSummaryStartDate, newSummaryEndDate, newSummaryWanType?.value, alertStatus);
                getAlertLinechartByName(vessels, newSummaryStartDate, newSummaryEndDate, newSummaryWanType?.value, alertStatus, interval);
            } else{
                getAlertPiechartBySite(vessels, newSummaryStartDate, newSummaryEndDate, newSummaryWanType?.value, alertStatus, pieLimit);
                getAlertLinechartBySite(vessels, newSummaryStartDate, newSummaryEndDate, newSummaryWanType?.value, alertStatus, pieLimit, interval);
            }
            getAlertTopWidget(vessels, newSummaryStartDate, newSummaryEndDate, newSummaryWanType?.value, alertStatus);
        }
    }, [newSummaryStartDate, newSummaryEndDate])

    useEffect(() => {
        if(!_.isEmpty(alertLineChartByName)) {
            const data = alertLineChartByName?.hasOwnProperty('data') ? alertLineChartByName?.data : {};
            if (data) {
                const lineChartByName: any = data?.count?.series && Object.keys(data?.count?.series)?.map((key, i) => {
                    const colors = ['#2f2282', '#1b51a7', '#3dab97', '#5e3f9e', '#3e88d3', '#1d136a', '#69b8e5', '#3f79ca', '#9e8cc5', '#ed9340'];
                    return { name: key, data: data?.count?.series[key], marker: { symbol: 'circle' }, visible: true, color: colors[i] }
                })
                setLineChartByName(lineChartByName);
                authReducer.alertLineChartByName = {};
            }
        }
        if(!_.isEmpty(alertLineChartBySite)) {
            const data = alertLineChartBySite?.hasOwnProperty('data') ? alertLineChartBySite?.data : {};
            if (data) {
                const lineChartBySite: any = data?.count?.series && Object.keys(data?.count?.series)?.map((key, i) => {
                    return { name: key.split('|')[0], data: data?.count?.series[key], k4Id: key.split('|')[1], marker: { symbol: 'circle' }, visible: true }
                })
                setLineChartBySite(lineChartBySite);
                authReducer.alertLineChartBySite = {};
            }
        }
    }, [alertLineChartByName, alertLineChartBySite])

    useEffect(() => {
        if(!_.isEmpty(alertPieChartByName)) {
            const data = alertPieChartByName?.hasOwnProperty('data') ? alertPieChartByName?.data : {};
            if(data) {
                const colors = ['#2f2282', '#1b51a7', '#3dab97', '#5e3f9e', '#3e88d3', '#1d136a', '#69b8e5', '#3f79ca', '#9e8cc5', '#ed9340'];
                const pieChartByName = data?.rows && data?.rows?.map((row, i) => {
                    return {x: row[0], y: row[1], color: colors[i]}
                })
                setPieChartByName(pieChartByName);
                authReducer.alertPieChartByName = {};
            }
        }
        if(!_.isEmpty(alertPieChartBySite)) {
            const data = alertPieChartBySite?.hasOwnProperty('data') ? alertPieChartBySite?.data : {};
            if(data) {
                const pieChartBySite = data?.rows && data?.rows?.map((row, i) => {
                    return {x: row[0], y: row[2], k4Id: row[1]}
                })
                setPieChartBySite(pieChartBySite);
                authReducer.alertPieChartBySite = {};
            }
        }
    }, [alertPieChartByName, alertPieChartBySite])

    const pinchAndResetChart = (start, end, interval) => {
        let params = getDecodeURI(location?.search);
        params.startDate = start.valueOf();
        params.endDate = end.valueOf();
        params.interval = interval;
        doNavigate(params);
    }

    const chartAreaSelection = () => {
        return (event: any) => {
            let start:any = moment(new Date(Math.ceil(event.xAxis[0].min)));
            let end:any = moment(new Date(Math.floor(event.xAxis[0].max)));
            const timeBand = Math.ceil(((new Date(end)).getTime() - (new Date(start)).getTime()) / 60e3);
            timeBand < 5 ? start = moment(Date.now()).subtract(5, 'minutes') : start = moment(new Date(Math.ceil(event.xAxis[0].min)));
            timeBand < 5 ? end = moment(Date.now()) : end = moment(new Date(Math.floor(event.xAxis[0].max)));
            pinchAndResetChart(start, end, 'customDates');

            let arr:any = resetDate;
            arr.push({startDate: start, endDate: end});
            setResetDate(arr);
            setAlertsPage(1);
            return false;
        }
    }

    const pieOptions: Highcharts.Options = {
        chart: {
            type: 'pie',
            height: '185px',
            style: {
                fontFamily: "Roboto, Nunito Sans, Arial, Verdana, Helvetica, sans-serif",
            },
            backgroundColor: 'none'
        },
        colors: ['#2f2282', '#1b51a7', '#3dab97', '#5e3f9e', '#3e88d3', '#1d136a', '#69b8e5', '#3f79ca', '#9e8cc5', '#ed9340',
            '#8cafdf', '#d9914f', '#86c6df', '#3c7df1', '#61cdc0', '#64a1f4', '#dc6161', '#146680', '#2039b5', '#00ad64',
            '#1b4dac', '#b50000', '#8cb5ec', '#c1d5f6', '#b3bbe6', '#e5a8a8', '#f9dabe', '#4b91f1', '#8dbdff', '#efeab9',
            '#8a39a7', '#d9cb4f', '#145f89', '#e7d696', '#8592e7', '#e78585', '#92d7c9', '#aeaeb1', '#755c5d', '#7c6ce6',
            '#cc7460', '#bd9331', '#15e1e8', '#514ced', '#8633de', '#eb52b3', '#c4355b', '#6dc779', '#5373a3', '#8dc293'
        ],
        title: {
            text: ''
        },
        yAxis: {
            title: {
                text: ''
            }
        },
        tooltip: {
            enabled: true,
            headerFormat: ``,
            pointFormat: '<span style="color: #7e8089">{point.x}</span> <br/> <b style="color: #fff">{point.y}</b><br/>',
            valueDecimals: 0,
            useHTML: true,
            borderRadius: 15,
            backgroundColor: '#060606',
            followPointer: false
        },
        plotOptions: {
            pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                shadow: false,
            }
        },
        legend: {
            enabled: false
        },
        credits: {
            enabled: false
        },
        series: [{
            type: 'pie',
            data: toggleBtn ? pieChartBySite : pieChartByName,
            size: '100%',
            innerSize: '50%',
            dataLabels: {
                enabled: false
            }
        }]
    }

    const lineOptions: any = {
        time: {
            timezone: timeZone
        },
        chart: {
            type: chartView,
            height: '260px',
            zoomType: 'x',
            plotBorderWidth: 1,
            resetZoomButton: {
                theme: {
                    style: {
                        display: 'none'
                    }
                }
            },
            events: {
                selection: chartAreaSelection()
            },
            style: {
                fontFamily: "Roboto, Nunito Sans, Arial, Verdana, Helvetica, sans-serif",
            },
        },
        colors: ['#2f2282', '#1b51a7', '#3dab97', '#5e3f9e', '#3e88d3', '#1d136a', '#69b8e5', '#3f79ca', '#9e8cc5', '#ed9340',
            '#8cafdf', '#d9914f', '#86c6df', '#3c7df1', '#61cdc0', '#64a1f4', '#dc6161', '#146680', '#2039b5', '#00ad64',
            '#1b4dac', '#b50000', '#8cb5ec', '#c1d5f6', '#b3bbe6', '#e5a8a8', '#f9dabe', '#4b91f1', '#8dbdff', '#efeab9',
            '#8a39a7', '#d9cb4f', '#145f89', '#e7d696', '#8592e7', '#e78585', '#92d7c9', '#aeaeb1', '#755c5d', '#7c6ce6',
            '#cc7460', '#bd9331', '#15e1e8', '#514ced', '#8633de', '#eb52b3', '#c4355b', '#6dc779', '#5373a3', '#8dc293'
        ],
        title: {
            text: '',
            align: 'left',
            floating: true,
            x: 12,
            y: 32,
            style: {
                fontWeight: '500'
            }
        },
        lang: {
            noData: "No Data",
        },
        noData: {
            style: {
                fontWeight: 'bold',
                fontSize: '15px',
                color: '#303030',
            },
        },
        credits: {
            enabled: false
        },
        legend: {
            enabled: false
        },
        plotOptions: {
            series: {
                turboThreshold: 1000000,
                stickyTracking: false,
                connectNulls: false,
            },
            column: {
                stacking: 'normal',
                dataLabels: {
                    enabled: false
                }
            },
            colorByPoint: true
        },
        tooltip: {
            valueSuffix: '',
            headerFormat: '',
            pointFormat: '<span style="color: #7e8089">{series.name}</span> <br/> <b style="color: #fff">{point.y}</b><br/>',
            valueDecimals: 0,
            borderWidth: 1,
            useHTML: true,
            borderRadius: 15,
            backgroundColor: '#060606',
        },
        xAxis: {
            gridLineWidth: 0.5,
            reversed: false,
            type: "datetime",
            maxPadding: 0.05,
            showLastLabel: true,
            labels: {
                style: {
                    color: '#2F4B82',
                    fontFamily: 'Inter',
                    fontSize: '9px',
                    fontStyle: 'normal',
                    fontWeight: '500',
                },
                format: toggleBtn && getDateForChart(lineChartBySite) ? getSingleRecordChartLable(lineChartBySite, timeZone) : !toggleBtn && getDateForChart(lineChartByName) ? getSingleRecordChartLable(lineChartByName, timeZone) : ''
            }
        },
        yAxis: {
            min:0,
            tickInterval: 1,
            gridLineWidth: 1,
            labels: {
                format: '{value}',
                style: {
                    color: '#2F4B82',
                    fontFamily: 'Inter',
                    fontSize: '9px',
                    fontStyle: 'normal',
                    fontWeight: '500',
                }
            },
            title: {
                text: 'Count',
            }
        },
        series: toggleBtn ? lineChartBySite : lineChartByName, 
        colorByPoint: true
    }

    const handleChangeToggle = (e) => {
        const value = e.target.checked;
        setToggleBtn(value);
        let params: any = getDecodeURI(location?.search);
        value ? params.toggle = true : delete params.toggle;
        doNavigate(params);
        if(vessels?.length > 0) {
            if(!value) {
                getAlertPiechartByName(vessels, newSummaryStartDate, newSummaryEndDate, newSummaryWanType?.value, alertStatus);
                getAlertLinechartByName(vessels, newSummaryStartDate, newSummaryEndDate, newSummaryWanType?.value, alertStatus, selectedBin);
            } else {
                getAlertPiechartBySite(vessels, newSummaryStartDate, newSummaryEndDate, newSummaryWanType?.value, alertStatus, pieLimit);
                getAlertLinechartBySite(vessels, newSummaryStartDate, newSummaryEndDate, newSummaryWanType?.value, alertStatus, pieLimit, selectedBin);
            }
        }
    };

    const handleResetZoom = () => {
        if(resetDate?.length == 0) {
          return;
        }
        let arr:any = resetDate;
        arr.pop();
        setResetDate(arr);
        if(arr?.length == 0) {
            const start = moment(Date.now()).subtract(1, 'hour');
            const end = moment(Date.now());
            pinchAndResetChart(start, end, '1h');
            setAlertsPage(1);
        }
        else {
            let obj = arr[arr?.length-1];
            let start = obj.startDate;
            let end = obj.endDate;
            pinchAndResetChart(start, end, 'customDates');
            setAlertsPage(1);
        }
    }
    
    const handleDefaultResetZoom = () => {
        setResetDate([]);
        const start = moment(Date.now()).subtract(1, 'hour');
        const end = moment(Date.now());
        setAlertsPage(1);
        pinchAndResetChart(start, end, '1h');
    }

    const handleLegendClick = (index) => {
        let all_true = true;
        let updatedData: any = !newSummaryWanType?.value && !toggleBtn ? [...lineChartByName] : [...lineChartBySite];
        let all_off_turn_on = 0;
        let click = 0;

        updatedData?.forEach((ele, i) => {
            if (!ele.visible) {
                all_true = false;
                all_off_turn_on += 1;
            }
            if (ele.visible) {
                click = i;
            }
        })

        if (click == index && all_off_turn_on == updatedData.length - 1) {
            updatedData?.forEach((ele, i) => {
                updatedData[i] = { ...ele, visible: true }
            })
            !newSummaryWanType?.value && !toggleBtn ? setLineChartByName([...updatedData]) : setLineChartBySite([...updatedData]);
        }
        else if (all_true) {
            updatedData?.forEach((ele, i) => {
                updatedData[i] = { ...ele, visible: false }
            })
            updatedData[index].visible = true;
            !newSummaryWanType?.value && !toggleBtn ? setLineChartByName([...updatedData]) : setLineChartBySite([...updatedData]);
        }
        else {
            updatedData.forEach((ele, i) => {
                updatedData[i] = ele;
            })
            updatedData[index].visible = !updatedData[index].visible;
            !newSummaryWanType?.value && !toggleBtn ? setLineChartByName([...updatedData]) : setLineChartBySite([...updatedData]);
        }
    }

    const handleChangePieLimit = (e) => {
        const value = e.target.value;
        setPieLimit(value);
        let params: any = getDecodeURI(location?.search);
        value == 10 ? delete params.pieLimit : params.pieLimit = value;
        doNavigate(params);
        if(vessels?.length > 0) {
            getAlertPiechartBySite(vessels, newSummaryStartDate, newSummaryEndDate, newSummaryWanType?.value, alertStatus, value);
            getAlertLinechartBySite(vessels, newSummaryStartDate, newSummaryEndDate, newSummaryWanType?.value, alertStatus, value, selectedBin);
        }
    }

    const handleBinChange = (e) => {
        const value = e.target.value;
        setSelectedBin(e.target.value);
        if(vessels?.length > 0) {
            if(!toggleBtn) {
                getAlertLinechartByName(vessels, newSummaryStartDate, newSummaryEndDate, newSummaryWanType?.value, alertStatus, value);
            } else{
                getAlertLinechartBySite(vessels, newSummaryStartDate, newSummaryEndDate, newSummaryWanType?.value, alertStatus, pieLimit, value);
            }
        }
    };

    const doNavigate = (params) => {
        history.push({ pathname: location.pathname, search: `?${getEncodedURI(params)}` });
    }

    const colors = ['#2f2282', '#1b51a7', '#3dab97', '#5e3f9e', '#3e88d3', '#1d136a', '#69b8e5', '#3f79ca', '#9e8cc5', '#ed9340',
        '#8cafdf', '#d9914f', '#86c6df', '#3c7df1', '#61cdc0', '#64a1f4', '#dc6161', '#146680', '#2039b5', '#00ad64',
        '#1b4dac', '#b50000', '#8cb5ec', '#c1d5f6', '#b3bbe6', '#e5a8a8', '#f9dabe', '#4b91f1', '#8dbdff', '#efeab9',
        '#8a39a7', '#d9cb4f', '#145f89', '#e7d696', '#8592e7', '#e78585', '#92d7c9', '#aeaeb1', '#755c5d', '#7c6ce6',
        '#cc7460', '#bd9331', '#15e1e8', '#514ced', '#8633de', '#eb52b3', '#c4355b', '#6dc779', '#5373a3', '#8dc293'
    ];

    const chartRef = useRef<HighchartsReact.RefObject>(null);
        
    const handleDownloadCSV = () => {
        if (chartRef.current?.chart) {
            const csvData = chartRef.current.chart.getCSV();
            const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
            const link = document.createElement('a');
            const url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
            link.setAttribute('download', 'trends_alerts_data.csv');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } else {
            console.error('Chart reference is null or not initialized.');
        }
    };

    return (
        <div className="new-summary-chart-container">
            <div className="alignTopbarItemsCenter">
                <div className="download-summary-btn margin-left-auto" onClick={handleDownloadCSV}><img className="downloadImg" src={Download} alt="" /><Button>Download</Button></div>
            </div>
            <Grid container spacing={2} className='new-summary-chart-component padding-left-5'>
                <Grid item xs={12} sm={12} md={3} lg={3}>
                    {!toggleBtn ? <Grid className="usage-per-site-pie">
                            <WanUsagePie
                                title='Alerts'
                                toggleBtn={toggleBtn}
                                handleChangeToggle={handleChangeToggle}
                                wanOptions={pieOptions}
                                usagePerWanType={pieChartByName}
                                chartRef={chartRef}
                            />
                        </Grid> : <Grid className="usage-per-site-pie">
                        <SiteUsagePie
                            title='Alerts'
                            pieLimit={pieLimit}
                            handleChangePieLimit={handleChangePieLimit}
                            toggleBtn={toggleBtn}
                            handleChangeToggle={handleChangeToggle}
                            wanOptions={pieOptions}
                            usagePerSite={pieChartBySite}
                            handleSiteClick={handleSiteClick}
                            colors={colors}
                            chartRef={chartRef}
                        />
                    </Grid>}
                </Grid>
                <Grid item xs={12} sm={12} md={9} lg={9} className="uptime-charts-component">
                    <Grid className="usage-per-site-pie">
                        {!toggleBtn ? <WanLineChart
                            title='Alerts'
                            data={lineChartByName}
                            setChartView={setChartView}
                            resetDate={resetDate}
                            handleResetZoom={handleResetZoom}
                            handleDefaultResetZoom={handleDefaultResetZoom}
                            options={lineOptions}
                            handleLegendClick={handleLegendClick}
                            chartView={chartView}
                            selectedBin={selectedBin}
                            handleBinChange={handleBinChange}
                            chartRef={chartRef}
                        /> :
                        <SiteLineChart
                            title='Alerts'
                            data={lineChartBySite}
                            setChartView={setChartView}
                            resetDate={resetDate}
                            handleResetZoom={handleResetZoom}
                            handleDefaultResetZoom={handleDefaultResetZoom}
                            options={lineOptions}
                            handleSiteClick={handleSiteClick}
                            colors={colors}
                            chartView={chartView}
                            setIsHistogram={setIsHistogram}
                            selectedBin={selectedBin}
                            handleBinChange={handleBinChange}
                            chartRef={chartRef}
                        /> }
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}

const mapStateToProps = (state) => ({
    authReducer: state?.authReducer,
    newSummaryStartDate: state?.authReducer?.newSummaryStartDate,
    newSummaryEndDate: state?.authReducer?.newSummaryEndDate,
    newSummaryWanType:state?.authReducer?.newSummaryWanType,
    alertPieChartByName: state?.authReducer?.alertPieChartByName,
    alertPieChartBySite: state?.authReducer?.alertPieChartBySite,
    alertLineChartByName: state?.authReducer?.alertLineChartByName,
    alertLineChartBySite: state?.authReducer?.alertLineChartBySite,
});

export default withRouter(
  connect(mapStateToProps, { getAlertPiechartByName, getAlertPiechartBySite, getAlertLinechartByName, getAlertLinechartBySite, getAlertTopWidget, getAlertsMain })(AlertCharts)
);