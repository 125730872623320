import { withRouter } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { useState, useEffect, useRef } from "react";
import { getCaptiveDashSubscribersSessionsTable } from "../../../actions/Users/authenticateCaptiveDahshboard";
import { GET_CAPTIVE_DASHBOARD_SUBSCRIBER_SESSIONS_TABLE } from "../../../actions/types";
import _ from "lodash";
import { Grid, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, InputAdornment, TextField, Box, Paper, Tooltip, Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import { Pagination } from "../../../components/Pagination";
import { getDecodeURI, getEncodedURI, readableBytesAsGB, convertDateTimeIntoTimezone } from "../../../utils/util";
import ascSortingSelected from "../../../asset/image/ascSortingSelected.svg";
import descSortingSelected from "../../../asset/image/descSortingSelected.svg";
import AscSort from "../../../asset/image/AscSort.svg";
import DescSort from "../../../asset/image/DescSort.svg";
import Download from '../../../asset/image/DownloadUsage.svg';
import DownloadCSV, { clearCsvDownload } from "../../../components/DownloadCSV";
import { CsvParameters, downloadAsCSV } from "../../../actions/Users/authenticateCsvDownload";
import { DEFAULT_DATE_FORMAT, MMDDYYYYHMMSS_DATE_FORMAT_24_HRS } from "../../../utils/constants";
import SessionsTable from './SessionsTable';
import close from "../../../asset/image/close.svg";

const SubscriberTable = (props) => {
    const { userTimezone, getCaptiveDashSubscribersSessionsTable, vessels, newSummaryStartDate, newSummaryEndDate, getCDSubscribersSessionsTable, setCaptiveDashSubscribersSessionsTableLoading, csvDataDownloded, csvDownloadId, downloadAsCSV, location, history, values, setValues, applyFilterClick } = props;

    const _q = getDecodeURI(location?.search);
    const DEFAULT_PAGE_SIZE = 10;
    const [searchValue, setSearchValue] = useState('');
    const [page, setPage] = useState(_q.hasOwnProperty('page') ? parseInt(_q.page) : 1);
    const [limit, setLimit] = useState(_q.hasOwnProperty('limit') ? parseInt(_q.limit) : DEFAULT_PAGE_SIZE);
    const ENTER_KEY_CODE = "Enter";
    const [tableData, setTableData] = useState<any>([]);
    const [count, setCount] = useState(0);
    const SUBSCRIBER = 'subscriber';
    const SITE_NAME = 'siteName';
    const PLAN_NAME = 'planName';
    const PLAN_START_DATE = 'planStartDate';
    const PLAN_END_DATE = 'planEndDate';
    const PLAN_QUOTA = 'quota';
    const TOPUPS = 'topUpCount';
    const TUPUP_USAGE = 'topUpQuota';
    const PLAN_USAGE = 'totalPlanQuotaUsed';
    const TOTAL_SESSIONS = 'totalSessions';
    const CHART_USAGE = 'charterUsage';
    const ASC_ORDER = "asc";
    const DESC_ORDER = "desc";
    const [sort, setSort] = useState<any>(_q.hasOwnProperty("sort") ? _q.sort : CHART_USAGE);
    const [sortOrder, setSortOrder] = useState<any>(_q.hasOwnProperty("sortOrder") ? _q.sortOrder : DESC_ORDER);
    const dispatch = useDispatch();
    const searchTimeoutRef = useRef<NodeJS.Timeout>();
    const { selectedSubscribers, selectedPlans } = values;
    const { setSelectedSubscribers, setSelectedPlans } = setValues;
    const [sessionsPopup, setSessionsPopup] = useState(false);
    const [selectedRow, setSelectedRow] = useState<any>({});

    useEffect(() => {
        if (vessels?.length >= 1) {
            getCaptiveDashSubscribersSessionsTable(vessels, newSummaryStartDate, newSummaryEndDate, 1, limit, sort, sortOrder, searchValue, selectedSubscribers, selectedPlans)
        } else {
            setTableData([]);
            setCount(0);
        }
    }, [vessels, newSummaryStartDate, newSummaryEndDate])

    useEffect(() => {
        if(!_.isEmpty(getCDSubscribersSessionsTable)) {
            const data = getCDSubscribersSessionsTable.hasOwnProperty('data') ? getCDSubscribersSessionsTable.data : [];
            setCount(data?.[0]?.['totalCount']);
            setTableData(data);
        }
    }, [getCDSubscribersSessionsTable])

    const convertToCsv = (data: any[][]) => {
        const [_columns, ...rows] = data;
        const csvRows: any[] = [];
        rows.forEach(row => {
            csvRows.push([row[2], row[10], row[3], convertDateTimeIntoTimezone(row[4], userTimezone, "MM-DD-YYYY"), convertDateTimeIntoTimezone(row[5], userTimezone, "MM-DD-YYYY"),  row[6], row[7], row[11], row[12]?.replace(/\[|\]/g, '')?.replace(/,/g, "; "), row[9], row[8], row[11] > 0 ? (row[7] - row[6]) : 0])
        })
        return [['Subscriber', 'Site', 'Plan Name', 'Plan Start', 'Plan End' ,'Plan Quota (GB)', 'Plan Usage (GB)', 'Top Ups' , 'Top Up Size (GB)', 'Sessions', 'Period Usage (GB)', 'Top Up Usage (GB)'], ...csvRows]
    }

    useEffect(() => {
        if (csvDataDownloded && csvDataDownloded.length > 0) {
            if (csvDownloadId === 'captive-dashboard-subscribers-table') {
                const [columns, _countRow, ...rows] = csvDataDownloded;
                DownloadCSV(convertToCsv([columns, ...rows]), {
                    formatters : {
                        0: 'ESC-COMMA',
                        1: 'ESC-COMMA',
                        2: 'ESC-COMMA',
                        3: 'ESC-COMMA',
                    }
                });
                return () => {
                    dispatch(clearCsvDownload())
                }
            }
        }
    }, [csvDataDownloded])

    const doNavigate = (params) => {
        history.push({ pathname: location.pathname, search: `?${getEncodedURI(params)}` });
    }

    const searchBoxKeyDown = (event: any) => {
        if (event && event.key === ENTER_KEY_CODE) {
            doSearch(searchValue);
        }
    }

    const doSearch = (value: string) => {
        let params: any = getDecodeURI(location?.search);
        value ? params.search = value : delete params.search;
        delete params.page;
        const Page = 1;
        setPage(1);
        doNavigate(params);
        vessels && getCaptiveDashSubscribersSessionsTable(vessels, newSummaryStartDate, newSummaryEndDate, Page, limit, sort, sortOrder, value, selectedSubscribers, selectedPlans)
    };

    const Sorting = (field, order) => {
        let params: any = getDecodeURI(location?.search);
        delete params.page;
        params.sort = field;
        params.sortOrder = order;
        const Page = 1;
        setPage(1);
        setSort(field);
        setSortOrder(order);
        doNavigate(params);
        vessels && getCaptiveDashSubscribersSessionsTable(vessels, newSummaryStartDate, newSummaryEndDate, Page, limit, field, order, searchValue, selectedSubscribers, selectedPlans)
    }

    const setSorting = (event: any, field: any, order: any,) => {
        if (event && event.currentTarget) {
            Sorting(field, order)
        }
    };

    const switchSorting = (event: any, field: any) => {
        let order = ASC_ORDER;
        if (sort === field) {
            order = ASC_ORDER === sortOrder ? DESC_ORDER : ASC_ORDER;
        }
        Sorting(field, order)
    };

    const handleChangePage = (e, value) => {
        setPage(value);
        let params: any = getDecodeURI(location?.search);
        value == 1 ? delete params.page : params.page = value;
        doNavigate(params);
        vessels && getCaptiveDashSubscribersSessionsTable(vessels, newSummaryStartDate, newSummaryEndDate, value, limit, sort, sortOrder, searchValue, selectedSubscribers, selectedPlans)
    }

    const handleChangeRowsPerPage = (e) => { 
        const Limit = parseInt(e.target.value);
        const Page = 1;
        let params: any = getDecodeURI(location?.search);
        delete params.page;
        Limit == 10 ? delete params.limit : params.limit = Limit;
        setPage(1);
        setLimit(Limit);
        doNavigate(params);
        vessels && getCaptiveDashSubscribersSessionsTable(vessels, newSummaryStartDate, newSummaryEndDate, Page, Limit, sort, sortOrder, searchValue, selectedSubscribers, selectedPlans)
    }

    const handleDownloadReport = () => {
        let isClickHouse = true;
        const startDate = newSummaryStartDate;
        const endDate = newSummaryEndDate;
        const params: CsvParameters = {
            type: isClickHouse? 'QUERY_CH' : "QUERY",
            id : "captive-dashboard-subscribers-table",
            queryName: 'CAPTIVE_DASHBOARD_SUBSCRIBERS_TABLE',
            payload: {
                "k4Id": vessels && vessels.map((vs) => `'${vs.id}'`).join(','),
                "startDate": startDate,
                "endDate": endDate,
                "search": searchValue,
                "pageOffset": 0,
                "pageSize": count,
                "sortColumn": sort,
                "sortOrder": sortOrder,
                "sub_filter": selectedSubscribers?.length > 0 ? `AND username in (${selectedSubscribers?.map(sb => `'${sb}'`).join(',')})` : '',
                "plan_filter": selectedPlans?.length > 0 ? `AND planName in (${selectedPlans?.map(plan => `'${plan}'`).join(',')})` : ''
              }
        }
        downloadAsCSV(params);
    }

    const rowsOption = [10, 15, 25, 50, 100]

    const handleOnSearchClick = () => {
        doSearch(searchValue);
    }

    const handleOnSearchClear = () => {
        setSearchValue('');
        doSearch('');
    }

    const handleOnSearchChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setSearchValue(e.target.value);
        if (searchTimeoutRef.current) {
            clearTimeout(searchTimeoutRef.current);
        }
        searchTimeoutRef.current = setTimeout(() => {
            searchTimeoutRef.current = undefined;
            doSearch(e.target.value);
        }, 1000)
    }

    const handleSiteClick = (row) => {
        let params: any = getDecodeURI(location?.search);
        params.k4Ids = `${row.k4Id}:${row.siteName}`;
        delete params.page;
        delete params.limit;
        delete params.sort;
        delete params.sortOrder;
        delete params.search;
        doNavigate(params);
    };
    
    const handleClickSubscriber = (row) => {
        setSelectedSubscribers([row.subscriber]);
        applyFilterClick([row.subscriber], selectedPlans);
    }
    
    const handleClickPlan = (row) => {
        setSelectedPlans([row.planName])
        applyFilterClick(selectedSubscribers, [row.planName]);
    }

    const handleOpenSessionsPopup = (row) => {
        setSessionsPopup(true);
        setSelectedRow(row);
        _q.userPlanId = row.userPlanId;
        delete _q.page;
        delete _q.limit;
        delete _q.sort;
        delete _q.sortOrder;
        doNavigate(_q);
    }

    const handleCloseSessionsPopup = () => {
        setSessionsPopup(false);
        setSelectedRow({});
        delete _q.page;
        delete _q.limit;
        delete _q.sort;
        delete _q.sortOrder;
        doNavigate(_q);
        dispatch({type: GET_CAPTIVE_DASHBOARD_SUBSCRIBER_SESSIONS_TABLE, payload: {}});
    }

    const processTopUpQuota = (topUpQuota) => {
        if (!topUpQuota) return { list: '' };
      
        let parsedQuota;
        try {
          parsedQuota = JSON.parse(topUpQuota.replace(/'/g, '"'));
        } catch (error) {
          return { list: '' };
        }
      
        if (!Array.isArray(parsedQuota) || parsedQuota.length === 0) {
          return { list: '' };
        }
      
        return { list: parsedQuota.join(', ') };
      };      

    return (
        <div className="config-container">
            <Grid container spacing={0.5} className="reports-login-history trends-usage-table-container">
                <Grid item xs={12} sm={12} md={12} lg={12} className="search-dropdown-limit-div trends-usage-search-container topbarMarginPaddingReset alignTopbarItemsCenter">
                    <Grid item xs={4} sm={4} md={4} lg={4} className="site-details-header usage-site-details-header">Subscriber Details</Grid>
                    <Grid item xs={8} sm={8} md={8} lg={8} className="usage-table-search-download-div">
                        <Grid className="usage-search-border-color">
                            <Box className="searchbarBox">
                                {<TextField
                                    id="outlined-basic"                                
                                    variant="outlined"
                                    placeholder="Type something"
                                    autoComplete="off"
                                    size="small"
                                    value={searchValue}
                                    onChange={handleOnSearchChange}
                                    onKeyDown={(event) => searchBoxKeyDown(event)}
                                    InputLabelProps={{ className: "serachLabel1" }}
                                    InputProps={{
                                        className: "searchbarInputs",
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                {!searchValue && <SearchIcon
                                                    className="searchbarIconsPosition"
                                                    onClick={handleOnSearchClick}
                                                />}
                                                {searchValue && <CloseIcon
                                                    className="searchbarIconsPosition"
                                                    onClick={handleOnSearchClear}
                                                />}
                                            </InputAdornment>
                                        ),
                                    }}
                                />}
                            </Box>
                        </Grid>
                        {count > 0 && <Grid>
                            <div className="download-summary-btn" onClick={handleDownloadReport}><img className="downloadImg" src={Download} alt="" /><Button>Download</Button></div>
                        </Grid>}
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} className="TopUsersConatiner tablePaddingReset">
                    <Paper className="pagination-position config-pagination new-usage remove-shadow">
                        <Pagination
                            rowsPerPageOptions={rowsOption}
                            count={count ? Number(count) : 0}
                            rowsPerPage={limit ? limit : 0}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Paper>
                    <TableContainer className="usageReports-FleetDashboard loginHistory-tableContainer">
                        <Table aria-label="simple sticky table" stickyHeader>
                            <TableHead className="usageReports-tableHead">
                                <TableRow className="UsageReports-tableRow login-tableRow">
                                    <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell new-summary-left">
                                        <span className="align-items-center">
                                            <span className="login-history-dp-name font-wt-900" onClick={(event) => switchSorting(event, SUBSCRIBER)}>Subscriber</span>
                                            <Grid className="sort-icon-margin">
                                                <img src={sort === SUBSCRIBER && sortOrder === ASC_ORDER ? ascSortingSelected : AscSort}
                                                    className="ascendingOrderIcon sortingImages" onClick={(event) => setSorting(event, SUBSCRIBER, ASC_ORDER)} />
                                                <img src={sort === SUBSCRIBER && sortOrder === DESC_ORDER ? descSortingSelected : DescSort}
                                                    className="sortingImages" onClick={(event) => setSorting(event, SUBSCRIBER, DESC_ORDER)} />
                                            </Grid>
                                        </span>
                                    </TableCell>
                                    <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell new-summary-left">
                                        <span className="align-items-center">
                                            <span className="login-history-dp-name font-wt-900" onClick={(event) => switchSorting(event, SITE_NAME)}>Site</span>
                                            <Grid className="sort-icon-margin">
                                                <img src={sort === SITE_NAME && sortOrder === ASC_ORDER ? ascSortingSelected : AscSort}
                                                    className="ascendingOrderIcon sortingImages" onClick={(event) => setSorting(event, SITE_NAME, ASC_ORDER)} />
                                                <img src={sort === SITE_NAME && sortOrder === DESC_ORDER ? descSortingSelected : DescSort}
                                                    className="sortingImages" onClick={(event) => setSorting(event, SITE_NAME, DESC_ORDER)} />
                                            </Grid>
                                        </span>
                                    </TableCell>
                                    <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell new-summary-left">
                                        <span className="align-items-center">
                                            <span className="login-history-dp-name font-wt-900" onClick={(event) => switchSorting(event, PLAN_NAME)}>Plan Name</span>
                                            <Grid className="sort-icon-margin">
                                                <img src={sort === PLAN_NAME && sortOrder === ASC_ORDER ? ascSortingSelected : AscSort}
                                                    className="ascendingOrderIcon sortingImages" onClick={(event) => setSorting(event, PLAN_NAME, ASC_ORDER)} />
                                                <img src={sort === PLAN_NAME && sortOrder === DESC_ORDER ? descSortingSelected : DescSort}
                                                    className="sortingImages" onClick={(event) => setSorting(event, PLAN_NAME, DESC_ORDER)} />
                                            </Grid>
                                        </span>
                                    </TableCell>
                                    <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell new-summary-left width-usage-sub-th">
                                        <span className="align-items-center">
                                            <span className="login-history-dp-name font-wt-900" onClick={(event) => switchSorting(event, PLAN_START_DATE)}>Plan Start</span>
                                            <Grid className="sort-icon-margin">
                                                <img src={sort === PLAN_START_DATE && sortOrder === ASC_ORDER ? ascSortingSelected : AscSort}
                                                    className="ascendingOrderIcon sortingImages" onClick={(event) => setSorting(event, PLAN_START_DATE, ASC_ORDER)} />
                                                <img src={sort === PLAN_START_DATE && sortOrder === DESC_ORDER ? descSortingSelected : DescSort}
                                                    className="sortingImages" onClick={(event) => setSorting(event, PLAN_START_DATE, DESC_ORDER)} />
                                            </Grid>
                                        </span>
                                    </TableCell>
                                    <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell new-summary-left width-usage-sub-th">
                                        <span className="align-items-center">
                                            <span className="login-history-dp-name font-wt-900" onClick={(event) => switchSorting(event, PLAN_END_DATE)}>Plan End</span>
                                            <Grid className="sort-icon-margin">
                                                <img src={sort === PLAN_END_DATE && sortOrder === ASC_ORDER ? ascSortingSelected : AscSort}
                                                className="ascendingOrderIcon sortingImages" onClick={(event) => setSorting(event, PLAN_END_DATE, ASC_ORDER)} />
                                            <img src={sort === PLAN_END_DATE && sortOrder === DESC_ORDER ? descSortingSelected : DescSort}
                                                className="sortingImages" onClick={(event) => setSorting(event, PLAN_END_DATE, DESC_ORDER)} />
                                            </Grid>
                                        </span>
                                    </TableCell>
                                    <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell new-summary-left width-usage-sub-th">
                                        <span className="align-items-center">
                                            <span className="login-history-dp-name font-wt-900" onClick={(event) => switchSorting(event, PLAN_QUOTA)}>Plan Quota (GB)</span>
                                            <Grid className="sort-icon-margin">
                                                <img src={sort === PLAN_QUOTA && sortOrder === ASC_ORDER ? ascSortingSelected : AscSort}
                                                className="ascendingOrderIcon sortingImages" onClick={(event) => setSorting(event, PLAN_QUOTA, ASC_ORDER)} />
                                            <img src={sort === PLAN_QUOTA && sortOrder === DESC_ORDER ? descSortingSelected : DescSort}
                                                className="sortingImages" onClick={(event) => setSorting(event, PLAN_QUOTA, DESC_ORDER)} />
                                            </Grid>
                                        </span>
                                    </TableCell>
                                    <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell new-summary-left width-usage-sub-th">
                                        <span className="align-items-center">
                                            <span className="login-history-dp-name font-wt-900" onClick={(event) => switchSorting(event, PLAN_USAGE)}>Plan Usage (GB)</span>
                                            <Grid className="sort-icon-margin">
                                                <img src={sort === PLAN_USAGE && sortOrder === ASC_ORDER ? ascSortingSelected : AscSort}
                                                className="ascendingOrderIcon sortingImages" onClick={(event) => setSorting(event, PLAN_USAGE, ASC_ORDER)} />
                                            <img src={sort === PLAN_USAGE && sortOrder === DESC_ORDER ? descSortingSelected : DescSort}
                                                className="sortingImages" onClick={(event) => setSorting(event, PLAN_USAGE, DESC_ORDER)} />
                                            </Grid>
                                        </span>
                                    </TableCell>
                                    <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell new-summary-left width-topups-sub-th">
                                        <span className="align-items-center">
                                            <span className="login-history-dp-name font-wt-900" onClick={(event) => switchSorting(event, TOPUPS)}>Top Ups</span>
                                            <Grid className="sort-icon-margin">
                                                <img src={sort === TOPUPS && sortOrder === ASC_ORDER ? ascSortingSelected : AscSort}
                                                className="ascendingOrderIcon sortingImages" onClick={(event) => setSorting(event, TOPUPS, ASC_ORDER)} />
                                            <img src={sort === TOPUPS && sortOrder === DESC_ORDER ? descSortingSelected : DescSort}
                                                className="sortingImages" onClick={(event) => setSorting(event, TOPUPS, DESC_ORDER)} />
                                            </Grid>
                                        </span>
                                    </TableCell>
                                    <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell new-summary-left width-usage-sub-th">
                                        <span className="align-items-center">
                                            <span className="login-history-dp-name font-wt-900" onClick={(event) => switchSorting(event, TUPUP_USAGE)}>Top Up Size (GB)</span>
                                            <Grid className="sort-icon-margin">
                                                <img src={sort === TUPUP_USAGE && sortOrder === ASC_ORDER ? ascSortingSelected : AscSort}
                                                className="ascendingOrderIcon sortingImages" onClick={(event) => setSorting(event, TUPUP_USAGE, ASC_ORDER)} />
                                            <img src={sort === TUPUP_USAGE && sortOrder === DESC_ORDER ? descSortingSelected : DescSort}
                                                className="sortingImages" onClick={(event) => setSorting(event, TUPUP_USAGE, DESC_ORDER)} />
                                            </Grid>
                                        </span>
                                    </TableCell>
                                    <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell new-summary-left width-loggen-in">
                                        <span>
                                            <span className="login-history-dp-name font-wt-900" onClick={(event) => switchSorting(event, TOTAL_SESSIONS)}>Sessions</span>
                                            <Grid className="sort-icon-margin">
                                                <img src={sort === TOTAL_SESSIONS && sortOrder === ASC_ORDER ? ascSortingSelected : AscSort}
                                                className="ascendingOrderIcon sortingImages" onClick={(event) => setSorting(event, TOTAL_SESSIONS, ASC_ORDER)} />
                                            <img src={sort === TOTAL_SESSIONS && sortOrder === DESC_ORDER ? descSortingSelected : DescSort}
                                                className="sortingImages" onClick={(event) => setSorting(event, TOTAL_SESSIONS, DESC_ORDER)} />
                                            </Grid>
                                        </span>
                                    </TableCell>
                                    <Tooltip title={`${convertDateTimeIntoTimezone(newSummaryStartDate.toString(), userTimezone, MMDDYYYYHMMSS_DATE_FORMAT_24_HRS)} - ${convertDateTimeIntoTimezone(newSummaryEndDate.toString(), userTimezone, MMDDYYYYHMMSS_DATE_FORMAT_24_HRS)}`}>
                                        <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell new-summary-left width-usage-sub-th">
                                            <span className="align-items-center">
                                                <span className="login-history-dp-name font-wt-900" onClick={(event) => switchSorting(event, CHART_USAGE)}>Period Usage (GB)</span>
                                                <Grid className="sort-icon-margin">
                                                    <img src={sort === CHART_USAGE && sortOrder === ASC_ORDER ? ascSortingSelected : AscSort}
                                                    className="ascendingOrderIcon sortingImages" onClick={(event) => setSorting(event, CHART_USAGE, ASC_ORDER)} />
                                                <img src={sort === CHART_USAGE && sortOrder === DESC_ORDER ? descSortingSelected : DescSort}
                                                    className="sortingImages" onClick={(event) => setSorting(event, CHART_USAGE, DESC_ORDER)} />
                                                </Grid>
                                            </span>
                                        </TableCell>
                                    </Tooltip>
                                </TableRow>
                            </TableHead>
                            {count > 0 && tableData && 1 != tableData?.length && !setCaptiveDashSubscribersSessionsTableLoading ? (
                                <TableBody className="tableBody usage-tableBody Table-Body-Opacity">
                                    {
                                        tableData && tableData?.map((item, i) => (
                                            i > 0 && <TableRow key={i} className="loginHistory-tableRow new-summary-usage-tr Table-Row-Opacity">
                                                <Tooltip title={item.subscriber}>
                                                    <TableCell className="login-left-align inter-font Table-Cell-Opacity elipseThreat selectedClick" onClick={() => handleClickSubscriber(item)}>{item?.subscriber}</TableCell>
                                                </Tooltip>
                                                <Tooltip title={item.siteName}>
                                                    <TableCell className="login-left-align inter-font Table-Cell-Opacity elipseThreat selectedClick" onClick={() => handleSiteClick(item)}>{item?.siteName}</TableCell>
                                                </Tooltip>
                                                <TableCell className='login-left-align inter-font Table-Cell-Opacity elipseThreat selectedClick' onClick={() => handleClickPlan(item)}>{item?.planName}</TableCell>
                                                <TableCell className='login-left-align inter-font Table-Cell-Opacity'>{convertDateTimeIntoTimezone(item?.planStartDate, userTimezone, DEFAULT_DATE_FORMAT)}</TableCell>
                                                <TableCell className='login-left-align inter-font Table-Cell-Opacity'>{convertDateTimeIntoTimezone(item?.planEndDate, userTimezone, DEFAULT_DATE_FORMAT)}</TableCell>
                                                <TableCell className='login-left-align inter-font Table-Cell-Opacity'>{item?.quota ? item?.quota : '0'}</TableCell>
                                                <TableCell className='login-left-align inter-font Table-Cell-Opacity'>{item?.totalPlanQuotaUsed ? item?.totalPlanQuotaUsed : '0'}</TableCell>
                                                <TableCell className='login-left-align inter-font Table-Cell-Opacity'>{item?.topUpCount}</TableCell>
                                                <TableCell className="login-left-align inter-font Table-Cell-Opacity topUpQuotaList">
                                                    {(() => {
                                                        const { list } = processTopUpQuota(item?.topUpQuota);
                                                        return (
                                                            <Tooltip title={list} arrow>
                                                                <span>{list}</span>
                                                            </Tooltip>
                                                        );
                                                    })()}
                                                </TableCell>
                                                <TableCell className='login-left-align inter-font Table-Cell-Opacity selectedClick' onClick={() => handleOpenSessionsPopup(item)}>{item?.totalSessions}</TableCell>
                                                <TableCell className='login-left-align inter-font Table-Cell-Opacity'>{item?.charterUsage ? item.charterUsage : '0'}</TableCell>
                                            </TableRow>
                                        ))
                                    }
                                </TableBody>
                            ) : (
                                <TableBody>
                                    <TableRow>
                                        <TableCell colSpan={12} className="noDataAvailableCss" align="center">No data available</TableCell>
                                    </TableRow>
                                </TableBody>
                            )}
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
            {sessionsPopup ? <SessionsData open={sessionsPopup} handleClose={handleCloseSessionsPopup} selectedRow={selectedRow} /> : null}
        </div>
    )
}

const SessionsData = ({ open, handleClose, selectedRow }) => {

    return (
        <div className="Form menuScroll captive-sessions-popup-container">
            <Dialog
                disableEnforceFocus
                open={open}
                onClose={handleClose}
                aria-labelledby='form-dialog-title'
                fullWidth={true}
                maxWidth={'md'}
                sx={{ borderRadius: '8px'}}
                className=' menuScroll sessions-table-popup'
            >
                <div>
                    <DialogTitle className='create-default-config-title' sx={{ borderBottom: '1px solid #EFEFEF', display:'flex', alignItems:'center', justifyContent:'space-between' }}>
                        <span>Sessions</span>
                        <img src={close} title="Close" onClick={handleClose} />
                    </DialogTitle>
                    <DialogContent>
                        <SessionsTable selectedRow={selectedRow} />
                    </ DialogContent>
                </div>
            </Dialog>
        </div>
    )
}

const mapStateToProps = (state) => ({
  userTimezone: state.authReducer.userTimezone,
  newSummaryStartDate: state?.authReducer?.newSummaryStartDate,
  newSummaryEndDate: state?.authReducer?.newSummaryEndDate,
  getCDSubscribersSessionsTable: state?.authReducer?.getCDSubscribersSessionsTable,
  setCaptiveDashSubscribersSessionsTableLoading: state?.authReducer?.setCaptiveDashSubscribersSessionsTableLoading,
  csvDataDownloded: state?.authReducer?.csvDataDownloded,
  csvDownloadId: state?.authReducer?.csvDownloadId
});

export default withRouter(
  connect(mapStateToProps, { getCaptiveDashSubscribersSessionsTable, downloadAsCSV })(SubscriberTable)
);