import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Button, DialogActions, Divider, Grid, TextField, DialogContent } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch } from 'react-redux';
import _ from "lodash";
import { toast } from 'react-toastify';
import ConfirmDialog from "../../ConfirmDialog";
import ServiceVlanActionsPopup from "./VlanActionsPopup";
import RouteActionsPopup from "./RouteActionsPopup";

import { createAgentServiceVlan, getAgentServiceVlans, getAgentRoutes, getAgentServices, createAgentAccessNetwork, updateAgentAccessNetwork, getAgentConfigSummary, getAgents } from "../../../actions/Users/authenticateHub";
import { CREATE_AGENT_ACCESS_NETWORK, UPDATE_AGENT_ACCESS_NETWORK } from "../../../actions/types";


export const AccessNetworkPopup = (props) => {
    const dispatch = useDispatch();
    const { authReducer, getAgentServiceVlans, getAgentServices, setIsActionPopup, isEditActionPopup, createAgentAccessNetwork, updateAgentAccessNetwork, getAgentConfigSummary, agentId, setAgentId, getAgents, selectedDp } = props;

    const [routeItems, setRouteItems] = useState<any[]>([])
    const [services, setServices] = useState<any>([])
    const [serviceVlanItems, setServiceVlanItems] = useState<any[]>([])
    const [selectedValue, setSelectedValue] = useState<any>(null)
    const [serviceVlans, setServiceVlans] = useState<any>([])

    const [confirmDialog, setConfirmDialog] = useState({
        isOpen: false,
        title: "",
        subTitle: "",
        onConfirm: {},
    });
    const [serviceVlanName2, setServiceVlanName2] = useState("")
    const [summary, setSummary] = useState<any>(null)

    useEffect(() => {
        if (isEditActionPopup && summary?.agent_name) { setServiceVlanName2(summary?.agent_name) }
    }, [isEditActionPopup, summary?.agent_name])

    useEffect(() => {
        if (isEditActionPopup && agentId) {
            getAgentConfigSummary(selectedDp?.id, agentId)
        }
    }, [isEditActionPopup, agentId])

    useEffect(() => {
        if (!_.isEmpty(authReducer?.getAgentConfigSummary) && !authReducer?.getAgentConfigSummaryLoading) {
            let configSummary = authReducer?.getAgentConfigSummary?.data
            setSummary(configSummary || [])
            handleRouteItems(configSummary?.routes?.wans)
            setServiceVlanItems(configSummary?.vlans?.vlans)
        }
    }, [authReducer?.getAgentConfigSummary])

    useEffect(() => {
        if (selectedDp?.id) {
            getAgentServiceVlans(selectedDp?.id)
            getAgentServices(selectedDp?.id)
        }
    }, [selectedDp])

    useEffect(() => {
        if (!_.isEmpty(authReducer?.getAgentServiceVlans) && !authReducer?.getAgentServiceVlansLoading) {
            setServiceVlans(authReducer?.getAgentServiceVlans?.items || [])
        }
    }, [authReducer?.getAgentServiceVlans])

    useEffect(() => {
        if (selectedValue && selectedValue?.id) {
            for (let item of serviceVlans) {
                if (item?.id === selectedValue?.id) {
                    setServiceVlanItems(item?.vlans)
                    handleRouteItems(item?.vlans)
                    break
                }
            }
        } else {
            setServiceVlanItems([])
        }
    }, [selectedValue])

    const handleRouteItems = (items: any) => {
        let existingRouteItems: any = []
        if (items?.length) {
            for (let index in items) {
                let item = items[index]
                existingRouteItems.push({ ...item, id: parseInt(index) })
            }
        }
        setRouteItems(existingRouteItems)
    }

    const handleSave = () => {
        let payload = {
            agent_id: agentId,
            agent_name: serviceVlanName2,
            name: `${agentId}_${serviceVlanName2}`,
            vlan: {
                vlans: serviceVlanItems
            },
            route: {
                wans: routeItems
            }
        }
        if (isEditActionPopup) {
            updateAgentAccessNetwork(selectedDp?.id, agentId, payload)
        } else {
            createAgentAccessNetwork(selectedDp?.id, payload)
        }
    }

    const handleClose = () => {
        setIsActionPopup(false)
    }

    const handleClear = () => {
        setServiceVlanItems([])
        setSelectedValue(null)
    }

    useEffect(() => {
        if (!_.isEmpty(authReducer?.createAgentAccessNetwork) && !authReducer?.createAgentAccessNetworkLoading) {
            getAgentConfigSummary(selectedDp?.id, agentId)
            getAgents(selectedDp?.id)
            toast.success("Agent config has been created successfully", { position: toast.POSITION.BOTTOM_LEFT })
            dispatch({ type: CREATE_AGENT_ACCESS_NETWORK, payload: {} })

            handleClear()
        };
    }, [authReducer?.createAgentAccessNetworkLoading, authReducer?.createAgentAccessNetwork])

    useEffect(() => {
        if (!_.isEmpty(authReducer?.updateAgentAccessNetwork) && !authReducer?.updateAgentAccessNetworkLoading) {
            getAgentConfigSummary(selectedDp?.id, agentId)
            getAgents(selectedDp?.id)
            toast.success("Agent config has been updated successfully", { position: toast.POSITION.BOTTOM_LEFT })
            dispatch({ type: UPDATE_AGENT_ACCESS_NETWORK, payload: {} })

            handleClear()
        };
    }, [authReducer?.updateAgentAccessNetworkLoading, authReducer?.updateAgentAccessNetwork])

    useEffect(() => {
        if (!_.isEmpty(authReducer?.getAgentServices) && !authReducer?.getAgentServicesLoading) {
            setServices(authReducer?.getAgentServices?.configured_services || [])
        }
    }, [authReducer?.getAgentServices])

    return (
        <Grid>
            <DialogContent style={{ paddingBottom: "10%", position: "relative", padding: "20px", minHeight: "400px" }}>
                <Grid container className="deploy-inventory-content register-inventory-content">
                    <Grid container className="config-apply-config-target">
                        <Grid xs={2} sm={2} md={2} lg={2} style={{ color: "#45464E", }}>Agent ID</Grid>
                        <Grid xs={4} sm={4} md={4} lg={4}>
                            <TextField
                                className="hub-config-hub-ip"
                                placeholder="Agent ID"
                                value={agentId}
                                onChange={(event) => { setAgentId(event?.target?.value) }}
                                variant="outlined"
                                size="small"
                                disabled={isEditActionPopup ? true : false}
                            />
                        </Grid>
                    </Grid>
                    <Grid container className="config-apply-config-target" style={{ marginTop: "10px", marginBottom: "10px" }}>
                        <Grid xs={2} sm={2} md={2} lg={2} style={{ color: "#45464E", }}>Agent Name</Grid>
                        <Grid xs={4} sm={4} md={4} lg={4}>
                            <TextField
                                className="hub-config-hub-ip"
                                placeholder="Agent Name"
                                value={serviceVlanName2}
                                onChange={(event) => { setServiceVlanName2(event?.target?.value) }}
                                variant="outlined"
                                size="small"
                                disabled={isEditActionPopup ? true : false}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <p style={{ color: "#45464E", fontFamily: "Inter", fontStyle: "normal", fontWeight: 500, fontSize: "14px", marginBottom: "10px", marginTop: "10px", }}> VLANs</p>
                <ServiceVlanActionsPopup serviceVlanItems={serviceVlanItems} setServiceVlanItems={setServiceVlanItems} isEditActionPopup={isEditActionPopup} setRouteItems={setRouteItems} routeItems={routeItems} />
                <>
                    <p style={{ color: "#45464E", fontFamily: "Inter", fontStyle: "normal", marginTop: "30px", fontWeight: 500, fontSize: "14px", marginBottom: "10px", }}> ROUTES</p>
                    <Grid container className="deploy-inventory-content register-inventory-content" style={{ width: "100%", marginTop: "10px" }}>
                        <RouteActionsPopup routeItems={routeItems} setRouteItems={setRouteItems} isEditActionPopup={isEditActionPopup} services={services} />
                    </Grid>
                </>
            </DialogContent>
            <DialogActions>
                <Divider className="threatKontrolDivider" />
                <div className="displayFlex threatKontrolMargin">
                    <Button className="confirm-dialogue-cancel" style={{ color: "#264c86" }} onClick={() => { handleClose() }}> Cancel </Button>
                    <Button variant="contained" style={{ background: "#264c86" }} onClick={() => { handleSave() }}>Save</Button>
                </div>
                <ConfirmDialog confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog} />
            </DialogActions>
        </Grid>
    )
}


const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
    errorReducer: state.errorReducer,
});

export default withRouter(
    connect(mapStateToProps, {
        createAgentServiceVlan,
        getAgentServiceVlans,
        getAgentRoutes,
        getAgentServices,
        createAgentAccessNetwork,
        updateAgentAccessNetwork,
        getAgentConfigSummary,
        getAgents
    })(AccessNetworkPopup)
);