import { TextField, IconButton, ListItemText, ListItem } from '@mui/material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { useEffect, useState } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import DeleteIcon from "@mui/icons-material/Delete"; // Import the delete icon
import { CONFIRM_DIALOG_TITLE, SERVICE_FUNCTION_TO_FEATURE } from "../../../config";
import ConfirmDialog from "../../ConfirmDialog";

const filter = createFilterOptions();

const FreeSoloCreateOption = (props: any) => {
    const { names, check, setConfigName, setIsCreate, value, setValue, styles, handleDeleteAction } = props

    const [confirmDialog, setConfirmDialog] = useState({
        isOpen: false,
        title: "",
        subTitle: "",
        onConfirm: {},
    });

    const [newItem, setNewItem] = useState("")

    useEffect(() => {
        setConfigName(value)
    }, [value])

    return (
        <div>
            <Autocomplete
                forcePopupIcon={true}
                popupIcon={<KeyboardArrowDownIcon />}
                value={value}
                ListboxProps={{ className: 'warehouse-apply-config-props' }}
                className="audit-filter-inputs-usage config-textField"
                onChange={(event, newValue) => {
                    if (typeof newValue === 'string') {
                        setValue({
                            label: newValue,
                        });
                        setIsCreate(false);
                    } else if (newValue && newValue.inputValue) {
                        setValue({
                            label: newValue.inputValue
                        });
                        setIsCreate(true);
                        check(false);
                    } else {
                        setIsCreate(false);
                        setValue(newValue);
                    }
                }}
                filterOptions={(options, params) => {
                    const filtered = filter(options, params);
                    const { inputValue } = params;
                    const isExisting = options.some((option) => inputValue === option.label);
                    if (inputValue.includes("'")) {
                        check(true);
                    }
                    if (inputValue.length == 0) {
                        check(false);
                    }
                    if (inputValue !== '' && !isExisting && !inputValue.includes("'")) {
                        let label = `Add "${inputValue}"`
                        filtered.push({
                            inputValue,
                            label: label,
                        });
                        setNewItem(label)
                    }
                    return filtered;
                }}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                id="free-solo-with-text-demo"
                size='small'
                options={names}
                getOptionLabel={(option) => {
                    if (typeof option === 'string') {
                        return option;
                    }
                    if (option.inputValue) {
                        return option.inputValue;
                    }
                    return option.label;
                }}
                renderOption={(props, option) => (
                    <ListItem
                        {...props}
                        key={option.label}
                        sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} // Ensures proper spacing
                    >
                        <ListItemText primary={option.label} sx={{ flex: 1, marginRight: 2 }} />
                        {newItem !== option.label && (
                            <IconButton
                                edge="end"
                                onClick={(event) => {
                                    event?.stopPropagation();
                                    setConfirmDialog({
                                        isOpen: true,
                                        title: CONFIRM_DIALOG_TITLE,
                                        subTitle: "Are you sure you want to delete?",
                                        onConfirm: () => {
                                            handleDeleteAction(event, option);
                                        },
                                    });
                                }}
                            >
                                <DeleteIcon />
                            </IconButton>
                        )}
                    </ListItem>
                )}
                freeSolo
                renderInput={(params) => (
                    <TextField {...params} placeholder="Select Or Type New" />
                )}
                sx={styles || {}}
            />
            <ConfirmDialog confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog} />
        </div>
    );
}

export default FreeSoloCreateOption