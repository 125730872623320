import { api } from "../../server/request";
import {
  LOGIN_USER, LOGOUT_USER, RECOVERY_PASSWORD_USER, SET_LOADING, CHANGE_PASSWORD, RESET_STATE, OTP_GENERATION_STATUS
} from "../types";
import { GET_ERRORS, OTP_ERROR } from "../error_types";
import { handleResponse, handleError } from "../responseUtil";

export const loginAPI = (formData) => (dispatch) => {
  dispatch({ type: SET_LOADING, payload: true });
  api
    .postRequestOut("/iam/v3/user/login", formData)
    .then((res) => {
      if (res && (res.status === 201 || res.status === 200 || res.status === 202)) {
        localStorage.setItem("loggedInUser", JSON.stringify(res.data));
        localStorage.setItem("ccfEnabled", res.data?.data?.user?.ccfEnabled)
        localStorage.setItem("pacHubEnabled", res.data?.data?.user?.pacHubEnabled)
        dispatch({ type: LOGIN_USER, payload: res.data });
      } else {
        dispatch({ type: LOGIN_USER, payload: res });
      }
    })
    .catch((err) => {
      dispatch({ type: GET_ERRORS, payload: { message: err.message } });
    });
};

export const loginWith2FAAPI = (formData) => (dispatch) => {
  dispatch({ type: SET_LOADING, payload: true });
  api
    .postRequestOut("/iam/emailOtp/validate", formData)
    .then((res) => {
      if(res?.data?.success) {
        if (res && (res.status === 201 || res.status === 200 || res.status === 202)) {
          localStorage.setItem("loggedInUser", JSON.stringify(res.data));
          localStorage.setItem("ccfEnabled", res.data?.data?.user?.ccfEnabled)
          localStorage.setItem("pacHubEnabled", res.data?.data?.user?.pacHubEnabled)
          dispatch({ type: LOGIN_USER, payload: res.data });
        } else {
          dispatch({ type: LOGIN_USER, payload: res });
        }
      } else {
        dispatch({type: OTP_ERROR, payload: true});
      }
     
    })
    .catch((err) => {
      dispatch({type: OTP_ERROR, payload: true});
      dispatch({ type: GET_ERRORS, payload: { message: err.message } });
    });
};

export const generateOtp = (formData) => (dispatch) => {
  dispatch({ type: SET_LOADING, payload: true });
  api
    .postRequestOut("/iam/emailOtp/generate", formData)
    .then((res) => {
      if (res && (res.status === 201 || res.status === 200 || res.status === 202)) {
        
        dispatch({ type: OTP_ERROR, payload: false});
        dispatch({ type: OTP_GENERATION_STATUS, payload: res?.data?.success});
      } else {
        dispatch({ type: OTP_GENERATION_STATUS, payload: res?.data?.success});
      }
    })
    .catch((err) => {
      dispatch({ type: OTP_GENERATION_STATUS, payload: false});
      dispatch({type: OTP_ERROR, payload: true});
      dispatch({ type: GET_ERRORS, payload: { message: err.message } });
    });
};


export const logoutUser = (logoutPayload) => (dispatch) => {
  api
    .postRequest("/iam/user/logout", logoutPayload)
    .then((res) => handleResponse(res, dispatch, LOGOUT_USER))
    .catch((err) => handleError(err, dispatch));
};

export const recoveryPasswordUser = (formData) => (dispatch) => {
  api
    .postRequestOut("/iam/user/recovery", formData)
    .then((res) => handleResponse(res, dispatch, RECOVERY_PASSWORD_USER))
    .catch((err) => handleError(err, dispatch));
};
export const changePassword = (changePassPayload) => (dispatch) => {
  api
    .patchRequest("/iam/user/changePassword/v2", changePassPayload)
    .then((res) => {
      if (res && (res.status === 201 || res.status === 200 || res.status === 202)) {
        dispatch({ type: CHANGE_PASSWORD, payload: res.data });
      } else {
        dispatch({ type: GET_ERRORS, payload: res });
      }
    })
    .catch((err) => {
      console.error(err);
      dispatch({ type: GET_ERRORS, payload: { message: err.message } });
    });

}

export const restState = () => (dispatch) => {
  dispatch({ type: RESET_STATE });
}

export const resetOtpStatus = () => (dispatch) => {
  dispatch({type: OTP_ERROR, payload: false});
}