import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Dialog, DialogProps, DialogTitle, DialogContent, Grid, TextField, Box, Button, Chip, Tooltip } from "@mui/material";
import "./ContentFilterBlockedCategories.css";
import { CheckBox, Label } from "@material-ui/icons";
import _ from "lodash"
import {Category} from "./../../../UserScreen/CustomContentFilter/types"
import {WEB_TITAN_CATEGORIES} from "./../../../UserScreen/CustomContentFilter/categories"

const ContentFilterBlockedCategories = ({blockedCategoriesProps, onSetBlockedCategories})=>{

    const [blockedCategories, setBlockedCategories] = useState<Category[]>([
        {id:0, allowed: false},
        {id:1, allowed: false},
        {id:2, allowed: false},
        {id:3, allowed: false},
        {id:4, allowed: false},
        {id:5, allowed: false},
        {id:6, allowed: false},
        {id:7, allowed: false},
        {id:8, allowed: false},
        {id:9,allowed: false},
        {id:10, allowed: false},
        {id:11, allowed: false},
        {id:12, allowed: false},
        {id:13, allowed: false},
        {id:14,allowed: false},
        {id:15,allowed: false},
        {id:16, allowed: false},
        {id:17, allowed: false},
        {id:18, allowed: false},
        {id:19, allowed: false},
        {id:20,allowed: false},
        {id:21, allowed: false},
        {id:22, allowed: false},
        {id:23, allowed: false},
        {id:24, allowed: false},
        {id:25, allowed: false},
        {id:26, allowed: false},
        {id:27, allowed: false},
        {id:28, allowed: false},
        {id:29, allowed: false},
        {id:30, allowed: false},
        {id:31, allowed: false},
        {id:32, allowed: false},
        {id:33, allowed: false},
        {id:34, allowed: false},
        {id:35, allowed: false},
        {id:36, allowed: false},
        {id:37, allowed: false},
        {id:38, allowed: false},
        {id:39, allowed: false},
        {id:41, allowed: false},
        {id:42, allowed: false},
        {id:43, allowed: false},
        {id:44, allowed: false},
        {id:45, allowed: false},
        {id:46, allowed: false},
        {id:47, allowed: false},
        {id:48, allowed: false},
        {id:49, allowed: false},
        {id:50, allowed: false},
        {id:51, allowed: false},
        {id:52, allowed: false},
        {id:53, allowed: false},
    ])
    const [isSelectedCategory, setIsSelectedCategory] = useState<boolean>(false)

    useEffect(()=>{
        if(blockedCategoriesProps.length == 0) {
            return
        }

        blockedCategories.forEach((category) => {
            if (blockedCategoriesProps.some((prop) => prop.id === category.id)) {
              category.allowed = true;
            }
          });
        setBlockedCategories(blockedCategories)
    }, [])

    useEffect(()=>{
        let selectedCategories:Category[] = []
        if(!_.isEmpty(blockedCategories)){
            let isSelected = false;
            blockedCategories.forEach((category)=>{
                if(category.allowed){
                    isSelected = true

                    selectedCategories.push({
                        id: category.id,
                        allowed: false,
                    })
                }
            })

            if(isSelected){
                setIsSelectedCategory(true)
            } else {
                setIsSelectedCategory(false)
            }

            onSetBlockedCategories(selectedCategories)
        }
    }, [blockedCategories])

    const handleBlockedCategoryChange = (category:any)=>
        (event)=>{
            let newBlockedCategories = blockedCategories.map((blockedCategory)=>{
                if(blockedCategory.id == category.id){
                    return {
                        id: blockedCategory.id,
                        allowed: event.target.checked
                    } 
                } else {
                    return {
                        id: blockedCategory.id,
                        allowed: blockedCategory.allowed
                    }
                }
            })

            setBlockedCategories(newBlockedCategories)
        }

    const handleSelectAllCategories = (event:any)=>{
        let newBlockedCategories = blockedCategories.map((blockedCategory)=>{
            return {
                id: blockedCategory.id,
                allowed: event.target.checked
            }
        })

        setBlockedCategories(newBlockedCategories)
    }

    return (
        <>
            <Grid container xs={12} sm={12} md={12} lg={12}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <span className="content_filter-blocked-categories-heading">Select Blocked Categories</span>
                </Grid>
                {
                    isSelectedCategory ?
                    <>
                        <Grid  item xs={12} sm={12} md={12} lg={12} className="content-filter-blocked-seletced-categoies-div">
                            <span>Selected Categories: </span>
                            {
                                blockedCategories.map((blockedCategory)=>{
                                    if(blockedCategory.allowed){
                                        return (<Chip className="content-filter-selected-blocked-category-chip" key={blockedCategory.id} label={WEB_TITAN_CATEGORIES?.[blockedCategory.id]?.name} onClick={()=>{}} onDelete={()=>{}}/>)
                                    }
                                })
                            }
                        </Grid>
                    </>
                    :
                    <></>
                }
                <Grid container xs={12} sm={12} md={12} lg={12} className="content-filter-blocked-categoies-div">
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <label  className="rc--form-control content_filter-blocked-categories-checkbox-select-all-text">
                            <input type="checkbox" name="Select All" onChange={handleSelectAllCategories}/>
                            Select All
                        </label>
                    </Grid>
                    <Grid container xs={12} sm={12} md={12} lg={12}>
                        {
                            blockedCategories.map((blockedCategory)=>{
                                return (<Grid item xs={3} sm={3} md={3} lg={3} key={blockedCategory.id}>
                                    <Tooltip classes={{ tooltip: 'content_filter--tooltip' }} title={
                                            <Grid classes={{ root: 'content_filter--base--padding_05x' }}>
                                                    {WEB_TITAN_CATEGORIES?.[blockedCategory.id]?.description}
                                            </Grid>
                                    }>
                                    <label  className="rc--form-control content_filter-blocked-categories-checkbox-text">
                                        <input type="checkbox" name={WEB_TITAN_CATEGORIES?.[blockedCategory.id]?.name} checked={blockedCategory.allowed} onChange={handleBlockedCategoryChange(blockedCategory)}/>
                                        {WEB_TITAN_CATEGORIES?.[blockedCategory.id]?.name}
                                    </label>
                                    </Tooltip>
                                </Grid>)
                            })
                        }
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

const mapStateToProps = (state: any) => ({
    authReducer: state.authReducer,
    errorReducer: state.errorReducer,
    customContentFilter: state.customContentFilter,
})

export default withRouter(
    connect(mapStateToProps, null)(ContentFilterBlockedCategories)
);  