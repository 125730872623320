import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { Autocomplete, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from "@mui/material";
import { useEffect, useState } from "react";
import { getDecodeURI, redirectToTopolgyTabs, readableBytesAsGB, getDataFromLocalStorageParsed } from "../../utils/util";
import { getTopologyHubTableData, getHubDashboardClietTableData } from '../../actions/Users/authenticateHub';
import _ from "lodash";
import CircleIcon from '@mui/icons-material/Circle';
import EdgeLinkIcon from "../../../asset/image/EdgeLinkIcon.svg"
import EdgeLinkIconDisabled from "../../../asset/image/linkDisabled.svg";
import { clearData, getGeneratedLinks } from "../../components/RemoteConnections/remoteConnectionSlice";
import { displayToastError } from "../../server/request";
import ClientsData from '../HubDetails/ClientPopup';
import { INTERVALS } from "../../constants/Constants";
import { getOrgHirarchy, getTopologyNodeInfo, resetTopologyDeviceInfo } from "../../actions/Users/authenticateService";
import FloatingGraph from "./FloatingGraph"
// import ForceDirectedGraphComponent from "../../components/TreeGraph/ForceDirectedGraph";
import TextField from "@material-ui/core/TextField/TextField";
import "./TopologyNetworkOrg.css"
import CommonFilters from "../../components/BreadCrumbs/CommonFilters";
import { SIDE_MENU_ITEM_TOPOLOGY } from "../../utils/constants";
import HardcodedOverlay from "./TopologyPopup";
import { updatePermission } from "../../actions/Users/authenticate";
import { SERVICE_FUNCTION_TO_FEATURE } from "../../config";

interface ApiNode {
  dpName: string;
  id: number;
  k4DpId: string;
  parentId: number | null;
  isFirstParent: boolean;
  siteDetails: { k4Id: string; siteName: string }[];
  children: ApiNode[];
  hideSymbol?: boolean;
}

interface TreeGraphNode {
  id: string;
  parent: string | null;
  name: string;
  isSite: boolean;
  useCustomMarker?: boolean;
  imageUrl?: string;
  level?: number;
  hidesymbol?: boolean;
}

const NetworkDiagram = (props) => {
  console.log("REDEING")

  const { selectedOu, startDate, endDate, getHubVesselListing, hubFilteredVessels, getTopologyHubTableData, getTopologyHubTable, setTopologyHubTableLoading, location, history, loading,
    getHubDashboardClietTableData, userTimezone, carrierHubDataClientTable, getOrgHirarchy, orgHirarchy, authReducer, updatePermission, getTopologyNodeInfo, networkTopologySiteSetails ,resetTopologyDeviceInfo} = props;


  if (authReducer && (!authReducer.sideMenu || SIDE_MENU_ITEM_TOPOLOGY !== authReducer.sideMenu.menuItem)) {
    authReducer.sideMenu = { menuItem: SIDE_MENU_ITEM_TOPOLOGY };
  }

  const VIEW_VESSEL_SUMMARY = "view-vessel-summary";
  var Loader = require("react-loader");


  const viewVesselSummaryService = SERVICE_FUNCTION_TO_FEATURE[VIEW_VESSEL_SUMMARY]["service"];
  const viewVesselSummaryFunction = SERVICE_FUNCTION_TO_FEATURE[VIEW_VESSEL_SUMMARY]["function"];

  useEffect(() => {
    const info = {
      permission: {
        service: viewVesselSummaryService,
        serviceFunction: viewVesselSummaryFunction,
      },
      isMultiVessel: false,
      isSingleVessel: false,
      showOu: true,
      ou: authReducer?.selectedOu ? authReducer.selectedOu : ''
    };
    updatePermission(info);
  }, [authReducer?.selectedOu])

  const calculateHeight = (dataLength: number) => Math.max(400, dataLength * 20);
  let _q = getDecodeURI(location?.search);
  const [tableData, setData] = useState<any>([]);
  const [page, setPage] = useState(_q.hasOwnProperty('pageStart') ? _q.pageStart : 1);
  const [limit, setLimit] = useState(_q.hasOwnProperty('pageLimit') ? _q.pageLimit : 10);
  const [selectedOrg, setSelectedOu] = useState<any>({});
  const [vessels, setVessels] = useState([]);
  const USAGE = 'totalUsage';
  const DESC_ORDER = "desc";
  const [sort, setSort] = useState<any>(_q.hasOwnProperty("sort") ? _q.sort : USAGE);
  const [sortOrder, setSortOrder] = useState<any>(_q.hasOwnProperty("sortOrder") ? _q.sortOrder : DESC_ORDER);
  const [interval, setInterval] = useState(_q.hasOwnProperty('interval') && _q.interval == '15mins' ? '15m' : _q.interval ? _q.interval : '1h');
  const [searchValue, setSearchValue] = useState(_q.hasOwnProperty('search') ? _q.search : '');
  const [_interval, setIntervalValue] = useState(_q.hasOwnProperty('interval') ? { label: _q.interval == '15mins' ? '15m' : _q.interval, value: _q.interval } : INTERVALS[1]);
  const [agentId, setAgentId] = useState(_q.hasOwnProperty('agentId') ? _q.agentId : '')
  const [orgHirarchyData, setOrgHirarchyData] = useState<any>({});
  const [selectedSiteId, setSelectedSiteId] = useState<any>()
  const [selectedDpId, setDpId] = useState<any>()
  const [selectedTopoDetails, setSelectedTopoDetails] = useState<any>({})
  const [selectedNameList, setSelectedNameList] = useState("")
  const [isPopUpOpen, setIsPopUpOpen] = useState<any>(false)
  const [isSite, setIsSite] = useState("");

  useEffect(() => {
    console.log("networkTopologySiteSetails", networkTopologySiteSetails)
    let tableData = networkTopologySiteSetails?.data?.rows.map((item) => {
      return networkTopologySiteSetails?.data?.columns.reduce((vsd, columnitem, i) => {
        vsd[columnitem] = item[i]
        return vsd;
      }, {})
    })
    if (tableData && tableData.length > 0) {
      setSelectedTopoDetails(tableData[0])
    } else {
      setSelectedTopoDetails({})
    }
  }, [networkTopologySiteSetails])

  console.log("selectedTopoDetails", selectedTopoDetails)
  useEffect(() => {
    if (!_.isEmpty(selectedOu) && selectedOu?.id) {
      setSelectedOu(selectedOu)
    }
  }, [selectedOu])

  useEffect(() => {
    if (selectedOu?.id) {
      let payload = {
        "dp_id": selectedOu?.id
      }
      getOrgHirarchy(payload)
    }
  }, [selectedOu])

  useEffect(() => {
    //if(selectedSiteId) {
    // let payload = {
    //   "dp_id": selectedOu?.id
    // }
    if(isSite !== "" && selectedSiteId) {
      getTopologyNodeInfo(selectedSiteId, isSite)
    }
 
    //}
  }, [selectedSiteId,isSite])

  useEffect(() => {
    if (_.isEmpty(getHubVesselListing))
      return;
    let Vessels = [];
    const filteredVessels = hubFilteredVessels;
    if (filteredVessels && filteredVessels.length > 0) {
      Vessels = filteredVessels;
    } else {
      Vessels = getHubVesselListing?.data;
    }
    setVessels(Vessels);
  }, [getHubVesselListing, hubFilteredVessels])

  useEffect(() => {
    const _interval = _q.interval == '15mins' ? '15m' : _q.interval ? _q.interval : '1h';
    const siteId = _q.siteId;
    setInterval(_interval);
    if (selectedOrg?.id && !_.isEmpty(vessels)) {
      getTopologyHubTableData(selectedOrg.id, startDate, endDate, page, limit, _interval, vessels, sort, sortOrder, searchValue, siteId, agentId);
    }
  }, [vessels, startDate, endDate])


  // const convertApiToNetworkGraph = (apiResponse) => {
  //   const links:any = [];
  //   const nodes = {};
  //   const colorPalette = [
  //     "#FF5733", "#33FF57", "#3357FF", "#F3FF33", "#FF33E1", "#33FFF5", "#FF9933",
  //   ]; // Colors for nodes
  //   let colorIndex = 0;

  //   // Helper function to process nodes recursively
  //   const processNode = (node, parentName = null) => {
  //     // Add the current node to the `nodes` array if it doesn't exist
  //     if (!nodes[node.dpName]) {
  //       nodes[node.dpName] = {
  //         id: node.dpName,
  //         name: node.dpName,
  //         marker: {
  //           radius: parentName ? 10 : 15, // Root nodes have larger radius
  //           fillColor: colorPalette[colorIndex % colorPalette.length],
  //         },
  //       };
  //       colorIndex++;
  //     }

  //     // Add a link from parent to the current node
  //     if (parentName) {
  //       links.push({
  //         from: parentName,
  //         to: node.dpName,
  //         linkLength: 60, // Longer link length for DP-to-DP connections
  //       });
  //     }

  //     // Add site details as child nodes and create links
  //     if (node.siteDetails && Array.isArray(node.siteDetails)) {
  //       node.siteDetails.forEach((site) => {
  //         if (!nodes[site.siteName]) {
  //           nodes[site.siteName] = {
  //             id: site.siteName,
  //             name: site.siteName,
  //             marker: {
  //               radius: 8,
  //               fillColor: nodes[node.dpName].marker.fillColor, // Match parent node color
  //             },
  //           };
  //         }
  //         links.push({
  //           from: node.dpName,
  //           to: site.siteName,
  //           linkLength: 30, // Shorter link length for DP-to-Site connections
  //         });
  //       });
  //     }

  //     // Process children recursively
  //     if (node.children && Array.isArray(node.children)) {
  //       node.children.forEach((child) => processNode(child, node.dpName));
  //     }
  //   };

  //   // Start processing from the root node
  //   processNode(apiResponse);

  //   return { links, nodes: Object.values(nodes) };
  // };

  const convertApiToNetworkGraph = (apiResponse) => {
    // Helper function to recursively build tree structure
    const processNode = (node) => {
      const treeNode: any = {
        name: node?.dpName,
        image: "", // Placeholder for now
        children: [],
        dpId: node?.k4DpId
      };

      // Process site details as children
      if (node.siteDetails && Array.isArray(node.siteDetails)) {
        treeNode.children = node.siteDetails.map((site) => ({
          name: site.siteName,
          image: "",
          k4id: site?.k4Id,
          isSite: site?.isSite,
          status: `${site?.status}`
        }));
      }

      // Process other children recursively
      if (node.children && Array.isArray(node.children)) {
        const childNodes = node.children.map(processNode);
        treeNode.children = [...treeNode.children, ...childNodes];
      }

      return treeNode;
    };

    return processNode(apiResponse);
  };


  useEffect(() => {
    console.log("orgHirarchy", orgHirarchy)
    if (orgHirarchy && orgHirarchy !== undefined && Object.keys(orgHirarchy).length > 0) {
      let resulthere: any = convertApiToNetworkGraph(orgHirarchy[0])
      console.log("resulthere", resulthere)
      setOrgHirarchyData(resulthere)
    }
  }, [orgHirarchy])

  console.log("orgHirarchyData", orgHirarchyData?.links)

  // const sampleData = {
  //   name: "Root",
  //   image: "https://k4storage.blob.core.windows.net/cdn/sportAssets/commboxedge.kvh.com/headerLogo.png", // Root node with image
  //   children: [
  //     {
  //       name: "Child 1",
  //       children: [
  //         { name: "Grandchild 1", image: "https://www.kognitive.net/wp-content/uploads/2024/06/edgepro-2.png" },
  //         { name: "Grandchild 2" }
  //       ]
  //     },
  //     {
  //       name: "Child 2",
  //       image: "https://www.kognitive.net/wp-content/uploads/2024/06/edge-VX-1.png"
  //     }
  //   ]
  // };

  //test
  //   <Grid item xs={2} sm={2} md={2} lg={2} className="rc--filter_section login-rc--filter_section orgDropSelection">
  //   {/* <div className="optionHeadingPadding12">Device Names</div> */}
  //   <Autocomplete
  //       // disableClearable={selectedDevice ? false : true}
  //       ListboxProps={{ style: { maxHeight: 200, fontSize: '13px' } }}
  //       options={["deviceNames"]}
  //       size="small"
  //       // value={selectedDevice}
  //       // onChange={handleInputDeviceChange}
  //       // getOptionLabel={""}
  //       renderInput={(params) => <TextField {...params} placeholder="Org Names" />}
  //   />
  // </Grid>

  const handleLeafNodeClick = (nameList, node) => {
    console.log("nameList", nameList, node?.k4id);
  
    setSelectedNameList(nameList);
  
    if (node?.k4id) {
      resetTopologyDeviceInfo()
      setSelectedSiteId((prevK4id) => {
        if (prevK4id === node.k4id) {
          setIsPopUpOpen(false);
          setIsSite("")
          return ""; // Reset k4id if it's the same
        } else {
          if(node?.isSite) {
            setIsSite("SITE")
          } else {
            setIsSite("STARLINK")
          }
          setIsPopUpOpen(true);
          return node.k4id;
        }
      });
    }
  };

  const handleOrgClick = (node, nameList) => {
    resetTopologyDeviceInfo()
    setSelectedNameList(nameList)
    console.log("nodeDPHERE",node)

    if (node?.dpId) {
      resetTopologyDeviceInfo()
      setSelectedSiteId((prevdpId) => {
        if (prevdpId=== node.dpId) {
          setIsPopUpOpen(false);
          setIsSite("")
          return ""; // Reset k4id if it's the same
        } else {
          setIsSite("ORG")
          setIsPopUpOpen(true);
          return node.dpId;
        }
      });
    }

  }
  

  const handleSetOpen = () => {
    setIsPopUpOpen(false)

  }

  return <div>

    <CommonFilters loading={loading} showSummaryPeriod={true} showWantType={false} />
    <HardcodedOverlay selectedTopoDetails={selectedTopoDetails} selectedNameList={selectedNameList} open={isPopUpOpen} setOpen={handleSetOpen} selectedSiteId={selectedSiteId} setSelectedSiteId={setSelectedSiteId} isSite={isSite}/>
    {orgHirarchyData && orgHirarchyData !== undefined && Object.keys(orgHirarchyData).length > 0 &&
      // <ForceDirectedGraphComponent
      //   mapStation={orgHirarchyData}
      // />


      <FloatingGraph data={orgHirarchyData} handleLeafNodeClick={handleLeafNodeClick} isPopUpOpen={isPopUpOpen} handleOrgClick={handleOrgClick}/>}
  </div>

}

const mapStateToProps = (state) => ({
  authReducer: state?.authReducer,
  selectedOu: state.authReducer?.selectedOu,
  orgHirarchy: state?.authReducer?.orgHirarchy,
  networkTopologySiteSetails: state?.authReducer?.networkTopologySiteSetails,
  loading: state?.authReducer?.loading
});

export default withRouter(
  connect(mapStateToProps, { getTopologyHubTableData, getHubDashboardClietTableData, getGeneratedLinks, getOrgHirarchy, updatePermission, getTopologyNodeInfo,resetTopologyDeviceInfo })(NetworkDiagram)
);