import { useEffect, useRef, useState } from "react";
import { withRouter } from "react-router-dom";
import { connect, useDispatch } from "react-redux";

import { Button, Box, FormControl, Grid, IconButton, InputAdornment, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip, Typography, Switch, Checkbox, Dialog, InputLabel, Select, MenuItem, RadioGroup, FormControlLabel, Radio, Autocomplete, accordionActionsClasses } from "@mui/material";

import { Search } from '@mui/icons-material';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';

import { Pagination } from "../../Pagination";
import ServiceLineDialog from "../ChangeSubOrgDialog";
// import CreateServiceLineDialog from "../CreateServiceLineDialog";
import './ServiceLines.css';
import AddIcon from '@mui/icons-material/Add';
import { checkLoggedInUserAuthorizedToViewPage, getDataFromLocalStorageParsed, getDecodeURI, getEncodedURI } from '../../../utils/util';

import { bulkUpdateServiceLineOrganization, getServiceLines, subscribeToServiceLines, optInForServiceLine, pauseResumeServiceLine, getAvailableProductsForAccount, clearComponentData, getSubscriptionForAccount } from "../slice";
import SortArrows, { SortOrder } from "../../SortArrows";
import { ServiceLine } from "../types";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from '@mui/icons-material/Close';
import Download from '../../../asset/image/DownloadUsage.svg';
import { CsvParameters, downloadAsCSV } from "../../../actions/Users/authenticateCsvDownload";
import DownloadCSV, { clearCsvDownload } from "../../DownloadCSV";
import { GET_CSV_DATA_TO_DOWNLOAD } from "../../../actions/types";
import CallSplitIcon from '@mui/icons-material/CallSplit';
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import PlayCircleRoundedIcon from '@mui/icons-material/PlayCircleRounded';
import moment from "moment-timezone";
import { SERVICE_FUNCTION_TO_FEATURE } from "../../../config";
import changeGroupIcon from "../../../asset/image/changeGroup.svg";
import SettingsIcon from '@mui/icons-material/Settings';
import EditActionsPopup from "./EditActionsPopup";
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import CreateServiceLineDialog from "../CreateServiceLineDialog";
import _ from "lodash";

const convertToCsv = (data: any[][]) => {
    const [_columns, ...rows] = data;
    const csvRows: any[] = [];
    rows.forEach(row => {
        csvRows.push([row[1], row[9], row[16], row[19], row[18] ? 'Active' : 'Inactive', row[27]?.replaceAll(",", "; ")])
    })
    return [['Organization', 'Sub Organization', 'Service Line', 'Service Plan', 'Status', 'User Terminals'], ...csvRows]
}

interface ServiceLinesProps {
    authReducer: any;
    errorReducer: any;
    history: any;
    location: any;
    dpIds: string[];
    serviceLines: any[];
    totalServiceLines: number;
    getServiceLines: (parameters: any) => void;
    downloadAsCSV: (parameters: any) => void;
    subscribeToServiceLines: (parameters: any) => void;
    optInForServiceLine: (parameters: any) => void;
    pauseResumeServiceLine: (parameters: any) => void;
    clearComponentData: () => void;
    getAvailableProductsForAccount: (parameters: any) => void;
    bulkUpdateServiceLineOrganization: (parameters: any) => Promise<any>;
    allServiceLines: any;
    availableProductsForAccount: any[];
    newSummaryServiceLine: any;
    getSubscriptionForAccount: any;
    subscriptionsForAccount: any;
    selectedServiceAccount:any;
}

interface OptInProps {
    serviceLineNumber: String,
    accountNumber: String,
    accountDpId: String,
    action: String,
    serviceLineName: String
}

interface PauseProps {
    serviceLineNumber: String,
    accountNumber: String,
    accountDpId: String,
    action: String,
    endNow: Boolean,
    serviceLineName: String
}

const STARLINK_ADMIN_SF = "starlink-admin";
const STARLINK_FULL_SF = "starlink-full";
const starlinkAdminService = SERVICE_FUNCTION_TO_FEATURE[STARLINK_ADMIN_SF]["service"];
const starlinkAdminFunction = SERVICE_FUNCTION_TO_FEATURE[STARLINK_ADMIN_SF]["function"];
const starlinkFullService = SERVICE_FUNCTION_TO_FEATURE[STARLINK_FULL_SF]["service"];
const starlinkFullFunction = SERVICE_FUNCTION_TO_FEATURE[STARLINK_FULL_SF]["function"];

export type ServiceLineSortBy = 'serviceLineName' | 'servicePlan' | 'userTerminals' | 'accountDpName' | 'dpName';

function ServiceLines(props: ServiceLinesProps) {

    const { authReducer, errorReducer, history, location, dpIds, serviceLines, allServiceLines, selectedServiceAccount, subscriptionsForAccount, availableProductsForAccount, totalServiceLines, getServiceLines, bulkUpdateServiceLineOrganization, clearComponentData, downloadAsCSV, subscribeToServiceLines, getSubscriptionForAccount, optInForServiceLine, pauseResumeServiceLine, getAvailableProductsForAccount, newSummaryServiceLine } = props;

    const queryParams = new Map<string, string>(Object.entries(getDecodeURI(location.search)));
    const loggedInUser = getDataFromLocalStorageParsed("loggedInUser");
    const params = getDecodeURI(location.search)
    const [selectedOu, setSelectedOu] = useState(params?.ouId)
    const [searchText, setSearchText] = useState<string>('');
    const [optInDialog, setOptInDialog] = useState<boolean>(false);
    const [associateUserTerminalDialog, setAssociateUserTerminalDialog] = useState<boolean>(false);
    const [pauseDialog, setPauseDialog] = useState<boolean>(false);
    const [product, setProduct] = useState('');
    const [optInData, setOptInData] = useState<OptInProps>({
        serviceLineNumber: '',
        accountNumber: '',
        accountDpId: '',
        action: '',
        serviceLineName: ''
    });
    const [pauseData, setPauseData] = useState<PauseProps>({
        serviceLineNumber: '',
        accountNumber: '',
        accountDpId: '',
        action: '',
        endNow: false,
        serviceLineName: ''
    });

    const [tableParams, setTableParams] = useState<{
        page: number;
        size: number;
        sortBy: ServiceLineSortBy;
        sortOrder: SortOrder;
        search: string;
        serviceLineStatus: String[];
    }>({
        page: queryParams.has('page') ? parseInt(queryParams.get('page') as string) : 1,
        size: queryParams.has('size') ? parseInt(queryParams.get('size') as string) : 10,
        sortBy: queryParams.has('sortBy') ? queryParams.get('sortBy') as ServiceLineSortBy : 'serviceLineName',
        sortOrder: queryParams.has('sortOrder') ? queryParams.get('sortOrder') as SortOrder : 'asc',
        search: queryParams.has('search') ? queryParams.get('search') as string : '',
        serviceLineStatus: queryParams.has('serviceLineStatus') ? queryParams.get('serviceLineStatus')?.split(',') as string[] : ['true', 'false']
    });
    const [openSubscriptionDialog, setOpenSubscriptionDialog] = useState(false)
    const [subData, setSubData] = useState<any>(null)
    const [endNow, setEndNow] = useState(false)
    const [rejectReason, setRejectReason] = useState('')
    const [availableProducts, setAvailableProducts] = useState<{
        plan: string;
        product_reference_id: string;
    }[]>([])

    const [openServiceLineDialog, setOpenServiceLineDialog] = useState<boolean>(false);
    const [openCreateServiceLineDialog, setOpenCreateServiceLineDialog] = useState<boolean>(false);
    const [serviceLineToUpdate, setServiceLineToUpdate] = useState<any>(null);
    const searchTimeoutRef = useRef<NodeJS.Timeout>();
    const dispatch = useDispatch();
    const [editActionsPopup, setEditActionsPopup] = useState(false);
    const [confirmDialog, setConfirmDialog] = useState({
        isOpen: false,
        title: "",
        subTitle: "",
        onConfirm: {},
    });
    const [selectedServiceLine, setSelectedServiceLine] = useState<any>({});
    const [mobilePriority, setMobilePriority] = useState(false);
    const [accountsData, setAccountsData] = useState<any>([]);
    const [newTerminalId, setNewTerminalId] = useState<any>(null);
    const [currentServiceLine, setCurrentServiceLine] = useState<any>(null);
    const userTerminalsData:any = []

    const refreshServiceLineTable = (samePage: boolean = false) => {
        let _page = 1;
        if (samePage) {
            _page = tableParams.page;
        }
        setTableParams({
            ...tableParams,
            page: _page
        });
    }

    const handleOpenServiceLineDialog = () => {
        setOpenServiceLineDialog(true);
    }

    const handleAddServiceLineDialog = () =>{
        setOpenCreateServiceLineDialog(true)
        getSubscriptionForAccount({dpIds: authReducer?.selectedOu ? authReducer?.selectedOu?.id : null})
    }

    const handleCloseServiceLineDialog = () => {
        setOpenServiceLineDialog(false);
        setOpenCreateServiceLineDialog(false);
        setServiceLineToUpdate(null);
        setTimeout(()=>{
            handleChangeServiceLine()
        },20000)
    }

    const handleChangeSubOrg = async (dpId: string, serviceLineNumbers: string[]) => {
        await bulkUpdateServiceLineOrganization({
            serviceLineNumbers: serviceLineNumbers,
            dpId: dpId,
        });
        refreshServiceLineTable(true);
        setOpenServiceLineDialog(false);
        setServiceLineToUpdate(null);
    }

    const handleOnSearchClick = () => {
        handleSearch(searchText);
    }

    const handleOnSearchClear = () => {
        setSearchText('');
        handleSearch('');
    }

    useEffect(() => {
        
        handleChangeServiceLine()

    }, [editActionsPopup])

    const handleChangeServiceLine = () =>{
        getServiceLines({
            accounts: selectedServiceAccount,
            dpIds,
            searchText: tableParams.search,
            sortBy: tableParams.sortBy,
            sortOrder: tableParams.sortOrder,
            page: tableParams.page,
            limit: tableParams.size,
            serviceLineStatus: tableParams.serviceLineStatus,
            serviceLines: Array.isArray(newSummaryServiceLine) ? newSummaryServiceLine?.filter(sl => sl !== '') : [],
        });
    }

  

    const handleOnSearchChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setSearchText(e.target.value);
        if (searchTimeoutRef.current) {
            clearTimeout(searchTimeoutRef.current);
        }
        searchTimeoutRef.current = setTimeout(() => {
            searchTimeoutRef.current = undefined;
            handleSearch(e.target.value);
        }, 1000)
    }

    const handleSearch = (value: string) => {
        setTableParams({
            ...tableParams,
            search: value,
            page: 1
        });
    }

    const handleChangePage = (event: any, newPage: number) => {
        setTableParams({ ...tableParams, page: newPage });
    };

    const handleChangeSize = (event: any) => {
        setTableParams({ ...tableParams, size: event.target.value, page: 1 });
    };

    const handleChangeSorting = (sortBy: ServiceLineSortBy, sortOrder: SortOrder) => {
        setTableParams({
            ...tableParams,
            sortBy: sortBy,
            sortOrder: sortOrder,
            page: 1
        });
    }

    const updateQueryParams = () => {
        queryParams.set('page', tableParams.page.toString())
        queryParams.set('size', tableParams.size.toString())
        queryParams.set('sortBy', tableParams.sortBy)
        queryParams.set('sortOrder', tableParams.sortOrder)
        queryParams.set('search', tableParams.search)
        queryParams.set('serviceLineStatus', tableParams.serviceLineStatus.join(','))
        history.replace({ pathname: location.pathname, search: `?${getEncodedURI(Object.fromEntries(queryParams.entries()))}` });
    }

    useEffect(() => {
        updateQueryParams();
        getSubscriptionForAccount({dpIds: authReducer?.selectedOu ? authReducer?.selectedOu?.id : null})
        clearComponentData()
        handleChangeServiceLine()
    }, [tableParams, newSummaryServiceLine]);

    const handleEdit = (serviceLine: any) => {
        setServiceLineToUpdate(serviceLine);
    }

    useEffect(() => {
        if (authReducer.csvDataDownloded && authReducer.csvDataDownloded.length > 0) {
            if (authReducer.csvDownloadId === 'service-lines-csv') {
                const [_columns, status] = authReducer.csvDataDownloded;
                if (status[0] === null) {
                    return;
                }
                if (status && status.length > 0) {
                    const rows = status[0];
                    if (rows.length) {
                        DownloadCSV(convertToCsv([Object.keys(rows[0]), ...rows.map(row => Object.values(row))]), {
                            formatters: {
                                0: 'ESC-COMMA',
                                1: 'ESC-COMMA',
                                4: 'ESC-COMMA'
                            }
                        });
                    }
                }
                return () => {
                    dispatch(clearCsvDownload())
                }
            }
        }
    }, [authReducer.csvDataDownloded])

    const handleDownloadReport = () => {
        const params: CsvParameters = {
            type: 'QUERY_PG',
            id: 'service-lines-csv',
            payload: {
                dpIds: dpIds.map(dpId => `'${dpId}'`).join(','),
                searchText: tableParams.search,
                sortBy: tableParams.sortBy,
                sortOrder: tableParams.sortOrder,
                limit: totalServiceLines,
                offset: 0,
                serviceLineStatus: tableParams.serviceLineStatus.map(status => `'${status}'`).join(','),
                serviceLines: Array.isArray(newSummaryServiceLine) && newSummaryServiceLine?.filter(sl => sl != '').map(sl => `'${sl}'`).join(',')
            },
            queryName: 'GET_SERVICE_LINES_ADMIN',
        }
        downloadAsCSV(params);
    }

    const handlePauseResume = async () => {
        const requestData = {
            dpId: pauseData.accountDpId,
            serviceLineNumber: pauseData.serviceLineNumber,
            accountNumber: pauseData.accountNumber,
            action: pauseData.action,
            productReferenceId: product,
            endNow: endNow,
            rejectReason: rejectReason
        }
        await pauseResumeServiceLine(requestData)

        handleChangeServiceLine()
    }


    const handleChangeOptIn = async (sl, accountDpId, accountNumber, action) => {
        const requestData = {
            dpId: accountDpId,
            serviceLineNumber: sl,
            accountNumber: accountNumber,
            action: action
        }
        await optInForServiceLine(requestData)

        handleChangeServiceLine()
    }

    const handleChangeProduct = (event) => {
        setProduct(event.target.value)
    }

    const handleChangeEndNow = (event) => {
        setEndNow(event.target.value === "Yes" ? true : false)
    }

    const handleChangeSwitch = async (action, sl, dpId) => {
        const date = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");

        const requestData: { serviceLineNumber: any; dpId: string; user: any; action: string; startDate?: string | undefined; endDate?: string | undefined; } =
        {
            serviceLineNumber: [sl],
            dpId: dpId,
            user: loggedInUser?.data?.user?.userName,
            action: action
        };

        if (action === 'subscribe') {
            requestData.startDate = date;
        } else {
            requestData.endDate = date;
        }
        await subscribeToServiceLines(requestData);

        handleChangeServiceLine()

    }

    const handleCloseOptInDialog = () => {
        setOptInDialog(false)
        setPauseDialog(false)
        setOptInData({
            serviceLineNumber: '',
            accountNumber: '',
            accountDpId: '',
            action: '',
            serviceLineName: ''
        })
        setPauseData({
            serviceLineNumber: '',
            accountNumber: '',
            accountDpId: '',
            action: '', serviceLineName: '',
            endNow: false
        })
    }

    const handleOptIn = () => {
        if (optInData?.serviceLineNumber != '' && optInData?.accountDpId != '' && optInData?.accountNumber != '') {
            handleChangeOptIn(optInData?.serviceLineNumber, optInData?.accountDpId, optInData?.accountNumber, optInData.action)
        }
    }


    const handleChangeServiceLineStatus = (event: React.ChangeEvent<HTMLInputElement>) => {
        let _serviceLineStatus = ['true', 'false'];
        if (event.target.value === 'true') {
            _serviceLineStatus = ['true'];
        } else if (event.target.value === 'false') {
            _serviceLineStatus = ['false'];
        }

        setTableParams({
            ...tableParams,
            serviceLineStatus: _serviceLineStatus,
            page: 1
        });
    };

    useEffect(() => {
        if (availableProductsForAccount && availableProductsForAccount.length > 0) {
            let productSubscriptions: any[] = availableProductsForAccount.map(item => {
                return {
                    plan: item[0],
                    product_reference_id: item[1]
                };
            });
            setAvailableProducts(productSubscriptions)
        }

    }, [availableProductsForAccount])

    const handleCloseEditPopUp = () => {
        setEditActionsPopup(false);
    }

    return (
        <Paper elevation={1} classes={{ root: 'starlink--base--mtb_07x starlink--base--padding_1x add-srcoll-starlink-subtab-pages' }}>
            {/* FILTERS */}
            <Grid classes={{ root: 'starlink--base--mtb_07x starlink--base--flex--align--center starlink--base--flex--justify--space_between' }}>
                <Grid>
                    <Typography component="div" classes={{ root: 'starlink--base--font_1x' }}>
                        SERVICE LINES
                    </Typography>
                </Grid>
                <Grid classes={{ root: 'starlink--base--flex starlink--base--flex--align--center' }}>
                    <RadioGroup
                        row
                        aria-labelledby="starlink--service_account_type--radio_group"
                        name="controlled-radio-buttons-group"
                        value={tableParams.serviceLineStatus.length === 2 ? 'all' : tableParams.serviceLineStatus[0]}
                        onChange={handleChangeServiceLineStatus}
                    >
                        <FormControlLabel value="all" control={<Radio size="small" />} label="All" />
                        <FormControlLabel value="true" control={<Radio size="small" />} label="Active" />
                        <FormControlLabel value="false" control={<Radio size="small" />} label="InActive" />
                    </RadioGroup>
                    <Box>
                        <TextField
                            id="search"
                            variant="outlined"
                            placeholder="Type something"
                            autoComplete="off"
                            classes={{ root: "input-box-serviceline" }}
                            size="small"
                            value={searchText}
                            onChange={handleOnSearchChange}
                            onKeyDown={(e) => { if (e.key === 'Enter') { handleOnSearchClick() } }}
                            InputLabelProps={{ className: "serachLabel" }}
                            InputProps={{
                                className: "serachBar",
                                startAdornment: (
                                    <InputAdornment position="start">
                                        {!searchText && <SearchIcon
                                            className="cursorPointer input-search-icons" onClick={handleOnSearchClick}
                                        />}
                                        {searchText && <CloseIcon
                                            className="cursorPointer input-search-icons"
                                            onClick={handleOnSearchClear}
                                        />}
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Box>
                    {openServiceLineDialog && <ServiceLineDialog open={serviceLineToUpdate !== null || openServiceLineDialog} serviceLine={serviceLineToUpdate} onClose={handleCloseServiceLineDialog} onSave={handleChangeSubOrg} />}
                    <Button size="small" variant="contained" className='starlink--button_contained--primary' onClick={()=>{handleAddServiceLineDialog()}} startIcon={<AddIcon />}>Add Service Line</Button>
                    <Button size="small" variant="contained" disabled={!checkLoggedInUserAuthorizedToViewPage(starlinkAdminService, starlinkAdminFunction)} className='starlink--button_contained--primary' onClick={handleOpenServiceLineDialog} startIcon={<CorporateFareIcon />}>Assign</Button>

                    {/* <ServiceLineDialog open={serviceLineToUpdate !== null || openServiceLineDialog} serviceLine={serviceLineToUpdate} onClose={handleCloseServiceLineDialog} onSave={handleChangeSubOrg} /> */}
                    <CreateServiceLineDialog open={openCreateServiceLineDialog} serviceLine={serviceLineToUpdate} data={subscriptionsForAccount} onClose={handleCloseServiceLineDialog} onSave={handleChangeSubOrg} />
                    {totalServiceLines > 0 && <div className="download-summary-btn" onClick={handleDownloadReport}><img className="downloadImg" src={Download} alt="" /><Button>Download</Button></div>}
                </Grid>
            </Grid>

            {/* TABLE PAGINATION */}
            <Pagination count={totalServiceLines} onPageChange={handleChangePage} page={tableParams.page} rowsPerPage={tableParams.size} onRowsPerPageChange={handleChangeSize} />

            {/* SERVICE ACCOUNT TABLE */}
            <TableContainer component={Paper} className="starlink--table_container">
                <Table sx={{ minWidth: 650 }} size="small" aria-label="starlink--service_accounts">
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <SortArrows sortBy={tableParams.sortBy} sortOrder={tableParams.sortOrder} field="accountDpName" onChange={handleChangeSorting}>
                                    <span className="font-wt-900">ORGANIZATION</span>
                                </SortArrows>
                            </TableCell>
                            <TableCell>
                                <SortArrows sortBy={tableParams.sortBy} sortOrder={tableParams.sortOrder} field="dpName" onChange={handleChangeSorting}>
                                    <span className="font-wt-900">SUB ORGANIZATION</span>
                                </SortArrows>
                            </TableCell>
                            <TableCell>
                                <SortArrows sortBy={tableParams.sortBy} sortOrder={tableParams.sortOrder} field="serviceLineName" onChange={handleChangeSorting}>
                                    <span className="font-wt-900">SERVICE LINE</span>
                                </SortArrows>
                            </TableCell>
                            <TableCell>
                                <SortArrows sortBy={tableParams.sortBy} sortOrder={tableParams.sortOrder} field="servicePlan" onChange={handleChangeSorting}>
                                    <span className="font-wt-900">SERVICE PLAN</span>
                                </SortArrows>
                            </TableCell>

                            <TableCell>
                                <span className="font-wt-900">STATUS</span>
                            </TableCell>

                            <TableCell>
                                <SortArrows sortBy={tableParams.sortBy} sortOrder={tableParams.sortOrder} field="userTerminals" onChange={handleChangeSorting}>
                                    <span className="font-wt-900">USER TERMINALS</span>
                                </SortArrows>
                            </TableCell>
                            <TableCell className="starlink-admin-serviceLines-action-colm">
                                <span className="font-wt-900">OPERATIONS</span>
                            </TableCell>

                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            serviceLines?.length > 0 ? serviceLines.map((serviceLine: ServiceLine) => {
                                return <TableRow
                                    key={serviceLine.serviceLineNumber}
                                >
                                    <TableCell>
                                        {serviceLine.accountDpName}
                                    </TableCell>
                                    <TableCell>
                                        {serviceLine.dpName}
                                    </TableCell>
                                    <TableCell>
                                        {serviceLine.serviceLineName ? serviceLine.serviceLineName : serviceLine.serviceLineNumber}
                                    </TableCell>
                                    <TableCell>
                                        {serviceLine.servicePlan ? serviceLine.servicePlan : '-'}
                                    </TableCell>
                                    <TableCell>
                                        {serviceLine.serviceLineStatus ? "Active" : 'Inactive'}
                                    </TableCell>
                                    <TableCell>
                                    <Tooltip title={serviceLine?.userTerminals ? serviceLine?.userTerminals : '-'}>
                                      <div> {serviceLine.userTerminals ? serviceLine.userTerminals?.length > 28 ?  `${serviceLine.userTerminals.slice(0, 28)}...`  : serviceLine.userTerminals  : '-'} </div> 
                                        </Tooltip>
                                    </TableCell>
                                    <TableCell>
                                        {/* edit */}
                                        <Grid container className="starlink-admin-service-lines-actions-td">
                                            <Tooltip title={serviceLine.servicelineSubscription ? "Starlink Cloud : Subscribed" : "Starlink Cloud : UnSubscribed"}>
                                                <div>
                                                    <Switch
                                                        checked={serviceLine.servicelineSubscription}
                                                        disabled={!checkLoggedInUserAuthorizedToViewPage(starlinkAdminService, starlinkAdminFunction)}
                                                        onChange={(e) => { setSubData(serviceLine); setOpenSubscriptionDialog(true)}}
                                                        inputProps={{ 'aria-label': 'controlled' }}
                                                    />
                                                </div>
                                            </Tooltip>
                                            <Tooltip title={!serviceLine.servicelineSubscription ? `Subscribe to service line to access actions` : "Actions"}>
                                                <div>
                                                    <IconButton
                                                        className="starlink-change-sub-org"
                                                        disabled={!serviceLine.servicelineSubscription}
                                                        size='small'>
                                                        <SettingsIcon  className={!serviceLine.servicelineSubscription ? `inventory-edit-icon-disabled starlink-admin-service-lines-actions` : `inventory-edit-icon starlink-admin-service-lines-actions` }
                                                            onClick={() => { setEditActionsPopup(true); setSelectedServiceLine(serviceLine) }} />
                                                    </IconButton>
                                                </div>
                                            </Tooltip>
                                        </Grid>
                                    </TableCell>
                                </TableRow>
                            }) : <TableRow>
                                <TableCell colSpan={8} align='center'>
                                    No data available
                                </TableCell>
                            </TableRow>
                        }
                    </TableBody>
                </Table>
            </TableContainer>

            <Dialog open={associateUserTerminalDialog} onClose={() => { setAssociateUserTerminalDialog(false) }} aria-labelledby="service_account--dialog" maxWidth="md" fullWidth>
                <Grid classes={{ root: 'starlink--base--padding_1x' }}>
                    <Grid container spacing={2} style={{ padding: '1em' }}>
                        <Grid item xs={12}>
                            <Typography variant="h4" component="div" align="center" style={{ fontWeight: 'bold', color: "#424141" }}>
                                ADD STARLINK
                            </Typography>
                            <Typography component="div" style={{ marginTop: '25px' }}>
                                Add a Starlink to this service line  {currentServiceLine?.nickname}.
                            </Typography>
                        </Grid>

                        <Grid item xs={12}>
                            {/* {accountsData && accountsData.userTerminalsArray && accountsData.userTerminalsArray.length > 0 ? */}
                            <Autocomplete
                                fullWidth
                                options={userTerminalsData}
                                getOptionLabel={(option) => `${option?.userTerminal} - ${option?.kitSerialNumber} - ${option?.dishSerialNumber}`}
                                value={userTerminalsData?.find(option => option?.userTerminal === newTerminalId) || null}
                                onChange={(event, newValue) => { setNewTerminalId(newValue?.userTerminal); }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Starlink ID, Starlink Serial Number, or Kit Serial Number"
                                        size="small"
                                        InputProps={{
                                            ...params.InputProps,
                                            style: { height: '48px' }
                                        }}
                                    />
                                )}
                            />
                            {/* : null } */}
                        </Grid>
                    </Grid>

                    <Grid item xs={12} display={"flex"} justifyContent={"center"}>
                        <Button
                            disabled={newTerminalId == null}
                            variant="contained"
                        // onClick={handleSubmit}
                        >
                            ADD STARLINK
                        </Button>
                    </Grid>


                </Grid>
            </Dialog>

            <Dialog
                open={openSubscriptionDialog}
                onClose={() => { setOpenSubscriptionDialog(false); setSubData(null); }}
                maxWidth="md" 
                fullWidth 
                PaperProps={{
                    style: {
                        overflow: 'hidden' 
                    }
                }}
            >
                <Grid container spacing={2} style={{ padding: '1em' }}>
                    <Grid item xs={12}>
                        <Typography variant="h5" component="div" align="center" style={{ fontWeight: 'bold', color: "#424141" }}>
                            {subData?.servicelineSubscription ? "UnSubscribe" : "Subscribe"}  Service Line {subData?.serviceLineName}.
                        </Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <Typography variant="h6" component="div" align="center">
                            Do you want to {subData?.servicelineSubscription ? "unsubscribe" : "subscribe"}?
                        </Typography>
                    </Grid>

                    <Grid item xs={12} className="starlink--base--flex starlink--base--mtb_07x starlink--base--flex--justify--end">
                        <Button
                            variant="outlined"
                            className='starlink--button--cancel'
                            onClick={() => { setOpenSubscriptionDialog(false); setSubData(null); }}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            className='starlink--button_contained--primary'
                            onClick={async(e) => { await handleChangeSwitch(subData?.servicelineSubscription ? "unsubscribe" : "subscribe", subData.serviceLineNumber, subData.dpId); setOpenSubscriptionDialog(false); setSubData(null); }}
                        >
                            {subData?.servicelineSubscription ? "UnSubscribe" : "Subscribe"}
                        </Button>
                    </Grid>
                </Grid>
            </Dialog>


            <Dialog open={optInDialog} onClose={handleCloseOptInDialog} aria-labelledby="service_account--dialog" maxWidth="md" fullWidth>
                <Grid classes={{ root: 'starlink--base--padding_1x' }}>
                    <Grid classes={{ root: 'starlink--base--mtb_07x' }}>
                        <Typography component="div" className="starlink--base--font_2x">
                            {optInData && optInData?.action != '' && optInData?.action == "optOut" ? "Disable" : "Enable"}  Mobile Priority for {optInData?.serviceLineName}.
                        </Typography>
                        <Typography component="div" className="starlink--base--font_1x">
                            {optInData && optInData?.action != '' && optInData?.action == "optOut" ?
                                "Data will continue to be charged for up to 15 minutes." :
                                "Charges will be incurred until Mobie Priority is disabled. Mobile Priority will begin within 15 minutes "

                            }
                        </Typography>
                    </Grid>

                    <Grid classes={{ root: 'starlink--base--flex starlink--base--mtb_07x starlink--base--flex--justify--end' }}>
                        <Button variant="outlined" className='starlink--button--cancel' onClick={handleCloseOptInDialog}>Cancel</Button>
                        <Button variant="contained" className='starlink--button_contained--primary' onClick={handleOptIn} >{optInData && optInData?.action != '' && optInData?.action == "optOut" ? "Disable" : "Enable"}</Button>
                    </Grid>
                </Grid>
            </Dialog>

            <Dialog open={pauseDialog} onClose={handleCloseOptInDialog} aria-labelledby="service_account--dialog" maxWidth="sm" fullWidth>
                <Grid classes={{ root: 'starlink--base--padding_1x' }}>
                    <Grid classes={{ root: 'starlink--base--mtb_07x' }}>
                        <Typography component="div" className="starlink--base--font_2x">
                            {pauseData && pauseData?.action != '' && pauseData?.action} Service Line - {pauseData?.serviceLineName}

                        </Typography>


                        {pauseData && pauseData?.action != '' && pauseData?.action === "Pause" &&
                            <>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <Typography component="div" className="starlink--base--font_1x">
                                        End on
                                    </Typography>

                                    <FormControl sx={{ m: 2, minWidth: 400 }}>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={endNow === true ? "Yes" : "No"}
                                            label=""
                                            onChange={handleChangeEndNow}
                                        >
                                            <MenuItem value="Yes">Now</MenuItem>
                                            <MenuItem value="No">Billing Date</MenuItem>

                                        </Select>
                                    </FormControl>
                                </div>

                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <Typography component="div" className="starlink--base--font_1x">
                                        Reason
                                    </Typography>

                                    <FormControl sx={{ m: 2, minWidth: 400 }}>
                                        <TextField sx={{ padding: 0 }}
                                            className="audit-filter-inputs"
                                            size="small"
                                            variant="outlined"
                                            placeholder=""
                                            value={rejectReason}
                                            onChange={(e) => { setRejectReason(e.target.value) }}

                                        />
                                    </FormControl>
                                </div>
                            </>
                        }


                        {pauseData && pauseData?.action != '' && pauseData?.action === "Resume" &&
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Typography component="div" className="starlink--base--font_1x">
                                    Select Products
                                </Typography>
                                <FormControl sx={{ m: 2, minWidth: 400 }}>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={product}
                                        label="Age"
                                        onChange={(e) => { handleChangeProduct(e) }}
                                    >
                                        {availableProducts.map((product) => {
                                            return <MenuItem value={product.product_reference_id}>{product.plan}</MenuItem>
                                        })}

                                    </Select>
                                </FormControl>
                            </div>
                        }

                    </Grid>

                    <Grid classes={{ root: 'starlink--base--flex starlink--base--mtb_07x starlink--base--flex--justify--end' }}>
                        <Button variant="outlined" className='starlink--button--cancel' onClick={handleCloseOptInDialog}>Cancel</Button>
                        <Button variant="contained" className='starlink--button_contained--primary' onClick={handlePauseResume} disabled={pauseData && pauseData?.action != '' && pauseData?.action === "Resume" && product == ''}>{pauseData && pauseData?.action != '' && pauseData?.action}</Button>
                    </Grid>
                </Grid>
            </Dialog>

            {editActionsPopup ? <EditActionsPopup
                open={editActionsPopup} setOpen={()=>{handleCloseEditPopUp()}}
                confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog}
                row={selectedServiceLine} setRow={setSelectedServiceLine} handleChangeServiceLine={handleChangeServiceLine}
                handleEdit={handleEdit} serviceLine={serviceLineToUpdate} availableCustomPlans={subscriptionsForAccount}
                onSave={handleChangeSubOrg} starlinkService={starlinkAdminService} starlinkFunction={starlinkAdminFunction}
                pauseData={pauseData} setPauseData={setPauseData} endNow={endNow} handleChangeEndNow={handleChangeEndNow} rejectReason={rejectReason} setRejectReason={setRejectReason}
                product={product} setProduct={setProduct} handleChangeProduct={handleChangeProduct} availableProducts={availableProducts} handlePauseResume={handlePauseResume}
                optInData={optInData} setOptInData={setOptInData} mobilePriority={mobilePriority} setMobilePriority={setMobilePriority} handleOptIn={handleOptIn} dpIds={dpIds}
            /> : null}
        </Paper>
    );
}

const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
    errorReducer: state.errorReducer,
    dpIds: state.starlinkCloud.dpIds,
    serviceLines: state.starlinkAdmin.serviceLines,
    totalServiceLines: state.starlinkAdmin.totalServiceLines,
    allServiceLines: state.starlinkReports.allServiceLines,
    availableProductsForAccount: state.starlinkAdmin.availableProductsForAccount,
    newSummaryServiceLine: state?.authReducer?.newSummaryServiceLine,
    subscriptionsForAccount: state.starlinkAdmin.subscriptionsForAccount,
    selectedServiceAccount: state?.authReducer?.selectedServiceAccount,
});

export default withRouter(
    connect(mapStateToProps, {
        getServiceLines,
        bulkUpdateServiceLineOrganization,
        downloadAsCSV,
        subscribeToServiceLines,
        optInForServiceLine,
        pauseResumeServiceLine,
        getAvailableProductsForAccount,
        clearComponentData,
        getSubscriptionForAccount
    })(ServiceLines)
);