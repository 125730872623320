import { withRouter} from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { fetchUserListAPI, parentDpAndSubDpList, updatePermission, updateSideMenu } from "../../actions/Users/authenticate";

import { ContentFilterPageProps } from './types';
import { Grid } from '@mui/material';

import "./index.css";
import { Fragment, useEffect, useState } from "react";
import { SIDE_MENU_ITEM_CONTENT_FILTER } from "../../utils/constants";
import { SERVICE_FUNCTION_TO_FEATURE } from "../../config";
import { checkLoggedInUserAuthorizedToViewPage, populateForParent } from "../../utils/util";
import NotAuthorized from "../NotAuthorized";
import UserGuideDoc from '../../asset/user_guide/Starlink_Cloud_User_Guide.pdf';


import _ from "lodash";
import { setDpIds } from "./slice";
import CommonFilters from "../../components/BreadCrumbs/CommonFilters";
import ContentFilterTable from "../../components/ContentFilter/ContentFilterTable";
import ContentFilterLogs from "../../components/ContentFilter/ContentFilterLogs";
import TabsComponent from "../../components/NewTabs";
import { getDecodeURI, getEncodedURI, strToK4List, updateTimePeriod } from "../../utils/util";

function CustomContentFilter(props: ContentFilterPageProps) {
    const dispatch = useDispatch()
    const { authReducer, errorReducer, match, location, history, customContentFilter, parentDpAndSubDpList, updatePermission, updateSideMenu, setDpIds, fetchUserListAPI } = props;
    const [loading, setLoading] = useState(false);
    const [isAuthorizedToViewPage, setIsAuthorizedToViewPage] = useState<boolean>(false);
    const not_authorized_page_title = "Custom Content Filter";
    const not_authorized_page_message = "You are not authorised to view Custom Content Filter";


    const [activeTab, setActiveTab] = useState(match?.params?.tabs ? match.params.tabs : "configuration");

    const tabs = [
        { tabName: 'Configuration', tabValue: 'configuration', pathName: '/content-filter/configuration' },
        { tabName: 'Logs', tabValue: 'logs', pathName: '/content-filter/logs' },
    ]

    useEffect(() => {

        if ((authReducer && !authReducer.sideMenu) || (SIDE_MENU_ITEM_CONTENT_FILTER !== authReducer.sideMenu.menuItem)) {
            updateSideMenu({ menuItem: SIDE_MENU_ITEM_CONTENT_FILTER });
        }
    }, [])

    useEffect(() => {
        const service = SERVICE_FUNCTION_TO_FEATURE["beta-feature"]["service"];
        const func = SERVICE_FUNCTION_TO_FEATURE["beta-feature"]["function"];
        let authorized = localStorage.getItem("ccfEnabled") === "true" ? true : false
        setIsAuthorizedToViewPage(authorized);
        if (authorized) {
            if (authReducer && authReducer.selectedOu) {
                const info = {
                    permission: {
                        service: service,
                        serviceFunction: func,
                    },
                    isMultiVessel: false,
                    isSingleVessel: false,
                    showOu: true,
                    ou: authReducer?.selectedOu ? authReducer.selectedOu : ''
                };
                updatePermission(info);
            }
        }
    }, [authReducer.selectedOu])

    const helpAction = () => {
        window.open(UserGuideDoc)
    }

    useEffect(() => {
        setLoading(
            authReducer.loading || customContentFilter.gettingContentFilters || customContentFilter.creatingContentFilter || customContentFilter.updatingContentFilter || customContentFilter.deletingContentFilter
        )
    }, [authReducer.loading, customContentFilter.gettingContentFilters, customContentFilter.creatingContentFilter, customContentFilter.updatingContentFilter, customContentFilter.deletingContentFilter])

    useEffect(() => {
        if (!_.isEmpty(authReducer.getOrganisationUnitList)) {
            if (authReducer.getOrganisationUnitList.dps && authReducer.getOrganisationUnitList.dps.length > 0) {
                getDpList(authReducer.getOrganisationUnitList.dps)
            }
        }
    }, [authReducer.getOrganisationUnitList])

    const getDPTree = (ous: any[], arr) => {
        if (ous && Array.isArray(ous)) {
            for (let i = 0; i < ous?.length; i++) {
                const item = ous[i];
                if (arr.hasOwnProperty(item.id)) {
                    const temp: any = arr[item.id];
                    arr[item.id] = { ...temp, ...item._childern };
                } else {
                    arr[item.id] = item._childern;
                }
                getDPTree(item._childern, arr);
            }
        }
    }

    const getDpList = (dpList) => {
        const tempArr: any = {};
        for (let i = 0; i < dpList.length; i++) {
            const _organisations = [];
            populateForParent(dpList[i], null, _organisations);
            getDPTree(_organisations, tempArr);
        }
        parentDpAndSubDpList(tempArr);
    }

     const doNavigateTab = (pathname, tabName) => {
        const currentParams = getDecodeURI(location?.search);
        const params:any = {};
        if (currentParams.ouName) {
            params['ouName'] = currentParams.ouName;
        }
        if (currentParams.ouId) {
            params['ouId'] = currentParams.ouId;
        }
        if (currentParams.k4Ids) {
            params['k4Ids'] = currentParams.k4Ids;
        }
        if(currentParams.sideNav){
            params['sideNav'] = currentParams.sideNav
        }
        if(currentParams.interval) {
            params['interval'] = currentParams.interval
        }
        const { startDate, endDate } = updateTimePeriod(dispatch, location);
        params.startDate = startDate;
        params.endDate = endDate;
        params.tab = tabName;
        history.push({ pathname, search: getEncodedURI(params) });
        setActiveTab(tabName);
    }

    return (
        <Grid>
            {
                isAuthorizedToViewPage ? <>
                    <CommonFilters loading={loading} showSummaryPeriod={false} showWantType={false} helpIcon={false} helpAction={helpAction} ></CommonFilters>
                    <TabsComponent tabs={tabs} handleChangeTab={doNavigateTab} activeTab={activeTab} />
                        {
                            activeTab == 'configuration' ?  
                            <ContentFilterTable></ContentFilterTable>  : 
                            activeTab == "logs" ? 
                            <ContentFilterLogs></ContentFilterLogs> : 
                                <></>
                        }
                    </> 
                :
                <NotAuthorized pageTitle={not_authorized_page_title} PageMessage={not_authorized_page_message} />
            }
        </Grid>
    );
}

const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
    errorReducer: state.errorReducer,
    customContentFilter: state.customContentFilter,
});

export default withRouter(
    connect(mapStateToProps, {
        parentDpAndSubDpList,
        updatePermission,
        updateSideMenu,
        setDpIds,
        fetchUserListAPI,
    })(CustomContentFilter)
);