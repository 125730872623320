import React, { useState, useEffect, useRef } from "react";
import { Grid, Button, Stack, Autocomplete, TextField, Dialog, DialogTitle, DialogContent, Box, InputAdornment, Tabs, Tab } from "@mui/material";
import Paper from "@material-ui/core/Paper";
import { Fragment } from "react";
import { SERVICE_FUNCTION_TO_FEATURE } from "../../config";
import { checkLoggedInUserAuthorizedToViewPage, escapeRegExp, getDecodeURI, getEncodedURI, updateTimePeriod } from "../../utils/util";
import { withRouter } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { SIDE_MENU_ITEM_ACCOUNT_MANAGEMENT, LOGIN_HISTORY, ACCOUNT_MANAGEMENT, GROUP_SETTINGS } from "../../utils/constants";
import { BC_ACCOUNT_MANAGEMENT_RESOURCES, BC_ACCOUNT_MANAGEMENT, PER_PAGE_ITEMS, DEFAULT_PAGE_SIZE, NEW_PER_PAGE_ITEMS, INTERVALS } from "../../constants/Constants";
import ResourceTable from "../../components/ResourceTable";
import AccountManagmentTable from "../../components/AccountManagmentTable";
import NotAuthorized from "../NotAuthorized";
import BreadCrumbs from "../../components/BreadCrumbs/BreadCrumbs";
import { enableDisable2Fa, updatePermission } from "../../actions/Users/authenticate";
import "./AccountManagement.css";
import { accountCreateSubDP, accountDeleteOrganization, getOrganisationInfo, accountModifyOrganization, uplodBulkUsers } from "../../actions/Users/authenticate";
import { CsvParameters, downloadAsCSV } from "../../actions/Users/authenticateCsvDownload";
import CreateChildDP from "./CreateChildDP";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import close from "../../asset/image/close.svg";
import LoginHistoryPage from "../LoginHistoryPage";
import GroupSettings from "../../components/GroupSettings";
import { Pagination } from "../../components/Pagination";
import SubHeaderSummary from "../../components/SubHeaderSummary";
import CommonFilters from "../../components/BreadCrumbs/CommonFilters";
import AccountManagement from '../../asset/user_guide/Account_Management.pdf';
import Download from '../../asset/image/DownloadUsage.svg';
import { SET_FETCH_USERS_LOADING } from "../../actions/types";
import _ from "lodash";
import TabsComponent from "../../components/NewTabs";
import CsvUploader from "../../components/CSVUpload";

const AccountManagment = (props) => {
  const { authReducer, match, history, location, updatePermission, accountCreateSubDP, enableDisable2Fa, downloadAsCSV, accountDeleteOrganization, getOrganisationInfo, accountModifyOrganization, uplodBulkUsers } = props;
  const accountManagement: any = React.useRef();
  const resourceManagement: any = React.useRef();
  const setBreadcrumbInfo: any = React.useRef();
  const [selectedButton, setSelectedButton] = useState("accounts");
  const [isAuthorizedToViewPage, setIsAuthorizedToViewPage] = useState<Boolean>(false);
  const [loading, setLoading] = useState<Boolean>(false);
  let [page, setPage] = useState<number>(0);
  let [limit, setLimit] = useState<number>(DEFAULT_PAGE_SIZE);
  let [search, setSearch] = useState<string>("");
  let [pages, setPages] = useState<number>(0);
  let [total, setTotal] = useState<number>(0);
  let [pageMessage, setPageMessage] = useState("");
  let [selectedOu, setSelectedOu] = React.useState(authReducer?.selectedOu?.name);
  let [selectedOuId, setSelectedOuId] = React.useState(authReducer?.selectedOu?.id ? authReducer?.selectedOu?.id : null);
  let [userOuName, __] = React.useState(authReducer?.userOu?.name ? authReducer?.userOu?.name : '');
  const [searchValue, setSearchValue]: any = React.useState("");
  const [createPopUp, setCreatePopup] = useState(false);
  const [enable2FAPopUp, setEnable2FAPopUp] = useState(false);
  const [ouSearchValue, setOuSearchValue] = useState("");
  const [parentDP, setParentDP] = useState<any>('');
  const [createBtnEnable, setCreateBtnEnable] = useState(true);
  const [errorInDpName, setErrorInDpName] = useState("");
  var Loader = require("react-loader");
  const [activeTab, setActiveTab] = useState<string>(match?.params?.tab ? match.params.tab : ACCOUNT_MANAGEMENT);
  const [showSummaryPeriod, setShowSummaryPeriod] = useState<boolean>(() => match?.params?.tab && match.params.tab === LOGIN_HISTORY);
  const [isAuthorizedToViewBetaFeature, setIsAuthorizedToViewBetaFeature] = useState<Boolean>(false);
  const [isAuthorizedAdminUser, setIsAuthorizedAdminUser] = useState<Boolean>(false);
  const searchTimeoutRef = useRef<NodeJS.Timeout>();

  const getDefaultTabValue = () => {
   let tab  = match.params.tab
    if(tab.includes("account-activity")){
      return 1
    }else if(tab.includes("groupSettings")){
      return 2
    }else{
      return 0
    }
  }
  const [value, setValue] = useState(getDefaultTabValue());
  const [interval, setInterval] = useState(INTERVALS[0]);
  const dispatch =  useDispatch();
  const [selectedEditOrgBtn, setSelectedEditOrgBtn] = useState('create');
  const [orgToDelete, setOrgToDelete] = useState<any>({});
  const [existingDpToEdit, setExistingDpToEdit] = useState<any>({});
  const [existingDpInfo, setExistingDpInfo] = useState<any>({});
  const [newDpName, setNewDpName] = useState(existingDpToEdit?.name);

  const ENTER_KEY_CODE = "Enter";

  let breadcrumbInfo = BC_ACCOUNT_MANAGEMENT;
  const MANAGE_OU = "manage-ou";
  const MANAGE_OU_USER = "manage-ou-user";
  const MANAGE_OU_RESOURCE_ADMIN = "manage-ou-resource-admin";
  const not_authorized_page_title = "Account Management";
  const not_authorized_page_message = "you are not authorized to view this page.";

  const manageOuService = SERVICE_FUNCTION_TO_FEATURE[MANAGE_OU]["service"];
  const manageOuFunction = SERVICE_FUNCTION_TO_FEATURE[MANAGE_OU]["function"];
  const manageOuUserService = SERVICE_FUNCTION_TO_FEATURE[MANAGE_OU_USER]["service"];
  const manageOuUserFunction = SERVICE_FUNCTION_TO_FEATURE[MANAGE_OU_USER]["function"];
  const manageOuResourceAdminService = SERVICE_FUNCTION_TO_FEATURE[MANAGE_OU_RESOURCE_ADMIN]["service"];
  const manageOuResourceAdminFunction = SERVICE_FUNCTION_TO_FEATURE[MANAGE_OU_RESOURCE_ADMIN]["function"];

  if (authReducer && (!authReducer.sideMenu || SIDE_MENU_ITEM_ACCOUNT_MANAGEMENT !== authReducer.sideMenu.menuItem)) {
    authReducer.sideMenu = { menuItem: SIDE_MENU_ITEM_ACCOUNT_MANAGEMENT };
  }
  

  useEffect(() => {
    const betaFeatureService = SERVICE_FUNCTION_TO_FEATURE["beta-feature"]["service"];
    const betaFeatureFunction = SERVICE_FUNCTION_TO_FEATURE["beta-feature"]["function"];
    const authorizedBetaUser = checkLoggedInUserAuthorizedToViewPage(betaFeatureService, betaFeatureFunction);
    setIsAuthorizedToViewBetaFeature(authorizedBetaUser)
    
    const ouAdminService = SERVICE_FUNCTION_TO_FEATURE["manage-ou"]["service"];
    const ouAdminFunction = SERVICE_FUNCTION_TO_FEATURE["manage-ou"]["function"];
    const authorizedAdminUser = checkLoggedInUserAuthorizedToViewPage(ouAdminService, ouAdminFunction);
    setIsAuthorizedAdminUser(authorizedAdminUser)

    let tab = match?.params?.tab ? match.params.tab : "accounts";
    setActiveTab(tab);
    processQueryUrl();
  }, [location]);

  useEffect(() => {
    setSelectedOu(authReducer?.selectedOu?.name);
    setSelectedOuId(authReducer?.selectedOu?.id);
  }, [authReducer.selectedOu]);

  useEffect(() => {
    const authorizedOu = checkLoggedInUserAuthorizedToViewPage(manageOuService, manageOuFunction);
    const authorizedOuUser = checkLoggedInUserAuthorizedToViewPage(manageOuUserService, manageOuUserFunction);
    const authorizedOuResourceAdmin = checkLoggedInUserAuthorizedToViewPage(manageOuResourceAdminService, manageOuResourceAdminFunction);
    setIsAuthorizedToViewPage(authorizedOu || authorizedOuUser || authorizedOuResourceAdmin);


    const info = {
      permission: {
        service: manageOuUserService,
        serviceFunction: manageOuUserFunction,
      },
      isMultiVessel: false,
      isSingleVessel: false,
      showOu: false,
      ou: authReducer?.selectedOu ? authReducer.selectedOu : ''
    };
    if (authorizedOu || authorizedOuUser) {
      info.showOu = true;
    }
    updatePermission(info);
    const params = getDecodeURI(location?.search);
    const { startDate, endDate } = updateTimePeriod(dispatch, location);
    params.startDate = startDate;
    params.endDate = endDate;
    history.push({ pathname: location.pathname, search: getEncodedURI(params) })
  }, []);

  useEffect(() => {
    setLoading(
      authReducer.loading ||
      authReducer.setIamLoginHistoryTable ||
      authReducer.setIamLoginHistoryCountLoading ||
      authReducer.setIamLoginHistoryTopWidget ||
      authReducer.setIamLoginHistoryFilterUser ||
      authReducer.setIamLoginHistoryFilterApp ||
      authReducer.setIamLoginHistoryFilterDescList ||
      authReducer.setIamLoginHistoryTimeseriesChart
    )
  }, [authReducer.loading,
  authReducer.setIamLoginHistoryTable,
  authReducer.setIamLoginHistoryCountLoading,
  authReducer.setIamLoginHistoryTopWidget,
  authReducer.setIamLoginHistoryFilterUser,
  authReducer.setIamLoginHistoryFilterApp,
  authReducer.setIamLoginHistoryFilterDescList,
  authReducer.setIamLoginHistoryTimeseriesChart
  ])

  const processQueryUrl = () => {
    let _q = getDecodeURI(location?.search);

    setPage(_q.page && '' !== _q.page.trim() ? parseInt(_q.page) : 1);
    setLimit(_q.limit && '' !== _q.limit.trim() ? parseInt(_q.limit) : DEFAULT_PAGE_SIZE);
    setSearch(_q.search && '' !== _q.search.trim() ? _q.search : "");
    setSelectedOu(_q.ouName && '' !== _q.ouName.trim() ? _q.ouName : (authReducer?.selectedOu?.name ? authReducer?.selectedOu?.name : ''));
    setSelectedOuId(_q.ouId && '' !== _q.ouId.trim() ? _q.ouId : (authReducer?.selectedOu?.id ? authReducer?.selectedOu?.id : ''));
    setSearchValue(_q.search && '' !== _q.search.trim() ? _q.search : "");
  }

  const doNavigate = (pathname, params = {}) => {
    history.push({ pathname: pathname, search: `?${getEncodedURI(params)}` });
  }

  const doNavigateToAccounts = () => {
    let q: any = getDecodeURI(location?.search);
    let params: any = {};
    if (q.hasOwnProperty("ouName") && q.hasOwnProperty("ouId") &&
      '' != q.ouName && '' != q.ouId) {
      params.ouName = q.ouName;
      params.ouId = q.ouId;
    }

    if (q.hasOwnProperty("k4Ids") && '' != q.k4Ids) {
      params.k4Ids = q.k4Ids;
    }
    doNavigate("/account-management/accounts", params);
  }

  const doNavigateToResources = () => {
    let q: any = getDecodeURI(location?.search);
    let params: any = {};
    if (q.hasOwnProperty("ouName") && q.hasOwnProperty("ouId") &&
      '' != q.ouName && '' != q.ouId) {
      params.ouName = q.ouName;
      params.ouId = q.ouId;
    }

    if (q.hasOwnProperty("k4Ids") && '' != q.k4Ids) {
      params.k4Ids = q.k4Ids;
    }
    doNavigate("/account-management/resources", params);
  }

  const handlePerPageChange = (e: any) => {
    let params: any = getDecodeURI(location.search);
    params.page = 1;
    params.limit = e.target.value;

    if (selectedButton === "accounts") {
      doNavigate("/account-management/accounts", params);
    } else if (selectedButton === "resources") {
      doNavigate("/account-management/resources", params);
    }
  }

  const handlePagination = (e, value) => {
    let params: any = getDecodeURI(location.search);
    params.page = value;
    if (selectedButton === "accounts") {
      doNavigate("/account-management/accounts", params);
    } else if (selectedButton === "resources") {
      doNavigate("/account-management/resources", params);
    }
  };

  const handleOpenCreateAccount = () => {
    accountManagement.current();
  }

  const handleOpenAddResource = () => {
    resourceManagement.current();
  }

  const updatePagination = (pageInfo) => {
    setPages(pageInfo.pages);
    setTotal(pageInfo.total);
    const page = (pageInfo.page - 1);
    const fromItem = (page * limit) + 1;
    const toItem = (page * limit) + pageInfo.size;
    if (selectedButton === "accounts") {
      setPageMessage(`${fromItem} to  ${toItem} out of ${pageInfo.total} Users`)
    } else if (selectedButton === "resources") {
      setPageMessage(`${fromItem} to  ${toItem} out of ${pageInfo.total} Resources`)
    }
  }

  const updateSearch = (search) => {
      let params: any = getDecodeURI(location.search);
      search ? params.search = search : delete params.search;
      if (selectedButton === "accounts") {
        doNavigate("/account-management/accounts", params);
      }
  }

  useEffect(() => {
    setLoading(authReducer.usersListLoading || authReducer.fetchResourcesLoading ||
      authReducer.updateUserLoading || authReducer.updateUserOrganizationLoading || authReducer.userDetachLoading || authReducer.userDeleteLoading || authReducer.enableUserAccountLoading ||
      authReducer.disableUserAccountLoading || authReducer.setPasswordUserLoading || authReducer.resendInviteLoading ||
      authReducer.inviteUserLoading || authReducer.setDpInfoLoading || authReducer.setDeleteOrgLoading|| authReducer.setModifyOrgLoading || authReducer.setSubDPCreateLoading);
  }, [authReducer.usersListLoading, authReducer.fetchResourcesLoading,
  authReducer.updateUserLoading, authReducer.updateUserOrganizationLoading, authReducer.userDetachLoading, authReducer.userDeleteLoading,
  authReducer.enableUserAccountLoading, authReducer.disableUserAccountLoading, authReducer.setPasswordUserLoading, authReducer.resendInviteLoading,
  authReducer.inviteUserLoading, authReducer.setDpInfoLoading, authReducer.setDeleteOrgLoading, authReducer.setModifyOrgLoading, authReducer.setSubDPCreateLoading]);

  const handelChangeCreateDP = (e) => {
    const value = e.target.value;
    if (!value.match(/[^A-Za-z0-9\s]/)) {
      setErrorInDpName("");
      setCreateBtnEnable(false);
    } else {
      setErrorInDpName("Special Characters are not allowed");
      setCreateBtnEnable(true);
    }
    setOuSearchValue(value);
  }

  const handleOpenEditOrgPopup = () => {
    setCreatePopup(true);
    setSelectedEditOrgBtn('create');
    setNewDpName('');
    setExistingDpToEdit({});
  }

  const handleCreateSubDP = () => {
    accountCreateSubDP(ouSearchValue, ouSearchValue, parentDP?.name);
    setCreateBtnEnable(true);
    setCreatePopup(false);
    setOuSearchValue('');
  }

  const handleClearInput = () => {
    setOuSearchValue('');
    setCreateBtnEnable(true);
  }

  const handleDisable2Fatoall = () => {
    let twofaPayload = {
      dpId: selectedOuId,
      usernames: [],
      enabled: false,
      mfaAskInMinutes: 0
    }
    enableDisable2Fa(twofaPayload)
    setEnable2FAPopUp(false);
  }

  const handleEnable2Fatoall = () => {
    let twofaPayload = {
      dpId: selectedOuId,
      usernames: [],
      enabled: true,
      mfaAskInMinutes: 0
    }
    enableDisable2Fa(twofaPayload)
    setEnable2FAPopUp(false);
  }

  const doNavigateTab = (pathname, tabName) => {
    const currentParams = getDecodeURI(location?.search);
    const params:any = {};
    if (currentParams.ouName) {
      params['ouName'] = currentParams.ouName;
    }
    if (currentParams.ouId) {
      params['ouId'] = currentParams.ouId;
    }
    if (currentParams.k4Ids) {
      params['k4Ids'] = currentParams.k4Ids;
    }
    if(currentParams.sideNav){
      params['sideNav'] = currentParams.sideNav
    }
    if(currentParams.interval) {
      params['interval'] = currentParams.interval
    }
    const { startDate, endDate } = updateTimePeriod(dispatch, location);
    params.startDate = startDate;
    params.endDate = endDate;
    history.push({ pathname, search: getEncodedURI(params) })
    setActiveTab(tabName)
    if(tabName === LOGIN_HISTORY) {
      setShowSummaryPeriod(true);
    } else {
      setShowSummaryPeriod(false);
    }
    
  }

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    
    if(newValue === 0 ){  doNavigateTab("/account-management/accounts", ACCOUNT_MANAGEMENT) }
    if(newValue === 1 ){  doNavigateTab("/account-management/account-activity", LOGIN_HISTORY) }
    if(newValue === 2 ){  doNavigateTab("/account-management/groupSettings", GROUP_SETTINGS) }
    
  };

  
  const searchBoxKeyDown = (event: any) => {
    if (event && event.key === ENTER_KEY_CODE) {
        doSearch(searchValue);
    }
  }

  const doSearch = (value: string) => {
      let params: any = getDecodeURI(location?.search);
      value ? params.search = value : delete params.search;
      delete params.page;

      updateSearch(value);
      doNavigate(location.url, params);
  }

  const helpAction = () =>{
    window.open(AccountManagement)
  }

  
  const handleOnSearchClick = () => {
    doSearch(searchValue);
  }

  const handleOnSearchClear = () => {
    setSearchValue('');
    doSearch('');
  }

  const handleOnSearchChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setSearchValue(e.target.value);
    if (searchTimeoutRef.current) {
      clearTimeout(searchTimeoutRef.current);
    }
    searchTimeoutRef.current = setTimeout(() => {
      searchTimeoutRef.current = undefined;
      doSearch(e.target.value);
    }, 1000)
  }

  const handleDownloadReport = () => {
    if (selectedOu && '' !== selectedOu) {
      const params: CsvParameters = {
        type: "GET_API",
        id:"account-mgmt-csv",
        endpoint: `/iam/organizationuser/user?ouName=${encodeURIComponent(selectedOu)}&page=${1}&limit=${total}&search=${encodeURIComponent(escapeRegExp(searchValue))}`,
        successHandler: SET_FETCH_USERS_LOADING
      }
      downloadAsCSV(params);
    }
  }

  const handleDeleteOrg = () => {
    const dpId = orgToDelete?.id;
    if(dpId) {
      accountDeleteOrganization(dpId);
    }
  }

  const handleCancelDeleteOrg = () => {
    setCreatePopup(false);
  }

  useEffect(() => {
    if (selectedEditOrgBtn == 'modify' && existingDpToEdit?.name) {
      setNewDpName(existingDpToEdit?.name)
    }
  }, [selectedEditOrgBtn, existingDpToEdit])

  useEffect(() => {
    if (!_.isEmpty(authReducer.getDpInfo) && authReducer.getDpInfo.hasOwnProperty('status') && authReducer.getDpInfo.status == 1) {
      const dpInfo = authReducer.getDpInfo?.data;
      setExistingDpInfo(dpInfo);
      const payload = {
        "DPName": dpInfo?.shortName,
        "DPFullNameNew": newDpName,
        "id": dpInfo?.k4DpId,
        "brandingAllowed": dpInfo?.brandingAllowed,
        "parentName": dpInfo?.parentName,
        "DPFullName": dpInfo?.fullName,
        "addressLineOne": dpInfo?.addrLine1,
        "addressLineTwo": dpInfo?.addrLine2,
        "city": dpInfo?.addrCity,
        "state": dpInfo?.addrState,
        "zipCode": dpInfo?.addrZipCode,
        "country": dpInfo?.addrCountry,
        "address": dpInfo?.address,
        "commissionAllowed": dpInfo?.commissionAllowed,
        "vsatP": dpInfo?.vsatBaseP,
        "lteP": dpInfo?.lteBaseP,
        "kaasP": dpInfo?.kaasBaseP,
        "nodePrice": dpInfo?.nodePrice,
        "vatId": dpInfo?.vatId,
        "parentId": dpInfo?.parentId
        }
      accountModifyOrganization(payload);
    }
    authReducer.getDpInfo = {};
  }, [authReducer.getDpInfo])

  const handlemodifyOrg = () => {
    getOrganisationInfo(existingDpToEdit?.name);
  }

  useEffect(() => {
    if (!_.isEmpty(authReducer.accountModifyOrg) && authReducer.accountModifyOrg.hasOwnProperty('status')) {
      setCreatePopup(false);
    }
    if (!_.isEmpty(authReducer.accountDeleteOrg) && authReducer.accountDeleteOrg.hasOwnProperty('status')) {
      setCreatePopup(false);
    }
  }, [authReducer.accountModifyOrg, authReducer.accountDeleteOrg])

  const tabs = [
    { tabName: 'Account Management', tabValue: ACCOUNT_MANAGEMENT, pathName: '/account-management/accounts' },
    { tabName: 'Account Activity', tabValue: LOGIN_HISTORY, pathName: '/account-management/account-activity' },
    { tabName: 'Organization Settings', tabValue: GROUP_SETTINGS, pathName: '/account-management/groupSettings' }
  ]

  const handleFileUpload = (file)=>{
    setLoading(true)
    uplodBulkUsers(file)
  }

  return isAuthorizedToViewPage ? (
    <div className="Form">
        <CommonFilters loading={loading || authReducer?.userPreferencesLoading} showSummaryPeriod={showSummaryPeriod} showWantType={false} helpIcon={false} helpAction={ helpAction} userGuide={[activeTab == ACCOUNT_MANAGEMENT ? true : false, 'account-management']} />
      <TabsComponent tabs={tabs} handleChangeTab={doNavigateTab} activeTab={activeTab} />
      <Grid style={{ width: "100%", background: "#FFFFFF", height:"calc(100vh - 160px)", overflowY: "auto"}}>
        {isAuthorizedToViewPage && activeTab == ACCOUNT_MANAGEMENT ? (
          <div>
            <Grid container style={{ paddingRight: "25px"}}>
              <Grid item xs={12} sm={6} md={6} lg={6} style={{ textAlign: "left", paddingLeft: '22px' }}>
                {selectedButton === "accounts" ? (
                  <Button className="buttonCssFleet account-create-button" title="Create a new account" onClick={handleOpenCreateAccount}>Invite User</Button>
                ) : <Button className={userOuName === selectedOu ? " buttonCssFleet account-create-button-disable" : "buttonCssFleet account-create-button"} title="Create a new account" disabled={userOuName === selectedOu ? true : false} onClick={handleOpenAddResource}>Add Resource</Button>}
                <Button className="buttonCssFleet account-create-button" onClick={handleOpenEditOrgPopup}>Edit Organization</Button>
              </Grid>
              
            </Grid>
            <Grid container className="align-item-center alignTopbarItemsCenter">
              <Grid item xs={4} sm={4} md={4} lg={4} className="searchbarBox ac-searchbar-padding-width">
                <TextField
                  id="search"
                  variant="outlined"
                  placeholder="Type something"
                  autoComplete="off"
                  size="small"
                  value={searchValue}
                  onChange={handleOnSearchChange}
                  onKeyDown={(event) => searchBoxKeyDown(event)}
                  InputLabelProps={{ className: "serachLabel" }}
                  InputProps={{
                    className: "searchbarInputs",
                    startAdornment: (
                      <InputAdornment position="start">
                        {!searchValue && <SearchIcon
                          className="searchbarIconsPosition" onClick={handleOnSearchClick}
                        />}
                        {searchValue && <CloseIcon
                          className="searchbarIconsPosition"
                        onClick={handleOnSearchClear}
                      />}
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              {total > 0 && <Grid item xs={4} sm={4} md={4} lg={4} className="sim-anal-csv marg-l-auto marg-r-20">
                <div className="download-summary-btn marg-left-auto" onClick={handleDownloadReport}><img className="downloadImg" src={Download} alt="" /><Button>Download</Button></div>
              </Grid>}
              {
                <Grid>
                  <CsvUploader onFileUpload={handleFileUpload}></CsvUploader>
                </Grid>
              }
            </Grid>
            <Box className="pagination-main accountManagementPagination">
                <Pagination
                  rowsPerPageOptions={NEW_PER_PAGE_ITEMS}
                  count={total ? total : 0}
                  rowsPerPage={limit}
                  page={page}
                  onPageChange={handlePagination}
                  onRowsPerPageChange={handlePerPageChange}
                />
              </Box>
            {selectedButton === "accounts" ? (
              <AccountManagmentTable updatePagination={updatePagination} accountManagement={accountManagement}
                page={page} limit={limit} search={search} selectedOu={selectedOu} selectedOuId={selectedOuId} updateSearch={updateSearch} searchValue={searchValue} setSearchValue={setSearchValue}/>
            ) : selectedButton === "resources" ? (
              <Fragment>
                <ResourceTable updatePagination={updatePagination} resourceManagement={resourceManagement}
                  page={page} limit={limit} selectedOu={selectedOu} userOuName={userOuName} />
              </Fragment>
            ) : null}
          </div>
        ) : (activeTab == LOGIN_HISTORY && <Grid className="Login-History-Page-iam">
          <LoginHistoryPage />
        </Grid>
        )}
        {
          isAuthorizedAdminUser && activeTab == GROUP_SETTINGS ?
          <Grid>
            <GroupSettings/>
          </Grid>
          :<></>
        }
      </Grid>
      {createPopUp ?
        <Dialog
          open={createPopUp}
          onClose={() => setCreatePopup(false)}
          aria-labelledby="form-dialog-title"
          fullWidth={true}
          maxWidth={'md'}
          className="assignDialog subdp-create-assignDialog"
        >
          <div>
            <DialogTitle className="dialog_title">
              <Grid className='mainAssignActiontitle'>
                <Grid className="AssignActiontitle">
                  <div className="edit-org-btns-div">
                    <button className={selectedEditOrgBtn == 'create' ? 'active-cteare-org-accounts' : 'inactive-create-org-accounts'} onClick={() => setSelectedEditOrgBtn('create')}>Create</button>
                    <button className={selectedEditOrgBtn == 'modify' ? 'active-cteare-org-accounts' : 'inactive-create-org-accounts'} onClick={() => setSelectedEditOrgBtn('modify')}>Modify</button>
                    <button className={selectedEditOrgBtn == 'delete' ? 'active-cteare-org-accounts' : 'inactive-create-org-accounts'} onClick={() => setSelectedEditOrgBtn('delete')}>Delete</button>
                  </div>
                </Grid>
                <Grid>
                  <img src={close} title="Close" onClick={() => setCreatePopup(false)}
                    className="dialog_closeIcon" />
                </Grid>
              </Grid>
            </DialogTitle>
            <DialogContent>
              {selectedEditOrgBtn == 'create' ? <div className="tkt_new_ticket_dialogContent">
                <Grid container spacing={2}>
                  <Grid item xs={6} sm={6} md={6} lg={6} className="childDP-create childDP-create-name">
                    <Grid className="inter-font">Organization Name</Grid>
                    <Grid className="SearchbarTextFieldBox SearchbarTextFieldBox-create" >
                      {
                        <TextField className="SearchbarTextField"
                          id="outlined-basic"
                          label=""
                          variant="outlined"
                          placeholder="Enter Organization name"
                          classes={{root: "input-box-accnt-mgmts"}}
                          size="small"
                          value={ouSearchValue}
                          onChange={handelChangeCreateDP}
                          InputLabelProps={{ className: "serachLabel" }}
                          InputProps={{
                            className: "serachBarWarehouse createDP-serachBarWarehouse"
                          }}
                          helperText={errorInDpName}
                          error={!!errorInDpName}
                        />}
                    </Grid>
                  </Grid>
                  <Grid item className="childDP-create childDP-create-name">
                    <Grid className="inter-font">Select Parent Organization</Grid>
                    <CreateChildDP setOu={setParentDP} />
                  </Grid>
                </Grid>
              </div> : selectedEditOrgBtn == 'modify' ? <div className="tkt_new_ticket_dialogContent">
                <Grid container spacing={2}>
                  <Grid item className="childDP-create childDP-create-name">
                    <Grid className="inter-font">Select Organization</Grid>
                    <CreateChildDP setOu={setExistingDpToEdit} />
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6} className="childDP-create childDP-create-name">
                    <Grid className="inter-font">New Organization Name</Grid>
                    <Grid className="SearchbarTextFieldBox SearchbarTextFieldBox-create" >
                      {
                        <TextField className="SearchbarTextField"
                          id="outlined-basic"
                          label=""
                          variant="outlined"
                          placeholder="Enter Organization name"
                          classes={{root: "input-box-accnt-mgmts"}}
                          size="small"
                          value={newDpName}
                          onChange={(e) => setNewDpName(e.target.value)}
                          InputLabelProps={{ className: "serachLabel" }}
                          InputProps={{
                            className: "serachBarWarehouse createDP-serachBarWarehouse"
                          }}
                          helperText={errorInDpName}
                          error={!!errorInDpName}
                        />}
                    </Grid>
                  </Grid>
                </Grid>
              </div> : <div className="tkt_new_ticket_dialogContent">
                <Grid container spacing={2}>
                  <Grid item className="childDP-create childDP-create-name">
                    <Grid className="inter-font">Select Organization</Grid>
                    <CreateChildDP setOu={setOrgToDelete} />
                  </Grid>
                </Grid>
              </div>}
              <Grid className="clear-create-subDp-div">
                <Button className='clearSubDP-button' onClick={selectedEditOrgBtn == 'create' ? handleClearInput  : handleCancelDeleteOrg}>{selectedEditOrgBtn == 'create' ? 'Clear' : 'Cancel'}</Button>
                <Button onClick={() => selectedEditOrgBtn == 'create' ? handleCreateSubDP() : selectedEditOrgBtn == 'modify' ? handlemodifyOrg() : handleDeleteOrg()} className='createSubDP-button' disabled={((ouSearchValue && !createBtnEnable && selectedEditOrgBtn == 'create') || (newDpName && selectedEditOrgBtn == 'modify') || selectedEditOrgBtn == 'delete') ? false : true}
                  style={{ background: (ouSearchValue && !createBtnEnable && selectedEditOrgBtn == 'create') || (newDpName && selectedEditOrgBtn == 'modify') || selectedEditOrgBtn == 'delete' ? '#264C86' : "#e8f0fd", color: (ouSearchValue && !createBtnEnable && selectedEditOrgBtn == 'create') || (newDpName && selectedEditOrgBtn == 'modify') || selectedEditOrgBtn == 'delete' ? '#fff' : "#264C86" }}
                >{selectedEditOrgBtn == 'create' ? 'CREATE' : selectedEditOrgBtn == 'modify' ? 'Modify' : 'DELETE'}</Button>
              </Grid>
            </DialogContent>
          </div>
        </Dialog>
        : null
      }



    </div>
  ):    <Grid item container className="notAuthorized">
  <NotAuthorized pageTitle={not_authorized_page_title} PageMessage={not_authorized_page_message} />
</Grid>;
};

const mapStateToProps = (state) => ({
  authReducer: state.authReducer,
  errorReducer: state.errorReducer,
});

export default withRouter(connect(mapStateToProps, { updatePermission, accountCreateSubDP, enableDisable2Fa, downloadAsCSV, accountDeleteOrganization, getOrganisationInfo, accountModifyOrganization, uplodBulkUsers })(AccountManagment));
