import { withRouter, Link } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { Box, Grid, InputAdornment, Paper, Table, TableBody, TableCell, IconButton, Menu, TableContainer, TableHead, TableRow, TextField, Tooltip, Button } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { Pagination } from "../../../components/Pagination";
import { getDecodeURI, getEncodedURI, convertDateTimeIntoTimezone } from "../../../utils/util";
import { getHubDataTableRowClientWise, getHubDataTableRowClientWiseCount, getHubDataClietTable, getHubDashboardClietTableData } from '../../../actions/Users/authenticateHub';
import { MMDDYYYYHMMSS_DATE_FORMAT_24_HRS } from "../../../utils/constants";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from '@mui/icons-material/Close';
import _ from "lodash";
import Download from '../../../asset/image/DownloadUsage.svg';
import CircleIcon from '@mui/icons-material/Circle';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import JsonTooltip, { JsonDataTooltip } from "../../../components/JSONTooltip/JsonTooltip";
import EdgeLinkIcon from "../../../asset/image/EdgeLinkIcon.svg"
import EdgeLinkIconDisabled from "../../../asset/image/linkDisabled.svg";
import ConfirmDialog from "../../../components/AcoordionPopUp";
import { clearData, getGeneratedLinks } from "../../../components/RemoteConnections/remoteConnectionSlice";
import { displayToastError } from "../../../server/request";
import ClientsData from '../ClientPopup';
import { INTERVALS, DEFAULT_PAGE_SIZE } from "../../../constants/Constants";
import Row from "../Row";
import { GET_HUB_DASHBOARD_CLIENT_TABLE, GET_HUB_DASHBOARD_CARRIER_CLIENT_TABLE } from "../../../actions/types";
import AgentDialog from "../../../components/HubAgentConfig/AgentDialog"
import ServiceHubDialog from "../../../components/HubAgentConfig/ServiceHubDialog/ServiceHubDialog"
import { getAgentWans, getAgentWanProfiles, getAgentHubs } from "../../../actions/Users/authenticateHub";
import { CREATE_AGENT_WAN } from "../../../actions/types";
import AgentTable from "./AgentTable";
import { CsvParameters, downloadAsCSV } from "../../../actions/Users/authenticateCsvDownload";
import "./AgentConfig.css"
import DownloadCSV, { clearCsvDownload } from "../../../components/DownloadCSV";

function convertToCSVFromJSON(data) {
    if (!Array.isArray(data) || data.length === 0) {
        return { headers: [], rows: [] };
    }

    const _columns = Object.keys(data[0]);
    const rows = data.map(obj => _columns.map(header => obj[header] ?? ""));
    return { _columns, rows };
}
const convertToCsv = (data: any[][], userTimezone: string) => {
    const { _columns, rows } = convertToCSVFromJSON(data)
    const csvRows: any[] = [];
    rows.forEach(row => {
        let services = row[9] || ""

        // Services
        if (row[9] && Array.isArray(row[9])) {
            services = row[9]?.join(",")
        }
        csvRows.push([row[7], row[2], row[3], row[1], convertDateTimeIntoTimezone(row[5], userTimezone, MMDDYYYYHMMSS_DATE_FORMAT_24_HRS), convertDateTimeIntoTimezone(row[4], userTimezone, MMDDYYYYHMMSS_DATE_FORMAT_24_HRS), services])
    })
    return [["Organization", 'Agent ID', 'Agent Name', 'Agent Config Version', 'Last Seen', 'Creation Date', 'Services'], ...csvRows]
}

const AgentControllerTable = (props) => {
    const { authReducer, selectedOu, startDate, endDate, getHubVesselListing, getHubDataTableRowClientWise, getHubDataTableRowClientWiseCount, hubDashboardClientWiseData, hubDashboardClientWiseLoading, hubDashboardClientWiseDataCount, location, history,
        getHubDataClietTable, getGeneratedLinks, setClientTableLoading, userTimezone, hubDataClientTable, getHubDashboardClietTableData, carrierHubDataClientTable,
        getAgentWans, getAgentWanProfiles, getAgentHubs, downloadAsCSV } = props;

    let _q = getDecodeURI(location?.search);

    const [count, setCount] = useState(0);
    const [selectedOrg, setSelectedOu] = useState<any>({});
    const [vessels, setVessels] = useState([]);
    const USAGE = 'clientUsage';
    const [interval, setInterval] = useState(_q.hasOwnProperty('interval') && _q.interval == '15mins' ? '15m' : _q.interval ? _q.interval : '1h');
    const ENTER_KEY_CODE = "Enter";
    const searchTimeoutRef = useRef<any>();
    const [wanProfilePopup, setWanProfilePopup] = useState(false);
    const [eachRow, setEachrow] = useState();
    const [clientData, setClientData] = useState([]);
    const [clientDataPopup, setClientDataPopup] = useState(false);
    const [selectedSite, setSelectedSite] = useState({});
    const [_interval, setIntervalValue] = useState(_q.hasOwnProperty('interval') ? { label: _q.interval == '15mins' ? '15m' : _q.interval, value: _q.interval } : INTERVALS[1]);
    const [filterPopup, setFilterPopup] = useState(false);
    const [isActionPopup, setIsActionPopup] = useState(false)
    const [isHubActionPopup, setIsHubActionPopup] = useState(false)
    const [isAgentActionPopup, setIsAgentActionPopup] = useState(false)
    const [existingAgentName, setExistingAgentName] = useState("")
    const [existingAgentConfigName, setExistingAgentConfigName] = useState("")
    const [currentAgentId, setCurrentAgentId] = useState("")
    const dispatch = useDispatch();

    const [sort, setSort] = useState<any>(_q.hasOwnProperty("sort") ? _q.sort : "created_at");
    const [sortOrder, setSortOrder] = useState<any>(_q.hasOwnProperty("sortOrder") ? _q.sortOrder : "desc");
    const [searchValue, setSearchValue] = useState<string>(_q.hasOwnProperty("search") && _q.search.trim() !== '' ? _q.search : "");
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState<number>(_q.hasOwnProperty("page") && '' != _q.page.trim() ? parseInt(_q.page) : 0);
    const [limit, setLimit] = useState<number>(_q.hasOwnProperty("limit") && '' != _q.limit.trim() ? parseInt(_q.limit) : DEFAULT_PAGE_SIZE);
    const [agentOrganizationId, setAgentOrganizationId] = useState("")
    const [agentOrganizationName, setAgentOrganizationName] = useState("")

    // Agent Config
    const [agentWans, setAgentWans] = useState([])
    const [agentWanProfiles, setAgentWanProfiles] = useState([])
    const [agentHubs, setAgentHubs] = useState([])
    const [isEditActionPopup, setIsEditActionPopup] = useState(false)
    const [anchorElRef, setAnchorElRef] = useState<HTMLElement>();

    useEffect(() => {
        if (!_.isEmpty(selectedOu) && selectedOu?.id) {
            setSelectedOu(selectedOu)
        }
    }, [selectedOu])

    useEffect(() => {
        if (authReducer.csvDataDownloded && authReducer.csvDataDownloded.length > 0) {
            if (authReducer.csvDownloadId === 'agents-dashboard') {
                DownloadCSV(convertToCsv(authReducer.csvDataDownloded, authReducer.userTimezone)
                    , {
                        formatters: {
                            0: 'ESC-COMMA',
                            1: 'ESC-COMMA',
                            2: 'ESC-COMMA',
                            3: 'ESC-COMMA',
                            4: 'ESC-COMMA',
                            5: 'ESC-COMMA',
                            6: 'ESC-COMMA',
                        }
                    }
                );
                return () => {
                    dispatch(clearCsvDownload())
                }
            }
        }
    }, [authReducer.csvDataDownloded])

    const rowsOption = [5, 10, 15, 25, 50, 100];

    const doNavigate = (params) => {
        history.push({ pathname: location.pathname, search: `?${getEncodedURI(params)}` });
    }

    const handleChangePage = (e, value) => {
        let params: any = getDecodeURI(location?.search);
        value == 1 ? delete params.pageStart : params.pageStart = value;
        setPage(value);
        doNavigate(params);
        getHubDataTableRowClientWise(selectedOrg?.id, startDate, endDate, value, limit, interval, vessels, sort, sortOrder, searchValue);
    };

    const handleChangeRowsPerPage = (e) => {
        const Limit = parseInt(e.target.value);
        const Page = 1;
        let params: any = getDecodeURI(location?.search);
        delete params.pageStart;
        Limit == 15 ? delete params.pageLimit : params.pageLimit = Limit;
        setPage(1);
        setLimit(Limit);
        doNavigate(params);
        getHubDataTableRowClientWise(selectedOrg?.id, startDate, endDate, Page, Limit, interval, vessels, sort, sortOrder, searchValue);
    };

    const doSearch = (value: string) => {
        let params: any = getDecodeURI(location?.search);
        value ? params.search = value : delete params.search;
        delete params.page;
        setPage(1);
        doNavigate(params);
        getHubDataTableRowClientWise(selectedOrg?.id, startDate, endDate, 1, limit, interval, vessels, sort, sortOrder, value);
        getHubDataTableRowClientWiseCount(selectedOrg.id, startDate, endDate, 1, limit, interval, vessels, sort, sortOrder, value);
    };

    const searchBoxKeyDown = (event: any) => {
        if (event && event.key === ENTER_KEY_CODE) {
            doSearch(searchValue);
        }
    }

    const handleOnSearchClick = () => {
        doSearch(searchValue);
    }

    const handleOnSearchClear = () => {
        setSearchValue('');
        doSearch('');
    }

    const handleOnSearchChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setSearchValue(e.target.value);
        if (searchTimeoutRef.current) {
            clearTimeout(searchTimeoutRef.current);
        }
        searchTimeoutRef.current = setTimeout(() => {
            searchTimeoutRef.current = undefined;
            doSearch(e.target.value);
        }, 1000)
    }

    const handleDownloadReport = () => {
        let url = "/mpkac/v1/api/dp/DP-0001/agent"
        let query = `?page=0&pageSize=${total}&`
        if (searchValue) {query += `search=${searchValue}&`}
        if (sort) {query += `sortBy=${sort}&`}
        if (sortOrder) {query += `sortOrder=${sortOrder}&`}

        if (query.endsWith("&")) {
            query = query.slice(0, -1);
        }

        url = url + query
        const params: CsvParameters = {
            type: 'GET_API_AGENT_CONTROLLER',
            id: 'agents-dashboard',
            endpoint: url,
        }
        downloadAsCSV(params);
    }

    return (
        <div>
            <div style={{ marginTop: "1%", paddingLeft: "5px", paddingRight: "5px" }}>
                <Grid item xs={12} sm={12} md={12} lg={12} className="alignTopbarItemsCenter hub-table-search-switch"  >
                    <Grid container>
                        <Grid item lg={2}>
                            <Box className="searchbarBox">
                                {<TextField
                                    id="outlined-basic"
                                    variant="outlined"
                                    placeholder="Type something"
                                    autoComplete="off"
                                    size="small"
                                    value={searchValue}
                                    onChange={handleOnSearchChange}
                                    onKeyDown={(event) => searchBoxKeyDown(event)}
                                    InputLabelProps={{ className: "serachLabel" }}
                                    InputProps={{
                                        className: "searchbarInputs",
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                {!searchValue && <SearchIcon
                                                    className="searchbarIconsPosition"
                                                    onClick={handleOnSearchClick}
                                                />}
                                                {searchValue && <CloseIcon
                                                    className="searchbarIconsPosition"
                                                    onClick={handleOnSearchClear}
                                                />}
                                            </InputAdornment>
                                        ),
                                    }}
                                />}
                            </Box>
                        </Grid>
                        <Grid item lg={1}>
                        </Grid>
                        <Grid item lg={1} />
                        <Grid item lg={8} style={{ display: "flex", justifyContent: "flex-end", gap: "0.5em" }}>
                            <div>
                                <Button variant="outlined" onClick={() => { setIsEditActionPopup(false); setIsHubActionPopup(true) }}>Service/Hub Config</Button>
                            </div>
                            <div>
                                <Button variant="outlined" onClick={() => { setIsEditActionPopup(false); setIsActionPopup(true) }}>Create Agent</Button>
                            </div>
                            <div className="download-summary-btn" onClick={() => handleDownloadReport()}><img className="downloadImg" src={Download} alt="" /><Button>Download</Button></div>
                        </Grid>
                    </Grid>
                </Grid>
                <AgentTable setAgentOrganizationId={setAgentOrganizationId} setAgentOrganizationName={setAgentOrganizationName} setIsActionPopup={setIsActionPopup} setIsEditActionPopup={setIsEditActionPopup} setIsAgentActionPopup={setIsAgentActionPopup} existingAgentName={existingAgentName} setExistingAgentName={setExistingAgentName} setExistingAgentConfigName={setExistingAgentConfigName} setCurrentAgentId={setCurrentAgentId} sort={sort} setSort={setSort} sortOrder={sortOrder} setSortOrder={setSortOrder} searchValue={searchValue} setSearchValue={setSearchValue} total={total} setTotal={setTotal} page={page} setPage={setPage} limit={limit} setLimit={setLimit} />
            </div>

            {isHubActionPopup ? <ServiceHubDialog open={isHubActionPopup} setIsHubActionPopup={setIsHubActionPopup} isEditActionPopup={isEditActionPopup} setIsEditActionPopup={setIsEditActionPopup} setExistingAgentConfigName={setExistingAgentConfigName} existingAgentConfigName={existingAgentConfigName} /> : null}
            {isActionPopup ? <AgentDialog open={isActionPopup} setIsActionPopup={setIsActionPopup} isEditActionPopup={isEditActionPopup} setIsEditActionPopup={setIsEditActionPopup} setExistingAgentConfigName={setExistingAgentConfigName} existingAgentConfigName={existingAgentConfigName} currentAgentId={currentAgentId} agentOrganizationId={agentOrganizationId} agentOrganizationName={agentOrganizationName} setAgentOrganizationId={setAgentOrganizationId} setAgentOrganizationName={setAgentOrganizationName}/> : null}
        </div>
    )
}

const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
    selectedOu: state.authReducer?.selectedOu,
    hubDashboardClientWiseData: state.authReducer.hubDashboardClientWiseData,
    hubDashboardClientWiseLoading: state.authReducer.hubDashboardClientWiseLoading,
    hubDashboardClientWiseDataCount: state.authReducer.hubDashboardClientWiseDataCount,
    selectedOrg: state.authReducer.selectedOrg,
    startDate: state.authReducer.newSummaryStartDate,
    endDate: state.authReducer.newSummaryEndDate,
    getHubVesselListing: state.authReducer.getHubVesselListing,
    setClientTableLoading: state.authReducer.setHubDataClientTableLoading,
    userTimezone: state.authReducer.userTimezone,
    hubDataClientTable: state.authReducer.hubDataClientTable,
    carrierHubDataClientTable: state.authReducer.carrierHubDataClientTable,
});

export default withRouter(
    connect(mapStateToProps, { getHubDataTableRowClientWise, getHubDataTableRowClientWiseCount, getHubDataClietTable, getGeneratedLinks, getHubDashboardClietTableData, getAgentWans, getAgentWanProfiles, getAgentHubs, downloadAsCSV })(AgentControllerTable)
);