import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Autocomplete, Button, DialogActions, Divider, Grid, TextField, DialogContent, Switch, FormControl, Chip, Checkbox, InputAdornment, CircularProgress } from "@mui/material";
import { useEffect, useState, useMemo } from "react";
import _ from "lodash";
import { CONFIRM_DIALOG_TITLE, SERVICE_FUNCTION_TO_FEATURE } from "../../../config";
import ConfirmDialog from "../../ConfirmDialog";
import { backupConfigNow, applyGoldenConfig, backupConfigData, getLicenseTypes, upgradeDevicetoLatestEOSversion, rebootDevice, upgradeInventoryLicense } from "../../../actions/Users/authenticateInventory";
import SelectTag from "../../InventoryWarehouseTable/SelectTag";
import { toast } from 'react-toastify';
import { checkLoggedInUserAuthorizedToViewPage, convertDateTimeIntoTimezone } from "../../../utils/util";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import SelectConfigDropdown from "../SelectConfigDropdown";
import InfoIcon from '@mui/icons-material/Info';
import { MMDDYYYYHMMSS_DATE_FORMAT_24_HRS } from "../../../utils/constants";
import { compare } from "compare-versions";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ServiceHubs from "./ServiceHubs"
import { createAgentService, updateAgentService, getAgentHubs, getAgentServices, getAgentHubsPerService, getAgentWanProfiles, getAgentWans } from "../../../actions/Users/authenticateHub";
import { CREATE_AGENT_SERVICE, UPDATE_AGENT_SERVICE } from "../../../actions/types";
import { getDecodeURI } from "../../../utils/util";
import { DEFAULT_PAGE_SIZE } from "../../../constants/Constants";
import { useLocation } from "react-router-dom";
import { useDispatch } from 'react-redux';
import Loader from "react-loader";

const DEFAULT_PAGE = 1;

export const VlanPopup = (props) => {
    const dispatch = useDispatch();
    const { authReducer, createAgentService, getAgentHubs, getAgentServices, selectedDeviceInfo, isValidTier, upgradeDevicetoLatestEOSversion, backupConfigNow, onClose, applyGoldenConfig, rebootDevice, backupConfigData, getLicenseTypes, upgradeInventoryLicense, selectedDeviceLatestEosVersion, handleValidateTier, setIsActionPopup, getAgentHubsPerService, getAgentWanProfiles, getAgentWans, updateAgentService, selectedDp } = props;

    const location = useLocation();
    const _q = getDecodeURI(location?.search);
    const [limit, setLimit] = useState<number>(_q.hasOwnProperty("limit") && '' != _q.limit.trim() ? parseInt(_q.limit) : DEFAULT_PAGE_SIZE);
    const [page, setPage] = useState<number>(_q.hasOwnProperty("page") && '' != _q.page.trim() ? parseInt(_q.page) : DEFAULT_PAGE);


    const [totalHubsList, setTotalHubsList] = useState<any>([])
    const [hubs, setHubs] = useState<any>([])
    const [hubItems, setHubItems] = useState<any>([])

    const [services, setServices] = useState<any>([])
    const [selectedService, setSelectedService] = useState<any>("")

    const [wanProfiles, setWanProfiles] = useState<any>([])
    const [currentOrg, setCurrentOrg] = useState<any>("")

    const [isServiceConfigured, setIsServiceConfigured] = useState<boolean>(false)

    const [confirmDialog, setConfirmDialog] = useState({
        isOpen: false,
        title: "",
        subTitle: "",
        onConfirm: {},
    });

    const paginatedHubs = useMemo(() => {
        let pageNumber = page || 1
        let pageLimit = limit || DEFAULT_PAGE_SIZE
        const startIndex = (pageNumber - 1) * pageLimit;
        return hubs.slice(startIndex, startIndex + pageLimit);
    }, [hubs, page, limit]);

    useEffect(() => {
        if (selectedDp && selectedDp?.id) {
            setCurrentOrg(selectedDp?.id)
            getAgentServices(selectedDp?.id)
            getAgentWanProfiles(selectedDp?.id)
        }
    }, [selectedDp])

    useEffect(() => {
        if (!_.isEmpty(authReducer?.getAgentWanProfiles) && !authReducer?.getAgentWanProfilesLoading) {
            setWanProfiles(authReducer?.getAgentWanProfiles?.data || [])
        }
    }, [authReducer?.getAgentWanProfiles])

    useEffect(() => {
        if (!_.isEmpty(authReducer?.getAgentServices) && !authReducer?.getAgentServicesLoading) {
            let combined: string[] = [];
            let merged = (authReducer?.getAgentServices?.configured_services || []).concat(authReducer?.getAgentServices?.available_services || []);

            for (let num of merged) {
                if (!combined.includes(num)) {
                    combined.push(num);
                }
            }
            setServices(combined)
            setHubs([])
            setSelectedService("")
        }
    }, [authReducer?.getAgentServices])

    useEffect(() => {
        if (!_.isEmpty(authReducer?.getAgentHubsPerService) && !authReducer?.getAgentHubsPerServiceLoading) {
            let hubList: any = []
            let isConfigured = false
            if (authReducer?.getAgentHubsPerService?.data?.configuredHubs?.length) {
                isConfigured = true
                hubList.push(...authReducer?.getAgentHubsPerService?.data?.configuredHubs)
            }
            if (authReducer?.getAgentHubsPerService?.data?.availableHubs?.length) {
                hubList.push(...authReducer?.getAgentHubsPerService?.data?.availableHubs)
            }
            const updatedHubList = handleAccumulateHubInterfaces(hubList)

            setHubs(updatedHubList)
            setIsServiceConfigured(isConfigured)
        }
    }, [authReducer?.getAgentHubsPerService])

    useEffect(() => {
        if (!_.isEmpty(authReducer?.createAgentService) && !authReducer?.createAgentServiceLoading) {
            toast.success("Configured successfully", { position: toast.POSITION.BOTTOM_LEFT })
            dispatch({ type: CREATE_AGENT_SERVICE, payload: {} })
            getAgentServices(selectedDp?.id)

            handleClear()
        };
    }, [authReducer?.createAgentServiceLoading, authReducer?.createAgentService])

    useEffect(() => {
        if (!_.isEmpty(authReducer?.updateAgentService) && !authReducer?.updateAgentServiceLoading) {
            toast.success("Configured successfully", { position: toast.POSITION.BOTTOM_LEFT })
            dispatch({ type: UPDATE_AGENT_SERVICE, payload: {} })
            getAgentServices(selectedDp?.id)

            handleClear()
        };
    }, [authReducer?.updateAgentServiceLoading, authReducer?.updateAgentService])

    useEffect(() => {
        if (selectedService && selectedDp?.id) {
            getAgentHubsPerService(selectedDp?.id, selectedService)
        }
    }, [selectedService])

    const handleSave = () => {
        let payloadResponse = handleServiceHubsPayload(hubs)
        if (payloadResponse?.err) {
            toast.error(payloadResponse?.err, { position: toast.POSITION.BOTTOM_LEFT })
            return
        }
        if (!payloadResponse?.result?.length) {
            toast.error("No hubs are configured", { position: toast.POSITION.BOTTOM_LEFT })
            return
        }
        let payload = {
            services: [
                {
                    service_name: selectedService,
                    hubs: payloadResponse?.result
                }
            ]
        }
        if (isServiceConfigured) {
            updateAgentService(selectedDp?.id, selectedService, payload)
        } else {
            createAgentService(selectedDp?.id, payload)
        }
    }

    const handleClose = () => {
        setIsActionPopup(false)
    }

    const handleAccumulateHubInterfaces = (data: any[]) => {
        let result: any[] = []
        for (let item of data) {
            let groupedWans = {};

            if (!item?.udpv1_wans?.length) {
                result.push({
                    hub_name: item.hub_name,
                    udpv1_wans: [],
                    wan_balancer_profile: item.wan_balancer_profile
                })
                continue
            }

            for (let wan of item?.udpv1_wans) {
                let interfaceAddrKey = wan?.interface_addr;
                if (!groupedWans[interfaceAddrKey]) {
                    groupedWans[interfaceAddrKey] = {
                        interface_addr: wan?.interface_addr,
                        remote_addr: wan?.remote_addr || "",
                        wan_names: [],
                        wan_type: wan?.wan?.type || ""
                    };
                }
                if (wan?.wan?.name) {
                    groupedWans[interfaceAddrKey]?.wan_names?.push(wan?.wan?.name);
                }
            }

            result.push({
                hub_name: item.hub_name,
                udpv1_wans: Object.values(groupedWans),
                wan_balancer_profile: item.wan_balancer_profile,
                is_wan_balancer_updated: false
            })
        }

        return result
    }

    const handleServiceHubsPayload = (data) => {
        let result: any = []
        for (let item of data) {
            const expandedWans: any = [];
            if (!item?.wan_balancer_profile || !item?.udpv1_wans?.length) continue

            for (let wan of item?.udpv1_wans) {
                if (wan?.wan_names?.length && !wan?.remote_addr) {
                    return {result: [], err: "Hub Reachable Address is required"}
                }
                if (wan?.wan_names?.length && !wan?.wan_type) {
                    return {result: [], err: "Wan type is required"}
                }
                if (wan?.wan_names?.length > 0) {
                    wan?.wan_names.forEach(name => {
                        expandedWans.push({
                            interface_addr: wan?.interface_addr,
                            remote_addr: wan?.remote_addr,
                            wan: {
                                name: name,
                                type: wan?.wan_type
                            }
                        });
                    });
                }
            };

            if (!expandedWans?.length) continue

            result.push({
                hub_name: item?.hub_name,
                udpv1_wans: expandedWans,
                wan_balancer_profile: item?.wan_balancer_profile
            });
        };
        return {result, err: null}
    }


    const handleClear = () => {
        setSelectedService("")
        setHubs([])
    }

    return (
        <Grid style={{ padding: "0px" }}>
            <DialogContent style={{ marginTop: "2%" }}>
                <Grid container>
                    <Grid container className="config-apply-config-target">
                        <Grid xs={2} sm={2} md={2} lg={2} style={{ color: "#45464E", display: "flex", alignItems: "center" }}>Service Name</Grid>
                        <Grid xs={4} sm={4} md={4} lg={3}>
                            <Autocomplete
                                id="select-users"
                                size="small"
                                // multiple
                                value={selectedService}
                                onChange={(event, newValue) => { setSelectedService(newValue); }}
                                options={services}
                                getOptionLabel={(option) => option}
                                renderInput={(params) =>
                                    <TextField
                                        {...params}
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment: (
                                                <>
                                                    {authReducer?.getAgentServicesLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                                    {params.InputProps.endAdornment}
                                                </>
                                            )
                                        }}
                                    />
                                }
                                renderOption={(props, option) => <li {...props}>{option}</li>}
                                renderTags={(value, getTagProps) =>
                                    value.map((option, index) => (
                                        <Chip size="small" label={option} {...getTagProps({ index })} />
                                    ))
                                }
                                sx={{ width: "100%", maxWidth: "100%" }}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                {selectedService && !authReducer?.getAgentHubsPerServiceLoading && hubs?.length ? (
                    <div style={{ marginTop: "30px", }}>
                        <ServiceHubs selectedService={selectedService} hubs={hubs} setHubs={setHubs} wanProfiles={wanProfiles} currentOrg={currentOrg} limit={limit} setLimit={setLimit} page={page} setPage={setPage} paginatedHubs={paginatedHubs} />
                    </div>
                ) : authReducer?.getAgentHubsPerServiceLoading ? <Loader radius={4} length={5} lines={10} width={2} color={"#264C86"} /> : null}
            </DialogContent>
            {selectedService ? (
                <DialogActions>
                    <div className="displayFlex threatKontrolMargin" style={{ marginTop: authReducer?.getAgentHubsPerServiceLoading ? "200px" : "0px" }}>
                        <Button className="confirm-dialogue-cancel" style={{ color: "#264c86" }} onClick={() => { handleClose() }}> Cancel </Button>
                        <Button variant="contained" style={{ background: "#264c86" }} onClick={() => { handleSave() }}>Save</Button>
                    </div>
                    <ConfirmDialog confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog} />
                </DialogActions>
            ) : null}
        </Grid>
    )
}


const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
    errorReducer: state.errorReducer,
});

export default withRouter(
    connect(mapStateToProps, {
        createAgentService,
        getAgentServices,
        getAgentHubs,
        getAgentHubsPerService,
        getAgentWanProfiles,
        getAgentWans,
        updateAgentService
    })(VlanPopup)
);