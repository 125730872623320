import { withRouter } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { updateHubFilteredVessels, updatePermission } from "../../actions/Users/authenticate";
import { useEffect, useLayoutEffect, useState } from "react";
import { HUB_RESOURCE_FIELD_VESSELNAME, SIDE_MENU_ITEM_HUB } from "../../utils/constants";
import CommonFilters from "../../components/BreadCrumbs/CommonFilters";
import { SERVICE_FUNCTION_TO_FEATURE } from "../../config";
import HubDetailsTable from "./HubDetailsTable";
import './hubDetails.css';
import { checkLoggedInUserAuthorizedToViewPage, getDecodeURI, getEncodedURI, strToK4HubList, strToK4IdList, updateTimePeriod } from "../../utils/util";
import { getHubDataSitesList } from "../../actions/Users/authenticateHub";
import { CONST_ALL_VESSELS } from "../../constants/Constants";
import _ from "lodash";
import AgentTable from "./AgentTable";
import AgentConfigTable from "./AgentConfig";
import TabsComponent from "../../components/NewTabs";
import HubEvent from "./HubEvent/HubEvent";

const HubDetails = (props) => {
    const { authReducer, setHubDataLoading, setHubDataCountLoading, setHubDataClientTableLoading, updatePermission, getHubDataSitesList, location,  history, updateHubFilteredVessels, setHubDashboardClientWiseLoading, setHhubDashboardClientWiseCountLoading, setHubDashboardCarrierClientTableLoading, setHubDashboardCarrierServiceTableLoading, setTopologyHubTableLoading } = props;
    let _q: any = getDecodeURI(location.search);
    const [loading, setLoading] = useState('');

    const [hubVesselNames, setHubVesselNames] = useState<any[]>([]);
    const [hubSelectedVessels, setHubSelectedVessels] = useState<any[]>([]);
    const [hubSelectedVesselNames, setHubSelectedVesselNames] = useState<string[]>([]);
    const [hubSelectedVesselsDisplayName, setHubSelectedVesselsDisplayName] = useState<any>(CONST_ALL_VESSELS);
    const [isAllSelectedHub, setIsAllSelectedHub] = useState(false);
    const [siteSearchValue, setSiteSearchValue] = useState("");
    const [hubFilterK4Ids, setHubFilterK4Ids] = useState<any>([]);
    const [hubVesselList, setHubVesselList] = useState([]);
    const [activeTab, setActiveTab] = useState(_q.hasOwnProperty('tab') ? _q.tab : 'hub');
    const dispatch = useDispatch();

    const VIEW_VESSEL_SUMMARY = "view-vessel-summary";

    const viewVesselSummaryService = SERVICE_FUNCTION_TO_FEATURE[VIEW_VESSEL_SUMMARY]["service"];
    const viewVesselSummaryFunction = SERVICE_FUNCTION_TO_FEATURE[VIEW_VESSEL_SUMMARY]["function"];

    if (authReducer && (!authReducer.sideMenu || SIDE_MENU_ITEM_HUB !== authReducer.sideMenu.menuItem)) {
        authReducer.sideMenu = { menuItem: SIDE_MENU_ITEM_HUB };
    }

    useLayoutEffect(() => {
        let authorized = checkLoggedInUserAuthorizedToViewPage(viewVesselSummaryService, viewVesselSummaryFunction);
        if (authorized && !authReducer.permission) {
            const info = {
                permission: {
                    service: viewVesselSummaryService,
                    serviceFunction: viewVesselSummaryFunction,
                },
                isMultiVessel: false,
                isSingleVessel: false,
                showOu: true,
                ou: authReducer.selectedOu ? authReducer.selectedOu : '',
                hubSites: activeTab == 'hub' ? true : false
            };
            updatePermission(info);
        }
    }, [activeTab])

    useEffect(() => {
        setLoading(setHubDataLoading || setHubDataCountLoading || setHubDataClientTableLoading || setHubDashboardClientWiseLoading || setHhubDashboardClientWiseCountLoading ||
            setHubDashboardCarrierClientTableLoading || setHubDashboardCarrierServiceTableLoading || setTopologyHubTableLoading || authReducer?.getAgentHubEventsLoading || authReducer?.getAgentHubEventFiltersLoading || authReducer?.getAgentsLoading)
    }, [setHubDataLoading , setHubDataCountLoading, setHubDataClientTableLoading, setHubDashboardClientWiseLoading, setHhubDashboardClientWiseCountLoading,
        setHubDashboardCarrierClientTableLoading, setHubDashboardCarrierServiceTableLoading, setTopologyHubTableLoading, authReducer?.getAgentHubEventsLoading, authReducer?.getAgentHubEventFiltersLoading, authReducer?.getAgentsLoading])

    useEffect(() => {
        if (_q.hasOwnProperty("hubK4Ids") && '' != _q.hubK4Ids.trim()) {
            let hubVessels = strToK4IdList(_q.hubK4Ids);
            if (hubVessels && hubVessels.length > 0) {
                hubVessels = hubVessels.filter(item => item !== '');
            }
            let hubVesselObjs = strToK4HubList(_q.hubK4Ids);
            if (hubVessels.length != hubFilterK4Ids.length || hubVessels.join() !== hubFilterK4Ids.join())
                updateHubFilteredVessels(hubVesselObjs);
            setHubFilterK4Ids(hubVessels);
        } else {
            if (hubFilterK4Ids.length > 0) {
                setHubFilterK4Ids([]);
                updateHubFilteredVessels([]);
            }
        }
        if (!_q.hasOwnProperty('hubK4Ids')) {
            updateHubFilteredVessels([]);
        }
    }, [location.search])

    useEffect(() => {
        let selVessels: any[] = hubVesselNames;
        let isAll = isAllSelectedHub;
        if (hubVesselNames.length > 0) {
            if (hubFilterK4Ids && hubFilterK4Ids.length > 0) {
                setIsAllSelectedHub(false);
                isAll = false;
                selVessels = hubVesselNames.filter(item => hubFilterK4Ids.indexOf(item.k4Id) >= 0);
                let selVesselNames: any[] = selVessels.map(item => item[HUB_RESOURCE_FIELD_VESSELNAME] ? item[HUB_RESOURCE_FIELD_VESSELNAME] : "");
                setHubSelectedVessels(selVessels);
                setHubSelectedVesselNames(selVesselNames);
                updateHubDisplayName(selVesselNames);
            } else {
                isAll = true;
                setHubSelectedVesselsDisplayName(CONST_ALL_VESSELS);
                checkAllCheckBoxHub(hubVesselNames);
            }
        } else {
            isAll = true;
            setHubSelectedVesselsDisplayName("");
        }
    }, [hubVesselNames, hubFilterK4Ids]);

    const updateHubDisplayName = (selVesselNames) => {
        if (0 === selVesselNames.length) {
            setHubSelectedVesselsDisplayName("");
        } else if (1 === selVesselNames.length) {
            setHubSelectedVesselsDisplayName(selVesselNames[0])
        } else {
            setHubSelectedVesselsDisplayName(`${selVesselNames[0]} +${selVesselNames.length - 1}`);
        }
    }

    const checkAllCheckBoxHub = (_vessels) => {
        setIsAllSelectedHub(true);
        setHubSelectedVesselNames(_vessels.map(item => item[HUB_RESOURCE_FIELD_VESSELNAME] ? item[HUB_RESOURCE_FIELD_VESSELNAME] : ""));
        setHubSelectedVessels(_vessels.filter(item => item[HUB_RESOURCE_FIELD_VESSELNAME] && item[HUB_RESOURCE_FIELD_VESSELNAME] !== CONST_ALL_VESSELS));
    }

    const handleChangeVesselHub = (e, vessel) => {
        let name = vessel[HUB_RESOURCE_FIELD_VESSELNAME];
        let isChecked = !hubSelectedVesselNames.includes(name);
        let selectedVessel: any = [];
        let selectedVesselName: any = [];
        let isAllSelect = isAllSelectedHub
        if (isChecked) {
            if (name == CONST_ALL_VESSELS) {
                checkAllCheckBoxHub(hubVesselNames);
            } else {
                if (isAllSelectedHub) {
                    selectedVesselName = [name]
                    selectedVessel = [vessel]
                    isAllSelect = false
                    setIsAllSelectedHub(isAllSelect);
                    setHubSelectedVesselNames(selectedVesselName);
                    setHubSelectedVessels(selectedVessel);
                } else {
                    selectedVesselName = [...hubSelectedVesselNames, name]
                    selectedVessel = [...hubSelectedVessels, vessel]
                    setHubSelectedVesselNames(selectedVesselName);
                    setHubSelectedVessels(selectedVessel);
                }
            }
        } else {
            if (name === CONST_ALL_VESSELS) {
                selectedVesselName = []
                selectedVessel = []
                setHubSelectedVesselNames(selectedVesselName);
                setHubSelectedVessels(selectedVessel);
            } else {
                if (isAllSelectedHub) {
                    isAllSelect = false
                    setIsAllSelectedHub(isAllSelect);
                    selectedVesselName = [name]
                    selectedVessel = [vessel]
                    setHubSelectedVesselNames(selectedVesselName);
                    setHubSelectedVessels(selectedVessel);
                } else {
                    selectedVesselName = hubSelectedVesselNames.filter(item => item !== name)
                    selectedVessel = hubSelectedVessels.filter(item => item[HUB_RESOURCE_FIELD_VESSELNAME] !== name)
                    setHubSelectedVesselNames(selectedVesselName);
                    setHubSelectedVessels(selectedVessel);
                }
            }
        }

        let params = getDecodeURI(location?.search);
        if (isAllSelect) {
            setHubSelectedVesselsDisplayName(CONST_ALL_VESSELS);
        } else {
            updateHubDisplayName(selectedVesselName.filter(item => item !== name));
        }

        let filterK4Ids: any[] = [];
        if (!isAllSelect) {
            filterK4Ids = selectedVessel.map(item => `${item.k4Id}:${item['site_name']}`);
        }
        let navigate = true;
        if (filterK4Ids.length > 0) {
            params.hubK4Ids = filterK4Ids.toString();
        } else if (params.hasOwnProperty("hubK4Ids")) {
            delete params.hubK4Ids;
        } else {
            navigate = false;
        }
        delete params.page;

        if (navigate) {
            history.push({ pathname: location.url, search: `?${getEncodedURI(params)}` });
        }

    }

    useEffect(() => {
        if (!_.isEmpty(authReducer.getHubVesselListing) && !_.isEmpty([authReducer?.selectedOu])) {
            let _vessels = [];
            let anotherVessels: any = authReducer.getHubVesselListing.hasOwnProperty('data') && authReducer.getHubVesselListing.data;
            if (anotherVessels) {
                anotherVessels = anotherVessels.map((item) => ({
                    k4Id: item.k4Id,
                    'site_name': item.site_name,
                }))
                _vessels = anotherVessels;
            }
            _vessels = _.sortBy(_vessels, ['site_name']);
            const ous = !_.isEmpty([authReducer?.selectedOu]) && [authReducer?.selectedOu].length > 0 ? [authReducer?.selectedOu].map(org => {
                return org?._childern?.length > 0 ? org._childern.map(dp => {
                    return {
                        'site_name': dp.name,
                    }
                }) : []
            }).flat() : [];
            _vessels = _.differenceBy(_vessels, ous, 'site_name')
            setHubVesselList(_vessels);
        }
    }, [authReducer.getHubVesselListing, authReducer.selectedOu])

    useEffect(() => {
        setHubVesselNames([{ "site_name": CONST_ALL_VESSELS, k4Id: '' }, ...hubVesselList]);
    }, [hubVesselList])

    useEffect(() => {
        if (siteSearchValue !== "" && !_.isEmpty(hubVesselList)) {
            let _vesselList = hubVesselList.filter((vessel: any) => !_.isEmpty(vessel["site_name"]) && vessel["site_name"].toLowerCase().includes(siteSearchValue.toLowerCase()));
            setHubVesselNames(!_.isEmpty(_vesselList) ? [{ "site_name": CONST_ALL_VESSELS, k4Id: '' }, ..._vesselList] : []);
        } else if (siteSearchValue === "" && !_.isEmpty(hubVesselList)) {
            setHubVesselNames([{ "site_name": CONST_ALL_VESSELS, k4Id: '' }, ...hubVesselList]);
        }
    }, [siteSearchValue])

    useEffect(() => {
        if (authReducer?.selectedOu?.id) {
            getHubDataSitesList(authReducer?.selectedOu?.id);
        }
    }, [authReducer.selectedOu])

    const tabs = [
        { tabName: 'Hub', tabValue: 'hub', pathName: '/hub' },
        { tabName: 'Agent', tabValue: 'agent', pathName: '/hub' },
        { tabName: 'Agent Config', tabValue: 'agentConfig', pathName: '/hub', hideTab: localStorage?.getItem("pacHubEnabled") === "true" ? 'No' : 'Yes' },
        { tabName: 'Events', tabValue: 'events', pathName: '/hub', hideTab: localStorage?.getItem("pacHubEnabled") === "true" ? 'No' : 'Yes' }
    ]

    const doNavigateTab = (pathname, tabName) => {
        const currentParams = getDecodeURI(location?.search);
        const params:any = {};
        if (currentParams.ouName) {
          params['ouName'] = currentParams.ouName;
        }
        if (currentParams.ouId) {
          params['ouId'] = currentParams.ouId;
        }
        if (currentParams.k4Ids) {
          params['hubK4Ids'] = currentParams.k4Ids;
        }
        if(currentParams.sideNav){
          params['sideNav'] = currentParams.sideNav
        }
        if(currentParams.interval) {
          params['interval'] = currentParams.interval
        }
        const { startDate, endDate } = updateTimePeriod(dispatch, location);
        params.startDate = startDate;
        params.endDate = endDate;
        params.tab = tabName;
        history.push({ pathname, search: getEncodedURI(params) });
        setActiveTab(tabName);
      }

    return (
        <div>
            <CommonFilters loading={loading} showWantType={false} showSummaryPeriod={activeTab === 'agentConfig' ? false : true} hubVesselNames={hubVesselNames} hubSelectedVesselNames={hubSelectedVesselNames} hubSelectedVesselsDisplayName={hubSelectedVesselsDisplayName}
            siteSearchValue={siteSearchValue} setSiteSearchValue={setSiteSearchValue} handleChangeVesselHub={handleChangeVesselHub}></CommonFilters>
            <TabsComponent tabs={tabs} handleChangeTab={doNavigateTab} activeTab={activeTab} />
            <div className="hub_details_container" style={{height: "700px", overflow: "scroll"}}>
                <div>
                {activeTab === 'hub' 
                    ? <HubDetailsTable /> : activeTab === 'agent' 
                    ? <AgentTable/> : activeTab === 'agentConfig'  && localStorage.getItem("pacHubEnabled") === "true"
                    ? <AgentConfigTable/> : activeTab === 'events'  && localStorage.getItem("pacHubEnabled") === "true" 
                    ? <HubEvent /> : null
                }
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
    setHubDataLoading: state.authReducer.setHubDataLoading,
    setHubDataCountLoading: state.authReducer.setHubDataCountLoading,
    setHubDataClientTableLoading: state.authReducer.setHubDataClientTableLoading,
    setHubDashboardClientWiseLoading: state.authReducer.setHubDashboardClientWiseLoading,
    setHubDashboardClientWiseCountLoading: state.authReducer.setHubDashboardClientWiseCountLoading,
    setHubDashboardCarrierClientTableLoading: state.authReducer.setHubDashboardCarrierClientTableLoading,
    setHubDashboardCarrierServiceTableLoading: state.authReducer.setHubDashboardCarrierServiceTableLoading,
    setTopologyHubTableLoading: state.authReducer.setTopologyHubTableLoading,
});

export default withRouter(
    connect(mapStateToProps, {updatePermission, getHubDataSitesList, updateHubFilteredVessels})(HubDetails)
)