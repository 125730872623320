import { Fragment, ReactNode, SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { AddCircle } from "@material-ui/icons";
import { Autocomplete, Button, Grid, TextField, Tooltip, Typography } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { RemoveCircle } from '@mui/icons-material';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { useDispatch } from 'react-redux';
import _ from "lodash";


const WanItemsPopup = (props) => {
    const { authReducer, wanItems, setWanItems, isEditActionPopup } = props;
    const addthretaKontrolAction = () => {
        const newAction = {
            id: wanItems.length,
            name: "",
            wanType: "",
            ip: "",
            gateway: "",
            vid: "",
            interface: "",
        };
        setWanItems([...wanItems, newAction]);
    };
    const WanItemsPopup = (id, updatedAction) => {
        setWanItems(
            wanItems?.map((action, index) =>
                index === id ? updatedAction : action
            )
        );
    };
    const removeWanItem = (id: string) => {
        const updatedActions = wanItems.filter((_, index) => index !== id);
        setWanItems(updatedActions);

        setWanItems((prevActions) =>
            prevActions?.map((action, index) => ({ ...action, id: index }))
        );
    };
    return (
        <div>
            <div style={{ marginTop: "25px", }}>
                {
                    wanItems?.length !== 0 ? (
                        <Grid container lg={12} style={{ background: "#f5f5f5", marginBottom: "1.5%" }}>
                            <Grid item lg={2}>
                                <p style={{ fontFamily: "Inter", fontStyle: "normal", fontWeight: 500, fontSize: "14px", marginBottom: "10px", marginTop: "10px", textAlign: "center" }}> WAN NAME</p>
                            </Grid>
                            <Grid item lg={2}>
                                <p style={{ fontFamily: "Inter", fontStyle: "normal", fontWeight: 500, fontSize: "14px", marginBottom: "10px", marginTop: "10px", textAlign: "center" }}> WAN TYPE</p>
                            </Grid>
                            <Grid item lg={2}>
                                <p style={{ fontFamily: "Inter", fontStyle: "normal", fontWeight: 500, fontSize: "14px", marginBottom: "10px", marginTop: "10px", textAlign: "center" }}> INTERFACE</p>

                            </Grid>
                            <Grid item lg={2}>
                                <p style={{ fontFamily: "Inter", fontStyle: "normal", fontWeight: 500, fontSize: "14px", marginBottom: "10px", marginTop: "10px", textAlign: "center" }}> IP</p>

                            </Grid>
                            <Grid item lg={2}>
                                <p style={{ fontFamily: "Inter", fontStyle: "normal", fontWeight: 500, fontSize: "14px", marginBottom: "10px", marginTop: "10px", textAlign: "center" }}> GATEWAY</p>

                            </Grid>
                            <Grid item lg={1}>
                                <p style={{ fontFamily: "Inter", fontStyle: "normal", fontWeight: 500, fontSize: "14px", marginBottom: "10px", marginTop: "10px", textAlign: "center" }}> VID</p>

                            </Grid>
                        </Grid>
                    ) : null
                }

                {wanItems?.map((action, index) => (
                    <WanItem
                        key={index}
                        id={index}
                        data={action}
                        authReducer={authReducer}
                        onUpdate={WanItemsPopup}
                        onRemove={removeWanItem}
                        isEditActionPopup={isEditActionPopup}
                    />
                ))}
            </div>
            {/* <Button variant="outlined" startIcon={<AddCircle className="addActionIcon" style={{ paddingRight: "0%" }} />} style={{ marginTop: "10px", width: "170px" }} onClick={addthretaKontrolAction} >
                Add WAN
            </Button> */}
            <Button variant="outlined" size="small" startIcon={<AddCircle />} className="agent-wan-action-button" style={{ marginTop: "15px" }} onClick={addthretaKontrolAction} >
                ADD WAN
            </Button>
        </div>
    )
}

const WanItem = (props) => {
    const { authReducer, getSeverityClassList, getThreatNameList, id, data, onUpdate, onRemove, copy, isEditActionPopup } = props;
    const handleChange = (field: string, value: any) => {
        onUpdate(id, { ...data, [field]: value || '' });
    };

    const dispatch = useDispatch();

    return (
        <div >
            <Grid container lg={12} spacing={1} style={{ marginTop: "5px" }}>
                {/* <Grid item lg={12} className="threatAction"> */}
                <Grid item lg={2}>
                    <TextField
                        placeholder="WAN Name"
                        value={data?.name}
                        onChange={(event) => {
                            handleChange("name", event?.target?.value)
                        }}
                        variant="outlined"
                        size="small"
                        inputProps={{
                            sx: {
                                "&::placeholder": {
                                    fontSize: "12px",
                                    color: "grey",
                                    fontFamily: 'Inter'
                                }
                            }
                        }}

                    />
                </Grid>
                <Grid item lg={2}>
                    <Autocomplete
                        id="select-users"
                        size='small'
                        // className={notificationMethods.includes("email") ? "" : "disbale-user-selection"}
                        value={data?.wanType}
                        onChange={(e, newValue: any) => {  handleChange("wanType", newValue) }}
                        options={["WIRED", "WIFI", "LEO",]}
                        getOptionLabel={(option) => option}
                        // disabled={disableActions}
                        renderInput={(params) => <TextField {...params} label="Select" InputLabelProps={{
                            sx: { fontSize: '12px', color: "lightgrey", }
                        }}
                            sx={{
                                "& .MuiInputBase-root": {
                                    height: 40,
                                },
                                "& .MuiOutlinedInput-input": {
                                    paddingTop: "10px",
                                },
                            }}
                        />}
                        renderOption={(props, option) => <li {...props} key={option}>{option}</li>}
                        sx={{ width: "100%", maxWidth: "100%", }}
                    />
                </Grid>
                <Grid item lg={2}>
                    <TextField
                        placeholder="Interface"
                        value={data?.interface}
                        onChange={(event) => {
                            handleChange("interface", event?.target?.value)
                        }}
                        variant="outlined"
                        size="small"
                        inputProps={{
                            sx: {
                                "&::placeholder": {
                                    fontSize: "12px",
                                    color: "grey",
                                    fontFamily: 'Inter'
                                }
                            }
                        }}

                    />
                </Grid>
                <Grid item lg={2}>
                    <TextField
                        placeholder="IP"
                        value={data?.ip}
                        onChange={(event) => {
                            handleChange("ip", event?.target?.value)
                        }}
                        variant="outlined"
                        size="small"
                        inputProps={{
                            sx: {
                                "&::placeholder": {
                                    fontSize: "12px",
                                    color: "grey",
                                    fontFamily: 'Inter'
                                }
                            }
                        }}

                    />
                </Grid>
                <Grid item lg={2}>
                    <TextField
                        placeholder="Gateway"
                        value={data?.gateway}
                        onChange={(event) => {
                            handleChange("gateway", event?.target?.value)
                        }}
                        variant="outlined"
                        size="small"
                        inputProps={{
                            sx: {
                                "&::placeholder": {
                                    fontSize: "12px",
                                    color: "grey",
                                    fontFamily: 'Inter'
                                }
                            }
                        }}

                    />
                </Grid>
                <Grid item lg={2}>
                    <div style={{ display: "flex", justifyContent: "space-around", alignItems: "center" }}>
                        <TextField
                            placeholder="VID"
                            value={data?.vid}
                            onChange={(event) => {
                                handleChange("vid", event?.target?.value)
                            }}
                            variant="outlined"
                            size="small"
                            sx={{ width: "80%" }}
                            inputProps={{
                                sx: {
                                    "&::placeholder": {
                                        fontSize: "12px",
                                        color: "grey",
                                        fontFamily: 'Inter'
                                    }
                                }
                            }}
                        />
                        <RemoveCircle className="wanActionremoveIcon" onClick={() => onRemove(id)} />
                    </div>
                </Grid>
                {/* <Grid item lg={1} style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "center" }}>

                </Grid> */}
                {/* </Grid> */}
                <br></br>
            </Grid>
        </div>
    )
}

const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
    options: state?.authReducer?.threatSeverityClassList
});

export default withRouter(
    connect(mapStateToProps, {})(WanItemsPopup)
)
