import { connect, useDispatch } from "react-redux";
import { Button, Dialog, DialogContent, DialogTitle, FormControl, FormControlLabel, Grid, IconButton, MenuItem, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { withRouter } from "react-router-dom";
import Paper, { PaperProps } from "@material-ui/core/Paper";
import Draggable from "react-draggable";
import { Pagination } from "../../../../../components/Pagination";
import TabsComponent from "../../../../../components/NewTabs";
import { DEFAULT_PAGE_SIZE } from "../../../../../constants/Constants";
import { convertDateTimeIntoTimezone, getDecodeURI } from "../../../../../utils/util";
import { getFlightSchedule } from "../../actions/singleStoreActions";
import { MMDDYYYYHMMSS_DATE_FORMAT_24_HRS } from "../../../../../utils/constants";
import CloseIc from '../Map/images/cross.svg'
import './flightsTable.css'



const FlightsTable = (props: any) => {
    const {  open, setOpen, authReducer, errorReducer, newSummaryStartDate, newSummaryEndDate, selectedVessel,  location, flightScheduleDetails, userTimezone, handleFlightJourney, k4Id  } = props;
    console.log("flightScheduleDetails",flightScheduleDetails)
    console.log("flightScheduleDetails authReducer",authReducer)
    let _q = getDecodeURI(location?.search);
    const [Page, setPage] = useState(_q.hasOwnProperty("page") ? parseInt(_q.page) : 1);
    const dispatch = useDispatch();


    useEffect(() => {
        if(localStorage.getItem("isBetaUser") === "true") {
            dispatch(getFlightSchedule(k4Id,newSummaryStartDate,newSummaryEndDate,""))
        }
    },[k4Id,newSummaryStartDate,newSummaryStartDate])

    const handleChangePage = (e:any, value:any) => {
        const page = value;
        // let params: any = getDecodeURI(location?.search);
        // params.page = value;
        setPage(page);
        // doNavigate(params);
        // getEdgeEventsTable({ ...parameters, offset: ((page - 1) * Limit), pageSize: Limit })
        // getEdgeEventsTableCount({ ...parameters, offset: ((page - 1) * Limit), pageSize: Limit })
    }


    const handleChangeRowsPerPage = (e:any) => {
        const limit = parseInt(e.target.value);
        const page = 1;
        // let params: any = getDecodeURI(location?.search);
        // params.page = 1;
        // params.limit = limit;
        setPage(1);
        setLimit(limit);
        // doNavigate(params);
        // getEdgeEventsTable({ ...parameters, offset: ((page - 1) * limit), pageSize: limit })
        // getEdgeEventsTableCount({ ...parameters, offset: ((page - 1) * limit), pageSize: limit })
    }

   const [Limit, setLimit] = useState(DEFAULT_PAGE_SIZE);
    return (
        <div className="Form menuScroll maps-chart-div">
            <Dialog
                disableEnforceFocus
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby='form-dialog-title'
                fullWidth={true}
                maxWidth={'md'}
                // PaperComponent={DraggablePaperComponent}
                sx={{ borderRadius: '8px', position:'absolute', top:'-60px', right:'-10px', display: 'block',
                    '& .MuiDialog-container': {
                        height: 'auto',
                        // display: 'block',
                        width: 'calc(100% - 6%)'
                    }
                 }}
                className='maps-charts-dialogue menuScroll '
            >
                <div>
                    <DialogTitle className='create-default-config-title'>
                        <span>Flights Selection</span>
                        <img src={CloseIc} title="Close" onClick={() => setOpen(false)} />
                    </DialogTitle>
                    <DialogContent>
                        <div>
                            {/* <div id="maps-sdwan-charts-btns">
                                <TabsComponent tabs={tabs} handleChangeTab={doNavigateTab} activeTab={selectedTab} />
                            </div> */}
                           <EventsTable Page={Page} setPage={setPage} flightScheduleDetails={flightScheduleDetails} handleChangePage={handleChangePage} handleChangeRowsPerPage={handleChangeRowsPerPage} Limit={Limit} userTimezone={userTimezone} handleFlightJourney={handleFlightJourney}/>
                
                        </div>
                    </ DialogContent>
                </div>
            </Dialog>
        </div>
    )
}


// const EventsTable = (props: any) => {
//     const { flightScheduleDetails, Page, handleChangePage, handleChangeRowsPerPage, Limit, userTimezone, handleFlightJourney } = props;
//     const [flightscheduleTable, setFlightscheduleTable] = useState<any>([]);
//     const [count, setCount] = useState(0);
//     const [selectedRowIndex, setSelectedRowIndex] = useState<number>(0); // Default first row selected

//     useEffect(() => {
//         if (flightScheduleDetails && flightScheduleDetails?.rows?.length > 0) {
//             let tableData = flightScheduleDetails?.rows.map((item: any) => {
//                 return flightScheduleDetails?.columns.reduce((vsd: any, columnitem: any, i: any) => {
//                     vsd[columnitem] = item[i];
//                     return vsd;
//                 }, {});
//             });
//             setFlightscheduleTable(tableData);
//             setCount(flightScheduleDetails.rows.length);
//         }
//     }, [flightScheduleDetails]);

//     const handleRowClick = (index: number, row: any) => {
//         setSelectedRowIndex(index); // Update selected row index
//         console.log("Selected Row Data:", row); // Callback: Replace this with your callback logic
//         handleFlightJourney(row)
//     };

//     function calculateDuration(startTime: any, endTime: any) {
//         const start: any = new Date(startTime);
//         const end: any = new Date(endTime);
//         if (isNaN(start) || isNaN(end)) {
//             throw new Error("Invalid date format. Ensure the input matches 'YYYY-MM-DD HH:mm:ss +0000 UTC'.");
//         }
//         const durationMs = end - start;
//         const days = Math.floor(durationMs / (1000 * 60 * 60 * 24));
//         const hours = Math.floor((durationMs % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
//         const minutes = Math.floor((durationMs % (1000 * 60 * 60)) / (1000 * 60));
//         const seconds = Math.floor((durationMs % (1000 * 60)) / 1000);
//         let result = "";
//         if (days > 0) {
//             result += `${days}d `;
//         }
//         result += `${hours}h ${minutes}m ${seconds}s`;
//         return result.trim();
//     }

//     return (
//         <Grid className="chartsEventTableContainer">
//             <Grid className="chartsEventTable chartsEventTableFlights">
//                 <Paper className="pagination-position usage-pagination">
//                     <Pagination
//                         rowsPerPageOptions={[10, 15, 25, 50, 100]}
//                         count={Number(count)}
//                         rowsPerPage={Limit}
//                         page={Page}
//                         onPageChange={handleChangePage}
//                         onRowsPerPageChange={handleChangeRowsPerPage}
//                     />
//                 </Paper>
//                 <TableContainer className="usageReports-FleetDashboard chartEvent-FleetDashboard">
//                     <Table aria-label="simple table">
//                         <TableHead className="usageReports-tableHead">
//                             <TableRow className="UsageReports-tableRow maps-charts-fleetDataTableRow">
//                                 <TableCell className="usage-fleetDataTable">TAIL ID</TableCell>
//                                 <TableCell className="usage-fleetDataTable">STARTTIME</TableCell>
//                                 <TableCell className="usage-fleetDataTable">ENDTIME</TableCell>
//                                 <TableCell className="usage-fleetDataTable">SOURCE</TableCell>
//                                 <TableCell className="usage-fleetDataTable">DESTINATION</TableCell>
//                                 <TableCell className="usage-fleetDataTable">DURATION</TableCell>
//                                 <TableCell className="usage-fleetDataTable">AVAILABILITY</TableCell>
//                             </TableRow>
//                         </TableHead>
//                         {flightscheduleTable.length > 0 ? (
//                             <TableBody className="tableBody usage-tableBody">
//                                 {flightscheduleTable.map((row: any, index: any) => (
//                                     <TableRow
//                                         key={index}
//                                         className={`usageData-tabelRow chartEvent-tableRow ${
//                                             selectedRowIndex === index ? "selected-row" : ""
//                                         }`}
//                                         onClick={() => handleRowClick(index, row)}
//                                         style={{
//                                             backgroundColor: selectedRowIndex === index ? "lightblue" : "inherit",
//                                             cursor: "pointer",
//                                         }}
//                                     >
//                                         <TableCell className="usagedata-tableCell chartEvent-tablecell highlight">{row?.tail_id}</TableCell>
//                                         <TableCell className="usagedata-tableCell chartEvent-tablecell highlight">
//                                             {convertDateTimeIntoTimezone(row?.start_time, userTimezone, MMDDYYYYHMMSS_DATE_FORMAT_24_HRS)}
//                                         </TableCell>
//                                         <TableCell className="usagedata-tableCell chartEvent-tablecell highlight">
//                                             {convertDateTimeIntoTimezone(row?.end_time, userTimezone, MMDDYYYYHMMSS_DATE_FORMAT_24_HRS)}
//                                         </TableCell>
//                                         <TableCell className="usagedata-tableCell chartEvent-tablecell highlight">{row?.origin}</TableCell>
//                                         <TableCell className="usagedata-tableCell chartEvent-tablecell highlight">{row?.destination}</TableCell>
//                                         <TableCell className="usagedata-tableCell chartEvent-tablecell highlight">
//                                             {calculateDuration(row?.start_time, row?.end_time)}
//                                         </TableCell>
//                                         <TableCell className="usagedata-tableCell chartEvent-tablecell highlight">{Math.round(row?.availability,2)}</TableCell>
//                                     </TableRow>
//                                 ))}
//                             </TableBody>
//                         ) : (
//                             <TableBody>
//                                 <TableRow>
//                                     <TableCell colSpan={7} className="noDataAvailableCss" align="center">
//                                         No data available
//                                     </TableCell>
//                                 </TableRow>
//                             </TableBody>
//                         )}
//                     </Table>
//                 </TableContainer>
//             </Grid>
//         </Grid>
//     );
// };

const EventsTable = (props: any) => {
    const { flightScheduleDetails, Page, handleChangePage, handleChangeRowsPerPage, Limit, userTimezone, handleFlightJourney } = props;
    
    const [flightscheduleTable, setFlightscheduleTable] = useState<any>([]);
    const [count, setCount] = useState(0);
    const [selectedFlightId, setSelectedFlightId] = useState<string | null>(null);

    useEffect(() => {
        if (flightScheduleDetails && flightScheduleDetails?.rows?.length > 0) {
            let tableData = flightScheduleDetails?.rows.map((item: any) => {
                return flightScheduleDetails?.columns.reduce((vsd: any, columnitem: any, i: any) => {
                    vsd[columnitem] = item[i];
                    return vsd;
                }, {});
            });
            setFlightscheduleTable(tableData);
            setCount(flightScheduleDetails.rows.length);
        }
    }, [flightScheduleDetails]);

    const handleRowClick = (row: any) => {
        const flightId = row?.fa_flight_id;
        const newFlightId = selectedFlightId === flightId ? null : flightId;

        setSelectedFlightId(newFlightId); // Toggle selection

        handleFlightJourney(newFlightId ? row : { ...row, fa_flight_id: "" }); // Pass empty ID if deselected
    };

    function calculateDuration(startTime: any, endTime: any) {
        const start: any = new Date(startTime);
        const end: any = new Date(endTime);
        if (isNaN(start) || isNaN(end)) {
            throw new Error("Invalid date format. Ensure the input matches 'YYYY-MM-DD HH:mm:ss +0000 UTC'.");
        }
        const durationMs = end - start;
        const days = Math.floor(durationMs / (1000 * 60 * 60 * 24));
        const hours = Math.floor((durationMs % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((durationMs % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((durationMs % (1000 * 60)) / 1000);
        let result = "";
        if (days > 0) {
            result += `${days}d `;
        }
        result += `${hours}h ${minutes}m ${seconds}s`;
        return result.trim();
    }

    return (
        <Grid className="chartsEventTableContainer">
            <Grid className="chartsEventTable chartsEventTableFlights">
                <Paper className="pagination-position usage-pagination">
                    <Pagination
                        rowsPerPageOptions={[10, 15, 25, 50, 100]}
                        count={Number(count)}
                        rowsPerPage={Limit}
                        page={Page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Paper>
                <TableContainer className="usageReports-FleetDashboard chartEvent-FleetDashboard">
                    <Table aria-label="simple table">
                        <TableHead className="usageReports-tableHead">
                            <TableRow className="UsageReports-tableRow maps-charts-fleetDataTableRow">
                                <TableCell className="usage-fleetDataTable">Flight No</TableCell>
                                <TableCell className="usage-fleetDataTable">TAIL ID</TableCell>
                                <TableCell className="usage-fleetDataTable">STARTTIME</TableCell>
                                <TableCell className="usage-fleetDataTable">ENDTIME</TableCell>
                                <TableCell className="usage-fleetDataTable">SOURCE</TableCell>
                                <TableCell className="usage-fleetDataTable">DESTINATION</TableCell>
                                {/* <TableCell className="usage-fleetDataTable">DURATION</TableCell> */}
                                <TableCell className="usage-fleetDataTable">AVAILABILITY</TableCell>
                            </TableRow>
                        </TableHead>
                        {flightscheduleTable.length > 0 ? (
                            <TableBody className="tableBody usage-tableBody">
                                {flightscheduleTable.map((row: any, index: any) => {
                                    const isSelected = selectedFlightId === row?.fa_flight_id;

                                    return (
                                        <TableRow
                                            key={index}
                                            className={`usageData-tabelRow chartEvent-tableRow ${isSelected ? "selected-row" : ""}`}
                                            onClick={() => handleRowClick(row)}
                                            style={{
                                                backgroundColor: isSelected ? "lightblue" : "inherit",
                                                cursor: "pointer",
                                            }}
                                        >
                                            <TableCell className="usagedata-tableCell chartEvent-tablecell highlight">{row?.flightno}</TableCell>
                                            <TableCell className="usagedata-tableCell chartEvent-tablecell highlight">{row?.tail_id}</TableCell>
                                            <TableCell className="usagedata-tableCell chartEvent-tablecell highlight">
                                                <Tooltip title={<span style={{ fontSize: "12px" }}>{calculateDuration(row?.start_time, row?.end_time)}</span>} arrow>
                                                   <div> {convertDateTimeIntoTimezone(row?.start_time, userTimezone, MMDDYYYYHMMSS_DATE_FORMAT_24_HRS)}  </div>
                                                </Tooltip>
                                            </TableCell>
                                            <TableCell className="usagedata-tableCell chartEvent-tablecell highlight">
                                                <Tooltip title={<span style={{ fontSize: "12px" }}>{calculateDuration(row?.start_time, row?.end_time)}</span>} arrow>
                                                   <div> {convertDateTimeIntoTimezone(row?.end_time, userTimezone, MMDDYYYYHMMSS_DATE_FORMAT_24_HRS)}  </div>
                                                </Tooltip>
                                            </TableCell>
                                            <TableCell className="usagedata-tableCell chartEvent-tablecell highlight">{row?.origin}</TableCell>
                                            <TableCell className="usagedata-tableCell chartEvent-tablecell highlight">{row?.destination}</TableCell>
                                            <TableCell className="usagedata-tableCell chartEvent-tablecell highlight">{Math.round(row?.availability).toFixed(2)}</TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        ) : (
                            <TableBody>
                                <TableRow>
                                    <TableCell colSpan={7} className="noDataAvailableCss" align="center">
                                        No data available
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        )}
                    </Table>
                </TableContainer>
            </Grid>
        </Grid>
    );
};


const mapStateToProps = (state:any) => ({
    errorReducer: state.errorReducer,
    newSummaryStartDate: state?.authReducer?.newSummaryStartDate,
    newSummaryEndDate: state?.authReducer?.newSummaryEndDate,
    flightScheduleDetails: state?.products?.flightScheduleDetails
});

export default withRouter(
    connect(mapStateToProps, {
        
    })(FlightsTable)
);