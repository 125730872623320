import React, { useEffect, useState } from "react";
import L from "leaflet";
import { Marker, useMap } from "react-leaflet";
import FlightIcon from "@mui/icons-material/Flight";
import { renderToStaticMarkup } from "react-dom/server";

const FlightPathWithElevation = ({ 
  points, 
  options = {}, 
  labels = {}, 
  iconPosition, 
  rotation = 0, 
  onPointClick,
  resetTrigger // Pass from parent to trigger reset
}) => {
  const map = useMap();
  const [localPoints, setLocalPoints] = useState(points);
  const [localIconPosition, setLocalIconPosition] = useState(iconPosition);

  useEffect(() => {
    setLocalPoints(points);
    setLocalIconPosition(iconPosition);
  }, [points, iconPosition]);


  useEffect(() => {
    if(resetTrigger) {
      setLocalPoints([]);
      setLocalIconPosition(null);
    }
  }, [resetTrigger]);

  // Helper function to validate coordinates
  const isValidLatLng = ([lat, lng]) =>
    typeof lat === "number" && typeof lng === "number" && !isNaN(lat) && !isNaN(lng);

  console.log("Input points:", localPoints);
  console.log("Icon position:", localIconPosition);

  useEffect(() => {
    if (!localPoints || localPoints.length < 2) {
      console.warn("Invalid points array:", localPoints);
      return;
    }

    // Filter valid points
    const validPoints = localPoints.filter(isValidLatLng);

    if (validPoints.length < 2) {
      console.warn("Not enough valid points to draw the path:", validPoints);
      return;
    }

    // Generate arcs with elevation
    const generateArcWithElevation = (start, end, numPoints = 100, maxAltitude = 50000) => {
      const toRadians = (deg) => (deg * Math.PI) / 180;
      const toDegrees = (rad) => (rad * 180) / Math.PI;

      const [lat1, lon1] = [toRadians(start[0]), toRadians(start[1])];
      const [lat2, lon2] = [toRadians(end[0]), toRadians(end[1])];
      const dLon = lon2 - lon1;

      return Array.from({ length: numPoints + 1 }, (_, i) => {
        const f = i / numPoints;
        const A = Math.sin((1 - f) * dLon) / Math.sin(dLon);
        const B = Math.sin(f * dLon) / Math.sin(dLon);

        const x = A * Math.cos(lat1) * Math.cos(lon1) + B * Math.cos(lat2) * Math.cos(lon2);
        const y = A * Math.cos(lat1) * Math.sin(lon1) + B * Math.cos(lat2) * Math.sin(lon2);
        const z = A * Math.sin(lat1) + B * Math.sin(lat2);

        const lat = toDegrees(Math.atan2(z, Math.sqrt(x * x + y * y)));
        const lon = toDegrees(Math.atan2(y, x));
        const altitude = Math.sin(Math.PI * f) * maxAltitude;

        return [lat, lon, altitude];
      });
    };

    // Generate arcs for valid points
    const allArcPoints = validPoints.slice(0, -1).flatMap((start, i) => {
      const end = validPoints[i + 1];
      return generateArcWithElevation(start, end, 100, options.maxAltitude || 50000);
    });

    // Flatten arcs to 2D points
    const flatArcPoints = allArcPoints.map(([lat, lon]) => [lat, lon]);

    // Draw the flight path
    const flightPath = L.polyline(flatArcPoints, {
      color: options.color || "blue",
      weight: options.weight || 3,
      opacity: options.opacity || 0.8,
      dashArray: options.dashArray || null,
    }).addTo(map);

    // Add click event on polyline
    flightPath.on("click", (e) => {
      if (onPointClick) {
        onPointClick(e.latlng);
      }
    });

    // Add start label
    const startLabel = L.marker(validPoints[0], {
      icon: L.divIcon({
        className: "custom-label",
        html: `<div style="font-size: 12px; color: black;">${labels.start ? labels.start : ""}</div>`,
        iconAnchor: [2, -2],
      }),
    }).addTo(map);

    // Add end label
    const endLabel = L.marker(validPoints[validPoints.length - 1], {
      icon: L.divIcon({
        className: "custom-label",
        html: `<div style="font-size: 12px; color: black;">${labels.end ? labels?.end : ""}</div>`,
        iconAnchor: [2, -2],
      }),
    }).addTo(map);

    // Add green circles at both ends of the line
    const startCircle = L.circleMarker(validPoints[0], {
      color: "green",
      radius: 4,
      fillOpacity: 1,
    }).addTo(map);

    const endCircle = L.circleMarker(validPoints[validPoints.length - 1], {
      color: "green",
      radius: 4,
      fillOpacity: 1,
    }).addTo(map);

    return () => {
      map.removeLayer(flightPath);
      map.removeLayer(startLabel);
      map.removeLayer(endLabel);
      map.removeLayer(startCircle);
      map.removeLayer(endCircle);
    };
  }, [localPoints, map, options, labels, onPointClick]);

  // Custom flight icon marker with rotation
  const flightIcon = L.divIcon({
    html: renderToStaticMarkup(
      <div
        style={{
          transform: `rotate(${rotation}deg)`,
          transformOrigin: "center",
        }}
      >
        <FlightIcon style={{ color: "green", fontSize: "44px" }} />
      </div>
    ),
    className: "custom-flight-icon",
    iconSize: [44, 44],
    iconAnchor: [22, 22], // Center the icon
  });

  if (localIconPosition && !isValidLatLng([localIconPosition.lat, localIconPosition.lng])) {
    console.error("Invalid icon position:", localIconPosition);
    return null;
  }

  return (
    <>
      {localIconPosition && (
        <Marker position={[localIconPosition.lat, localIconPosition.lng]} icon={flightIcon} />
      )}
    </>
  );
};

export default FlightPathWithElevation;
