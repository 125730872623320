import { Checkbox, Dialog, DialogContent, DialogTitle, FormControlLabel, Grid, Tab, Tabs, Tooltip } from "@mui/material";
import InfoIcon from '@mui/icons-material/Info';
import close from "../../asset/image/close.svg";
import { useState } from "react";
import BackArrow from "../../asset/image/arrow_back.svg";
import './newTabs.css';
import _ from "lodash";
import {CAPTIVE_ACCESS_PORTAL_URL} from "../../../src/config";
import {getDataFromLocalStorageParsed} from "../../../src/utils/util";

const TabsComponent = (props) => {
    const { tabs, handleChangeTab, activeTab, alertStatus, setAlertStatus, trendsAlertsFilter, isSubTabs, infoPopup, infoMessage, infoIcon, infoiconMessage, backButtonNeeded, goBack } = props;

    const [confirmDialog, setConfirmDialog] = useState({
        isOpen: false,
        title: "",
        subTitle: ""
    });
    const appendEdgeToken = (url) => {
        const loggedInUser = getDataFromLocalStorageParsed("loggedInUser");
        let edgeToken = loggedInUser?.data?.edge_token;
        if (!_.isEmpty(edgeToken)) {
            url = `${url}?token=${edgeToken}`
        }
        return url
    }
    const handleChangeStatus = (name: string) => (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setAlertStatus({ ...alertStatus, [name]: event.target.checked })
    };

    return (
        <Grid className="New-UX-NavigationTabs-Conatiner" sx={{ display: activeTab === 'alerts' ? 'flex' : 'block' }}
            id={isSubTabs ? 'SubTabs-Container-marginTop-10' : 'SubTabs-Container-marginTop-112'}
        >
            {backButtonNeeded && <img src={BackArrow} className="backButtonImg" onClick={goBack} />}
            <Grid className="NewTabsDiv-InfoIconsDiv">
                <Tabs
                    value={activeTab}
                    onChange={(event, newValue) => {
                        if(newValue === 'captive_admin'){
                            window.open(appendEdgeToken(CAPTIVE_ACCESS_PORTAL_URL), "_blank")
                        }
                        else {
                            const selectedTab = tabs.find(tab => tab.tabValue === newValue);
                            if (selectedTab) {
                                handleChangeTab(selectedTab.pathName, newValue);
                            }
                        }
                    }}
                    className="tabButtons-parent-div"
                    variant="scrollable"
                    scrollButtons="auto"
                    TabIndicatorProps={{ style: { display: 'none' } }}
                >
                    {tabs.filter(tab => tab && !(tab.hideTab && tab.hideTab === "Yes")).map((tab, i) => (
                        <Tab
                            key={i}
                            value={tab.tabValue}
                            label={tab.tabName}
                            disabled={tab && tab?.disable && tab.disable === "Yes" ? true : false}
                            className={activeTab === tab.tabValue ? "New-UX-NavigationTabs-activeTab" : "New-UX-NavigationTabs-inActiveTab"}
                            title={tab.tabName}
                            onClick={(event) => {
                                if (tab.tabValue === "captive_admin") {
                                    event.preventDefault();
                                }
                            }}
                        />
                    ))}
                    {trendsAlertsFilter && <Grid className='tabs-alert-status'>
                        <FormControlLabel control={<Checkbox name='alerting' checked={alertStatus.Alerting} disabled={alertStatus?.isCurrentAlert ? true : false} onChange={handleChangeStatus('Alerting')} />} label="Alerting" />
                        <FormControlLabel control={<Checkbox name='normal' checked={alertStatus.Normal} disabled={alertStatus?.isCurrentAlert ? true : false} onChange={handleChangeStatus('Normal')} />} label="Cleared" />
                    </Grid>}
                </Tabs>
                {infoPopup && <div onClick={() => setConfirmDialog({
                    isOpen: true,
                    title: 'Information',
                    subTitle: infoMessage,
                })} className="tabs-helpIcon"><InfoIcon />
                </div>}
                {infoIcon && <div className="tabs-helpIcon">
                    <Tooltip placement="left" title={infoiconMessage}><InfoIcon /></Tooltip>
                </div>}
            </Grid>
            {confirmDialog.isOpen ? <ShowText confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog} /> : null}
        </Grid>
    )
}

function ShowText({ confirmDialog, setConfirmDialog }) {
    return (
        <div className="text-msg-dialogue">
            <Dialog open={confirmDialog.isOpen} onClose={() => setConfirmDialog({ ...confirmDialog, isOpen: false })} className="custom_confirm_dialog custom-text-dialogue">
                <DialogTitle className="text-msg-dialogue-title">
                    <div>
                        {confirmDialog.title}
                    </div>
                    <img src={close} className="text-close" title="Close" onClick={() => setConfirmDialog({ ...confirmDialog, isOpen: false })} />
                </DialogTitle>
                <DialogContent className="text-msg-dialogue-content" style={{ paddingTop: "45px", paddingBottom: '50px' }}>
                    <div className="custom_confirm_dialog_sub_title">
                        {confirmDialog.subTitle}
                    </div>
                </DialogContent>
            </Dialog>
        </div>
    )
}

export default TabsComponent;