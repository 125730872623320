import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Button, Grid, DialogContent, Box, TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from "@mui/material";
import { useEffect, useState, useMemo } from "react";
import _ from "lodash";
import { backupConfigNow, applyGoldenConfig, backupConfigData, getLicenseTypes, upgradeDevicetoLatestEOSversion, rebootDevice, upgradeInventoryLicense } from "../../../actions/Users/authenticateInventory";
import { NEW_PER_PAGE_ITEMS } from "../../../constants/Constants";
import { Pagination } from "../../../components/Pagination";
import { DEFAULT_PAGE_SIZE } from "../../../constants/Constants";

import { getAgentConfigSummary } from "../../../actions/Users/authenticateHub";

const DEFAULT_PAGE = 1;

export const AgentConfigSummary = (props) => {
    const { authReducer, getAgentConfigSummary, agentId, handleClose, selectedDp } = props;

    const [hubs, setHubs] = useState<any>([])
    const [vlans, setVlans] = useState<any>([])
    const [routes, setRoutes] = useState<any>([])
    const [summary, setSummary] = useState<any>(null)

    const [hubLimit, setHubLimit] = useState<number>(DEFAULT_PAGE_SIZE);
    const [hubPage, setHubPage] = useState<number>(DEFAULT_PAGE);

    const [vlanLimit, setVlanLimit] = useState<number>(DEFAULT_PAGE_SIZE);
    const [vlanPage, setVlanPage] = useState<number>(DEFAULT_PAGE);

    const [routeLimit, setRouteLimit] = useState<number>(DEFAULT_PAGE_SIZE);
    const [routePage, setRoutePage] = useState<number>(DEFAULT_PAGE);

    useEffect(() => {
        if (agentId && selectedDp?.id) {
            getAgentConfigSummary(selectedDp?.id, agentId)
        }
    }, [agentId, selectedDp])

    useEffect(() => {
        if (!_.isEmpty(authReducer?.getAgentConfigSummary) && !authReducer?.getAgentConfigSummaryLoading) {
            let configSummary = authReducer?.getAgentConfigSummary?.data
            setSummary(configSummary || null)
            setRoutes(configSummary?.routes?.wans)
            setVlans(configSummary?.vlans?.vlans)

            let existingHubs: any = []
            if (configSummary?.services) {
                for (let item of configSummary?.services) {
                    let serviceName = item?.service_name
                    if (item?.hubs) {
                        for (let itemHub of item?.hubs) {
                            if (itemHub?.udpv1_wans) {
                                for (let wan of itemHub?.udpv1_wans) {
                                    existingHubs.push({
                                        service_name: serviceName,
                                        hub_name: itemHub?.hub_name,
                                        remote_addr: wan?.interface_addr,
                                        reachable_ip: wan?.remote_addr,
                                        wan_name: wan?.wan?.name,
                                        wan_type: wan?.wan?.type,
                                    })
                                }
                            }
                        }
                    }
                }
            }
            setHubs(existingHubs)
        }
    }, [authReducer?.getAgentConfigSummary])

    const paginatedHubs = useMemo(() => {
        let pageNumber = hubPage || 1
        let pageLimit = hubLimit || DEFAULT_PAGE_SIZE
        const startIndex = (pageNumber - 1) * pageLimit;
        return hubs.slice(startIndex, startIndex + pageLimit);
    }, [hubs, hubPage, hubLimit]);

    const paginatedVlans = useMemo(() => {
        let pageNumber = vlanPage || 1
        let pageLimit = vlanLimit || DEFAULT_PAGE_SIZE
        const startIndex = (pageNumber - 1) * pageLimit;
        return vlans.slice(startIndex, startIndex + pageLimit);
    }, [vlans, vlanPage, vlanLimit]);

    const paginatedRoutes = useMemo(() => {
        let pageNumber = routePage || 1
        let pageLimit = routeLimit || DEFAULT_PAGE_SIZE
        const startIndex = (pageNumber - 1) * pageLimit;
        return routes.slice(startIndex, startIndex + pageLimit);
    }, [routes, routePage, routeLimit]);

    const handleHubPagination = (e: any, value: number) => {
        setHubPage(value)
    }

    const handleHubPerPageChange = (e: any) => {
        const value = parseInt(e.target.value);
        setHubLimit(value)
    }

    const handleVlanPagination = (e: any, value: number) => {
        setVlanPage(value)
    }

    const handleVlanPerPageChange = (e: any) => {
        const value = parseInt(e.target.value);
        setVlanLimit(value)
    }

    const handleRoutePagination = (e: any, value: number) => {
        setRoutePage(value)
    }

    const handleRoutePerPageChange = (e: any) => {
        const value = parseInt(e.target.value);
        setRouteLimit(value)
    }

    return (
        <Grid className="Form">
            <DialogContent className="deploy-inventory-content register-inventory-content">
                <Grid container className="config-apply-config-target" style={{ marginBottom: "15px" }}>
                    <Grid lg={12} style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "1em" }}>
                        <p> {summary?.agent_config_name}</p>
                    </Grid>
                </Grid>
                <Grid container className="config-apply-config-target" style={{ paddingRight: "10px", marginTop: "20px" }}>
                    <p style={{ fontFamily: "Inter", fontStyle: "normal", fontWeight: 500, fontSize: "14px", marginBottom: "10px", marginTop: "10px" }}> HUBS</p>

                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Box>
                            <Pagination
                                rowsPerPageOptions={NEW_PER_PAGE_ITEMS}
                                count={hubs?.length || 0}
                                rowsPerPage={hubLimit || 0}
                                page={hubPage || 1}
                                onPageChange={handleHubPagination}
                                onRowsPerPageChange={handleHubPerPageChange}
                            />
                        </Box>
                        <TableContainer className="agent-table-tableContainer">
                            <Table aria-label="simple table">
                                <TableHead className="tableHead agent-table-tableHead">
                                    <TableRow className="agent-table-tableRow">
                                        <TableCell className="agent-table-tableData font-wt-900">SERVICE NAME</TableCell>
                                        <TableCell className="agent-table-tableData font-wt-900">HUB NAME</TableCell>
                                        <TableCell className="agent-table-tableData font-wt-900">INTERFACE ADDRESS</TableCell>
                                        <TableCell className="agent-table-tableData font-wt-900">REACHABLE ADDRESS</TableCell>
                                        <TableCell className="agent-table-tableData font-wt-900">WAN NAME</TableCell>
                                        <TableCell className="agent-table-tableData font-wt-900">WAN TYPE</TableCell>
                                    </TableRow>
                                </TableHead>
                                {paginatedHubs?.length > 0 &&
                                    <TableBody >
                                        {
                                            paginatedHubs?.length > 0 && paginatedHubs.map((row, index) =>
                                                <TableRow className="agent-table-tabelRow">
                                                    <TableCell className="agent-table-tableCell agent-table-tableCell-source">{row["service_name"]}</TableCell>
                                                    <TableCell className="agent-table-tableCell agent-table-tableCell-source">{row['hub_name']}</TableCell>
                                                    <TableCell className="agent-table-tableCell agent-table-tableCell-source">{row['remote_addr']}</TableCell>
                                                    <TableCell className="agent-table-tableCell agent-table-tableCell-source">{row['reachable_ip']}</TableCell>
                                                    <TableCell className="agent-table-tableCell agent-table-tableCell-source">{row['wan_name']}</TableCell>
                                                    <TableCell className="agent-table-tableCell agent-table-tableCell-source">{row['wan_type']}</TableCell>
                                                </TableRow>
                                            )
                                        }
                                    </TableBody>}
                                {(paginatedHubs?.length === 0) && <TableBody>
                                    <TableRow>
                                        <TableCell colSpan={12} className="agent-table-noDataAvailableCss" align="center">No data available</TableCell>
                                    </TableRow>
                                </TableBody>}

                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>

                <Grid container className="agent-table-container-edgeEvent" style={{ paddingRight: "10px", marginTop: "20px" }}>
                    <p style={{ fontFamily: "Inter", fontStyle: "normal", fontWeight: 500, fontSize: "14px", marginBottom: "10px", marginTop: "10px" }}> VLANS</p>

                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Box>
                            <Pagination
                               rowsPerPageOptions={NEW_PER_PAGE_ITEMS}
                               count={vlans?.length || 0}
                               rowsPerPage={vlanLimit || 0}
                               page={vlanPage || 1}
                               onPageChange={handleVlanPagination}
                               onRowsPerPageChange={handleVlanPerPageChange}
                            />
                        </Box>
                        <TableContainer className="agent-table-tableContainer">
                            <Table aria-label="simple table">
                                <TableHead className="tableHead agent-table-tableHead">
                                    <TableRow className="agent-table-tableRow">
                                        <TableCell className="agent-table-tableData font-wt-900">VLAN NAME</TableCell>
                                        <TableCell className="agent-table-tableData font-wt-900">INTERFACE</TableCell>
                                        <TableCell className="agent-table-tableData font-wt-900">VID</TableCell>
                                        <TableCell className="agent-table-tableData font-wt-900">IP</TableCell>
                                    </TableRow>
                                </TableHead>
                                {paginatedVlans?.length > 0 &&
                                    <TableBody >
                                        {
                                            paginatedVlans?.length > 0 && paginatedVlans.map((row, index) =>
                                                <TableRow className="agent-table-tabelRow">
                                                    <TableCell className="agent-table-tableCell agent-table-tableCell-source">{row['name']}</TableCell>
                                                    <TableCell className="agent-table-tableCell agent-table-tableCell-source">{row['interface']}</TableCell>
                                                    <TableCell className="agent-table-tableCell agent-table-tableCell-source">{row['vid']}</TableCell>
                                                    <TableCell className="agent-table-tableCell agent-table-tableCell-source">{row['ip']}</TableCell>
                                                </TableRow>
                                            )
                                        }
                                    </TableBody>}
                                {(paginatedVlans?.length === 0) && <TableBody>
                                    <TableRow>
                                        <TableCell colSpan={12} className="agent-table-noDataAvailableCss" align="center">No data available</TableCell>
                                    </TableRow>
                                </TableBody>}

                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>

                <Grid container className="agent-table-container-edgeEvent" style={{ paddingRight: "10px", marginTop: "20px" }}>
                    <p style={{ fontFamily: "Inter", fontStyle: "normal", fontWeight: 500, fontSize: "14px", marginBottom: "10px", marginTop: "10px" }}> ROUTES</p>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Box>
                            <Pagination
                                rowsPerPageOptions={NEW_PER_PAGE_ITEMS}
                                count={routes?.length || 0}
                                rowsPerPage={routeLimit || 0}
                                page={routePage || 1}
                                onPageChange={handleRoutePagination}
                                onRowsPerPageChange={handleRoutePerPageChange}
                            />
                        </Box>
                        <TableContainer className="agent-table-tableContainer">
                            <Table aria-label="simple table">
                                <TableHead className="tableHead agent-table-tableHead">
                                    <TableRow className="agent-table-tableRow">
                                        <TableCell className="agent-table-tableData font-wt-900">VID</TableCell>
                                        <TableCell className="agent-table-tableData font-wt-900">SOURCE NETWORK</TableCell>
                                        <TableCell className="agent-table-tableData font-wt-900">DESTINATION NETWORK</TableCell>
                                        <TableCell className="agent-table-tableData font-wt-900">SERVICE</TableCell>
                                    </TableRow>
                                </TableHead>
                                {paginatedRoutes?.length > 0 &&
                                    <TableBody >
                                        {
                                            paginatedRoutes?.length > 0 && paginatedRoutes.map((row, index) =>
                                                <TableRow className="agent-table-tabelRow">
                                                    <TableCell className="agent-table-tableCell agent-table-tableCell-source">{row['access_vid']}</TableCell>
                                                    <TableCell className="agent-table-tableCell agent-table-tableCell-source">{row['source_access_net']}</TableCell>
                                                    <TableCell className="agent-table-tableCell agent-table-tableCell-source">{row['destination_net']}</TableCell>
                                                    <TableCell className="agent-table-tableCell agent-table-tableCell-source">{row['service_name']}</TableCell>
                                                </TableRow>
                                            )
                                        }
                                    </TableBody>}
                                {(paginatedRoutes?.length === 0) && <TableBody>
                                    <TableRow>
                                        <TableCell colSpan={12} className="agent-table-noDataAvailableCss" align="center">No data available</TableCell>
                                    </TableRow>
                                </TableBody>}

                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
                <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "20px", marginRight: "10px" }}>
                    <Button variant="contained" style={{ background: "#264c86", marginTop: "30px" }} onClick={() => { handleClose() }}>Done</Button>
                </div>
            </DialogContent>
        </Grid>
    )
}


const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
    errorReducer: state.errorReducer,
});

export default withRouter(
    connect(mapStateToProps, {
        upgradeDevicetoLatestEOSversion, backupConfigNow, applyGoldenConfig, rebootDevice, backupConfigData, getLicenseTypes, upgradeInventoryLicense, getAgentConfigSummary
    })(AgentConfigSummary)
);