import { Fragment, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux"
import _ from "lodash"
import moment from "moment-timezone";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Grid, TextField, InputAdornment, Button, Stack, Box } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import Tooltip from '@mui/material/Tooltip';
import { getEdgeEventsDashBoard, getEdgeEventsFilterDashboard, downloadEdgeEventsTableDashbOard, clearEdgeEventsDownload } from "../../../actions/Users/authenticateEdgeEvents";
import { MMDDYYYYHMMSS_DATE_FORMAT_24_HRS } from "../../../utils/constants";
import "./HubEvent.css"
import { DEFAULT_PAGE_SIZE, NEW_PER_PAGE_ITEMS } from "../../../constants/Constants";
import HubEventFilter from "./HubEventFilter";
import { displayToastError } from "../../../server/request";
import { ERROR_GET_EDGE_EVENTS, ERROR_GET_EDGE_EVENTS_FILTER } from "../../../actions/error_types";
import { convertDateTimeIntoTimezone, getDecodeURI, getEncodedURI } from "../../../utils/util";
import { useHistory, useLocation } from "react-router-dom";
import JsonTooltip, { JsonDataTooltip } from "../../../components/JSONTooltip/JsonTooltip";
import { StyledEngineProvider } from '@mui/material/styles';
import { jsPDF } from "jspdf";
import autoTable from 'jspdf-autotable';
import { Pagination } from "../../../components/Pagination";
import TuneIcon from '@mui/icons-material/Tune';
import Download from '../../../asset/image/DownloadUsage.svg';
import { CsvParameters, downloadAsCSV } from "../../../actions/Users/authenticateCsvDownload";
import { getAgentHubEvents, getAgentHubEventFilters } from "../../../actions/Users/authenticateHub";
import DownloadCSV, { clearCsvDownload } from "../../../components/DownloadCSV";

const convertToCsv = (data: any[][], userTimezone: string) => {
    const [_columns, ...rows] = data;
    const csvRows: any[] = [];
    rows.forEach(row => {
        csvRows.push([convertDateTimeIntoTimezone(row[0], userTimezone, MMDDYYYYHMMSS_DATE_FORMAT_24_HRS), row[12], row[9], row[2], row[6], row[10], row[3], row[4], row[5], row[7]])
    })
    let timestamp = 'Timestamp (' + userTimezone + ')'
    return [[timestamp, 'Organization', 'Site Name', 'DID', 'Source', 'Service', 'Level', 'Module', 'Description', 'Metadata'], ...csvRows]
}

const EdgeEventsGlobal = (props) => {

    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();

    const { authReducer, errorReducer, clearEdgeEventsDownload, vesselIds, startDate, endDate, downloadAsCSV, getAgentHubEvents, getAgentHubEventFilters } = props;
    const _q = getDecodeURI(location?.search);
    const [searchValue, setSearchValue] = useState<string>(_q.hasOwnProperty("search") && _q.search.trim() !== '' ? _q.search : "");
    const DEFAULT_PAGE = 1;
    const [currentStartDate, setCurrentStartDate] = useState(startDate)
    const [currentEndDate, setCurrentEndDate] = useState(endDate)
    const [page, setPage] = useState<number>(_q.hasOwnProperty("page") && '' != _q.page.trim() ? parseInt(_q.page) : DEFAULT_PAGE);
    const [limit, setLimit] = useState<number>(_q.hasOwnProperty("limit") && '' != _q.limit.trim() ? parseInt(_q.limit) : DEFAULT_PAGE_SIZE);
    const [edgeEventsFitler, setEdgeEventsFilter] = useState({});
    const [showMoreFilterPopup, setMoreFiltersShowPopUP] = useState<Boolean>(false);
    const [pages, setPages] = useState<number>(0);
    const [total, setTotal] = useState<number>(0);
    const [fromItem, setFromItem] = useState<number>(0);
    const [toItem, setToItem] = useState<number>(0);
    const [selectedEventFilters, setSelectedEventFilters] = useState({});
    const [parameters, setParameters] = useState<any>({});
    const [isDownload, setIsDownload] = useState(false);
    const ENTER_KEY_CODE = "Enter";
    const searchTimeoutRef = useRef<NodeJS.Timeout>();

    const [hubEvents, setHubEvents] = useState<any>([])

    useEffect(() => {
        if (!_.isEmpty(authReducer?.getAgentHubEvents) && !authReducer?.getAgentHubEventsLoading) {
            setHubEvents(authReducer?.getAgentHubEvents?.data || [])
        }
    }, [authReducer?.getAgentHubEvents])

    useEffect(() => {
        if (!_.isEmpty(authReducer?.getAgentHubEventFilters) && !authReducer?.getAgentHubEventFiltersLoading) {
            const edgeEvents = authReducer?.getAgentHubEventFilters?.data;
            if (edgeEvents?.hasOwnProperty('filters')) {
                const data = edgeEvents.filters.data;
                if (data?.length >= 3) {
                    let filters = {};
                    filters["sources"] = data[3] ? data[3].split(',') : ['No Records'];
                    filters["devices"] = data[0] ? data[0].split(',') : ['No Records'];
                    filters["modules"] = data[1] ? data[1].split(',') : ['No Records'];
                    filters["levels"] = data[2] ? data[2].split(',') : ['No Records'];
                    filters["services"] = data[4] ? data[4].split(',') : ['No Records'];
                    const totalRecords = Number.parseInt(data[5]);
                    let totalPages = Math.ceil(totalRecords / limit);
                    let fromIndex = (totalRecords > 0) ? (page * limit + 1) : 0;
                    let toIndex = (fromIndex + limit - 1) > totalRecords ? totalRecords : (fromIndex + limit - 1);
                    setTotal(totalRecords);
                    setPages(totalPages);
                    setFromItem(fromIndex);
                    setToItem(toIndex);
                    let rev = new Map(Object.entries(filters).reverse());
                    let reverse = Object.fromEntries(rev);
                    setEdgeEventsFilter(reverse);
                } else {
                    setTotal(0);
                    setPages(0);
                    setFromItem(0);
                    setToItem(0);
                    setEdgeEventsFilter({});
                }
            }
        }
    }, [authReducer?.getAgentHubEventFilters])

    useEffect(() => {
        if (!_.isEmpty(errorReducer.errorGetEdgeEvents)) {
            displayToastError(errorReducer.errorGetEdgeEvents.description);
            dispatch({ type: ERROR_GET_EDGE_EVENTS, payload: {} })
        }
    }, [errorReducer.errorGetEdgeEvents]);

    useEffect(() => {
        if (!_.isEmpty(errorReducer.errorGetEdgeEventsFitler)) {
            displayToastError(errorReducer.errorGetEdgeEventsFitler.description);
            dispatch({ type: ERROR_GET_EDGE_EVENTS_FILTER, payload: {} })
        }
    }, [errorReducer.errorGetEdgeEventsFitler]);

    useEffect(() => {
        processQueryUrl()
    }, [location])

    useEffect(() => {
        if (authReducer.csvDataDownloded && authReducer.csvDataDownloded.length > 0) {
            if (authReducer.csvDownloadId === 'global-agent-events') {
                DownloadCSV(convertToCsv(authReducer.csvDataDownloded, authReducer.userTimezone)
                    , {
                        formatters: {
                            0: 'ESC-COMMA',
                            1: 'ESC-COMMA',
                            2: 'ESC-COMMA',
                            5: 'ESC-COMMA',
                            9: "JSON"
                        }
                    }
                );
                return () => {
                    dispatch(clearCsvDownload())
                }
            }
        }
    }, [authReducer.csvDataDownloded])

    const doNavigate = (params) => {
        history.push({ pathname: location.pathname, search: `?${getEncodedURI(params)}` });
    }

    const processQueryUrl = () => {

        let parameters = {
            startTime: currentStartDate?.toISOString(),
            endTime: currentEndDate?.toISOString(),
            pageSize: limit,
            offset: page,
            deviceIdFilter: "",
            moduleFilter: "",
            levelFilter: "",
            sourceFilter: "",
            serviceFilter: "",
            search: "",
            dp_id: authReducer?.selectedOu?.id,
        }
        let _q = getDecodeURI(location?.search);
        setSearchValue(_q.search ? _q.search : '');

        let _limit = _q.limit && '' != _q.limit.trim() ? parseInt(_q.limit) : DEFAULT_PAGE_SIZE;
        if (_limit != limit) {
            parameters.pageSize = _limit;
            setLimit(_limit);
        }

        let _page = _q.page && '' != _q.page.trim() ? parseInt(_q.page) : 0;
        if (_page != page) {
            parameters.offset = _page * _limit;
            setPage(_page);
        }
        let filters: any = {}
        if (_q.filter && '' !== _q.filter.trim()) {
            filters = JSON.parse(_q.filter);
            parameters.moduleFilter = filters.modules?.length > 0 ? `and module IN (${filters.modules.map(m => `'${m}'`).join()})` : '';
            parameters.deviceIdFilter = filters.devices?.length > 0 ? `and device_id IN (${filters.devices.map(d => `'${d}'`).join()})` : '';
            parameters.levelFilter = filters.levels?.length > 0 ? `and level IN (${filters.levels.map(l => `'${l}'`).join()})` : '';
            parameters.sourceFilter = filters?.sources?.length > 0 ? `and source IN (${filters.sources.map(s => `'${s}'`).join()})` : '';
            parameters.serviceFilter = filters?.services?.length > 0 ? `and service IN (${filters.services.map(s => `'${s}'`).join()})` : '';
        } else {
            filters = {
                "levels": [],
                "modules": [],
                "devices": [],
                "services": [],
            };
        }
        setSelectedEventFilters(filters);

        let _startDate = currentStartDate;
        if (_q.startDate && '' !== _q.startDate && _q.startDate > 1) {
            let incominStarDate = moment(new Date(parseInt(_q.startDate)));
            setCurrentStartDate(incominStarDate)
            _startDate = incominStarDate;
            parameters.startTime = incominStarDate.toISOString();
        }

        let _endDate = currentEndDate;
        if (_q.endDate && '' !== _q.endDate && _q.endDate > 1) {
            let incominEndDate = moment(new Date(parseInt(_q.endDate)));
            setCurrentEndDate(incominEndDate);
            _endDate = incominEndDate;
            parameters.endTime = incominEndDate.toISOString();
        }

        if (_q.search && _q.search.trim() !== '') {
            parameters.search = `and (concat_ws(',',if(device_id IS NULL,'',device_id),if(deviceAlias IS NULL,'',deviceAlias),if(level IS NULL,'',level),if(deviceAlias IS NULL,'',deviceAlias),
            if(description IS NULL,'',description),if(source IS NULL,'',source)) ILIKE '%${_q.search}%' OR  b_metadata ILIKE '%${_q.search}%')`
            setSearchValue(_q.search);
        }

        if (!_startDate.isBefore(_endDate)) {
            return;
        }
        setParameters(parameters);
        getAgentHubEventFilters(parameters);
        getAgentHubEvents(parameters);
    }

    const searchBoxKeyDown = (event: any) => {
        if (event && event.key === ENTER_KEY_CODE) {
            doSearch(searchValue);
        }
    }

    const doSearch = (value: string) => {
        let params: any = getDecodeURI(location.search);
        value ? params.search = value : delete params.search;
        delete params.page;
        doNavigate(params);
        setParameters(params);
    }

    const handlePerPageChange = (e: any) => {
        let params: any = getDecodeURI(location.search);
        const value = parseInt(e.target.value);
        params.page = 0;
        params.limit = value;
        doNavigate(params);
        setParameters(params);
    }

    const handlePagination = (e: any, value: number) => {
        let params: any = getDecodeURI(location.search);
        params.page = value - 1;
        doNavigate(params);
        setParameters(params);
    }

    const onApplyFilter = (selecedEventFilters) => {
        let params: any = getDecodeURI(location.search);
        params.filter = JSON.stringify(selecedEventFilters);
        delete params.page;
        doNavigate(params);
        setParameters(params);
    }

    const handleDownloadReport = () => {
        const vesselMap: any[] = vesselIds?.map((item: any) => item["id"])
        const payload = {
            startTime: currentStartDate?.toISOString(),
            endTime: currentEndDate?.toISOString(),
            pageSize: total,
            offset: 0,
            deviceIdFilter: "",
            moduleFilter: "",
            levelFilter: "",
            sourceFilter: "",
            serviceFilter: "",
            search: "",
            dp_id: authReducer?.selectedOu?.id,
        }
        if (_q.filter && '' !== _q.filter.trim()) {
            const filters = JSON.parse(_q.filter);
            payload.moduleFilter = filters.modules.length > 0 ? `and module IN (${filters.modules.map(m => `'${m}'`).join()})` : '';
            payload.deviceIdFilter = filters.devices.length > 0 ? `and device_id IN (${filters.devices.map(d => `'${d}'`).join()})` : '';
            payload.levelFilter = filters.levels.length > 0 ? `and level IN (${filters.levels.map(l => `'${l}'`).join()})` : '';
            payload.sourceFilter = filters.sources.length > 0 ? `and source IN (${filters.sources.map(s => `'${s}'`).join()})` : '';
            payload.serviceFilter = filters.services.length > 0 ? `and service IN (${filters.services.map(s => `'${s}'`).join()})` : '';
        }
        if (_q.search) {
            payload.search = `and (concat_ws(',',if(device_id IS NULL,'',device_id),if(deviceAlias IS NULL,'',deviceAlias),if(level IS NULL,'',level),if(deviceAlias IS NULL,'',deviceAlias),
            if(description IS NULL,'',description),if(source IS NULL,'',source)) ILIKE '%${_q.search}%' OR  b_metadata ILIKE '%${_q.search}%')`
        }
        const params: CsvParameters = {
            type: 'QUERY_CH',
            id: 'global-agent-events',
            payload,
            queryName: 'AGENT_EVENTS_DASHBOARD_V2',
        }
        downloadAsCSV(params);
    }

    const handleOnSearchClick = () => {
        doSearch(searchValue);
    }

    const handleOnSearchClear = () => {
        setSearchValue('');
        doSearch('');
    }

    const handleOnSearchChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setSearchValue(e.target.value);
        if (searchTimeoutRef.current) {
            clearTimeout(searchTimeoutRef.current);
        }
        searchTimeoutRef.current = setTimeout(() => {
            searchTimeoutRef.current = undefined;
            doSearch(e.target.value);
        }, 1000)
    }

    return (
        <Fragment><StyledEngineProvider injectFirst>
            <Grid container className="container-edgeEvent edgeEventsPadding" padding={3}>
                <Grid container item xs={12} sm={12} md={12} lg={12} className="topbarWidth alignTopbarItemsCenter">
                    <Grid item xs={1} sm={1} md={1} lg={1} className="filterButtonMaxWidth">
                        <Button variant="outlined" startIcon={<TuneIcon id="filterIcon" />} onClick={() => setMoreFiltersShowPopUP(true)} id="filterButton">
                            Filter
                        </Button>
                    </Grid>
                    <Grid item xs={3} sm={3} md={3} lg={3} className="searchbarMaxWidth1">
                        <Box className="searchbarBox">
                            {<TextField
                                id="outlined-basic"
                                variant="outlined"
                                placeholder="Type something"
                                size="small"
                                autoComplete="off"
                                value={searchValue}
                                onChange={handleOnSearchChange}
                                onKeyDown={(event) => searchBoxKeyDown(event)}
                                InputLabelProps={{ className: "serachLabel" }}
                                InputProps={{
                                    className: "searchbarInputs",
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            {!searchValue && <SearchIcon
                                                className="searchbarIconsPosition"
                                                onClick={handleOnSearchClick}
                                            />}
                                            {searchValue && <CloseIcon
                                                className="searchbarIconsPosition"
                                                onClick={handleOnSearchClear}
                                            />}
                                        </InputAdornment>
                                    ),
                                }}
                            />}
                        </Box>
                    </Grid>
                    {total !== 0 && <Grid item style={{ marginLeft: "auto" }}>
                        <div className="download-summary-btn" onClick={() => handleDownloadReport()}><img className="downloadImg" src={Download} alt="" /><Button>Download</Button></div>
                    </Grid>}
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Box>
                        <Pagination
                            rowsPerPageOptions={NEW_PER_PAGE_ITEMS}
                            count={total ? total : 0}
                            rowsPerPage={limit}
                            page={page + 1}
                            onPageChange={handlePagination}
                            onRowsPerPageChange={handlePerPageChange}
                        />
                    </Box>
                    <TableContainer className="alertsTable-FleetDashboard">
                        <Table aria-label="simple table" >
                            <TableHead className="tableHead alertsTable-tableHead">
                                <TableRow className="alertReports-tableRow">
                                    <TableCell className="alertsTable-fleetDataTable font-wt-900 width-5">TIMESTAMP</TableCell>
                                    <TableCell align="left" className="alertsTable-fleetDataTable font-wt-900 agent-width-10">ORGANIZATION</TableCell>
                                    <TableCell align="left" className="alertsTable-fleetDataTable font-wt-900 agent-width-10">SITE NAME</TableCell>
                                    <TableCell align="left" className="alertsTable-fleetDataTable font-wt-900 agent-width-10">DID</TableCell>
                                    <TableCell align="left" className="alertsTable-fleetDataTable font-wt-900 agent-width-10">SOURCE</TableCell>
                                    <TableCell align="left" className="alertsTable-fleetDataTable font-wt-900 agent-width-10">SERVICE</TableCell>
                                    <TableCell align="left" className="alertsTable-fleetDataTable font-wt-900 agent-width-10">LEVEL</TableCell>
                                    <TableCell align="left" className="alertsTable-fleetDataTable font-wt-900 agent-width-10">MODULE</TableCell>
                                    <TableCell align="left" className="alertsTable-fleetDataTable font-wt-900 agent-width-20">DESCRIPTION</TableCell>
                                    <TableCell className="alertsTable-fleetDataTable font-wt-900 width-5">METADATA</TableCell>
                                </TableRow>
                            </TableHead>
                            {hubEvents?.length > 0 &&
                                <TableBody className="tableBody alertsTable-tableBody">
                                    {
                                        hubEvents?.length > 0 && hubEvents.map((row) =>
                                            <TableRow className="alertsData-tabelRow">
                                                <TableCell className="alertsTable-tableCell">
                                                    {convertDateTimeIntoTimezone(row['ts'], authReducer.userTimezone, MMDDYYYYHMMSS_DATE_FORMAT_24_HRS)}
                                                </TableCell>
                                                <Tooltip title={<span style={{ fontSize: "12px" }}>{row['dp_name']}</span>}>
                                                    <TableCell className="events-description-width agent-padding-left-5" >{row['dp_name']}</TableCell>
                                                </Tooltip>
                                                <Tooltip title={<span style={{ fontSize: "12px" }}>{row['sitename']}</span>}>
                                                    <TableCell className="events-description-width agent-padding-left-5" >{row['sitename'] || "-"}</TableCell>
                                                </Tooltip>
                                                <Tooltip title={<span style={{ fontSize: "12px" }}>{row['device_id']}</span>}>
                                                    <TableCell className="events-description-width agent-padding-left-5" >{row['device_id']}</TableCell>
                                                </Tooltip>
                                                <TableCell className="alertsTable-tableCell alertsTable-tableCell-source agent-padding-left-5" >{row['source']}</TableCell>
                                                <TableCell className="alertsTable-tableCell alertsTable-tableCell-source agent-padding-left-5" >{row['service']}</TableCell>
                                                <TableCell className="alertsTable-tableCell agent-padding-left-5" >{row['level']}</TableCell>
                                                <TableCell className="alertsTable-tableCell agent-padding-left-5 agent-padding-right-5" >{row['module']}</TableCell>
                                                <Tooltip title={<span style={{ fontSize: "12px" }}>{row['description']}</span>}>
                                                    <TableCell className="events-description-width">{row['description']}</TableCell>
                                                </Tooltip>
                                                <TableCell className="alertsTable-tableCell labelDetails">
                                                    <JsonDataTooltip data={row['metadata']} />
                                                </TableCell>
                                            </TableRow>
                                        )
                                    }
                                </TableBody>}
                            {(hubEvents?.length === 0) && <TableBody>
                                <TableRow>
                                    <TableCell colSpan={12} className="noDataAvailableCss" align="center">No data available</TableCell>
                                </TableRow>
                            </TableBody>}

                        </Table>
                    </TableContainer>
                </Grid>

            </Grid>
            <HubEventFilter open={showMoreFilterPopup} edgeEventsFitler={edgeEventsFitler} onClose={() => { setMoreFiltersShowPopUP(false) }} onApplyFilter={onApplyFilter} selectedEventFilters={selectedEventFilters} globalEvents={true}></HubEventFilter>
        </StyledEngineProvider></Fragment>);
}

const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
    errorReducer: state.errorReducer,
    startDate: state?.authReducer?.newSummaryStartDate,
    endDate: state?.authReducer?.newSummaryEndDate,
});

export default withRouter(
    connect(mapStateToProps, {
        getEdgeEventsDashBoard,
        getEdgeEventsFilterDashboard,
        downloadEdgeEventsTableDashbOard,
        downloadAsCSV,
        clearEdgeEventsDownload,
        getAgentHubEvents,
        getAgentHubEventFilters
    })(EdgeEventsGlobal)
);