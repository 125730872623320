import { api } from "../../server/request";
import { handleResponse, handleError } from "../responseUtil";
import {
    SET_SITE_CHARTS_RSSI_LOADING,
    SET_SITE_CHARTS_SINR_LOADING,
    SET_SITE_CHARTS_LINK_STATUS_LOADING,
    SET_SITE_CHARTS_LATENCY_JITTER_LOADING,
    SET_SITE_CHARTS_THROUGHPUT_LOADING,
    SET_SITE_CHARTS_VSAT_SNR_LOADING,
    SET_SITE_CHARTS_VSAT_TXPOWER_LOADING,
    SET_SITE_CHARTS_VSAT_POWER_LOADING,
    SET_SITE_CHARTS_VSAT_SYMBOL_RATE_LOADING,
    SET_SITE_CHARTS_SITE_INTERFACES_LOADING,
    SET_SITE_CHARTS_SITE_DATA_RATE_LOADING,
    SET_LOADING, GET_SITE_RSSI, GET_SITE_SINR, GET_SITE_DATA_RATE, GET_SITE_LINK_STATUS, GET_SITE_LATENCY_JITTER, GET_SITE_INTERFACES, CLEAR_SITE_CHARTS, DELETE_SITE_CHART_SERIES, CLEAR_SITE_CHART_EVENTS, GET_SITE_THROUGHPUT, GET_SITE_VSAT_SNR, GET_SITE_VSAT_TXPOWER, GET_SITE_VSAT_POWER, GET_SITE_VSAT_SYMBOL_RATE,
    SET_SITE_CLIENTS_LOADING,
    GET_SITE_CLIENTS,
    SET_SITE_USAGE_LOADING,
    GET_SITE_USAGE,
    SET_SITE_SYSTEM_UPTIME_LOADING,
    GET_SITE_SYSTEM_UPTIME,
    SET_SITE_LAN_STATUS_LOADING,
    GET_SITE_LAN_STATUS,
    SET_HUB_CLIENT_SDWAN_FLOWS_LOADING,
    GET_HUB_CLIENT_SDWAN_FLOWS,
    SET_HUB_CLIENT_SDWAN_SPEED_LOADING,
    GET_HUB_CLIENT_SDWAN_SPEED,
    SET_HUB_CLIENT_SDWAN_LATENCY_LOADING,
    GET_HUB_CLIENT_SDWAN_LATENCY,
    SET_HUB_CLIENT_SDWAN_STATUS_LOADING,
    GET_HUB_CLIENT_SDWAN_STATUS,
    SET_SDWAN_SPEEDTEST_LOADING,
    GET_SDWAN_SPEEDTEST,
    SET_SITE_LINK_PROBE_NATIVE_LOADING,
    GET_SITE_LINK_PROBE_NATIVE,
    SET_SITE_LINK_PROBE_VTP_LOADING,
    GET_SITE_LINK_PROBE_VTP,
    SET_SDWAN_QOE_LOADING,
    GET_SDWAN_QOE,
    SET_SDWAN_QOE_HMAP_LOADING,
    GET_SDWAN_QOE_HMAP
} from "../types";
import { MetricTypes } from "../../UserScreen/CreateAlert/types";

interface ChartsQuery {
    query: string;
    named?: boolean;
    format?: string;
    groupBy?: string;
    tsColumn?: string;
    series?: string;
    parameters?: ChartsQueryParameters;
    annotation?: string;
}

export interface ChartsQueryParameters {
    device_filter: string;
    startTime: string;
    endTime: string;
    locationId: string;
    chartInterval: string;
    device_interface_filter?: string;
    vsat_info_attribute?: string;
    iot_info_attribute?: string;
    intervalNum?: string;
    interval?: string;
}

export const getSiteRSSI = (parameters: ChartsQueryParameters) => (dispatch) => {
    dispatch({ type: SET_SITE_CHARTS_RSSI_LOADING, payload: true });
    let isClickHouse = true;
    const apiUrl = isClickHouse ? `/store/ch/query` : `/store/ss/query`
    const data: ChartsQuery = {
        query: `SITE_RSSI`,
        named: true,
        format: 'chart',
        groupBy: "interfaceDeviceAlias",
        tsColumn: "tb",
        series: "rssiAvg",
        annotation: "signalType,provider",
        parameters: parameters
    }
    api
        .postRequestOut(`${apiUrl}`, data)
        .then((res) => handleResponse(res, dispatch, GET_SITE_RSSI))
        .catch((err) => handleError(err, dispatch));
};

export const getSiteSINR = (parameters: ChartsQueryParameters) => (dispatch) => {
    let isClickHouse = true;
    const apiUrl = isClickHouse ? `/store/ch/query` : `/store/ss/query`
    dispatch({ type: SET_SITE_CHARTS_SINR_LOADING, payload: true });
    const data: ChartsQuery = {
        query: `SITE_SINR`,
        named: true,
        format: 'chart',
        groupBy: "interfaceDeviceAlias",
        tsColumn: "tb",
        series: "sinrAvg",
        annotation: "signalType,provider",
        parameters: parameters
    }
    api
        .postRequestOut(`${apiUrl}`, data)
        .then((res) => handleResponse(res, dispatch, GET_SITE_SINR))
        .catch((err) => handleError(err, dispatch));
};

export const getSiteDataRate = (parameters: ChartsQueryParameters) => (dispatch) => {
    let isClickHouse = true;
    const apiUrl = isClickHouse ? `/store/ch/query` : `/store/ss/query`
    dispatch({ type: SET_SITE_CHARTS_SITE_DATA_RATE_LOADING, payload: true });
    const data: ChartsQuery = {
        query: `SITE_PRE`,
        named: true,
        format: 'chart',
        groupBy: "interfaceDeviceAlias",
        tsColumn: "tb",
        series: "rateAvg",
        parameters: parameters
    }
    api
        .postRequestOut(`${apiUrl}`, data)
        .then((res) => handleResponse(res, dispatch, GET_SITE_DATA_RATE))
        .catch((err) => handleError(err, dispatch));
};

export const getSiteLinkStatus = (parameters: ChartsQueryParameters) => (dispatch) => {
    dispatch({ type: SET_SITE_CHARTS_LINK_STATUS_LOADING, payload: true });
    let isClickHouse = true;
    const apiUrl = isClickHouse ? `/store/ch/query` : `/store/ss/query`
    // here
    const data: ChartsQuery = {
        query: `SITE_LINK_STATUS`,
        named: true,
        format: 'chart',
        groupBy: "interfaceDeviceAlias",
        tsColumn: "tb",
        series: "probeSuccess",
        parameters: parameters
    }
    api
        .postRequestOut(`${apiUrl}`, data)
        .then((res) => handleResponse(res, dispatch, GET_SITE_LINK_STATUS))
        .catch((err) => handleError(err, dispatch));
};

export const getSiteLatencyJitter = (parameters: ChartsQueryParameters) => (dispatch) => {
    let isClickHouse = true;
    const apiUrl = isClickHouse ? `/store/ch/query` : `/store/ss/query`
    dispatch({ type: SET_SITE_CHARTS_LATENCY_JITTER_LOADING, payload: true });
    const data: ChartsQuery = {
        query: `SITE_RTT`,
        named: true,
        format: 'chart',
        groupBy: "interfaceDeviceAlias",
        tsColumn: "tb",
        series: "latency,jitter",
        parameters: parameters
    }
    api
        .postRequestOut(`${apiUrl}`, data)
        .then((res) => handleResponse(res, dispatch, GET_SITE_LATENCY_JITTER))
        .catch((err) => handleError(err, dispatch));
};

export const getSiteThroughput = (parameters: ChartsQueryParameters) => (dispatch) => {
    let isClickHouse = true;
    const apiUrl = isClickHouse ? `/store/ch/query` : `/store/ss/query`
    dispatch({ type: SET_SITE_CHARTS_THROUGHPUT_LOADING, payload: true });
    const data: ChartsQuery = {
        query: `SITE_THROUGHPUT`,
        named: true,
        format: 'chart',
        groupBy: "interfaceDeviceAlias",
        tsColumn: "tb",
        series: "avgDownSpeed,avgUpSpeed",
        parameters: parameters
    }
    api
        .postRequestOut(`${apiUrl}`, data)
        .then((res) => handleResponse(res, dispatch, GET_SITE_THROUGHPUT))
        .catch((err) => handleError(err, dispatch));
};

export const getSiteVSATSnr = (parameters: ChartsQueryParameters) => (dispatch) => {
    let isClickHouse = true;
    const apiUrl = isClickHouse ? `/store/ch/query` : `/store/ss/query`
    dispatch({ type: SET_SITE_CHARTS_VSAT_SNR_LOADING, payload: true });
    parameters.vsat_info_attribute = 'snr'
    const data: ChartsQuery = {
        query: `SITE_VSAT_INFO`,
        named: true,
        format: 'chart',
        groupBy: "interfaceDeviceAlias",
        tsColumn: "tb",
        series: "avg",
        annotation: "provider",
        parameters: parameters
    }
    api
        .postRequestOut(`${apiUrl}`, data)
        .then((res) => handleResponse(res, dispatch, GET_SITE_VSAT_SNR))
        .catch((err) => handleError(err, dispatch));
};

export const getSiteVSATTxpower = (parameters: ChartsQueryParameters) => (dispatch) => {
    let isClickHouse = true;
    const apiUrl = isClickHouse ? `/store/ch/query` : `/store/ss/query`
    dispatch({ type: SET_SITE_CHARTS_VSAT_TXPOWER_LOADING, payload: true });
    parameters.vsat_info_attribute = 'txpower'
    const data: ChartsQuery = {
        query: `SITE_VSAT_INFO`,
        named: true,
        format: 'chart',
        groupBy: "interfaceDeviceAlias",
        tsColumn: "tb",
        series: "avg",
        annotation: "provider",
        parameters: parameters
    }
    api
        .postRequestOut(`${apiUrl}`, data)
        .then((res) => handleResponse(res, dispatch, GET_SITE_VSAT_TXPOWER))
        .catch((err) => handleError(err, dispatch));
};

export const getSiteVSATPower = (parameters: ChartsQueryParameters) => (dispatch) => {
    let isClickHouse = true;
    const apiUrl = isClickHouse ? `/store/ch/query` : `/store/ss/query`
    dispatch({ type: SET_SITE_CHARTS_VSAT_POWER_LOADING, payload: true });
    parameters.vsat_info_attribute = 'power'
    const data: ChartsQuery = {
        query: `SITE_VSAT_INFO`,
        named: true,
        format: 'chart',
        groupBy: "interfaceDeviceAlias",
        tsColumn: "tb",
        series: "avg",
        annotation: "provider",
        parameters: parameters
    }
    api
        .postRequestOut(`${apiUrl}`, data)
        .then((res) => handleResponse(res, dispatch, GET_SITE_VSAT_POWER))
        .catch((err) => handleError(err, dispatch));
};

export const getSiteVSATSymbolRate = (parameters: ChartsQueryParameters) => (dispatch) => {
    let isClickHouse = true;
    const apiUrl = isClickHouse ? `/store/ch/query` : `/store/ss/query`
    dispatch({ type: SET_SITE_CHARTS_VSAT_SYMBOL_RATE_LOADING, payload: true });
    parameters.vsat_info_attribute = 'symbol_rate'
    const data: ChartsQuery = {
        query: `SITE_VSAT_INFO`,
        named: true,
        format: 'chart',
        groupBy: "interfaceDeviceAlias",
        tsColumn: "tb",
        series: "avg",
        annotation: "provider",
        parameters: parameters
    }
    api
        .postRequestOut(`${apiUrl}`, data)
        .then((res) => handleResponse(res, dispatch, GET_SITE_VSAT_SYMBOL_RATE))
        .catch((err) => handleError(err, dispatch));
};

export const getSiteClients = (parameters: ChartsQueryParameters) => (dispatch) => {
    let isClickHouse = true;
    const apiUrl = isClickHouse ? `/store/ch/query` : `/store/ss/query`
    dispatch({ type: SET_SITE_CLIENTS_LOADING, payload: true });
    const data: ChartsQuery = {
        query: `SITE_CLIENTS`,
        named: true,
        format: 'chart',
        groupBy: "deviceID",
        tsColumn: "tb",
        series: "clients",
        parameters: parameters
    }
    api
        .postRequestOut(`${apiUrl}`, data)
        .then((res) => handleResponse(res, dispatch, GET_SITE_CLIENTS))
        .catch((err) => handleError(err, dispatch));
};

export const getSiteUsage = (parameters: ChartsQueryParameters) => (dispatch) => {
    let isClickHouse = true;
    const apiUrl = isClickHouse ? `/store/ch/query` : `/store/ss/query`
    dispatch({ type: SET_SITE_USAGE_LOADING, payload: true });
    const data: ChartsQuery = {
        query: `SITE_USAGE`,
        named: true,
        format: 'chart',
        groupBy: "interfaceDeviceAlias",
        tsColumn: "tb",
        series: "upUsage,downUsage,totalUsage",
        parameters: parameters
    }
    api
        .postRequestOut(`${apiUrl}`, data)
        .then((res) => {console.log('inside res', res)
            handleResponse(res, dispatch, GET_SITE_USAGE)})
        .catch((err) => handleError(err, dispatch));
};

export const getSiteSystemUptime = (parameters: ChartsQueryParameters) => (dispatch) => {
    let isClickHouse = true;
    const apiUrl = isClickHouse ? `/store/ch/query` : `/store/ss/query`
    dispatch({ type: SET_SITE_SYSTEM_UPTIME_LOADING, payload: true });
    const data: ChartsQuery = {
        query: `SITE_SYSTEM_UPTIME`,
        named: true,
        format: 'chart',
        groupBy: "site",
        tsColumn: "tb",
        series: "status",
        parameters: parameters
    }
    api
        .postRequestOut(`${apiUrl}`, data)
        .then((res) => handleResponse(res, dispatch, GET_SITE_SYSTEM_UPTIME))
        .catch((err) => handleError(err, dispatch));
};

export const getSiteLanStatus = (parameters: ChartsQueryParameters) => (dispatch) => {
    let isClickHouse = true;
    const apiUrl = isClickHouse ? `/store/ch/query` : `/store/ss/query`
    dispatch({ type: SET_SITE_LAN_STATUS_LOADING, payload: true });
    const lanStatus: ChartsQuery = {
        query: `SITE_LAN_STATUS`,
        named: true,
        format: 'chart',
        groupBy: "deviceID",
        tsColumn: "tb",
        series: "LAN_status",
        parameters: parameters
    }
    api
        .postRequestOut(`${apiUrl}`, lanStatus)
        .then((res) => handleResponse(res, dispatch, GET_SITE_LAN_STATUS))
        .catch((err) => handleError(err, dispatch));
};

export const getSiteFlows = (parameters: ChartsQueryParameters) => (dispatch) => {
    const apiUrl = `/store/ch/query`;
    dispatch({ type: SET_HUB_CLIENT_SDWAN_FLOWS_LOADING, payload: true });
    const flows: ChartsQuery = {
        query: `HUB_CLIENT_SDWAN_FLOWS`,
        named: true,
        format: 'chart',
        groupBy: "interfaceDeviceAlias",
        tsColumn: "tb",
        series: "flows",
        parameters: parameters
    }
    api
        .postRequestOut(`${apiUrl}`, flows)
        .then((res) => handleResponse(res, dispatch, GET_HUB_CLIENT_SDWAN_FLOWS))
        .catch((err) => handleError(err, dispatch));
};

export const getSiteSpeed = (parameters: ChartsQueryParameters) => (dispatch) => {
    const apiUrl = `/store/ch/query`;
    dispatch({ type: SET_HUB_CLIENT_SDWAN_SPEED_LOADING, payload: true });
    const speed: ChartsQuery = {
        query: `HUB_CLIENT_SDWAN_SPEED`,
        named: true,
        format: 'chart',
        groupBy: "interfaceDeviceAlias",
        tsColumn: "tb",
        series: "avgDownSpeed,avgUpSpeed",
        parameters: parameters
    }
    api
        .postRequestOut(`${apiUrl}`, speed)
        .then((res) => handleResponse(res, dispatch, GET_HUB_CLIENT_SDWAN_SPEED))
        .catch((err) => handleError(err, dispatch));
};

export const getSiteLatency = (parameters: ChartsQueryParameters) => (dispatch) => {
    const apiUrl = `/store/ch/query`;
    dispatch({ type: SET_HUB_CLIENT_SDWAN_LATENCY_LOADING, payload: true });
    const latency: ChartsQuery = {
        query: `HUB_CLIENT_SDWAN_LATENCY`,
        named: true,
        format: 'chart',
        groupBy: "interfaceDeviceAlias",
        tsColumn: "tb",
        series: "latency",
        parameters: parameters
    }
    api
        .postRequestOut(`${apiUrl}`, latency)
        .then((res) => handleResponse(res, dispatch, GET_HUB_CLIENT_SDWAN_LATENCY))
        .catch((err) => handleError(err, dispatch));
};

export const getSiteStatus = (parameters: ChartsQueryParameters) => (dispatch) => {
    const apiUrl = `/store/ch/query`;
    dispatch({ type: SET_HUB_CLIENT_SDWAN_STATUS_LOADING, payload: true });
    const latency: ChartsQuery = {
        query: `HUB_CLIENT_SDWAN_STATUS`,
        named: true,
        format: 'chart',
        groupBy: "interfaceDeviceAlias",
        tsColumn: "tb",
        series: "status",
        parameters: parameters
    }
    api
        .postRequestOut(`${apiUrl}`, latency)
        .then((res) => handleResponse(res, dispatch, GET_HUB_CLIENT_SDWAN_STATUS))
        .catch((err) => handleError(err, dispatch));
};

export const getSiteSpeedtest = (parameters: ChartsQueryParameters) => (dispatch) => {
    const apiUrl = `/store/ch/query`;
    dispatch({ type: SET_SDWAN_SPEEDTEST_LOADING, payload: true });
    const speedtest: ChartsQuery = {
        query: `SDWAN_SPEEDTEST`,
        named: true,
        format: 'csv',
        parameters: parameters
    }
    api
        .postRequestOut(`${apiUrl}`, speedtest)
        .then((res) => handleResponse(res, dispatch, GET_SDWAN_SPEEDTEST))
        .catch((err) => handleError(err, dispatch));
};

export const getSiteLinkProbeNative = (parameters: ChartsQueryParameters) => (dispatch) => {
    const apiUrl = `/store/ch/query`;
    dispatch({ type: SET_SITE_LINK_PROBE_NATIVE_LOADING, payload: true });
    const probeNative: ChartsQuery = {
        named: true,
        query: `SITE_LINK_PROBE`,
        format: 'chart',
        groupBy: "interfaceDeviceAlias",
        tsColumn: "tb",
        series: "probeSuccess",
        parameters: parameters
    }
    api
        .postRequestOut(`${apiUrl}`, probeNative)
        .then((res) => handleResponse(res, dispatch, GET_SITE_LINK_PROBE_NATIVE))
        .catch((err) => handleError(err, dispatch));
};

export const getSiteLinkProbeVtp = (parameters: ChartsQueryParameters) => (dispatch) => {
    const apiUrl = `/store/ch/query`;
    dispatch({ type: SET_SITE_LINK_PROBE_VTP_LOADING, payload: true });
    const probeVtp: ChartsQuery = {
        named: true,
        query: `SITE_LINK_PROBE`,
        format: 'chart',
        groupBy: "interfaceDeviceAlias",
        tsColumn: "tb",
        series: "probeSuccess",
        parameters: parameters
    }
    api
        .postRequestOut(`${apiUrl}`, probeVtp)
        .then((res) => handleResponse(res, dispatch, GET_SITE_LINK_PROBE_VTP))
        .catch((err) => handleError(err, dispatch));
};

export const getSiteQoe = (parameters: ChartsQueryParameters) => (dispatch) => {
    const apiUrl = `/store/ch/query`;
    dispatch({ type: SET_SDWAN_QOE_LOADING, payload: true });
    const qoeData: ChartsQuery = {
        named: true,
        query: `SDWAN_QoE`,
        format: 'chart',
        groupBy: "interfaceDeviceAlias",
        tsColumn: "tb",
        series: "QoEStreamingPoints,QoEGamingPoints",
        "annotation": "QoEStreaming,QoEGaming",
        parameters: parameters
    }
    api
        .postRequestOut(`${apiUrl}`, qoeData)
        .then((res) => handleResponse(res, dispatch, GET_SDWAN_QOE))
        .catch((err) => handleError(err, dispatch));
};

export const getSiteQoeHmap = (parameters: ChartsQueryParameters) => (dispatch) => {
    const apiUrl = `/store/ch/query`;
    dispatch({ type: SET_SDWAN_QOE_HMAP_LOADING, payload: true });
    const gamingData: ChartsQuery = {
        named: true,
        query: `SDWAN_QoE_HMap`,
        format: 'csv',
        groupBy: "interfaceDeviceAlias",
        parameters: parameters
    }
    api
        .postRequestOut(`${apiUrl}`, gamingData)
        .then((res) => handleResponse(res, dispatch, GET_SDWAN_QOE_HMAP))
        .catch((err) => handleError(err, dispatch));
};

interface SiteInterfaceQueryParameters {
    locationId: string;
}

export const getSiteInterfaces = (parameters: SiteInterfaceQueryParameters) => (dispatch) => {
    let isClickHouse = true;
    const apiUrl = isClickHouse ? `/store/ch/query` : `/store/ss/query`
    dispatch({ type: SET_SITE_CHARTS_SITE_INTERFACES_LOADING, payload: true });
    const data = {
        query: `SITE_INTERFACES`,
        named: true,
        format: 'csv',
        parameters: parameters
    }
    api
        .postRequestOut(`${apiUrl}`, data)
        .then((res) => handleResponse(res, dispatch, GET_SITE_INTERFACES))
        .catch((err) => handleError(err, dispatch));
};

export const clearSiteCharts = (metrics: string[]) => (dispatch) => {
    dispatch({ type: CLEAR_SITE_CHARTS, payload: metrics});
}

export const deleteSiteChartSeries = (seriesKey: string) => (dispatch) => {
    dispatch({ type: DELETE_SITE_CHART_SERIES, payload: seriesKey});
}

export const clearSiteChartEvents = () => (dispatch) => {
    dispatch({ type: CLEAR_SITE_CHART_EVENTS});
}

interface MetricInfo {query: string, series: string, valueUnit: string, iot_info_attribute?: string}

export const MetricQueries: { [key: string]: MetricInfo;} = {
    "sinr": {
        query: "SITE_SINR",
        series: "sinrAvg",
        valueUnit: "dB"
    },
    "rssi": {
        query: "SITE_RSSI",
        series: "rssiAvg",
        valueUnit: "dBm"

    },
    "latency": {
        query: "SITE_RTT",
        series: "latency",
        valueUnit: "ms"

    },
    "jitter": {
        query: "SITE_RTT",
        series: "jitter",
        valueUnit: "ms"

    },
    "peak_rate_estimate": {
        query: "SITE_PRE",
        series: "rateAvg",
        valueUnit: "Mbps"

    },
    "link_status": {
        query: "SITE_LINK_STATUS",
        series: "probeSuccess",
        valueUnit: "%"
    },
    "iot_temperature": {
        query: "TEST_SITE_IOT_INFO",
        series: "avg",
        iot_info_attribute: "temperature",
        valueUnit: "°C"
    },
}

export const getMetricData = (parameters: ChartsQueryParameters, metric: string) => async (dispatch) => {
        let isClickHouse = true;
        const apiUrl = isClickHouse ? `/store/ch/query` : `/store/ss/query`
    dispatch({ type: SET_LOADING, payload: true });
    let metricInfo: MetricInfo = MetricQueries[metric];

    if (metricInfo.iot_info_attribute) {
        parameters = {
            ...parameters,
            iot_info_attribute: metricInfo.iot_info_attribute
        }
    }
    const data: ChartsQuery = {
        query: metricInfo.query,
        named: true,
        format: 'chart',
        groupBy: "interfaceDeviceAlias",
        tsColumn: "tb",
        series: metricInfo.series,
        parameters: parameters
    }

    try {
        let res = await api.postRequestOut(`${apiUrl}`, data)
        dispatch({ type: SET_LOADING, payload: false })
        return res
    } catch (error) {
        dispatch({ type: SET_LOADING, payload: false })
        console.log(error);
        return null
    }
}