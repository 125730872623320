import { useState, useEffect } from "react";
import MapLeaflet from "../../components/Map/MapLeafLet";
import MapVoyage from "../../components/Map/MapVoyageLeaflet";
import '../../components/Map/Map.css';
import { connect, useDispatch } from 'react-redux';
import { setAllMapErrors } from "../../actions/fetchData";
import ChartDetails from './vesselChart';
import HexDetails from './hexRightPaneDetails';
import Box from '@mui/material/Box';
import './TowerVesselDetails.css';
import { Grid, IconButton, Tab, Tabs } from "@mui/material";
import Tooltip from '@material-ui/core/Tooltip';
import moment from "moment";
import { getDecodeURI, getEncodedURI, getSingleRecordChartLable, getDateRangeLablePerVessel, getDateInISo } from "../../util/util";
import { useHistory, useLocation } from "react-router-dom";
import {kmlNames} from '../../components/Map/BeamData';
import TowerJson from '../../components/Map/towers.json'
import ReactDOM from "react-dom";
import {cloneDeep} from "lodash";
import { SET_ENV } from "../../actions/actionType";
import VesselRedIc from '../../components/Map/images/vesselRed.svg';
import VesselBlueIc from '../../components/Map/images/vesselBlue.svg';
import VesselGreyIc from '../../components/Map/images/vesselGreyIc.svg'
import TerminalBlueIc from '../../components/Map/images/terminalBlue.svg'
import TerminalRedIc from '../../components/Map/images/terminalRed.svg'
import { getSelectedPathVesselVoyage, getVesselVoyage, getTerminalLastLocation, getSelectedSiteServiceLineData, getSiteStarlinkCount, getVesselVoyageForStarlink } from "../../actions/singleStoreActions";
import { carrierGeoJson250sqkm,carrierGeoJson5sqkm,StarLinkCoverageWithFour,StartlinkCoverageWithThree,vsatCoverage } from "../../actions/cdnAction";
import {fetchVesselKpiDataSuccess, fetchVesselDataRateSuccess, fetchVesselDataRTTSuccess, fetchVesselCurrentLocationSuccess, fetchTerminalCurrentLocationSuccess, setEmptyVesselVoyage} from "../../actions/action";
import CloseIcon from '@material-ui/icons/Close';

type SiteMap = {
    [key: string]: string;
  };

const TowerVesselDetails = (props: any) => {

    const { newSummaryStartDate, newSummaryEndDate, userTimezone, siteChartsSiteInterfacesLoading, siteChartsThroughputLoading, siteChartsLinkStatusLoading, siteChartsSiteDataRateLoading, siteChartsSINRLoading, siteChartsLatencyJitterLoading, siteChartsRSSILoading, vesselList, selectedVessel, selectedOu, handleApiLoading, voaygeLoading, k4Ids, handleLastLocSelectedVessel, vesselLastLocLoading, apiErrorHandling, appEnv,edgeEventsData,authorizedVesselList, carrierData, carrierInnerLayerData, vsatCoverageData,isSpecialPrrmEnabled, starLinkData, starLinkSpeedData, carriersListLoading, carrierDataLoading, carrierInnerLayerDataLoading, vsatCoverageDataLoading, starLinkDataLoading, starLinkSpeedDataLoading, speedHistoDataLoading, rssiHistoDataLoading, sinrHistoDataLoading, vsatCarrierListLoading, starLinkLatencyHistoDetailsLoading, starLinkSpeedHistoDetailsLoading, starLinkDropPercentageHistoDetailsLoading, selctedVoyageDataLoading, getEdgeEventsLoading, getEdgeEventsFilterLoading, getEdgeEventsChartsLoading, terminalCurrentLocationDetails, terminalLastLocLoading , selectedSiteOrServiceLineData, siteSelectionOrServiceLineLoading , getServiceLineandSitesCount, starlinkServiceLines, handleIsMultiVessel,newSummaryServiceLine,handleStarlinkLoc,
        voyageData, selctedVoyageData, vesselCurrentLocationDetails, carriersList, vsatCarrierList,starlinkLocationData , flightPathDetails, flightEstimatedPathDetails
    } = props;
    console.log("starlinkLocationData",starlinkLocationData)
    let history = useHistory();
    let location = useLocation();
    let _q = getDecodeURI(location?.search);
    const [towerMap, setTowerMap] = useState<any>("");
    const [connectivity , setConnectivity] = useState([]);
    const [siteMap, setSiteMap] = useState<SiteMap>({});
    const [flightJourneyPoints, setFlightJourneyPoints] = useState([])
    const [flightEstimatedJourneyPoints, setFlightEstimatedJourneyPoints] = useState([])
    const [flightJourneyDetails, setFlightJourneyDetails] = useState({})
    const [locationMap, setLocationMap] = useState({});
      

    useEffect(() => {
        if(appEnv?.includes("dev")) {
            dispatch({type:SET_ENV,item:"DEV"})
        } else if(appEnv?.includes("stage")) {
            dispatch({type:SET_ENV,item:"STAGE"})
        } else if(appEnv?.includes("prod")) {
            dispatch({type:SET_ENV,item:"PROD"})
        } else if(appEnv?.includes("local")){
            dispatch({type:SET_ENV,item:"local"})
        }
    },[])

    const extractPoints = (data:any) => {
        return data
          .map((item:any) => {
            const latitude = parseFloat(item[1]); // Ensure it's a number
            const longitude = parseFloat(item[2]); // Ensure it's a number
      
            if (!isNaN(latitude) && !isNaN(longitude)) {
              return [latitude, longitude];
            }
            return null; // Mark invalid entries
          })
          .filter((point:any) => point !== null); // Remove invalid points
      };

    useEffect(() => {
        console.log("flightPathDetails",flightPathDetails)
        if(flightPathDetails && flightPathDetails.data && flightPathDetails.data?.rows?.length > 0 ) {

            let flightJourneyPoint = extractPoints(flightPathDetails?.data?.rows)
            setFlightJourneyPoints(flightJourneyPoint)
            let totalLen = flightPathDetails?.data?.rows?.length -1
            let lastKnownPositionObj = flightPathDetails?.data?.rows[totalLen]
            let firstKnownPositionObj = flightPathDetails?.data?.rows[0]
            let lastKnownLatLong = {"lat": lastKnownPositionObj[1],"lng":lastKnownPositionObj[2], "StartTime":firstKnownPositionObj[3],"EndTime":lastKnownPositionObj[3]}
            let FlightJourneyPosition = { "ponits":lastKnownLatLong }
            setFlightJourneyDetails(FlightJourneyPosition)
        }
    },[flightPathDetails])

    useEffect(() => {
        if(selectedOu?.id) {
            dispatch(getSiteStarlinkCount(selectedOu?.id))
        }
    },[selectedOu])

    useEffect(()=> {
        if(flightEstimatedPathDetails && flightEstimatedPathDetails.data && flightEstimatedPathDetails.data?.rows?.length > 0 ) {
            console.log("flightEstimatedPathDetails",flightEstimatedPathDetails)
            let flightEstimatedJourneyPoint = extractPoints(flightEstimatedPathDetails?.data?.rows)
            setFlightEstimatedJourneyPoints(flightEstimatedJourneyPoint)

            
        }
    },[flightEstimatedPathDetails])

    const [siteHoverDetails, setSiteHoverDetails] = useState("NA")
    const [starlinkHoverDetails, setStarlinkHoverDetails] = useState("NA")
    useEffect(() => {
        let siteDetails = getServiceLineandSitesCount &&  getServiceLineandSitesCount?.data  && getServiceLineandSitesCount?.data[0] ?   getServiceLineandSitesCount?.data[0] : {}
        let siteHoverTemp = "";
        let starlinkHoverTemp = ""
    
        if(siteDetails && siteDetails!== undefined && siteDetails !== "" && Object.keys(siteDetails) && Object.keys(siteDetails).length > 0 ) {
            console.log("siteDetails",Object.keys(siteDetails).length)
            siteHoverTemp =  Object.keys(siteDetails)?.length == 9  ? `Active Sites : ${siteDetails?.active_sites}, Online : ${siteDetails?.Online_site} , offline : ${siteDetails?.Offline_site} , Unknown: ${siteDetails?.Unknown_site} , Mapped : ${siteDetails?.sites_with_loc} ` : "";
            starlinkHoverTemp = Object.keys(siteDetails)?.length == 9 ? `Active Service Line : ${siteDetails?.active_starlink_terminal}, Online : ${siteDetails?.Online_starlink} , offline : ${siteDetails?.Offline_starlink} , Mapped : ${siteDetails?.starlink_with_loc} ` : "";
        }
        setSiteHoverDetails(siteHoverTemp)
        setStarlinkHoverDetails(starlinkHoverTemp)
    },[getServiceLineandSitesCount])

    useEffect(() => {
        let hashMap = new Map();
        if(TowerJson?.features) {
            TowerJson?.features.forEach((item, i) => {
                let jsonId: string = item?.properties?.id;
                hashMap.set(jsonId,item);
            })
            setTowerMap(hashMap);
        }
    },[])

    useEffect(() => {
        setSelectedSite(null);
        setSelectedServiceLine(null)
    },[k4Ids,selectedVessel, starlinkServiceLines])
   
    const [ouName, setOuName] = useState('');
    const dispatch = useDispatch();
    const [vesselStartTime, setVesselStartTime] = useState("");
    const [vesselEndTime, setVesselEndTime] = useState("");
    const [isPopUpOpen, setPopUpOpen] = useState(false);
    const [isHexPopUpOpen, setHexPopUpOpen] = useState(false);
    const [isChartClosed, setIsChartClosed] = useState(true);
    const [selectedCarrier, setSelectedCarrier] = useState("");
    const [selectedLayer, setSelectedLayer] = useState("")
    const [interval, setInterval] = useState('20m');
    const [isLayersSelOpen, setLayersSelOpen] = useState(true);
    const [uniqueCarrierList, setUniqueCarrierList] = useState<any>([]);
    const [uniqueVsatCarrierList, setUniqueVsatCarrierList] = useState<any>([]);
    const [isOffLayer, setIsOffLayer] = useState("Turn off All Layers")
    const [outerHexOpacity, setOuterHexOpacity] = useState(3);
    const [innerHexOpacity, setInnerHexOpacity] = useState(3);
    const [selectedLayersList, setSelectedLayersList] = useState<any>({ "Location": true, "Starlink(Latency)": false});
    const [hexContent, setHexContent] = useState(null);
    const [selctedPathStart, setSelectedPathStart] = useState("");
    const [slectedPathEnd, setSelectedPathEnd] = useState("");
    const [selectedH3, setSelectedH3] = useState("");
    const [isErrorPopUp, setIsErrorPopUp] = useState(false);
    const [isVsatDataSource, setVsatDataSource] = useState(false);
    const [selectedDataSource, setSelectedDataSource] = useState("STARLINK");
    const [ vesselTrackTimeOut, setVesselTrackTimeout] = useState(true);
    const [isChartWindowEnabled, setIsChartWindowEnabled] = useState(false);
    const [hexResolution, setHexResolution] = useState(0);
    const [selectedSite , setSelectedSite] = useState(null);
    const [selectedServiceLine , setSelectedServiceLine] = useState(null);
    const [isStarlinkMicro, setStarlinkMicro] = useState(false)
    const [isStarlinkMacro, setStarlinkMacro] = useState(false)
    const [isStarlinkLocation, setIsStarlinkLocation] = useState(false);
    const [isLteMicro, setLteMicro] = useState(false);
    const [isLteMacro, setLteMacro] = useState(false);
    const [edgeVal, setEdgeVal] = useState("true")
    const [starlinkVal, setStarlinkVal] = useState("false");
    const [isEdgeStarlink, setEdgeStarlink] = useState("true")
    const [isHeatMaps, setHeatMaps] =useState(false)
    const [selctedK4Id, setSelectedK4Id] = useState("")
    const [selectedTabId,setselectedTabId] = useState("")
    const [selectedTabName, setSelectedTabName] = useState("")
    const [tabValue, setTabValue] = useState(0);
    const [isStarlinkSourceLoc , setIsStarlinkSourceLoc] = useState(false)
    const [flashingTab , setFlashingTab] = useState(null)
    let defaultLayers = {
        "Starlink_Macro": "12393",
        "Starlink_Micro": null,
        "selectedSource": "Starlink",
        "Starlink_provider": null,
        "Starlink_Latency": true,
        "Starlink_Speed": null,
        "Starlink_DropPercentage": null,
        "Cell_Macro": null,
        "Cell_Micro": null,
        "Cell_provider": null,
        "Cell_CallPerformance": null,
        "Cell_FastestSpeed": null,
        "VSAT_Macro": null,
        "VSAT_Micro": null,
        "VSAT_provider": null,
        "VSAT_CallPerformance": null,
        "VSAT_FastestSpeed": null,
        "Site": true,
        "serviceTerminal": "all",
        "Starlink": false,
        "heatMaps": false,
        "SitewithStarlink": true,
        "Viasat1": false,
        "Viasat2": false
    }
    const [layerSelectionDetails, setLayerSelectionDetails] = useState(defaultLayers);
    const [vesseld, setVesseld] = useState<any>();
    const [vesselName, setVesselName] = useState<any>();
    const [voyageDataDetails, setVoyageDataDetails] = useState<any>();
    const [vesselData, setVesselData] = useState<any>();
    const [selctedVesselData, setSelctedVesselData] = useState<any>();
    const [vesselCurrLocation, setVesselCurrLocation] = useState<any>();
    const [terminalCurrLocation, setTerminalCurrLocation] = useState<any>();
    const [uniqueCarrieList, setUniqueCarrieList] = useState<any>();
    const [uniqueVstCarrierList, setUniqueVstCarrierList] = useState<any>();

    useEffect(() => {
        setVesseld(selectedVessel["id"]);
        setVesselName(selectedVessel["vessel-name"]);
    }, [selectedVessel])
    
    useEffect(() => {
        setVoyageDataDetails(voyageData?.data?.data?.rows)
        setVesselData(voyageData?.getVesselPath?.data)
    }, [voyageData])

    useEffect(() => {
        setSelctedVesselData(selctedVoyageData?.data?.data?.rows)
    }, [selctedVoyageData])

    useEffect(() => {
        setVesselCurrLocation(vesselCurrentLocationDetails)
    }, [vesselCurrentLocationDetails])

    useEffect(() => {
        setTerminalCurrLocation(terminalCurrentLocationDetails);
    }, [terminalCurrentLocationDetails])

    useEffect(() => {
        setUniqueCarrieList(carriersList?.data?.data?.operator?.data);
    }, [carriersList])

    useEffect(() => {
        setUniqueVstCarrierList(vsatCarrierList?.data?.data?.service_provider?.data);
    }, [vsatCarrierList])

    useEffect(() => {
        if(location) {
            processQueryUrl();
        }
       
    }, []);

    useEffect(() => {
        if(selectedSite) {
            dispatch(getSelectedSiteServiceLineData(selectedSite,""))
        } else  if(selectedServiceLine) {
            dispatch(getSelectedSiteServiceLineData("",selectedServiceLine))
        } else {
        }
    },[selectedSite,selectedServiceLine])

    useEffect(() => {
        setPopUpOpen(false);        
        setHexPopUpOpen(false);
        setSelectedCarrier("ALL")
        let params = getDecodeURI(location?.search);
        params.network = "ALL";
        doNavigate(params);
    }, [isVsatDataSource])

    useEffect(() => {
        setHexPopUpOpen(false);
    },[selectedDataSource])

    useEffect(() => {
        let params = getDecodeURI(location?.search);
        if( params.dataSource === "VSAT") {
            params.layer = "Call Performance"
            doNavigate(params);
            setVsatDataSource(true);
            setSelectedDataSource("VSAT");
            setSelectedCarrier("ALL");
            setSelectedLayer("Call Performance")
           
        } else if( params.dataSource === "STARLINK") {
            params.dataSource = "STARLINK";
            params.layer = "Latency"
            doNavigate(params);
            setVsatDataSource(false);
            setSelectedDataSource("STARLINK");
            setSelectedCarrier("NA");
            setSelectedLayer("Latency")
        }  else if(params.dataSource === "CELL") {
            params.dataSource = "CELL";
            params.layer = "Call Performance"
            doNavigate(params);
            setVsatDataSource(false);
            setSelectedDataSource("CELL");
            setSelectedCarrier("ALL");
            setSelectedLayer("Call Performance")
        } else {
            params.dataSource = "STARLINK";
            params.layer = "Latency"
            doNavigate(params);
            setVsatDataSource(false);
            setSelectedDataSource("STARLINK");
            setSelectedCarrier("NA");
            setSelectedLayer("Latency")
        }
        
    },[])

    const doNavigate = (params: any) => {
        history.push({ pathname: "/vessel-map", search: `?${getEncodedURI(params)}` });
    }

    useEffect(() => {
        if (Object.keys(apiErrorHandling).length > 0) {
            setIsErrorPopUp(true)
            dispatch(setAllMapErrors(JSON.stringify(apiErrorHandling)));
            setTimeout(() => {
                setIsErrorPopUp(false);
            }, 4000);
        }

    }, [apiErrorHandling])

    useEffect(() => {
        if (selectedDataSource === "CELL" && selectedLayer!== "") {
            dispatch(carrierGeoJson250sqkm("quantize_topojson/1e4/CELL",selectedCarrier.split(' ').join('_'), 5, selectedLayer.split(' ').join('_')));
            dispatch(carrierGeoJson5sqkm("quantize_topojson/1e4/CELL",selectedCarrier.split(' ').join('_'), 7, selectedLayer.split(' ').join('_')));
        } else if(selectedDataSource === "VSAT") {
            dispatch(vsatCoverage("VSAT",selectedCarrier.split(' ').join('_'), 5, selectedLayer.split(' ').join('_')));
        } else {
            if(selectedDataSource === "STARLINK" && selectedLayer !== "") {
                dispatch(StartlinkCoverageWithThree("quantize_topojson/1e4/Starlink",3,selectedLayer.split(' ').join('_')));
                dispatch(StarLinkCoverageWithFour("quantize_topojson/1e4/Starlink",4,selectedLayer.split(' ').join('_')));
            }
        }
    }, [selectedCarrier, selectedLayer, isVsatDataSource,selectedDataSource]);

    useEffect(() => {
        handleApiLoading(terminalLastLocLoading || voaygeLoading);
    }, [terminalLastLocLoading, siteSelectionOrServiceLineLoading,voaygeLoading]);

    const getVesselTowerConectivity = (nearestTowerDetails:any) => {
        setConnectivity([]);
        if(nearestTowerDetails && nearestTowerDetails.length > 0) {
          let towerConnect =   nearestTowerDetails.map((vesselConnects:any) => {
                let startCord = vesselConnects?.cordinates;
                let TowerId = vesselConnects?.towerid;
                let towerObj = towerMap.get(TowerId);
                if(towerObj) {
                    return [ [`${startCord[0]}`,`${startCord[1]}`],[towerObj?.geometry?.coordinates[1],towerObj?.geometry?.coordinates[0]]]
                } 
                
            })
            setConnectivity(towerConnect);
        }
    }

    const handleVesselClick = (vesselStartTime: any, vesselEndTime: any, nearestTowerDetails: any, isWindowEnabled:boolean) => {
        Promise.resolve().then(() => {
            ReactDOM.unstable_batchedUpdates(() => {
                if(vesselStartTime && vesselEndTime) {
                    getVesselTowerConectivity(nearestTowerDetails)
                    setVesselStartTime(getDateInISo(moment(vesselStartTime).valueOf(), userTimezone));
                    setVesselEndTime(getDateInISo(moment(vesselEndTime).valueOf(), userTimezone));
                    setIsChartWindowEnabled(isWindowEnabled);
                } else {
                    setVesselStartTime("");
                    setVesselEndTime("")
                }
            });
          });
    }

    const handleLastLocVesselClick = (selectedVessel:any) => {
        if(selectedVessel) {
                setLocationMap(prevMap => ({
                  ...prevMap, // Keep previous data
                  [selectedVessel.locationId]: selectedVessel.src // Update new location
                }));
              } else {
                console.warn("Invalid response data", selectedVessel);
              }
        
        if (selectedVessel?.locationId  && selectedVessel?.name) {
            setSiteMap(prevMap => {
              if (!prevMap[selectedVessel?.locationId]) {
                setFlashingTab(selectedVessel?.locationId);  // Trigger the flashing effect for the new tab
                setTimeout(() => setFlashingTab(null), 1500); 
                return { ...prevMap, [selectedVessel?.locationId]: selectedVessel?.name };
              }
              return prevMap;
            });
          }
        if(selectedVessel?.locationId) {
            if(selectedVessel?.locationId == selectedSite) {
                setSelectedSite(null)
            } else {
                setSelectedServiceLine(null);
                setSelectedSite(selectedVessel?.locationId)
            }
        } else if(selectedVessel?.starlinkName) {
            if(selectedVessel?.starlinkName) {
                setSelectedSite(null)
                setSelectedServiceLine(selectedVessel?.starlinkName)
            } else {
                setSelectedServiceLine(null);
            }
        } else {
            setSelectedServiceLine(null);
            setSelectedSite(null)
        }
    }

    const handleVesselOnHover = (vesselStartTime: any, vesselEndTime: any, selectedVessel: any, lat: any, long: any, nearestTowerDetails:any, isEdge:any,item:any, isEdgeEve:any) => {
        let vesselPopUpContent: JSX.Element = !isEdge ? <>
            <div className="vesselHoverName">{selectedVessel}</div>
            <div>Start - {getSingleRecordChartLable(moment(vesselStartTime).valueOf(), userTimezone)}</div>
            <div>End - {getSingleRecordChartLable(moment(vesselEndTime).valueOf(), userTimezone)}</div>
            <div className='vesselHoverTab'>
                
            </div>
        </> :
            <> {isEdgeEve &&
                <>
                    <div className="vesselHoverName">{item?.name}</div>
                    <div>Time - {getSingleRecordChartLable(moment(vesselStartTime).valueOf(), userTimezone)}</div>
                </>
            }</>

        return vesselPopUpContent;
    }

    if (uniqueCarrierList.length === 0 && uniqueCarrieList !== undefined && uniqueCarrieList.length > 0) {
        let defaultList = ["ALL"];
        let uniqueList = uniqueCarrieList.sort();
        uniqueList = defaultList.concat(uniqueList);
        setUniqueCarrierList(uniqueList);
    }

    if (uniqueVsatCarrierList.length === 0 && uniqueVstCarrierList !== undefined && uniqueVstCarrierList.length > 0) {
        let defaultList = ["ALL"];
        let uniqueList = uniqueVstCarrierList.sort();
        uniqueList = defaultList.concat(uniqueList);
        setUniqueVsatCarrierList(uniqueList);
    }

    const vesselLocationDetails: string[] = vesselCurrLocation !== undefined && vesselCurrLocation.length > 0 &&
        vesselCurrLocation.map((item: any) => {
            if (item[2] === null || item[3] === null) {
                return;
            }
            let lastSeenTime = moment.utc(item[1], 'YYYY-MM-DD HH:mm:ss.SSS +-HHmm').toISOString();
            return { "long": item[3], "lat": item[2], "name": item[5], "heading": null, "locationId": item[0], "lastSeen": lastSeenTime, "status": item[7] , "src": "edge"}
        })
    const terminalLocationDetails: string[] =  terminalCurrLocation !== undefined && terminalCurrLocation.length > 0 &&
    terminalCurrLocation.map((item: any) => {
        if (item[2] === null || item[3] === null) {
            return;
        }
        let lastSeenTime = moment.utc(item[2], 'YYYY-MM-DD HH:mm:ss.SSS +-HHmm').toISOString();
        return { "long": item[4], "lat": item[3], "name": item[5], "heading": null, "locationId": item[0], "lastSeen": lastSeenTime, "status": item[8], "src":(item[13] == "true") ?"flightaware": item[11] == "true" ? "STARLINK": "EDGE", "edgeKpi": item[9] !== null ? JSON.parse(item[9]) : null, "starlinkKpi": item[10] !== null ? JSON.parse(item[10]): null , "starlinkName":item[1] }

    })

    const vesselPathDetails = voyageDataDetails !== undefined && voyageDataDetails.map((item: any, i: any) => {
        let cordinates:any = [];
        cordinates.push(item[2]);
        cordinates.push(item[3]);
        cordinates.push(item[4]);
        let isStalinkLoc = item[5] === "STARLINK" ? true : false;
        if(!isStarlinkSourceLoc && isStalinkLoc) {
            setIsStarlinkSourceLoc(true)
        }
        if (cordinates === undefined || cordinates[0] === null || cordinates[1] === null) {
        } else {
            return cordinates;
        }
    });

    const vesselPathPoints = vesselData !== undefined  && cloneDeep(vesselData).map((item: any, i: any) => {
        let cordinates:any = [];
        cordinates.push(item[2]);
        cordinates.push(item[3]);
        cordinates.push(item[4]);
        cordinates[2] =  Math.trunc(item?.linkbwe);
        if (cordinates === undefined || cordinates[0] === null || cordinates[1] === null) {
        } else {
            return cordinates;
        }
    });

    let selectedPathDetails = [];
    if (selctedPathStart !== "" && slectedPathEnd !== "") {
        selectedPathDetails =selctedVesselData !== undefined  && selctedVesselData.map((item: any, i: any) => {
            let cordinates:any = [];
            cordinates.push(item[2]);
            cordinates.push(item[3]);
            if (cordinates === undefined || cordinates[0] === null || cordinates[1] === null) {
            } else {
                return cordinates;
            }
        });
    }


    const vesselTrack = vesselPathDetails && vesselPathDetails.filter((item: any) => item !== undefined);
    let selectedTrack = [];
    if (selctedPathStart !== "" && slectedPathEnd !== "") {
        selectedTrack = selectedPathDetails && selectedPathDetails.filter((item: any) => item !== undefined);
    }

    const vesselTrackPointsData = vesselPathPoints && vesselPathPoints.filter((item: any) => item !== undefined);

    useEffect(() => {
        if (selectedTabId && newSummaryStartDate && newSummaryEndDate) {
            isPopUpOpen && setPopUpOpen(false);
            dispatch(fetchVesselKpiDataSuccess({}))
            dispatch(fetchVesselDataRateSuccess({}))
            dispatch(fetchVesselDataRTTSuccess({}))
        }
    },[selectedTabId, interval, newSummaryStartDate, newSummaryEndDate])

    useEffect(() => {
        setIsChartClosed(false);
    }, [selectedTabId]);

    const handleChartOnClose = () => {
        setSelectedPathEnd("");
        setSelectedPathStart("");
        isPopUpOpen && setPopUpOpen(false);
        setIsChartClosed(false);
    }

    const handleHexOnClose = () => {
        isHexPopUpOpen && setHexPopUpOpen(false);
    }

    const handleChartOpen = (value: boolean) => {
        setPopUpOpen(value);
        setIsChartClosed(value);
        isHexPopUpOpen && setHexPopUpOpen(false);
        isLayersSelOpen && setLayersSelOpen(false);
    }

    const Legend = (props: any) => {
        const { legendColor, legendText } = props;
        return (
            <>
                <Box
                    sx={{
                        width: 25,
                        height: 20,
                        marginTop: '2px',

                        backgroundColor: legendColor,

                    }}
                />

            </>
        )
    }

    useEffect(() => {
        if(tabValue == 1) {
            return;
        }
        let params = getDecodeURI(location.search);
        let incominOuName = params?.ouName ? params.ouName : '';
        setOuName(incominOuName)
  
        let vessels = [];
        if(selectedVessel && selectedVessel.length > 0) {
            vessels = selectedVessel.map((item:any) => {
                return item?.id
            })
        }
        if (k4Ids && k4Ids.length > 0 && (vessels.length == 0)) { 
            dispatch(getTerminalLastLocation([], incominOuName, [], authorizedVesselList,selectedOu?.id, edgeVal, newSummaryServiceLine, starlinkVal, isEdgeStarlink));
        } else {
            dispatch(fetchVesselCurrentLocationSuccess([]))
        }
    }, [k4Ids,tabValue,newSummaryServiceLine]);

    useEffect(() => {
        if(tabValue === 1) {
            return
        }
        setVesselTrackTimeout(true);
        setTimeout(() => {
            setVesselTrackTimeout(false)
        }, 12000);
        let vessels = []
        if(selectedVessel && selectedVessel.length > 0) {
            vessels = selectedVessel.map((item:any) => {
                return item?.id
            })
        }
        let params = getDecodeURI(location.search);
        let incominOuName = params?.ouName ? params.ouName : '';
        if (vessels && vessels.length > 0 ) {
            dispatch(getTerminalLastLocation(vessels, incominOuName, [], selectedVessel,selectedOu?.id, edgeVal, newSummaryServiceLine,  starlinkVal,isEdgeStarlink));
        } else  if(incominOuName == ouName) {
            if(k4Ids.length == vessels.length) {
                dispatch(getTerminalLastLocation([], incominOuName, [], authorizedVesselList,selectedOu?.id, edgeVal, newSummaryServiceLine, starlinkVal ,isEdgeStarlink));
            } else {
                dispatch(getTerminalLastLocation([], incominOuName, [], authorizedVesselList,selectedOu?.id, edgeVal,newSummaryServiceLine, starlinkVal, isEdgeStarlink));
            }
           
        }
    },[selectedVessel, edgeVal, starlinkVal, isEdgeStarlink,newSummaryServiceLine])

    useEffect(() => {
        let params = getDecodeURI(location.search);
        let incominOuName = params?.ouName ? params.ouName : '';
        if(selctedK4Id && tabValue > 0) {
            dispatch(getTerminalLastLocation([`${selctedK4Id}`], incominOuName, [], selectedVessel,selectedOu?.id, edgeVal, newSummaryServiceLine,  "false",isEdgeStarlink));
        }

    },[selctedK4Id,tabValue])
    
    const handleLayerButton = () => {
        isLayersSelOpen ? setLayersSelOpen(false) : setLayersSelOpen(true);
    }

    const rssiLegendList = !isVsatDataSource ? ['-90', '-85', '-75', '-65', '-58', '-53', '-48', '-45)'] : ['-10', '-5', '0', '5', '10', '15', '20', '25(SINR avg dB)'];
    const rateLegendList = !isVsatDataSource ? ['1', '3', '8', '13', '25', '40', '50', '75'] : ['0','15','30','45','60','75','90','100(speed in %)'];
    const LatencyList = ['60','80','120','140','200','300','400','500'];
    const rssiCellCallPerformance = ['-90', '-85', '-75', '-65', '-58', '-53', '-48', '-45']
    const speedCellSpeed = ['1', '3', '8', '13', '25', '40', '50', '75'] 
    const starLinkLossList = ['1', '3','7','15','30','50','70','90']
    const legendColorList = ['#EA3A24', '#FA9100', '#FFD11D', '#FFFFCC', '#B9E0A8', '#88ce7d', '#37b345', '#0183D7'];
    const starlinkColorList = ['#0183D7','#37b345','#88ce7d','#B9E0A8','#FFFFCC','#FFD11D','#FA9100','#EA3A24'];

    const handleHexClick = (item: any, resolution: any) => {
        isLayersSelOpen && setLayersSelOpen(false);
        setHexResolution(resolution);
        setIsChartClosed(false);
        setPopUpOpen(false);
        setHexPopUpOpen(true);
        setHexContent(item);
    }

    const handleSelctedTrack = (start: any, end: any) => {
        setSelectedPathStart(start);
        setSelectedPathEnd(end);
    }

    useEffect(() => {
        if (vesseld && selctedPathStart !== "" && selctedPathStart !== undefined && slectedPathEnd !== "" && slectedPathEnd !== undefined) {
            dispatch(getSelectedPathVesselVoyage(vesseld, selctedPathStart, slectedPathEnd, interval));
        } else {

        }
    }, [vesseld, selctedPathStart, slectedPathEnd, interval]);

    const h3Index = (h3Index: any) => {
        setSelectedH3(h3Index);
    }

    const processQueryUrl = () => {
        let _q = getDecodeURI(location?.search);
        setSelectedDataSource(_q.dataSource && '' !== _q.dataSource.trim() ? _q.dataSource : "STARLINK" );
        setInterval('20m');
        selectedDataSource !== "STARLINK" ? setSelectedLayer(_q.layer && '' !==_q.layer.trim() ? _q.layer :"Call Performance"): setSelectedLayer(_q.layer && '' !==_q.layer.trim() ? _q.layer :"Latency");
        setSelectedCarrier(_q.network && '' !==_q.network.trim() ? decodeURIComponent(_q.network) : "ALL");
        if(selectedDataSource === "STARLINK") {
            setSelectedCarrier("NA");
        }
    }

    const getLegendLables = (footerKey: string) => {
        switch (footerKey) {
            case "Drop_Percentage": return starLinkLossList
            case "Speed": return speedCellSpeed
            case "Call_Performance": return rssiCellCallPerformance
            case "Fastest_Speed": return rateLegendList
            default: return LatencyList 
        }
    }

    const getLegendUnits = (footerKey: string) => {
        switch (footerKey) {
            case "Drop_Percentage": return "Drop percentage(%)"
            case "Speed": return "Speed (Mbps)"
            case "Call_Performance": return "Call Performance RSSI (dBm)" 
            case "Fastest_Speed": return " Fatstest Speed (Mbps)"
            default: return "Latency (ms)"
        }
    }


    const handleChange = (event: React.SyntheticEvent | null, newValue: number) => {
        dispatch(fetchTerminalCurrentLocationSuccess([]))
        setTabValue(newValue);
        handleIsMultiVessel(newValue === 0 ? true : false)
        if(selectedVessel) {
            handleLastLocSelectedVessel(selectedVessel)
        } else {
            handleLastLocSelectedVessel(null)
        }
      };

      const alertMap:any = {
        50: "Ethernet Slow 10mbps",
        51: "Ethernet Slow 100mbps",
        52: "Power Supply Thermal Throttling",
        53: "Actuator Motor Stuck",
        54: "Mast Not Vertical",
        55: "Disabled No Active Account",
        56: "Disabled Too Far From Service Address",
        57: "Disabled No Service In Ocean",
        58: "Disabled Invalid Country",
        59: "Moving While Not Mobile",
        60: "Disabled Moving Too Fast",
        61: "Power Disconnect Detected",
        62: "Pop Change",
        79: "Software Update Reboot Pending",
        63: "Unable To Align",
        64: "High Sky Obstruction",
        65: "High Sky Obstruction Mobile",
        66: "High Time Obstruction",
        67: "High Time Obstruction Mobile",
        80: "Thermal Shutdown",
        81: "Thermal Throttling",
        82: "Software Update Reboot Pending",
        "over_threshold": "Over Quota Threshold",
        "over_limit": "Over Max Quota",
        "offline_for_15m": "Offline For More Than 15 Minutes",
        "offline_for_1h": "Offline For More Than 1 Hour",
        "offline_for_6h": "Offline For More Than 6 Hours",
    };

      let alertMsgList: any = "";
      let activeAlerts :any = ""
      if(selectedSiteOrServiceLineData[0]?.starlink_alerts) {
        activeAlerts = JSON.parse(selectedSiteOrServiceLineData[0]?.starlink_alerts);
      }
      if(activeAlerts && activeAlerts?.length > 0) {
        alertMsgList = activeAlerts.map((item: string) => {
          return alertMap[item]
        } )
      }
      if(alertMsgList && alertMsgList.length > 0 ) {
        alertMsgList = alertMsgList?.join(',')
      }

      let edgeAlertList = selectedSiteOrServiceLineData && selectedSiteOrServiceLineData[0]  && JSON.parse(selectedSiteOrServiceLineData[0]?.site_alerts) 

      edgeAlertList = edgeAlertList ? edgeAlertList.join(',') : ""

      useEffect(()=> {
        handleStarlinkLoc(isStarlinkLocation)
      },[isStarlinkLocation])
 
    const getLayerDetailsCallBack = (layerObject: any) => {
        setLayerSelectionDetails(layerObject)
        setIsStarlinkLocation(layerObject?.Starlink)
        setStarlinkMicro(layerObject?.Starlink_Micro)
        setStarlinkMacro(layerObject?.Starlink_Macro) 
        setLteMicro(layerObject?.Cell_Micro)
        setLteMacro(layerObject?.Cell_Macro)

        let source = layerObject?.selectedSource ? layerObject?.selectedSource.toUpperCase(): "";
        setSelectedDataSource(source)
        if(source == "STARLINK") {
            if(layerObject?.Starlink_Latency) {
                setSelectedLayer("Latency")
            } else if(layerObject?.Starlink_Speed) {
                setSelectedLayer("Speed")
            } else {
                setSelectedLayer("Drop_Percentage")
            }
        } else  {
            if(layerObject?.Cell_provider && layerObject?.Cell_provider !== 'All') {
                setSelectedCarrier(layerObject?.Cell_provider)
            } else {
                setSelectedCarrier("ALL")
            }  
            if(layerObject?.Cell_CallPerformance) {
                setSelectedLayer("Call_Performance")
            } else {
                setSelectedLayer("Fastest_Speed")
            }
         
        }
 
        if(layerObject?.Starlink !== null && layerObject?.Site !== null  && layerObject?.SitewithStarlink !== null ) {
            setEdgeVal("true");
            setStarlinkVal("true");
            setEdgeStarlink("true")
        } else if (layerObject?.site === null  && layerObject?.Starlink === null && layerObject?.SitewithStarlink === null) {
            setEdgeVal("NA")
            setStarlinkVal("false");
            setEdgeStarlink("false")
        } else {
            if(layerObject?.Site) {
                setEdgeVal("true")
            } else {
                setEdgeVal("false")
            }
            if(layerObject?.Starlink) {
                setStarlinkVal("true");
            } else {
                setStarlinkVal("false");
            }
            if(layerObject?.SitewithStarlink) {
                setEdgeStarlink("true")
            }  else {
                setEdgeStarlink("false")     
            }
        }
        setHeatMaps(layerObject?.heatMaps)
        
    }

    useEffect(() => {
        if(selectedVessel[0]) {
            setSelectedK4Id(selectedVessel[0]['id'])
        } else {
            setSelectedK4Id("");
        }
    },[selectedVessel])

    useEffect(() => {
        if(tabValue > 0 && selectedTabId && newSummaryStartDate?.isSame(moment(new Date(parseInt(_q.startDate))))) {
            dispatch(getVesselVoyage(selectedTabId, newSummaryStartDate, newSummaryEndDate, interval))
            dispatch(getVesselVoyageForStarlink(selectedTabId, newSummaryStartDate, newSummaryEndDate, interval))
        }
    },[tabValue,selectedTabId,newSummaryStartDate, newSummaryEndDate, interval])

    const handleTabSelection = (key:any,value:any, tabIndex:any) => {
        dispatch(setEmptyVesselVoyage())
        setTabValue(tabIndex + 1);
        if(key && value) {
            setselectedTabId(key)
            setSelectedTabName(value);
        }
        let params = getDecodeURI(location?.search);
        params.interval = "7d";
        params.startDate = moment(Date.now()).subtract(7, 'day').valueOf();
        params.endDate = moment(Date.now()).valueOf();
        doNavigate(params);
    }

    const handleTabClose = (key:any) => {
        dispatch(setEmptyVesselVoyage())
        if(key) {
            setTabValue(0);
            handleIsMultiVessel(true)
            setSelectedSite(null)
            setSelectedServiceLine(null)
            setSiteMap((prevMap) => {
                const newMap = { ...prevMap };
                delete newMap[key];
                return newMap;
              });
        }
    }

    return (
        <Grid item container lg={12} className="coverageMapContainer" >
           <Grid className="New-UX-NavigationTabs-Conatiner">
    <Tabs
      value={tabValue}
      onChange={handleChange}
      className="NewTabsDiv-InfoIconsDiv"
      variant="scrollable"
      scrollButtons="auto"
      TabIndicatorProps={{ style: { display: 'none' } }}
    >
      <Tab
        label="Sites"
        className={tabValue === 0 ? "New-UX-NavigationTabs-activeTab" : "New-UX-NavigationTabs-inActiveTab"}
        onClick={() => handleChange(null, 0)}
      />

      {Object.entries(siteMap).map(([key, value], index) => (
        <Tab
          key={key}
          label={
            <div className="selectedSiteTabs">
              <span title={value}>{value}</span>
              <IconButton
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  handleTabClose(key);
                }}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </div>
          }
          className={`
            ${tabValue === index + 1 ? "New-UX-NavigationTabs-activeTab" : "New-UX-NavigationTabs-inActiveTab"} 
            ${flashingTab === key ? "flashTab" : ""}
          `}
          onClick={() => handleTabSelection(key, value, index)}
        />
      ))}
    </Tabs>
  </Grid>
            {tabValue == 0 && <Grid item container lg={12} className="mapChartContainer">
               
                <Grid className="mapsBox" item container lg={12} >
                    <MapLeaflet
                        polyColor={'#0000ff'}
                        handleVesselClick={handleVesselClick}
                        selectedvessels={vesselName}
                        selctedVesselId={vesseld}
                        handleChartOpen={handleChartOpen}
                        isChartClosed={isChartClosed}
                        outerHexData={carrierData}
                        innerHexData={carrierInnerLayerData}
                        vesselCurrentLocation={vesselLocationDetails}
                        isAllStarlinkLocation={selectedLayersList["Location"]}
                        selectedLayer={selectedLayer}
                        isMultiIcons={false}
                        mapHeight="90vh"
                        outerHexOpacity={outerHexOpacity}
                        innerHexOpacity={innerHexOpacity}
                        isPopUpOpen={isPopUpOpen}
                        handleLayerButton={handleLayerButton}
                        handleHexClick={handleHexClick}
                        selectedCarrier={selectedCarrier}
                        h3Index={h3Index}
                        isVesselLastLocClickEnabled={true}
                        handleLastLocVesselClick={handleLastLocVesselClick}
                        isVsatDataSource={isVsatDataSource}
                        vsatCoverageData={vsatCoverageData}
                        selectedDataSource={selectedDataSource}
                        defaultStartDate={newSummaryStartDate}
                        defaultEndDate={newSummaryEndDate}
                        isSpecialPrrmEnabled={isSpecialPrrmEnabled}
                        starLinkData={starLinkData}
                        starLinkCoverageFour={starLinkSpeedData}
                        terminalLocationDetails={terminalLocationDetails}
                        isStarlinkLatencyMacro={isStarlinkMacro}
                        isStarlinkLatencyMicro={isStarlinkMicro}
                        edgeVal={edgeVal}
                        isLteMicro={isLteMicro}
                        isLteMacro={isLteMacro}
                        selectedOu = {selectedOu}
                        k4Ids = {selectedVessel}
                        getLayerDetails={getLayerDetailsCallBack} setLayersOpen={setLayersSelOpen} layerSelectionDetails={layerSelectionDetails} isLocationEnabled={tabValue} layersOpen={isLayersSelOpen}
                    />

                
                </Grid>
                {false &&
                    <Grid className="rightContainer" item container lg={8}>
                        <ChartDetails
                            vesselStartTime={newSummaryStartDate}
                            vesselEndTime={newSummaryEndDate}
                            hoveredStart={vesselStartTime}
                            hoveredEnd={vesselEndTime}
                            selctedVessel={vesseld}
                            vesselName={vesselName}
                            handleChartOnClose={handleChartOnClose}
                            handleSelctedTrack={handleSelctedTrack}
                            isWindowEnabled={isChartWindowEnabled} />

                    </Grid>
                }

              

            </Grid>}
            {tabValue > 0 && <Grid item container lg={12} className="mapChartContainer">
               
               <Grid className="mapsBox" item container lg={12} >
                   <MapVoyage
                       polyColor={'#0000ff'}
                       handleVesselClick={handleVesselClick}
                       handleVesselOnHover={handleVesselOnHover}
                       vesselData={voyageDataDetails}
                       vesselTrack={vesselTrack}
                       selectedTrack={selectedPathDetails}
                       isVesselTrackEnabled={selectedLayersList["Vessel Track"]}
                       selectedvessels={vesselName}
                       selctedVesselId={vesseld}
                       handleChartOpen={handleChartOpen}
                       isChartClosed={isChartClosed}
                       outerHexData={carrierData}
                       innerHexData={carrierInnerLayerData}
                       vesselCurrentLocation={vesselLocationDetails}
                       selectedLayer={selectedLayer}
                       mapHeight="90vh"
                       outerHexOpacity={outerHexOpacity}
                       innerHexOpacity={innerHexOpacity}
                       isPopUpOpen={isPopUpOpen}
                       handleLayerButton={handleLayerButton}
                       handleHexClick={handleHexClick}
                       selectedCarrier={selectedCarrier}
                       h3Index={h3Index}
                       isVsatDataSource={isVsatDataSource}
                       vsatCoverageData={vsatCoverageData}
                       selectedDataSource={selectedDataSource}
                       connectivity={connectivity}
                       vesselTrackPointsData={vesselTrackPointsData}
                       vesselPointsEnabled={selectedLayersList['Performance']}
                       isEdgeEventsEnabled={selectedLayersList['Events']}
                       edgeEventsData={edgeEventsData?.getEdgeEvents}
                       isKmlEnabled={selectedLayersList["Viasat"]}
                       kmlLayersDetails={kmlNames}
                       isSpecialPrrmEnabled={isSpecialPrrmEnabled}
                       starLinkData={starLinkData}
                       starLinkCoverageFour={starLinkSpeedData}
                       userTimezone={userTimezone}
                       isStarlinkLatencyMacro={isStarlinkMacro}
                       isStarlinkLatencyMicro={isStarlinkMicro}
                       isLteMicro={isLteMicro}
                       isLteMacro={isLteMacro}
                       selectedOu = {selectedOu}
                       k4Ids = {selectedVessel}
                       selctedK4Id={selectedTabId}
                       isStarlinkSourceLoc={isStarlinkSourceLoc}
                       starlinkLocationData={starlinkLocationData}
                       getLayerDetails={getLayerDetailsCallBack} setLayersOpen={setLayersSelOpen} layerSelectionDetails={layerSelectionDetails} isLocationEnabled={tabValue} layersOpen={isLayersSelOpen}
                       chartsOpen={isChartClosed} setChartsOpen={handleChartOnClose} selectedVessel={selectedTabId} selectedVesselName={selectedTabName} hoveredStart={vesselStartTime} hoveredEnd={vesselEndTime}
                       defaultStartDate={newSummaryStartDate}
                       defaultEndDate={newSummaryEndDate}
                       flightJourneyPoints={flightJourneyPoints}
                       flightJourneyDetails={flightJourneyDetails}
                       flightEstimatedJourneyPoints={flightEstimatedJourneyPoints}
                       flightEstimatedPathDetails={flightEstimatedPathDetails}
                       locationMap={locationMap}
                   />

               
               </Grid>
               {false &&
                   <Grid className="rightContainer" item container lg={8}>
                       <ChartDetails
                           vesselStartTime={newSummaryStartDate}
                           vesselEndTime={newSummaryEndDate}
                           hoveredStart={vesselStartTime}
                           hoveredEnd={vesselEndTime}
                           selctedVessel={vesseld}
                           vesselName={vesselName}
                           handleChartOnClose={handleChartOnClose}
                           handleSelctedTrack={handleSelctedTrack}
                           isWindowEnabled={isChartWindowEnabled} />

                   </Grid>
               }


             

           </Grid>}
            {isHexPopUpOpen &&
                    <Grid className="rightContainer" item container>
                        <HexDetails
                            handleChartOnClose={handleHexOnClose}
                            hexContent={hexContent}
                            selectedCarrier={selectedCarrier}
                            selctedIndex={selectedH3}
                            isVsatDataSource={isVsatDataSource}
                            selectedDataSource={selectedDataSource}
                            hexResolution={hexResolution}
                            selectedLayer={selectedLayer}
                        />
                    </Grid>
                }
            {tabValue > 0 && isChartClosed  &&
            <div></div>
                }
      
            {vesselName && (vesselPathDetails === false) && !voaygeLoading && vesselTrackTimeOut &&  <div className="errorCardForTrack">
                <span className="errorWord">{`No voyage data available for Vessel - ${vesselName} in this interval ${getDateRangeLablePerVessel(moment(newSummaryStartDate).valueOf(), userTimezone)} - ${getDateRangeLablePerVessel(moment(newSummaryEndDate).valueOf(), userTimezone)} `}</span> 
            </div>
            }
            {Object.keys(apiErrorHandling).length > 0 && isErrorPopUp && <div className="errorCard">
                <span className="errorWord">Uh - oh!.</span> Map hits an unexpected problem while trying to service your request.
                Please try again after some time. We are looking to the issue.
            </div>
            }

            {isHeatMaps && <div className={tabValue == 0 ? 'colorMeter' : 'colorMeterTwo'}>
                <>
                    <div className='mapLegends'>
                        {(selectedLayer === 'Call Performance') ? rssiLegendList?.map((item, i) => {
                            return <Legend className="coloredLegendBox" legendColor={selectedDataSource == 'STARLINK' ? starlinkColorList[i] : legendColorList[i]} />;
                        }) :
                            rateLegendList?.map((item, i) => {
                                return <Legend className="coloredLegendBox" legendColor={selectedDataSource == 'STARLINK' && selectedLayer != 'Speed' ? starlinkColorList[i] : legendColorList[i]} />;
                            })
                        }

                    </div>
                    <div className="meterlegendLabels">
                        {getLegendLables(selectedLayer)?.map((item, index) => (
                            <div key={index} className="meterlegendLabel">{item}</div>
                        ))}
                    </div>
                    <div className="colorMeterUnit">{layerSelectionDetails?.selectedSource}  {getLegendUnits(selectedLayer)}</div>
                </>
            </div>}

            {tabValue === 0 && <div className="vesselTrackInd">
                <div className="legendRow">
                <span className="legendLabel"></span>
                <span className="legendLabel">Online</span>
                <span className="legendLabel">Offline</span>
                <span className="legendLabel">Unknown</span>
                  
                  
          
                </div>
                <div className="legendRow">
                <Tooltip  classes={{ tooltip: 'custom-tooltip-site', arrow: 'custom-arrow-site' }}  title={<span style={{ fontSize: "12px" }} 
                    >{siteHoverDetails}</span>}>
                    <span className="legendLabel pointerCursor">Site</span>
                </Tooltip>
                    <img className="dotLegendSite" src={VesselBlueIc} alt="VSAT" />
                    <img className="dotLegendSite" src={VesselRedIc} alt="CELL" />
                    <img className="dotLegendSite" src={VesselGreyIc} alt="Starlink" />
                </div>
                    <div className="legendRow">
                    <Tooltip  classes={{ tooltip: 'custom-tooltip-starlink', arrow: 'custom-arrow-site' }}  title={<span style={{ fontSize: "12px" }}     >{starlinkHoverDetails}</span>}>
                    <span className="legendLabel pointerCursor">Starlink</span>
                    </Tooltip>
                    <img className="dotLegendStarone" src={TerminalBlueIc} alt="Selected Track" />
                    <img className="dotLegendStartwo" src={TerminalRedIc} alt="No Network" />
                    <div className="dotLegendStar" ></div>
                    
                </div>
            </div>}

            {(selectedSite || selectedServiceLine) && tabValue == 0 && <div className="vesselTrackIndSecond">
                <div className="secondLegendRow">
                    <span className="siteLegendLabelHead"></span>
                    <span className="siteLegendLabel">Name</span>
                    <span className="siteLegendLabel">Last Seen</span>
                    <span className="siteLegendLabelAvail">Availability</span>
                    <span className="siteLegendLabel">Alert</span>
                </div>
                <div className="secondLegendRowData">
                <span className="siteValueLabelHead">Site</span>
                <Tooltip placement="left" title={<span style={{ fontSize: "12px" }}>{selectedSiteOrServiceLineData && selectedSiteOrServiceLineData[0] ?  selectedSiteOrServiceLineData[0]?.site_name : ""}</span>}>
                <span className="siteValueLabel">{selectedSiteOrServiceLineData && selectedSiteOrServiceLineData[0] ?  selectedSiteOrServiceLineData[0]?.site_name : ""}</span>
                </Tooltip>
                <span className="siteValueLabel">{selectedSiteOrServiceLineData && selectedSiteOrServiceLineData[0] && selectedSiteOrServiceLineData && selectedSiteOrServiceLineData[0]?.site_last_seen ?  getSingleRecordChartLable(moment(selectedSiteOrServiceLineData[0]?.site_last_seen).valueOf(), userTimezone) : ""}</span>
                <span className="siteValueLabelAvail">{selectedSiteOrServiceLineData && selectedSiteOrServiceLineData[0] ?  selectedSiteOrServiceLineData[0]?.edge_availability : ""}</span>
                <Tooltip placement="right"  title={<span style={{ fontSize: "12px",zIndex: 999999 }}>{edgeAlertList ? edgeAlertList: ""}</span>}>
                <span className="siteValueLabel" style={{ zIndex: 999999 }}>{selectedSiteOrServiceLineData && selectedSiteOrServiceLineData[0] &&  JSON.parse(selectedSiteOrServiceLineData[0]?.site_alerts) ? JSON.parse(selectedSiteOrServiceLineData[0]?.site_alerts) : ""}</span>
               </Tooltip>
                </div>
                <div className="secondLegendRowData">
                <span className="siteValueLabelHead">Starlink</span>
                <Tooltip placement="left" title={<span style={{ fontSize: "12px" }}>{selectedSiteOrServiceLineData && selectedSiteOrServiceLineData[0] ?  selectedSiteOrServiceLineData[0]?.starlink_service_line_name : ""}</span>}>
                <span className="siteValueLabel">{selectedSiteOrServiceLineData && selectedSiteOrServiceLineData[0] ?  selectedSiteOrServiceLineData[0]?.starlink_service_line_name : ""}</span>
                </Tooltip>
                <span className="siteValueLabel">{selectedSiteOrServiceLineData && selectedSiteOrServiceLineData[0] && selectedSiteOrServiceLineData && selectedSiteOrServiceLineData[0]?.starlink_last_seen ?  getSingleRecordChartLable(moment(selectedSiteOrServiceLineData[0]?.starlink_last_seen).valueOf(), userTimezone) : ""}</span>
                <span className="siteValueLabelAvail">{selectedSiteOrServiceLineData && selectedSiteOrServiceLineData[0] ?  selectedSiteOrServiceLineData[0]?.starlink_availability : ""}</span>

                <Tooltip  placement="right" title={<span style={{ fontSize: "12px",zIndex: 999999 }}>{alertMsgList ? alertMsgList: 'no data'}</span>}>
                <span className="siteValueLabel" style={{ zIndex: 999999 }}>{alertMsgList ? alertMsgList : ""}</span>
                </Tooltip>
                </div>
            </div>}

            {tabValue > 0 && 
                        <div className="vesselVoyage">
                            <span className="dotLegend" style={{background:"#00FF00"}}></span>
                            <span className="legendLabel">CELL</span>
                            <span className="dotLegend" style={{background:"#0000FF"}}></span>
                            <span className="legendLabel">VSAT</span>
                            <span className="dotLegend" style={{background:"#ff9900"}}></span>
                            <span className="legendLabel">Starlink</span>
                            <span className="dotLegend" style={{background:"#A020F0"}}></span>
                            <span className="legendLabel">Multiple</span>
                            <span className="dotLegend" style={{background:"#FF0000"}}></span>
                            <span className="legendLabel">No Network</span>
                            <span className="dotLegend" style={{background:"#251d1f"}}></span>
                            <span className="legendLabel">No Data</span>
                            <span className="dotLegend" style={{background:"#00FFFF"}}></span>
                            <span className="legendLabel">Selected Track</span>
                        
                        </div>}

            {tabValue > 0 && selctedK4Id == "" &&
            <div className="">
                {/* <span className="legendLabel">Please select a site to continue...</span> */}
            </div>}
                
        </Grid>
    );
}

const mapStateToProps = (state: any) => ({
    newSummaryStartDate: state.authReducer.newSummaryStartDate,
    newSummaryEndDate: state.authReducer.newSummaryEndDate,
    towerDetails: state?.products?.data,
    vesselData: state?.products?.vesselData,
    voyageData: state?.products?.voyageData,
    selctedVoyageData: state?.products?.selctedVoyageData,
    kpiData: state?.products?.kpiData,
    carrierData: state?.products?.carrierData,
    carrierInnerLayerData: state?.products?.carrierInnerLayerData,
    vesselCurrentLocationDetails: state?.products?.vesselCurrentLocationDetails,
    terminalCurrentLocationDetails: state?.products?.terminalCurrentLocationDetails,
    carriersList: state?.products?.carriersList,
    voaygeLoading: state?.products?.voaygeLoading,
    vesselLastLocLoading: state?.products?.vesselLastLocLoading,
    terminalLastLocLoading: state?.products?.terminalLastLocLoading,
    apiErrorHandling: state?.products?.apiErrorHandling,
    vsatCoverageData: state?.products?.vsatCoverageData,
    vsatCoverageInnerLayerData: state?.products?.vsatCoverageInnerLayerData,
    edgeEventsData: state?.products?.edgeEventsData,
    vsatCarrierList: state?.products?.vsatCarrierList,
    starLinkData: state?.products?.starLinkData,
    starLinkSpeedData: state?.products?.starLinkSpeedData,
    carriersListLoading: state?.products?.carriersListLoading,
    carrierDataLoading: state?.products?.carrierDataLoading,
    carrierInnerLayerDataLoading: state?.products?.carrierInnerLayerDataLoading,
    vsatCoverageDataLoading: state?.products?.vsatCoverageDataLoading,
    starLinkDataLoading: state?.products?.starLinkDataLoading,
    starLinkSpeedDataLoading: state?.products?.starLinkSpeedDataLoading,
    speedHistoDataLoading: state?.products?.speedHistoDataLoading,
    rssiHistoDataLoading: state?.products?.rssiHistoDataLoading,
    sinrHistoDataLoading: state?.products?.sinrHistoDataLoading,
    vsatCarrierListLoading: state?.products?.vsatCarrierListLoading,
    starLinkLatencyHistoDetailsLoading: state?.products?.starLinkLatencyHistoDetailsLoading,
    starLinkSpeedHistoDetailsLoading: state?.products?.starLinkSpeedHistoDetailsLoading,
    starLinkDropPercentageHistoDetailsLoading: state?.products?.starLinkDropPercentageHistoDetailsLoading,
    selctedVoyageDataLoading: state?.products?.selctedVoyageDataLoading,
    selectedSiteOrServiceLineData: state?.products?.selectedSiteOrServiceLineData,
    siteSelectionOrServiceLineLoading: state?.products?.siteSelectionOrServiceLineLoading,
    starlinkServiceLines: state?.products?.starlinkServiceLines,
    starlinkLocationData: state?.products?.starlinkLocationData,
    flightPathDetails: state?.products?.flightPathDetails,
    flightEstimatedPathDetails: state?.products?.flightEstimatedPathDetails,

    siteChartsSiteInterfacesLoading: state?.authReducer?.siteChartsSiteInterfacesLoading,
    siteChartsThroughputLoading: state?.authReducer?.siteChartsThroughputLoading,
    siteChartsLinkStatusLoading: state?.authReducer?.siteChartsLinkStatusLoading,
    siteChartsSiteDataRateLoading: state?.authReducer?.siteChartsSiteDataRateLoading,
    siteChartsSINRLoading: state?.authReducer?.siteChartsSINRLoading,
    siteChartsLatencyJitterLoading: state?.authReducer?.siteChartsLatencyJitterLoading,
    siteChartsRSSILoading: state?.authReducer?.siteChartsRSSILoading,
    getEdgeEventsLoading: state?.authReducer?.getEdgeEventsLoading,
    getEdgeEventsFilterLoading: state?.authReducer?.getEdgeEventsFilterLoading,
    getEdgeEventsChartsLoading: state?.authReducer?.loading,
    getServiceLineandSitesCount: state?.products?.getServiceLineandSitesCount,
    newSummaryServiceLine: state?.authReducer?.newSummaryServiceLine,
   
});

const TowerVesselDetailsWithData = connect(
    mapStateToProps,
)(TowerVesselDetails);

export default TowerVesselDetailsWithData;
