import { Button, Dialog, DialogContent, DialogTitle, Divider, Grid, List, ListItemButton, Stack, Tooltip, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import close from "../../asset/image/close.svg";
import _ from "lodash";
import AssignInventory from "./AssignInventory";
import ChangeGroup from "./ChangeGroup";
import UpgradeWarehouseDevice from "./UpgradeWarehouseDevice";
import { getDeviceLatestEOSversion } from "../../actions/Users/authenticateInventory";
import { DEVICE_LATEST_EOS_VERSION } from "../../actions/types";
import { withRouter } from "react-router-dom";

const EditActionsPopup = (props) => {
    
    const { authReducer, open, setOpen, confirmDialog, setConfirmDialog, changeGroup, actionSelectedRow, handleOu, value, setValue, selectedDevice, handleAssignInventory, handleCreateAndAssignInventory, setOu, idData, setIdData, siteValue, setSiteValue, isCreate, setIsCreate, selectedLicense, setSelectedLicense,selectedTerm, setSelectedTerm,
        licenseOptions, setLicenseOptions, termOptions, setTermOptions, clearLicenceSelection, isUpgradeToLatestEosSelected, setIsUpgradeToLatestEosSelected, isGoldenConfigApplied, setIsGoldenConfigApplied, isGoldenConfigOtherApplied, setIsGoldenGonfigOtherApplied, setRegisterLoading, getDeviceLatestEOSversion
     } = props;

    const dispatch = useDispatch();

    const deviceActionsList = [
        actionSelectedRow.deviceId.substring(0, 2) !== "AM" && 'Register',
        actionSelectedRow.deviceId.substring(0, 2) !== "AM" && 'Change Organization',
        actionSelectedRow.deviceId.substring(0, 2) !== "AM" && 'System',
    ].filter(action => action);

    const [selectedAction, setSelectedAction] = useState(deviceActionsList[0]);
    const [selectedDeviceLatestEosVersion, setSelectedDeviceLatestEosVersion] = useState<any>("Fetching data...");

    const handleChangeActionType = (actionType) => {
        setSelectedAction(actionType);
        setConfirmDialog({ ...confirmDialog, subTitle: '', isOpen: false });
    }

    const handleConfirmClick = () => {
        confirmDialog.onConfirm();
    }

    const handleClose = () => {
        setOpen(false);
        setSelectedAction('');
        setConfirmDialog({ ...confirmDialog, subTitle: '', isOpen: false });
    }

    useEffect(() => {
            if(!_.isEmpty(actionSelectedRow?.deviceId)){
                getDeviceLatestEOSversion(actionSelectedRow.deviceId)
            }
    }, [actionSelectedRow])

    useEffect(() => {
        if (!_.isEmpty(authReducer?.deviceLatestEosVersion)) {
            if (!_.isEmpty(authReducer.deviceLatestEosVersion?.data)) {
                let eosVersion = authReducer.deviceLatestEosVersion?.data?.app_version;
                if (!_.isEmpty(eosVersion)) {
                    eosVersion=eosVersion.split("~")[0]
                    eosVersion = eosVersion.toLowerCase().startsWith("v") ? eosVersion : "v" + eosVersion
                    setSelectedDeviceLatestEosVersion(eosVersion)
                } else {
                    setSelectedDeviceLatestEosVersion("")
                }
            }
            dispatch({ type: DEVICE_LATEST_EOS_VERSION, payload: {} })
        }
    }, [authReducer?.deviceLatestEosVersion])

    return (
        <div className="Form">
            <Dialog
                disableEnforceFocus
                open={open}
                onClose={handleClose}
                aria-labelledby='form-dialog-title'
                fullWidth={true}
                maxWidth={'md'}
                className='config-deploy-inventory-popup menuScroll inventory-warehouse-register-pop-up inventory-edit-actions-container'
            >
                <div>
                    <DialogTitle className='create-default-config-title edit-actions-title'>
                        <span>{`Operations (${actionSelectedRow['deviceId'] ? actionSelectedRow['deviceId'] : ''})`}</span>
                        <img src={close} title="Close" onClick={handleClose} />
                    </DialogTitle>
                    <DialogContent className="edit-actions-content warehouse-edit-actions-content">
                        <Grid container spacing={1}>
                            <Grid xs={4} className="menuScroll device-border-right" item>
                                <Grid className="device-data-body actions-device-data-body actions-device-data-body-warehouse">
                                    <List>
                                        {deviceActionsList && deviceActionsList.map((identifier, index) => {
                                            return <ListItemButton className='status-lists' style={identifier === selectedAction ? { background: 'rgba(244, 245, 250, 1)' } : { background: '#fff' }} key={`device-${index}`} selected={identifier === selectedAction}
                                                onClick={() => handleChangeActionType(identifier)}>
                                                <Tooltip title={identifier}>
                                                    <Typography className={identifier === selectedAction ? "font-status-pop-up selectedAction" : "font-status-pop-up NotselectedAction"}>{identifier}</Typography>
                                                </Tooltip>
                                            </ListItemButton>
                                        })}
                                    </List>
                                </Grid>
                            </Grid>
                            <Grid item xs={8} className="menuScroll">
                                <Grid className="device-data-body edit-actions-data-body">
                                    <Stack className="sub-tab-parent" direction="column" spacing={1}>
                                        {confirmDialog.isOpen && <div className="custom_confirm_dialog_sub_title edit-actions-subTitle">
                                            {confirmDialog.subTitle}
                                            <div className="edit-actions-confirm-btns">
                                                <Divider className="confirm-dialogue-new-divider" />
                                                <div className="confirm-dialogue-new-btns">
                                                    <Button className="confirm-dialogue-cancel" onClick={() => setConfirmDialog({ ...confirmDialog, isOpen: false })}> Cancel </Button>
                                                    {
                                                        selectedAction === 'Register' ? (
                                                            !setRegisterLoading ? (
                                                                <Button className="confirm-dialogue-proceed" onClick={() => handleConfirmClick()}>
                                                                    Proceed
                                                                </Button>
                                                            ) : (
                                                                <Button className="confirm-dialogue-proceed-notAllow">
                                                                    Proceed
                                                                </Button>
                                                            )
                                                        ) : (
                                                            <Button className="confirm-dialogue-proceed" onClick={() => handleConfirmClick()}>
                                                                Proceed
                                                            </Button>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </div>}
                                        {
                                            selectedAction == 'Register' && !confirmDialog.isOpen && <AssignInventory
                                                setConfirmDialog={setConfirmDialog} handleClose={handleClose}
                                                deviceOu={{ "id": actionSelectedRow["k4DpId"], "name": actionSelectedRow["dpName"] }}
                                                handleOu={handleOu} value={value} setValue={setValue}
                                                selectedDevice={selectedDevice}
                                                handleAssignInventory={handleAssignInventory}
                                                handleCreateAndAssignInventory={handleCreateAndAssignInventory}
                                                setOu={setOu}
                                                idData={idData} setIdData={setIdData} siteValue={siteValue} setSiteValue={setSiteValue}
                                                isCreate={isCreate} setIsCreate={setIsCreate}
                                                selectedLicense={selectedLicense} setSelectedLicense={setSelectedLicense}
                                                selectedTerm={selectedTerm} setSelectedTerm={setSelectedTerm}
                                                licenseOptions={licenseOptions} setLicenseOptions={setLicenseOptions}
                                                termOptions={termOptions} setTermOptions={setTermOptions}
                                                clearLicenceSelection={clearLicenceSelection}
                                                isUpgradeToLatestEosSelected={isUpgradeToLatestEosSelected} setIsUpgradeToLatestEosSelected={setIsUpgradeToLatestEosSelected}
                                                isGoldenConfigApplied={isGoldenConfigApplied} setIsGoldenConfigApplied={setIsGoldenConfigApplied}
                                                isGoldenConfigOtherApplied={isGoldenConfigOtherApplied} setIsGoldenGonfigOtherApplied={setIsGoldenGonfigOtherApplied}
                                                selectedDeviceLatestEosVersion={selectedDeviceLatestEosVersion}
                                            />
                                        }
                                        { selectedAction == 'Change Organization' && !confirmDialog.isOpen && <ChangeGroup setOpen={setOpen} setOu={setOu} changeGroup={changeGroup} actionSelectedRow={actionSelectedRow} />}
                                        { selectedAction == 'System' && !confirmDialog.isOpen && <UpgradeWarehouseDevice setOpen={setOpen} selectedRow={actionSelectedRow} setConfirmDialog={setConfirmDialog} selectedDeviceLatestEosVersion={selectedDeviceLatestEosVersion} />}
                                    </Stack>
                                </Grid>
                            </Grid>
                        </Grid>
                    </ DialogContent>
                </div>
            </Dialog>
        </div>
    )
}

const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
});

export default withRouter(
    connect(mapStateToProps, {
        getDeviceLatestEOSversion
    })(EditActionsPopup)
);