import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Button, Dialog, DialogContent, IconButton, DialogTitle, Divider, Grid, List, ListItemButton, MenuItem, Paper, Select, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography, Autocomplete, TextField, FormControl, ListItem, ListItemText, DialogActions, Checkbox, Box, Switch } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import close from "../../asset/image/close.svg";
import _, { template } from "lodash";

import { Info } from "@material-ui/icons";
import moment from "moment";
import { getDataFromLocalStorage } from "../../utils/util";
import { USER_OU } from "../../constants/Constants";
import { assignCustomPlanAPI, assignChildPoolAPI } from "../StarlinkPooled/slice";

var Loader = require("react-loader");

const EditActionsPopup = (props) => {
    const { loading, starlinkPlanTemplate, open, setOpen, confirmDialog, setConfirmDialog, selectedPlan, handleEdit, assignCustomPlanAPI, assignCustomPlanToPool, assignChildPool, assignChildPoolAPI, callPlanHierarchyAPI } = props;

       const deviceActionsList = useMemo(() => {
        return ["Pool",
            "Plan"

        ].filter(Boolean);
    }, []);

    const getIdNameForList = (action) => {
        if (action == "Pool") {
            return 'actionType-enabled';
        }
        if (action == "Plan") {
            return 'actionType-enabled';
        }
        // if (action == "Service Line") {
        //     return 'actionType-enabled';
        // }
        return 'actionType-disabled';
    }

    const getDefaultActionType = (list) => {
        const actionTypes = list.map(action => {
            if (getIdNameForList(action) == 'actionType-enabled') {
                return action;
            } else {
                return '';
            }
        })
        return actionTypes?.filter(action => action != '')?.length > 0 ? actionTypes?.filter(action => action != '')?.[0] : deviceActionsList[0];
    }
    const [selectedAction, setSelectedAction] = useState(getDefaultActionType(deviceActionsList));
    const templateList = starlinkPlanTemplate
    const [selectedTemplate, setSelectedTemplate] = useState<any>(null)

    const updateActionValues = (action) => {
        switch (action) {
            case "a": {
                handleEdit({
                    serviceLineNumber: selectedPlan?.serviceLineNumber,
                    nickname: selectedPlan?.serviceLineName
                });
                break;
            }

            default: setConfirmDialog({ ...confirmDialog, subTitle: '', isOpen: false })
        }
    }

    useEffect(() => {
        updateActionValues(selectedAction);
    }, [selectedAction])

    const handleChangeActionType = (actionType) => {
        setSelectedTemplate(null)
        setSelectedAction(actionType);
        selectedAction != actionType && setConfirmDialog({ ...confirmDialog, subTitle: '', isOpen: false });
    }

    const handleConfirmClick = () => {
        confirmDialog.onConfirm();
    }

    const handleClose = () => {
        setSelectedTemplate(null)
        setOpen(false);
        setConfirmDialog({ ...confirmDialog, subTitle: '', isOpen: false });

    }

     const _userOu: any = getDataFromLocalStorage(USER_OU)
     const userOu = JSON.parse(_userOu)

     const handleAssignPool = () =>{
        let params = {
            templateId: selectedTemplate?.id,
            // parentTemplateId: selectedPlan?.pool_template_id ? selectedPlan?.pool_template_id : null,
            credentialId: selectedPlan?.credential_id, 
            parentSubPoolId: selectedPlan?.id ? selectedPlan?.id : "", 
            parentPoolId: selectedPlan?.parent_pool_id,
            accountNumber: selectedPlan?.account_number,
            assignedBy:userOu?.id,
            poolName: selectedTemplate?.plan_name,
            poolSize: selectedTemplate?.plan_size,
            unit: selectedTemplate?.unit,
            startDate: selectedTemplate?.start_date,
            duration: selectedTemplate?.duration,
            isRecurring: selectedTemplate?.is_recurring ? true : false,
            poolInfo: selectedTemplate?.plan_info,
            usersToNotify: selectedTemplate?.users_to_notify,
            quotaActions: selectedTemplate?.quota_actions,
            notificationMethods: selectedTemplate?.notification_methods,

        }

         assignChildPoolAPI(params)

    }

    const handleAssignPlan = () =>{
        let params = {
            templateId: selectedTemplate?.id,
            parentTemplateId: selectedPlan?.pool_template_id ? selectedPlan?.pool_template_id : null,
            credentialId: selectedPlan?.credential_id, 
            parentPoolId: selectedPlan?.parent_pool_id, 
            childPoolId: selectedPlan?.id ? selectedPlan?.id : "",
            accountNumber: selectedPlan?.account_number,
            createdBy:userOu?.id,
            planName: selectedTemplate?.plan_name,
            planSize: selectedTemplate?.plan_size,
            isRecurring: selectedTemplate?.is_recurring ? true : false,
            planInfo: selectedTemplate?.plan_info,
            // planInfo: "PlanInfo",
            duration: selectedTemplate?.duration,
            planUsage: selectedTemplate?.plan_size,
            billingDate: selectedTemplate?.start_date,
            unit: selectedTemplate?.unit,
            usersToNotify: selectedTemplate?.users_to_notify,
            quotaActions: selectedTemplate?.quota_actions,
            planType: selectedTemplate?.type,
            notificationMethods: selectedTemplate?.notification_methods,
            organizationIds:[],
        }

       assignCustomPlanAPI(params)

    }

    useEffect(()=>{

        if(!_.isEmpty(assignCustomPlanToPool)){
            callPlanHierarchyAPI()
            handleClose()
            setSelectedTemplate(null)
        }

    },[assignCustomPlanToPool])

    useEffect(()=>{

        if(!_.isEmpty(assignChildPool)){
            callPlanHierarchyAPI()
            handleClose()
            setSelectedTemplate(null)
        }

    },[assignChildPool])

    const handleChangeTemplate = (val) => {
        setSelectedTemplate(val)
    }


    return (
        <div className="Form">
            <Dialog
                disableEnforceFocus
                open={open}
                onClose={handleClose}
                aria-labelledby='form-dialog-title'
                fullWidth={true}
                maxWidth={'md'}
                className='config-deploy-starlink-popup menuScroll inventory-warehouse-register-pop-up inventory-edit-actions-container'
            >
                <div>
                    <DialogTitle className='create-default-config-title edit-actions-title'>
                        <div>
                            <span>Assignment</span>
                            <span className="selected-site-device starlink-admin-operations-popup-service-line-name">{` `}</span>
                        </div>
                        <div>
                            {loading ? (
                                <div className="commonLoader_NewSummary wan-profile-interfaces-loader editActions-loader">
                                    <Loader radius={4} length={5} lines={10} width={2} color={"#264C86"} />
                                </div>
                            ) : null}
                            <img src={close} title="Close" onClick={handleClose} />
                        </div>
                    </DialogTitle>
                    <DialogContent className="edit-actions-content">
                        <Grid container spacing={1}>
                            <Grid xs={4} className="menuScroll device-border-right deployed-device-list" item>
                                <Grid className="device-data-body actions-device-data-body">
                                    <List>
                                        {deviceActionsList && deviceActionsList.map((identifier, index) => {
                                            return <ListItemButton id={getIdNameForList(identifier)} className='status-lists'
                                                style={identifier === selectedAction ? { background: 'rgba(244, 245, 250, 1)' } : { background: '#fff' }}
                                                key={`device-${index}`}
                                                selected={identifier === selectedAction}
                                                onClick={() => getIdNameForList(identifier) == 'actionType-enabled' && handleChangeActionType(identifier)}>
                                                <Typography className={identifier === selectedAction ? "font-status-pop-up selectedAction" : "font-status-pop-up NotselectedAction"}>{identifier}</Typography>
                                            </ListItemButton>
                                        })}
                                    </List>
                                </Grid>
                            </Grid>
                            <Grid item xs={8} className="menuScroll starlinkAdmin-edit-actions-cont" style={{ minHeight: "250px" }}>
                                <Grid className="device-data-body edit-actions-data-body">
                                    <Stack className="sub-tab-parent" direction="column" spacing={1}>
                                        {confirmDialog.isOpen && <div className="custom_confirm_dialog_sub_title edit-actions-subTitle">
                                            {confirmDialog.subTitle}
                                            <div className="edit-actions-confirm-btns">
                                                <Divider className="confirm-dialogue-new-divider" />
                                                <div className="confirm-dialogue-new-btns">
                                                    <Button className="confirm-dialogue-cancel" onClick={handleClose}> Cancel </Button>
                                                    <Button className="confirm-dialogue-proceed" onClick={() => handleConfirmClick()}> Proceed </Button>
                                                </div>
                                            </div>
                                        </div>}
                                        {selectedAction == "Pool" && getIdNameForList(selectedAction) == 'actionType-enabled' &&

                                            <div className="pause-container-starlink">
                                                <Grid item xs={12} sm={12} md={12} lg={12} className="config-configuration-form-deploy select-config-from" >
                                                    <span id="content-left-text">Select Template</span>
                                                    <Grid className="configuration-form-build">
                                                        <FormControl>
                                                            <Select
                                                                labelId="demo-simple-select-label"
                                                                id="demo-simple-select"
                                                                value={selectedTemplate}
                                                                label="select"
                                                                onChange={(e) => { handleChangeTemplate(e.target.value) }}
                                                                size="small"
                                                            >
                                                                {templateList?.filter((item) => { return item?.type === "orgPool" }).map((item) => {
                                                                    return <MenuItem disabled={selectedPlan?.pool_template_id === item?.id} value={item}>{item?.plan_name}</MenuItem>
                                                                })}
                                                            </Select>
                                                        </FormControl>
                                                    </Grid>
                                                </Grid>
                                                <div className="edit-actions-confirm-btns starlink-admin-edit-actions-confirm-btns">
                                                    <Divider className="confirm-dialogue-new-divider" />
                                                    <div className="confirm-dialogue-new-btns">
                                                        <Button className="confirm-dialogue-cancel" onClick={handleClose}> Cancel </Button>
                                                        <Button variant="contained" className='starlink--button_contained--primary' disabled={!selectedTemplate} onClick={() => { handleAssignPool();  }} >Save</Button>
                                                    </div>
                                                </div>
                                            </div>
                                        }

                                        {selectedAction == "Plan" && getIdNameForList(selectedAction) == 'actionType-enabled' &&
                                            <div className="pause-container-starlink">
                                                <Grid item xs={12} sm={12} md={12} lg={12} className="config-configuration-form-deploy select-config-from" >
                                                    <span id="content-left-text">Select Template</span>
                                                    <Grid className="configuration-form-build">
                                                        <FormControl>
                                                            <Select
                                                                labelId="demo-simple-select-label"
                                                                id="demo-simple-select"
                                                                value={selectedTemplate}
                                                                label="select"
                                                                onChange={(e) => { handleChangeTemplate(e.target.value) }}
                                                                size="small"
                                                            >
                                                                {templateList?.filter((item) => { return item?.type === "customPlan" }).map((item) => {
                                                                    return <MenuItem value={item}>{item?.plan_name}</MenuItem>
                                                                })}
                                                            </Select>
                                                        </FormControl>
                                                    </Grid>
                                                </Grid>
                                                <div className="edit-actions-confirm-btns starlink-admin-edit-actions-confirm-btns">
                                                    <Divider className="confirm-dialogue-new-divider" />
                                                    <div className="confirm-dialogue-new-btns">
                                                        <Button className="confirm-dialogue-cancel" onClick={handleClose}> Cancel </Button>
                                                        <Button variant="contained" className='starlink--button_contained--primary' disabled={!selectedTemplate} onClick={() => { handleAssignPlan();}} >Save</Button>
                                                    </div>
                                                </div>
                                            </div>
                                        }


                                    </Stack>
                                </Grid>
                            </Grid>
                        </Grid>
                    </ DialogContent>
                </div>
            </Dialog>


        </div>
    )
}

const mapStateToProps = (state) => ({
    loading: state.starlinkAdmin.subcribingServiceLine,
    authReducer: state.authReducer,
    allNonAdminPermissions: state.starlinkAdmin.allNonAdminPermissions,
    starlinkMapTelemetry: state.starlinkDashboard.starlinkMapTelemetry,
    assignCustomPlanToPool: state.starlinkPooled.assignCustomPlanToPool,
    assignChildPool: state.starlinkPooled.assignChildPool
});

export default withRouter(
    connect(mapStateToProps, {
        assignCustomPlanAPI,
        assignChildPoolAPI
    })(EditActionsPopup)
);