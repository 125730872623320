// New Summary Dashboard

import { api } from "../../server/request";
import { handleError, handleResponse } from "../responseUtil";
import {  SET_CAPTIVE_DASHBOARD_SITES_SUBSCRIBERS_SESSIONS_TOP_WIDGET, GET_CAPTIVE_DASHBOARD_SITES_SUBSCRIBERS_SESSIONS_TOP_WIDGET, SET_CAPTIVE_DASHBOARD_PLANS_TOP_WIDGET, GET_CAPTIVE_DASHBOARD_PLANS_TOP_WIDGET, SET_CAPTIVE_DASHBOARD_USAGE_TOP_WIDGET, GET_CAPTIVE_DASHBOARD_USAGE_TOP_WIDGET,
    SET_CAPTIVE_DASHBOARD_TOP_SITES_USAGE_BAR, GET_CAPTIVE_DASHBOARD_TOP_SITES_USAGE_BAR, SET_CAPTIVE_DASHBOARD_SUBSCRIBERS_LINE_CHART, GET_CAPTIVE_DASHBOARD_SUBSCRIBERS_LINE_CHART, SET_CAPTIVE_DASHBOARD_SESSIONS_LINE_CHART, GET_CAPTIVE_DASHBOARD_SESSIONS_LINE_CHART, SET_CAPTIVE_DASHBOARD_USAGE_LINE_CHART,
    GET_CAPTIVE_DASHBOARD_USAGE_LINE_CHART, SET_CAPTIVE_DASHBOARD_SITES_TABLE, GET_CAPTIVE_DASHBOARD_SITES_TABLE, SET_CAPTIVE_DASHBOARD_TOP_SITES_SUBSCRIBERS_BAR, GET_CAPTIVE_DASHBOARD_TOP_SITES_SUBSCRIBERS_BAR, SET_CAPTIVE_DASHBOARD_TOP_SITES_SESSIONS_BAR, GET_CAPTIVE_DASHBOARD_TOP_SITES_SESSIONS_BAR,
    SET_CAPTIVE_DASHBOARD_SUBSCRIBERS_SESSIONS_TABLE, GET_CAPTIVE_DASHBOARD_SUBSCRIBERS_SESSIONS_TABLE, SET_CAPTIVE_DASHBOARD_TOP_PLANS_USERS_BAR, GET_CAPTIVE_DASHBOARD_TOP_PLANS_USERS_BAR, SET_CAPTIVE_DASHBOARD_EVENTS_SUBSCRIBERS_PLAN_USAGE, GET_CAPTIVE_DASHBOARD_EVENTS_SUBSCRIBERS_PLAN_USAGE,
    SET_CAPTIVE_DASHBOARD_TOP_SUBSCRIBERS_USAGE_BAR, GET_CAPTIVE_DASHBOARD_TOP_SUBSCRIBERS_USAGE_BAR, SET_CAPTIVE_DASHBOARD_TOP_SUBSCRIBERS_SESSIONS_BAR, GET_CAPTIVE_DASHBOARD_TOP_SUBSCRIBERS_SESSIONS_BAR, SET_CAPTIVE_DASHBOARD_PLANS_SUBSCRIBERS_HISTOGRAM,
    GET_CAPTIVE_DASHBOARD_PLANS_SUBSCRIBERS_HISTOGRAM, SET_CAPTIVE_DASHBOARD_PLANS_USAGE_LINE_CHART, GET_CAPTIVE_DASHBOARD_PLANS_USAGE_LINE_CHART, SET_CAPTIVE_DASHBOARD_SUBSCRIBERS_LIST, GET_CAPTIVE_DASHBOARD_SUBSCRIBERS_LIST, SET_CAPTIVE_DASHBOARD_PLANS_LIST, GET_CAPTIVE_DASHBOARD_PLANS_LIST,
    SET_CAPTIVE_DASHBOARD_SUBSCRIBER_SESSIONS_TABLE, GET_CAPTIVE_DASHBOARD_SUBSCRIBER_SESSIONS_TABLE, SET_CAPTIVE_DASHBOARD_EVENTS_TABLE, GET_CAPTIVE_DASHBOARD_EVENTS_TABLE, SET_CAPTIVE_DASHBOARD_EVENTS_IP_MAC_LIST, GET_CAPTIVE_DASHBOARD_EVENTS_IP_MAC_LIST, SET_CAPTIVE_DASHBOARD_PLANS_SUBSCRIBERS_LINE_CHART, 
    GET_CAPTIVE_DASHBOARD_PLANS_SUBSCRIBERS_LINE_CHART, SET_CAPTIVE_DASHBOARD_TOP_PLANS_USAGE_BAR, GET_CAPTIVE_DASHBOARD_TOP_PLANS_USAGE_BAR, SET_CAPTIVE_DASHBOARD_SITES_HISTOGRAM, GET_CAPTIVE_DASHBOARD_SITES_HISTOGRAM
 } from "../types";

export const getClickhouseBucketReformat = (interval) => {
    switch (interval) {
        case "5m": return '5 minute';
        case "10m": return '10 minute';
        case "15m": return '15 minute';
        case "30m": return '30 minute';
        case "1h": return '1 hour';
        case "2h": return '2 hour';
        case "3h": return '3 hour';
        case "4h": return '4 hour';
        case "6h": return '6 hour';
        case "12h": return '12 hour';
        case "1d": return '1 day';
        case "7d": return '7 day';
        default: return '1 hour';
    }
}

export const getCaptiveDashSitesSubscribersSessionsTopWidget = (vessels, startDate, endDate, subscribers, plans, durations, quotas, status, startFrom, startTo, endFrom, endTo, autoRenewal) => (dispatch) => {
    dispatch({ type: SET_CAPTIVE_DASHBOARD_SITES_SUBSCRIBERS_SESSIONS_TOP_WIDGET, payload: true });
    const sitesSubscribersSessionsTopWidget: any = {
        query: 'CAPTIVE_DASHBOARD_SITES_SUBSCRIBERS_SESSIONS_TOP_WIDGET_WITH_FILTERS',
        named: true,
        format: "json",
        parameters: {
            k4Id: vessels && vessels.length > 0 ? `${vessels.map((item:any) => `'${item.id}'`).join(",")}` : '',
            startDate: startDate,
            endDate: endDate,
            sub_filter: subscribers?.length > 0 ? `AND username in (${subscribers?.map(sb => `'${sb}'`).join(',')})` : '',
            plan_filter: plans?.length > 0 ? `AND planName IN (${plans?.map(plan => `'${plan}'`).join(',')})` : '',
            duration_filter: durations?.length > 0 ? `AND duration IN (${durations?.map(duration => `'${duration}'`).join(',')})` : '',
            quota_filter: quotas?.length > 0 ? `AND quota IN (${quotas?.map(quota => `'${quota * (1000 * 1000 * 1000)}'`).join(',')})` : '',
            status_filter: status ? `AND isPlanExpired = ${status == 'active' ? 0 : 1}` : '',
            startDate_filter: startFrom && startTo ? `AND planStartDate > parseDateTime64BestEffort('${startFrom.toISOString()}') AND planStartDate < parseDateTime64BestEffort('${startTo.toISOString()}')` : 
                              startFrom ? `AND planStartDate > parseDateTime64BestEffort('${startFrom.toISOString()}') AND planStartDate < parseDateTime64BestEffort('${endDate.toISOString()}')` : '',
            endDate_filter: endFrom && endTo ? `AND planEndDate > parseDateTime64BestEffort('${endFrom.toISOString()}') AND planEndDate < parseDateTime64BestEffort('${endTo.toISOString()}')` :
                            endFrom ? `AND planEndDate > parseDateTime64BestEffort('${endFrom.toISOString()}') AND planEndDate < parseDateTime64BestEffort('${endDate.toISOString()}')` : '',
            autoRenewal_filter: autoRenewal ? "AND autoRenewal = true" : ""
        }
    }
    api
        .postRequestOut(`/store/ch/query`, sitesSubscribersSessionsTopWidget)
        .then((res) => handleResponse(res, dispatch, GET_CAPTIVE_DASHBOARD_SITES_SUBSCRIBERS_SESSIONS_TOP_WIDGET))
        .catch((err) => handleError(err, dispatch));
};

export const getCaptiveDashPlansTopWidget = (vessels, startDate, endDate, subscribers, plans, durations, quotas, status, startFrom, startTo, endFrom, endTo, autoRenewal) => (dispatch) => {
    dispatch({ type: SET_CAPTIVE_DASHBOARD_PLANS_TOP_WIDGET, payload: true });
    const plansTopWidget: any = {
        query: 'CAPTIVE_DASHBOARD_PLANS_TOP_WIDGET_WITH_FILTERS',
        named: true,
        format: "json",
        parameters: {
            k4Id: vessels && vessels.length > 0 ? `${vessels.map((item:any) => `'${item.id}'`).join(",")}` : '',
            startDate: startDate,
            endDate: endDate,
            sub_filter: subscribers?.length > 0 ? `AND username in (${subscribers?.map(sb => `'${sb}'`).join(',')})` : '',
            plan_filter: plans?.length > 0 ? `AND planName IN (${plans?.map(plan => `'${plan}'`).join(',')})` : '',
            duration_filter: durations?.length > 0 ? `AND duration IN (${durations?.map(duration => `'${duration}'`).join(',')})` : '',
            quota_filter: quotas?.length > 0 ? `AND quota IN (${quotas?.map(quota => `'${quota * (1000 * 1000 * 1000)}'`).join(',')})` : '',
            status_filter: status ? `AND isPlanExpired = ${status == 'active' ? 0 : 1}` : '',
            startDate_filter: startFrom && startTo ? `AND planStartDate > parseDateTime64BestEffort('${startFrom.toISOString()}') AND planStartDate < parseDateTime64BestEffort('${startTo.toISOString()}')` : 
                              startFrom ? `AND planStartDate > parseDateTime64BestEffort('${startFrom.toISOString()}') AND planStartDate < parseDateTime64BestEffort('${endDate.toISOString()}')` : '',
            endDate_filter: endFrom && endTo ? `AND planEndDate > parseDateTime64BestEffort('${endFrom.toISOString()}') AND planEndDate < parseDateTime64BestEffort('${endTo.toISOString()}')` :
                            endFrom ? `AND planEndDate > parseDateTime64BestEffort('${endFrom.toISOString()}') AND planEndDate < parseDateTime64BestEffort('${endDate.toISOString()}')` : '',
            autoRenewal_filter: autoRenewal ? "AND autoRenewal = true" : ""
        }
    }
    api
        .postRequestOut(`/store/ch/query`, plansTopWidget)
        .then((res) => handleResponse(res, dispatch, GET_CAPTIVE_DASHBOARD_PLANS_TOP_WIDGET))
        .catch((err) => handleError(err, dispatch));
};

export const getCaptiveDashUsageTopWidget = (vessels, startDate, endDate, subscribers, plans, durations, quotas, status, startFrom, startTo, endFrom, endTo, autoRenewal) => (dispatch) => {
    dispatch({ type: SET_CAPTIVE_DASHBOARD_USAGE_TOP_WIDGET, payload: true });
    const usageTopWidget: any = {
        query: 'CAPTIVE_DASHBOARD_USAGE_TOP_WIDGET_WITH_FILTERS',
        named: true,
        format: "json",
        parameters: {
            k4Id: vessels && vessels.length > 0 ? `${vessels.map((item:any) => `'${item.id}'`).join(",")}` : '',
            startDate: startDate,
            endDate: endDate,
            sub_filter: subscribers?.length > 0 ? `AND username in (${subscribers?.map(sb => `'${sb}'`).join(',')})` : '',
            plan_filter: plans?.length > 0 ? `AND planName IN (${plans?.map(plan => `'${plan}'`).join(',')})` : '',
            duration_filter: durations?.length > 0 ? `AND duration IN (${durations?.map(duration => `'${duration}'`).join(',')})` : '',
            quota_filter: quotas?.length > 0 ? `AND quota IN (${quotas?.map(quota => `'${quota * (1000 * 1000 * 1000)}'`).join(',')})` : '',
            status_filter: status ? `AND isPlanExpired = ${status == 'active' ? 0 : 1}` : '',
            startDate_filter: startFrom && startTo ? `AND planStartDate > parseDateTime64BestEffort('${startFrom.toISOString()}') AND planStartDate < parseDateTime64BestEffort('${startTo.toISOString()}')` : 
                              startFrom ? `AND planStartDate > parseDateTime64BestEffort('${startFrom.toISOString()}') AND planStartDate < parseDateTime64BestEffort('${endDate.toISOString()}')` : '',
            endDate_filter: endFrom && endTo ? `AND planEndDate > parseDateTime64BestEffort('${endFrom.toISOString()}') AND planEndDate < parseDateTime64BestEffort('${endTo.toISOString()}')` :
                            endFrom ? `AND planEndDate > parseDateTime64BestEffort('${endFrom.toISOString()}') AND planEndDate < parseDateTime64BestEffort('${endDate.toISOString()}')` : '',
            autoRenewal_filter: autoRenewal ? "AND autoRenewal = true" : ""

        }
    }
    api
        .postRequestOut(`/store/ch/query`, usageTopWidget)
        .then((res) => handleResponse(res, dispatch, GET_CAPTIVE_DASHBOARD_USAGE_TOP_WIDGET))
        .catch((err) => handleError(err, dispatch));
};

export const getCaptiveDashTopSitesUsageBar = (vessels, startDate, endDate, pieLimit, page, subscribers) => (dispatch) => {
    dispatch({ type: SET_CAPTIVE_DASHBOARD_TOP_SITES_USAGE_BAR, payload: true });
    const topSitesPie: any = {
        query: 'CAPTIVE_DASHBOARD_TOP_SITES_USAGE_BAR',
        named: true,
        format: "csv",
        parameters: {
            k4Id: vessels && vessels.length > 0 ? `${vessels.map((item:any) => `'${item.id}'`).join(",")}` : '',
            startDate: startDate,
            endDate: endDate,
            recLimit: pieLimit,
            pageOffset: ((page - 1) * 10),
            pageSize: 10,
            sub_filter: subscribers?.length > 0 ? `AND username in (${subscribers?.map(sb => `'${sb}'`).join(',')})` : ''
        },
        groupBy: "siteName",
        series: "quotaUsed"
    }
    api
        .postRequestOut(`/store/ch/query`, topSitesPie)
        .then((res) => handleResponse(res, dispatch, GET_CAPTIVE_DASHBOARD_TOP_SITES_USAGE_BAR))
        .catch((err) => handleError(err, dispatch));
};

export const getCaptiveDashTopSitesSubscribersBar = (vessels, startDate, endDate, pieLimit, page, subscribers) => (dispatch) => {
    dispatch({ type: SET_CAPTIVE_DASHBOARD_TOP_SITES_SUBSCRIBERS_BAR, payload: true });
    const topSitesSubscribersPie: any = {
        query: 'CAPTIVE_DASHBOARD_TOP_SITES_SUBSCRIBERS_BAR',
        named: true,
        format: "csv",
        parameters: {
            k4Id: vessels && vessels.length > 0 ? `${vessels.map((item:any) => `'${item.id}'`).join(",")}` : '',
            startDate: startDate,
            endDate: endDate,
            recLimit: pieLimit,
            pageOffset: ((page - 1) * 10),
            pageSize: 10,
            sub_filter: subscribers?.length > 0 ? `AND username in (${subscribers?.map(sb => `'${sb}'`).join(',')})` : ''
        },
        groupBy: "siteName",
        series: "subscriberCount"
    }
    api
        .postRequestOut(`/store/ch/query`, topSitesSubscribersPie)
        .then((res) => handleResponse(res, dispatch, GET_CAPTIVE_DASHBOARD_TOP_SITES_SUBSCRIBERS_BAR))
        .catch((err) => handleError(err, dispatch));
};

export const getCaptiveDashTopSitesSessionsBar = (vessels, startDate, endDate, pieLimit, page, subscribers) => (dispatch) => {
    dispatch({ type: SET_CAPTIVE_DASHBOARD_TOP_SITES_SESSIONS_BAR, payload: true });
    const topSitesSessionsPie: any = {
        query: 'CAPTIVE_DASHBOARD_TOP_SITES_SESSIONS_BAR',
        named: true,
        format: "csv",
        parameters: {
            k4Id: vessels && vessels.length > 0 ? `${vessels.map((item:any) => `'${item.id}'`).join(",")}` : '',
            startDate: startDate,
            endDate: endDate,
            recLimit: pieLimit,
            pageOffset: ((page - 1) * 10),
            pageSize: 10,
            sub_filter: subscribers?.length > 0 ? `AND username in (${subscribers?.map(sb => `'${sb}'`).join(',')})` : ''
        },
        groupBy: "siteName",
        series: "numberOfSessions"
    }
    api
        .postRequestOut(`/store/ch/query`, topSitesSessionsPie)
        .then((res) => handleResponse(res, dispatch, GET_CAPTIVE_DASHBOARD_TOP_SITES_SESSIONS_BAR))
        .catch((err) => handleError(err, dispatch));
};

export const getCaptiveDashTopSubscribersUsageBar = (vessels, startDate, endDate, pieLimit, page, subscribers, plans) => (dispatch) => {
    dispatch({ type: SET_CAPTIVE_DASHBOARD_TOP_SUBSCRIBERS_USAGE_BAR, payload: true });
    const topSitesSessionsPie: any = {
        query: 'CAPTIVE_DASHBOARD_TOP_SUBSCRIBERS_USAGE_BAR',
        named: true,
        format: "csv",
        parameters: {
            k4Id: vessels && vessels.length > 0 ? `${vessels.map((item:any) => `'${item.id}'`).join(",")}` : '',
            startDate: startDate,
            endDate: endDate,
            recLimit: pieLimit,
            pageOffset: ((page - 1) * 10),
            pageSize: 10,
            sub_filter: subscribers?.length > 0 ? `AND username in (${subscribers?.map(sb => `'${sb}'`).join(',')})` : '',
            plan_filter: plans?.length > 0 ? `AND planName in (${plans?.map(plan => `'${plan}'`).join(',')})` : ''
        }
    }
    api
        .postRequestOut(`/store/ch/query`, topSitesSessionsPie)
        .then((res) => handleResponse(res, dispatch, GET_CAPTIVE_DASHBOARD_TOP_SUBSCRIBERS_USAGE_BAR))
        .catch((err) => handleError(err, dispatch));
};

export const getCaptiveDashTopSubscribersSessionsBar = (vessels, startDate, endDate, pieLimit, page, subscribers, plans) => (dispatch) => {
    dispatch({ type: SET_CAPTIVE_DASHBOARD_TOP_SUBSCRIBERS_SESSIONS_BAR, payload: true });
    const topSitesSessionsPie: any = {
        query: 'CAPTIVE_DASHBOARD_TOP_SUBSCRIBERS_SESSIONS_BAR',
        named: true,
        format: "csv",
        parameters: {
            k4Id: vessels && vessels.length > 0 ? `${vessels.map((item:any) => `'${item.id}'`).join(",")}` : '',
            startDate: startDate,
            endDate: endDate,
            recLimit: pieLimit,
            pageOffset: ((page - 1) * 10),
            pageSize: 10,
            sub_filter: subscribers?.length > 0 ? `AND username in (${subscribers?.map(sb => `'${sb}'`).join(',')})` : '',
            plan_filter: plans?.length > 0 ? `AND planName in (${plans?.map(plan => `'${plan}'`).join(',')})` : ''
        }
    }
    api
        .postRequestOut(`/store/ch/query`, topSitesSessionsPie)
        .then((res) => handleResponse(res, dispatch, GET_CAPTIVE_DASHBOARD_TOP_SUBSCRIBERS_SESSIONS_BAR))
        .catch((err) => handleError(err, dispatch));
};

export const getCaptiveDashSubscribersLineChart = (vessels, startDate, endDate, interval, subscribers, plans) => (dispatch) => {
    dispatch({ type: SET_CAPTIVE_DASHBOARD_SUBSCRIBERS_LINE_CHART, payload: true });
    const subscribersLine: any = {
        query: 'CAPTIVE_DASHBOARD_SUBSCRIBERS_LINE_CHART',
        named: true,
        format: "chart",
        groupBy: "type",
        series: "SubscribersCount",
        tsColumn: "timeStamp",
        parameters: {
            k4Id: vessels && vessels.length > 0 ? `${vessels.map((item:any) => `'${item.id}'`).join(",")}` : '',
            startDate: startDate,
            endDate: endDate,
            intervalNum: interval.split(' ')[0],
            interval: interval.split(' ')[1],
            sub_filter: subscribers?.length > 0 ? `AND username in (${subscribers?.map(sb => `'${sb}'`).join(',')})` : '',
            plan_filter: plans?.length > 0 ? `AND planName in (${plans?.map(plan => `'${plan}'`).join(',')})` : ''
        }
    }
    api
        .postRequestOut(`/store/ch/query`, subscribersLine)
        .then((res) => handleResponse(res, dispatch, GET_CAPTIVE_DASHBOARD_SUBSCRIBERS_LINE_CHART))
        .catch((err) => handleError(err, dispatch));
};

export const getCaptiveDashSessionsLineChart = (vessels, startDate, endDate, interval, subscribers, plans) => (dispatch) => {
    dispatch({ type: SET_CAPTIVE_DASHBOARD_SESSIONS_LINE_CHART, payload: true });
    const sessionsLine: any = {
        query: 'CAPTIVE_DASHBOARD_SESSIONS_LINE_CHART',
        named: true,
        format: "chart",
        groupBy: "type",
        series: "SessionsCount",
        tsColumn: "timeStamp",
        parameters: {
            k4Id: vessels && vessels.length > 0 ? `${vessels.map((item:any) => `'${item.id}'`).join(",")}` : '',
            startDate: startDate,
            endDate: endDate,
            intervalNum: interval.split(' ')[0],
            interval: interval.split(' ')[1],
            sub_filter: subscribers?.length > 0 ? `AND username in (${subscribers?.map(sb => `'${sb}'`).join(',')})` : '',
            plan_filter: plans?.length > 0 ? `AND planName in (${plans?.map(plan => `'${plan}'`).join(',')})` : ''
        }
    }
    api
        .postRequestOut(`/store/ch/query`, sessionsLine)
        .then((res) => handleResponse(res, dispatch, GET_CAPTIVE_DASHBOARD_SESSIONS_LINE_CHART))
        .catch((err) => handleError(err, dispatch));
};

export const getCaptiveDashUsageLineChart = (vessels, startDate, endDate, interval, subscribers, plans) => (dispatch) => {
    dispatch({ type: SET_CAPTIVE_DASHBOARD_USAGE_LINE_CHART, payload: true });
    const usageLine: any = {
        query: 'CAPTIVE_DASHBOARD_USAGE_LINE_CHART_WITH_FILTERS',
        named: true,
        format: "chart",
        groupBy: "type",
        series: "quotaUsed",
        tsColumn: "timeStamp",
        parameters: {
            k4Id: vessels && vessels.length > 0 ? `${vessels.map((item:any) => `'${item.id}'`).join(",")}` : '',
            startDate: startDate,
            endDate: endDate,
            intervalNum: interval.split(' ')[0],
            interval: interval.split(' ')[1],
            sub_filter: subscribers?.length > 0 ? `AND username in (${subscribers?.map(sb => `'${sb}'`).join(',')})` : '',
            plan_filter: plans?.length > 0 ? `AND planName in (${plans?.map(plan => `'${plan}'`).join(',')})` : ''
        }
    }
    api
        .postRequestOut(`/store/ch/query`, usageLine)
        .then((res) => handleResponse(res, dispatch, GET_CAPTIVE_DASHBOARD_USAGE_LINE_CHART))
        .catch((err) => handleError(err, dispatch));
};

export const getCaptiveDashSitesTable = (vessels, startDate, endDate, page, limit, sortColumn, sortOrder, search, subscribers) => (dispatch) => {
    dispatch({ type: SET_CAPTIVE_DASHBOARD_SITES_TABLE, payload: true });
    const sitesTable: any = {
        query: 'CAPTIVE_DASHBOARD_SITES_TABLE',
        named: true,
        format: "json",
        parameters: {
            k4Id: vessels && vessels.length > 0 ? `${vessels.map((item:any) => `'${item.id}'`).join(",")}` : '',
            startDate: startDate,
            endDate: endDate,
            search: search,
            sortColumn: sortColumn,
            sortOrder: (sortColumn == 'siteName' || sortColumn == 'organizationName') ? `${sortOrder} collate 'en'` : sortOrder,
            pageOffset: ((page - 1) * limit),
            pageSize: limit,
            sub_filter: subscribers?.length > 0 ? `AND username in (${subscribers?.map(sb => `'${sb}'`).join(',')})` : ''
        }
    }
    api
        .postRequestOut(`/store/ch/query`, sitesTable)
        .then((res) => handleResponse(res, dispatch, GET_CAPTIVE_DASHBOARD_SITES_TABLE))
        .catch((err) => handleError(err, dispatch));
};

export const getCaptiveDashSubscribersSessionsTable = (vessels, startDate, endDate, page, limit, sortColumn, sortOrder, search, subscribers, plans) => (dispatch) => {
    dispatch({ type: SET_CAPTIVE_DASHBOARD_SUBSCRIBERS_SESSIONS_TABLE, payload: true });
    const subscribersSessionsTable: any = {
        query: 'CAPTIVE_DASHBOARD_SUBSCRIBERS_TABLE',
        named: true,
        format: "json",
        parameters: {
            k4Id: vessels && vessels.length > 0 ? `${vessels.map((item:any) => `'${item.id}'`).join(",")}` : '',
            startDate: startDate,
            endDate: endDate,
            search: search,
            sortColumn: sortColumn,
            sortOrder: sortOrder,
            pageOffset: ((page - 1) * limit),
            pageSize: limit,
            sub_filter: subscribers?.length > 0 ? `AND username in (${subscribers?.map(sb => `'${sb}'`).join(',')})` : '',
            plan_filter: plans?.length > 0 ? `AND planName in (${plans?.map(plan => `'${plan}'`).join(',')})` : ''
        }
    }
    api
        .postRequestOut(`/store/ch/query`, subscribersSessionsTable)
        .then((res) => handleResponse(res, dispatch, GET_CAPTIVE_DASHBOARD_SUBSCRIBERS_SESSIONS_TABLE))
        .catch((err) => handleError(err, dispatch));
};

export const getCaptiveDashSitesHistogram = (vessels, startDate, endDate, subscribers, binSize, minRange, maxRange) => (dispatch) => {
    dispatch({ type: SET_CAPTIVE_DASHBOARD_SITES_HISTOGRAM, payload: true });
    const sitesHistogram: any = {
        query: 'CAPTIVE_DASHBOARD_SITES_HISTOGRAM',
        named: true,
        format: "json",
        parameters: {
            k4Id: vessels && vessels.length > 0 ? `${vessels.map((item:any) => `'${item.id}'`).join(",")}` : '',
            startDate: startDate,
            endDate: endDate,
            sub_filter: subscribers?.length > 0 ? `AND username in (${subscribers?.map(sb => `'${sb}'`).join(',')})` : '',
            binSize: binSize,
            usage_filter: minRange && maxRange ? `Where actualQuotaUsed >= ${minRange} and actualQuotaUsed <= ${maxRange}` : ''
        }
    }
    api
        .postRequestOut(`/store/ch/query`, sitesHistogram)
        .then((res) => handleResponse(res, dispatch, GET_CAPTIVE_DASHBOARD_SITES_HISTOGRAM))
        .catch((err) => handleError(err, dispatch));
};

// Plans
export const getCaptiveDashTopPlansUsersBar = (vessels, startDate, endDate, pieLimit, page, plans, durations, quotas, status, startFrom, startTo, endFrom, endTo, autoRenewal) => (dispatch) => {
    dispatch({ type: SET_CAPTIVE_DASHBOARD_TOP_PLANS_USERS_BAR, payload: true });
    const topPlansUsersBar: any = {
        query: 'CAPTIVE_DASHBOARD_TOP_PLANS_USERS_BAR',
        named: true,
        format: "csv",
        groupBy: 'planName',
        parameters: {
            k4Id: vessels && vessels.length > 0 ? `${vessels.map((item:any) => `'${item.id}'`).join(",")}` : '',
            startDate: startDate,
            endDate: endDate,
            recLimit: pieLimit,
            pageOffset: ((page - 1) * 10),
            pageSize: 10,
            plan_filter: plans?.length > 0 ? `AND planName IN (${plans?.map(plan => `'${plan}'`).join(',')})` : '',
            duration_filter: durations?.length > 0 ? `AND duration IN (${durations?.map(duration => `'${duration}'`).join(',')})` : '',
            quota_filter: quotas?.length > 0 ? `AND quota IN (${quotas?.map(quota => `'${quota * (1000 * 1000 * 1000)}'`).join(',')})` : '',
            status_filter: status ? `AND isPlanExpired = ${status == 'active' ? 0 : 1}` : '',
            startDate_filter: startFrom && startTo ? `AND planStartDate > parseDateTime64BestEffort('${startFrom.toISOString()}') AND planStartDate < parseDateTime64BestEffort('${startTo.toISOString()}')` : 
                              startFrom ? `AND planStartDate > parseDateTime64BestEffort('${startFrom.toISOString()}') AND planStartDate < parseDateTime64BestEffort('${endDate.toISOString()}')` : '',
            endDate_filter: endFrom && endTo ? `AND planEndDate > parseDateTime64BestEffort('${endFrom.toISOString()}') AND planEndDate < parseDateTime64BestEffort('${endTo.toISOString()}')` :
                            endFrom ? `AND planEndDate > parseDateTime64BestEffort('${endFrom.toISOString()}') AND planEndDate < parseDateTime64BestEffort('${endDate.toISOString()}')` : '',
            autoRenewal_filter: autoRenewal ? "AND autoRenewal = true" : ""
        }
    }
    api
        .postRequestOut(`/store/ch/query`, topPlansUsersBar)
        .then((res) => handleResponse(res, dispatch, GET_CAPTIVE_DASHBOARD_TOP_PLANS_USERS_BAR))
        .catch((err) => handleError(err, dispatch));
};

export const getCaptiveDashTopPlansUsageBar = (vessels, startDate, endDate, pieLimit, page, plans, durations, quotas, status, startFrom, startTo, endFrom, endTo, autoRenewal) => (dispatch) => {
    dispatch({ type: SET_CAPTIVE_DASHBOARD_TOP_PLANS_USAGE_BAR, payload: true });
    const topPlansUsageBar: any = {
        query: 'CAPTIVE_DASHBOARD_TOP_PLANS_USAGE_BAR',
        named: true,
        format: "csv",
        groupBy: 'planName',
        parameters: {
            k4Id: vessels && vessels.length > 0 ? `${vessels.map((item:any) => `'${item.id}'`).join(",")}` : '',
            startDate: startDate,
            endDate: endDate,
            recLimit: pieLimit,
            pageOffset: ((page - 1) * 10),
            pageSize: 10,
            plan_filter: plans?.length > 0 ? `AND planName IN (${plans?.map(plan => `'${plan}'`).join(',')})` : '',
            duration_filter: durations?.length > 0 ? `AND duration IN (${durations?.map(duration => `'${duration}'`).join(',')})` : '',
            quota_filter: quotas?.length > 0 ? `AND quota IN (${quotas?.map(quota => `'${quota * (1000 * 1000 * 1000)}'`).join(',')})` : '',
            status_filter: status ? `AND isPlanExpired = ${status == 'active' ? 0 : 1}` : '',
            startDate_filter: startFrom && startTo ? `AND planStartDate > parseDateTime64BestEffort('${startFrom.toISOString()}') AND planStartDate < parseDateTime64BestEffort('${startTo.toISOString()}')` : 
                              startFrom ? `AND planStartDate > parseDateTime64BestEffort('${startFrom.toISOString()}') AND planStartDate < parseDateTime64BestEffort('${endDate.toISOString()}')` : '',
            endDate_filter: endFrom && endTo ? `AND planEndDate > parseDateTime64BestEffort('${endFrom.toISOString()}') AND planEndDate < parseDateTime64BestEffort('${endTo.toISOString()}')` :
                            endFrom ? `AND planEndDate > parseDateTime64BestEffort('${endFrom.toISOString()}') AND planEndDate < parseDateTime64BestEffort('${endDate.toISOString()}')` : '',
            autoRenewal_filter: autoRenewal ? "AND autoRenewal = true" : ""
        }
    }
    api
        .postRequestOut(`/store/ch/query`, topPlansUsageBar)
        .then((res) => handleResponse(res, dispatch, GET_CAPTIVE_DASHBOARD_TOP_PLANS_USAGE_BAR))
        .catch((err) => handleError(err, dispatch));
};

export const getCaptiveDashPlansSubscribersHistogram = (vessels, startDate, endDate, plans, durations, quotas, status, startFrom, startTo, endFrom, endTo, autoRenewal, binSize, minRange, maxRange) => (dispatch) => {
    dispatch({ type: SET_CAPTIVE_DASHBOARD_PLANS_SUBSCRIBERS_HISTOGRAM, payload: true });
    const topSitesSessionsPie: any = {
        query: 'CAPTIVE_DASHBOARD_PLANS_SUBSCRIBERS_HISTOGRAM',
        named: true,
        format: "json",
        parameters: {
            k4Id: vessels && vessels.length > 0 ? `${vessels.map((item:any) => `'${item.id}'`).join(",")}` : '',
            startDate: startDate,
            endDate: endDate,
            plan_filter: plans?.length > 0 ? `AND planName IN (${plans?.map(plan => `'${plan}'`).join(',')})` : '',
            duration_filter: durations?.length > 0 ? `AND duration IN (${durations?.map(duration => `'${duration}'`).join(',')})` : '',
            quota_filter: quotas?.length > 0 ? `AND quota IN (${quotas?.map(quota => `'${quota * (1000 * 1000 * 1000)}'`).join(',')})` : '',
            status_filter: status ? `AND isPlanExpired = ${status == 'active' ? 0 : 1}` : '',
            startDate_filter: startFrom && startTo ? `AND planStartDate > parseDateTime64BestEffort('${startFrom.toISOString()}') AND planStartDate < parseDateTime64BestEffort('${startTo.toISOString()}')` : 
                              startFrom ? `AND planStartDate > parseDateTime64BestEffort('${startFrom.toISOString()}') AND planStartDate < parseDateTime64BestEffort('${endDate.toISOString()}')` : '',
            endDate_filter: endFrom && endTo ? `AND planEndDate > parseDateTime64BestEffort('${endFrom.toISOString()}') AND planEndDate < parseDateTime64BestEffort('${endTo.toISOString()}')` :
                            endFrom ? `AND planEndDate > parseDateTime64BestEffort('${endFrom.toISOString()}') AND planEndDate < parseDateTime64BestEffort('${endDate.toISOString()}')` : '',
            autoRenewal_filter: autoRenewal ? "AND autoRenewal = true" : "",
            binSize: binSize,
            usage_filter: minRange && maxRange ? `Where actualQuotaUsed >= ${minRange} and actualQuotaUsed <= ${maxRange}` : ''
        }
    }
    api
        .postRequestOut(`/store/ch/query`, topSitesSessionsPie)
        .then((res) => handleResponse(res, dispatch, GET_CAPTIVE_DASHBOARD_PLANS_SUBSCRIBERS_HISTOGRAM))
        .catch((err) => handleError(err, dispatch));
};

export const getCaptiveDashPlansUsageLineCharts = (vessels, startDate, endDate, interval, recLimit, plans, durations, quotas, status, startFrom, startTo, endFrom, endTo, autoRenewal) => (dispatch) => {
    dispatch({ type: SET_CAPTIVE_DASHBOARD_PLANS_USAGE_LINE_CHART, payload: true });
    const plansLineChart: any = {
        query: 'CAPTIVE_DASHBOARD_PLANS_USAGE_LINE_CHART_WITH_FILTER',
        named: true,
        format: "chart",
        groupBy: "planName",
        series: "quotaUsed",
        tsColumn: "timeStamp",
        parameters: {
            k4Id: vessels && vessels.length > 0 ? `${vessels.map((item:any) => `'${item.id}'`).join(",")}` : '',
            startDate: startDate,
            endDate: endDate,
            intervalNum: interval.split(' ')[0],
            interval: interval.split(' ')[1],
            recLimit: recLimit == 'all' ? '' : `limit ${recLimit}`,
            plan_filter: plans?.length > 0 ? `AND planName IN (${plans?.map(plan => `'${plan}'`).join(',')})` : '',
            duration_filter: durations?.length > 0 ? `AND duration IN (${durations?.map(duration => `'${duration}'`).join(',')})` : '',
            quota_filter: quotas?.length > 0 ? `AND quota IN (${quotas?.map(quota => `'${quota * (1000 * 1000 * 1000)}'`).join(',')})` : '',
            status_filter: status ? `AND isPlanExpired = ${status == 'active' ? 0 : 1}` : '',
            startDate_filter: startFrom && startTo ? `AND planStartDate > parseDateTime64BestEffort('${startFrom.toISOString()}') AND planStartDate < parseDateTime64BestEffort('${startTo.toISOString()}')` : 
                              startFrom ? `AND planStartDate > parseDateTime64BestEffort('${startFrom.toISOString()}') AND planStartDate < parseDateTime64BestEffort('${endDate.toISOString()}')` : '',
            endDate_filter: endFrom && endTo ? `AND planEndDate > parseDateTime64BestEffort('${endFrom.toISOString()}') AND planEndDate < parseDateTime64BestEffort('${endTo.toISOString()}')` :
                            endFrom ? `AND planEndDate > parseDateTime64BestEffort('${endFrom.toISOString()}') AND planEndDate < parseDateTime64BestEffort('${endDate.toISOString()}')` : '',
            autoRenewal_filter: autoRenewal ? "AND autoRenewal = true" : ""
        }
    }
    api
        .postRequestOut(`/store/ch/query`, plansLineChart)
        .then((res) => handleResponse(res, dispatch, GET_CAPTIVE_DASHBOARD_PLANS_USAGE_LINE_CHART))
        .catch((err) => handleError(err, dispatch));
};

export const getCaptiveDashPlansSubscribersLineCharts = (vessels, startDate, endDate, interval, recLimit, plans, durations, quotas, status, startFrom, startTo, endFrom, endTo, autoRenewal) => (dispatch) => {
    dispatch({ type: SET_CAPTIVE_DASHBOARD_PLANS_SUBSCRIBERS_LINE_CHART, payload: true });
    const subscribersLineChart: any = {
        query: 'CAPTIVE_DASHBOARD_PLANS_SUBSCRIBERS_LINE_CHART',
        named: true,
        format: "chart",
        groupBy: "planGroupKey",
        series: "subscribers",
        tsColumn: "ts",
        parameters: {
            k4Id: vessels && vessels.length > 0 ? `${vessels.map((item:any) => `'${item.id}'`).join(",")}` : '',
            startDate: startDate,
            endDate: endDate,
            intervalNum: interval.split(' ')[0],
            interval: interval.split(' ')[1],
            recLimit: recLimit == 'all' ? '' : `${recLimit}`,
            plan_filter: plans?.length > 0 ? `AND planName IN (${plans?.map(plan => `'${plan}'`).join(',')})` : '',
            duration_filter: durations?.length > 0 ? `AND duration IN (${durations?.map(duration => `'${duration}'`).join(',')})` : '',
            quota_filter: quotas?.length > 0 ? `AND quota IN (${quotas?.map(quota => `'${quota * (1000 * 1000 * 1000)}'`).join(',')})` : '',
            status_filter: status ? `AND isPlanExpired = ${status == 'active' ? 0 : 1}` : '',
            startDate_filter: startFrom && startTo ? `AND planStartDate > parseDateTime64BestEffort('${startFrom.toISOString()}') AND planStartDate < parseDateTime64BestEffort('${startTo.toISOString()}')` : 
                              startFrom ? `AND planStartDate > parseDateTime64BestEffort('${startFrom.toISOString()}') AND planStartDate < parseDateTime64BestEffort('${endDate.toISOString()}')` : '',
            endDate_filter: endFrom && endTo ? `AND planEndDate > parseDateTime64BestEffort('${endFrom.toISOString()}') AND planEndDate < parseDateTime64BestEffort('${endTo.toISOString()}')` :
                            endFrom ? `AND planEndDate > parseDateTime64BestEffort('${endFrom.toISOString()}') AND planEndDate < parseDateTime64BestEffort('${endDate.toISOString()}')` : '',
            autoRenewal_filter: autoRenewal ? "AND autoRenewal = true" : ""
        }
    }
    api
        .postRequestOut(`/store/ch/query`, subscribersLineChart)
        .then((res) => handleResponse(res, dispatch, GET_CAPTIVE_DASHBOARD_PLANS_SUBSCRIBERS_LINE_CHART))
        .catch((err) => handleError(err, dispatch));
};

export const getCaptiveDashPlanTable = (vessels, startDate, endDate, page, limit, sortColumn, sortOrder, search, plans, durations, quotas, status, startFrom, startTo, endFrom, endTo, autoRenewal) => (dispatch) => {
    console.log('status', endDate.toISOString())
    dispatch({ type: SET_CAPTIVE_DASHBOARD_EVENTS_SUBSCRIBERS_PLAN_USAGE, payload: true });
    const subscribersTable: any = {
        query: 'CAPTIVE_DASHBOARD_PLANS_TABLE_V2_WITH_FILTER',
        named: true,
        format: "json",
        parameters: {
            k4Id: vessels && vessels.length > 0 ? `${vessels.map((item:any) => `'${item.id}'`).join(",")}` : '',
            startDate: startDate,
            endDate: endDate,
            search: search,
            sortColumn: sortColumn,
            sortOrder: sortOrder,
            pageOffset: ((page - 1) * limit),
            pageSize: limit,
            plan_filter: plans?.length > 0 ? `AND planName IN (${plans?.map(plan => `'${plan}'`).join(',')})` : '',
            duration_filter: durations?.length > 0 ? `AND duration IN (${durations?.map(duration => `'${duration}'`).join(',')})` : '',
            quota_filter: quotas?.length > 0 ? `AND quota IN (${quotas?.map(quota => `'${quota * (1000 * 1000 * 1000)}'`).join(',')})` : '',
            status_filter: status ? `AND isPlanExpired = ${status == 'active' ? 0 : 1}` : '',
            startDate_filter: startFrom && startTo ? `AND planStartDate > parseDateTime64BestEffort('${startFrom.toISOString()}') AND planStartDate < parseDateTime64BestEffort('${startTo.toISOString()}')` : 
                              startFrom ? `AND planStartDate > parseDateTime64BestEffort('${startFrom.toISOString()}') AND planStartDate < parseDateTime64BestEffort('${endDate.toISOString()}')` : '',
            endDate_filter: endFrom && endTo ? `AND planEndDate > parseDateTime64BestEffort('${endFrom.toISOString()}') AND planEndDate < parseDateTime64BestEffort('${endTo.toISOString()}')` :
                            endFrom ? `AND planEndDate > parseDateTime64BestEffort('${endFrom.toISOString()}') AND planEndDate < parseDateTime64BestEffort('${endDate.toISOString()}')` : '',
            autoRenewal_filter: autoRenewal ? "AND autoRenewal = true" : ""

        }
    }
    api
        .postRequestOut(`/store/ch/query`, subscribersTable)
        .then((res) => handleResponse(res, dispatch, GET_CAPTIVE_DASHBOARD_EVENTS_SUBSCRIBERS_PLAN_USAGE))
        .catch((err) => handleError(err, dispatch));
};

export const getCaptiveDashUserSessionsTable = (startDate, endDate, page, limit, sortColumn, sortOrder, search, userPlanId) => (dispatch) => {
    dispatch({ type: SET_CAPTIVE_DASHBOARD_SUBSCRIBER_SESSIONS_TABLE, payload: true });
    const subscribersSessionsTable: any = {
        query: 'CAPTIVE_DASHBOARD_SUBSCRIBER_SESSIONS_TABLE',
        named: true,
        format: "json",
        parameters: {
            startDate: startDate,
            endDate: endDate,
            search: search,
            sortColumn: sortColumn,
            sortOrder: sortOrder,
            pageOffset: ((page - 1) * limit),
            pageSize: limit,
            userPlanId: `'${userPlanId}'`,
        }
    }
    api
        .postRequestOut(`/store/ch/query`, subscribersSessionsTable)
        .then((res) => handleResponse(res, dispatch, GET_CAPTIVE_DASHBOARD_SUBSCRIBER_SESSIONS_TABLE))
        .catch((err) => handleError(err, dispatch));
};

export const getCaptiveDashEventsTable = (vessels, startDate, endDate, page, limit, sortColumn, sortOrder, search, userNames, ips, macs, actions, types, dpId) => (dispatch) => {
    dispatch({ type: SET_CAPTIVE_DASHBOARD_EVENTS_TABLE, payload: true });
    const eventsTable: any = {
        query: 'CAPTIVE_DASHBOARD_EVENTS_TABLE_WITH_FILTERS',
        named: true,
        format: "json",
        parameters: {
            dpId,
            k4IdFilter: vessels && vessels.length > 0 ? `AND k4Id In [${vessels.map((item:any) => `'${item.id}'`).join(",")}]` : '',
            startDate: startDate,
            endDate: endDate,
            search: search,
            sortColumn: sortColumn,
            sortOrder: sortOrder,
            pageOffset: ((page - 1) * limit),
            pageSize: limit,
            userName_filter: userNames?.length > 0 ? `AND userName IN (${userNames?.map(user => `'${user}'`).join(',')})` : '',
            ip_filter: ips?.length > 0 ? `AND ipAddress IN (${ips?.map(ip => `'${ip}'`).join(',')})` : '',
            mac_filter: macs?.length > 0 ? `AND macAddress IN (${macs?.map(mac => `'${mac}'`).join(',')})` : '',
            action_filter: actions?.length > 0 ? `AND action IN (${actions?.map(action => `'${action}'`).join(',')})` : '',
            eventType_filter: types?.length > 0 ? `AND eventType IN (${types?.map(type => `'${type}'`).join(',')})` : ''
        }
    }
    api
        .postRequestOut(`/store/ch/query`, eventsTable)
        .then((res) => handleResponse(res, dispatch, GET_CAPTIVE_DASHBOARD_EVENTS_TABLE))
        .catch((err) => handleError(err, dispatch));
};

// Filters list
export const getCaptiveDashSubscribersList = (vessels, startDate, endDate) => (dispatch) => {
    dispatch({ type: SET_CAPTIVE_DASHBOARD_SUBSCRIBERS_LIST, payload: true });
    const subscribersList: any = {
        query: 'CAPTIVE_DASHBOARD_SUBSCRIBERS_LIST',
        named: true,
        format: "json",
        parameters: {
            k4Id: vessels && vessels.length > 0 ? `${vessels.map((item:any) => `'${item.id}'`).join(",")}` : '',
            startDate: startDate,
            endDate: endDate
        }
    }
    api
        .postRequestOut(`/store/ch/query`, subscribersList)
        .then((res) => handleResponse(res, dispatch, GET_CAPTIVE_DASHBOARD_SUBSCRIBERS_LIST))
        .catch((err) => handleError(err, dispatch));
};

export const getCaptiveDashPlansList = (vessels, startDate, endDate) => (dispatch) => {
    dispatch({ type: SET_CAPTIVE_DASHBOARD_PLANS_LIST, payload: true });
    const plansFiltersList: any = {
        query: 'CAPTIVE_DASHBOARD_PLANS_FILTER_LIST',
        named: true,
        format: "json",
        parameters: {
            k4Id: vessels && vessels.length > 0 ? `${vessels.map((item:any) => `'${item.id}'`).join(",")}` : '',
            startDate: startDate,
            endDate: endDate
        }
    }
    api
        .postRequestOut(`/store/ch/query`, plansFiltersList)
        .then((res) => handleResponse(res, dispatch, GET_CAPTIVE_DASHBOARD_PLANS_LIST))
        .catch((err) => handleError(err, dispatch));
};

export const getCaptiveDashIpMacsList = (vessels, startDate, endDate) => (dispatch) => {
    dispatch({ type: SET_CAPTIVE_DASHBOARD_EVENTS_IP_MAC_LIST, payload: true });
    const ipMacList: any = {
        query: 'CAPTIVE_DASHBOARD_EVENTS_FILTER_LIST',
        named: true,
        format: "json",
        parameters: {
            k4Id: vessels && vessels.length > 0 ? `${vessels.map((item:any) => `'${item.id}'`).join(",")}` : '',
            startDate: startDate,
            endDate: endDate
        }
    }
    api
        .postRequestOut(`/store/ch/query`, ipMacList)
        .then((res) => handleResponse(res, dispatch, GET_CAPTIVE_DASHBOARD_EVENTS_IP_MAC_LIST))
        .catch((err) => handleError(err, dispatch));
};