import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Button, Dialog, DialogContent, DialogTitle, Divider, Grid, List, ListItemButton, Stack, Typography, Box, FormControl, Select, MenuItem, Checkbox, ListItemText, Slider, Switch, FormControlLabel } from "@mui/material";
import React, { useEffect, useState } from "react";
import close from "../../../asset/image/close.svg";
import _ from "lodash";
import { checkLoggedInUserAuthorizedToViewPage, getDataFromLocalStorage, getDecodeURI } from "../../../utils/util";
import { getAvailableProductsForAccount, assignCustomPlan, updateNonAdminPermissions, getNonAdminPermissions, getParentDP } from "../slice";
import { SERVICE_FUNCTION_TO_FEATURE } from '../../../config';
import { USER_OU } from "../../../constants/Constants";
import OrgSelection from "../../OrgSelection";
import MobilePriority from "../ServiceLines/MobilePriority";
var Loader = require("react-loader");

const EditActionsPopup = (props) => {
    const { loading, open, setOpen, confirmDialog, setConfirmDialog, location, authReducer, allNonAdminPermissions, updateNonAdminPermissions, getNonAdminPermissions, getParentDP, parentDp } = props;
    const STARLINK_ADMIN_SF = "starlink-admin";
    const _userOu: any = JSON.parse(getDataFromLocalStorage(USER_OU) || "{}");
    const starlinkAdminService = SERVICE_FUNCTION_TO_FEATURE[STARLINK_ADMIN_SF]["service"];
    const starlinkAdminFunction = SERVICE_FUNCTION_TO_FEATURE[STARLINK_ADMIN_SF]["function"];
    const params = getDecodeURI(location?.search)


    const [selectedRole, setSelectedRole] = useState("starlinkAdminAccess"); // Default role
    const [permissions, setPermissions] = useState({
        starlinkAdminAccess: {
            mobilePriority: { allowed: true, enabled: true },
            pauseServicePlans: { allowed: false, enabled: true },
            servicePlan: { allowed: false, enabled: true },
            terminals: { allowed: false, enabled: true },
            publicIp: { allowed: false, enabled: true },
        },
        starlinkFullAccess: {
            mobilePriority: { allowed: false, enabled: true },
            pauseServicePlans: { allowed: false, enabled: true },
            servicePlan: { allowed: false, enabled: true },
            terminals: { allowed: false, enabled: true },
            publicIp: { allowed: false, enabled: true },
        },
        starlinkAccess: {
            mobilePriority: { allowed: false, enabled: true },
            pauseServicePlans: { allowed: false, enabled: true },
            servicePlan: { allowed: false, enabled: true },
            terminals: { allowed: false, enabled: true },
            publicIp: { allowed: false, enabled: true },
        }
    });


    const handleRoleChange = (event) => {
        setSelectedRole(event.target.value);
    };

    const handleToggle = (key) => {
        setPermissions((prevPermissions) => ({
            ...prevPermissions,
            [selectedRole]: {
                ...prevPermissions[selectedRole],
                [key]: {
                    ...prevPermissions[selectedRole][key], // Keep `enabled` unchanged
                    allowed: !prevPermissions[selectedRole][key].allowed, // Toggle `allowed`
                }
            }
        }));
    };




    useEffect(() => {
        if (!_.isEmpty(allNonAdminPermissions)) {
            setPermissions(allNonAdminPermissions)
        }
    }, [allNonAdminPermissions])

    const [selectedDp, setSelectedDp] = useState<any>(null);
    const [disableActions, setDisableActions] = useState<boolean>(false);
    const deviceActionsList = [
        checkLoggedInUserAuthorizedToViewPage(starlinkAdminService, starlinkAdminFunction) ? "CONFIGURE" : null,
    ].filter(action => action);

    useEffect(() => {
        if (allNonAdminPermissions) {

            if (!_.isEmpty(allNonAdminPermissions)) {

            } else {

            }
        }
    }, [allNonAdminPermissions])


    const getIdNameForList = (action) => {
        if (action == "CONFIGURE") {
            if (checkLoggedInUserAuthorizedToViewPage(starlinkAdminService, starlinkAdminFunction)) {
                return 'actionType-enabled';
            }
        }
        return 'actionType-disabled';
    }

    const getDefaultActionType = (list) => {
        const actionTypes = list.map(action => {
            if (getIdNameForList(action) == 'actionType-enabled') {
                return action;
            } else {
                return '';
            }
        })

        return actionTypes?.filter(action => action != '')?.length > 0 ? actionTypes?.filter(action => action != '')?.[0] : deviceActionsList[0];
    }

    const [selectedAction, setSelectedAction] = useState(getDefaultActionType(deviceActionsList));


    const updateActionValues = (action) => {
        switch (action) {
            case "CONFIGURE": {
                break;
            }

            default: setConfirmDialog({ ...confirmDialog, subTitle: '', isOpen: false })
        }
    }

    useEffect(() => {
        updateActionValues(selectedAction);
    }, [selectedAction])

    const handleChangeActionType = (actionType) => {
        setSelectedAction(actionType);
        selectedAction != actionType && setConfirmDialog({ ...confirmDialog, subTitle: '', isOpen: false });
    }

    const handleConfirmClick = () => {
        confirmDialog.onConfirm();
    }

    const handleClose = () => {
        setOpen(false);
        setConfirmDialog({ ...confirmDialog, subTitle: '', isOpen: false });
    }

    const handleSavePermissions = async () => {

        const formattedPermissions = Object.fromEntries(
            Object.entries(permissions).map(([role, perms]) => [role,Object.fromEntries(  Object.entries(perms).map(([perm, { allowed }])=>[perm, allowed])  )  ]) );
    
        let params = { organizationId: selectedDp?.id,
         data: {data: formattedPermissions} 
        };
    
        await updateNonAdminPermissions(params);
        handleClose();
    };
    

    useEffect(() => {
        setSelectedDp({ id: _userOu?.id, name: _userOu?.name })
        getParentDP({ dp: authReducer?.selectedOu?.name })
    }, [])

    useEffect(() => {
        if (!_.isEmpty(parentDp)) {
            getNonAdminPermissions({ dpId: selectedDp?.id, parentDpId: parentDp?.parentOUId })
        }
    }, [parentDp])

    const handleChangeDp = async (ou: any) => {
        setSelectedDp(ou)
        if (!_.isEmpty(parentDp)) {
            getNonAdminPermissions({ dpId: ou?.id, parentDpId: _userOu?.id })
        }

    }

    return (
        <div className="Form">
            <Dialog
                disableEnforceFocus
                open={open}
                onClose={handleClose}
                aria-labelledby='form-dialog-title'
                fullWidth={true}
                maxWidth={'md'}
                className='config-deploy-starlink-popup menuScroll inventory-warehouse-register-pop-up inventory-edit-actions-container'
            >
                <div>
                    <DialogTitle className='create-default-config-title edit-actions-title'>
                        <div>
                            <span>Permissions</span>
                            <span className="selected-site-device starlink-admin-operations-popup-service-line-name">{`Organization: ${authReducer?.selectedOu ? authReducer?.selectedOu?.name : ''} `}</span>
                        </div>
                        <div>
                            {loading ? (
                                <div className="commonLoader_NewSummary wan-profile-interfaces-loader editActions-loader">
                                    <Loader radius={4} length={5} lines={10} width={2} color={"#264C86"} />
                                </div>
                            ) : null}
                            <img src={close} title="Close" onClick={handleClose} />
                        </div>
                    </DialogTitle>
                    <DialogContent className="edit-actions-content">
                        <Grid container spacing={1}>
                            <Grid xs={3} className="menuScroll device-border-right deployed-device-list" item>
                                <Grid className="device-data-body actions-device-data-body">
                                    <List>
                                        {deviceActionsList && deviceActionsList.map((identifier, index) => {
                                            return <ListItemButton id={getIdNameForList(identifier)} className='status-lists'
                                                style={identifier === selectedAction ? { background: 'rgba(244, 245, 250, 1)' } : { background: '#fff' }}
                                                key={`device-${index}`}
                                                selected={identifier === selectedAction}
                                                onClick={() => getIdNameForList(identifier) == 'actionType-enabled' && handleChangeActionType(identifier)}>
                                                <Typography className={identifier === selectedAction ? "font-status-pop-up selectedAction" : "font-status-pop-up NotselectedAction"}>{identifier}</Typography>
                                            </ListItemButton>
                                        })}
                                    </List>
                                </Grid>
                            </Grid>
                            <Grid item xs={9} className="menuScroll starlinkAdmin-edit-actions-cont" style={{ minHeight: "250px" }}>
                                <Grid className="device-data-body edit-actions-data-body">
                                    <Stack className="sub-tab-parent" direction="column" spacing={1}>
                                        {confirmDialog.isOpen && <div className="custom_confirm_dialog_sub_title edit-actions-subTitle">
                                            {confirmDialog.subTitle}
                                            <div className="edit-actions-confirm-btns">
                                                <Divider className="confirm-dialogue-new-divider" />
                                                <div className="confirm-dialogue-new-btns">
                                                    <Button className="confirm-dialogue-cancel" onClick={handleClose}> Cancel </Button>
                                                    <Button className="confirm-dialogue-proceed" onClick={() => handleConfirmClick()}> Proceed </Button>
                                                </div>
                                            </div>
                                        </div>}
                                        {selectedAction == "CONFIGURE" && getIdNameForList(selectedAction) == 'actionType-enabled' &&
                                            <div>
                                                <Box className="starlink-serviceAccount-details-container">

                                                    <Box className="starlink-serviceAccount-details-row">
                                                        <Box className="starlink-serviceAccount-details-heading">Sub Organization </Box>
                                                        <Grid style={{ width: "200px" }} className="configuration-form-build">
                                                            <OrgSelection label={"Sub Organization"} value={selectedDp} onChange={handleChangeDp} disabled={disableActions} />

                                                        </Grid>
                                                    </Box>
                                                    <Box className="starlink-serviceAccount-details-row">
                                                        <Box className="starlink-serviceAccount-details-heading">Role</Box>
                                                        <Grid className="configuration-form-build">
                                                            <FormControl>
                                                                <Select
                                                                    id="mobile-priority-select"
                                                                    value={selectedRole} onChange={handleRoleChange}
                                                                    size="small"
                                                                    style={{ width: "200px" }}
                                                                >

                                                                    {/* <MenuItem value="starlinkAdminAccess" >Starlink Admin Access</MenuItem>
                                                                   
                                                                    <MenuItem value="starlinkFullAccess">Starlink Full Access</MenuItem>
                                                                    <MenuItem value="starlinkAccess">Starlink Access</MenuItem> */}
                                                                    {Object.keys(permissions)?.map((role) => (
                                                                        <MenuItem key={role} value={role}>
                                                                             {role.replace(/([A-Z])/g, " $1").replace(/^./, (str) => str.toUpperCase())}
                                                                        </MenuItem>
                                                                    ))}
                                                                </Select>
                                                            </FormControl>
                                                        </Grid>
                                                    </Box>

                                                    {Object.keys(permissions[selectedRole])?.map((perm) => (
                                                        <Box key={perm} className="starlink-serviceAccount-details-row">
                                                            <Box className="starlink-serviceAccount-details-heading">
                                                                {perm === "mobilePriority" ? "Mobile Priority" :
                                                                    perm === "pauseServicePlans" ? "Pause Resume Service Line" :
                                                                        perm === "servicePlan" ? "Update Service Plan" :
                                                                            perm === "terminals" ? "Manage Terminals" :
                                                                                perm === "publicIp" ? "Public IP Access" : perm}
                                                            </Box>

                                                            <Grid container className="starlink-account-service-permissions-actions-td">
                                                                <FormControlLabel
                                                                    control={
                                                                        <Switch
                                                                            checked={permissions[selectedRole][perm].allowed}
                                                                            disabled={!permissions[selectedRole][perm].enabled} // Only disable if `enabled` is false
                                                                            onChange={() => handleToggle(perm)}
                                                                            color="primary"
                                                                            inputProps={{ "aria-label": "controlled" }}
                                                                        />
                                                                    }
                                                                    labelPlacement="start"
                                                                    label={permissions[selectedRole][perm].allowed ? "Allowed" : "Not Allowed"}
                                                                />
                                                            </Grid>
                                                        </Box>
                                                    ))}





                                                </Box>

                                                <div className="edit-actions-confirm-btns" style={{ width: "73%" }}>
                                                    <Divider className="confirm-dialogue-new-divider" />
                                                    <div className="confirm-dialogue-new-btns">
                                                        <Button className="confirm-dialogue-cancel" onClick={handleClose}> Cancel </Button>
                                                        <Button className="confirm-dialogue-proceed" onClick={() => { handleSavePermissions() }}
                                                            disabled={false}>Save</Button>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </Stack>
                                </Grid>
                            </Grid>
                        </Grid>
                    </ DialogContent>
                </div>
            </Dialog>



        </div>
    )
}

const mapStateToProps = (state) => ({
    loading: state.starlinkAdmin.subcribingServiceLine,
    authReducer: state.authReducer,
    parentDp: state.starlinkAdmin.parentDp,
    allNonAdminPermissions: state.starlinkAdmin.allNonAdminPermissions
});

export default withRouter(
    connect(mapStateToProps, { getAvailableProductsForAccount, assignCustomPlan, updateNonAdminPermissions, getNonAdminPermissions, getParentDP })(EditActionsPopup)
);