// Accordion for alert types on dp level
import React, { useEffect, useState } from "react";
import { Grid, Accordion, AccordionSummary, Typography, AccordionDetails, Select, InputLabel, Button, IconButton, Switch, Tooltip } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import NotificationsOffIcon from '@mui/icons-material/NotificationsOff';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import MenuItem from '@mui/material/MenuItem';
import _ from "lodash";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getAlertChildInfo, updateAlert } from "../../components/AlertsDashboard/slice";
import ConfirmDialog from "../../components/ConfirmDialog";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { updateDpAlert, updateDpAlertNotification, updateDpAlertStatus, deleteDpAlertLinkAvailability } from "../../actions/Users/authenticateAlert";

const AccrodionComp = (props: any) => {
    const LINK_AVAILABILITY = "Link Availability"
    const INTERNET_STATUS = "Internet Status"
    const LOGIN_ATTEMPT = "Login Attempt"
    const { alert, getAlertChildInfo, alertChildDetails, handleCloseMenuOnMouseOut, currentDpId, updateDpAlert, updateDpAlertNotification, updateDpAlertStatus, deleteDpAlertLinkAvailability, authReducer}: {
        alert: any,
        currentDpId: string,
        getAlertChildInfo: any,
        alertChildDetails: any,
        handleCloseMenuOnMouseOut: any,
        updateDpAlert: any,
        updateDpAlertNotification: any,
        updateDpAlertStatus: any,
        deleteDpAlertLinkAvailability: any,
        authReducer: any
    } = props;


    const allInterfaces = [
        {
            label: "Ethernet",
            value: "wired"
        },
        {
            label: "Cellular",
            value: "lte"
        },
        {
            label: "Starlink",
            value: "starlink"
        },
        {
            label: "VSAT",
            value: "vsat"
        },
        {
            label: "WIFI",
            value: "wifi"
        },
        {
            label: "Lband",
            value: "lband"
        },
    ]
    const numerFieldErrorMessage = "Value must be greater than 0"
    const thresholdErrorMessage = "Value must between 1 and 100"
    const [isAccordionExapanded, setIsAccordionExapanded] = useState(false);
    const [linkAvailabilityConfig, setLinkAvailabilityConfig] = useState<any>([])
    const [internetStatusConfig, setInternetStatusConfig] = useState<any>({})
    const [loginAttemptConfig, setLoginAttemptConfig] = useState<any>({})
    const [wanTypes, setWanTypes] = useState<any>([])
    const [disableForm, setDisableForm] = useState<boolean>(false)
    const [isButtonVisible, setIsButtonVisible] = useState<boolean>(false)
    const [isFormValueChanged, setIsFormValueChanged] = useState<any>({
        linkAvailability: false,
        internetStatus: false,
        loginAttempt: false
    })
    const [confirmDialog, setConfirmDialog] = useState({
        isOpen: false,
        title: "",
        subTitle: "",
        onConfirm: {},
    });

    useEffect(() => {
        if (alert?.alert_name === LINK_AVAILABILITY) {
            const order = { "wired": 1, "lte": 2, "starlink": 3, "vsat": 4, "wifi": 5, "lband": 6 }
            let items = alert?.alert_config
            items?.sort((a, b) => order[a.wan_type] - order[b.wan_type])
            // probe loss=100-entry_threshold
            items = items.map(item => ({
            ...item, // Keep existing properties
            entry_threshold: Math.max(1, 100 - (parseInt(item?.entry_threshold) || 0))
            }));
            setLinkAvailabilityConfig(items)
            handleUpdateWanTypeList(alert?.alert_config)
        } else if (alert?.alert_name === INTERNET_STATUS) {
            setInternetStatusConfig(alert)
        } else if (alert?.alert_name === LOGIN_ATTEMPT) {
            setLoginAttemptConfig(alert)
        }
        setIsButtonVisible(false)
        setDisableForm(alert?.is_alert_status_disabled)
    }, [alert])

    useEffect(() => {
        if (isFormValueChanged?.linkAvailability) {
            handleLinkAvailabilityValidation()
        }
    }, [linkAvailabilityConfig])

    useEffect(() => {
        if (isFormValueChanged?.internetStatus) {
            handleInternetStatusValidation()
        }
    }, [internetStatusConfig])

    useEffect(() => {
        if (isFormValueChanged?.loginAttempt) {
            handleLoginAttemptValidation()
        }
    }, [loginAttemptConfig])

    const handleUpdateWanTypeList = (linkAvailabilityItems) => {
        const newWanTypes = allInterfaces.filter(item => !linkAvailabilityItems?.some(existing => existing.wan_type === item.value))
        setWanTypes(newWanTypes)
    }

    const handleAddWanType = () => {
        let existingLinkAvailabilities = [...linkAvailabilityConfig]
        existingLinkAvailabilities.push({
            wan_type: "",
            entry_duration: "",
            entry_threshold: "",
            exit_duration: "",
            exit_threshold: "",
            new_record: true
        })
        setLinkAvailabilityConfig(existingLinkAvailabilities)
    }

    const handleUpdateWanType = (updateIndex, wanTypeValue) => {
        let existingItems = [...linkAvailabilityConfig]
        for (let index in existingItems) {
            let item = existingItems[index]
            if (parseInt(index) === parseInt(updateIndex)) {
                item.wan_type = wanTypeValue
            }
        }

        setLinkAvailabilityConfig(existingItems)
        setIsButtonVisible(false)
    }

    const handleDeleteWanType = (deleteIndex, deleteItem) => {
        if (deleteItem?.new_record) {
            let existingItems = [...linkAvailabilityConfig]
            let updatedItems: any = []
            for (let index in existingItems) {
                let item = existingItems[index]
                if (parseInt(index) !== parseInt(deleteIndex)) {
                    updatedItems.push(item)
                }
            }
            setLinkAvailabilityConfig(updatedItems)
        }
    }

    const handleLinkAvailabilityChange = (wan_type: string, key: string, value: any) => {
        let existingRecords: any = [...linkAvailabilityConfig]
        for (let item of existingRecords) {
            if (item.wan_type === wan_type) {
                item[key] = value
            }
        }
        setLinkAvailabilityConfig(existingRecords)
        setIsFormValueChanged({ ...isFormValueChanged, linkAvailability: true })
    }

    const handleInternetStatusChange = (key, value) => {
        setInternetStatusConfig((prev) => ({ ...prev, [key]: value }))
        setIsFormValueChanged({ ...isFormValueChanged, internetStatus: true })
    }

    const handleLoginAttemptChange = (key, value) => {
        setLoginAttemptConfig((prev) => ({ ...prev, [key]: value }))
        setIsFormValueChanged({ ...isFormValueChanged, loginAttempt: true })
    }

    const handleFetchWanTypeValue = (wanType) => {
        let activeInterface = allInterfaces?.find(item => item?.value == wanType)
        if (!activeInterface) {
            return ""
        }
        return activeInterface?.label
    }

    const handleShowWantype = (wanType): boolean => {
        return linkAvailabilityConfig?.some(item => item?.wan_type === wanType)
    }

    const handleInvalidNumberField = (value): boolean => {
        let invalid: boolean = false
        if (Number.isNaN(value) || value <= 0) {
            invalid = true
        }
        return invalid
    }

    const handleInvalidThreshold = (threshold): boolean => {
        let invalid: boolean = false
        if (Number.isNaN(threshold) || threshold > 100 || threshold <= 0) {
            invalid = true
        }
        return invalid
    }

    const handleLinkAvailabilityValidation = () => {
        let existingLinkAvailabilities = [...linkAvailabilityConfig]
        let isVisible: boolean = true
        if (!linkAvailabilityConfig || !linkAvailabilityConfig?.length) {
            isVisible = false
        }
        for (let item of existingLinkAvailabilities) {
            let entryDuration = parseInt(item?.entry_duration)
            let entryThreshold = parseInt(item?.entry_threshold)
            let exitDuration = parseInt(item?.exit_duration)
            let exitThreshold = parseInt(item?.exit_threshold)
            if (["", null, undefined].includes(item?.wan_type) || handleInvalidNumberField(entryDuration) || handleInvalidNumberField(exitDuration)) {
                isVisible = false
            }
            if (handleInvalidThreshold(entryThreshold) || handleInvalidThreshold(exitThreshold)) {
                isVisible = false
            }
        }

        setIsButtonVisible(isVisible)
    }

    const handleInternetStatusValidation = () => {
        let isVisible: boolean = true
        if (!internetStatusConfig || !Object.keys(internetStatusConfig)?.length || handleInvalidNumberField(parseInt(internetStatusConfig?.entry_duration)) || handleInvalidNumberField(parseInt(internetStatusConfig?.exit_duration))) {
            isVisible = false
        }
        setIsButtonVisible(isVisible)
    }

    const handleLoginAttemptValidation = () => {
        let isVisible: boolean = true
        if (!loginAttemptConfig || !Object.keys(loginAttemptConfig)?.length || handleInvalidNumberField(parseInt(loginAttemptConfig?.login_failure_count)) || handleInvalidNumberField(parseInt(loginAttemptConfig?.duration))) {
            isVisible = false
        }
        setIsButtonVisible(isVisible)
    }

    const handleUpdateAlert = () => {
        if (authReducer?.updateDpAlertLoading || authReducer?.dpAlertsLoading) {
            return
        }
        let configData: any = {}
        if (alert?.alert_name === LINK_AVAILABILITY) {
            let interfaces: any = []
            for (let item of linkAvailabilityConfig) {
                interfaces.push({
                    wan_type: item.wan_type,
                    entry_duration: parseInt(item?.entry_duration),
                    entry_threshold: 100-parseInt(item?.entry_threshold),  // probe pass = 100-probe loss
                    exit_duration: parseInt(item?.exit_duration),
                    exit_threshold: parseInt(item?.exit_threshold),  // probe pass
                    new_record: item?.new_record
                })
            }

            configData = interfaces
        } else if (alert?.alert_name === INTERNET_STATUS) {
            configData = {
                entry_duration: parseInt(internetStatusConfig?.entry_duration),
                exit_duration: parseInt(internetStatusConfig?.exit_duration)
            }
        } else if (alert?.alert_name === LOGIN_ATTEMPT) {
            configData = {
                login_failure_count: parseInt(loginAttemptConfig?.login_failure_count),
                duration: parseInt(loginAttemptConfig?.duration)
            }
        }

        updateDpAlert(currentDpId, {
            alert_name: alert?.alert_name,
            alert_config: configData
        })
    }

    const handleDisableNotification = () => {
        if (authReducer?.updateDpAlertNotificationLoading || authReducer?.dpAlertsLoading) {
            return
        }
        updateDpAlertNotification(currentDpId, {
            alert_name: alert?.alert_name,
            is_disabled: true
        })
    }

    const handleEnableNotification = () => {
        if (authReducer?.updateDpAlertNotificationLoading || authReducer?.dpAlertsLoading) {
            return
        }
        updateDpAlertNotification(currentDpId, {
            alert_name: alert?.alert_name,
            is_disabled: false
        })
    }

    const handleAlertStatusChange = () => {
        if (authReducer?.updateDpAlertStatusLoading || authReducer?.dpAlertsLoading) {
            return
        }
        if (alert?.is_alert_status_disabled) {
            handleResumeAlert()
        } else {
            handlePauseAlert()
        }
    }

    const handlePauseAlert = () => {
        updateDpAlertStatus(currentDpId, {
            alert_name: alert?.alert_name,
            is_disabled: true
        })
    }

    const handleResumeAlert = () => {
        updateDpAlertStatus(currentDpId, {
            alert_name: alert?.alert_name,
            is_disabled: false
        })
    }

    const handleDeleteLinkAvailability = (wanType: string) => {
        if (authReducer?.deleteDpAlertLALoading || authReducer?.dpAlertsLoading) {
            return
        }
        deleteDpAlertLinkAvailability(currentDpId, {
            wan_type: wanType,
        })
    }

    return (
        <div className="accordian">
        <Accordion className="alertsAccordions" expanded={true} onChange={() => { setIsAccordionExapanded(!isAccordionExapanded) }}>
            <AccordionSummary className="accordionSummaryAlert"
                // expandIcon={<ExpandMoreIcon />}
            >
                <Grid container xs={12} lg={12} >
                    <Typography className="alertStatusAccordhead">
                        <Grid item xs={5} lg={5} className="alertName cursor-auto">
                            <span className="title" title={alert?.alert_name == LOGIN_ATTEMPT?"System Events":alert?.alert_name}>{alert?.alert_name == LOGIN_ATTEMPT?"System Events":alert?.alert_name}</span>
                            {
                                alert?.alert_name == LINK_AVAILABILITY
                                ?
                                <Tooltip title="Notifies the user if the WAN Interfaces are not active i.e. if the availability percentage meets the threshold value">
                                    <InfoOutlinedIcon className="dpAlertTypeHeaderIcon alert-name-info-icon"/>
                                </Tooltip>
                                :null
                            }
                            {
                                alert?.alert_name == INTERNET_STATUS
                                ?
                                <Tooltip title="Notifies the user if the internet access is not active in all the WAN interfaces">
                                    <InfoOutlinedIcon className="dpAlertTypeHeaderIcon alert-name-info-icon"/>
                                </Tooltip>
                                :null
                            }
                            {
                                alert?.alert_name == LOGIN_ATTEMPT
                                ?
                                <Tooltip title="Notifies the user if there is a suspicious amount of failed login attempts within a set time period">
                                    <InfoOutlinedIcon className="dpAlertTypeHeaderIcon alert-name-info-icon"/>
                                </Tooltip>
                                :null
                            }
                        </Grid>
                        <Grid item xs={3} lg={3} className="alertDescription" title={alert?.description} >{alert?.description}</Grid>
                        <Grid item xs={1} lg={1} onClick={(e) => { e.stopPropagation() }}>
                            {alert?.is_notification_status_disabled ?
                                <span onClick={
                                    () => {
                                        setConfirmDialog({
                                            isOpen: true,
                                            title: "Enable Notification",
                                            subTitle: "Are you sure you want to enable notifications",
                                            onConfirm: () => {
                                                handleEnableNotification()
                                            },
                                        })
                                    }
                                } >
                                    <Tooltip title={<span>Notification Disabled</span>}>
                                        <NotificationsOffIcon className="dpAlertTypeHeaderIcon notification-icon" />
                                    </Tooltip>
                                </span>
                                :
                                <span onClick={
                                    () => {
                                        setConfirmDialog({
                                            isOpen: true,
                                            title: "Disable Notification",
                                            subTitle: "Are you sure you want to disable notifications",
                                            onConfirm: () => {
                                                handleDisableNotification()
                                            },
                                        })
                                    }
                                } >
                                    <Tooltip title={<span>Notification Enabled</span>}>
                                        <NotificationsNoneIcon className="dpAlertTypeHeaderIcon notification-icon" />
                                    </Tooltip>
                                </span>
                            }
                        </Grid>
                        <Grid item xs={1} onClick={(e) => { e.stopPropagation() }}>
                            <Tooltip title={<span>{alert?.is_alert_status_disabled ? "Alerts Disabled" : "Alerts Enabled"}</span>}>
                                <Switch size="small" checked={!alert?.is_alert_status_disabled} onChange={handleAlertStatusChange}/>
                            </Tooltip>
                        </Grid>
                        <Grid item xs={2} lg={2} className="create_alert--form_group justify_content--end">
                            <Button disabled={!isButtonVisible} variant="contained" onClick={() => { handleUpdateAlert() }}>Update</Button>
                        </Grid>
                    </Typography>
                </Grid>
            </AccordionSummary>
            <AccordionDetails>
                <>{alert?.alert_name == LINK_AVAILABILITY ? (
                    (<>
                        <Grid container style={{width:"100%"}} columnSpacing={{ xs: 2 }} className="link-availability trigger-alert-clear-alert-text">
                            <Grid item xs={2}><span className="wan-type">WAN Type</span></Grid>
                            <Grid item xs={3}>Trigger Alert</Grid>
                            <Grid item xs={1}></Grid>
                            <Grid item xs={3}>Clear Alert</Grid>
                        </Grid>
                        <Grid container spacing={2} style={{width:"100%"}} columnSpacing={{ xs: 2 }}>
                            <Grid item xs={2} className="dpAlertTypeHeader"></Grid>
                            <Grid item xs={3} className="dpAlertTypeHeader trigger-alert-probe-loss-and-duration-text">
                                <div className="probe-loss-text">Probe Loss</div>
                                <div className="probe-loss-duration-text">Duration</div>
                            </Grid>
                            <Grid item xs={1} className="dpAlertTypeHeader"></Grid>
                            <Grid item xs={3} className="dpAlertTypeHeader trigger-alert-probe-pass-and-duration-text">
                                <span className="probe-pass-text">Probe Pass</span>
                                <span className="probe-pass-duration-text">Duration</span>
                            </Grid>
                        </Grid>
                        {linkAvailabilityConfig?.map((item, index) => {
                            return (
                                [
                                    <Grid container spacing={2} className="dpAlertLAContainer">
                                        {
                                            item?.new_record ? (
                                                <Grid item xs={2} >
                                                    <div className="dpAlertTypeInputContainer">
                                                        <Select
                                                            className="linkAvailabilityWanTypeSelect"
                                                            labelId="demo-simple-select-standard-label"
                                                            id="demo-simple-select-standard"
                                                            defaultValue={item?.wan_type}
                                                            value={item?.wan_type}
                                                            onChange={(e) => { handleUpdateWanType(index, e.target.value) }}
                                                        >
                                                            {wanTypes?.map(wanType => {
                                                                return (
                                                                    <MenuItem value={wanType?.value} disabled={handleShowWantype(wanType?.value)}>{wanType?.label}</MenuItem>
                                                                )
                                                            })}
                                                        </Select>
                                                    </div>
                                                </Grid>
                                            ) : (

                                                <Grid item xs={2} >
                                                    <div className="dpAlertTypeInputContainer">
                                                        <input disabled className="create_alert--form_group--input create_alert--form_group--input_text dpAlertTypeLAInputBox wan-type" type="text" name="wan_type" id="create_alert--alert_meta--name" value={handleFetchWanTypeValue(item?.wan_type)} />
                                                    </div>
                                                </Grid>
                                            )
                                        }
                                        <Grid item xs={3} >
                                            <div className="dpAlertTypeInputContainer trigger-alert-probe-loss-and-duration">
                                                <div>
                                                    <div className="probe-loss-value">
                                                        <input min={1} value={item?.entry_threshold} onChange={(e) => { handleLinkAvailabilityChange(item.wan_type, "entry_threshold", e.target.value) }} className="create_alert--form_group--input create_alert--form_group--input_text dpAlertTypeLAInputBox" type="number" name="entry_threshold" id="create_alert--alert_meta--name" aria-label="Set Name" disabled={item?.wan_type == "" ? true : false || disableForm}  />
                                                        <span className="unit-percentage">%</span>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className="probe-loss-duration-value">
                                                        <input min={1} value={item?.entry_duration} onChange={(e) => { handleLinkAvailabilityChange(item.wan_type, "entry_duration", e.target.value) }} className="create_alert--form_group--input create_alert--form_group--input_text dpAlertTypeLAInputBox" type="number" name="entry_duration" id="create_alert--alert_meta--name" aria-label="Set Name" disabled={item?.wan_type == "" ? true : false || disableForm} />
                                                        <span className="unit-minute">min</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </Grid>
                                        <Grid item xs={1}></Grid>
                                        <Grid item xs={3} >
                                            <div className="dpAlertTypeInputContainer trigger-alert-probe-pass-and-duration">
                                                <div>
                                                    <div className="probe-pass-value">
                                                        <input min={1} size={1} value={item?.exit_threshold} className="create_alert--form_group--input create_alert--form_group--input_text dpAlertTypeLAInputBox" type="number" name="exit_threshold" id="create_alert--alert_meta--name" aria-label="Set Name" onChange={(e) => { handleLinkAvailabilityChange(item.wan_type, "exit_threshold", e.target.value) }} disabled={item?.wan_type == "" ? true : false || disableForm}  />
                                                        <span className="unit-percentage">%</span>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className="probe-pass-duration-value">
                                                        <input min={1} value={item?.exit_duration} onChange={(e) => { handleLinkAvailabilityChange(item.wan_type, "exit_duration", e.target.value) }} className="create_alert--form_group--input create_alert--form_group--input_text dpAlertTypeLAInputBox" type="number" name="exit_duration" id="create_alert--alert_meta--name" aria-label="Set Name" disabled={item?.wan_type == "" ? true : false || disableForm}  />
                                                        <span className="unit-minute">min</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </Grid>
                                        <Grid item xs={1}></Grid>
                                        <Grid item xs={1} className="link-availability-delete-wan-select-want-type">
                                        {item?.new_record ? (
                                                        <IconButton className="dpAlertTypeFormIcon" onClick={() => { handleDeleteWanType(index, item) }} disabled={disableForm}>
                                                            <DeleteOutlineIcon />
                                                        </IconButton>
                                                    ) : (
                                                        <span onClick={
                                                            () => {
                                                                if (!disableForm) {
                                                                    setConfirmDialog({
                                                                        isOpen: true,
                                                                        title: "Delete Alerts",
                                                                        subTitle: "Are you sure you want to delete all alerts to this wan type?",
                                                                        onConfirm: () => {
                                                                            handleDeleteLinkAvailability(item?.wan_type)
                                                                        },
                                                                    })
                                                                }
                                                            }
                                                        } >
                                                            <IconButton className="dpAlertTypeFormIcon" onClick={() => { handleDeleteWanType(index, item) }} disabled={disableForm}>
                                                                <DeleteOutlineIcon />
                                                            </IconButton>
                                                        </span>
                                                    )}
                                                    {index == linkAvailabilityConfig?.length - 1 ? (
                                                        <IconButton className="dpAlertTypeFormIcon" onClick={() => { handleAddWanType() }} disabled={allInterfaces?.length === linkAvailabilityConfig?.length || disableForm}>
                                                            <AddCircleIcon />
                                                        </IconButton>
                                                    ) : null}
                                        </Grid>
                                    </Grid>,
                                    <Grid>
                                        {
                                            item.wan_type && handleInvalidThreshold(item?.entry_threshold) ? <p className="dpLAInvalidThreshold link-availability">{handleFetchWanTypeValue(item?.wan_type)+": Probe Loss: "+thresholdErrorMessage}</p> : null
                                        }
                                        {
                                            item.wan_type && handleInvalidNumberField(item?.entry_duration) ? <p className="dpLAInvalidThreshold link-availability">{handleFetchWanTypeValue(item?.wan_type)+": Trigger Alert Duration: "+numerFieldErrorMessage}</p> : null
                                        }
                                        {
                                            item.wan_type && handleInvalidThreshold(item?.exit_threshold) ? <p className="dpLAInvalidThreshold link-availability">{handleFetchWanTypeValue(item?.wan_type)+": Probe Pass: "+thresholdErrorMessage}</p> : null
                                        }
                                        {
                                            item.wan_type && handleInvalidNumberField(item?.exit_duration) ? <p className="dpLAInvalidThreshold link-availability">{handleFetchWanTypeValue(item?.wan_type)+": Clear Alert Duration: "+numerFieldErrorMessage}</p> : null
                                        }
                                    </Grid>
                                ]
                            )
                        })
                        }
                    </>)
                ) : alert?.alert_name == INTERNET_STATUS ? (
                    <Grid className="internet-status-alert-config">
                        <Grid container style={{width:"100%"}} columnSpacing={{ xs: 2 }} className="link-availability trigger-alert-clear-alert-text">
                            <Grid item xs={3}><span className="wan-type">Event</span></Grid>
                            <Grid item xs={3}>Trigger Alert</Grid>
                            <Grid item xs={1}></Grid>
                            <Grid item xs={3}>Clear Alert</Grid>
                        </Grid>
                        <Grid container spacing={2} style={{width:"100%"}} columnSpacing={{ xs: 2 }} className="trigger-alert-clear-alert-duration-text">
                            <Grid item xs={3} className="dpAlertTypeHeader"></Grid>
                            <Grid item xs={3} className="dpAlertTypeHeader">Duration</Grid>
                            <Grid item xs={1} className="dpAlertTypeHeader"></Grid>
                            <Grid item xs={3} className="dpAlertTypeHeader">Duration</Grid>
                        </Grid>
                        <Grid container spacing={2} className="dpAlertLAContainer">
                            <Grid item xs={3} className="internet-outage">Internet outage</Grid>
                            <Grid item xs={3}>
                            <div className="dpAlertTypeInputContainer">
                                <div>
                                    <div className="internet-status-trigger-alert-duration-value">
                                        <input min={1} value={internetStatusConfig?.entry_duration} onChange={(e) => { handleInternetStatusChange("entry_duration", e.target.value) }} className="create_alert--form_group--input create_alert--form_group--input_text dpAlertTypeInputBox" type="number" name="entry_duration" id="create_alert--alert_meta--name" aria-label="Set Name" disabled={disableForm} />
                                        <span className="unit-minute">min</span>
                                    </div>
                                </div>
                            </div>
                            </Grid>
                            <Grid item xs={1}></Grid>
                            <Grid item xs={3}>
                                <div className="dpAlertTypeInputContainer">
                                    <div>
                                        <div className="internet-status-clear-duration-value">
                                            <input min={1} value={internetStatusConfig?.exit_duration} onChange={(e) => { handleInternetStatusChange("exit_duration", e.target.value) }} className="create_alert--form_group--input create_alert--form_group--input_text dpAlertTypeInputBox" type="number" name="exit_duration" id="create_alert--alert_meta--name" aria-label="Set Name" disabled={disableForm} />
                                            <span className="unit-minute">min</span>
                                        </div>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} className="invalid-message-parent">
                            <Grid item xs={3}></Grid>
                            <Grid item xs={3} className="invalid-message-1">
                                {
                                    handleInvalidNumberField(internetStatusConfig?.entry_duration) ? <p className="dpLAInvalidThreshold" >{numerFieldErrorMessage}</p> : null
                                }
                            </Grid>
                            <Grid item xs={1}></Grid>
                            <Grid item xs={3} className="invalid-message-2">
                                {
                                    handleInvalidNumberField(internetStatusConfig?.exit_duration) ? <p className="dpLAInvalidThreshold" >{numerFieldErrorMessage}</p> : null
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                ) : alert?.alert_name == LOGIN_ATTEMPT ? (
                    <Grid className="internet-status-alert-config system-alert-config">
                        <Grid container style={{width:"100%"}} columnSpacing={{ xs: 2 }} className="link-availability trigger-alert-clear-alert-text">
                            <Grid item xs={3}><span className="wan-type">Event</span></Grid>
                            <Grid item xs={3}>Trigger Alert</Grid>
                            <Grid item xs={1}></Grid>
                            <Grid item xs={3}>Clear Alert</Grid>
                        </Grid>
                        <Grid container spacing={2} style={{width:"100%"}} columnSpacing={{ xs: 2 }} className="trigger-alert-clear-alert-duration-text">
                            <Grid item xs={3} className="dpAlertTypeHeader"></Grid>
                            <Grid item xs={3} className="dpAlertTypeHeader">Login Failures</Grid>
                            <Grid item xs={1} className="dpAlertTypeHeader"></Grid>
                            <Grid item xs={3} className="dpAlertTypeHeader">Duration</Grid>
                        </Grid>
                        <Grid container spacing={2} className="dpAlertLAContainer">
                            <Grid item xs={3} className="internet-outage">Login Failures</Grid>
                            <Grid item xs={3}>
                            <div className="dpAlertTypeInputContainer">
                                <div>
                                    <div className="internet-status-trigger-alert-duration-value">
                                        <input min={1} value={loginAttemptConfig?.login_failure_count} onChange={(e) => { handleLoginAttemptChange("login_failure_count", e.target.value) }} className="create_alert--form_group--input create_alert--form_group--input_text dpAlertTypeInputBox" type="number" name="login_failure_count" id="create_alert--alert_meta--name" aria-label="Set Name" disabled={disableForm} />
                                        <span className="unit-minute">fail</span>
                                    </div>
                                </div>
                            </div>
                            </Grid>
                            <Grid item xs={1}></Grid>
                            <Grid item xs={3}>
                            <div className="dpAlertTypeInputContainer">
                                <div>
                                    <div className="internet-status-clear-duration-value">
                                        <input min={1} size={1} value={loginAttemptConfig?.duration} onChange={(e) => { handleLoginAttemptChange("duration", e.target.value) }} className="create_alert--form_group--input create_alert--form_group--input_text dpAlertTypeInputBox" type="number" name="duration" id="create_alert--alert_meta--name" aria-label="Set Name" disabled={disableForm} />
                                        <span className="unit-minute">min</span>
                                    </div>
                                </div>
                            </div>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} className="invalid-message-parent">
                            <Grid item xs={3}></Grid>
                            <Grid item xs={3} className="invalid-message-1">
                                {
                                    handleInvalidNumberField(loginAttemptConfig?.login_failure_count) ? <p className="dpLAInvalidThreshold" >{numerFieldErrorMessage}</p> : null
                                }
                            </Grid>
                            <Grid item xs={1}></Grid>
                            <Grid item xs={3} className="invalid-message-2">
                                {
                                    handleInvalidNumberField(loginAttemptConfig?.duration) ? <p className="dpLAInvalidThreshold" >{numerFieldErrorMessage}</p> : null
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                ) : null
                }
                </>
            </AccordionDetails>
            <ConfirmDialog confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog} />
        </Accordion>
    </div>
    )
}

const mapStateToProps = (state) => ({
    alertChildDetails: state?.alertsDashboard?.alertChildDetails,
    authReducer: state?.authReducer,
});


export default withRouter(
    connect(mapStateToProps, {
        updateAlert,
        getAlertChildInfo,
        updateDpAlert,
        updateDpAlertNotification,
        updateDpAlertStatus,
        deleteDpAlertLinkAvailability
    })(AccrodionComp)
);