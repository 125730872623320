import { withRouter } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { useEffect } from "react";
import { getCaptiveDashSitesSubscribersSessionsTopWidget, getCaptiveDashPlansTopWidget, getCaptiveDashUsageTopWidget } from "../../../actions/Users/authenticateCaptiveDahshboard";
import { GET_CAPTIVE_DASHBOARD_SITES_SUBSCRIBERS_SESSIONS_TOP_WIDGET, GET_CAPTIVE_DASHBOARD_USAGE_TOP_WIDGET, GET_CAPTIVE_DASHBOARD_PLANS_TOP_WIDGET } from "../../../actions/types";
import _ from "lodash";
import Card from "@mui/material/Card";
import { Grid, LinearProgress } from "@mui/material";
import Typography from "@mui/material/Typography";
import Box from '@mui/material/Box';
import { getDecodeURI, getEncodedURI, readableBytesAsGB } from '../../../utils/util';
import { useHistory, useLocation } from 'react-router-dom';

const CaptiveTopWidget = (props) => {
  const { vessels, getCaptiveDashSitesSubscribersSessionsTopWidget, getCaptiveDashPlansTopWidget, getCaptiveDashUsageTopWidget, newSummaryStartDate, newSummaryEndDate, getCDSitesSubscribersSesTopWidget, getCDPlansTopWidget, getCDUsageTopWidget, topcardSelected, setTopCardSelected, selectedTab, values, setValues } = props;
  const { selectedSubscribers, selectedPlans, selectedPlanDurations, selectedPlanQuota, selectedPlanStatus, selectedPlanStartFrom, selectedPlanStartTo, selectedPlanEndFrom, selectedPlanEndTo, planAutoRenewal } = values;
  const { setChartView } = setValues;

  const dispatch = useDispatch();
  useEffect(() => {
    if (vessels?.length > 0) {
      getCaptiveDashSitesSubscribersSessionsTopWidget(vessels, newSummaryStartDate, newSummaryEndDate, selectedSubscribers, selectedPlans, selectedPlanDurations, selectedPlanQuota, selectedPlanStatus, selectedPlanStartFrom, selectedPlanStartTo, selectedPlanEndFrom, selectedPlanEndTo, planAutoRenewal);
      getCaptiveDashPlansTopWidget(vessels, newSummaryStartDate, newSummaryEndDate, selectedSubscribers, selectedPlans, selectedPlanDurations, selectedPlanQuota, selectedPlanStatus, selectedPlanStartFrom, selectedPlanStartTo, selectedPlanEndFrom, selectedPlanEndTo, planAutoRenewal);
      getCaptiveDashUsageTopWidget(vessels, newSummaryStartDate, newSummaryEndDate, selectedSubscribers, selectedPlans, selectedPlanDurations, selectedPlanQuota, selectedPlanStatus, selectedPlanStartFrom, selectedPlanStartTo, selectedPlanEndFrom, selectedPlanEndTo, planAutoRenewal);
    } else {
      dispatch({ type: GET_CAPTIVE_DASHBOARD_SITES_SUBSCRIBERS_SESSIONS_TOP_WIDGET, payload: {} });
      dispatch({ type: GET_CAPTIVE_DASHBOARD_USAGE_TOP_WIDGET, payload: {} });
      dispatch({ type: GET_CAPTIVE_DASHBOARD_PLANS_TOP_WIDGET, payload: {} });
    }
  }, [vessels, newSummaryStartDate, newSummaryEndDate])

  return (
    <Grid container columns={10} spacing={2}>
      {selectedTab == 'sites' || selectedTab == 'subscribers' ? <>
        {CardContents(`SITES ONLINE`, getCDSitesSubscribersSesTopWidget?.[0]?.activeSites, '', '', getCDSitesSubscribersSesTopWidget?.[0]?.TotalSites, "#2F4B82", '', '', 'Total Sites', ((getCDSitesSubscribersSesTopWidget?.[0]?.activeSites / getCDSitesSubscribersSesTopWidget?.[0]?.TotalSites) * 100), setTopCardSelected, topcardSelected, selectedTab, false, setChartView)}
        {CardContents(`SUBSCRIBERS`, getCDSitesSubscribersSesTopWidget?.[0]?.currentSubscribers, getCDSitesSubscribersSesTopWidget?.[0]?.typicalSubscribers, '', 'NA', "#d62728", 'Typical', '', 'New', 0, setTopCardSelected, topcardSelected, selectedTab, selectedTab === 'sites', setChartView)}
        {CardContents(`SESSIONS`, getCDSitesSubscribersSesTopWidget?.[0]?.currentSessions, getCDSitesSubscribersSesTopWidget?.[0]?.typicalSessions, '', 'NA', "#ff7f0e", 'Typical', '', 'Failed', 0, setTopCardSelected, topcardSelected, selectedTab, (selectedTab === 'subscribers' || selectedTab == 'sites'), setChartView)}
        {CardContents(`TOTAL USAGE`, getCDUsageTopWidget?.[0]?.current_quota_usage ? readableBytesAsGB(getCDUsageTopWidget?.[0]?.current_quota_usage) : 0, getCDUsageTopWidget?.[0]?.comparison_quota_usage ? readableBytesAsGB(getCDUsageTopWidget?.[0]?.comparison_quota_usage) : 0, '', getCDUsageTopWidget?.[0]?.last_24h_quota_usage ? readableBytesAsGB(getCDUsageTopWidget?.[0]?.last_24h_quota_usage) : 0, "#1f77b4", 'Typical', '', '24 hrs ago', ((getCDUsageTopWidget?.[0]?.last_24h_quota_usage / (getCDUsageTopWidget?.[0]?.last_24h_quota_usage + getCDUsageTopWidget?.[0]?.current_quota_usage)) * 100), setTopCardSelected, topcardSelected, selectedTab, (selectedTab === 'subscribers' || selectedTab == 'sites'), setChartView)}
      </> : <>
        {CardContents(`PLANS`, getCDPlansTopWidget?.[0]?.currentPlans, '', '', getCDPlansTopWidget?.[0]?.totalPlans, "#ffbf00", '', '', 'Total Plans', ((getCDPlansTopWidget?.[0]?.currentPlans / getCDPlansTopWidget?.[0]?.totalPlans) * 100), setTopCardSelected, topcardSelected, selectedTab, false, setChartView)}
        {CardContents(`SUBSCRIBERS`, getCDSitesSubscribersSesTopWidget?.[0]?.currentSubscribers, getCDSitesSubscribersSesTopWidget?.[0]?.typicalSubscribers, '', 'NA', "#d62728", 'Typical', '', 'New', 0, setTopCardSelected, topcardSelected, selectedTab, false, setChartView)}
        {CardContents(`TOTAL USAGE`, getCDUsageTopWidget?.[0]?.current_quota_usage ? readableBytesAsGB(getCDUsageTopWidget?.[0]?.current_quota_usage) : 0, getCDUsageTopWidget?.[0]?.comparison_quota_usage ? readableBytesAsGB(getCDUsageTopWidget?.[0]?.comparison_quota_usage) : 0, '', getCDUsageTopWidget?.[0]?.last_24h_quota_usage ? readableBytesAsGB(getCDUsageTopWidget?.[0]?.last_24h_quota_usage) : 0, "#1f77b4", 'Typical', '', '24 hrs ago', ((getCDUsageTopWidget?.[0]?.last_24h_quota_usage / (getCDUsageTopWidget?.[0]?.last_24h_quota_usage + getCDUsageTopWidget?.[0]?.current_quota_usage)) * 100), setTopCardSelected, topcardSelected, selectedTab, false, setChartView)}
      </>}
    </Grid>
  )
}

const CardContents = (cardHeading, totalValue, leftBottomValue, middleBottomValue, rightBottomValue, borderColor, bottomLeftText, bottommiddleText, bottomRightText, progress, setTopCardSelected, topcardSelected, selectedTab, isClickable, setChartView) => {
  const location = useLocation();
  const history = useHistory();

  const handleCardClick = (card) => {
    if (isClickable) {
      setTopCardSelected(card);
      setChartView('spline');
      let params = getDecodeURI(location?.search);
      params.topCard = card;
      delete params.page;
      delete params.chartView;
      history.push({ pathname: location.pathname, search: `?${getEncodedURI(params)}` });
    }
  }

  return (
    <Grid item xs={12} sm={6} md={selectedTab == 'plans' ? 3.33 : 2.5} lg={selectedTab == 'plans' ? 3.33 : 2.5} style={{ marginTop: '15px' }}>
      <Card className="card-widget"
        id={isClickable && cardHeading !== topcardSelected ? 'captive-addBoxShadow' : 'captive-removeBoxShadow'} onClick={() => handleCardClick(cardHeading)}
        style={isClickable && cardHeading == topcardSelected ? { border: '3px solid rgba(0, 0, 238, 1)' } : { border: '3px solid #fff' }}
      >
        <Box className="cardHeader">
          <Typography className="cardTitle">{cardHeading}</Typography>
        </Box>
        <Box className="cardContents">
          <Box className="cardActive">
            <div title={cardHeading == 'TOTAL USAGE' ? 'Usage' : "Count"} className="content">{totalValue ? totalValue : '-'}</div>
          </Box>
        </Box>
        <Box sx={{ margin: '0.25rem', marginLeft: '0.5rem', marginRight: '0.5rem' }}>
          {
            !_.isNaN(1) && !_.isNaN(1) ? <>
              <LinearProgress
                sx={{
                  "--LinearProgress-radius": "10px",
                  width: '100%',
                  height: '5px',
                  backgroundColor: '#ddd', // Background color of the progress track
                  '& .MuiLinearProgress-bar': {
                    backgroundColor: borderColor, // Custom color for the progress bar itself
                  },
                }}
                variant="determinate"
                value={progress} // Progress at 70%
              />
              <div className="advance-dashboard-card-css font-size-12px">
                <div>{`${bottomLeftText} `}<span style={{ fontWeight: '600' }}>{leftBottomValue}</span></div>
                {bottommiddleText && <div >{`${bottommiddleText} `} <span style={{ fontWeight: '600' }}>{middleBottomValue}</span></div>}
                <div >{`${bottomRightText} `}<span style={{ fontWeight: '600' }}>{rightBottomValue}</span></div>
              </div>
            </> : null
          }
        </Box>
      </Card>
    </Grid>
  );
}

const mapStateToProps = (state) => ({
  authReducer: state.authReducer,
  newSummaryStartDate: state?.authReducer?.newSummaryStartDate,
  newSummaryEndDate: state?.authReducer?.newSummaryEndDate,
  getCDSitesSubscribersSesTopWidget: state?.authReducer?.getCDSitesSubscribersSesTopWidget?.data,
  getCDPlansTopWidget: state?.authReducer?.getCDPlansTopWidget?.data,
  getCDUsageTopWidget: state?.authReducer?.getCDUsageTopWidget?.data,
});

export default withRouter(
  connect(mapStateToProps, { getCaptiveDashSitesSubscribersSessionsTopWidget, getCaptiveDashPlansTopWidget, getCaptiveDashUsageTopWidget })(CaptiveTopWidget)
);