import { Fragment, ReactNode, SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { AddCircle } from "@material-ui/icons";
import { Autocomplete, Button, Grid, TextField, Tooltip, Typography } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { RemoveCircle } from '@mui/icons-material';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { useDispatch } from 'react-redux';
import _ from "lodash";


const ServiceVlanItemsPopup = (props) => {
    const { authReducer, serviceVlanItems, setServiceVlanItems, isEditActionPopup, setRouteItems, routeItems } = props;
    const addthretaKontrolAction = () => {
        const newAction = {
            id: serviceVlanItems.length,
            name: "",
            ip: "",
            vrf_name: "",
            vid: null,
            interface: "",
        };
        setServiceVlanItems([...serviceVlanItems, newAction]);
    };
    const ServiceVlanItemsPopup = (id, updatedAction) => {
        setServiceVlanItems(
            serviceVlanItems?.map((action, index) =>
                index === id ? updatedAction : action
            )
        );
    };
    const removeServiceVlanItem = (id: string) => {
        const updatedActions = serviceVlanItems.filter((_, index) => index !== id);
        setServiceVlanItems(updatedActions);

        setServiceVlanItems((prevActions) =>
            prevActions?.map((action, index) => ({ ...action, id: index }))
        );
    };

    return (
        <div>
            {/* <Typography>SERVICE VLAN Details</Typography> */}
            <div>
                {
                    serviceVlanItems?.length !== 0 ? (
                        <div>
                            <Grid container lg={12} style={{ marginTop: "20px", background: "#f5f5f5", }}>
                                <Grid lg={3}>
                                    <p style={{ fontFamily: "Inter", fontStyle: "normal", fontWeight: 500, fontSize: "14px", marginBottom: "10px", marginTop: "10px", textAlign: "center", color: "#45464E" }}> VLAN NAME</p>
                                </Grid>
                                <Grid lg={3}>
                                    <p style={{ fontFamily: "Inter", fontStyle: "normal", fontWeight: 500, fontSize: "14px", marginBottom: "10px", marginTop: "10px", textAlign: "center", color: "#45464E" }}> INTERFACE</p>
                                </Grid>
                                <Grid lg={3}>
                                    <p style={{ fontFamily: "Inter", fontStyle: "normal", fontWeight: 500, fontSize: "14px", marginBottom: "10px", marginTop: "10px", textAlign: "center", color: "#45464E" }}> VID</p>
                                </Grid>
                                <Grid lg={3}>
                                    <p style={{ fontFamily: "Inter", fontStyle: "normal", fontWeight: 500, fontSize: "14px", marginBottom: "10px", marginTop: "10px", textAlign: "center", color: "#45464E" }}> IP</p>
                                </Grid>
                            </Grid>
                        </div>
                    ) : null
                }
                {serviceVlanItems?.map((action, index) => (
                    <ServiceVlanItem
                        key={index}
                        id={index}
                        data={action}
                        authReducer={authReducer}
                        onUpdate={ServiceVlanItemsPopup}
                        onRemove={removeServiceVlanItem}
                        isEditActionPopup={isEditActionPopup}
                    />
                ))}
            </div>
            <div style={{ width: "100%", display: "block", marginTop: "20px" }}>
                {/* <Button variant="outlined" startIcon={<AddCircle className="addActionIcon" />} style={{ marginTop: "10px", width: "170px" }} onClick={addthretaKontrolAction} >
                    Add VLAN
                </Button> */}
                <Button variant="outlined" size="small" startIcon={<AddCircle />} className="quota_management--button_outlined--primary" onClick={addthretaKontrolAction} >
                    ADD VLAN
                </Button>

            </div>
        </div>
    )
}

const ServiceVlanItem = (props) => {
    const { authReducer, getSeverityClassList, getThreatNameList, id, data, onUpdate, onRemove, isEditActionPopup, } = props;
    const handleChange = (field: string, value: any) => {
        onUpdate(id, { ...data, [field]: value || '' });
    };
    const [error, setError] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            setError(false);
        }, 2000);
        return () => clearTimeout(timer)
    }, [error])

    const handleVidChange = (value: any) => {
        if (/^\d*$/.test(value)) {
            setError(false);
            handleChange("vid", value ? parseInt(value) : null)
        } else {
            setError(true);
        }
    }

    return (
        <div >
            <Grid container lg={12} spacing={1} style={{ marginTop: "5px" }}>
                {/* <Grid item lg={12} className="threatAction"> */}
                <Grid item lg={3} style={{ display: "flex", justifyContent: "center" }}>
                    <TextField
                        placeholder="Vlan Name"
                        value={data?.name}
                        onChange={(event) => {
                            handleChange("name", event?.target?.value)
                        }}
                        variant="outlined"
                        size="small"
                        sx={{ maxWidth: "80%", width: "80%" }}
                        style={{ maxWidth: "80%", width: "80%" }}
                    />
                </Grid>
                {/* <Grid item lg={3}>
                    <TextField
                        placeholder="Vrf Name"
                        value={data?.vrf_name}
                        onChange={(event) => {
                            handleChange("vrf_name", event?.target?.value)
                        }}
                        variant="outlined"
                        size="small"
                    />
                </Grid> */}
                <Grid item lg={3} style={{ display: "flex", justifyContent: "center" }}>
                    <TextField
                        placeholder="Interface"
                        value={data?.interface}
                        onChange={(event) => {
                            handleChange("interface", event?.target?.value)
                        }}
                        variant="outlined"
                        size="small"
                        sx={{ maxWidth: "80%", width: "80%" }}
                        style={{ maxWidth: "80%", width: "80%" }}
                    />
                </Grid>
                <Grid item lg={3} style={{ display: "flex", justifyContent: "center" }}>
                    <TextField
                        placeholder="Vid"
                        value={data?.vid}
                        onChange={(event) => {
                            handleVidChange(event?.target?.value)
                        }}
                        variant="outlined"
                        size="small"
                        sx={{ maxWidth: "80%", width: "80%",  "& .MuiFormHelperText-root": {color: "red"}}}
                        style={{ maxWidth: "80%", width: "80%" }}
                        helperText={error ? "Only numbers are allowed" : ""}
                    />
                </Grid>
                <Grid item lg={3} style={{ display: "flex", justifyContent: "center" }}>
                    <div style={{ display: "flex", justifyContent: "space-around", alignItems: "center" }}>
                        <TextField
                            placeholder="IP"
                            value={data?.ip}
                            onChange={(event) => {
                                handleChange("ip", event?.target?.value)
                            }}
                            variant="outlined"
                            size="small"
                            style={{ maxWidth: "80%", width: "80%" }}
                        />
                        <RemoveCircle className="serviceVlanActionremoveIcon" onClick={() => { onRemove(id); }} />
                    </div>
                </Grid>
                <br></br>
            </Grid>
        </div>
    )
}

const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
    options: state?.authReducer?.threatSeverityClassList
});

export default withRouter(
    connect(mapStateToProps, {})(ServiceVlanItemsPopup)
)
