import { Grid, TableBody, TableRow, TableCell, TableContainer, Table, TableHead, Tooltip, Paper, Button } from "@mui/material";
import "./appUsageCard.css";
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import React from 'react';
import { readableBytesAsGB} from "../../utils/util"
import ImageWithFallback from "../ImageWithFallback";
import CircularProgress from "@mui/material/CircularProgress";
import {getWanUsageSingleSiteNetworkUsage, getWanUsageSingleSiteWanSelectionNetworkUsage, getWanUsageSingleSiteVsatSelectionNetworkUsage} from '../../actions/Users/authenticateReports';
import { withRouter } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import _ from "lodash";
import {useState, useEffect} from 'react';
import { AddCircle } from "@material-ui/icons";
import { RemoveCircle } from '@mui/icons-material';

const BorderLinearProgress = styled(LinearProgress)<{green?:boolean, borderValue?:number}>(({ theme, green=false, borderValue=0}) => ({
    height: 6,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: green ? "#bafa8c" : theme.palette.mode === 'light' ? '#1B51A7' : '#308fe8',
    },
    ...((green && borderValue) && {
        "&::after": {
          content: '""',
          position: "absolute",
          top: 0,
          left: 0,
          height: "100%",
          width: `${borderValue}%`, 
          border: "1px solid #1B51A7",
          borderRadius: 5,
          boxSizing: "border-box"
        },
      }),
  }));

const AppUsageCard = (props) => {
    const { appDownloadTableData, title, header, tab, selectCard, vessels, handlePlusClick, handleMinusClick, site, setSite, authReducer,
        getWanUsageSingleSiteNetworkUsage, wanUsageReportSingleSiteNetworkUsage, wanUsageReportSingleSiteNetworkUsageLoading, WanUsageSingleSiteWanSelectionNetworkUsageLoading, WanUsageSingleSiteVsatSelectionNetworkUsageLoading, getWanUsageSingleSiteVsatSelectionNetworkUsage, newSummaryStartDate, newSummaryEndDate} = props;

function GetTopAppsImage (props)  {
        const { app } = props;
        if(app) {
            let appName = app.includes(' ') ? app.split(' ')[0]: app.includes('-') ? app.split('-')[0] : ""; 
            return <ImageWithFallback src={`https://cdn.k4mobility.com/cdn/sportAssets/app_icons/${app.trim()}`} fallbackSrcs={[`https://cdn.k4mobility.com/cdn/sportAssets/app_icons/${appName.trim()}`,"https://cdn.k4mobility.com/cdn/sportAssets/fallbackImg.png"]} app={app} />
        } else {
            return <img className="logo-activity paddingLogo" src={`https://cdn.k4mobility.com/cdn/sportAssets/fallbackImg.png`} />
        }
    }

return (
    <div className="Form">
        <Grid className="am-table">
           <Grid container className="AppUsageContainer">
                <Grid>
                    <div className="TopApplicationData">{title}</div>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} className="AppUsageTableContainer">
                    <TableContainer className="AppUsage-FleetDashboard">
                        <Table aria-label="simple table" style={tab!=='vessel_chart' ? {tableLayout:'fixed'} : {tableLayout: 'auto'}}>
                            <TableHead className="usage-tableHead">
                                <TableRow className="appUsage-tableRow">
                                    <TableCell className={tab!=='vessel_chart' ? "appUsage-fleetDataTable activity-appUsage-fleetDataTable" : "appUsage-fleetDataTable"}>{tab=='top_subCategory' ? 'SUB CATEGORY' : tab=='top_category' ? 'CATEGORY' : tab=='top_domain' ? 'DOMAIN' : tab=='top_ip' ? 'IP' : tab=='vessel_chart' ? 'SITE' : 'APPLICATION'}</TableCell>
                                    { header ? <TableCell align="left" className={tab!=='vessel_chart' ? "appUsage_fleetDataTable activity-appUsage_fleetDataTable" : "appUsage_fleetDataTable"}>{`${header}`}</TableCell> : ''}
                                </TableRow>
                            </TableHead>
                            { appDownloadTableData && 0 != appDownloadTableData.length ? (
                                <TableBody className="tableBody appUsage-tableBody">
                                    {
                                        header!=='HITS' ? 
                                        appDownloadTableData && appDownloadTableData.map((row, i) =>
                                          <React.Fragment key={i}>
                                            <TableRow className="tabelRow appUsage-tableRow">
                                                { tab == "top_aaplication" ?
                                                    <TableCell className={tab!=='vessel_chart' ? "appUsage-tableCell activityTableCell-logo activity-appUsage-tableCell" : 'appUsage-tableCell activityTableCell-logo'}>
                                                        <Tooltip title={row.application}>
                                                            <div>
                                                                <GetTopAppsImage app={row.application} />
                                                                <span>{row.application}</span>
                                                            </div>
                                                        </Tooltip>
                                                    </TableCell> : 
                                                    <TableCell className={tab!=='vessel_chart' ? "appUsage-tableCell activityTableCell-logo activity-appUsage-tableCell" : 'appUsage-tableCell activityTableCell-logo'}>
                                                        <Tooltip title={tab!=='vessel_chart' &&  row.application}>
                                                            <div>
                                                                <span>{row.application}</span>
                                                            </div>
                                                        </Tooltip>
                                                    </TableCell>
                                                }
                                                <TableCell className="appUsage_tableCell">
                                                    <TableRow className="usage-innerTableRow">
                                                        <Tooltip title={ tab == 'vessel_chart' &&
                                                            <Paper style={{width:'200px'}}>
                                                                <div className="vessel-chart-usage-cont">
                                                                    {row?.wan_usage?.map((ele) => (
                                                                        <div>
                                                                            <span>{ele['wan_type']}</span>
                                                                            <span>{readableBytesAsGB(ele['usage'])}</span>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            </Paper>
                                                            } classes={{ tooltip: "quota_management--usage_tooltip" }} placement="top">
                                                            <TableCell className={tab!=='vessel_chart' ? "usage-innerTableCell activity-usage-innerTableCell" : 'usage-innerTableCell'}>
                                                                <div>
                                                                    {(vessels?.length > 1 && row.network_usage) ? <BorderLinearProgress variant="determinate" value={100} green={true} borderValue={Math.ceil(row.percentage)}/> :
                                                                    <BorderLinearProgress variant="determinate" value={Math.ceil(row.percentage)} />
                                                                    }
                                                                </div>
                                                            </TableCell>
                                                        </Tooltip>
                                                        <TableCell style={{display:"flex"}} className={tab!=='vessel_chart' ? "usage_innerTableCell activity-usage_innerTableCell" : 'usage_innerTableCell'}>
                                                          <div style={{display: "flex"}}>  
                                                            <span className={vessels?.length > 1 ? "justifyContent" : "" }>{readableBytesAsGB(row.download).replace(" GB", "")}</span>
                                                        { vessels?.length > 1 ? (row.location_id == site && (wanUsageReportSingleSiteNetworkUsageLoading || WanUsageSingleSiteVsatSelectionNetworkUsageLoading || WanUsageSingleSiteWanSelectionNetworkUsageLoading)) ? <span style={{justifyContent:"end"}} className="wanUsagePlusIconWidth"><CircularProgress size={12}/></span> : (row.network_usage) ? <span style={{justifyContent:"end"}} className="wanUsagePlusIconWidth"><RemoveCircle onClick={() => handleMinusClick(row.location_id)} id="addActionIcon"/></span> : <span style={{justifyContent:"end"}} className="wanUsagePlusIconWidth"><AddCircle onClick={() => handlePlusClick(row.location_id)} id="addActionIcon"/></span> : null}
                                                          </div>
                                                        </TableCell>
                                                    </TableRow>
                                                </TableCell>
                                            </TableRow>
                                            {selectCard === "TOTAL" && row.network_usage && vessels?.length > 1 && (
                                                <TableRow className="usagePadding tabelRow appUsage-tableRow">
                                                    <TableCell className={tab!=='vessel_chart' ? "appUsage-tableCell activityTableCell-logo activity-appUsage-tableCell usageNameCellPadding  verticalAlign" : 'appUsage-tableCell activityTableCell-logo usageNameCellPadding verticalAlign'}>
                                                        {row.network_usage.map((sitewise, ind) => (
                                                          <div>
                                                            <span key={ind}>{sitewise.name}</span>
                                                          </div>
                                                        ))}
                                                    </TableCell>
                                                    <TableCell className={tab!=='vessel_chart' ? "usage-innerTableCell activity-usage-innerTableCell networkUsagePadding" : 'usage-innerTableCell networkUsagePadding'}>
                                                        <TableRow className="usage-innerTableRow">
                                                            <TableCell className={tab!=='vessel_chart' ? "usage-innerTableCell activity-usage-innerTableCell verticalAlign usageCellPadding" : 'usage-innerTableCell verticalAlign usageCellPadding'}>
                                                                {row.network_usage.map((sitewise, ind) => (
                                                                  <div key={ind} className="networkUsageProgressBar">
                                                                    <BorderLinearProgress variant="determinate" value={Math.ceil(sitewise.percentage)} green={true}/>
                                                                  </div>
                                                                ))}
                                                            </TableCell>
                                                            <TableCell className={tab!=='vessel_chart' ? "usage_innerTableCell activity-usage_innerTableCell usageCellPadding" : 'usage_innerTableCell usageCellPadding'}>
                                                                {row.network_usage.map((sitewise, ind) => (
                                                                    <div className="alignContentsCenter">
                                                                        <span key={ind} className="alignNetworkUsage">{readableBytesAsGB(sitewise.total_usage).replace(" GB", "")}</span>
                                                                        <span className="wanUsagePlusIconWidth"></span>
                                                                    </div>
                                                                ))}
                                                            </TableCell>
                                                        </TableRow>  
                                                    </TableCell>
                                                </TableRow>
                                            )}
                                            {selectCard === "VSAT" && row.network_usage && vessels?.length > 1 && (
                                                <TableRow className="usagePadding tabelRow appUsage-tableRow">
                                                    <TableCell className={tab!=='vessel_chart' ? "appUsage-tableCell activityTableCell-logo activity-appUsage-tableCell usageNameCellPadding verticalAlign" : 'appUsage-tableCell activityTableCell-logo usageNameCellPadding verticalAlign'}>
                                                        {row.network_usage.map((sitewise, ind) => (
                                                            <div>
                                                                <span key={ind}>{sitewise.name}</span>
                                                            </div>
                                                        ))}
                                                    </TableCell>
                                                    <TableCell className={tab!=='vessel_chart' ? "usage-innerTableCell activity-usage-innerTableCell networkUsagePadding" : 'usage-innerTableCell networkUsagePadding'}>
                                                        <TableRow className="usage-innerTableRow">
                                                            <TableCell className={tab!=='vessel_chart' ? "usage-innerTableCell activity-usage-innerTableCell verticalAlign usageCellPadding" : 'usage-innerTableCell verticalAlign usageCellPadding'}>
                                                                {row.network_usage.map((sitewise, ind) => (
                                                                  <div key={ind} className="networkUsageProgressBar">
                                                                    <BorderLinearProgress variant="determinate" value={Math.ceil(sitewise.percentage)} green={true}/>
                                                                  </div>
                                                                ))}
                                                            </TableCell>
                                                            <TableCell className={tab!=='vessel_chart' ? "usage_innerTableCell activity-usage_innerTableCell usageCellPadding" : 'usage_innerTableCell usageCellPadding'}>
                                                                {row.network_usage.map((sitewise, ind) => (
                                                                    <div className="alignContentsCenter">
                                                                        <span key={ind} className="alignNetworkUsage">{readableBytesAsGB(sitewise.total_usage).replace(" GB", "")}</span>
                                                                        <span className="wanUsagePlusIconWidth"></span>
                                                                    </div>
                                                                ))}
                                                            </TableCell>
                                                        </TableRow>  
                                                    </TableCell>
                                                </TableRow>
                                            )}
                                            {(selectCard === "CELLULAR" || selectCard === 'STARLINK' || selectCard === 'LBAND' || selectCard === 'ETHERNET' || selectCard === 'WIFI') && row.network_usage && vessels?.length > 1 && (
                                                <TableRow className="usagePadding tabelRow appUsage-tableRow">
                                                    <TableCell className={tab!=='vessel_chart' ? "appUsage-tableCell activityTableCell-logo activity-appUsage-tableCell usageNameCellPadding verticalAlign" : 'appUsage-tableCell activityTableCell-logo usageNameCellPadding verticalAlign'}>
                                                        {row.network_usage.map((sitewise, ind) => (
                                                            <div>
                                                                <span key={ind}>{sitewise.name}</span>
                                                            </div>
                                                        ))}
                                                    </TableCell>
                                                    <TableCell className={tab!=='vessel_chart' ? "usage-innerTableCell activity-usage-innerTableCell networkUsagePadding" : 'usage-innerTableCell networkUsagePadding'}>
                                                        <TableRow className="usage-innerTableRow">
                                                            <TableCell className={tab!=='vessel_chart' ? "usage-innerTableCell activity-usage-innerTableCell verticalAlign usageCellPadding" : 'usage-innerTableCell verticalAlign usageCellPadding'}>
                                                                {row.network_usage.map((sitewise, ind) => (
                                                                  <div key={ind} className="networkUsageProgressBar">
                                                                    <BorderLinearProgress variant="determinate" value={Math.ceil(sitewise.percentage)} green={true}/>
                                                                  </div>
                                                                ))}
                                                            </TableCell>
                                                            <TableCell className={tab!=='vessel_chart' ? "usage_innerTableCell activity-usage_innerTableCell usageCellPadding" : 'usage_innerTableCell usageCellPadding'}>
                                                                {row.network_usage.map((sitewise, ind) => (
                                                                    <div className="alignContentsCenter">
                                                                        <span key={ind} className="alignNetworkUsage">{readableBytesAsGB(sitewise.total_usage).replace(" GB", "")}</span>
                                                                        <span className="wanUsagePlusIconWidth"></span>
                                                                    </div>
                                                                ))}
                                                            </TableCell>
                                                        </TableRow>  
                                                    </TableCell>
                                                </TableRow>
                                            )}
                                          </React.Fragment>
                                        )
                                        : 
                                        appDownloadTableData && appDownloadTableData.map((row, i) =>
                                            <TableRow className="tabelRow appUsage-tableRow">
                                                { tab == "top_aaplication" ?
                                                    <TableCell className={tab!=='vessel_chart' ? "appUsage-tableCell activityTableCell-logo activity-appUsage-tableCell" : 'appUsage-tableCell activityTableCell-logo'}>
                                                        <Tooltip title={row.application}>
                                                            <div>
                                                                <GetTopAppsImage app={row.application} />
                                                                <span>{row.application}</span>
                                                            </div>
                                                        </Tooltip>
                                                    </TableCell> : 
                                                    <TableCell className={tab!=='vessel_chart' ? "appUsage-tableCell activityTableCell-logo activity-appUsage-tableCell" : 'appUsage-tableCell activityTableCell-logo'}>
                                                        <Tooltip title={row.application}>
                                                            <div>
                                                                <span>{row.application}</span>
                                                            </div>
                                                        </Tooltip>
                                                    </TableCell>
                                                }
                                                <TableCell className="appUsage_tableCell"><span className="blockedHits">{row.hits}</span></TableCell>
                                            </TableRow>
                                        )
                                    }
                                </TableBody>
                                ) : (
                                <TableBody>
                                    <TableRow className="noData-appDetails">
                                        <TableCell colSpan={2} className="noDataAvailableCss" align="center">No data available</TableCell>
                                    </TableRow>
                                </TableBody>
                            )}                                     
                        </Table>
                    </TableContainer>
                </Grid>
           </Grid>
        </Grid>
    </div>
    
)
}

const mapStateToProps = (state) => ({
  authReducer: state.authReducer,
  errorReducer: state.errorReducer,
  wanUsageReportSingleSiteNetworkUsage: state.authReducer.wanUsageReportSingleSiteNetworkUsage,
  wanUsageReportSingleSiteNetworkUsageLoading: state.authReducer.wanUsageReportSingleSiteNetworkUsageLoading,
  WanUsageSingleSiteVsatSelectionNetworkUsageLoading: state.authReducer.WanUsageSingleSiteVsatSelectionNetworkUsageLoading,
  WanUsageSingleSiteWanSelectionNetworkUsageLoading: state.authReducer.WanUsageSingleSiteWanSelectionNetworkUsageLoading,
  newSummaryStartDate: state.authReducer.newSummaryStartDate,
  newSummaryEndDate: state.authReducer.newSummaryEndDate,
});

export default withRouter(
  connect(mapStateToProps, {
    getWanUsageSingleSiteNetworkUsage,
    getWanUsageSingleSiteWanSelectionNetworkUsage,
    getWanUsageSingleSiteVsatSelectionNetworkUsage
  })(AppUsageCard)
);