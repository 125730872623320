
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";import { Link } from "react-router-dom";
import { TableCell, TableRow, TableContainer, TableHead, TableBody, Table, DialogTitle, Dialog, DialogContent, Grid } from "@mui/material";
import { getDecodeURI, readableBytesAsGB, redirectToTopolgyTabs, convertDateTimeIntoTimezone, getEncodedURI } from "../../utils/util";
import CircleIcon from '@mui/icons-material/Circle';
import _ from "lodash";
import close from "../../asset/image/cross.svg";
import { MMDDYYYYHMMSS_DATE_FORMAT_24_HRS } from '../../utils/constants';

const ClientsData = ({clientData, open, setOpen, loading, selectedSite, location, userTimezone, history}) => {

    const redirectToSDWAN = (row) => {
        let params = getDecodeURI(location?.search);
        params.siteId = selectedSite.siteId;
        params.agentId = selectedSite.agentId;
        params.wanId = row.wan_name;
        params.hub_name = selectedSite.hub_name;
        params.hubId = selectedSite.hubId;
        params.device_id = selectedSite.deviceId;
        history.push({ pathname: '/fleet-dashboard/hub/topology', search: `?${getEncodedURI(params)}` });
    }

    return (
        <Dialog open={open} className="dashboard_popop_dialog interface-profile-popup" onClose={() => setOpen(false)}>
            <DialogTitle className='pop-head'>
                <Grid item container className="hub-clients-header">
                    {selectedSite.name}
                    <Grid className="hub-clients-header-grid">
                        <img src={close} onClick={() => setOpen(false)} />
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent>
                <Grid container className="deploy-inventory-content device-history-content client-data-content">
                    <TableContainer className="usageReports-FleetDashboard loginHistory-tableContainer">
                        <Table aria-label="simple sticky table" stickyHeader>
                            <TableHead className="usageReports-tableHead">
                                <TableRow className="UsageReports-tableRow login-tableRow">
                                    <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell CF-cell device-history-th">WAN INTERFACE</TableCell>
                                    <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell CF-cell device-history-th clientData-speedHeader">SPEED (Mbps)</TableCell>
                                    <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell CF-cell device-history-th clientData-speedHeader">Latency (ms)</TableCell>
                                    <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell CF-cell device-history-th">Flows</TableCell>
                                    <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell CF-cell device-history-th">USAGE</TableCell>
                                    <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell CF-cell device-history-th last-observed-width">LAST OBSERVED</TableCell>
                                </TableRow>
                            </TableHead>
                            {0 != clientData.length && !loading ? (
                                <TableBody className="tableBody usage-tableBody">
                                    {
                                        clientData && clientData?.map((row, i) =>
                                            <TableRow key={i} className="loginHistory-tableRow">
                                                <TableCell className="login-left-align">
                                                    {selectedSite.service == 'AGENT' ? <span className="hub-redirectToOtherTabs" onClick={() => redirectToSDWAN(row)}>{row?.wan_name}</span> : <div className="client-data-wan-status">
                                                        <CircleIcon id={row.status == 1 ? 'greenDotSymbol' : 'redDotSymbol'} />
                                                        <Link to={row?.wan_name && redirectToTopolgyTabs(location, "/fleet-dashboard/sites/SDWAN", selectedSite?.id, selectedSite?.name, {device_id: row.client_device_id, wan_name: row.wan_name}, true, false)} className='hub-redirectToOtherTabs'>
                                                            {row?.wan_name}
                                                        </Link>
                                                    </div>}
                                                </TableCell>
                                                <TableCell className="login-left-align">{row?.speed ? (row.speed).toFixed(3) : '-'}</TableCell>
                                                <TableCell className="login-left-align">{row?.latency ? (row.latency).toFixed(2) : '-'}</TableCell>
                                                <TableCell className="login-left-align">{row?.flows ? Math.round(row.flows) : '-'}</TableCell>
                                                <TableCell className="login-left-align">{row?.usage ? readableBytesAsGB(row.usage) : '-'}</TableCell>
                                                <TableCell className='login-left-align'>{convertDateTimeIntoTimezone(row?.last_observed, userTimezone, MMDDYYYYHMMSS_DATE_FORMAT_24_HRS)}</TableCell>
                                            </TableRow>
                                        )
                                    }
                                </TableBody>
                            ) : (
                                <TableBody>
                                    <TableRow>
                                        <TableCell colSpan={6} className="noDataAvailableCss" align="center">No data available</TableCell>
                                    </TableRow>
                                </TableBody>
                            )}
                        </Table>
                    </TableContainer>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}

const mapStateToProps = (state) => ({

});

export default withRouter(
    connect(mapStateToProps, {})(ClientsData)
)