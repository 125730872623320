import { api } from "../../server/request";
import { handleResponse, handleError, handleResponseObj } from "../responseUtil";
import { KAPTUREV2_URL_INV } from "../../config";

import {SET_LOADING, HIGH_AVAILABILITY, GET_HIGH_AVAILABILITY_DEVICE, CREATE_HA, SYNC_DEVICE, UPDATE_DEVICE, DELETE_HIGH_AVAILABILITY } from "../types";
import { toast } from "react-toastify";

export const highAvailability = ( k4Id:'' ) => (dispatch) => {
    dispatch({ type: SET_LOADING, payload: true });
    api
      .getRequest(`/client/v1/sites/${k4Id}/high_availability`)
      .then((res) => handleResponseObj(res, dispatch, HIGH_AVAILABILITY))
      .catch((err) => handleError(err, dispatch));
  };
  
export const getHighAvailabilityDevice = (dpId, k4Id) => (dispatch) => {
    dispatch({ type: SET_LOADING, payload: true });
    api
      .getRequest(`/edge/distributionPartners/${dpId}/locations/${k4Id}`)
      .then((res) => handleResponse(res, dispatch, GET_HIGH_AVAILABILITY_DEVICE))
      .catch((err) => handleError(err, dispatch));
  };

  const handleCreateHA = (res, dispatch) => {
    if(res.data && res.data.success==false ) {
      toast.error(res.data.errors[0].message, {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    }
    else if(res && res.success==false ) {
      toast.error(res.errors[0].message, {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    }
    else{
      toast.success("Created successfully", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    }
    handleResponseObj(res, dispatch, CREATE_HA)
  }

export const createHighAvailability = (k4Id:'',payload) => (dispatch) => {
  dispatch({ type: SET_LOADING, payload:true});
  api
  .postRequest(`/client/v1/sites/${k4Id}/high_availability`, payload)
  .then((res) => handleCreateHA(res, dispatch))
  .catch((err) => handleError(err, dispatch));
}

const handleSync = (res, dispatch) => {
  if(res.data && res.data.success==false ) {
    toast.error("Sync Failed", {
      position: toast.POSITION.BOTTOM_LEFT,
    });
  }
  else{
    toast.success("Sync Success", {
      position: toast.POSITION.BOTTOM_LEFT,
    });
  }
  handleResponse(res, dispatch, SYNC_DEVICE)
}

export const syncDevice = (k4Id:"", payload) => (dispatch) => {
  dispatch({ type: SET_LOADING, payload:true});
  api
  .postRequest(`/client/v1/sites/${k4Id}/high_availability/sync`, payload)
  .then((res) => handleSync(res, dispatch))
  .catch((err) => handleError(err, dispatch));
}

const handleUpdate = (res, dispatch) => {
  if(res.data && res.data.success==false ) {
    toast.error(res.data.errors[0].message, {
      position: toast.POSITION.BOTTOM_LEFT,
    });
  }else if (res.success==false){
    toast.error(res.errors[0].message, {
      position: toast.POSITION.BOTTOM_LEFT,
    });
  }
  else {
    toast.success('Updated successfully', {
      position: toast.POSITION.BOTTOM_LEFT,
    });
  }
  handleResponseObj(res, dispatch, UPDATE_DEVICE)
}

export const updateDevice = (k4Id:"", payload) => (dispatch) => {
  dispatch({ type: SET_LOADING, payload:true});
  api
  .putRequest(`/client/v1/sites/${k4Id}/high_availability`, payload)
  .then((res) => handleUpdate(res, dispatch))
  .catch((err) => handleError(err, dispatch));
}

const handleDelete = (res, dispatch) => {
  if (res.data && res.data.success == false) {
    toast.error('Deletion failed', {
      position: toast.POSITION.BOTTOM_LEFT,
    });
  } else {
    toast.success('Deleted successfully', {
      position: toast.POSITION.BOTTOM_LEFT,
    });
  }
  handleResponse(res, dispatch, DELETE_HIGH_AVAILABILITY)
}

export const deleteHighAvailability = (k4Id: "") => (dispatch) => {
  dispatch({ type: SET_LOADING, payload: true });
  api
    .deleteRequest(`/client/v1/sites/${k4Id}/high_availability`)
    .then((res) => handleDelete(res, dispatch))
    .catch((err) => handleError(err, dispatch));
}

export const updateHASecondaryDeviceLicense = (secondaryDevice)=>(dispatch)=>{
  api
  .postRequestForExternalApi(`${KAPTUREV2_URL_INV}inventoryConfig/updateLicenseToHA?deviceId=${secondaryDevice}`)
  .then((res) =>{
    if(res?.status == 1){
      toast.success('HA license updated successfully.', {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else {
      toast.error(res?.error?.message, {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    }
  })
  .catch((err) => {
    toast.error('Error updating secondary device license.', {
      position: toast.POSITION.BOTTOM_LEFT,
    });
  });
}