import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import NoDataToDisplay from "highcharts/modules/no-data-to-display";
import { useMemo, useState, useRef, useEffect } from "react";
import { Box, Button, Grid, LinearProgress, Paper } from "@mui/material";
import moment from "moment-timezone";
import ChartUnSelected from "../../../asset/image/ChartUnSelected.svg";
import { getTimezoneCity, readableBytesAsGB } from "../../../utils/util";
import Card from "@mui/material/Card";
import { makeStyles } from "@material-ui/core/styles";
import Download from '../../../asset/image/DownloadUsage.svg';
import Exporting from 'highcharts/modules/exporting';
import ExportData from 'highcharts/modules/export-data';
import upArrow from '../../../asset/image/newUpArrow.svg';
import PieChart from "./PieChart";
import NewDownloadButton from '../../../asset/image/NewDownloadButton.svg';
import PlanHierarchy from "./PlanHierarchy";
import { getStarlinkPlanUsageTopWidget } from "../../StarlinkReports/slice";
import _ from "lodash";

NoDataToDisplay(Highcharts);

const useStyles:any = makeStyles({
    colorPrimary: {
        backgroundColor: '#D6DAFA',
    },
    barColorPrimary: {
        backgroundColor: '#2F4B82',
    }
});

const AssetUsageBarChart = (props) => {
    const { selectedOu, startDate, endDate, assetUsage, assetPlanUsageSplit, userTimezone, expand, toggleExpand, parentPoolId, setParentPoolId, getStarlinkPlanUsageTopWidget, starlinkUsageTopWidget, planFilter, setPlanFilter, subTab, setSelectedRow, setExpandedRows, selectedRow, expandedRows, planData  } = props;
    const timeZone = getTimezoneCity(userTimezone);
    const [cardsData, setCardsData] = useState([
        { title: "TOTAL USAGE", value: 0, isGB: true },
        { title: "TOTAL PRIORITY USAGE", value: 0, isGB: true },
        { title: "ORGANIZATION", value: 0, isGB: false },
        { title: "SERVICE LINES", value: 0, isGB: false }
    ]);
    const classes = useStyles();

    const formattedData = useMemo(() => {
        const data:any = {
            standard_gb: [],
            priority_gb: [],
            optIn_priority_gb: [],
            non_billable_gb:[]
        };
    
        // const cards: any = [
        //     { key: 'total_priority_gb', label: 'PRIORITY' },
        //     { key: 'total_standard_gb', label: 'STANDARD' },
        //     { key: 'total_non_billable_gb', label: 'NON-BILLABLE' },
        //     { key: 'total_optIn_priority_gb', label: 'OPT-IN PRIORITY' }
        // ];
    
        // Process assetPlanUsageSplit for time series data
        assetPlanUsageSplit && Object.keys(assetPlanUsageSplit)?.forEach((key) => {
            const series = assetPlanUsageSplit?.[key];
            series?.forEach(({ x, y }) => {
                const timestamp = x;
                const usage_value = y;
                if (key === "standard_gb") data.standard_gb.push([timestamp, usage_value]);
                if (key === "optIn_priority_gb") data.optIn_priority_gb.push([timestamp, usage_value]);
                if (key === "non_billable_gb") data.non_billable_gb.push([timestamp, usage_value]);
                if (key === "priority_gb") data.priority_gb.push([timestamp, usage_value]);
            });
        });
        
        // Create cardsData using the first `y` value in the array for each key (total value)
        // const cardsData = cards.map(({ key, label }) => {
        //     const series = assetPlanUsageSplit?.[key];
        //     const total = series && series.length > 0 ? series[0].y.toFixed(2) : '0.00';
        //     return {
        //         label: label,
        //         value: total
        //     };
        // });
    
        return { data, cardsData };
    }, [assetUsage, assetPlanUsageSplit]);

    const HighchartsInstance = { ...Highcharts };
    Exporting(HighchartsInstance);
    ExportData(HighchartsInstance);
    Highcharts.setOptions({
        exporting: { enabled: false },
    });

    const series = !_.isEmpty(parentPoolId) && !_.isEmpty(planFilter) ? [
        // {
        //     name: "Priority GB",
        //     data: formattedData?.data?.priority_gb,
        //     stack: "gb",
        //     color: "#3a88d3",
        // },
      
        {
            name: "Standard GB",
            data: formattedData?.data?.standard_gb,
            stack: "gb",
            color: "#1d3fa6",
        },
        {
            name: "Non Billable GB",
            data: formattedData?.data?.non_billable_gb,
            stack: "gb",
            color: "#8ac9e3",
        },
        {
            name: "Opt-In Priority GB",
            data: formattedData?.data?.optIn_priority_gb,
            stack: "gb",
            color: "#4464f3",
        }
    ] : [];

   useEffect(() => {
    if(parentPoolId && selectedOu?.id) {
        getStarlinkPlanUsageTopWidget(parentPoolId, selectedOu?.id, planFilter?.plan_id, planFilter?.type, planFilter?.account_number)
    }
   }, [selectedOu, parentPoolId, planFilter])

   useEffect(() => {
    if (!_.isEmpty(starlinkUsageTopWidget)) {
        const data = starlinkUsageTopWidget?.[0] || {};
        
        setCardsData([
            { title: "TOTAL USAGE", value: data.total_usage ?? 0, isGB: true },
            { title: "TOTAL PRIORITY USAGE", value: data.total_priority_usage ?? 0, isGB: true },
            { title: "ORGANIZATION", value: data.total_sub_organizations ?? 0, isGB: false },
            { title: "SERVICE LINES", value: data.total_service_lines ?? 0, isGB: false }
        ]);
    }
}, [starlinkUsageTopWidget]);

    const options = {
        time: {
            timezone: timeZone,
        },
        chart: {
            type: "column",
            height: Math.max(230, window.innerHeight - 365),
            zoomType: "x",
            plotBorderWidth: 1,
            style: {
                fontFamily: "Roboto, Nunito Sans, Arial, Verdana, Helvetica, sans-serif",
            },
        },
        title: {
            text: "",
            align: "left",
            floating: true,
            x: 12,
            y: 32,
            style: {
                fontWeight: "500",
            },
        },
        lang: {
            noData: "No Data",
        },
        noData: {
            style: {
                fontWeight: "bold",
                fontSize: "15px",
                color: "#303030",
            },
        },
        credits: {
            enabled: false,
        },
        legend: {
            enabled: true,
        },
        plotOptions: {
            column: {
                stacking: "normal",
                cursor: 'pointer',
                dataLabels: {
                    enabled: false,
                },
            },
        },
        tooltip: {
            shared: true,  
            pointFormat:
                '<span style="color:{series.color}">{series.name}</span>: <b>{point.y} GB</b><br/>',
            valueDecimals: 0,
            borderWidth: 1,
        },
        xAxis: {
            gridLineWidth: 0.5,
            type: "datetime",
        },
        yAxis: {
            // tickInterval: 50,
            gridLineWidth: 1,
            labels: {
                format: "{value} GB",
            },
            title: {
                text: null,
            },
        },
        exporting: {
            enabled: false,
            csv: {
                columnHeaderFormatter: (item, key) => {
                    if (item.isXAxis) {
                        return 'Time';
                    }
                    return `${item.name.replace(" GB", "")} (GB)`;
                }
            }
        },
        series: series,
    };

    function addCommasToNumber(number) {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    // Calculate total usage for Priority, Standard, and Non-Billable
    const totalUsage = Number(formattedData?.cardsData[0]?.value) +  Number(formattedData?.cardsData[1]?.value) +  Number(formattedData?.cardsData[2]?.value);

    // Calculate progress for each item
    const calculateProgress = (value, total) => (value / total) * 100;

    // Function to format values based on size (GB or TB)
    const formatUsage = (valueInGB) => {
        if (Number(valueInGB) >= 1000) {
            const tbValue = Number(valueInGB) / 1000;
            return `${tbValue.toFixed(2)} TB`;
        } else {
            return `${Number(valueInGB).toFixed(2)} GB`;
        }
    };

    const chartRef = useRef<HighchartsReact.RefObject>(null);

    const handleDownloadCSV = () => {
        if (chartRef.current?.chart) {
            const csvData = chartRef.current.chart.getCSV();
            const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
            const link = document.createElement('a');
            const url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
            link.setAttribute('download', 'starlink_usage_data.csv');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } else {
            console.error('Chart reference is null or not initialized.');
        }
    };

    return (
        <>
            {expand.isCardExpanded && (
                <Grid className="cards" container columns={12} spacing={2}>
                    {cardsData.map((card, index) => (
                        <Grid item xs={12} sm={6} md={3} lg={3} key={index}>
                            <Card className="card-widget" id="removeBoxShadow">
                                <Box className="cardHeader cardHeader-serviceLines">
                                    <span className="cardTitle">{card.title}</span>
                                </Box>
                                <Box className="cardContents">
                                    <Box className="cardActive">
                                        <div className="content">
                                            {card.isGB ? `${(card.value ?? 0).toFixed(2)} GB` : card.value ?? 0}
                                        </div>
                                    </Box>
                                </Box>
                                <Box sx={{ margin: '10px' }}>
                                    <LinearProgress
                                        sx={{ "--LinearProgress-radius": "10px" }}
                                        classes={{ colorPrimary: classes.colorPrimary, barColorPrimary: classes.barColorPrimary }}
                                        variant="determinate"
                                        value={100}
                                    />
                                </Box>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            )}
            <div style={{ boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)', maxHeight: 'calc(100vh - 365px)' }}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', margin: '0 6px 0 15px' }}>
                    <span style={{ fontFamily: 'Inter', fontWeight: '600', fontSize: '14px' }}>Graphs</span>
                    <img src={upArrow} alt={expand.isChartsExpanded ? "collapse" : "expand"} onClick={() => toggleExpand('isChartsExpanded')} style={!expand.isChartsExpanded ? { transform: 'rotate(180deg)', cursor: 'pointer' } : { transform: 'rotate(0deg)', cursor: 'pointer' }} />
                </div>
                {expand.isChartsExpanded && <Grid container spacing={1} style={{maxHeight: 'calc(100vh - 385px)'}}>
                    <Grid item md={4.3} lg={4.3}>
                        <PlanHierarchy setParentPoolId={setParentPoolId} parentPoolId={parentPoolId} setPlanFilter={setPlanFilter} subTab={subTab} setSelectedRow={setSelectedRow} setExpandedRows={setExpandedRows} selectedRow={selectedRow} expandedRows={expandedRows} planData={planData} />
                    </Grid>
                    <Grid item md={3.4} lg={3.4}>
                        <PieChart parentPoolId={parentPoolId} planFilter={planFilter} />
                    </Grid>
                    <Grid item md={4.3} lg={4.3} className="Starlink-Usage-LineChart">
                        <Paper elevation={1} className="starlink--reports--usage_chart--container plan-starlink--reports--usage_chart--container" classes={{ root: 'starlink--base--mtb_07x' }}>
                            <div className="alignTopbarItemsCenter" style={{height: 'unset'}}>
                                {series?.some(item => item?.data?.length > 0) && (
                                    <div className="margin-left-auto" onClick={handleDownloadCSV}>
                                        <img src={NewDownloadButton} alt="" />
                                    </div>
                                )}
                            </div>
                            <HighchartsReact highcharts={Highcharts} options={options} ref={chartRef} />
                        </Paper>
                    </Grid>
                </Grid>}
            </div>
        </>
    );
};

const mapStateToProps = (state) => ({
    assetUsage: state.starlinkReports.assetUsage,
    assetPlanUsageSplit: state.starlinkReports.assetPlanUsageSplit,
    starlinkUsageTopWidget: state.starlinkReports.starlinkUsageTopWidget,
    userTimezone: state.authReducer.userTimezone,
    newSummaryServiceLine: state?.authReducer?.newSummaryServiceLine,
    selectedOu: state.authReducer.selectedOu
});

export default withRouter(connect(mapStateToProps, { getStarlinkPlanUsageTopWidget })(AssetUsageBarChart));