import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { useEffect, useState, useRef } from "react";
import { Button, Grid, Tooltip } from "@mui/material";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import _ from "lodash";
import Exporting from 'highcharts/modules/exporting';
import ExportData from 'highcharts/modules/export-data';
import NewBarIcon from '../../../asset/image/NewBarIcon.svg';
import NewPieIcon from '../../../asset/image/NewPieIcon.svg';
import NewDownloadButton from '../../../asset/image/NewDownloadButton.svg';
import CircleIcon from '@mui/icons-material/Circle';
import { getStarlinkUsagePoolPlansChart, getStarlinkUsagePoolServiceLinesChart } from "../../StarlinkReports/slice";
import moment from "moment";

const PieChart = (props) => {
    const { parentPoolId, newSummaryStartDate, newSummaryEndDate, starlinkUsagePoolPlansChart, getStarlinkUsagePoolPlansChart, getStarlinkUsagePoolServiceLinesChart, starlinkUsagePoolServiceLinesChart, planFilter, location, history } = props;
    const [chartData, setChartData] = useState<any>([]);
    const [serviceLinesData, setServiceLinesData] = useState<any>([]);
    const [selectedTab, setSelectedTab] = useState('plans');
    const [chartType, setChartType] = useState('pie');

    const HighchartsInstance = { ...Highcharts };
    Exporting(HighchartsInstance);
    ExportData(HighchartsInstance);
    Highcharts.setOptions({
        exporting: { enabled: false },
    });

    const getUsageSource = () => {
        const endDate = moment(newSummaryEndDate, 'YYYY-MM-DD HH:mm:ss');
        const startDate = moment(newSummaryEndDate, 'YYYY-MM-DD HH:mm:ss');

        const hoursDifference = moment.duration(endDate.diff(startDate)).asHours();
        if (hoursDifference <= 24) {
            return 'aggregation_table';
        } else {
            return 'direct_source';
        }
    }

    useEffect(() => {
        if (!parentPoolId) return;

        const startDate = newSummaryStartDate.format('YYYY-MM-DD');
        const endDate = newSummaryEndDate.format('YYYY-MM-DD');
        const source = getUsageSource();

        const fetchChartData = selectedTab === 'plans'
            ? getStarlinkUsagePoolPlansChart
            : getStarlinkUsagePoolServiceLinesChart;

        fetchChartData(parentPoolId, startDate, endDate, source, planFilter?.plan_id, planFilter?.type, planFilter?.account_number);

    }, [newSummaryStartDate, newSummaryEndDate, selectedTab, parentPoolId, planFilter]);

    useEffect(() => {
        if(_.isEmpty(parentPoolId) && _.isEmpty(planFilter)) {
            setChartData([]);
            setServiceLinesData([]);
            return;
        }
        if (!_.isEmpty(starlinkUsagePoolPlansChart)) {
            setChartData(starlinkUsagePoolPlansChart)
            // dispatch({type: ACTION_TYPES.GET_STARLINK_USAGE_POOL_PLANS_CHART, payload: {}})
        } else {
            setChartData([]);
        }
        if (!_.isEmpty(starlinkUsagePoolServiceLinesChart)) {
            setServiceLinesData(starlinkUsagePoolServiceLinesChart)
            // dispatch({type: ACTION_TYPES.GET_STARLINK_USAGE_SERVICE_LINES_CHART, payload: {}})
        } else {
            setServiceLinesData([])
        }
    }, [starlinkUsagePoolPlansChart, starlinkUsagePoolServiceLinesChart, parentPoolId, planFilter])

    const options = {
        chart: {
            type: chartType,
            height: chartType == 'pie' ? '170px' : '330px',
            style: {
                fontFamily: "Roboto, Nunito Sans, Arial, Verdana, Helvetica, sans-serif",
            },
            plotBorderWidth: chartType != 'pie' && 1,
            backgroundColor: 'none'
        },
        colors: ['#2f2282', '#1b51a7', '#3dab97', '#5e3f9e', '#3e88d3', '#1d136a', '#69b8e5', '#3f79ca', '#9e8cc5', '#ed9340',
            '#8cafdf', '#d9914f', '#86c6df', '#3c7df1', '#61cdc0', '#64a1f4', '#dc6161', '#146680', '#2039b5', '#00ad64',
            '#1b4dac', '#b50000', '#8cb5ec', '#c1d5f6', '#b3bbe6', '#e5a8a8', '#f9dabe', '#4b91f1', '#8dbdff', '#efeab9',
            '#8a39a7', '#d9cb4f', '#145f89', '#e7d696', '#8592e7', '#e78585', '#92d7c9', '#aeaeb1', '#755c5d', '#7c6ce6',
            '#cc7460', '#bd9331', '#15e1e8', '#514ced', '#8633de', '#eb52b3', '#c4355b', '#6dc779', '#5373a3', '#8dc293'
        ],
        title: {
            verticalAlign: 'middle',
            floating: true,
            text: '',
            style: {
                color: '#9E9E9E',
                fontFamily: 'Roboto',
                fontSize: '10px',
                fontWeight: '400'
            }
        },
        xAxis: {
            categories: selectedTab == 'plans' ? chartData?.map(item => item.name) : serviceLinesData?.map(item => item.name),
            type: 'category',
            title: {
                text: ''
            },
            gridLineDashStyle: 'Dot',
            gridLineWidth: 1,
            labels: {
                style: {
                    // color: '#4c3aff',
                    // cursor: 'pointer',
                    width: '100px',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                },
                enabled: chartType != 'pie'
            }
        },
        yAxis: {
            title: {
                text: ''
            },
            labels: {
                format: `{value} GB`,
                enabled: chartType != 'pie'
            },
            gridLineDashStyle: 'Dot',
            gridLineWidth: 1,
        },
        tooltip: {
            enabled: true,
            headerFormat: '',
            pointFormat: '<span style="color: #7e8089">{point.name}</span> <br/> <b style="color: #fff">{point.y}</b><br/>',
            valueDecimals: 2,
            useHTML: true,
            borderRadius: 15,
            backgroundColor: '#060606',
            followPointer: false,
            valueSuffix: ' GB',
        },
        plotOptions: {
            pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                shadow: false,
            },
            column: {
                stacking: false,
                grouping: true,
                allowPointSelect: false,
                cursor: 'pointer',
                shadow: false,
                dataLabels: {
                    enabled: true,
                    format: '{point.y}'
                },
                pointWidth: 15,
            }
        },
        legend: {
            enabled: false
        },
        credits: {
            enabled: false
        },
        exporting: {
            enabled: false,
            csv: {
                columnHeaderFormatter: (item, key) => {
                    if (item.isXAxis) {
                        return 'Time';
                    }
                    return `${item.name} (GB)`;
                }
            }
        },
        series: [{
            data: !_.isEmpty(chartData) && selectedTab == 'plans' ? chartData : !_.isEmpty(serviceLinesData) && selectedTab == 'service_lines' ? serviceLinesData : [],
            dataLabels: {
                enabled: false
            },
            color: '#86c6df',
            size: '100%',
            innerSize: '50%',
        }]
    }

    const colors = ['#2f2282', '#1b51a7', '#3dab97', '#5e3f9e', '#3e88d3', '#1d136a', '#69b8e5', '#3f79ca', '#9e8cc5', '#ed9340',
        '#8cafdf', '#d9914f', '#86c6df', '#3c7df1', '#61cdc0', '#64a1f4', '#dc6161', '#146680', '#2039b5', '#00ad64',
        '#1b4dac', '#b50000', '#8cb5ec', '#c1d5f6', '#b3bbe6', '#e5a8a8', '#f9dabe', '#4b91f1', '#8dbdff', '#efeab9',
        '#8a39a7', '#d9cb4f', '#145f89', '#e7d696', '#8592e7', '#e78585', '#92d7c9', '#aeaeb1', '#755c5d', '#7c6ce6',
        '#cc7460', '#bd9331', '#15e1e8', '#514ced', '#8633de', '#eb52b3', '#c4355b', '#6dc779', '#5373a3', '#8dc293'
    ]

    const chartRef = useRef<HighchartsReact.RefObject>(null);

    const handleDownloadCSV = () => {
        if (!chartRef.current?.chart) {
            console.error('Chart reference is null or not initialized.');
            return;
        }

        const chart = chartRef.current.chart;
        let csvContent = "";
        let header = "";

        if (selectedTab === 'plans') {
            header = "Plan,Usage (GB)\n";
        } else if (selectedTab === 'service_lines') {
            header = "Service Line,Usage (GB)\n";
        } else {
            header = "Category,Usage (GB)\n";
        }

        if (chartType === 'pie') {
            csvContent += header;
            csvContent += chart.series[0].data
                .map(point => `${point.name},${point.y ?? 0}`)
                .join("\n");
        } else {
            const categories = chart.xAxis[0].categories;
            const series = chart.series[0];

            csvContent += header;
            categories.forEach((category, index) => {
                const usage = series.data[index]?.y ?? 0;
                csvContent += `${category},${usage}\n`;
            });
        }

        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', 'chart_usage_data.csv');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <div className="new-summary-chart-container" style={{ margin: '10px 0 18px 0', height: '100%' }}>
            <Grid className='new-summary-chart-component' >
                <Grid >
                    <Grid className="usage-per-site-pie">
                        <Grid className="new-summary-control-limit" style={{ justifyContent: 'space-between' }}>
                            <Tooltip title='Source : Activity Report'><div className="title-text starlink-usage-pie">Summary</div></Tooltip>
                            <Grid className="pieChat-selectTag">
                                <Grid className="analytics-sub-tabs-container" style={{ paddingLeft: 'unset' }}>
                                    {[{ tabName: 'Plans', tabValue: 'plans' }, { tabName: 'Lines', tabValue: 'service_lines' }].map(subTabValue => (
                                        <Button
                                            key={subTabValue.tabValue}
                                            className="analytics-sub-tabs"
                                            id={subTabValue.tabValue === selectedTab ? 'selectedTab-background' : ''}
                                            onClick={() => {setSelectedTab(subTabValue.tabValue); setServiceLinesData([]); setChartData([])}}
                                        >
                                            {subTabValue.tabName}
                                        </Button>
                                    ))}
                                </Grid>
                            </Grid>
                            <Grid style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                                <img src={NewPieIcon} alt="pie" onClick={() => setChartType('pie')} className={chartType == 'pie' ? 'iconColor-select' : 'iconColor-unselect'} />
                                <img src={NewBarIcon} alt="column" onClick={() => setChartType('column')} className={chartType == 'column' ? 'iconColor-select' : 'iconColor-unselect'} />
                                {selectedTab == 'plans' && chartData?.length > 0 && <img src={NewDownloadButton} alt="download" onClick={handleDownloadCSV} style={{ width: '24px', height: '24px', cursor: 'pointer' }} />}
                                {selectedTab == 'service_lines' && serviceLinesData?.length > 0 && <img src={NewDownloadButton} alt="download" onClick={handleDownloadCSV} style={{ width: '24px', height: '24px', cursor: 'pointer' }} />}
                            </Grid>
                        </Grid>
                        <div className={chartType == 'pie' ? "hide-axis-line" : ''} id={chartType == 'pie' ? 'Starlink-Usage-PieChart' : 'Starlink-Usage-ColumnChart'}>
                            <HighchartsReact
                                highcharts={Highcharts}
                                options={options}
                                ref={chartRef}
                            />
                        </div>
                        {chartType == 'pie' && selectedTab == 'plans' && <Grid container className="usage-details-pie-perSite starlink-usage-pie-chart" id={chartData?.length == 1 ? 'justify-content-center' : 'justify-content-start'}>
                            {chartData && chartData.map((usage, i) => (
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <CircleIcon sx={{ fontSize: 9, color: colors[i] }} />
                                    <span className="summary-usage-value">{usage.name}</span>
                                </Grid>
                            ))}
                        </Grid>}
                        {chartType == 'pie' && selectedTab == 'service_lines' && <Grid container className="usage-details-pie-perSite starlink-usage-pie-chart" id={serviceLinesData?.length == 1 ? 'justify-content-center' : 'justify-content-start'}>
                            {serviceLinesData && serviceLinesData.map((usage, i) => (
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <CircleIcon sx={{ fontSize: 9, color: colors[i] }} />
                                    <span className="summary-usage-value">{usage.name}</span>
                                </Grid>
                            ))}
                        </Grid>}
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}

const mapStateToProps = (state) => ({
    newSummaryStartDate: state?.authReducer?.newSummaryStartDate,
    newSummaryEndDate: state?.authReducer?.newSummaryEndDate,
    starlinkUsagePoolPlansChart: state.starlinkReports.starlinkUsagePoolPlansChart,
    starlinkUsagePoolServiceLinesChart: state.starlinkReports.starlinkUsagePoolServiceLinesChart
});

export default withRouter(
    connect(mapStateToProps, { getStarlinkUsagePoolPlansChart, getStarlinkUsagePoolServiceLinesChart })(PieChart)
);