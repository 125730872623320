import React, { useState, useEffect, useRef } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { Typography, Divider, Button, Select, MenuItem, IconButton, Tooltip, Autocomplete, TextField } from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import moment from "moment";
import "./TopologyNetworkOrg.css";
import EdgeLinkIcon from "../../asset/image/TopologyLink.svg"
import TopologyIc from "../../asset/image/TopologyIc.svg"
import AnalyticsIC from "../../asset/image/analytics.svg"
import EdgeLinkIconDisabled from "../../asset/image/linkDisabled.svg"
import { convertDateTimeIntoTimezone, getDataFromLocalStorageParsed, getDecodeURI, getEncodedURI, readableBytesAsGB, redirectToTopolgyTabs } from "../../utils/util";
import ImageWithFallback from "../../components/ImageWithFallback";
import { getTopologyDeviceInfo, resetTopologyDeviceInfo } from "../../actions/Users/authenticateService";
import { MMDDYYYYHMMSS_DATE_FORMAT_24_HRS } from "../../utils/constants";
import { clearData, getGeneratedLinks } from "../../components/RemoteConnections/remoteConnectionSlice";
import _ from "lodash";
import { displayToastError } from "../../server/request";
import CircularProgress from "@mui/material/CircularProgress";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';


const HardcodedOverlay = (props: any) => {
    const { location, selectedTopoDetails, selectedNameList, open, setOpen, getTopologyDeviceInfo, selectedSiteId, setSelectedSiteId, networkTopologyDeviceInfo, authReducer, resetTopologyDeviceInfo, getGeneratedLinks, isSite, networkTopologySiteSetailsLoading, networkTopologyDeviceInfoLoading } = props;
    const dispatch = useDispatch();
    console.log("networkTopologyDeviceInfohere", selectedTopoDetails)
    const [selectedIdDetails, setSelectedIDDetails] = useState<any>({})
    const [selectedTerminalOrDev, setSelectedTerminalOrDev] = useState("");
    const [devicesOrterminals, setDevicesOrTerminals] = useState<any[]>([])
    const [isOverflowing, setIsOverflowing] = useState(false);

    useEffect(() => {
        console.log("networkTopologyDeviceInfo", networkTopologyDeviceInfo)
        if (!networkTopologyDeviceInfo?.data) {
            console.log("setting Empty")
            setSelectedTerminalOrDev(""); // Reset dropdown selection
        }
    }, [networkTopologyDeviceInfo]);

    useEffect(() => {
        console.log("networkTopologySiteSetails", networkTopologyDeviceInfo)
        let tableData = networkTopologyDeviceInfo?.data?.rows.map((item) => {
            return networkTopologyDeviceInfo?.data?.columns.reduce((vsd, columnitem, i) => {
                vsd[columnitem] = item[i]
                return vsd;
            }, {})
        })
        if (tableData && tableData.length > 0) {
            setSelectedIDDetails(tableData[0])
        } else {
            setSelectedIDDetails({})
        }
    }, [networkTopologyDeviceInfo])


    console.log("selectedIdDetails", selectedIdDetails)


    // let devices: any = []
    // if (selectedTopoDetails && selectedTopoDetails?.device_list) {
    //     devices = selectedTopoDetails?.device_list && selectedTopoDetails?.device_list?.split(",").map((item) => {
    //         const [key, value] = item?.split("=");
    //         return { key, value };
    //     });
    // }

    useEffect(() => {
        let devices: any = [];
        if (selectedTopoDetails?.device_list) {
            devices = selectedTopoDetails.device_list.split(",").map((item) => {
                const [key, value] = item.split("=");
                return { key, value };
            });
        } else if (selectedTopoDetails?.userTerminalDetails) {
            devices = selectedTopoDetails.userTerminalDetails.split(",").map((item) => {
                const [key, value] = item.split("=");
                return { key, value };
            });
        }
        setDevicesOrTerminals(devices)
        // Auto-select the only device if there's exactly one
        if (devices.length === 1) {
            console.log("devices[0].key", devices[0].key)
            setSelectedTerminalOrDev(devices[0].key);
        }
    }, [selectedTopoDetails]);


    const handleDeviceChange = (e) => {
        console.log("value", e?.target?.value)
        if (e?.target?.value) {
            // resetTopologyDeviceInfo()
            setSelectedTerminalOrDev(e?.target?.value)
        }
    }

    // const hasSetInitialDevice = useRef(false);

    // useEffect(() => {
    //     if (open && devices.length > 0 && !hasSetInitialDevice.current) {
    //         const firstDeviceKey = devices[0].key;
    //         setSelectedTerminalOrDev(firstDeviceKey);
    //         hasSetInitialDevice.current = true; // Ensures this runs only once
    //     }
    // }, [open, devices]);

    // useEffect(() => {
    //     if (selectedTerminalOrDev && selectedSiteId) {
    //         getTopologyDeviceInfo(selectedSiteId, selectedTerminalOrDev, isSite);
    //     }
    // }, [selectedTerminalOrDev, selectedSiteId]);

    useEffect(() => {
        if (selectedTerminalOrDev && selectedSiteId && isSite) {
            getTopologyDeviceInfo(selectedSiteId, selectedTerminalOrDev, isSite)
        }
    }, [selectedTerminalOrDev, selectedSiteId, isSite])

    function GetTopAppsImage(props) {
        console.log("each props", props)
        const { app } = props;
        if (app) {
            console.log("GETTING INSIDE")
            let appName = app.includes(' ') ? app.split(' ')[0] : app.includes('-') ? app.split('-')[0] : "";
            return <ImageWithFallback src={`https://cdn.k4mobility.com/cdn/sportAssets/app_icons/${app.trim()}`} fallbackSrcs={[`https://cdn.k4mobility.com/cdn/sportAssets/app_icons/${appName.trim()}`, "https://cdn.k4mobility.com/cdn/sportAssets/fallbackImg.png"]} app={app} />
        } else {

            return <img className="logo-activity paddingLogo" src={`https://cdn.k4mobility.com/cdn/sportAssets/fallbackImg.png`} />
        }

    }

    console.log("selectedTerminalOrDev out", selectedTerminalOrDev)
    const handlePopupClose = () => {
        setOpen(false)
        resetTopologyDeviceInfo()
        setSelectedSiteId(); 
    }

    const EDGE_PORTAL_ADDRESS = "127.0.0.1"
    const EDGE_PORTAL_PORT = 80
    const MAX_WAIT_TIME = 20

    const onConnectToEdge = async (e, deviceId: any) => {
        e.preventDefault()
        let resp: any = await getGeneratedLinks(selectedSiteId, deviceId, { address: [EDGE_PORTAL_ADDRESS] })
        if (!_.isEmpty(resp?.result)) {
            let [edgeLink] = resp.result?.filter(rc => {
                return rc?.address === EDGE_PORTAL_ADDRESS && rc.port === EDGE_PORTAL_PORT && rc.alias.includes("Portal")
            });
            if (edgeLink && edgeLink.url) {
                let url = edgeLink.url;
                const loggedInUser = getDataFromLocalStorageParsed("loggedInUser");
                let edgeToken = loggedInUser?.data?.edge_token;
                if (!_.isEmpty(edgeToken)) {
                    let objUrl = new URL(`/login?token=${edgeToken}`, `${url}`)
                    url = objUrl?.href
                }
                window.open(url)
                clearData()
            }
        } else {
            displayToastError('No remote connection link found')
        }
    }

    const handleInsightsClick = (k4id, siteName) => {
        console.log("insight click ", k4id, siteName)
        // const newTabURL = `/fleet-dashboard/sites/SDWAN?k4Ids=?k4Ids=${encodeURIComponent(k4id+":"+siteName)}`;
        // console.log("newTabURL",newTabURL)
        // window.open(newTabURL, "_blank");


    };

    const textRef = useRef<HTMLDivElement | null>(null);
    useEffect(() => {
        const checkOverflow = () => {
            if (textRef.current) {
                setIsOverflowing(textRef.current.scrollWidth > textRef.current.clientWidth);
            }
        };

        checkOverflow();
        window.addEventListener("resize", checkOverflow);
        return () => window.removeEventListener("resize", checkOverflow);
    }, [selectedNameList]);
    return open ? (

        <div className="overlay-container overlay-topology topologyPopupPosition">
            { (networkTopologySiteSetailsLoading || networkTopologyDeviceInfoLoading) ? <div className="cancel-button colorblackOverride"> <CircularProgress size={12}/> </div> :
            <button
                className="cancel-button colorblackOverride"
                onClick={() => handlePopupClose()}
            >
                ✕
            </button>}

            {isSite == "SITE" ? <>
                <Typography ref={textRef} variant="h6" className={`titleTypo colorblackOverride topologuPopupHeader topologyElipse ${isOverflowing ? "overflowing" : ""}`}>
                    {selectedNameList ? selectedNameList.split(", ")[0] : "Hirarchy"}
                </Typography>
                <Typography className={`titleTypo colorblackOverride topologuPopupSecondHeader topologyElipse ${isOverflowing ? "overflowing" : ""}`}>{selectedNameList ? selectedNameList.split(", ")[1] : "Hirarchy"}</Typography>
                <Divider style={{ backgroundColor: "#ccc", height: "1px", width: "100%" }} />
                <Typography variant="h6" className="titleTypo colorblackOverride topologyTopAppsMT">
                    <div className="topologyTopApps">
                        {selectedTopoDetails?.apps?.split(',').map((app, index) => (
                            <GetTopAppsImage key={index} app={app} />
                        ))}
                    </div>
                </Typography>
                {/* <Typography variant="h6" className="titleTypo colorblackOverride">
                    Plan: Premium (75.3%)
                </Typography> */}
                <div className="usage-container colorblackOverride topologyItemsWidth">
                    <div className="usage-header colorblackOverride mb5px">
                        <span className="boldText">Usage</span>
                        <span className="usage-value colorblackOverride boldText">{readableBytesAsGB(selectedTopoDetails?.total_usage, true)}</span>
                    </div>
                    {selectedTopoDetails?.wan_usage?.split("|").map((item, index) => {
                        const [key, value] = item.split("=");
                        return (
                            <div key={index} className="usage-item left10 mb5px">
                                <span className="usage-label colorblackOverride">
                                    {key.charAt(0).toUpperCase() + key.slice(1)}
                                </span>
                                <span className="usage-amount colorblackOverride">
                                    {readableBytesAsGB(value)}
                                </span>
                            </div>
                        );
                    })}

                    <div className="usage-header colorblackOverride mt5px mb5px">
                        <span>Quota</span>
                        <span className="terminal-value colorBlue">{selectedTopoDetails?.current_quota?.split(",").map(value => parseFloat(value).toFixed(2)).join(",")}</span>
                    </div>
                    <div className="usage-header colorblackOverride mb5px">
                        <span>Alerts</span>
                        <span className="terminal-value colorBlue">{selectedTopoDetails?.total_alerts}</span>
                    </div>
                    <div className="usage-header colorblackOverride mb5px">
                        <span>Availability</span>
                        <span className="terminal-value colorblackOverride">{selectedTopoDetails?.availibility} %</span>
                    </div>
                    <div className="usage-header colorblackOverride mb5px">
                        <span>Internet Status</span>
                        <span style={{display:"flex", alignItems:"center"}}>
                        <span id={selectedTopoDetails?.Internet_status==="Online" ? "smallGreenDot" : "smallRedDot"}></span>
                        <span className="terminal-value colorBlue">{selectedTopoDetails?.Internet_status}</span>
                        </span> 
                    </div>
                    
                </div>
            </> :
                isSite == "STARLINK" ? <>
                    <Typography ref={textRef} variant="h6" className={`titleTypo colorblackOverride topologuPopupHeader topologyElipse ${isOverflowing ? "overflowing" : ""}`}>
                        {selectedNameList ? selectedNameList.split(", ")[0] : "Hirarchy"}
                    </Typography>
                    <Typography className={`titleTypo colorblackOverride topologuPopupSecondHeader topologyElipse ${isOverflowing ? "overflowing" : ""}`}>{selectedNameList ? selectedNameList.split(", ")[1] : "Hirarchy"}</Typography>
                    <Divider style={{ backgroundColor: "#ccc", height: "1px", width: "100%" }} />
                    <Typography variant="h6" className="titleTypo colorblackOverride">
                        {`SL Number - ${selectedTopoDetails?.serviceLineNumber}`}
                    </Typography>
                    <Typography variant="h6" className={`titleTypo colorblackOverride topologyElipse`}>
                        {`Plan - ${selectedTopoDetails?.servicePlan}`}
                    </Typography>
                    <div className="usage-header colorblackOverride mb5px topologyDetailsMaxwidth">
                        <span className="boldText">Usage</span>
                        <span className="usage-value colorblackOverride boldText">{`${selectedTopoDetails?.allTypeUsageGB} GB`}</span>
                    </div>

                    {selectedTopoDetails?.usageSplit?.split("|").map((item, index) => {
                        const [key, value] = item.split("=");
                        return (
                            <div key={index} className="usage-item left10 mb5px topologyDetailsMaxwidth330">
                                <span className="usage-label colorblackOverride">
                                    {key.charAt(0).toUpperCase() + key.slice(1)}
                                </span>
                                <span className="usage-amount colorblackOverride">
                                    {value} GB
                                </span>
                            </div>
                        );
                    })}
           
                </> :
                    <>
                        {/* <Tooltip title={<span>{selectedNameList ? selectedNameList.split(", ").reverse().join(" / ") : "Hirarchy"}</span>}> */}
                        <Typography ref={textRef} variant="h6" className={`titleTypo colorblackOverride topologuPopupHeader topologyElipse ${isOverflowing ? "overflowing" : ""}`}>
                            {selectedNameList ? selectedNameList.split(", ")[0] : "Hirarchy"}
                        </Typography>
                        {/* </Tooltip> */}
                        <Typography className={`titleTypo colorblackOverride topologuPopupSecondHeader topologyElipse ${isOverflowing ? "overflowing" : ""}`}>{selectedNameList ? selectedNameList.split(", ")[1] : "Hirarchy"}</Typography>
                        <Divider style={{ backgroundColor: "#ccc", height: "1px", width: "100%" }} />
                        <div className="terminal-container">
                        <div className="usage-header colorblackOverride mb5px">
                            <span className="boldText">Usage</span>
                            <span className="usage-value colorblackOverride boldText">{readableBytesAsGB(selectedTopoDetails?.total_usage)}</span>
                        </div>
                        {selectedTopoDetails?.wan_usage_split?.split("|").map((item, index) => {
                            const [key, value] = item.split("=");
                            return (
                                <div key={index} className="usage-item left10 mb5px">
                                    <span className="usage-label colorblackOverride">
                                        {key.charAt(0).toUpperCase() + key.slice(1)}
                                    </span>
                                    <span className="usage-amount colorblackOverride">
                                        {readableBytesAsGB(value)}
                                    </span>
                                </div>
                            );
                        })}
                        <div className="usage-header colorblackOverride mb5px">
                            <span className="boldText">Alerts</span>
                            <span className="usage-value boldText colorBlue">{selectedTopoDetails?.total_alerting}</span>
                        </div>
                        <div className="usage-header colorblackOverride mb5px">
                            <span className="boldText">Availability</span>
                            <span className="usage-value colorblackOverride boldText">{selectedTopoDetails?.total_availibility}</span>
                        </div>
                        <Typography variant="h6" className="titleTypo colorblackOverride topologuPopupHeader">
                             Organization Summary
                        </Typography>
                        <Divider style={{ backgroundColor: "#ccc", height: "1px", width: "100%" }} />
                        <div className="usage-header colorblackOverride mb5px">
                            <span className="boldText">Devices</span>
                            <span className="usage-value colorblackOverride boldText">{selectedTopoDetails?.online_devices} / {selectedTopoDetails?.total_devices}</span>
                        </div>
                        <div className="usage-header colorblackOverride mb5px">
                            <span className="boldText">Active Liscenses</span>
                            <span className="usage-value colorblackOverride boldText">{selectedTopoDetails?.active_license}</span>
                        </div>
                        {selectedTopoDetails?.licese_distribution?.split("|").map((item, index) => {
                            const [key, value] = item.split("=");
                            return (
                                <div key={index} className="usage-item left10 mb5px">
                                    <span className="usage-label colorblackOverride">
                                        {key.charAt(0).toUpperCase() + key.slice(1)}
                                    </span>
                                    <span className="usage-amount colorblackOverride">
                                        {value}
                                    </span>
                                </div>
                            );
                        })}
                    </div>
                    </>

            }

            <div className="terminal-container colorblackOverride">
                {(isSite == "SITE" || isSite == "STARLINK") && 
                <div>
                <div className="terminal-item colorblackOverride">
                    <Select
                        fullWidth
                        size="small"
                        value={selectedTerminalOrDev}
                        className="strMaps-custom-select strMap-sl-dropdown topologyPopUpSelect selectIcon"
                        onChange={handleDeviceChange}
                        displayEmpty
                        variant="standard" // Removes the default box
                        disableUnderline // Removes underline
                        IconComponent={(props) => <ArrowDropDownIcon {...props} sx={{ color: "#D9D9D9" }} />}
                    >
                        <MenuItem value="" disabled>Select a Device</MenuItem>
                        {devicesOrterminals.map(({ key, value }) => (
                            <MenuItem key={key} value={key}>{value}</MenuItem>
                        ))}
                    </Select>
                </div>
                <div>
                    <Divider style={{ backgroundColor: "#ccc", height: "1px", width: "100%" }} />
                </div>
                </div>}
                {isSite == "SITE" ? <>
                    <div className="terminal-item colorblackOverride mb5px">
                        <span className="terminal-label colorblackOverride">DID</span>
                        <span className="terminal-value colorblackOverride">{selectedIdDetails?.deviceId ? selectedIdDetails?.deviceId : ""}</span>
                    </div>
                    <div className="terminal-item colorblackOverride mb5px">
                        <span className="terminal-label colorblackOverride">Software Version</span>
                        <span className="terminal-value colorblackOverride">{selectedIdDetails?.software_version ? selectedIdDetails?.software_version.split("~")[0] : ""}</span>
                    </div>
                    <div className="terminal-item colorblackOverride mb5px">
                        <span className="terminal-label colorblackOverride">Licence Type</span>
                        <span className="terminal-value colorblackOverride">{selectedIdDetails?.licence_type ? selectedIdDetails?.licence_type : ""}</span>
                    </div>

                    <div className="terminal-item mb5px">
                        <span className="terminal-label">Last Online</span>
                        <span className="terminal-value colorblackOverride">{selectedIdDetails?.last_online ? convertDateTimeIntoTimezone(selectedIdDetails?.last_online, authReducer.userTimezone, MMDDYYYYHMMSS_DATE_FORMAT_24_HRS) : ""}</span>
                    </div>
                    <div className="usage-header colorblackOverride mb5px">
                        <span>Coordinates</span>
                        <span className="terminal-value colorBlue">{selectedTopoDetails?.cordinates?.split(",")
                            .map(num => Number(parseFloat(num).toFixed(2)))
                            .join(", ")}
                        </span>
                    </div>
                </> :
                    isSite == "STARLINK" ?
                        <>
                            <div className="terminal-item colorblackOverride mb5px topologyKeyElipse">
                                <span className="terminal-label colorblackOverride">Terminal ID</span>
                                <span className="terminal-value colorblackOverride">{selectedTerminalOrDev ? selectedTerminalOrDev : ""}</span>
                            </div>
                            <div className="terminal-item colorblackOverride mb5px">
                                <span className="terminal-label colorblackOverride">Obstruction</span>
                                <span className="terminal-value colorblackOverride">{selectedIdDetails?.obstruction_percent_time ? `${selectedIdDetails?.obstruction_percent_time.toFixed(2)} %` : ""}</span>
                            </div>
                            <div className="terminal-item colorblackOverride mb5px">
                                <span className="terminal-label colorblackOverride">Downlink</span>
                                <span className="terminal-value colorblackOverride">{selectedIdDetails?.downlink_throughput ? `${selectedIdDetails?.downlink_throughput.toFixed(2)} Mbps` : ""}</span>
                            </div>
                            <div className="terminal-item colorblackOverride mb5px">
                                <span className="terminal-label colorblackOverride">UpLink</span>
                                <span className="terminal-value colorblackOverride">{selectedIdDetails?.uplink_throughput ? `${selectedIdDetails?.uplink_throughput.toFixed(2)} Mbps` : ""}</span>
                            </div>
                            <div className="terminal-item colorblackOverride mb5px">
                                <span className="terminal-label colorblackOverride">Signal Quality</span>
                                <span className="terminal-value colorblackOverride">{selectedIdDetails?.signal_quality ? `${selectedIdDetails?.signal_quality.toFixed(2)} %` : ""}</span>
                            </div>
                            <div className="terminal-item colorblackOverride mb5px">
                                <span className="terminal-label colorblackOverride">Ping Drop Rate</span>
                                <span className="terminal-value colorblackOverride">{selectedIdDetails?.ping_drop_rate_avg ? `${selectedIdDetails?.ping_drop_rate_avg.toFixed(2)} %` : ""}</span>
                            </div>
                            <div className="terminal-item colorblackOverride mb5px">
                                <span className="terminal-label colorblackOverride">Latency</span>
                                <span className="terminal-value colorblackOverride">{selectedIdDetails?.ping_latency_ms_avg ? `${selectedIdDetails?.ping_latency_ms_avg.toFixed(2)} ms` : ""}</span>
                            </div>
                            <div className="terminal-item colorblackOverride mb5px">
                                <span className="terminal-label colorblackOverride">Uptime</span>
                                <span className="terminal-value colorblackOverride">{selectedIdDetails?.uptime ? selectedIdDetails?.uptime.toFixed(2) : ""}</span>
                            </div>

                            <div className="terminal-item mb5px">
                                <span className="terminal-label">Last Online</span>
                                <span className="terminal-value colorblackOverride">{selectedIdDetails?.last_online ? convertDateTimeIntoTimezone(selectedIdDetails?.last_online, authReducer.userTimezone, MMDDYYYYHMMSS_DATE_FORMAT_24_HRS) : ""}</span>
                            </div>
                            {/* <div className="terminal-item colorblackOverride mb5px">
                    <span className="terminal-label colorblackOverride">Cordinates</span>
                    <span className="terminal-value colorblackOverride">{selectedIdDetails?.licence_type ? selectedIdDetails?.licence_type : ""}</span>
                </div> */}
                        </>
                        :
                        <>
                        </>
                }


                {/* <div className="terminal-item mb5px">
                    <span className="terminal-label">Cordinates</span>
                    <span className="terminal-value">24.E 7 N 25 S</span>
                </div> */}
                {/* <div className="terminal-item mb5px">
                    <span className="terminal-label">WAN Status</span>
                    <span className="terminal-value">Images here</span>
                </div> */}

            </div>
            <div className="terminal-container">
                <Divider style={{ backgroundColor: "#ccc", height: "1px", width: "100%" }} />
            </div>
            <div className="d-flex str-justify str-mt-0">
                {selectedIdDetails.konnect_status === "STATUS_CONNECTED"  && isSite !== "ORG "?

                    <Button size="small" variant="contained" onClick={(e) => onConnectToEdge(e, selectedIdDetails.deviceId)} className='starlink--button_contained--primary' title="remote connection">
                        <IconButton className="strMap-SettingsIcon" size='small'>
                            <img src={EdgeLinkIcon} />
                        </IconButton>
                    </Button>
                    : selectedIdDetails.konnect_status === "STATUS_NOT_FOUND" ?

                        <Button size="small" variant="contained" className='starlink--button_contained--primary' title="portal">
                            <IconButton className="strMap-SettingsIcon" size='small'>
                                <img src={EdgeLinkIcon} />
                            </IconButton>
                        </Button>

                        : selectedIdDetails?.konnect_status ? "-" :

                            <Button size="small" variant="contained" className='starlink--button_contained--primary' title="portal">
                                <IconButton className="strMap-SettingsIcon" size='small'>
                                    <img src={EdgeLinkIconDisabled} />
                                </IconButton>
                            </Button>

                }

                <Link to={redirectToTopolgyTabs(location, "/fleet-dashboard/sites/topology", selectedSiteId, selectedTopoDetails?.sitename, {}, true, false, null)} style={{ textDecoration: "none" }} title="topology">
                    <Button size="small" variant="contained" className='starlink--button_contained--primary'>
                        <IconButton className="strMap-SettingsIcon" size='small'>
                            <img src={TopologyIc} />
                        </IconButton>
                    </Button>
                </Link>



                {/* <Button size="small" variant="contained" className='starlink--button_contained--primary'>Site</Button> */}

                <Link to={redirectToTopolgyTabs(location, "/fleet-dashboard/sites/SDWAN", selectedSiteId, selectedTopoDetails?.sitename, {}, true, false)} style={{ textDecoration: "none" }} title="sdwan">
                    <Button onClick={() => handleInsightsClick(selectedSiteId, selectedTopoDetails?.sitename)} size="small" variant="contained" className='starlink--button_contained--primary'>
                        <IconButton className="strMap-SettingsIcon analyticsIc" size='small'>
                            <img src={AnalyticsIC} />
                        </IconButton>
                    </Button>
                </Link>


                {/* <Button size="small" variant="contained" className='starlink--button_contained--primary'>
                    <IconButton className="strMap-SettingsIcon" size='small'>
                        <SettingsIcon />
                    </IconButton>
                </Button> */}
            </div>
        </div>

    ) : null
};

const mapStateToProps = (state) => ({
    authReducer: state?.authReducer,
    selectedOu: state.authReducer?.selectedOu,
    networkTopologyDeviceInfo: state?.authReducer?.networkTopologyDeviceInfo,
    networkTopologySiteSetailsLoading: state?.authReducer?.networkTopologySiteSetailsLoading,
    networkTopologyDeviceInfoLoading: state?.authReducer?.networkTopologyDeviceInfoLoading
});

export default withRouter(
    connect(mapStateToProps, { getTopologyDeviceInfo, resetTopologyDeviceInfo, getGeneratedLinks })(HardcodedOverlay)
);