import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Autocomplete, Button, DialogActions, Divider, Grid, TextField, DialogContent, Switch, Checkbox } from "@mui/material";
import { useEffect, useState } from "react";
import _ from "lodash";
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { CONFIRM_DIALOG_TITLE, SERVICE_FUNCTION_TO_FEATURE } from "../../../config";
import ConfirmDialog from "../../ConfirmDialog";
import { backupConfigNow, applyGoldenConfig, backupConfigData, getLicenseTypes, upgradeDevicetoLatestEOSversion, rebootDevice, upgradeInventoryLicense } from "../../../actions/Users/authenticateInventory";
import ConfigDropDownWithDelete from "../ConfigDropDown/ConfigDropDownWithDelete";
import SelectTag from "../../InventoryWarehouseTable/SelectTag";
import { checkLoggedInUserAuthorizedToViewPage, convertDateTimeIntoTimezone } from "../../../utils/util";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import InfoIcon from '@mui/icons-material/Info';
import { MMDDYYYYHMMSS_DATE_FORMAT_24_HRS } from "../../../utils/constants";
import { compare } from "compare-versions";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import AgentWanBalancerActions from "./AgentWanBalancerActions";
import { createAgentWanProfile, getAgentWanProfiles, getAgentWans, updateAgentWanProfile, deleteAgentWanProfile } from "../../../actions/Users/authenticateHub";
import { CREATE_AGENT_WAN_PROFILE, DELETE_AGENT_WAN_PROFILE, UPDATE_AGENT_WAN_PROFILE } from "../../../actions/types";
import SelectConfigDropdown from "../SelectConfigDropdown";
import "./AgentWanBalancer.css"

export const VlanPopup = (props) => {
    const dispatch = useDispatch();
    const { authReducer, createAgentWanProfile, getAgentWanProfiles, getAgentWans, setIsActionPopup, updateAgentWanProfile, deleteAgentWanProfile, selectedDp } = props;

    const [wanProfileLevels, setWanProfileLevels] = useState<any[]>([])
    const [confirmDialog, setConfirmDialog] = useState({
        isOpen: false,
        title: "",
        subTitle: "",
        onConfirm: {},
    });

    const [name, setName] = useState("")
    const [serviceFollowClient, setServiceFollowClient] = useState(false)

    const [vlanNames, setVlanNames] = useState<any>([])
    const [checkInputValue, setCheckInputValue] = useState(false)
    const [isCreate, setIsCreate] = useState(false)
    const [selectedValue, setSelectedValue] = useState<any>(null)

    const [wans, setWans] = useState<any>([])
    const [wansDropDownList, setWansDropDownList] = useState<string[]>([])
    const [wanProfileName, setWanProfileName] = useState<any>({})


    const [wanProfiles, setWanProfiles] = useState<any>([])

    const [wanNamesList, setWanNamesList] = useState(["Narrow Body wan profile 1", "Narrow Body wan profile 2"])

    useEffect(() => {
        if (wanProfileLevels?.length) {
            handleUpdateWanProfileLevels()
        }
    }, [wanProfileLevels])

    useEffect(() => {
        if (selectedDp && selectedDp?.id) {
            setWanProfiles([])
            setWans([])
            setSelectedValue(null)
            getAgentWanProfiles(selectedDp?.id)
            getAgentWans(selectedDp?.id)
        }
    }, [selectedDp])

    useEffect(() => {
        if (!_.isEmpty(authReducer?.getAgentWans) && !authReducer?.getAgentWansLoading) {
            if (authReducer?.getAgentWans?.data) {
                let allNames = authReducer?.getAgentWans?.data?.flatMap(item =>
                    item.wans.map(wan => wan.name)
                );

                let uniqueWans: string[] = [];
                if (allNames) {
                    for (let name of allNames) {
                        if (uniqueWans.indexOf(name) === -1) {
                            uniqueWans.push(name);
                        }
                    }
                }
                setWans(uniqueWans)
                setWansDropDownList(uniqueWans)
            }
        }
    }, [authReducer?.getAgentWans])

    useEffect(() => {
        if (!_.isEmpty(authReducer?.getAgentWanProfiles) && !authReducer?.getAgentWanProfilesLoading) {
            setWanProfiles(authReducer?.getAgentWanProfiles?.data || [])
        }
    }, [authReducer?.getAgentWanProfiles])

    useEffect(() => {
        let dropDownList: any = []
        if (wanProfiles?.length) {
            for (let item of wanProfiles) {
                dropDownList.push({
                    label: item?.name,
                    id: item?.id
                })
            }
        }

        setVlanNames(dropDownList)
    }, [wanProfiles])

    useEffect(() => {
        if (selectedValue && selectedValue?.id) {
            for (let item of wanProfiles) {
                if (item?.id === selectedValue?.id) {
                    let levels: any = []
                    if (item?.client_config?.levels?.length) {
                        let existingLevels = item?.client_config?.levels
                        for (let index in existingLevels) {
                            let item = existingLevels[index]
                            levels.push({
                                id: index,
                                names: item?.names,
                                level: item?.level,
                                balancer_type: item?.balancer_type,
                                weightage: item?.balancer_type == "static" ? item?.static?.wans : {} 
                            })
                        }
                    }
                    setWanProfileLevels(levels)
                    break
                }
            }
        } else if (selectedValue && selectedValue?.label && !selectedValue.id) {
            const newAction = {
                id: wanProfileLevels?.length,
                names: [],
                level: wanProfileLevels?.length + 1,
                balancer_type: "not_applicable",
                weightage: {}
            };
            setWanProfileLevels([newAction])
        }
        else {
            setWanProfileLevels([])
        }
    }, [selectedValue])

    useEffect(() => {
        if (!_.isEmpty(authReducer?.createAgentWanProfile) && !authReducer?.createAgentWanProfileLoading) {
            getAgentWanProfiles(selectedDp?.id)
            toast.success("Wan balancer has been created successfully", { position: toast.POSITION.BOTTOM_LEFT })
            dispatch({ type: CREATE_AGENT_WAN_PROFILE, payload: {} })
            getAgentWanProfiles(selectedDp?.id)

            handleClear()
        };
    }, [authReducer?.createAgentWanProfileLoading, authReducer?.createAgentWanProfile])

    useEffect(() => {
        if (!_.isEmpty(authReducer?.updateAgentWanProfile) && !authReducer?.updateAgentWanProfileLoading) {
            getAgentWanProfiles(selectedDp?.id)
            toast.success("Wan balancer has been updated successfully", { position: toast.POSITION.BOTTOM_LEFT })
            dispatch({ type: UPDATE_AGENT_WAN_PROFILE, payload: {} })

            handleClear()
            // setIsActionPopup(false)
        };
    }, [authReducer?.updateAgentWanProfileLoading, authReducer?.updateAgentWanProfile])

    useEffect(() => {
        if (!_.isEmpty(authReducer?.deleteAgentWanProfile) && !authReducer?.deleteAgentWanProfileLoading) {
            getAgentWanProfiles(selectedDp?.id)
            toast.success("Wan balancer has been deleted successfully", { position: toast.POSITION.BOTTOM_LEFT })
            dispatch({ type: DELETE_AGENT_WAN_PROFILE, payload: {} })

            handleClear()
            // setIsActionPopup(false)
        };
    }, [authReducer?.deleteAgentWanProfileLoading, authReducer?.deleteAgentWanProfile])

    const handleUpdateWanProfileLevels = () => {
        let existingWans: string[] = []
        for (let item of wanProfileLevels) {
            existingWans = existingWans.concat(item?.names)
        }
        let newWans = wans.filter(wan => !existingWans.includes(wan));
        setWansDropDownList(newWans)
    }

    const handleSave = () => {
        let levels: any = []
        for (let item of wanProfileLevels) {
            let balancerType = item?.balancer_type
            let weightage = {}
            if (balancerType == "static") {
                weightage["static"] = {
                    wans: item?.weightage
                }
            } else {
                balancerType = "dynamic"
                weightage["dynamic"] = { mode: "auto" }
            }
            let levelItem: any = {
                level: item?.level,
                names: item?.names,
                balancer_type: balancerType,
                ...weightage,
            }
            levels.push(levelItem)
        }

        if (wanProfileName?.id) {
            updateAgentWanProfile(selectedDp?.id, wanProfileName?.id, {
                name: wanProfileName?.label,
                client_config: {
                    levels: levels
                }
            })
        } else {
            createAgentWanProfile(selectedDp?.id, {
                name: wanProfileName?.label,
                client_config: {
                    levels: levels
                }
            })
        }
    }

    const handleClose = () => {
        setIsActionPopup(false)
    }

    const handleClear = () => {
        setWanProfileLevels([])
        setVlanNames([])
        setCheckInputValue(false)
        setIsCreate(false)
        setSelectedValue(null)
        setServiceFollowClient(false)
    }

    const handleDeleteAction = (event: any, option: any) => {
        event?.stopPropagation();
        if (option?.id) {
            deleteAgentWanProfile(selectedDp?.id, option?.id)
        }
    }


    return (
        <div className="sh--agent-wan-balancer-container">
            <div style={{ minHeight: "330px" }}>
                <Grid container className="sh--agent-wan-balancer-grid-container">
                    <Grid xs={2} sm={2} md={2} lg={3} style={{ color: "#45464E", display: "flex", alignItems: "center" }}>WAN Balancer Profile</Grid>
                    <Grid xs={4} sm={4} md={4} lg={4}>
                        <ConfigDropDownWithDelete names={vlanNames} check={setCheckInputValue} setConfigName={setWanProfileName} setIsCreate={setIsCreate} value={selectedValue} setValue={setSelectedValue} handleDeleteAction={handleDeleteAction} />
                    </Grid>
                </Grid>
                <AgentWanBalancerActions wanProfileLevels={wanProfileLevels} setWanProfileLevels={setWanProfileLevels} wansDropDownList={wansDropDownList} />
            </div>
            <div className="agent-wan-display-flex">
                <Button className="agent-wan-cancel-button" style={{ color: "#264c86" }} onClick={() => { handleClose() }}> Cancel </Button>
                <Button variant="contained" style={{ background: "#264c86" }} onClick={() => { handleSave() }}>Save</Button>
            </div>
        </div>
    )
}


const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
    errorReducer: state.errorReducer,
});

export default withRouter(
    connect(mapStateToProps, {
        createAgentWanProfile, getAgentWanProfiles, getAgentWans, updateAgentWanProfile, deleteAgentWanProfile
    })(VlanPopup)
);