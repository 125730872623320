import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Button, Dialog, DialogContent, IconButton, DialogTitle, Divider, Grid, List, ListItemButton, MenuItem, Paper, Select, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography, Autocomplete, TextField, FormControl, ListItem, ListItemText, DialogActions, Checkbox, Box, Switch, Menu, InputAdornment } from "@mui/material";
import React, { Fragment, useEffect, useMemo, useState, useRef } from "react";
import close from "../../asset/image/close.svg";
import _, { template } from "lodash";

import { Info } from "@material-ui/icons";
import moment from "moment";
import { getDataFromLocalStorage } from "../../utils/util";
import { USER_OU } from "../../constants/Constants";
import { assignCustomPlanAPI, getServiceLinePerPlan, getServiceLinePerPlanRight, moveServicePlanAPI } from "../StarlinkPooled/slice";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import customPlanIcon from "../../asset/image/CustomPlanIcon.svg";
import starlinkPlanIcon from "../../asset/image/StarlinkPlanIcon.svg";
import planFilterButton from "../../asset/image/planFilterButton.svg";
import planHierarchyImg from "../../asset/image/planHierarchyImg.svg";
import orgPoolIcon from "../../asset/image/OrgPoolIcon.svg";
import { Edit, AddCircle } from "@material-ui/icons";
import TuneIcon from "@mui/icons-material/Tune";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import authReducer from "../../reducers/authReducer";

var Loader = require("react-loader");

const AssignServiceLine = (props) => {
    const { open, setOpen, loading, planData, getServiceLinePerPlan, authReducer, slClicked, setSlClicked, serviceLinePlans, getServiceLinePerPlanRight, callPlanHierarchyAPI, serviceLinePlansRight, moveServicePlanAPI, moveServicePlan } = props;
    const searchTimeoutRef = useRef<NodeJS.Timeout>();
    const [expandedRows, setExpandedRows] = useState({});
    const [searchValue, setSearchValue] = useState("");
    const [searchValuePlanHierarchy, setSearchValuePlanHierarchy] = useState("");
    const [searchValueRight, setSearchValueRight] = useState("");
    const [selectedPlan, setSelectedPlan] = useState<any>(null);
    const [selectedPlanRight, setSelectedPlanRight] = useState<any>(null);
    const [highlightedRows, setHighlightedRows] = useState<any>([]);
    const [highlightedRowsRight, setHighlightedRowsRight] = useState<any>([]);
    const [options, setOptions] = useState<any>([]);
    const ENTER_KEY_CODE = 'Enter';
    const [moveClickedToRight, setMoveClickedToRight] = useState(false)
    const [moveClickedToMiddle, setMoveClickedToMiddle] = useState(false)
    const [servicePlansLocal, setServicePlansLocal] = useState<any>([])
    const [servicePlansRightLocal, setServicePlansRightLocal] = useState<any>([])
    const [confirmDialog, setConfirmDialog] = useState<boolean>(false)
    const [selectedBox, setSelectedBox] = useState<any>("")
    const [selectedServiceLines, setSelectedServiceLines] = useState<any>([]);
    const [customPlanId, setCustomPlanId] = useState<any>(null);
    const [customPlanIdRight, setCustomPlanIdRight] = useState<any>(null);
    const [accountNumber, setAccountNumber] = useState(null)
    const [accountNumberRight, setAccountNumberRight] = useState(null)
   
    useEffect(() => {
        if (slClicked) {
            handleRowClick(slClicked, "middle")
        }
    }, [slClicked])

    const clearData = () =>{
        setSelectedPlan(null)
        setSelectedPlanRight(null)
        setHighlightedRows([])
        setHighlightedRowsRight([])
        setMoveClickedToRight(false)
        setMoveClickedToMiddle(false)
        setServicePlansLocal([])
        setServicePlansRightLocal([])
        setSelectedBox("")
        setSlClicked(null)
    }


    useEffect(() => {
        if (!_.isEmpty(serviceLinePlans)) {
            setServicePlansLocal(serviceLinePlans)
        } else {
            setServicePlansLocal([])
        }
    }, [serviceLinePlans])

    useEffect(() => {
        if (!_.isEmpty(serviceLinePlansRight)) {
            setServicePlansRightLocal(serviceLinePlansRight)
        } else {
            setServicePlansRightLocal([])
        }
    }, [serviceLinePlansRight])

    const handleExpandClick = (id) => {
        setExpandedRows((prev) => ({
            ...prev,
            [id]: !prev[id],
        }));
    };

    const findParentPath = (data, targetUid) => {
        let path = [];

        const traverse = (items, currentPath = []) => {
            for (let item of items) {
                let newPath: any = [...currentPath, item.uid];

                if (item.uid === targetUid) {
                    path = newPath;
                    return;
                }

                if (item.children?.length > 0) {
                    traverse(item.children, newPath);
                }
            }
        };

        traverse(data);
        return path;
    };


    const handleExpandClickForPath = (uids) => {
        setExpandedRows((prev) => {
            let newExpandedRows = { ...prev };
            uids.forEach(uid => {
                newExpandedRows[uid] = true; // Expand each row in the path
            });
            return newExpandedRows;
        });
    };


    const expandRowsAboveUid = (uid) => {
        const parentPath = findParentPath(planData, uid);
        handleExpandClickForPath(parentPath);
    };

    const [hoveredRow, setHoveredRow] = useState(null)

    const getBorderStyle = (row) => {
        if (hoveredRow === row?.uid && selectedBox !== "") {
            return selectedBox === "middle"
                ? "2px solid #53E1AE"
                : selectedBox === "right"
                    ? "2px solid #8CC5F6"
                    : "";
        }
        return "";
    };

    const handleRowClick = (row, selectedBox) => {
        // setSelectedRow(row?.uid);
        const selectedObj = options.find(option => option.value === row?.uid);

        if (selectedObj && selectedBox !== "") {
            if (selectedBox === "middle") {
                setSelectedPlan(selectedObj)
                let params = {
                    dp_id: authReducer?.selectedOu?.id,
                    custom_plan_id: selectedObj?.type && selectedObj?.type === 'Main Pool' ? "" : selectedObj?.val ? selectedObj.val : "",
                    org_filter: "",
                    search: searchValue,
                    account_number: row?.account_number
                    
                }
                setCustomPlanId(selectedObj?.type && selectedObj?.type === 'Main Pool' ? "" : selectedObj?.val ? selectedObj.val : "")
                setAccountNumber(row?.account_number)
                getServiceLinePerPlan(params)
            } else {
                setSelectedPlanRight(selectedObj);
                let params = {
                    dp_id: authReducer?.selectedOu?.id,
                    custom_plan_id: selectedObj?.type && selectedObj?.type === 'Main Pool' ? "" : selectedObj?.val ? selectedObj.val : "",
                    org_filter: "",
                    search: searchValueRight,
                    account_number: row?.account_number
                }

                setCustomPlanIdRight(selectedObj?.type && selectedObj?.type === 'Main Pool' ? "" : selectedObj?.val ? selectedObj.val : "")
                setAccountNumberRight(row?.account_number)
                getServiceLinePerPlanRight(params)
            }
        }


    };

    const getBackgroundColor = (rowVal) => {
        if (selectedPlan?.value === rowVal?.uid) {
            return "#53E1AE";
        }

        if (selectedPlanRight?.value === rowVal?.uid) {
            return "#8CC5F6";
        }

    }

    const renderRow = (row, level = 0) => (
        <React.Fragment key={row.uid}>
            <TableRow className="strPlan-tableRow"
                onMouseEnter={() => { setHoveredRow(row.uid); }}
                onMouseLeave={() => setHoveredRow(null)} >
                <TableCell onClick={(event) => {  handleRowClick(row, selectedBox) }}
                    className={`starPlan-hierarchy-table-cell`}
                    style={{
                        paddingLeft: `${level * 20}px`,
                        borderTop: getBorderStyle(row),
                        borderLeft: getBorderStyle(row),
                        borderBottom: getBorderStyle(row),
                        backgroundColor: getBackgroundColor(row)
                    }}

                >
                    {row.children?.length > 0 && (
                        <IconButton size="small" className="strPlans-icon-padding" onClick={(event) => {
                            event.stopPropagation();
                            handleExpandClick(row?.uid)
                        }}>
                            {expandedRows[row.uid] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        </IconButton>
                    )}
                    <Grid className="strPlans-pln-hir-name"
                        style={{ paddingLeft: `${level * 15}px`, }}>
                        {row?.icon ? <img className="icon-cursor strPlans-pd-r-10" src={row?.icon === "CustomPlanIcon" ? customPlanIcon : row?.icon === "orgPoolIcon" ? orgPoolIcon : starlinkPlanIcon} /> : null}
                        {row?.account_type ? row?.account_type.charAt(0).toUpperCase() + row?.account_type.slice(1).toLowerCase() + " | " : ""}
                        {(row?.account_name || row?.custom_plan_name || row?.pool_name || row?.plan_name || '').slice(0, 25) + ((row?.account_name || row?.custom_plan_name || row?.pool_name || row?.plan_name || '').length > 25 ? "..." : "")}

                    </Grid>
                </TableCell>


                <TableCell align="center"
                    style={{
                        borderTop: getBorderStyle(row),
                        borderRight: getBorderStyle(row),
                        borderBottom: getBorderStyle(row),
                        backgroundColor: getBackgroundColor(row)
                    }}
                    className={(selectedPlan !== "" && selectedPlan?.value === row?.uid) ? "strPlan-color-blue strPlan-background-blue" : "strPlan-color-blue strPlan-background-transparent"}
                    onClick={() => handleRowClick(row, selectedBox)} >{row.service_lines}</TableCell>

            </TableRow>

            {expandedRows[row?.uid] && row.children?.length > 0 && (
                row.children.map((child) => renderRow(child, level + 1))
            )}
        </React.Fragment>
    );

    const handleRowSelection = (id) => {
        setSelectedServiceLines((prevSelected) =>
            prevSelected.includes(id)
                ? prevSelected.filter((rowId) => rowId !== id)
                : [...prevSelected, id]
        );
    };

    const isSelected = (id) => selectedServiceLines.includes(id);

    useEffect(() => {
        let params = {
            dp_id: authReducer?.selectedOu?.id,
            custom_plan_id: customPlanIdRight,
            org_filter: "",
            search: searchValueRight,
            account_number: accountNumberRight,
        }

        getServiceLinePerPlanRight(params)
    }, [searchValueRight])

    useEffect(() => {
        let params = {
            dp_id: authReducer?.selectedOu?.id,
            custom_plan_id: customPlanId,
            org_filter: "",
            search: searchValue,
            account_number: accountNumber,
        }

        getServiceLinePerPlan(params)
    }, [searchValue])



    const extractPlanPoolOptions = (data, options: any = [], level = 0) => {
        data?.forEach((item: any) => {
            if (item.account_type !== 'standard' && item.icon !== "starlinkPlanIcon") {
                if (item.pool_name) {
                    options.push({ value: item.uid, 
                        val: item.custom_plan_id, 
                        label: item.pool_name, 
                        type: "Pool", level, 
                        sl: item.service_lines, 
                        size: item?.unit === "tb" ? item?.size*1000 : item?.size  });
                } else if (item.custom_plan_name || item.plan_name) {
                    options.push({
                        val: item.id,
                        value: item.uid,
                        label: item.custom_plan_name || item.plan_name,
                        type: "Plan",
                        level,
                        sl: item.service_lines, 
                        size: item?.unit === "tb" ? item?.size*1000 : item?.size 
                    });
                } else if (item.account_name) {
                    options.push({ value: item.uid, val: item.uid, label: item.account_name, type: "Main Pool", level, sl: item.service_lines, 
                        size: item?.unit === "tb" ? item?.size*1000 : item?.size  });
                }

                if (item.children?.length) {
                    extractPlanPoolOptions(item.children, options, level + 1);
                }
            }
        });

        return options;
    };


    // const extractPlanPoolOptions = (data, options: any = []) => {
    //     data?.forEach((item: any) => {
    //         if (item.account_type !== 'standard') {
    //             if (item.icon !== "starlinkPlanIcon") {
    //                 if (item.pool_name) {
    //                     options.push({ value: item.uid, val: item.custom_plan_id, label: item.pool_name, type: "Pool" });
    //                 } else if (item.custom_plan_name || item.plan_name) {
    //                     options.push({
    //                         val: item.id,
    //                         value: item.uid,
    //                         label: item.custom_plan_name || item.plan_name,
    //                         type: "Plan",
    //                     });
    //                 } else if (item.account_name) {
    //                     options.push({ value: item.uid, val: item.uid, label: item.account_name, type: "Main Pool" });
    //                 }

    //                 if (item.children?.length) {
    //                     extractPlanPoolOptions(item.children, options);
    //                 }
    //             }
    //         }
    //     });

    //     return options;
    // };

    useMemo(() => {
        if (!_.isEmpty(planData)) {
            setOptions(extractPlanPoolOptions(planData))
        }
    }, [planData]);



    const handleHighlightedRowClick = (row) => {
        setHighlightedRows((prevHighlighted) => {
            const exists = prevHighlighted.some((item) => item.id === row.id);
            return exists
                ? prevHighlighted.filter((item) => item.id !== row.id)
                : [...prevHighlighted, row];
        });
    };


    const isHighlighted = (id: string) => {
        return highlightedRows.some((item) => item.id === id);
    };


    const handleHighlightedRowsClick = (row: { id: number; account_number: string }) => {
        setHighlightedRowsRight((prevHighlighted) => {
            const exists = prevHighlighted.some((item) => item.id === row.id);
            return exists
                ? prevHighlighted.filter((item) => item.id !== row.id)
                : [...prevHighlighted, row];
        });
    };

    const isHighlight = (id: number) => {
        return highlightedRowsRight.some((item) => item.id === id);
    };

    const handleAssignPlanToLeft = () => {

        if (!_.isEmpty(highlightedRowsRight)) {
            let params = {
                organizationId: authReducer?.selectedOu?.id,
                serviceLines: highlightedRowsRight?.map((item) => item.id),
                customPlanId: selectedPlan?.val,
                allowDowngradeNow: false,
                accountNumber: highlightedRowsRight[0]?.accountNumber
            }

            moveServicePlanAPI(params)
        }
    }

    useEffect(() => {
        if (!_.isEmpty(moveServicePlan)) {
            // setOpen(false)
            // setSelectedPlan(null);
            // setSelectedPlanRight(null);
            setHighlightedRows([]);
            setHighlightedRowsRight([]);

            let params = {
                dp_id: authReducer?.selectedOu?.id,
                custom_plan_id: customPlanIdRight,
                org_filter: "",
                search: searchValueRight,
                account_number: accountNumberRight
            }

            let param = {
                dp_id: authReducer?.selectedOu?.id,
                custom_plan_id: customPlanId,
                org_filter: "",
                search: searchValue,
                account_number: accountNumber
            }

            getServiceLinePerPlan(param)
            getServiceLinePerPlanRight(params)
            callPlanHierarchyAPI()

        }

    }, [moveServicePlan])

    const handleAssignPlanToRight = () => {

        if (!_.isEmpty(highlightedRows)) {
            let params = {
                organizationId: authReducer?.selectedOu?.id,
                serviceLines: highlightedRows?.map((item) => item.id),
                customPlanId: selectedPlanRight?.type === "Main Pool" ? "" : selectedPlanRight?.val,
                allowDowngradeNow: false,
                accountNumber: highlightedRows[0]?.account_number
            }

            moveServicePlanAPI(params)
            setHighlightedRows([]);
            setHighlightedRowsRight([]);
            setMoveClickedToRight(false)
            setConfirmDialog(false)

        }

        if (!_.isEmpty(highlightedRowsRight)) {
            let params = {
                organizationId: authReducer?.selectedOu?.id,
                serviceLines: highlightedRowsRight?.map((item) => item.id),
                customPlanId: selectedPlan?.type === "Main Pool" ? "" : selectedPlan?.val,
                allowDowngradeNow: false,
                accountNumber: highlightedRowsRight[0]?.account_number
            }

            moveServicePlanAPI(params)
            setHighlightedRows([]);
            setHighlightedRowsRight([]);
            setMoveClickedToRight(false)
            setConfirmDialog(false)

        }
    }

    return (
        <Dialog
            disableEnforceFocus
            open={open} onClose={() => { setOpen(false); clearData() }}
            aria-labelledby='form-dialog-title'
            fullWidth={true}
            maxWidth={'xl'}
            className='menuScroll'
        >
            <div>
                <DialogTitle
                    style={{ display: "flex", justifyContent: "space-between", borderBottom: "1px solid #efefef", padding: "5px 25px" }}
                    className='create-default-config-title edit-actions-title'>
                    <div>
                        <span>Assign Service Line</span>
                        <span className="selected-site-device starlink-admin-operations-popup-service-line-name">{` `}</span>
                    </div>
                    <div>
                        {loading ? (
                            <div className="commonLoader_NewSummary wan-profile-interfaces-loader editActions-loader">
                                <Loader radius={4} length={5} lines={10} width={2} color={"#264C86"} />
                            </div>
                        ) : null}
                        <img src={close} title="Close" onClick={() => { setOpen(false); clearData() }} />
                    </div>
                </DialogTitle>
                <DialogContent className="edit-actions-content">
                    <Grid container gap={2} paddingTop={2} >
                        <Grid item xs={4} className="middleTable">
                            <Grid container className="strPlans-senderGridTop">
                                <Grid item >
                                    <Typography className="strPlans-senderTypo">
                                        <img className="icon-cursor strPlans-pd-r-10" src={planHierarchyImg} />    Plan Hierarchy
                                    </Typography>
                                </Grid>
                            </Grid>
                            <div className="strPlans-pb10hierarchy strPlan-tableHierarchy-Height" >
                                <Grid container spacing={1} className="configTable">
                                    <Grid item xs={1.5} className="configuration-form-build">
                                        <img className="icon-cursor strPlans-pd-r-10" src={planFilterButton} />
                                    </Grid>

                                    <Grid item xs={10.5} className="usage-search-border-color">
                                        <Box className="strPlans-searchbarBox" >
                                            <TextField
                                                id="outlined-basic"
                                                variant="outlined"
                                                placeholder="Filter Search"
                                                autoComplete="off"
                                                size="small"
                                                value={searchValuePlanHierarchy}
                                                onChange={(e) => setSearchValuePlanHierarchy(e.target.value)}
                                                onKeyDown={(event) => { setSearchValuePlanHierarchy(searchValuePlanHierarchy) }}
                                                InputLabelProps={{ className: "serachLabel1" }}
                                                InputProps={{
                                                    className: "strPlans-searchbarInputs",
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            {!searchValuePlanHierarchy && <SearchIcon
                                                                className="searchbarIconsPosition" onClick={() => { setSearchValuePlanHierarchy(searchValuePlanHierarchy) }}
                                                            />}
                                                            {searchValuePlanHierarchy && <CloseIcon
                                                                className="searchbarIconsPosition"
                                                                onClick={() => { setSearchValuePlanHierarchy("") }}
                                                            />}
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        </Box>
                                    </Grid>
                                </Grid>
                                <TableContainer component={Paper} className="starlink--table_container strMap-table plan-hierarchy-height" >
                                    <Table stickyHeader size="small" aria-label="plan-hierarchy-table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>
                                                    <span className="font-wt-900">Plan</span>
                                                </TableCell>

                                                <TableCell>
                                                    <span className="font-wt-900">Service Lines</span>
                                                </TableCell>

                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {planData?.length > 0 ? (
                                                planData?.map((row) => renderRow(row))
                                            ) : (
                                                <TableRow>
                                                    <TableCell colSpan={4} align="center">
                                                        No service lines available.
                                                    </TableCell>
                                                </TableRow>
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                        </Grid>
                        <Grid item xs={3.5} className="middleTable" >
                            <Grid container className="strPlans-senderGridTop">
                                <Grid item className="strPlans-PlanIcon-grid">
                                    <div className="strPlans-PlanIconMiddle" ></div>
                                    <Typography className="strPlans-senderTypo">   {selectedPlan?.label || "Select Plan"}   </Typography>
                                </Grid>
                            </Grid>
                            <div className={`strPlans-pb10hierarchy strPlan-tableHierarchy-Height strPlans-pb10 ${selectedBox === "middle" ? "selectedMiddle" : ""}`} onClick={() => { setSelectedBox("middle") }}  >
                                {
                                    selectedPlan ?
                                        <>
                                            <Grid container spacing={1} className="configTable">
                                                <Grid item xs={1.5} className="configuration-form-build">
                                                    <img className="icon-cursor strPlans-pd-r-10" src={planFilterButton} />
                                                </Grid>

                                                <Grid item xs={10.5} className="usage-search-border-color">
                                                    <Box className="strPlans-searchbarBox">
                                                        <TextField
                                                            id="outlined-basic"
                                                            variant="outlined"
                                                            placeholder="Filter Search"
                                                            autoComplete="off"
                                                            size="small"
                                                            value={searchValue}
                                                            onChange={(e) => setSearchValue(e.target.value)}
                                                            onKeyDown={(event) => { setSearchValue(searchValue) }}
                                                            InputLabelProps={{ className: "serachLabel1" }}
                                                            InputProps={{
                                                                className: "strPlans-searchbarInputs",
                                                                startAdornment: (
                                                                    <InputAdornment position="start">
                                                                        {!searchValue && <SearchIcon
                                                                            className="searchbarIconsPosition" onClick={() => { setSearchValue(searchValue) }}
                                                                        />}
                                                                        {searchValue && <CloseIcon
                                                                            className="searchbarIconsPosition"
                                                                            onClick={() => { setSearchValue("") }}
                                                                        />}
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                        />
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                            <div className="service-lines-container">
                                                    <div className="service-lines-card">
                                                        <div className="label">Total Service Lines</div>
                                                        <div className={"highlight"}>{servicePlansLocal?.length || 0}</div>
                                                    </div>
                                                    <div className="service-lines-card">
                                                        <div className="label">Selected</div>
                                                        <div className={"highlight"}><Tooltip
                                                            classes={{ tooltip: 'starlink--tooltip' }}
                                                            title={
                                                                <div className="strPlans-tooltip-grid">
                                                                    <Table size="small">
                                                                        <TableBody>
                                                                            {highlightedRows?.map((highlight) => {
                                                                                return (<TableRow>
                                                                                    <TableCell>{highlight?.service_lines}</TableCell>
                                                                                </TableRow>)
                                                                            })}
                                                                        </TableBody>
                                                                    </Table>
                                                                </div>
                                                            }
                                                        >
                                                            <span>{highlightedRows?.length || 0}</span>
                                                        </Tooltip></div>
                                                    </div>
                                                </div>
                                            <TableContainer component={Paper} className="starlink--table_container strMap-table strPlan-tableHeight" >
                                                <Table stickyHeader size="small" aria-label="plan-hierarchy-table">
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell>
                                                                <span className="font-wt-900">Service Line</span>
                                                            </TableCell>

                                                            <TableCell>
                                                                <span className="font-wt-900">Organization</span>
                                                            </TableCell>

                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody className="strPlan-sl-table">
                                                        {servicePlansLocal?.length > 0 ? (
                                                            servicePlansLocal?.map((row: any) => (
                                                                <TableRow
                                                                    key={row.id}
                                                                    onClick={() => {
                                                                        handleHighlightedRowClick(row)
                                                                        setHighlightedRowsRight([])
                                                                    }}>
                                                                    <TableCell
                                                                        className={`strPlans-table-cell ${isHighlighted(row.id) ? 'highlighted' : ''}`}>
                                                                        {row.service_lines}
                                                                    </TableCell>
                                                                    <TableCell
                                                                        className={`strPlans-table-cell ${isHighlighted(row.id) ? 'highlighted' : ''}`}>
                                                                        {row.organization_name}
                                                                    </TableCell>
                                                                </TableRow>
                                                            ))
                                                        ) : (
                                                            <TableRow>
                                                                <TableCell colSpan={4} align="center">
                                                                    No service lines available.
                                                                </TableCell>
                                                            </TableRow>
                                                        )}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                            <Grid container spacing={2} className="strPlans-senderGrid">

                                                <Grid item >
                                                    <Button  sx={{ textTransform: 'none' }} variant="outlined" className='strPlans-cancel'
                                                        onClick={() => { setHighlightedRows([]) }} >  Clear</Button>
                                                </Grid>
                                                <Grid item >
                                                    <Button  sx={{ textTransform: 'none' }} variant="outlined" className='strPlans-selectAll'
                                                        onClick={() => { setHighlightedRows([...servicePlansLocal]) }} >  Select All </Button>

                                                </Grid>
                                            </Grid>
                                        </> : <div className="strPlan-selectPool">Select a pool to view its service plans. Use the navigation buttons to move.</div>
                                }
                            </div>
                        </Grid>

                        <Grid item xs={0.5} className="strPlans-arrowBtn-grid">
                            <div className="strPlans-arrowBtn-div">
                                <IconButton size="small" className="strPlan-arrowBtn"
                                    style={{ color: moveClickedToRight ? "blue" : "" }}
                                    disabled={selectedPlan === null || highlightedRows.length === 0 || (selectedPlan === selectedPlanRight)}
                                    onClick={() => {
                                        setMoveClickedToRight(!moveClickedToRight)
                                        setServicePlansRightLocal((prev) => [
                                            ...highlightedRows.filter(
                                                (row) => !prev.some((item) => item.id === row.id)
                                            ),
                                            ...prev,
                                        ]);
                                    }}>
                                    <KeyboardArrowRightIcon />
                                </IconButton>

                                <IconButton size="small" className="strPlan-arrowBtn"
                                    disabled={selectedPlan === null || selectedPlanRight === null || highlightedRowsRight.length === 0 || (selectedPlan === selectedPlanRight)}
                                    style={{ marginBottom: "10px", color: moveClickedToMiddle ? "blue" : "" }}
                                    onClick={() => {
                                        setMoveClickedToMiddle(!moveClickedToMiddle)
                                        setServicePlansLocal((prev) => [
                                            ...highlightedRowsRight.filter(
                                                (row) => !prev.some((item) => item.id === row.id)
                                            ),
                                            ...prev,
                                        ]);
                                    }}>
                                    <NavigateBeforeIcon />
                                </IconButton>


                            </div>

                        </Grid>

                        <Grid item xs={3.5} className="middleTable">
                            <Grid container className="strPlans-senderGridTop">
                                <Grid item className="strPlans-PlanIcon-grid">
                                    <div className="strPlans-PlanIconRight"></div>
                                    <Typography className="strPlans-senderTypo"> {selectedPlanRight?.label || "Select Plan"} </Typography>
                                </Grid>
                            </Grid>
                            <div className={`strPlans-pb10hierarchy strPlan-tableHierarchy-Height strPlans-pb10 ${selectedBox === "right" ? "selectedRight" : ""}`}
                                onClick={() => { setSelectedBox("right") }}  >
                                {
                                    selectedPlanRight ?
                                        <>
                                            <Grid container spacing={1} className="configTable">
                                                <Grid item xs={1.5} className="configuration-form-build">
                                                    <img className="icon-cursor strPlans-pd-r-10" src={planFilterButton} />
                                                </Grid>

                                                <Grid item xs={10.5} className="usage-search-border-color">
                                                    <Box className="strPlans-searchbarBox">
                                                        <TextField
                                                            id="outlined-basic"
                                                            variant="outlined"
                                                            placeholder="Filter Search"
                                                            autoComplete="off"
                                                            size="small"
                                                            value={searchValueRight}
                                                            onChange={(e) => setSearchValueRight(e.target.value)}
                                                            onKeyDown={(event) => { setSearchValueRight(searchValueRight) }}
                                                            InputLabelProps={{ className: "serachLabel1" }}
                                                            InputProps={{
                                                                className: "strPlans-searchbarInputs",
                                                                startAdornment: (
                                                                    <InputAdornment position="start">
                                                                        {!searchValueRight && <SearchIcon
                                                                            className="searchbarIconsPosition" onClick={() => { setSearchValueRight(searchValueRight) }}
                                                                        />}
                                                                        {searchValueRight && <CloseIcon
                                                                            className="searchbarIconsPosition"
                                                                            onClick={() => { setSearchValueRight("") }}
                                                                        />}
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                        />
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                            <div className="service-lines-container">
                                                    <div className="service-lines-card">
                                                        <div className="label">Total Service Lines</div>
                                                        <div className={"highlight"}>{servicePlansRightLocal?.length || 0}</div>
                                                    </div>
                                                    <div className="service-lines-card">
                                                        <div className="label">Selected</div>
                                                        <div className={"highlight"}><Tooltip
                                                            classes={{ tooltip: 'starlink--tooltip' }}
                                                            title={
                                                                <div className="strPlans-tooltip-grid">
                                                                    <Table size="small">
                                                                        <TableBody>
                                                                            {highlightedRowsRight?.map((highlight) => {
                                                                                return (<TableRow>
                                                                                    <TableCell>{highlight?.service_lines}</TableCell>
                                                                                </TableRow>)
                                                                            })}
                                                                        </TableBody>
                                                                    </Table>
                                                                </div>
                                                            }
                                                        >
                                                            <span>{highlightedRowsRight?.length || 0}</span>
                                                        </Tooltip></div>
                                                    </div>
                                                </div>
                                            <TableContainer component={Paper} className="starlink--table_container strMap-table strPlan-tableHeight" >
                                               
                                                <Table stickyHeader size="small" aria-label="plan-hierarchy-table">
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell>
                                                                <span className="font-wt-900">Service Line</span>
                                                            </TableCell>

                                                            <TableCell>
                                                                <span className="font-wt-900">Organization</span>
                                                            </TableCell>

                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody className="strPlan-sl-table">
                                                        {servicePlansRightLocal?.length > 0 ? (
                                                            servicePlansRightLocal?.map((row: any) => (
                                                                <TableRow
                                                                    key={row.id}
                                                                    onClick={() => {
                                                                        handleHighlightedRowsClick(row)
                                                                        setHighlightedRows([])
                                                                    }}>
                                                                    <TableCell
                                                                        className={`strPlans-table-cell ${isHighlight(row.id) ? 'highlighted-purpule' : ''}`}>
                                                                        {row.service_lines}
                                                                    </TableCell>
                                                                    <TableCell
                                                                        className={`strPlans-table-cell ${isHighlight(row.id) ? 'highlighted-purpule' : ''}`}>
                                                                        {row.organization_name}
                                                                    </TableCell>
                                                                </TableRow>
                                                            ))
                                                        ) : (
                                                            <TableRow>
                                                                <TableCell colSpan={4} align="center">
                                                                    No service lines available.
                                                                </TableCell>
                                                            </TableRow>
                                                        )}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                            <Grid container spacing={2} className="strPlans-senderGrid">
                                                <Grid item >
                                                    <Button  sx={{ textTransform: 'none' }} variant="outlined" className='strPlans-cancel'
                                                         onClick={() => { setHighlightedRowsRight([]) }} >  Clear</Button>
                                                </Grid>
                                                <Grid item >
                                                    <Button  sx={{ textTransform: 'none' }} variant="outlined" className='strPlans-selectAll'
                                                         onClick={() => { setHighlightedRowsRight([...servicePlansRightLocal]) }} >  Select All </Button>
                                                </Grid>

                                            </Grid>
                                        </> : <div className="strPlan-selectPool">Select a pool to view its service plans. Use the navigation buttons to move.</div>
                                }
                            </div>

                        </Grid>
                    </Grid>
                </DialogContent>
            </div>

            <Grid classes={{ root: 'starlink--base--flex starlink--base--mtb_07x starlink--base--flex--justify--end' }} className="strPlan-actionBtns" >
                <Button variant="outlined" className='starlink--button--cancel'
                    onClick={() => {
                        // setOpen(false)
                        setMoveClickedToRight(false)
                        setMoveClickedToMiddle(false)
                        setHighlightedRows([]);
                        setHighlightedRowsRight([]);
                        setServicePlansRightLocal([...serviceLinePlansRight])
                        setServicePlansLocal([...serviceLinePlans])
                    }} >
                    Cancel</Button>
                <Button variant="contained" className='starlink--button_contained--primary'
                    disabled={!(moveClickedToRight || moveClickedToMiddle)}
                    onClick={() => { setConfirmDialog(true) }} >
                    Verify
                </Button>
            </Grid>

            <Dialog
                PaperProps={{
                    className: "strPlans-dialog"
                }}
                open={confirmDialog}
                onClose={() => setConfirmDialog(false)}
            >
                <DialogTitle className="strPlans-title">Sending Service Lines</DialogTitle>
                {moveClickedToRight ?
                    <DialogContent>
                        <Typography className="strPlans-text">
                            From :  <div className="PlanIconMiddle" ></div> <span className="strPlans-from">{selectedPlan?.label}</span>
                        </Typography>
                        <Typography className="strPlans-text">
                            To : <div className="PlanIconRight"></div> <span className="strPlans-to">{selectedPlanRight?.label}</span>
                        </Typography>

                        {highlightedRows?.map((highlight, index) => (
                            <ListItem key={highlight?.id} className="strPlans-listItem">
                                <div className="strPlans-number">{index + 1}</div>
                                <ListItemText
                                    primary={highlight?.service_lines}
                                    secondary={`Service Line: ${highlight?.id}`}
                                    className="strPlans-listText"
                                />
                            </ListItem>
                        ))}
                    </DialogContent> : <DialogContent>
                        <Typography className="strPlans-text">
                            From : <div className="PlanIconRight"></div> <span className="strPlans-to">{selectedPlanRight?.label}</span>
                        </Typography>
                        <Typography className="strPlans-text">
                            To :  <div className="PlanIconMiddle" ></div> <span className="strPlans-from">{selectedPlan?.label}</span>
                        </Typography>

                        {highlightedRowsRight?.map((highlight, index) => (
                            <ListItem key={highlight?.id} className="strPlans-listItem">
                                <div className="strPlans-number">{index + 1}</div>
                                <ListItemText
                                    primary={highlight?.service_lines}
                                    secondary={`Service Line: ${highlight?.id}`}
                                    className="strPlans-listText"
                                />
                            </ListItem>
                        ))}
                    </DialogContent>}

                <DialogActions className="strPlans-dialogActions">
                    <Button onClick={() => setConfirmDialog(false)} variant="outlined" className='starlink--button--cancel'>Go Back</Button>
                    <Button
                        onClick={() => { handleAssignPlanToRight(); }}
                        variant="contained" className='starlink--button_contained--primary'
                    >
                        Move
                    </Button>
                </DialogActions>
            </Dialog>
        </Dialog>
    )
}

const mapStateToProps = (state) => ({
    loading: state.starlinkAdmin.subcribingServiceLine,
    authReducer: state.authReducer,
    allNonAdminPermissions: state.starlinkAdmin.allNonAdminPermissions,
    starlinkMapTelemetry: state.starlinkDashboard.starlinkMapTelemetry,
    assignCustomPlanToPool: state.starlinkPooled.assignCustomPlanToPool,
    serviceLinePlans: state.starlinkPooled.serviceLinePlans,
    serviceLinePlansRight: state.starlinkPooled.serviceLinePlansRight,
    moveServicePlan: state.starlinkPooled.moveServicePlan
});

export default withRouter(
    connect(mapStateToProps, {
        assignCustomPlanAPI,
        getServiceLinePerPlan,
        getServiceLinePerPlanRight,
        moveServicePlanAPI
    })(AssignServiceLine)
);