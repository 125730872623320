import { isValidElement, useEffect, useState, useRef } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import _ from "lodash";
import Moment from 'moment';
import "./simAnalysis.css"
import {
  Grid,
  Select,
  MenuItem,
  SelectChangeEvent,
  Button
} from "@mui/material";
import { updatePermission } from "../../actions/Users/authenticate";
import { Bar } from 'react-chartjs-2';
import { getLteProviders, GetDataUsageType, QueryParameters, getUsageVesselList, getCellularDataUsage } from "../../actions/Users/authenticateReports";
import { fnRound, fnComma, strToList, readableBytesAsGB, readableBytesAsMB } from "../../utils/util"
import { getEncodedURI } from '../../utils/util';
import CustomLoader from "../../components/Loader/CustomLoader";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import Download from '../../asset/image/DownloadUsage.svg';
import Exporting from 'highcharts/modules/exporting';
import ExportData from 'highcharts/modules/export-data';

interface CSVTable {
  columns: string[];
  rows: any[][];
}

interface DataUsageFilter {
  month: string;
  day: number;
  year: number;
  type: string;
  providers: string[]
}

const getDecodeURI = (value: string) => {
  if (!value || value.length == 0)
    return {};
  const params = new URLSearchParams(value);
  return Object.fromEntries(params.entries());
}

const createRows = (data: any, defaultValue: any[] = []) => {
  if (!data || typeof data.data.rows == 'undefined')
  return defaultValue;
  return data?.data?.rows?.map(row => {
    const rowValue: { [key: string]: any } = {};
    data?.data?.columns?.forEach((col, index) => {
      rowValue[col] = row[index];
    })
    return rowValue;
  })
}

const compareKeys = (a: string, b: string) => {
  return a.localeCompare(b, undefined, { numeric: true, sensitivity: 'base' })
}

const createFlatRows = (data: Record<string, CSVTable>, defaultValue: any[] = []) => {
  if (!data)
    return defaultValue;
  const result = new Array<any>();
  const keys = Object.keys(data).sort(compareKeys);
  keys?.forEach(key => {
    const item = data[key];
    item?.rows?.forEach(value => {
      const rowValue: Record<string, any> = {};

      item.columns.forEach((col, index) => {
        rowValue[col] = value[index];
      })
      result.push(rowValue);
    })
  })
  return result;
}

const GBInBytes = Math.pow(1024, 3);

const sizeConversion = (value: number) => {
  if (!value) return '0 GB';
  value = (value / Math.pow(1024, 3));
  return value > 1024 ? `${fnComma(fnRound((value / 1024), 0))} TB` : value < 1 ? `${fnComma(fnRound((value*1024), 0))} MB` : `${fnComma(fnRound(value, 0))} GB`;
}

const createLTEOpChart = (data: Record<string, CSVTable>) => {
  const rows = createFlatRows(data);
  const computedRows = new Map<string, any[]>();
  rows.forEach(row => {
    let vesselDataList = computedRows.get(row['label']);
    if (!vesselDataList) {
      vesselDataList = [];
      computedRows.set(row['label'], vesselDataList);
    }
    vesselDataList.push(row);
  });
  const dataSets = new Map<string, any[]>();
  Array.from(computedRows).forEach(([_, value], index) => {
    value.forEach((item) => {
      let operatorList = dataSets.get(item['foperator']);
      if (!operatorList) {
        operatorList = new Array(computedRows.size);
        dataSets.set(item['foperator'], operatorList);
      }
      operatorList[index] = item;
    });
  });
  const series = Array.from(dataSets);
  const isGBCrossed = series.some(([_, items]) => {
    return items.some(item => item.lte_usage > GBInBytes)
  });
  const convertBytes = isGBCrossed ? readableBytesAsGB : readableBytesAsMB;
  return {
    labels: Array.from(computedRows.keys()),
    dataUnit: isGBCrossed ? 'GB' : 'MB',
    datasets: series.map(([operator, data]) => {
      const first = data.find(item => item);
      return {
        data: data.map(value => convertBytes(value['lte_usage'], false)),
        backgroundColor: first ? first['color'] || '#000000' : '#000000',
        label: operator
      };
    })
  };
};

const createLTESiteChart = (data: Record<string, CSVTable>) => {
  const rows = createFlatRows(data);
  const computedRows = new Map<string, any[]>();
  rows.forEach(row => {
    let vesselDataList = computedRows.get(row['name']);
    if (!vesselDataList) {
      vesselDataList = [];
      computedRows.set(row['name'], vesselDataList);
    }
    vesselDataList.push(row);
  });
  const dataSets = new Map<string, any[]>();
  Array.from(computedRows).forEach(([_, value], index) => {
    value.forEach((item) => {
      let operatorList = dataSets.get(item['foperator']);
      if (!operatorList) {
        operatorList = new Array(computedRows.size);
        dataSets.set(item['foperator'], operatorList);
      }
      operatorList[index] = item;
    });
  });
  const series = Array.from(dataSets);
  const isGBCrossed = series.some(([_, items]) => {
    return items.some(item => item.lte_usage > GBInBytes)
  });
  const convertBytes = isGBCrossed ? readableBytesAsGB : readableBytesAsMB;
  
  return {
    labels: Array.from(computedRows.keys()),
    dataUnit: isGBCrossed ? 'GB' : 'MB',
    datasets: series.map(([operator, data]) => {
      const first = data.find(item => item);
      return {
        data: data.map(value => convertBytes(value['lte_usage'], false)),
        backgroundColor: first ? first['color'] || '#000000' : '#000000',
        label: operator
      };
    })
  };
};

const Months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
const Year = [Moment().year() - 1, Moment().year(), Moment().year() + 1]

const CellularChart = (props) => {
  const { authReducer, getCellularDataUsage, getVesselsListing, filteredVessels, location, history } = props;
  
  
  const _q = getDecodeURI(location?.search);

  const [DailyDate, setDailyDate] = useState([]);
  const [selectedDate, setSelectedDate] = useState(_q.date ?? 'All');
  const [selectedMonth, setSelectedMonth] = useState(_q.month ?? Moment().format('MMM'));
  const [selectedYear, setSelectedYear] = useState(_q.year ? parseInt(_q.year) : Moment().year());
  const [serviceProviderList, setServiceProviderList] = useState(_q.providers ? strToList(_q.providers) : []);
  const [selectedServiceProviders, setSelectedServiceProviders] = useState(['All']);

  const [counterCards, setcounterCards] = useState<any[]>([
    { label: 'CELLULAR', value: 0 }
  ]);
  const [dateWiseUsageData, setDateWiseUsageData] = useState<any>({})
  const [dataWiseUnit, setDataWiseUnit] = useState('GB');
  const [vesselWiseUsageData, setVesselWiseUsageData] = useState<any>({})

  const HighchartsInstance = { ...Highcharts };
  Exporting(HighchartsInstance);
  ExportData(HighchartsInstance);
  Highcharts.setOptions({
      exporting: { enabled: false },
  });

  useEffect(() => {
    fnAllDates()
  }, [])

  const fnAllDates = () => {
    let month = Moment().daysInMonth(),
      days: any = [];

    for (let i = 0; i < month; i++) {
      if (i == 0) {
        days.push('All');
      }
      days.push(i < 9 ? '0' + (i + 1).toString() : (i + 1).toString());
    }
    setDailyDate(days)
    setSelectedDate(days[0])
  }
  
  useEffect(() => {
    if (!_.isEmpty(authReducer.getCellularDataUsage) && authReducer.getCellularDataUsage.data) {
      const {
        data: {
          operatorChart = {},
          sitewiseChart = {},
          totalLTE,
          serviceProviders = {}
        }
      } = authReducer.getCellularDataUsage;

      const { dataUnit: data1Unit, ...chart1Value} = createLTEOpChart(operatorChart?.data);
      const {dataUnit: data2Unit, ...chart2Value} = createLTESiteChart(sitewiseChart?.data);

      const [{lte_usage}] = createRows(totalLTE, [0]);
      setDateWiseUsageData(chart1Value);
      setDataWiseUnit(data1Unit);
      setVesselWiseUsageData(chart2Value);
      setDataWiseUnit(data2Unit);
      
      const operatorsList = createRows(serviceProviders);
      let SPList = ["All"]
      operatorsList?.forEach((obj) => {
        SPList.push(obj.foperator)
      });
      setServiceProviderList(SPList)

      setcounterCards([
        {
          label: 'CELLULAR',
          value: sizeConversion(lte_usage),
        }
      ])
      authReducer.getCellularDataUsage = {};
    }
  }, [authReducer.getCellularDataUsage])

  const doNavigate = (params) => {
    history.push({ pathname: location.pathname, search: `?${getEncodedURI(params)}` });
  }

  useEffect(() => {
    if (_.isEmpty(getVesselsListing))
      return;
    processQueryUrl({});
  }, [getVesselsListing, filteredVessels]);

  const processQueryUrl = (filters: Partial<DataUsageFilter>) => {
    const {
      day = selectedDate,
      month = selectedMonth,
      year = selectedYear,
      providers = selectedServiceProviders
    } = filters;

    let data: any[] = [];
    if (!_.isEmpty(getVesselsListing)) {
      data = getVesselsListing?.locations;
    }
    if (_.isArray(filteredVessels) && filteredVessels.length > 0) {
      data = filteredVessels;
    }
    const parameters: Partial<QueryParameters> = {
      f1_k4id: `${data.map(v => `'${v.id}'`).join(',')}`,
      f2_fullName: "",
      f3_day: day === 'All' ? '' : `day(ts)=${day} and`,
      f4_month: Months.indexOf(month) + 1,
      f5_year: year,
      f6_sp: providers.length > 0 && providers[0] !== 'All' ? `and operator in (${providers.map(p => `'${p}'`).join(',')})` : '',
    };
    if(data?.length > 0) {
      const _getCellularDataUsage: GetDataUsageType = getCellularDataUsage;
      _getCellularDataUsage({ type: 'CELLULAR', parameters });
    } else {
      setDateWiseUsageData({});
      setVesselWiseUsageData({});
      setcounterCards([{label: 'CELLULAR', value: 0}]);
    }
  }

  const handleProviderChange = (e: SelectChangeEvent<string[]>, child: React.ReactNode) => {
    let value = e.target.value;
    let providers: string[] = [];
    if (_.isArray(value)) {
      value = isValidElement(child) && child.props.value && child.props.value !== 'All' ? value.filter(v => v !== 'All') : ['All'];
      setSelectedServiceProviders(value);
      providers = value;
    } else {
      providers = [value];
      setSelectedServiceProviders(providers);
    }
    let params: any = getDecodeURI(location?.search);
    params.providers = value;
    if (value.length == 0) {
      delete params.providers;
      setSelectedServiceProviders(['All'])
    }
    doNavigate(params);
    processQueryUrl({ providers })
  }

  const handleDateChange = (e) => {
    const value = e.target.value;
    setSelectedDate(value);
    let params: any = getDecodeURI(location?.search);
    params.date = value;
    doNavigate(params);
    processQueryUrl({ day: value })
  }

  const handleMonthChange = (e) => {
    const value = e.target.value;
    setSelectedMonth(value);
    let params: any = getDecodeURI(location?.search);
    params.month = value;
    doNavigate(params);
    processQueryUrl({ month: value })
  }

  const handleYearChange = (e) => {
    const value = e.target.value;
    setSelectedYear(value);
    let params: any = getDecodeURI(location?.search);
    params.year = value;
    doNavigate(params);
    processQueryUrl({ year: value })
  }

  const chartRef = useRef<HighchartsReact.RefObject>(null);

    const handleDownloadCSV = () => {
        if (chartRef.current?.chart) {
            const csvData = chartRef.current.chart.getCSV();
            const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
            const link = document.createElement('a');
            const url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
            link.setAttribute('download', 'trends_usage_data.csv');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } else {
            console.error('Chart reference is null or not initialized.');
        }
    };

  return (

    <div className="Form Cellular-Form">
      <div>
        <div className="graphical-justyfy-space-between reports-cellular-chart">
          <Grid item className="reportsHeader">
            <div className="d-flex align-center">
              <div className="usage_select">Date</div>
              <div className="dropdownPadding">
                <Select
                  margin='dense'
                  className="selectBox"
                  variant='outlined'
                  id='simple-select-month'
                  value={selectedDate}
                  onChange={handleDateChange}
                  SelectDisplayProps={{ style: { padding: '8px 32px 8px 8px' } }}
                >
                  {DailyDate.length > 0 && DailyDate.map((entry, id) => {
                    return (
                      <MenuItem key={id} value={entry}>
                        {entry}
                      </MenuItem>
                    );
                  })}
                </Select>
              </div>
            </div>
            <div className="d-flex align-center usage_select_margin">
              <div className="usage_select">Month</div>
              <div className="dropdownPadding">
                <Select
                  className="selectBox"
                  variant='outlined'
                  margin='dense'
                  id='simple-select-month'
                  value={selectedMonth}
                  onChange={handleMonthChange}
                  SelectDisplayProps={{ style: { padding: '8px 32px 8px 8px' } }}
                >
                  {Months.map((entry, id) => {
                    return (
                      <MenuItem key={id} value={entry}>
                        {entry}
                      </MenuItem>
                    );
                  })}
                </Select>
              </div>
            </div>
            <div className="d-flex align-center usage_select_margin">
              <div className="usage_select">Year</div>
              <div className="dropdownPadding">
                <Select
                  margin='dense'
                  className="selectBox"
                  variant='outlined'
                  id='simple-select-month'
                  value={selectedYear}
                  onChange={handleYearChange}
                  SelectDisplayProps={{ style: { padding: '8px 32px 8px 8px' } }}
                >
                  {Year.map((entry, id) => {
                    return (
                      <MenuItem key={id} value={entry}>
                        {entry}
                      </MenuItem>
                    );
                  })}
                </Select>
              </div>
            </div>
            <div className="d-flex align-center usage_select_margin">
              <div className="usage_select">SP</div>
              <div className="dropdownPadding">
                <Select
                  margin='dense'
                  style={{ width: '125px' }}
                  className="selectBox"
                  variant='outlined'
                  multiple
                  id='simple-select-month'
                  value={selectedServiceProviders}
                  onChange={handleProviderChange}
                  SelectDisplayProps={{
                    style: { padding: '8px 32px 8px 8px' },
                  }}
                >
                  {serviceProviderList.map((entry, id) => {
                    return (
                      <MenuItem key={`provider_${id}`} value={entry}>
                        {entry}
                      </MenuItem>
                    );
                  })}
                </Select>
              </div>
            </div>
            <Grid className="cellular-lte-usage">
                {counterCards[0].value}
            </Grid>
            {/* <div className="download-summary-btn margin-left-auto" onClick={handleDownloadCSV}><img className="downloadImg" src={Download} alt="" /><Button>Download</Button></div> */}
          </Grid>

        </div>
        <div className="innerScrollGDU cellular-charts">
          {!_.isEmpty(dateWiseUsageData?.datasets) && !_.isEmpty(vesselWiseUsageData?.datasets) ? (
            <div>
              <div
                className='tabs shadow cellularMarginReset'
              >
                <Bar
                  data={dateWiseUsageData}
                  height={350}
                  options={{
                    maintainAspectRatio: false,
                    scales: {
                      xAxes:
                      {
                        stacked: true,
                        ticks: {
                          autoSkip: true,
                          maxTicksLimit: 40,
                        },
                      },
                      yAxes:
                      {
                        stacked: true,
                        ticks: {
                          callback: function (value) {
                            return value + ` ${dataWiseUnit}`;
                          },
                        },
                      },

                    },
                  }}
                />
              </div>
              {/* <div className="alignTopbarItemsCenter">
                <div className="download-summary-btn margin-left-auto" onClick={handleDownloadCSV}><img className="downloadImg" src={Download} alt="" /><Button>Download</Button></div>
              </div> */}
              <div className='tabs shadow cellularMarginReset' >
                <div className='p-2'>
                  <Bar
                    data={vesselWiseUsageData}
                    height={350}
                    options={{
                      maintainAspectRatio: false,
                      scales: {
                        xAxes:
                        {
                          stacked: true,
                          ticks: {
                            autoSkip: true,
                            maxTicksLimit: 40,
                          },
                        },
                        yAxes:
                        {
                          stacked: true,
                          ticks: {
                            callback: function (value) {
                              return value + ` ${dataWiseUnit}`;
                            },
                          },
                        },

                      },
                    }}
                  />
                </div>

              </div>
            </div>
          ) : <div className="wan-usage-no-data cellular-no-data">No data available</div>}
        </div>
      </div>
      { authReducer.setCellularDataUsageLoading ? (
          <CustomLoader
            showLoader={true}
            loadingText={ "Fetching data please wait..." }
          />
        ) : null
      }
    </div >
  )
}

const mapStateToProps = (state) => ({
  authReducer: state.authReducer,
  errorReducer: state.errorReducer,
  getVesselsListing: state.authReducer?.getVesselsListing,
  filteredVessels: state.authReducer?.filteredVessels,
});

export default withRouter(
  connect(mapStateToProps, {
    updatePermission,
    getLteProviders,
    getUsageVesselList,
    getCellularDataUsage,
  })(CellularChart)
);