import _ from "lodash";
import { api, getRequest, putRequest } from "../../server/request";
import { handleError, handleResponse, handleResponseObj } from "../../actions/responseUtil";
import { ServiceAccount } from "./types";
import { KAPTUREV2_URL_INV } from "../../config";
import axios, { Method } from 'axios';
import ServiceLines from "./ServiceLines";
import { toast } from "react-toastify";
import { getDataFromLocalStorage } from "../../utils/util";
import { LOGGED_IN_USER } from "../../constants/Constants";

const _authorizationHeaders = () => {
    const loggedInUser = getDataFromLocalStorage(LOGGED_IN_USER);
    const token = loggedInUser ? JSON.parse(loggedInUser)?.data.id_token : '';

    return {
        "Content-Type": "application/json",
        "Authorization": token ? `Bearer ${token}` : '',
    };
};

export const ACTION_TYPES = {
    SET_LOADING: "starlinkAdmin/setLoading",
    SET_TOPUP_LOADING: "starlinkAdmin/setTopUpLoading",
    GET_SERVICE_ACCOUNTS: "starlinkAdmin/getServiceAccounts",
    CREATE_SERVICE_ACCOUNT: "starlinkAdmin/createServiceAccount",
    GET_SERVICE_LINES: "starlinkAdmin/getServiceLines",
    GET_USER_TERMINALS: "starlinkAdmin/getUserTerminals",
    GET_STARLINK_AUDIT_LOGS: "starlinkAdmin/getStarlinkAuditLogs",
    GET_ALL_SERVICE_LINES_BY_CREDENTIAL: "starlinkAdmin/getAllServiceLinesByCredential",
    GET_SERVICE_LINES_SUBSCRIPTIONS: "starlinkAdmin/getServiceLineSubscription",
    GET_AVAILABLE_PRODUCTS_FOR_ACCOUNT: "starlinkAdmin/getAvailableProductsForAccount",
    GET_OPTIN_SERVICE_LINE: 'starlinkAdmin/getOptinServiceLine',
    GET_ORGANIZATION_LIST:'starlinkAdmin/getOrganizationList',
    GET_CUSTOM_PLANS:'starlinkAdmin/getCustomPlans',
    GET_STARLINK_PARENT_POOL_LIST:"starlinkAdmin/getStarLinkParentPoolList",
    GET_CUSTOM_PLAN_PER_DP: "starlinkAdmin/getCustomPlanPerDP",
    GET_SUBSCRIPTION_FOR_ACCOUNT: "starlinkAdmin/getSubscriptionForAccount",
    UPDATE_SERVICE_LINE_DETAILS: "starlinkAdmin/updateServiceLineDetails",
    UPDATE_PUBLIC_IP: "starlinkAdmin/updatePublicIP",
    ADD_USER_TERMINAL_TO_SERVICE_LINE: "starlinkAdmin/addUserTerminalToServiceLine",
    REMOVE_USER_TERMINAL_FROM_SERVICE_LINE : "starlinkAdmin/removeUserTerminalFromServiceLine",
    REBOOT_USER_TERMINAL : "starlinkAdmin/rebootUserTerminal",
    ADD_TOPUP_TO_PARENT_POOL:"starlinkAdmin/addTopUpToParentPool",
    ADD_TOPUP_TO_CHILD_POOL:"starlinkAdmin/addTopUpToChildPool",
    ADD_TOPUP_TO_SERVICE_LINE:"starlinkAdmin/addTopUpToServiceLine",
    GET_ALL_SITES_WITH_DEVICES: "starlinkAdmin/getAllSitesWithDevices",
    GET_SITE_FOR_TERMINAL: "starlinkAdmin/siteForTerminal",
    ADD_SITE_TO_SERVICE_LINE: "starlinkAdmin/addSiteToServiceLine",
    GET_NON_ADMIN_PERMISSIONS: "starlinkAdmin/getNonAdminPermissions",
    GET_PARENT_DP: "/starlinkAdmin/getParentDP",
    UPDATE_NON_ADMIN_PERMISSIONS: "/starlinkAdmin/updateNonAdminPermissions"

}

const initialState = {
    serviceAccounts: [],
    totalServiceAccounts: 0,
    gettingServiceAccounts: false,
    creatingServiceAccount: false,
    addingUTtoServiceLine:false,
    addingSiteToServiceLine:false,
    addingTopup:false,
    removeUTfromServiceLine:false,
    rebootingUserTerminal:false,
    updatingServiceAccount: false,
    deletingServiceAccount: false,
    serviceLines: [],
    allServiceLines: [],
    totalServiceLines: 0,
    gettingServiceLines: false,
    updatingServiceLine: false,
    userTerminals: [],
    totalUserTerminals: 0,
    auditLogs:[],
    totalAuditLogs:0,
    gettingUserTerminals: false,
    subcribingServiceLine:false,
    updatingNonAdminPermissions: false,
    gettingNonAdminPermissions:false,
    allNonAdminPermissions: [],
    parentDp:[],
    updateNonAdmin:[],
    allServiceLinesSubscription:[],
    availableProductsForAccount:[],
    optInServiceLineRes: {},
    creatingSubPool:false,
    organizationList:[],
    organizationListCount:0,
    customPlansCount:0,
    customPlansList:[],
    parentPoolList:[],
    allCustomPlansPerDP: [],
    subscriptionsForAccount:[],
    updateServiceLineDetail:{},
    updatePublicIP:{},
    addUserTerminalToServiceLine:{},
    addTopUpToParentPool:{},
    addTopUpToChildPool:{},
    addTopUpToServiceLine:{},
    removeUserTerminalFromServiceLine:{},
    rebootUserTerminal:{},
    sitesWithDevices: [],
    siteForTerminal:{},
    addSiteToServiceLine:{},

}

export function starlinkAdminReducer(state: any = initialState, action) {
    switch (action.type) {
        case ACTION_TYPES.GET_SERVICE_ACCOUNTS: {
            const [serviceAccounts, totalServiceAccounts] = action.payload?.data?.rows.length > 0 ?  action.payload?.data?.rows[0] : [];
            return {
                ...state,
                serviceAccounts: serviceAccounts || [],
                totalServiceAccounts: totalServiceAccounts ? totalServiceAccounts : 0,
            }
        }
        case ACTION_TYPES.GET_SERVICE_LINES: {
            const [serviceLines, totalServiceLines] = action.payload?.data?.rows.length > 0 ? action.payload?.data?.rows[0] : [];
            return {
                ...state,
                serviceLines: serviceLines || [],
                totalServiceLines: totalServiceLines ? totalServiceLines : 0,
            }
        }
        case ACTION_TYPES.GET_AVAILABLE_PRODUCTS_FOR_ACCOUNT: {
            return {
                ...state,
                availableProductsForAccount: action.payload?.data?.rows,
            }
        }
        case ACTION_TYPES.GET_CUSTOM_PLANS: {
            return {
                ...state,
                customPlansList: action.payload?.data?.rows && action.payload?.data?.rows.length > 0 && action.payload?.data?.rows[0] && action.payload?.data?.rows[0].length > 0 ? action.payload?.data?.rows[0][0] : [],
                customPlansCount: action.payload?.data?.rows && action.payload?.data?.rows.length > 0 && action.payload?.data?.rows[0] && action.payload?.data?.rows[0].length > 0 ? action.payload?.data?.rows[0][1] : [],
            }
        }
        case ACTION_TYPES.GET_STARLINK_PARENT_POOL_LIST: {
            return {
                ...state,
                parentPoolList: action.payload?.data?.rows && action.payload?.data?.rows.length > 0 && action.payload?.data?.rows[0] && action.payload?.data?.rows[0].length > 0 ? action.payload?.data?.rows[0][0] : [],
            }
        }
        
        case ACTION_TYPES.GET_ORGANIZATION_LIST: {
            return {
                ...state,
                organizationList: action.payload?.data?.rows && action.payload?.data?.rows.length > 0 && action.payload?.data?.rows[0] && action.payload?.data?.rows[0].length > 0 ? action.payload?.data?.rows[0][0] : [],
                organizationListCount: action.payload?.data?.rows && action.payload?.data?.rows.length > 0 && action.payload?.data?.rows[0] && action.payload?.data?.rows[0].length > 0 ? action.payload?.data?.rows[0][1] : 0,
            }
        }
        case ACTION_TYPES.GET_USER_TERMINALS: {
            const userTerminals = action.payload?.data;
            const totalUserTerminals = action.payload?.data[0]?.total_count;
            return {
                ...state,
                userTerminals: userTerminals || [],
                totalUserTerminals: totalUserTerminals ? totalUserTerminals : 0,
            }
        }
        case ACTION_TYPES.GET_ALL_SITES_WITH_DEVICES: {
            const sitesWithDevices = action.payload?.data;
            return {
                ...state,
                sitesWithDevices: sitesWithDevices || [],
            }
        }
        case ACTION_TYPES.GET_SITE_FOR_TERMINAL: {
            const siteForTerminal = action.payload?.data;
            return {
                ...state,
                siteForTerminal: siteForTerminal || [],
            }
        }
        case ACTION_TYPES.GET_SUBSCRIPTION_FOR_ACCOUNT: {
            const subscriptions = action.payload?.data?.rows &&  action.payload?.data?.rows.length > 0 ?  action.payload?.data?.rows[0][0]: [];
            return {
                ...state,
                subscriptionsForAccount: subscriptions || [],
            }
        }
        case ACTION_TYPES.GET_STARLINK_AUDIT_LOGS: {
            const auditLogs = action.payload?.data[0]?.result;
            const totalAuditLogs = action.payload?.data[0]?.total_count;
            return {
                ...state,
                auditLogs: auditLogs || [],
                totalAuditLogs: totalAuditLogs ? totalAuditLogs : 0,
            }
        }
        
        case ACTION_TYPES.GET_CUSTOM_PLAN_PER_DP: {
            const allCustomPlansPerDP = action.payload?.data?.rows[0][0];
          
            return {
                ...state,
                allCustomPlansPerDP: allCustomPlansPerDP || [],
            }
        }
        case ACTION_TYPES.GET_ALL_SERVICE_LINES_BY_CREDENTIAL: {
            const allServiceLines = JSON.parse(action.payload?.data?.rows[0][0]);
            return {
                ...state,
                allServiceLines: allServiceLines || [],
            }
        }
        case ACTION_TYPES.GET_SERVICE_LINES_SUBSCRIPTIONS: {
            const allServiceLinesSubscription = action.payload || [];
            return {
                ...state,
                allServiceLinesSubscription: allServiceLinesSubscription
            }
        }
        case ACTION_TYPES.GET_NON_ADMIN_PERMISSIONS: {
             const allNonAdminPermissions = action.payload?.data || [];
            return {
                ...state,
                allNonAdminPermissions: allNonAdminPermissions
            }
        }
        case ACTION_TYPES.GET_PARENT_DP: {
            const parentDp = action.payload?.data || [];
           return {
               ...state,
               parentDp: parentDp
            }
        }
        case ACTION_TYPES.UPDATE_NON_ADMIN_PERMISSIONS: {
            const updateNonAdmin = action.payload?.data || [];
            return {
                ...state,
                updateNonAdmin: updateNonAdmin
            }
        }
        case ACTION_TYPES.SET_LOADING: {
            return {
                ...state,
                ...action.payload,
            }
        }
        case ACTION_TYPES.GET_OPTIN_SERVICE_LINE: {
            return {
                ...state,
                optInServiceLineRes: action.payload
            }
        }
        case ACTION_TYPES.UPDATE_SERVICE_LINE_DETAILS: {
            return {
                ...state,
                updateServiceLineDetail: action.payload
            }
        }
        case ACTION_TYPES.UPDATE_PUBLIC_IP: {
            return {
                ...state,
                updatePublicIP: action.payload
            }
        }
        case ACTION_TYPES.ADD_TOPUP_TO_PARENT_POOL: {
            return {
                ...state,
                addTopUpToParentPool: action.payload
            }
        }
        case ACTION_TYPES.ADD_TOPUP_TO_CHILD_POOL: {
            return {
                ...state,
                addTopUpToChildPool: action.payload
            }
        }
        case ACTION_TYPES.ADD_TOPUP_TO_SERVICE_LINE: {
            return {
                ...state,
                addTopUpToServiceLine: action.payload
            }
        }
        case ACTION_TYPES.ADD_USER_TERMINAL_TO_SERVICE_LINE: {
            return {
                ...state,
                addUserTerminalToServiceLine: action.payload
            }
        }
        case ACTION_TYPES.ADD_SITE_TO_SERVICE_LINE: {
            return {
                ...state,
                addSiteToServiceLine: action.payload
            }
        }
        case ACTION_TYPES.REMOVE_USER_TERMINAL_FROM_SERVICE_LINE: {
            return {
                ...state,
                removeUserTerminalFromServiceLine: action.payload
            }
        }
        case ACTION_TYPES.REBOOT_USER_TERMINAL: {
            return {
                ...state,
                rebootUserTerminal: action.payload
            }
        }
        default:
            return state
    }
}

export interface getServiceAccountsParameters {
    dpIds: string[];
    searchText: string;
    sortBy: string;
    sortOrder: string;
    page: number;
    limit: number;
}

export const getServiceAccounts = (parameters: getServiceAccountsParameters) => (dispatch) => {
    if (_.isEmpty(parameters?.dpIds)) return;
    dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { gettingServiceAccounts: true } });
    let _parameters: any = {
        dpIds: parameters.dpIds.map(dpId => `'${dpId}'`).join(','),
        searchText: parameters.searchText,
        sortBy: parameters.sortBy,
        sortOrder: parameters.sortOrder,
        limit: parameters.limit,
        offset: (parameters.page - 1) * parameters.limit
    };
    const data = {
        query: `GET_SERVICE_ACCOUNTS`,
        named: true,
        format: 'csv',
        parameters: _parameters
    }
    api
        .postRequestOut(`/store/pg/query`, data)
        .then((res) => {
            handleResponse(res, dispatch, ACTION_TYPES.GET_SERVICE_ACCOUNTS)
            dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { gettingServiceAccounts: false } });
        })
        .catch((err) => {
            handleError(err, dispatch);
            dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { gettingServiceAccounts: false } });
        });
}

export const createServiceAccount = (parameters, abortSignal: AbortSignal | undefined) => async (dispatch) => {
    dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { creatingServiceAccount: true } });
    const data = {
        dpId: parameters.dpId,
        type: parameters.type,
        username: parameters.userName,
        password: parameters.password,
        clientId: parameters.clientId,
        clientSecret: parameters.clientSecret,
        validate: parameters.validate,
    }
    
    try {
        let res = await api.starlink.post('/credentials', data, {}, abortSignal)
        dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { creatingServiceAccount: false } });
        return res;
    } catch (error) {
        dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { creatingServiceAccount: false } });
        return error;
    }
}

export const createParentPool = (parameters) => async (dispatch) => {
    dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { creatingServiceAccount: true } });
    const data = {
        organizationId: parameters.dpId,
        accountNumber: parameters.accountNumber,
        credentialId: parameters.credentialId,
        poolName: parameters.poolName,
        poolSize: parameters.poolSize,
        unit: parameters.unit,
        startDate: parameters.startDate,
        endDate: parameters.endDate,
        isRecurring: parameters.isRecurring,
        poolInfo: parameters.poolInfo,
        notificationMethods: [
            "email"
        ],
        usersToNotify: parameters.usersToNotify,
        quotaActions: parameters.quotaActions,
    }
    
    try {
        let res = await api.starlink.post('/parentPools', data, {})
        dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { creatingServiceAccount: false } });
        return res;
    } catch (error) {
        dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { creatingServiceAccount: false } });
        return error;
    }
}

export const updateParentPool = (parameters) => async (dispatch) => {
    dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { creatingServiceAccount: true } });
    const data = {
        organizationId: parameters.dpId,
        accountNumber: parameters.accountNumber,
        credentialId: parameters.credentialId,
        poolName: parameters.poolName,
        poolSize: parameters.poolSize,
        unit: parameters.unit,
        startDate: parameters.startDate,
        endDate: parameters.endDate,
        isRecurring: parameters.isRecurring,
        poolInfo: parameters.poolInfo,
        notificationMethods: [
            "email"
        ],
        usersToNotify: parameters.usersToNotify,
        quotaActions: parameters.quotaActions,
    }
    
    try {
        let res = await api.starlink.put(`/parentPools/${parameters?.parentPoolId}`, data, {})
        dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { creatingServiceAccount: false } });
        return res;
    } catch (error) {
        dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { creatingServiceAccount: false } });
        return error;
    }
}

export const createSubPool = ( parameters) => async (dispatch) => {
    dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { creatingSubPool: true } });

    const data = {
        credentialId: parameters.credentialId,
        organizationId: parameters.dpId,
        accountNumber: parameters.accountNumber,
        assignedBy: parameters.assignedBy,
        poolName: parameters.poolName,
        poolSize: parameters.poolSize,
        unit: parameters.unit,
        startDate: parameters.startDate,
        duration: parameters.duration,
        isRecurring: parameters.isRecurring,
        poolInfo: parameters.poolInfo,
        notificationMethods: parameters.notificationMethods,
        usersToNotify: parameters.usersToNotify,
        quotaActions: parameters.quotaActions
       
    };
    
    try {
        let res = await api.starlink.post(`/parentPools/${parameters.parentPoolId}/childPools`, data);
        dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { creatingSubPool: false } });
        return res;
    } catch (error) {
        dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { creatingSubPool: false } });
        return error;
    }
};

export const updateSubPool = ( parameters) => async (dispatch) => {
    dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { creatingSubPool: true } });

    const data = {
        credentialId: parameters.credentialId,
        organizationId: parameters.dpId,
        accountNumber: parameters.accountNumber,
        assignedBy: parameters.assignedBy,
        poolName: parameters.poolName,
        poolSize: parameters.poolSize,
        unit: parameters.unit,
        startDate: parameters.startDate,
        duration: parameters.duration,
        isRecurring: parameters.isRecurring,
        poolInfo: parameters.poolInfo,
        notificationMethods: parameters.notificationMethods,
        usersToNotify: parameters.usersToNotify,
        quotaActions: parameters.quotaActions
       
    };
    
    try {
        let res = await api.starlink.put(`/parentPools/${parameters.parentPoolId}/childPools/${parameters?.childPoolId}`, data);
        dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { creatingSubPool: false } });
        return res;
    } catch (error) {
        dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { creatingSubPool: false } });
        return error;
    }
};


export const updateServiceAccount = (parameters: ServiceAccount) => async (dispatch) => {
    dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { updatingServiceAccount: true } });
    const data = {
        dpId: parameters.dpId,
        type: parameters.type,
        username: parameters.userName,
        password: parameters.password,
        clientId: parameters.clientId,
        clientSecret: parameters.clientSecret,
    }
    
    try {
        let res = await api.starlink.put(`/credentials/${parameters.id}`, data)
        dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { updatingServiceAccount: false } });
        return res;
    } catch (error) {
        dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { updatingServiceAccount: false } });
        return null;
    }
}

export const deleteServiceAccount = (id: string) => async (dispatch) => {
    dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { deletingServiceAccount: true } });
    try {
        let res = await api.starlink.delete(`/credentials/${id}`)
        dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { deletingServiceAccount: false } });
        return res;
    } catch (error) {
        dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { deletingServiceAccount: false } });
        return null;
    }
}

export const updateServiceLineDetails = (parameters) => async (dispatch) => {
    dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { creatingServiceAccount: true } });
    const data = {
        organizationId: parameters.organizationId,
        accountNumber: parameters.accountNumber,
        serviceLineNumber: parameters.serviceLineNumber,
        nickname: parameters.serviceLineName,
        address: parameters.address,
        emailIds: parameters.emailIds,
    }
    
    try {
        let res = await api.starlink.put('/service_lines/update_service_line', data, {})
        dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { creatingServiceAccount: false } });
        handleResponseObj(res, dispatch, ACTION_TYPES.UPDATE_SERVICE_LINE_DETAILS)
    } catch (error) {
        dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { creatingServiceAccount: false } });
        return error;
    }
}

export const updatePublicIP = (parameters) => async (dispatch) => {
    dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { creatingServiceAccount: true } });
    const data = {
        organizationId: parameters.organizationId,
        serviceLineNumber: parameters.serviceLineNumber,
        publicIP: parameters.publicIP,
    }
    
    try {
        let res = await api.starlink.post('/public_ip/update', data, {})
        dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { creatingServiceAccount: false } });
        handleResponseObj(res, dispatch, ACTION_TYPES.UPDATE_PUBLIC_IP)
    } catch (error) {
        dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { creatingServiceAccount: false } });
        return error;
    }
}

export const getAllSitesWithDevices = (parameters) => (dispatch) => {
    if (_.isEmpty(parameters?.sites)) return;
    dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { gettingUserTerminals: true } });
    let _parameters: any = {
        k4Ids: parameters.sites.map(site => `'${site?.id}'`).join(','),
       
    };
    const data = {
        query: `GET_ALL_LOCATIONS`,
        named: true,
        format: 'json',
        parameters: _parameters
    }
    api
        .postRequestOut(`/store/ch/query`, data)
        .then((res) => {
            handleResponse(res, dispatch, ACTION_TYPES.GET_ALL_SITES_WITH_DEVICES)
            dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { gettingUserTerminals: false } });
        })
        .catch((err) => {
            handleError(err, dispatch);
            dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { gettingUserTerminals: false } });
        });
}

export const getSitesForTerminal = (parameters) => (dispatch) => {
    if (_.isEmpty(parameters?.terminalId)) return;
    dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { gettingUserTerminals: true } });
    let _parameters: any = {
        deviceId: parameters?.terminalId,
       
    };
    const data = {
        query: `GET_SITE_FOR_TERMINAL`,
        named: true,
        format: 'json',
        parameters: _parameters
    }
    api
        .postRequestOut(`/store/ch/query`, data)
        .then((res) => {
            handleResponse(res, dispatch, ACTION_TYPES.GET_SITE_FOR_TERMINAL)
            dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { gettingUserTerminals: false } });
        })
        .catch((err) => {
            handleError(err, dispatch);
            dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { gettingUserTerminals: false } });
        });
}

export const addSiteToServiceLineApi = (parameters) => async (dispatch) => {
    dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { addingSiteToServiceLine: true } });
    const data = {
        deviceId: parameters.deviceId,
        serviceLineNumber: parameters.serviceLineNumber,
        siteId: parameters.siteId,
        userTerminalId: parameters.userTerminalId
    }
    
    try {
        let res = await api.starlink.post('/service_lines/site_association', data, {})
        dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { addingSiteToServiceLine: false } });
        handleResponseObj(res, dispatch, ACTION_TYPES.ADD_SITE_TO_SERVICE_LINE)
    } catch (error) {
        dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { addingSiteToServiceLine: false } });
        return error;
    }
}

export const addTerminalToServiceLine = (parameters) => async (dispatch) => {
    dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { addingUTtoServiceLine: true } });
    const data = {
        organizationId: parameters.organizationId,
        accountNumber: parameters.accountNumber,
        serviceLineNumber: parameters.serviceLineNumber,
        userTerminalId: parameters.userTerminalId
    }
    
    try {
        let res = await api.starlink.post('/user_terminals/create_user_terminal', data, {})
        dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { addingUTtoServiceLine: false } });
        handleResponseObj(res, dispatch, ACTION_TYPES.ADD_USER_TERMINAL_TO_SERVICE_LINE)
    } catch (error) {
        dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { addingUTtoServiceLine: false } });
        return error;
    }
}

export const removeTerminalFromServiceLine = (parameters) => async (dispatch) => {
    dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { removeUTfromServiceLine: true } });
    const data = {
        organizationId: parameters.organizationId,
        accountNumber: parameters.accountNumber,
        serviceLineNumber: parameters.serviceLineNumber,
        userTerminalId: parameters.userTerminalId
    }
    
    try {
        let res = await api.starlink.put('/user_terminals/remove_user_terminal', data, {})
        dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { removeUTfromServiceLine: false } });
        handleResponseObj(res, dispatch, ACTION_TYPES.REMOVE_USER_TERMINAL_FROM_SERVICE_LINE)
    } catch (error) {
        dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { removeUTfromServiceLine: false } });
        return error;
    }
}

export const rebootTerminal = (parameters) => async (dispatch) => {
    dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { rebootingUserTerminal: true } });
    const data = {
        organizationId: parameters.organizationId,
        accountNumber: parameters.accountNumber,
        userTerminalId: parameters.userTerminalId
    }
    
    try {
        let res = await api.starlink.post('/user_terminals/reboot_user_terminal', data, {})
        dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { rebootingUserTerminal: false } });
        handleResponseObj(res, dispatch, ACTION_TYPES.REBOOT_USER_TERMINAL)
    } catch (error) {
        dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { rebootingUserTerminal: false } });
        return error;
    }
}

export interface TopUpParams {
    organizationId: string[];
    accountNumber: string;
    unit: string;
    topup: number;
    credentialId: string;
    serviceLineNumber:string;
    parentPoolId:string;
    childPoolId:string;
    customPlanId:string;
}

export const topUpParentPool = (parameters: TopUpParams) => async (dispatch) => {
    dispatch({ type: ACTION_TYPES.SET_TOPUP_LOADING, payload: { addingTopup: true } });
    const data = {
        organizationId: parameters?.organizationId,
        accountNumber: parameters?.accountNumber,
        topup: parameters?.topup,
        unit: parameters?.unit
    }
    
    try {
        let res = await api.starlink.post(`/parentPools/${parameters?.parentPoolId}/topup`, data, {})
        dispatch({ type: ACTION_TYPES.SET_TOPUP_LOADING, payload: { addingTopup: false } });
        handleResponseObj(res, dispatch, ACTION_TYPES.ADD_TOPUP_TO_PARENT_POOL)
    } catch (error) {
        dispatch({ type: ACTION_TYPES.SET_TOPUP_LOADING, payload: { addingTopup: false } });
        return error;
    }
}

export const topUpChildPool = (parameters: TopUpParams) => async (dispatch) => {
    dispatch({ type: ACTION_TYPES.SET_TOPUP_LOADING, payload: { addingTopup: true } });
    const data = {
        organizationId: parameters?.organizationId,
        accountNumber: parameters?.accountNumber,
        topup: parameters?.topup,
        unit: parameters?.unit
    }
    
    try {
        let res = await api.starlink.post(`/parentPools/${parameters?.parentPoolId}/childPools/${parameters?.childPoolId}/topup`, data, {})
        dispatch({ type: ACTION_TYPES.SET_TOPUP_LOADING, payload: { addingTopup: false } });
        handleResponseObj(res, dispatch, ACTION_TYPES.ADD_TOPUP_TO_CHILD_POOL)
    } catch (error) {
        dispatch({ type: ACTION_TYPES.SET_TOPUP_LOADING, payload: { addingTopup: false } });
        return error;
    }
}

export const topUpServiceLine = (parameters: TopUpParams) => async (dispatch) => {
    dispatch({ type: ACTION_TYPES.SET_TOPUP_LOADING, payload: { addingTopup: true } });
    const data = {
        credentialId: parameters?.credentialId,
        organizationId: parameters?.organizationId,
        accountNumber: parameters?.accountNumber,
        serviceLineNumber: parameters?.serviceLineNumber,
        topup: parameters.topup,
        unit: parameters?.unit,
        customPlanId:parameters?.customPlanId
    }
    
    try {
        let res = await api.starlink.post('/service_lines/add_topup', data, {})
        dispatch({ type: ACTION_TYPES.SET_TOPUP_LOADING, payload: { addingTopup: false } });
        handleResponseObj(res, dispatch, ACTION_TYPES.ADD_TOPUP_TO_SERVICE_LINE)
    } catch (error) {
        dispatch({ type: ACTION_TYPES.SET_TOPUP_LOADING, payload: { addingTopup: false } });
        return error;
    }
}

export interface getServiceLinesParameters {
    dpIds: string[];
    searchText: string;
    sortBy: string;
    sortOrder: string;
    page: number;
    limit: number;
    serviceLineStatus:('true' | 'false')[];
    serviceLines: string[];
    accounts:any;
}

export const getServiceLines = (parameters: getServiceLinesParameters) => (dispatch) => {
    // if (_.isEmpty(parameters?.dpIds)) return;
    if (_.isEmpty(parameters?.serviceLines)) return;
    if (parameters?.serviceLines.includes('All') ) return;
    dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { gettingServiceLines: true } });
    let _parameters: any = {
        // dpIds: parameters.dpIds.map(dpId => `'${dpId}'`).join(','),
        accounts: parameters?.accounts?.map(dpId => `'${dpId}'`).join(','),
        searchText: parameters?.searchText,
        sortBy: parameters?.sortBy,
        sortOrder: parameters?.sortOrder,
        limit: parameters?.limit,
        offset: (parameters?.page - 1) * parameters?.limit,
        serviceLineStatus:  parameters?.serviceLineStatus?.map(status => `'${status}'`).join(','),
        serviceLines:  parameters?.serviceLines?.map(serviceLine => `'${serviceLine}'`).join(',')
    };
    const data = {
        query: `GET_SERVICE_LINES_ADMIN`,
        named: true,
        format: 'csv',
        parameters: _parameters
    }
    api
        .postRequestOut(`/store/pg/query`, data)
        .then((res) => {
            handleResponse(res, dispatch, ACTION_TYPES.GET_SERVICE_LINES)
            dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { gettingServiceLines: false } });
        })
        .catch((err) => {
            handleError(err, dispatch);
            dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { gettingServiceLines: false } });
        });
}

export const getOrganizationList = (parameters) => (dispatch) => {
    dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { gettingServiceLines: true } });
    let _parameters: any = {
        dpId:parameters?.dpId,
        searchText:parameters?.searchText,
        sortBy:parameters?.sortBy,
        sortOrder:parameters?.sortOrder,
        limit:parameters?.limit,
        offset:parameters?.offset,
    };
    const data = {
        query:`GET_STARLINK_SUB_ORGANIZATIONS`,
        named: true,
        format: 'csv',
        parameters: _parameters
    }
    api
        .postRequestOut(`/store/pg/query`, data)
        .then((res) => {
            handleResponse(res, dispatch, ACTION_TYPES.GET_ORGANIZATION_LIST)
            dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { gettingServiceLines: false } });
        })
        .catch((err) => {
            handleError(err, dispatch);
            dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { gettingServiceLines: false } });
        });
}

export const getCustomPlansList = (parameters) => (dispatch) => {
    dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { gettingServiceLines: true } });
    let _parameters: any = {
        dpId:parameters?.dpIds?.map(dpId => `'${dpId}'`).join(','),
        searchText:parameters?.searchText,
        sortBy:parameters?.sortBy,
        sortOrder:parameters?.sortOrder,
        limit:parameters?.limit,
        offset:parameters?.offset,
    };
    const data = {
        query:`GET_STARLINK_CUSTOM_PLANS`,
        named: true,
        format: 'csv',
        parameters: _parameters
    }
    api
        .postRequestOut(`/store/pg/query`, data)
        .then((res) => {
            handleResponse(res, dispatch, ACTION_TYPES.GET_CUSTOM_PLANS)
            dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { gettingServiceLines: false } });
        })
        .catch((err) => {
            handleError(err, dispatch);
            dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { gettingServiceLines: false } });
        });
}

export const getParentPoolList = (parameters) => (dispatch) => {
    dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { gettingServiceLines: true } });
    let _parameters: any = {
        dpIds:parameters?.dpIds?.map(dpId => `'${dpId}'`).join(','),
        searchText:parameters?.searchText,
        sortBy:parameters?.sortBy,
        sortOrder:parameters?.sortOrder,
        limit:parameters?.limit,
        offset:parameters?.offset,
        childPools:parameters?.childPools
    };
    const data = {
        query:`GET_STARLINK_PARENT_POOL_LIST`,
        named: true,
        format: 'csv',
        parameters: _parameters
    }
    api
        .postRequestOut(`/store/pg/query`, data)
        .then((res) => {
            handleResponse(res, dispatch, ACTION_TYPES.GET_STARLINK_PARENT_POOL_LIST)
            dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { gettingServiceLines: false } });
        })
        .catch((err) => {
            handleError(err, dispatch);
            dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { gettingServiceLines: false } });
        });
}

export const createCustomPlan = (parameters) => async (dispatch) => {
    dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { creatingServiceAccount: true } });
    const data = {
        credentialId: parameters?.credentialId,
            parentPoolId: parameters?.parentPoolId,
            accountNumber: parameters?.accountNumber,
            childPoolId: parameters?.childPoolId,
            createdBy: parameters?.createdBy,
            planName: parameters?.planName,
            planSize: parameters?.planSize,
            isRecurring: parameters?.isRecurring,
            planInfo: parameters?.planInfo,
            organizationIds: parameters?.dpIds,
            duration: parameters?.duration,
            planUsage: parameters?.planUsage,
            billingDate: parameters?.billingDate,
            unit: parameters?.unit,
            usersToNotify: parameters?.usersToNotify,
            quotaActions: parameters?.entries,
            planType: parameters?.planType,
            notificationMethods: parameters?.notificationMethods
    }
    
    try {
        let res = await api.starlink.post('/customPlans', data, {})
        dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { creatingServiceAccount: false } });
        return res;
    } catch (error) {
        dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { creatingServiceAccount: false } });
        return error;
    }
}

export const updateCustomPlan = (parameters) => async (dispatch) => {
    dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { creatingServiceAccount: true } });
    const data = {
        customPlanId: parameters.customPlanId,
        credentialId: parameters?.credentialId,
            parentPoolId: parameters?.parentPoolId,
            accountNumber: parameters?.accountNumber,
            childPoolId: parameters?.childPoolId,
            createdBy: parameters?.createdBy,
            planName: parameters?.planName,
            planSize: parameters?.planSize,
            isRecurring: parameters?.isRecurring,
            planInfo: parameters?.planInfo,
            organizationIds: parameters?.dpIds,
            duration: parameters?.duration,
            planUsage: parameters?.planUsage,
            billingDate: parameters?.billingDate,
            unit: parameters?.unit,
            usersToNotify: parameters?.usersToNotify,
            quotaActions: parameters?.entries,
            notificationMethods: parameters?.notificationMethods
    }
    
    try {
        let res = await api.starlink.put(`/customPlans/${parameters?.customPlanId}`, data, {})
        dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { creatingServiceAccount: false } });
        return res;
    } catch (error) {
        dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { creatingServiceAccount: false } });
        return error;
    }
}

export const assignCustomPlan = (parameters) => async (dispatch) => {
    if(!parameters?.organizationId) return
    dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { creatingServiceAccount: true } });
    const data = {
        serviceLineNumber: parameters.serviceLineNumber,
        accountNumber: parameters?.accountNumber || "",
        kitIds: parameters?.userTerminalId || "",
        productReferenceId: parameters?.productReferenceId || "",
        customPlanId: parameters?.customPlanId || "",
        organizationId : parameters?.organizationId,
        allowDowngradeNow: parameters?.allowDowngradeNow,  
    }
    
    try {
        let res = await api.starlink.put(`/service_lines/assign_service_plan`, data, {})
        dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { creatingServiceAccount: false } });
        return res;
    } catch (error) {
        dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { creatingServiceAccount: false } });
        return error;
    }
}

export const createServiceLine = (parameters) => async (dispatch) => {
    if(!parameters?.organizationId) return
    dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { creatingServiceAccount: true } });
    const data = {
        name: parameters?.name,
            customPlanId:parameters?.customPlanId,
            productReferenceId:parameters?.productReferenceId,
            accountNumber:parameters?.accountNumber,
            address:parameters?.address,
            organizationId:parameters?.organizationId,
            userTerminalIds:parameters?.userTerminalId,
            emailIds:parameters?.emailIds
    }
    
    try {
        let res = await api.starlink.post('/service_lines/new_service_line', data, {})
        dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { creatingServiceAccount: false } });
        return res;
    } catch (error) {
        dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { creatingServiceAccount: false } });
        return error;
    }
}

export const getAvailableProductsForAccount = (parameters) => (dispatch) => {
    dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { gettingServiceLines: true } });
    let _parameters: any = {
       accountNumber:parameters?.accountNumber
    };
    const data = {
        query: `GET_AVAILABLE_PRODUCTS_FOR_ACCOUNT`,
        named: true,
        format: 'csv',
        parameters: _parameters
    }
    api
        .postRequestOut(`/store/pg/query`, data)
        .then((res) => {
            handleResponse(res, dispatch, ACTION_TYPES.GET_AVAILABLE_PRODUCTS_FOR_ACCOUNT)
            dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { gettingServiceLines: false } });
        })
        .catch((err) => {
            handleError(err, dispatch);
            dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { gettingServiceLines: false } });
        });
}

export interface parametersUpdateServiceLineOrganization {
    serviceLineNumber: string;
    dpId: string;
}

export const updateServiceLineOrganization = (parameters: parametersUpdateServiceLineOrganization) => async (dispatch) => {
    dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { updatingServiceLine: true } });
    const data = {
        dpId: parameters.dpId,
    }
    try {
        let res = await api.starlink.put(`/service-lines/${parameters.serviceLineNumber}/organization`, data)
        dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { updatingServiceLine: false } });
        return res;
    } catch (error) {
        dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { updatingServiceLine: false } });
        return null;
    }
}

export interface parametersBulkUpdateServiceLineOrganization {
    serviceLineNumbers: string[];
    dpId: string;
}

export const bulkUpdateServiceLineOrganization = (parameters: parametersBulkUpdateServiceLineOrganization) => async (dispatch) => {
    dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { updatingServiceLine: true } });
    try {
        let res = await api.starlink.put(`/service-lines/organization`, parameters)
        dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { updatingServiceLine: false } });
        showToastMessage(res, 'Change Sub Org');
        return res;
    } catch (error) {
        dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { updatingServiceLine: false } });
        return null;
    }
}

export interface getUserTerminalsParameters {
    dpIds: string[];
    searchText: string;
    sortBy: string;
    sortOrder: string;
    page: number;
    limit: number;
    terminalStatus: ('deployed' | 'in-warehouse')[];
    serviceLines: string[];
    accounts:any
}

export const getUserTerminals = (parameters: getUserTerminalsParameters) => (dispatch) => {
    // if (_.isEmpty(parameters?.dpIds)) return;
    if (_.isEmpty(parameters?.serviceLines)) return;
    if (parameters?.serviceLines.includes('All') ) return;
    dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { gettingUserTerminals: true } });
    let _parameters: any = {
        dpIds: parameters.dpIds.map(dpId => `'${dpId}'`).join(','),
        accounts: parameters?.accounts?.map(acc => `'${acc}'`).join(','),
        searchText: parameters.searchText,
        sortBy: parameters.sortBy,
        sortOrder: parameters.sortOrder,
        limit: parameters.limit,
        offset: (parameters.page - 1) * parameters.limit,
        terminalStatus: parameters.terminalStatus.map(status => `'${status}'`).join(','),
        serviceLines: parameters.serviceLines.map(serviceLine => `'${serviceLine}'`).join(',')
    };
    const data = {
        query: `GET_USER_TERMINALS_ADMIN`,
        named: true,
        format: 'json',
        parameters: _parameters
    }
    api
        .postRequestOut(`/store/ch/query`, data)
        .then((res) => {
            handleResponse(res, dispatch, ACTION_TYPES.GET_USER_TERMINALS)
            dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { gettingUserTerminals: false } });
        })
        .catch((err) => {
            handleError(err, dispatch);
            dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { gettingUserTerminals: false } });
        });
}

export const getSubscriptionForAccount = (parameters) => (dispatch) => {
    if (_.isEmpty(parameters?.dpIds)) return;
    dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { gettingUserTerminals: true } });
    let _parameters: any = {
        dpId: parameters.dpIds,
    };
    const data = {
        query: `GET_STARLINK_PLANS_ADDRESSES_AND_TERMINALS`,
        named: true,
        format: 'csv',
        parameters: _parameters
    }
    api
        .postRequestOut(`/store/pg/query`, data)
        .then((res) => {
            handleResponse(res, dispatch, ACTION_TYPES.GET_SUBSCRIPTION_FOR_ACCOUNT)
            dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { gettingUserTerminals: false } });
        })
        .catch((err) => {
            handleError(err, dispatch);
            dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { gettingUserTerminals: false } });
        });
}

export interface extractThroughCredentialParameters {
    id?: string;
    username?: string;
    clientId?: string;
    skipUsage?: boolean;
}

export const extractThroughCredential = (parameters: extractThroughCredentialParameters, abortSignal: AbortSignal | undefined) => async (dispatch) => {
    dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { updatingServiceAccount: true } });
    const data = {}
    for (let param of Object.keys(parameters)) {
        if (parameters[param]) {
            data[param] = parameters[param];
        }
    }
    try {
        let res = await api.starlink.post(`/credentials/extract`, data, {}, abortSignal)
        dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { updatingServiceAccount: false } });
        return res;
    } catch (error) {
        dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { updatingServiceAccount: false } });
        return null;
    }
}

export interface getServiceLineByCredentialParameters extends extractThroughCredentialParameters {}

export const getAllServiceLinesByCredential = (parameters: getServiceLineByCredentialParameters, abortSignal: AbortSignal | undefined) => async (dispatch) => {
    if (_.isEmpty(parameters?.clientId) && _.isEmpty(parameters?.username)) return;
    dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { gettingServiceLines: true } });
    let _parameters = {
        clientId: parameters.clientId,
        username: parameters.username,
    };
    const data = {
        query: `GET_SERVICE_LINES_WORKFLOW`,
        named: true,
        format: 'csv',
        parameters: _parameters
    }
    try {
        let res = await api.postRequestOut(`/store/ch/query`, data, abortSignal)
        handleResponse(res, dispatch, ACTION_TYPES.GET_ALL_SERVICE_LINES_BY_CREDENTIAL)
        dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { gettingServiceLines: false } });
    } catch (err) {
        handleError(err, dispatch);
        dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { gettingServiceLines: false } });
    }
}

export const getCustomPlanPerDP = (parameters) => async (dispatch) => {
    dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { gettingServiceLines: true } });
    let _parameters = {
        subOrgId: parameters.subOrgId,
    };
    const data = {
        query: `GET_CUSTOM_PLAN_LIST`,
        named: true,
        format: 'csv',
        parameters: _parameters
    }
    try {
        let res = await api.postRequestOut(`/store/pg/query`, data)
        handleResponse(res, dispatch, ACTION_TYPES.GET_CUSTOM_PLAN_PER_DP)
        dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { gettingServiceLines: false } });
    } catch (err) {
        handleError(err, dispatch);
        dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { gettingServiceLines: false } });
    }
}


export interface createSubOrgParameters {
    DPName: string;
    DPFullName: string;
    parentName: string;
}

export const createSubOrg = (parameters: createSubOrgParameters) => async (dispatch) => {
    dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { creatingServiceAccount: true } });    
    try {
        let res = await api.postRequestForExternalApi(`${KAPTUREV2_URL_INV}vessels/addDP`, parameters)
        dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { creatingServiceAccount: false } });
        return res.data;
    } catch (error) {
        dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { creatingServiceAccount: false } });
        return error;
    }
}

export const clearComponentData = () => (dispatch) => {
    dispatch({ type: ACTION_TYPES.GET_SERVICE_ACCOUNTS, payload: { data: { rows: [[[], 0]] } } })
    dispatch({ type: ACTION_TYPES.GET_STARLINK_AUDIT_LOGS,  payload: { data: [{result:[]}]} })
    dispatch({ type: ACTION_TYPES.GET_USER_TERMINALS,  payload: { data: { rows: [[[], 0]] } } })
    dispatch({ type: ACTION_TYPES.GET_SUBSCRIPTION_FOR_ACCOUNT,  payload: { data: { rows: [[[], 0]] } } })
    dispatch({ type: ACTION_TYPES.GET_SERVICE_LINES,  payload: { data: { rows: [[[], 0]] } } })
    // dispatch({
}

export const subscribeToServiceLines = (parameters) => async (dispatch) => {
    if (_.isEmpty(parameters?.serviceLineNumber)) return;
    dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { subcribingServiceLine: true } });
    try {
        let res = await api.starlink.post('/service_lines/subscriptions', parameters)
        dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { subcribingServiceLine: false } });
        showToastMessage(res, 'Subscribe');
        return res;
    } catch (error) {
        dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { subcribingServiceLine: false } });
        return null;
    }
}

export const getServiceLineSubscription = (parameters) => async (dispatch) => {
    if (_.isEmpty(parameters?.dpId)) return;
    dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { subcribingServiceLine: true } });
    try {
        let res = await api.starlink.post('/service_lines/getSubscriptionData', parameters)
       
            handleResponse(res, dispatch, ACTION_TYPES.GET_SERVICE_LINES_SUBSCRIPTIONS)
        
        dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { subcribingServiceLine: false } });
        return res;
    } catch (error) {
        dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { subcribingServiceLine: false } });
        return null;
    }
}

const showToastMessage = (res, handler) => {
   if(res.success == false) {
    toast.error(`${handler} : ${res.message}, Please Try Later.`, {
        position: toast.POSITION.BOTTOM_LEFT,
      })
   } else {
    toast.success(res.message, {
        position: toast.POSITION.BOTTOM_LEFT,
      })
   }
}

export const optInForServiceLine = (parameters) => async (dispatch) => {
    if (_.isEmpty(parameters?.serviceLineNumber)) return;
    dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { subcribingServiceLine: true } });
    try {
        let res = await api.starlink.post('/service_lines/optInOptOut', parameters)

        dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { subcribingServiceLine: false } });
        dispatch({ type: ACTION_TYPES.GET_OPTIN_SERVICE_LINE, payload: res });
        showToastMessage(res, 'Mobile Priority');
        return res;
    } catch (error) {
        dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { subcribingServiceLine: false } });
        return null;
    }
}

export const pauseResumeServiceLine = (parameters) => async (dispatch) => {
    if (_.isEmpty(parameters?.serviceLineNumber)) return;
    dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { subcribingServiceLine: true } });
    try {
        let res = await api.starlink.post('/service_lines/pauseResume', parameters)

        dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { subcribingServiceLine: false } });
        showToastMessage(res, 'Pause/Resume SL');
        return res;
    } catch (error) {
        dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { subcribingServiceLine: false } });
        return null;
    }
}

export const updateNonAdminPermissions = (parameters) => async (dispatch) => {
    if (_.isEmpty(parameters?.organizationId)) return;
    dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { updatingNonAdminPermissions: true } });
    try {
        api.putRequest(`/iam/starlink/dpId/${parameters?.organizationId}/permissions`, parameters?.data)
                .then((res) => handleResponse(res, dispatch, ACTION_TYPES.UPDATE_NON_ADMIN_PERMISSIONS))
                .catch((err) => handleError(err, dispatch));

    } catch (error) {
        dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { updatingNonAdminPermissions: false } });
        return null;
    }
}

export const getNonAdminPermissions = (parameters) => async (dispatch) => {
    
    if (_.isEmpty(parameters?.dpId)) return;
    dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { gettingNonAdminPermissions: true } });
    try {
        let res = await getRequest(`/iam/starlink/dpId/${parameters?.dpId}/permissions?parentDpId=${parameters?.parentDpId}`)
            handleResponse(res, dispatch, ACTION_TYPES.GET_NON_ADMIN_PERMISSIONS)
        
        dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { gettingNonAdminPermissions: false } });
        return res;
    } catch (error) {
        dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { gettingNonAdminPermissions: false } });
        return null;
    }
}

export const getParentDP = (parameters) => async (dispatch) => {
    
    if (_.isEmpty(parameters?.dp)) return;
    dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { gettingNonAdminPermissions: true } });
    try {
        let res = await getRequest(`/iam/ou/details?name=${parameters?.dp}`)
            handleResponse(res, dispatch, ACTION_TYPES.GET_PARENT_DP)
        
        dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { gettingNonAdminPermissions: false } });
        return res;
    } catch (error) {
        dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { gettingNonAdminPermissions: false } });
        return null;
    }
}


export const getStarlinkAuditLogs = (parameters) => (dispatch) => {
    clearComponentData()
    // if (_.isEmpty(parameters?.dpIds)) return;
    if (_.isEmpty(parameters?.serviceLines)) return;
    if (parameters?.serviceLines.includes('All') ) return;
    dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { gettingUserTerminals: true } });
    let _parameters: any = {
        dpIds: parameters.dpIds.map(dpId => `'${dpId}'`).join(','),
        searchText: parameters.searchText,
        sortBy: parameters.sortBy,
        sortOrder: parameters.sortOrder,
        limit: parameters.limit,
        offset: (parameters.page - 1) * parameters.limit,
        page: parameters.page,
        serviceLines: parameters.serviceLines.map(serviceLine => `'${serviceLine}'`).join(',')
    };
    const data = {
        query: `GET_STARLINK_AUDIT_LOGS`,
        named: true,
        format: 'json',
        parameters: _parameters
    }
    api
        .postRequestOut(`/store/pg/query`, data)
        .then((res) => {
            handleResponse(res, dispatch, ACTION_TYPES.GET_STARLINK_AUDIT_LOGS)
            dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { gettingUserTerminals: false } });
        })
        .catch((err) => {
            handleError(err, dispatch);
            dispatch({ type: ACTION_TYPES.SET_LOADING, payload: { gettingUserTerminals: false } });
        });
}
