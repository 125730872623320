import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { useEffect, useState, useRef } from "react";
import { Button, FormControl, FormControlLabel, Grid, IconButton, MenuItem, Select, Switch } from "@mui/material";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import _ from "lodash";
import moment from "moment";
import { getContentFilterLinechart } from '../../actions/Users/authenticateContentFilter';
import { getDateForChart, getDecodeURI, getEncodedURI, getMinutes, getSingleRecordChartLable, getTimezoneCity } from "../../utils/util";
import Download from '../../asset/image/DownloadUsage.svg';
import Exporting from 'highcharts/modules/exporting';
import ExportData from 'highcharts/modules/export-data';

const LineChart = (props) => {
    const { authReducer, values, setValues, apiCall, newSummaryStartDate, newSummaryEndDate, getContentFilterLinechart, history, location } = props;
    const { vessels, category, app, user, vlan, device, subCategory, limit, pageLimit, sort, sortOrder, field, order, selectedBin } = values;
    const { setPage, setPageStart, setSelectedBin } = setValues;
    const [lineChartData, setLineChartData] = useState<any>([]);
    const [resetDate, setResetDate] = useState<any>([]);
    const [type, setType] = useState(true);
    const timeZone = getTimezoneCity(authReducer?.userTimezone);

    const HighchartsInstance = { ...Highcharts };
    Exporting(HighchartsInstance);
    ExportData(HighchartsInstance);
    Highcharts.setOptions({
        exporting: { enabled: false },
    });

    useEffect(() => {
        if(vessels.length == 0) {
            setLineChartData([]);
        }
     }, [vessels])

    useEffect(() => {
        if(!_.isEmpty(authReducer.getContentFilterLinechart)) {
            const data = authReducer.getContentFilterLinechart.hasOwnProperty('data') ? authReducer.getContentFilterLinechart?.data : {};
            if(data) {
                const colors = ["#1d136a", "#1b51a7", "#3e88d3", "#8cb5ec", "#86c6df", "#4b91f1", "#64a1f4", "#8dbdff", "#c1d5f6",
                    "#0e0548", "#1d136a", "#1b51a7", "#3e88d3", "#8cb5ec", "#86c6df", "#4b91f1", "#64a1f4", "#8dbdff", "#c1d5f6"
                ]
                const lineChartData:any = data?.hits?.series && Object.keys(data?.hits?.series)?.map((key, i) => {
                    return {name: key, data: data?.hits?.series[key], marker: {symbol: 'circle'}, visible: true, color: colors[i]}
                })
                setLineChartData(lineChartData);
            }
        }
    }, [authReducer.getContentFilterLinechart])

    const pinchAndResetChart = (start, end, interval) => {
        let params = getDecodeURI(location?.search);
        params.startDate = start.valueOf();
        params.endDate = end.valueOf();
        params.interval = interval;
        doNavigate(params);
    }

    const doNavigate = (params) => {
        history.push({ pathname: location.pathname, search: `?${getEncodedURI(params)}` });
    }

    const chartAreaSelection = () => {
        return (event: any) => {
            let start: any = moment(new Date(Math.ceil(event.xAxis[0].min)));
            let end = moment(new Date(Math.floor(event.xAxis[0].max)));
            setPage(1);
            setPageStart(1);
            pinchAndResetChart(start, end, 'customDates');
            let arr: any = resetDate;
            arr.push({ startDate: start, endDate: end });
            setResetDate(arr);
            return false;
        }
    }

    const options: any = {
        time: {
            timezone: timeZone
        },
        chart: {
            type: type ? 'spline' : 'column',
            height: '230px',
            zoomType: 'x',
            plotBorderWidth: 1,
            resetZoomButton: {
                theme: {
                    style: {
                        display: 'none'
                    }
                }
            },
            events: {
                selection: chartAreaSelection()
            },
            style: {
                fontFamily: "Roboto, Nunito Sans, Arial, Verdana, Helvetica, sans-serif",
            },
        },
        colors: ["#0e0548", "#1d136a", "#1b51a7", "#3e88d3", "#8cb5ec", "#86c6df", "#4b91f1", "#64a1f4", "#8dbdff", "#c1d5f6"],
        title: {
            text: '',
            align: 'left',
            floating: true,
            x: 12,
            y: 32,
            style: {
                fontWeight: '500'
            }
        },
        lang: {
            noData: "No Data",
        },
        noData: {
            style: {
                fontWeight: 'bold',
                fontSize: '15px',
                color: '#303030',
            },
        },
        credits: {
            enabled: false
        },
        legend: {
            enabled: false
        },
        plotOptions: {
            series: {
                turboThreshold: 1000000,
                stickyTracking: false,
                connectNulls: false,
            },
        },
        tooltip: {
            valueSuffix: ' Hits',
            pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b><br/>',
            valueDecimals: 0,
            borderWidth: 1
        },
        xAxis: {
            gridLineWidth: 0.5,
            reversed: false,
            type: "datetime",
            maxPadding: 0.05,
            showLastLabel: true,
            labels: {
                format: getDateForChart(lineChartData) && getSingleRecordChartLable(lineChartData, timeZone)
            }
        },
        yAxis: {
            tickInterval: 1,
            gridLineWidth: 1,
            labels: {
                format: '',
            },
            title: {
                text: null
            }
        },
        exporting: {
            enabled: false,
            csv: {
                columnHeaderFormatter: (item, key) => {
                    if (item.isXAxis) {
                        return 'Time';
                    }
                    return `${item.name} (Hits)`;
                }
            }
        },
        series: lineChartData ? lineChartData : [],
        colorByPoint: true,
    }

    const handleResetZoom = () => {
        if(resetDate?.length == 0) {
          return;
        }
        let arr:any = resetDate;
        arr.pop();
        setResetDate(arr);
        if(arr?.length == 0) {
            setPage(1);
            setPageStart(1);
            const start = moment(Date.now()).subtract(1, 'hour');
            const end = moment(Date.now());
            pinchAndResetChart(start, end, '1h');
        }
        else {
            let obj = arr[arr?.length-1];
            let start = obj.startDate;
            let end = obj.endDate;
            setPage(1);
            setPageStart(1);
            pinchAndResetChart(start, end, 'customDates');
        }
    }
    
    const handleDefaultResetZoom = () => {
        setResetDate([])
        setPage(1);
        setPageStart(1);
        const start = moment(Date.now()).subtract(1, 'hour');
        const end = moment(Date.now());
        pinchAndResetChart(start, end, '1h');
    }

    const handleLegendClick = (app, color, index) => {
        let all_true = true;
        let updatedData:any = [...lineChartData];
        let all_off_turn_on = 0;
        let click = 0;
    
        updatedData?.forEach(( ele, i)=>{
          if( !ele.visible ){
            all_true = false;
            all_off_turn_on +=1;
          }
          if(ele.visible){
            click = i;
          }
        })
        
        if(click == index && all_off_turn_on == updatedData.length-1 ){
            updatedData?.forEach((ele,i)=>{
            updatedData[i] = { ...ele, visible:true }
           })
          setLineChartData([...updatedData]);
        }
        else if(all_true){
          updatedData?.forEach((ele,i)=>{
           updatedData[i] = { ...ele, visible:false }
          })
          updatedData[index].visible = true;
          setLineChartData([...updatedData]);
        }
        else{
          updatedData.forEach((ele,i)=>{
            updatedData[i] = ele;
           })
          updatedData[index].visible = !updatedData[index].visible; 
          setLineChartData([...updatedData]);
        }
    }

    const handleBinChange = (e) => {
        const value = e.target.value;
        setSelectedBin(e.target.value);
        vessels.length > 0 && getContentFilterLinechart(newSummaryStartDate, newSummaryEndDate, vessels, category, app, user, vlan, device, subCategory, value);
    };

    const chartRef = useRef<HighchartsReact.RefObject>(null);

    const handleDownloadCSV = () => {
        if (chartRef.current?.chart) {
            const csvData = chartRef.current.chart.getCSV();
            const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
            const link = document.createElement('a');
            const url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
            link.setAttribute('download', 'content_filter_data.csv');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } else {
            console.error('Chart reference is null or not initialized.');
        }
    };

    return (
        <div className="login-history-bar-chart-comp">
            <div className="alignTopbarItemsCenter">
                <div className="download-summary-btn margin-left-auto" onClick={handleDownloadCSV}><img className="downloadImg" src={Download} alt="" /><Button>Download</Button></div>
            </div>
            <Grid className="cf-linechart">
                <Grid className="chart-option-btn">
                    <Grid>
                        <span className="hits-cate">Hits Per Day</span>
                    </Grid>
                    <Grid className="content-filter-actions-div">
                        <Grid className="pieChat-selectTag">
                            <FormControl variant="standard" className='selectEntry-pie'>
                                <Select
                                    labelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-standard"
                                    value={selectedBin}
                                    onChange={handleBinChange}
                                >
                                    {getMinutes(newSummaryStartDate, newSummaryEndDate) >= 5 && <MenuItem value={'5m'}>5m</MenuItem>}
                                    {getMinutes(newSummaryStartDate, newSummaryEndDate) >= 10 && <MenuItem value={'10m'}>10m</MenuItem>}
                                    {getMinutes(newSummaryStartDate, newSummaryEndDate) >= 15 && <MenuItem value={'15m'}>15m</MenuItem>}
                                    {getMinutes(newSummaryStartDate, newSummaryEndDate) >= 60 && <MenuItem value={'1h'}>1h</MenuItem>}
                                    {getMinutes(newSummaryStartDate, newSummaryEndDate) >= 240 && <MenuItem value={'4h'}>4h</MenuItem>}
                                    {getMinutes(newSummaryStartDate, newSummaryEndDate) >= 360 && <MenuItem value={'6h'}>6h</MenuItem>}
                                    {getMinutes(newSummaryStartDate, newSummaryEndDate) >= 720 && <MenuItem value={'12h'}>12h</MenuItem>}
                                    {getMinutes(newSummaryStartDate, newSummaryEndDate) >= 1440 && <MenuItem value={'1d'}>1d</MenuItem>}
                                    {getMinutes(newSummaryStartDate, newSummaryEndDate) >= 10080 && <MenuItem value={'7d'}>7d</MenuItem>}
                                </Select>
                            </FormControl>
                        </Grid>
                        { resetDate.length > 0 &&
                            <div className="siteCharts__filters-reset-zoom resetZoom-usage">
                                <div>
                                    <FormControlLabel
                                        value="Zoom Back"
                                        control={<IconButton onClick={handleResetZoom}><ZoomOutIcon /></IconButton>}
                                        label="Zoom Back"
                                        labelPlacement="start"
                                    />
                                    <FormControlLabel
                                        value="Zoom Reset"
                                        control={<IconButton onClick={handleDefaultResetZoom}><RestartAltIcon /></IconButton>}
                                        label="Zoom Reset"
                                        labelPlacement="start"
                                    />
                                </div>
                            </div> }
                            <div className="siteCharts__filters-reset-zoom resetZoom-usage">
                                <div>
                                    <FormControlLabel 
                                    value="Line"
                                    control={<Switch checked={type} onChange={(e) => setType(e.target.checked)} />}
                                    label="Line"
                                    labelPlacement="start"
                                />
                                </div>
                            </div>
                    </Grid>
                </Grid>
                <HighchartsReact
                    highcharts={Highcharts}
                    options={options}
                    ref={chartRef}
                />
                <Grid className="topApplicationNames cf-legends">
                    {lineChartData?.map((ele, i) => (
                        <Grid key={i} className="name-color line-cf-chart">
                            <div className="totalUsageColor" style={{ background: ele['color'] }}></div>
                            <Grid className={lineChartData[i]?.visible ? "lineChart-legends" : 'disable-lineChart-legends'}
                                onClick={() => handleLegendClick(ele, ele['color'], i)}>{ele['name']}
                            </Grid>
                        </Grid>
                    ))}
                </Grid>
            </Grid>
        </div>
    )
}

const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
    newSummaryStartDate: state.authReducer.newSummaryStartDate,
    newSummaryEndDate: state.authReducer.newSummaryEndDate,
    errorReducer: state.errorReducer,
});

export default withRouter(
    connect(mapStateToProps, { getContentFilterLinechart })(LineChart)
);