import { api } from "../../server/request";
import { handleResponse, handleError, handleResponseObj } from "../responseUtil";
import { SET_HUB_DASHBOARD_HUB_DATA_LOADING, GET_HUB_DASHBOARD_HUB_DATA, SET_HUB_DASHBOARD_HUB_DATA_COUNT_LOADING, GET_HUB_DASHBOARD_HUB_DATA_COUNT, SET_HUB_DASHBOARD_CLIENT_TABLE_LOADING, GET_HUB_DASHBOARD_CLIENT_TABLE, SET_HUB_DASHBOARD_SITES_DROPDOWN_LOADING, GET_HUB_DASHBOARD_SITES_DROPDOWN,
  SET_HUB_DASHBOARD_CLIENT_WISE_LOADING, GET_HUB_DASHBOARD_CLIENT_WISE, SET_HUB_DASHBOARD_CLIENT_WISE_COUNT_LOADING, GET_HUB_DASHBOARD_CLIENT_WISE_COUNT, SET_TOPOLOGY_HUB_TABLE, GET_TOPOLOGY_HUB_TABLE, SET_HUB_DASHBOARD_CARRIER_CLIENT_TABLE, GET_HUB_DASHBOARD_CARRIER_CLIENT_TABLE,
  SET_HUB_DASHBOARD_CARRIER_SERVICE_VRF_TABLE, GET_HUB_DASHBOARD_CARRIER_SERVICE_VRF_TABLE, SET_CARRIER_HUB_SDWAN_STATUS, GET_CARRIER_HUB_SDWAN_STATUS, SET_CARRIER_HUB_SDWAN_LATENCY, GET_CARRIER_HUB_SDWAN_LATENCY, SET_CARRIER_HUB_SDWAN_DATA_RATES, GET_CARRIER_HUB_SDWAN_DATA_RATES,
  SET_CARRIER_HUB_AGENT_DROPDOWN, GET_CARRIER_HUB_AGENT_DROPDOWN, SET_CARRIER_HUB_WAN_DROPDOWN, GET_CARRIER_HUB_WAN_DROPDOWN, SET_CARRIER_HUB_SDWAN_USAGE, GET_CARRIER_HUB_SDWAN_USAGE, SET_CARRIER_HUB_SDWAN_HUB_DROPDOWN, GET_CARRIER_HUB_SDWAN_HUB_DROPDOWN, SET_CARRIER_HUB_SDWAN_HUBS, GET_CARRIER_HUB_SDWAN_HUBS,
  CREATE_AGENT_WAN_LOADING,CREATE_AGENT_WAN,GET_AGENT_WANS_LOADING,GET_AGENT_WANS,
  CREATE_AGENT_HUB_LOADING, CREATE_AGENT_HUB, GET_AGENT_HUBS_LOADING, GET_AGENT_HUBS,
  CREATE_AGENT_WAN_PROFILE_LOADING, CREATE_AGENT_WAN_PROFILE, GET_AGENT_WAN_PROFILES_LOADING, GET_AGENT_WAN_PROFILES,
  CREATE_AGENT_SERVICE_VLAN_LOADING, CREATE_AGENT_SERVICE_VLAN, GET_AGENT_SERVICE_VLANS, GET_AGENT_SERVICE_VLANS_LOADING,
  CREATE_AGENT_PROFILE_LOADING, CREATE_AGENT_PROFILE, GET_AGENT_PROFILES_LOADING, GET_AGENT_PROFILES, CREATE_AGENT_ROUTE_LOADING, 
  CREATE_AGENT_ROUTE, GET_AGENT_ROUTES_LOADING, GET_AGENT_ROUTES, CREATE_AGENT_SERVICE_LOADING, CREATE_AGENT_SERVICE, GET_AGENT_SERVICES_LOADING,
  GET_AGENT_SERVICES, CREATE_AGENT_CONFIG_LOADING, CREATE_AGENT_CONFIG, GET_AGENT_CONFIGS_LOADING, GET_AGENT_CONFIGS,
  CREATE_AGENT_LOADING, CREATE_AGENT, GET_AGENTS_LOADING, GET_AGENTS,
  UPDATE_AGENT_WAN_LOADING, UPDATE_AGENT_WAN, DELETE_AGENT_WAN_LOADING, DELETE_AGENT_WAN, UPDATE_AGENT_WAN_PROFILE_LOADING, UPDATE_AGENT_WAN_PROFILE, DELETE_AGENT_WAN_PROFILE_LOADING, 
  DELETE_AGENT_WAN_PROFILE, DELETE_AGENT_SERVICE_VLAN_LOADING, DELETE_AGENT_SERVICE_VLAN, DELETE_AGENT_ROUTE_LOADING, DELETE_AGENT_ROUTE,
  CREATE_AGENT_ACCESS_NETWORK_LOADING, CREATE_AGENT_ACCESS_NETWORK, GET_AGENT_HUBS_PER_SERVICE_LOADING, GET_AGENT_HUBS_PER_SERVICE,
  GET_AGENT_CONFIG_SUMMARY_LOADING, GET_AGENT_CONFIG_SUMMARY, UPDATE_AGENT_SERVICE_LOADING, UPDATE_AGENT_SERVICE, UPDATE_AGENT_ACCESS_NETWORK_LOADING, UPDATE_AGENT_ACCESS_NETWORK, GET_AGENT_HUB_EVENTS_LOADING, GET_AGENT_HUB_EVENTS, GET_AGENT_HUB_EVENT_FILTERS_LOADING, GET_AGENT_HUB_EVENT_FILTERS
 } from "../types";
import { getAvilWindow } from "./authenticateDashboard";

export const getIntervalInNewFormat = (interval) => {
  switch (interval) {
    case "15m": return "minute,15";
    case "1h": return "hour,1";
    case "2h": return "hour,2";
    case "6h": return "hour,6";
    case "12h": return "hour,12";
    case "1d": return "day,1";
    case "7d": return "day,7";
    case "30d": return "day,30";
    default: return "minute,15";
  }
}

export const getHubDataTableRow = (dpId, startDate, endDate, page, limit, interval, vessels, sort, sortOrder, search) => (dispatch) => {
  dispatch({ type: SET_HUB_DASHBOARD_HUB_DATA_LOADING, payload: true });
  const data: any = {
    query: 'HUB_DASHBOARD',
    named: true,
    groupBy: 'server_device_id',
    parameters: {
      "dp_id": dpId,
      "pageoffset": ((page - 1) * limit),
      "pagesize": limit,
      "start_time": startDate,
      "end_time": endDate,
      "interval": getIntervalInNewFormat(interval),
      "apps_interval": getIntervalInNewFormat(interval),
      "avail_window": getAvilWindow(interval),
      "site_ids": vessels && vessels.map((vs) => `${vs.k4Id}`).join(','),
      "sortColumn": sort,
      "sortOrder": sortOrder,
      "search": search
    },
    format: 'csv'
  }
  const apiUrl = `/store/ch/query`
  api
    .postRequestOut(`${apiUrl}`, data)
    .then((res) => {
      handleResponse(res, dispatch, GET_HUB_DASHBOARD_HUB_DATA)
    })
    .catch((err) => handleError(err, dispatch));
};

export const getHubDataTableRowCount = (dpId, vessels, search) => (dispatch) => {
  dispatch({ type: SET_HUB_DASHBOARD_HUB_DATA_COUNT_LOADING, payload: true });
  const count: any = {
    query: 'HUB_DASHBOARD_COUNT',
    named: true,
    parameters: {
      "dp_id": dpId,
      "site_ids": vessels && vessels.map((vs) => `${vs.k4Id}`).join(','),
      "search": search
    },
    format: 'csv'
  }
  const apiUrl = `/store/ch/query`
  api
    .postRequestOut(`${apiUrl}`, count)
    .then((res) => {
      handleResponse(res, dispatch, GET_HUB_DASHBOARD_HUB_DATA_COUNT)
    })
    .catch((err) => handleError(err, dispatch));
};

export const getHubDataClietTable = (server_location_id, client_device_id, interval) => (dispatch) => {
  dispatch({ type: SET_HUB_DASHBOARD_CLIENT_TABLE_LOADING, payload: true });
  const clientData: any = {
    query: 'HUB_DASHBOARD_CLIENT_TABLE',
    named: true,
    parameters: {
      "server_location_id": server_location_id,
      "client_device_id": client_device_id,
      "interval": getIntervalInNewFormat(interval)
    },
    format: 'json'
  }
  const apiUrl = `/store/ch/query`
  api
    .postRequestOut(`${apiUrl}`, clientData)
    .then((res) => {
      handleResponse(res, dispatch, GET_HUB_DASHBOARD_CLIENT_TABLE)
    })
    .catch((err) => handleError(err, dispatch));
};

export const getHubDataSitesList = (dpId) => (dispatch) => {
  dispatch({ type: SET_HUB_DASHBOARD_SITES_DROPDOWN_LOADING, payload: true });
  const sitesDropdown: any = {
    query: 'HUB_DASHBOARD_SITES_DROPDOWN',
    named: true,
    parameters: {
      "dp_id": dpId
    },
    format: 'json'
  }
  const apiUrl = `/store/ch/query`
  api
    .postRequestOut(`${apiUrl}`, sitesDropdown)
    .then((res) => {
      handleResponse(res, dispatch, GET_HUB_DASHBOARD_SITES_DROPDOWN)
    })
    .catch((err) => handleError(err, dispatch));
};

export const getHubDataTableRowClientWise = (dpId, startDate, endDate, page, limit, interval, vessels, sort, sortOrder, search) => (dispatch) => {
  dispatch({ type: SET_HUB_DASHBOARD_CLIENT_WISE_LOADING, payload: true });
  const data: any = {
    query: 'HUB_DASHBOARD_CLIENT_WISE',
    named: true,
    parameters: {
      "dp_id": dpId,
      "pageoffset": ((page - 1) * limit),
      "pagesize": limit,
      "start_time": startDate,
      "end_time": endDate,
      "interval": getIntervalInNewFormat(interval),
      "apps_interval": getIntervalInNewFormat(interval),
      "avail_window": getAvilWindow(interval),
      "site_ids": vessels && vessels.map((vs) => `${vs.k4Id}`).join(','),
      "sortColumn": sort,
      "sortOrder": sortOrder,
      "search": search
    },
    format: 'json'
  }
  const apiUrl = `/store/ch/query`
  api
    .postRequestOut(`${apiUrl}`, data)
    .then((res) => {
      handleResponse(res, dispatch, GET_HUB_DASHBOARD_CLIENT_WISE)
    })
    .catch((err) => handleError(err, dispatch));
};

export const getHubDataTableRowClientWiseCount = (dpId, startDate, endDate, page, limit, interval, vessels, sort, sortOrder, search) => (dispatch) => {
  dispatch({ type: SET_HUB_DASHBOARD_CLIENT_WISE_COUNT_LOADING, payload: true });
  const data: any = {
    query: 'HUB_DASHBOARD_CLIENT_WISE_COUNT',
    named: true,
    parameters: {
      "dp_id": dpId,
      "pageoffset": ((page - 1) * limit),
      "pagesize": limit,
      "start_time": startDate,
      "end_time": endDate,
      "interval": getIntervalInNewFormat(interval),
      "apps_interval": getIntervalInNewFormat(interval),
      "avail_window": getAvilWindow(interval),
      "site_ids": vessels && vessels.map((vs) => `${vs.k4Id}`).join(','),
      "sortColumn": sort,
      "sortOrder": sortOrder,
      "search": search
    },
    format: 'csv'
  }
  const apiUrl = `/store/ch/query`
  api
    .postRequestOut(`${apiUrl}`, data)
    .then((res) => {
      handleResponse(res, dispatch, GET_HUB_DASHBOARD_CLIENT_WISE_COUNT)
    })
    .catch((err) => handleError(err, dispatch));
};

export const getTopologyHubTableData = (dpId, startDate, endDate, page, limit, interval, vessels, sort, sortOrder, search, siteId, agentId, deviceId) => (dispatch) => {
  dispatch({ type: SET_TOPOLOGY_HUB_TABLE, payload: true });
  const data: any = {
    query: 'TOPOLOGY_HUB_TABLE',
    named: true,
    parameters: {
      "dp_id": dpId,
      "pageoffset": ((page - 1) * limit),
      "pagesize": limit,
      "start_time": startDate,
      "end_time": endDate,
      "interval": getIntervalInNewFormat(interval),
      "apps_interval": getIntervalInNewFormat(interval),
      "avail_window": getAvilWindow(interval),
      "site_ids": vessels && vessels.map((vs) => `${vs.k4Id}`).join(','),
      "sortColumn": sort,
      "sortOrder": sortOrder,
      "search": search,
      "k4Id_filter": `WHERE server_location_id = '${siteId}'`,
      "agent_filter": `AND client_device_id = '${agentId}'`,
      "device_id": deviceId
    },
    format: 'json'
  }
  const apiUrl = `/store/ch/query`
  api
    .postRequestOut(`${apiUrl}`, data)
    .then((res) => {
      handleResponse(res, dispatch, GET_TOPOLOGY_HUB_TABLE)
    })
    .catch((err) => handleError(err, dispatch));
};

export const getHubDashboardClietTableData = (server_location_id, client_device_id, interval) => (dispatch) => {
  dispatch({ type: SET_HUB_DASHBOARD_CARRIER_CLIENT_TABLE, payload: true });
  const clientData: any = {
    query: 'HUB_DASHBOARD_CARRIER_CLIENT_TABLE_V2',
    named: true,
    parameters: {
      "server_location_id": server_location_id,
      "client_device_id": client_device_id,
      "interval": getIntervalInNewFormat(interval)
    },
    format: 'json'
  }
  const apiUrl = `/store/ch/query`
  api
    .postRequestOut(`${apiUrl}`, clientData)
    .then((res) => {
      handleResponse(res, dispatch, GET_HUB_DASHBOARD_CARRIER_CLIENT_TABLE)
    })
    .catch((err) => handleError(err, dispatch));
};

export const getHubDashboardServiceTableData = (server_location_id, agent_id, interval, deviceId) => (dispatch) => {
  dispatch({ type: SET_HUB_DASHBOARD_CARRIER_SERVICE_VRF_TABLE, payload: true });
  const serviceTable: any = {
    query: 'HUB_DASHBOARD_TOPOLOGY_SERVICE_VRF',
    named: true,
    parameters: {
      "server_location_id": server_location_id,
      "agent_id": `AND agent_id = '${agent_id}'`,
      "interval": getIntervalInNewFormat(interval),
      "device_id": deviceId
    },
    format: 'json'
  }
  const apiUrl = `/store/ch/query`
  api
    .postRequestOut(`${apiUrl}`, serviceTable)
    .then((res) => {
      handleResponse(res, dispatch, GET_HUB_DASHBOARD_CARRIER_SERVICE_VRF_TABLE)
    })
    .catch((err) => handleError(err, dispatch));
};

export const getCarrierHubSDWANStatusChart = (startDate, endDate, agentId, wanNames, interval, hubs, deviceId) => (dispatch) => {
  dispatch({ type: SET_CARRIER_HUB_SDWAN_STATUS, payload: true });
  const statusData: any = {
    query: 'CARRIER_HUB_SDWAN_STATUS',
    named: true,
    format: 'chart',
    groupBy: "interfaceDeviceAlias",
    tsColumn: "tb",
    series: "status",
    parameters: {
      startTime: startDate,
      endTime: endDate,
      agent_id: agentId,
      wan_name: `${wanNames?.map((wan:any) => `'${wan}'`).join(",")}`,
      intervalNum: interval.split(' ')[0],
      interval: interval.split(' ')[1],
      hub_ids: `and location_id IN(${hubs?.map(id => `'${id}'`).join(',')})`,
      device_id: deviceId
    }
  }
  const apiUrl = `/store/ch/query`
  api
    .postRequestOut(`${apiUrl}`, statusData)
    .then((res) => {
      handleResponse(res, dispatch, GET_CARRIER_HUB_SDWAN_STATUS)
    })
    .catch((err) => handleError(err, dispatch));
};

export const getCarrierHubSDWANLatencyChart = (startDate, endDate, agentId, wanNames, interval, hubs, deviceId) => (dispatch) => {
  dispatch({ type: SET_CARRIER_HUB_SDWAN_LATENCY, payload: true });
  const latencyData: any = {
    query: 'CARRIER_HUB_SDWAN_LATENCY',
    named: true,
    format: 'chart',
    groupBy: "interfaceDeviceAlias",
    tsColumn: "tb",
    series: "latency",
    parameters: {
      startTime: startDate,
      endTime: endDate,
      agent_id: agentId,
      wan_name: `${wanNames?.map((wan:any) => `'${wan}'`).join(",")}`,
      intervalNum: interval.split(' ')[0],
      interval: interval.split(' ')[1],
      hub_ids: `and location_id IN(${hubs?.map(id => `'${id}'`).join(',')})`,
      device_id: deviceId
    }
  }
  const apiUrl = `/store/ch/query`
  api
    .postRequestOut(`${apiUrl}`, latencyData)
    .then((res) => {
      handleResponse(res, dispatch, GET_CARRIER_HUB_SDWAN_LATENCY)
    })
    .catch((err) => handleError(err, dispatch));
};

export const getCarrierHubSDWANDataRatesChart = (startDate, endDate, agentId, wanNames, interval, hubs, deviceId) => (dispatch) => {
  dispatch({ type: SET_CARRIER_HUB_SDWAN_DATA_RATES, payload: true });
  const dataRates: any = {
    query: 'CARRIER_HUB_SDWAN_DATA_RATES',
    named: true,
    format: 'chart',
    groupBy: "interfaceDeviceAlias",
    tsColumn: "tb",
    series: "avgDownSpeed,avgUpSpeed",
    parameters: {
      startTime: startDate,
      endTime: endDate,
      agent_id: agentId,
      wan_name: `${wanNames?.map((wan:any) => `'${wan}'`).join(",")}`,
      intervalNum: interval.split(' ')[0],
      interval: interval.split(' ')[1],
      hub_ids: `and location_id IN(${hubs?.map(id => `'${id}'`).join(',')})`,
      device_id: deviceId
    }
  }
  const apiUrl = `/store/ch/query`
  api
    .postRequestOut(`${apiUrl}`, dataRates)
    .then((res) => {
      handleResponse(res, dispatch, GET_CARRIER_HUB_SDWAN_DATA_RATES)
    })
    .catch((err) => handleError(err, dispatch));
};

export const getCarrierHubSDWANUsageChart = (startDate, endDate, agentId, wanNames, interval, hubs, deviceId) => (dispatch) => {
  dispatch({ type: SET_CARRIER_HUB_SDWAN_USAGE, payload: true });
  const dataRates: any = {
    query: 'CARRIER_HUB_SDWAN_USAGE',
    named: true,
    format: 'chart',
    groupBy: "interfaceDeviceAlias",
    tsColumn: "tb",
    series: "download,upload",
    parameters: {
      startTime: startDate,
      endTime: endDate,
      agent_id: agentId,
      wan_name: `${wanNames?.map((wan: any) => `'${wan}'`).join(",")}`,
      intervalNum: interval.split(' ')[0],
      interval: interval.split(' ')[1],
      hub_ids: `and location_id IN(${hubs?.map(id => `'${id}'`).join(',')})`,
      device_id: deviceId
    }
  }
  const apiUrl = `/store/ch/query`
  api
    .postRequestOut(`${apiUrl}`, dataRates)
    .then((res) => {
      handleResponse(res, dispatch, GET_CARRIER_HUB_SDWAN_USAGE)
    })
    .catch((err) => handleError(err, dispatch));
};

export const getCarrierHubSDWANHubsChart = (startDate, endDate, agentId, wanNames, interval, hubs, deviceId) => (dispatch) => {
  dispatch({ type: SET_CARRIER_HUB_SDWAN_HUBS, payload: true });
  const dataRates: any = {
    query: 'CARRIER_HUB_SDWAN_HUBS',
    named: true,
    format: 'csv',
    groupBy: 'hub_name',
    parameters: {
      startTime: startDate,
      endTime: endDate,
      agent_id: agentId,
      wan_name: `${wanNames?.map((wan: any) => `'${wan}'`).join(",")}`,
      intervalNum: interval.split(' ')[0],
      interval: interval.split(' ')[1],
      hub_ids: `and location_id IN(${hubs?.map(id => `'${id}'`).join(',')})`,
      device_id: deviceId
    }
  }
  const apiUrl = `/store/ch/query`
  api
    .postRequestOut(`${apiUrl}`, dataRates)
    .then((res) => {
      handleResponse(res, dispatch, GET_CARRIER_HUB_SDWAN_HUBS)
    })
    .catch((err) => handleError(err, dispatch));
};

export const getCarrierHubAgentDropdownList = () => (dispatch) => {
  dispatch({ type: SET_CARRIER_HUB_AGENT_DROPDOWN, payload: true });
  const agents: any = {
    query: 'CARRIER_HUB_AGENT_DROPDOWN',
    named: true,
    format: 'json',
  }
  const apiUrl = `/store/ch/query`
  api
    .postRequestOut(`${apiUrl}`, agents)
    .then((res) => {
      handleResponse(res, dispatch, GET_CARRIER_HUB_AGENT_DROPDOWN)
    })
    .catch((err) => handleError(err, dispatch));
};

export const getCarrierHubWanDropdownList = (agentId, deviceId) => (dispatch) => {
  dispatch({ type: SET_CARRIER_HUB_WAN_DROPDOWN, payload: true });
  const wans: any = {
    query: 'CARRIER_HUB_WAN_DROPDOWN',
    named: true,
    format: 'json',
    parameters: {
      agent_id: agentId,
      device_id: deviceId
    }
  }
  const apiUrl = `/store/ch/query`
  api
    .postRequestOut(`${apiUrl}`, wans)
    .then((res) => {
      handleResponse(res, dispatch, GET_CARRIER_HUB_WAN_DROPDOWN)
    })
    .catch((err) => handleError(err, dispatch));
};

export const getCarrierHubsDropdownList = (startDate, endDate, agentId, deviceId) => (dispatch) => {
  dispatch({ type: SET_CARRIER_HUB_SDWAN_HUB_DROPDOWN, payload: true });
  const hubs: any = {
    query: 'CARRIER_HUB_SDWAN_HUB_DROPDOWN',
    named: true,
    format: 'json',
    parameters: {
      startTime: startDate,
      endTime: endDate,
      agent_id: agentId,
      device_id: deviceId
    }
  }
  const apiUrl = `/store/ch/query`
  api
    .postRequestOut(`${apiUrl}`, hubs)
    .then((res) => {
      handleResponse(res, dispatch, GET_CARRIER_HUB_SDWAN_HUB_DROPDOWN)
    })
    .catch((err) => handleError(err, dispatch));
};

// agent config apis

// wan
export const createAgentWan = ( dpId, payload ) => async (dispatch) => {
  dispatch({ type: CREATE_AGENT_WAN_LOADING, payload: true });
  let URL = `/v1/api/dp/${dpId}/wans`
  try {
    let res = await api.agentController.post(URL, payload);
    dispatch({ type: CREATE_AGENT_WAN_LOADING, payload: false })
    handleResponseObj(res?.data, dispatch, CREATE_AGENT_WAN)
  } catch (error) {
    dispatch({ type: CREATE_AGENT_WAN_LOADING, payload: false })
    handleError(error, dispatch)
  }
};

export const updateAgentWan = ( dpId, wanId, payload ) => async (dispatch) => {
  dispatch({ type: UPDATE_AGENT_WAN_LOADING, payload: true });
  let URL = `/v1/api/dp/${dpId}/wans/${wanId}`
  try {
    let res = await api.agentController.put(URL, payload);
    dispatch({ type: UPDATE_AGENT_WAN_LOADING, payload: false })
    handleResponseObj(res?.data, dispatch, UPDATE_AGENT_WAN)
  } catch (error) {
    dispatch({ type: UPDATE_AGENT_WAN_LOADING, payload: false })
    handleError(error, dispatch)
  }
};

export const deleteAgentWan = ( dpId, wanId ) => async (dispatch) => {
  dispatch({ type: DELETE_AGENT_WAN_LOADING, payload: true });
  let URL = `/v1/api/dp/${dpId}/wans/${wanId}`
  try {
    let res = await api.agentController.delete(URL);
    dispatch({ type: DELETE_AGENT_WAN_LOADING, payload: false })
    handleResponseObj(res?.data, dispatch, DELETE_AGENT_WAN)
  } catch (error) {
    dispatch({ type: DELETE_AGENT_WAN_LOADING, payload: false })
    handleError(error, dispatch)
  }
};

export const getAgentWans = ( dpId ) => async (dispatch) => {
  dispatch({ type: GET_AGENT_WANS_LOADING, payload: true });
  let URL = `/v1/api/dp/${dpId}/wans`
  try {
    let res = await api.agentController.get(URL);
    dispatch({ type: GET_AGENT_WANS_LOADING, payload: false })
    handleResponseObj(res?.data, dispatch, GET_AGENT_WANS)
  } catch (error) {
    dispatch({ type: GET_AGENT_WANS_LOADING, payload: false })
    handleError(error, dispatch)
  }
};


// wan profile
export const createAgentWanProfile = ( dpId, payload ) => async (dispatch) => {
  dispatch({ type: CREATE_AGENT_WAN_PROFILE_LOADING, payload: true });
  let URL = `/v1/api/dp/${dpId}/wanprofiles`
  try {
    let res = await api.agentController.post(URL, payload);
    dispatch({ type: CREATE_AGENT_WAN_PROFILE_LOADING, payload: false })
    handleResponseObj(res?.data, dispatch, CREATE_AGENT_WAN_PROFILE)
  } catch (error) {
    dispatch({ type: CREATE_AGENT_WAN_PROFILE_LOADING, payload: false })
    handleError(error, dispatch)
  }
};

export const updateAgentWanProfile = ( dpId, wanBalancerId, payload ) => async (dispatch) => {
  dispatch({ type: UPDATE_AGENT_WAN_PROFILE_LOADING, payload: true });
  let URL = `/v1/api/dp/${dpId}/wanprofiles/${wanBalancerId}`
  try {
    let res = await api.agentController.put(URL, payload);
    dispatch({ type: UPDATE_AGENT_WAN_PROFILE_LOADING, payload: false })
    handleResponseObj(res?.data, dispatch, UPDATE_AGENT_WAN_PROFILE)
  } catch (error: any) {
    dispatch({ type: UPDATE_AGENT_WAN_PROFILE_LOADING, payload: false })
    handleError(error, dispatch)
  }
};

export const deleteAgentWanProfile = ( dpId, wanBalancerId ) => async (dispatch) => {
  dispatch({ type: DELETE_AGENT_WAN_PROFILE_LOADING, payload: true });
  let URL = `/v1/api/dp/${dpId}/wanprofiles/${wanBalancerId}`
  try {
    let res = await api.agentController.delete(URL);
    dispatch({ type: DELETE_AGENT_WAN_PROFILE_LOADING, payload: false })
    handleResponseObj(res?.data, dispatch, DELETE_AGENT_WAN_PROFILE)
  } catch (error) {
    dispatch({ type: DELETE_AGENT_WAN_PROFILE_LOADING, payload: false })
    handleError(error, dispatch)
  }
};


export const getAgentWanProfiles = ( dpId ) => async (dispatch) => {
  dispatch({ type: GET_AGENT_WAN_PROFILES_LOADING, payload: true });
  let URL = `/v1/api/dp/${dpId}/wanprofiles`
  try {
    let res = await api.agentController.get(URL);
    dispatch({ type: GET_AGENT_WAN_PROFILES_LOADING, payload: false })
    handleResponseObj(res?.data, dispatch, GET_AGENT_WAN_PROFILES)
  } catch (error) {
    dispatch({ type: GET_AGENT_WAN_PROFILES_LOADING, payload: false })
    handleError(error, dispatch)
  }
};


// hub
export const createAgentHub = ( dpId, payload ) => async (dispatch) => {
  dispatch({ type: CREATE_AGENT_HUB_LOADING, payload: true });
  let URL = `/v1/api/dp/${dpId}/hubs`
  try {
    let res = await api.agentController.post(URL, payload);
    dispatch({ type: CREATE_AGENT_HUB_LOADING, payload: false })
    handleResponseObj(res?.data, dispatch, CREATE_AGENT_HUB)
  } catch (error) {
    dispatch({ type: CREATE_AGENT_HUB_LOADING, payload: false })
    handleError(error, dispatch)
  }
};

export const getAgentHubs = ( dpId ) => async (dispatch) => {
  dispatch({ type: GET_AGENT_HUBS_LOADING, payload: true });
  let URL = `/v1/api/dp/${dpId}/hubs`
  try {
    let res = await api.agentController.get(URL);
    dispatch({ type: GET_AGENT_HUBS_LOADING, payload: false })
    handleResponseObj(res?.data, dispatch, GET_AGENT_HUBS)
  } catch (error) {
    dispatch({ type: GET_AGENT_HUBS_LOADING, payload: false })
    handleError(error, dispatch)
  }
};

export const getAgentHubsPerService = ( dpId, serviceName ) => async (dispatch) => {
  dispatch({ type: GET_AGENT_HUBS_PER_SERVICE_LOADING, payload: true });
  let URL = `/v1/api/dp/${dpId}/services/${serviceName}/hubs`
  try {
    let res = await api.agentController.get(URL);
    dispatch({ type: GET_AGENT_HUBS_PER_SERVICE_LOADING, payload: false })
    handleResponseObj(res?.data, dispatch, GET_AGENT_HUBS_PER_SERVICE)
  } catch (error) {
    dispatch({ type: GET_AGENT_HUBS_PER_SERVICE_LOADING, payload: false })
    handleError(error, dispatch)
  }
};


// service vlan
export const createAgentServiceVlan = ( dpId, payload ) => async (dispatch) => {
  dispatch({ type: CREATE_AGENT_SERVICE_VLAN_LOADING, payload: true });
  let URL = `/v1/api/dp/${dpId}/service_vlans`
  try {
    let res = await api.agentController.post(URL, payload);
    dispatch({ type: CREATE_AGENT_SERVICE_VLAN_LOADING, payload: false })
    handleResponseObj(res?.data, dispatch, CREATE_AGENT_SERVICE_VLAN)
  } catch (error) {
    dispatch({ type: CREATE_AGENT_SERVICE_VLAN_LOADING, payload: false })
    handleError(error, dispatch)
  }
};

export const getAgentServiceVlans = ( dpId ) => async (dispatch) => {
  dispatch({ type: GET_AGENT_SERVICE_VLANS_LOADING, payload: true });
  let URL = `/v1/api/dp/${dpId}/service_vlans`
  try {
    let res = await api.agentController.get(URL);
    dispatch({ type: GET_AGENT_SERVICE_VLANS_LOADING, payload: false })
    handleResponseObj(res?.data, dispatch, GET_AGENT_SERVICE_VLANS)
  } catch (error) {
    dispatch({ type: GET_AGENT_SERVICE_VLANS_LOADING, payload: false })
    handleError(error, dispatch)
  }
};

// route
export const createAgentRoute = ( dpId, payload ) => async (dispatch) => {
  dispatch({ type: CREATE_AGENT_ROUTE_LOADING, payload: true });
  let URL = `/v1/api/dp/${dpId}/routes`
  try {
    let res = await api.agentController.post(URL, payload);
    dispatch({ type: CREATE_AGENT_ROUTE_LOADING, payload: false })
    handleResponseObj(res?.data, dispatch, CREATE_AGENT_ROUTE)
  } catch (error) {
    dispatch({ type: CREATE_AGENT_ROUTE_LOADING, payload: false })
    handleError(error, dispatch)
  }
};

export const getAgentRoutes = ( dpId ) => async (dispatch) => {
  dispatch({ type: GET_AGENT_ROUTES_LOADING, payload: true });
  let URL = `/v1/api/dp/${dpId}/routes`
  try {
    let res = await api.agentController.get(URL);
    dispatch({ type: GET_AGENT_ROUTES_LOADING, payload: false })
    handleResponseObj(res?.data, dispatch, GET_AGENT_ROUTES)
  } catch (error) {
    dispatch({ type: GET_AGENT_ROUTES_LOADING, payload: false })
    handleError(error, dispatch)
  }
};

// service
export const createAgentService = ( dpId, payload ) => async (dispatch) => {
  dispatch({ type: CREATE_AGENT_SERVICE_LOADING, payload: true });
  let URL = `/v1/api/dp/${dpId}/services/hubs`
  try {
    let res = await api.agentController.post(URL, payload);
    dispatch({ type: CREATE_AGENT_SERVICE_LOADING, payload: false })
    handleResponseObj(res?.data, dispatch, CREATE_AGENT_SERVICE)
  } catch (error) {
    dispatch({ type: CREATE_AGENT_SERVICE_LOADING, payload: false })
    handleError(error, dispatch)
  }
};

export const updateAgentService = ( dpId, serviceName, payload ) => async (dispatch) => {
  dispatch({ type: UPDATE_AGENT_SERVICE_LOADING, payload: true });
  let URL = `/v1/api/dp/${dpId}/services/hubs/name/${serviceName}`
  try {
    let res = await api.agentController.put(URL, payload);
    dispatch({ type: UPDATE_AGENT_SERVICE_LOADING, payload: false })
    handleResponseObj(res?.data, dispatch, UPDATE_AGENT_SERVICE)
  } catch (error) {
    dispatch({ type: UPDATE_AGENT_SERVICE_LOADING, payload: false })
    handleError(error, dispatch)
  }
};

export const getAgentServices = ( dpId ) => async (dispatch) => {
  dispatch({ type: GET_AGENT_SERVICES_LOADING, payload: true });
  let URL = `/v1/api/dp/${dpId}/services`
  try {
    let res = await api.agentController.get(URL);
    dispatch({ type: GET_AGENT_SERVICES_LOADING, payload: false })
    handleResponseObj(res?.data, dispatch, GET_AGENT_SERVICES)
  } catch (error) {
    dispatch({ type: GET_AGENT_SERVICES_LOADING, payload: false })
    handleError(error, dispatch)
  }
};

// profile
export const createAgentProfile = ( dpId, payload ) => async (dispatch) => {
  dispatch({ type: CREATE_AGENT_PROFILE_LOADING, payload: true });
  let URL = `/v1/api/dp/${dpId}/profiles`
  try {
    let res = await api.agentController.post(URL, payload);
    dispatch({ type: CREATE_AGENT_PROFILE_LOADING, payload: false })
    handleResponseObj(res?.data, dispatch, CREATE_AGENT_PROFILE)
  } catch (error) {
    dispatch({ type: CREATE_AGENT_PROFILE_LOADING, payload: false })
    handleError(error, dispatch)
  }
};

export const getAgentProfiles = ( dpId ) => async (dispatch) => {
  dispatch({ type: GET_AGENT_PROFILES_LOADING, payload: true });
  let URL = `/v1/api/dp/${dpId}/profiles`
  try {
    let res = await api.agentController.get(URL);
    dispatch({ type: GET_AGENT_PROFILES_LOADING, payload: false })
    handleResponseObj(res?.data, dispatch, GET_AGENT_PROFILES)
  } catch (error) {
    dispatch({ type: GET_AGENT_PROFILES_LOADING, payload: false })
    handleError(error, dispatch)
  }
};

// agent config
export const createAgentConfig = ( dpId, payload ) => async (dispatch) => {
  dispatch({ type: CREATE_AGENT_CONFIG_LOADING, payload: true });
  let URL = `/v1/api/dp/${dpId}/agentconfig`
  try {
    let res = await api.agentController.post(URL, payload);
    dispatch({ type: CREATE_AGENT_CONFIG_LOADING, payload: false })
    handleResponseObj(res?.data, dispatch, CREATE_AGENT_CONFIG)
  } catch (error) {
    dispatch({ type: CREATE_AGENT_CONFIG_LOADING, payload: false })
    handleError(error, dispatch)
  }
};

export const getAgentConfigs = ( dpId ) => async (dispatch) => {
  dispatch({ type: GET_AGENT_CONFIGS_LOADING, payload: true });
  let URL = `/v1/api/dp/${dpId}/agentconfig`
  try {
    let res = await api.agentController.get(URL);
    dispatch({ type: GET_AGENT_CONFIGS_LOADING, payload: false })
    handleResponseObj(res?.data, dispatch, GET_AGENT_CONFIGS)
  } catch (error) {
    dispatch({ type: GET_AGENT_ROUTES_LOADING, payload: false })
    handleError(error, dispatch)
  }
};

// agent
export const createAgent = ( dpId, payload ) => async (dispatch) => {
  dispatch({ type: CREATE_AGENT_LOADING, payload: true });
  let URL = `/v1/api/dp/${dpId}/agent`
  try {
    let res = await api.agentController.post(URL, payload);
    dispatch({ type: CREATE_AGENT_LOADING, payload: false })
    handleResponseObj(res?.data, dispatch, CREATE_AGENT)
  } catch (error) {
    dispatch({ type: CREATE_AGENT_LOADING, payload: false })
    handleError(error, dispatch)
  }
};

export const getAgents = ( dpId, parameters: any ) => async (dispatch) => {
  dispatch({ type: GET_AGENTS_LOADING, payload: true });
  let query = ""
  if (parameters) {
    if (parameters?.pageSize) {
      query += `pageSize=${parameters?.pageSize}&`
    } 
    if (parameters?.page) {
      query += `page=${parameters?.page}&`
    }
    if (parameters?.search) {
      query += `search=${parameters?.search}&`
    }
    if (parameters?.sortBy) {
      query += `sortBy=${parameters?.sortBy}&`
    }
    if (parameters?.sortOrder) {
      query += `sortOrder=${parameters?.sortOrder}&`
    }

    if (query.endsWith("&")) {
        query = query.slice(0, -1);
    }
  }
  let URL = `/v1/api/dp/${dpId}/agent?${query}`
  try {
    let res = await api.agentController.get(URL);
    dispatch({ type: GET_AGENTS_LOADING, payload: false })
    handleResponseObj(res?.data, dispatch, GET_AGENTS)
  } catch (error) {
    dispatch({ type: GET_AGENTS_LOADING, payload: false })
    handleError(error, dispatch)
  }
};

export const getAgentConfigSummary = ( dpId, agentId ) => async (dispatch) => {
  dispatch({ type: GET_AGENT_CONFIG_SUMMARY_LOADING, payload: true });
  let URL = `/v1/api/dp/${dpId}/accessnetwork/agent_id/${agentId}`
  try {
    let res = await api.agentController.get(URL);
    dispatch({ type: GET_AGENT_CONFIG_SUMMARY_LOADING, payload: false })
    handleResponseObj(res?.data, dispatch, GET_AGENT_CONFIG_SUMMARY)
  } catch (error) {
    dispatch({ type: GET_AGENT_CONFIG_SUMMARY_LOADING, payload: false })
    handleError(error, dispatch)
  }
};

// access network
export const createAgentAccessNetwork = ( dpId, payload ) => async (dispatch) => {
  dispatch({ type: CREATE_AGENT_ACCESS_NETWORK_LOADING, payload: true });
  let URL = `/v1/api/dp/${dpId}/accessnetwork`
  try {
    let res = await api.agentController.post(URL, payload);
    dispatch({ type: CREATE_AGENT_ACCESS_NETWORK_LOADING, payload: false })
    handleResponseObj(res?.data, dispatch, CREATE_AGENT_ACCESS_NETWORK)
  } catch (error) {
    dispatch({ type: CREATE_AGENT_ACCESS_NETWORK_LOADING, payload: false })
    handleError(error, dispatch)
  }
};
export const updateAgentAccessNetwork = ( dpId, agentId, payload ) => async (dispatch) => {
  dispatch({ type: UPDATE_AGENT_ACCESS_NETWORK_LOADING, payload: true });
  let URL = `/v1/api/dp/${dpId}/accessnetwork/agent_id/${agentId}`
  try {
    let res = await api.agentController.put(URL, payload);
    dispatch({ type: UPDATE_AGENT_ACCESS_NETWORK_LOADING, payload: false })
    handleResponseObj(res?.data, dispatch, UPDATE_AGENT_ACCESS_NETWORK)
  } catch (error) {
    dispatch({ type: UPDATE_AGENT_ACCESS_NETWORK_LOADING, payload: false })
    handleError(error, dispatch)
  }
};


export const getAgentHubEvents = (parameters: any) => (dispatch) => {
  const apiUrl =  `/store/ch/query`
  dispatch({ type: GET_AGENT_HUB_EVENTS_LOADING, payload: true });
  const data : any = {
    query: 'AGENT_EVENTS_DASHBOARD_V2',
    named: true,
    format: "json",
    parameters: parameters,
  }
  api.postRequestOut(`${apiUrl}`, data)
  .then((res) => {
    handleResponseObj(res?.data, dispatch, GET_AGENT_HUB_EVENTS)
    dispatch({ type: GET_AGENT_HUB_EVENTS_LOADING, payload: false });
  })
  .catch((err) => {
    handleError(err, dispatch)
    dispatch({ type: GET_AGENT_HUB_EVENTS_LOADING, payload: false });
  });
};

export const getAgentHubEventFilters = (parameters: any) => (dispatch) => {
  const apiUrl =  `/store/ch/query`
  dispatch({ type: GET_AGENT_HUB_EVENT_FILTERS_LOADING, payload: true });
  const data : any = {
    query: 'AGENT_EVENTS_DASHBOARD_FILTERS_V2',
    named: true,
    parameters: parameters,
  }
  api.postRequestOut(`${apiUrl}`, data)
  .then((res) => {
    handleResponseObj(res?.data, dispatch, GET_AGENT_HUB_EVENT_FILTERS)
    dispatch({ type: GET_AGENT_HUB_EVENT_FILTERS_LOADING, payload: false });
  })
  .catch((err) => {
    handleError(err, dispatch)
    dispatch({ type: GET_AGENT_HUB_EVENT_FILTERS_LOADING, payload: false });
  });
};
