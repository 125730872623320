import { Fragment, ReactNode, SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { AddCircle } from "@material-ui/icons";
import { Autocomplete, Button, Grid, TextField, Select, MenuItem, Divider, Box, TableContainer, Checkbox, Chip, Table, TableHead, TableRow, TableBody, TableCell, Tooltip } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { RemoveCircle } from '@mui/icons-material';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { useDispatch } from 'react-redux';
import { PER_PAGE_ITEMS, DEFAULT_PAGE_SIZE, NEW_PER_PAGE_ITEMS } from "../../../constants/Constants";
import { Pagination } from "../../Pagination";
import { getAgentWans } from "../../../actions/Users/authenticateHub";

import _ from "lodash";



const ServiceItemsPopup = (props) => {
    const { authReducer, selectedService, hubs, setHubs, wanProfiles, getAgentWans, currentOrg, limit, page, setLimit, setPage, paginatedHubs  } = props;
    
    const updateHubItem = (id, updatedAction) => {
        const globalIndex = (page - 1) * limit + id;
        setHubs(
            hubs?.map((action, index) =>
                index === globalIndex ? updatedAction : action
            )
        );
    };
    const removeHubItem = (id: string) => {
        const updatedActions = hubs.filter((_, index) => index.toString() != id);
        setHubs(updatedActions);

    };

    const handlePagination = (e: any, value: number) => {
        setPage(value)
    }

    const handlePerPageChange = (e: any) => {
        const value = parseInt(e.target.value);
        setLimit(value)
    }

    return (
        <div>
            <div style={{ marginBottom: "10px", height: "25px", display: "flex", justifyContent: "center", alignItems: "center", fontFamily: "Inter", }}>{selectedService}</div>
            <div style={{}}>
                <Grid container className="agent-table-container-edgeEvent" >
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Box>
                            <Pagination
                                rowsPerPageOptions={NEW_PER_PAGE_ITEMS}
                                count={hubs?.length || 0}
                                rowsPerPage={limit || 0}
                                page={page || 1}
                                onPageChange={handlePagination}
                                onRowsPerPageChange={handlePerPageChange}
                            />
                        </Box>
                        <TableContainer className="agent-table-tableContainer">
                            <Table aria-label="simple table">
                                <TableHead className="tableHead agent-table-tableHead">
                                    <TableRow className="agent-table-tableRow">
                                        <TableCell className="agent-table-tableData font-wt-900">HUB NAME</TableCell>
                                        <TableCell className="agent-table-tableData font-wt-900">AGENT WAN BALANCER PROFILE</TableCell>
                                        <TableCell className="agent-table-tableData font-wt-900">AGENT WAN NAME</TableCell>
                                        <TableCell className="agent-table-tableData font-wt-900">HUB INTERFACE ADDRESS</TableCell>
                                        <TableCell className="agent-table-tableData font-wt-900">HUB REACHABLE ADDRESS</TableCell>
                                        <TableCell className="agent-table-tableData font-wt-900">AGENT WAN TYPE</TableCell>
                                    </TableRow>
                                </TableHead>
                                {paginatedHubs?.length > 0 &&
                                    <TableBody >
                                        {
                                            paginatedHubs?.length > 0 && paginatedHubs.map((action, index) =>
                                                <HubItem
                                                    key={index}
                                                    id={index}
                                                    data={action}
                                                    onUpdate={updateHubItem}
                                                    onRemove={removeHubItem}
                                                    hubs={hubs}
                                                    wanProfiles={wanProfiles}
                                                    getAgentWans={getAgentWans}
                                                    currentOrg={currentOrg}
                                                    authReducer={authReducer}
                                                    page={page}
                                                    limit={limit}
                                                    selectedService={selectedService}
                                                />
                                            )
                                        }
                                    </TableBody>}
                                {(paginatedHubs?.length === 0) && <TableBody>
                                    <TableRow>
                                        <TableCell colSpan={12} className="agent-table-noDataAvailableCss" align="center">No data available</TableCell>
                                    </TableRow>
                                </TableBody>}
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}

const HubItem = (props) => {
    const { hubs, id, data, onUpdate, wanProfiles, page, limit, selectedService } = props;
    const handleChange = (field: string, value: any) => {
        onUpdate(id, { ...data, [field]: value || '' });
    };

    const handleChangeMultiple = (values) => {
        onUpdate(id, { ...data, ...values });
    };

    const [wanProfileList, setWanProfileList] = useState<any>([])
    const [interfaceAddresses, setInterfaceAddresses] = useState<any>([])

    const updateUdpv1Item = (id, updatedAction) => {
        let udpv1 = data?.udpv1_wans?.map((action, index) =>
            index === id ? updatedAction : action
        )

        handleChange("udpv1_wans", udpv1)
    };

    const [wans, setWans] = useState<any>([])

    useEffect(() => {
        if (data?.udpv1_wans?.length) {
            let existingWans: string[] = []
            for (let item of data?.udpv1_wans) {
                existingWans.push(...item?.wan_names)
            }

            let uniqueArray = existingWans.filter((value, index, self) => self.indexOf(value) === index);
            handleUpdateWanList(uniqueArray)
        } 
    }, [data?.udpv1_wans, page, limit, selectedService])

    useEffect(() => {
        if (data?.wan_balancer_profile && data?.is_wan_balancer_updated) {
            handleUpdateWanBalancerProfile()
        }

        if (data?.wan_balancer_profile) {
            handleCollectInterfaceAddress(data?.udpv1_wans)
        } else {
            setWans([])
            handleUpdateWanBalancerProfile()
        }
    }, [data?.wan_balancer_profile])

    useEffect(() => {
        if (wanProfiles?.length) {
            let existingItems: any = []
            for (let item of wanProfiles) {
                existingItems.push(item?.name)
            }
            setWanProfileList(existingItems)
        }
    }, [wanProfiles])


    const handleCollectInterfaceAddress = (udpv1_wans: any) => {
        let address: string[] = []
        if (udpv1_wans?.length) {
            for (let item of udpv1_wans) {
                address.push(item?.interface_addr)
            }
        }

        setInterfaceAddresses(address)
    }

    const handleUpdateWanBalancerProfile = () => {
        if (data?.udpv1_wans) {
            let updatedUdpv1Wans: any = []
            let udpv1Wans = data?.udpv1_wans
            for (let index in udpv1Wans) {
                let item = udpv1Wans[index]
                item.wan_names = []
                item.wan_type = ""
                updatedUdpv1Wans.push(item)
            }
            handleChange("udpv1_wans", updatedUdpv1Wans)
        }
    }

    const handleUpdateWanList = (existingWans: string[]) => {

        let allWanList = handleFetchAllWanList(data?.wan_balancer_profile)
        let newWans = allWanList.filter(wan => !existingWans.includes(wan));
        setWans(newWans)
    }

    const handleFetchAllWanList = (wanBalancerProfile) => {
        let wanList: string[] = []
        for (let item of wanProfiles) {
            if (item?.name == wanBalancerProfile) {
                if (item?.client_config?.levels) {
                    for (let level of item?.client_config?.levels) {
                        if (level?.names) {
                            wanList.push(...level?.names)
                        }
                    }
                }
            }
        }
        return wanList
    }

    return (
        <>
            <TableRow className="agent-table-tabelRow">
                <Tooltip title={<span style={{ fontSize: "12px" }}>{data?.hub_name}</span>}>
                    <TableCell className="agent-table-tableCell agent-table-tableCell-source agent-table-elipse" title={data?.hub_name} sx={{ maxWidth: "50px", width: "50px" }}>{data?.hub_name}</TableCell>
                </Tooltip>
                <TableCell className="agent-table-tableCell agent-table-tableCell-source" sx={{ maxWidth: "100px", width: "100px" }}>
                    <Autocomplete
                        id="select-users"
                        size='small'
                        value={data?.wan_balancer_profile || ""}
                        onChange={(e, newValue: any) => {handleChangeMultiple({ wan_balancer_profile: newValue, is_wan_balancer_updated: true }) }}
                        options={wanProfileList}
                        getOptionLabel={(option) => option}
                        renderInput={(params) => <TextField {...params} label="select" />}
                        renderOption={(props, option) => <li {...props} key={option}>{option}</li>}
                        sx={{ width: "100%", maxWidth: "100%" }}
                        style={{ width: "100%", maxWidth: "100%" }}
                    />
                </TableCell>
                {
                    data?.udpv1_wans?.map((item: any, index: number) => {
                        if (index !== 0) return null
                        return (
                            <HubInterfaceItem
                                key={index}
                                id={index}
                                data={item}
                                onUpdateUdpv1={updateUdpv1Item}
                                hubs={hubs}
                                wans={wans}
                                interfaceAddressList={interfaceAddresses}
                                selectedWanBalancer={data?.wan_balancer_profile}
                            />
                        )
                    })
                }
            </TableRow>
            {data?.wan_balancer_profile ? (
                data?.udpv1_wans?.map((item: any, index: number) => {
                    if (index === 0) return null
                    return (
                        <TableRow className="agent-table-tabelRow">
                            <TableCell colSpan={2} />
                            <HubInterfaceItem
                                key={index}
                                id={index}
                                data={item}
                                wanBalancerprofile={data?.agent_wan_balancer_group_alias}
                                onUpdateUdpv1={updateUdpv1Item}
                                hubs={hubs}
                                wans={wans}
                                selectedWanBalancer={data?.wan_balancer_profile}
                            />
                        </TableRow>
                    )
                })
            ) : null}
        </>
    )
}

const HubInterfaceItem = (props: any) => {
    const { id, data, wans, selectedWanBalancer, interfaceAddressList, onUpdateUdpv1 } = props;
    const handleChangeUdpv1 = (field: string, value: any) => {
        onUpdateUdpv1(id, { ...data, [field]: value || '' });
    };

    return (
        <>
            <TableCell className="agent-table-tableCell agent-table-tableCell-source" sx={{ width: "130px", maxWidth: "130px" }}>
                <Autocomplete
                    id="select-users"
                    size='small'
                    multiple={true}
                    disabled={!wans?.length && !data?.wan_names?.length ? true: false}
                    value={data?.wan_names || []}
                    onChange={(event, newValue: any) => {
                        handleChangeUdpv1("wan_names", newValue)
                    }}
                    options={wans}
                    getOptionLabel={(option) => option}
                    limitTags={1}
                    disableCloseOnSelect // Keeps the dropdown open when selecting items
                    renderInput={(params) => <TextField {...params} label="select" />}
                    renderOption={(props, option, { selected }) => (
                        <li {...props} key={option}>
                            <Checkbox size="small" checked={selected} />
                            {option}
                        </li>
                    )}
                    renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                            <Chip size="small" label={option} {...getTagProps({ index })} />
                        ))
                    }
                    sx={{ width: "100%", maxWidth: "100%" }}
                    style={{ width: "100%", maxWidth: "100%" }}
                />
            </TableCell>
            <TableCell className="agent-table-tableCell agent-table-tableCell-source" sx={{ maxWidth: "70px", width: "70px" }}>
                {selectedWanBalancer ? (
                    data?.interface_addr || "--"
                ) : (
                    <Autocomplete
                        id="select-users"
                        size='small'
                        value={data?.interface_addr}
                        options={interfaceAddressList}
                        getOptionLabel={(option) => option}
                        renderInput={(params) => <TextField {...params} label="select" />}
                        renderOption={(props, option) => <li {...props} key={option}>{option}</li>}
                        sx={{ width: "100%", maxWidth: "100%" }}
                        style={{ width: "100%", maxWidth: "100%" }}
                        disabled={true}
                    />
                )}
            </TableCell>
            <TableCell className="agent-table-tableCell agent-table-tableCell-source">
                <TextField
                    placeholder="Reachable IP"
                    value={data?.remote_addr}
                    disabled={!wans?.length && !data?.wan_names?.length ? true: false}
                    onChange={(event) => {
                        handleChangeUdpv1("remote_addr", event?.target?.value)
                    }}
                    variant="outlined"
                    size="small"
                    style={{ width: "100%" }}
                />
            </TableCell>
            <TableCell className="agent-table-tableCell agent-table-tableCell-source" sx={{ maxWidth: "70px", width: "70px" }}>
                <Autocomplete
                    id="select-users"
                    size='small'
                    value={data?.wan_type || ""}
                    disabled={!wans?.length && !data?.wan_names?.length ? true: false}
                    onChange={(event, newValue: any) => {
                        handleChangeUdpv1("wan_type", newValue)
                    }}
                    options={["LEO", "WIRED", "WIFI"]}
                    getOptionLabel={(option) => option}
                    renderInput={(params) => <TextField {...params} label="select" />}
                    renderOption={(props, option) => <li {...props} key={option}>{option}</li>}
                />
            </TableCell>
        </>
    )
}

const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
    options: state?.authReducer?.threatSeverityClassList
});

export default withRouter(
    connect(mapStateToProps, { getAgentWans })(ServiceItemsPopup)
)
