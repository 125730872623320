import React, { useEffect, useState, useRef, useMemo } from "react";
import { Box, Button, Grid, IconButton, InputAdornment, Paper, SelectChangeEvent, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip, Autocomplete, Dialog, Switch } from "@mui/material";
import { Fragment } from "react";
import ThreatKontrolDialog from "./ThreatKontrolDialog";
import Download from '../../../asset/image/DownloadUsage.svg';
import { Delete } from "@material-ui/icons";
import { withRouter, useLocation, useHistory } from "react-router-dom";
import { checkLoggedInUserAuthorizedToViewPage, convertDateTimeIntoTimezone, getDecodeURI, getEncodedURI, readablePeriod } from '../../../utils/util';
import { getThreats } from "../../../actions/Users/authenticateThreatDashboard";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from '@mui/icons-material/Close';
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { Pagination } from '../../../components/Pagination';
import { connect, useDispatch } from "react-redux";
import {CREATE_THREAT_KONTROL, UPDATE_THREAT_KONTROL, DELETE_THREAT_KONTROL} from "../../../actions/types";
import _ from "lodash";
import ascSortingSelected from "../../../asset/image/ascSortingSelected.svg";
import descSortingSelected from "../../../asset/image/descSortingSelected.svg";
import AscSort from "../../../asset/image/AscSort.svg";
import DescSort from "../../../asset/image/DescSort.svg";
import { fetchUserListAPI } from "../../../actions/Users/authenticate";
import EditThreatKontrol from "./EditThreatKontrol";
import ThreatKontrolDelete from "./ThreatKontrolDelete"; 
import {updateThreatKontrol} from "../../../actions/Users/authenticateThreatDashboard";
import { JsonDataTooltip } from "../../../components/JSONTooltip/JsonTooltip";
import { SET_THREAT_FILTERS } from "../../../actions/types";
import { CsvParameters, downloadAsCSV } from "../../../actions/Users/authenticateCsvDownload";
import DownloadCSV, {clearCsvDownload} from "../../../components/DownloadCSV";

const ENTER_KEY_CODE = 'Enter';

interface User {
    name: string;
    email: string;
}

const convertToCsv = (data: any) => {
    const [_columns, ...rows] = data;
    const csvRows: any[] = [];
    rows.forEach(row => {
        let uniqueClasses = "";
        try {
            const parsedData = JSON.parse(row[23]);
            const uniqueClassSet = new Set<string>(
                parsedData.map((item: { class: string }) => item.class)
            );
            uniqueClasses = Array.from(uniqueClassSet).join(", ");
        } catch (error) {
            console.error("Error parsing row[23] or extracting unique classes:", error);
            uniqueClasses = "No Classes";
        }
        let uniqueThreatNames = "No Threat Names";
        try {
            const parsedData = JSON.parse(row[23]);
            const uniqueThreatNameSet = new Set<string>(
                parsedData.map((item: { threat_name: string }) => item.threat_name)
            );
            uniqueThreatNames = Array.from(uniqueThreatNameSet).join(", ");
        } catch (error) {
            console.error("Error parsing row[0] or extracting threat names:", error);
        }
        csvRows.push([row[8], row[20], row[21], row[10], `${uniqueClasses}`, `${uniqueThreatNames}`, row[12], row[13], row[14], row[18] ? "Disabled" : "Enabled" ])
    })
    return [['Threat Kontrol Name', 'Site Name', 'Device Name', 'Severity', 'Classes', 'Threat Names', 'Measurement Period', 'Count', 'Action', 'Status'], ...csvRows]
};

const ThreatKontrolTable = (props: any) => {
    const { authReducer, usersList, fetchUserListAPI, updateThreatKontrol, threatList, threatFilters, getThreats, location, history, suppress } = props;
    const [threatOpen, setThreatOpen] = useState<boolean>(false);
    const [editOpen, setEditOpen] = useState<boolean>(false);
    const [deleteOpen, setDeleteOpen] = useState<boolean>(false);
    const [selectedRow, setSelectedRow] = useState({});
    const [threatDwn, setThreatDwn] = useState<boolean>(false);
    let _q = new Map<string, string>(Object.entries(getDecodeURI(location.search)));
    const [searchValue, setSearchValue] = useState(_q.has('search') ? _q.get('search') as string : '');
    const [sortColumn, setSortColumn] = useState<string>("updated_at");
    const [sortOrder, setSortOrder] = useState<string>("desc");
    const [tableParams, setTableParams] = useState<{
        page: number;
        size: number;
        search: string;
    }>({
        page: _q.has('page') ? parseInt(_q.get('page') as string) : 1,
        size: _q.has('size') ? parseInt(_q.get('size') as string) : 10,
        search: _q.has('search') ? _q.get('search') as string : '',
    });
    const [row, setRow] = useState<any>({});
    const searchTimeoutRef = useRef<NodeJS.Timeout>();
    const dispatch = useDispatch();
    const sites = [];
    const [count, setCount] = useState(0);
    const [tableData, setTableData] = useState([]);
    const doNavigate = (params) => {
        history.push({ pathname: location.pathname, search: `?${getEncodedURI(params)}` });
    };

    const handleThreatKontrol = () => {
        setThreatOpen(true)
    }

    const searchBoxKeyDown = (event: any) => {
        if (event && event.key === ENTER_KEY_CODE) {
            handleSearch(searchValue);
        }
    }

    const handleOnSearchClick = () => {
        handleSearch(searchValue);
    }

    const handleOnSearchClear = () => {
        setSearchValue('');
        handleSearch('');
    }

    const handleOnSearchChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setSearchValue(e.target.value);
        if (searchTimeoutRef.current) {
            clearTimeout(searchTimeoutRef.current);
        }
        searchTimeoutRef.current = setTimeout(() => {
            searchTimeoutRef.current = undefined;
            handleSearch(e.target.value);
        }, 1000)
    }

    const handleSearch = (value: string) => {
        setTableParams({
            ...tableParams,
            search: value,
            page: 1
        });
        let params: any = getDecodeURI(location?.search);
        value ? params.search = value : delete params.search;
        delete params.page;
        doNavigate(params);
    }
    const handleChangePage = (event: any, newPage: number) => {
        setTableParams({ ...tableParams, page: newPage });
    };
    const handleChangeSize = (event: any) => {
        setTableParams({ ...tableParams, size: event.target.value, page: 1 });
    };
    const [vessels, setVessels] = useState([]);

    const toggleSortOrder = (column) => {
        const newOrder = sortOrder === "desc" ? "asc" : "desc";
        handleColumnSort(column, newOrder); 
    };
    const handleColumnSort = (value: string, value1: string) => {
        setSortColumn(value);
        setSortOrder(value1);
    }

    const handleEdit = (row) => {
        setSelectedRow(row);
        setEditOpen(true);
    }

    const handleEnableDisable = (is_disabled, site_id, device_id, rule_id) => {
        const payload = {
            is_disabled: is_disabled, 
        };
        updateThreatKontrol(site_id, device_id, rule_id, payload);
    }

    const handleThreatDownload = () => {
        setThreatDwn(true);
    }

    const handleSiteClick = (row) => {
        let params: any = getDecodeURI(location?.search);
        row && row.k4Id ? params.k4Ids = `${row.k4Id}:${row.x}` : row && row.location_id ? params.k4Ids = `${row.location_id}:${row.site}`:  delete params.k4Ids;
        delete params.page;
        delete params.limit;
        delete params.sort;
        delete params.sortOrder;
        delete params.search;
        doNavigate(params);
    }

    const handleDeviceClick = (deviceName) => {
        let params: any = getDecodeURI(location?.search);
        params.deviceName = deviceName;
        doNavigate(params);
        dispatch({ type: SET_THREAT_FILTERS, payload: { ...threatFilters, deviceName: deviceName } });
    }

    const handleSeverityClick = (severity) => {
        let params: any = getDecodeURI(location?.search);
        params.severityType =severity;
        doNavigate(params);
        dispatch({ type: SET_THREAT_FILTERS, payload: { ...threatFilters, severityType: severity } });
    } 

    const handleClassClick = (classes) => {
        let params: any = getDecodeURI(location?.search);
        params.threatClass = classes;
        doNavigate(params);
        dispatch({ type: SET_THREAT_FILTERS, payload: { ...threatFilters, threatClass: classes } });
    }

    const users = useMemo(() => {
        return authReducer?.usersList?.data?.records?.length > 0 ? _.orderBy(authReducer?.usersList?.data?.records?.map((u: any) => {
            let _u: User = {
                name: u?.name?.givenName + " " + u?.name?.familyName,
                email: u.userName
            };
            return _u;
        }).filter(
            (u: User) => u.name.trim() !== "" && u.email.trim() !== ""
        ), 'name', 'asc') : [];
    }, [usersList]);

    useEffect(() => {
        if (authReducer?.selectedOu?.name) {
            fetchUserListAPI(authReducer.selectedOu.name, 1, 1000000, '');
        }
    }, [authReducer.selectedOu]);

    useEffect(() => {
        if (_.isEmpty(authReducer?.getVesselsListing)){
            return;
        }
        let Vessels = [];
        const filteredVessels = authReducer?.filteredVessels;
        if (filteredVessels && filteredVessels.length > 0) {
            Vessels = filteredVessels;
        } else {
            Vessels = authReducer?.getVesselsListing?.locations;
        }
        setVessels(Vessels);
        setTableParams(() => ({
            ...tableParams,
            page: 1,
            size: 10
        }));
    },[authReducer?.getVesselsListing, authReducer?.filteredVessels])

    useEffect(() => {
        if (vessels.length > 0 && !_.isEmpty(authReducer.createThreatKontrol) || !_.isEmpty(authReducer.updateThreatKontrol) || !_.isEmpty(authReducer.deleteThreatkontrol)) {
            setSortColumn("updated_at");
            getThreats({
                vessels: vessels,
                search: tableParams.search,
                sort: sortColumn,
                order: sortOrder,
                limit: 10,
                offset: 0,
                device_filter: threatFilters?.deviceName? `and device_id ilike '${threatFilters?.deviceName}'` : "",
                severity_filter: threatFilters?.severityType? `and severity ilike '${threatFilters?.severityType}'` : "",
                class_filter: threatFilters?.threatClass? `AND classes ILIKE '%${threatFilters?.threatClass}%'` : "",
                suppress: suppress
            })

            if(!_.isEmpty(authReducer.createThreatKontrol)){
                dispatch({ type: CREATE_THREAT_KONTROL, payload: {}})
            }

            if(!_.isEmpty(authReducer.updateThreatKontrol)){
                dispatch({ type: UPDATE_THREAT_KONTROL, payload: {}})
            }

            if(!_.isEmpty(authReducer.deleteThreatkontrol)){
                dispatch({ type: DELETE_THREAT_KONTROL, payload: {}})
            }
        }
    }, [authReducer.createThreatKontrol, authReducer.updateThreatKontrol, authReducer.deleteThreatkontrol])


    useEffect ( () => {
        if(vessels.length > 0 ){
            getThreats({
                vessels: vessels,
                search: tableParams.search,
                sort: sortColumn,
                order: sortOrder,
                limit: tableParams.size,
                offset: (tableParams.page-1)*tableParams.size,
                device_filter: threatFilters?.deviceName? `and device_id ilike '${threatFilters?.deviceName}'` : "",
                severity_filter: threatFilters?.severityType? `and severity ilike '${threatFilters?.severityType}'` : "",
                class_filter: threatFilters?.threatClass? `AND classes ILIKE '%${threatFilters?.threatClass}%'` : "",
                suppress: suppress
            })
        }
    },[vessels, tableParams, sortColumn, sortOrder, threatFilters, suppress])

    useEffect(() => {
            if(!_.isEmpty(threatList)) {
                const data = threatList?.hasOwnProperty('data') ? threatList?.data : {};
                const dataArr:any = [];
                setCount(data?.rows?.[0]?.[22]);
                data?.rows && data?.rows?.forEach((item, i) => {
                    if (i != 0) {
                        const itemArr = data?.columns?.reduce((acc, columItem, i) => {
                            acc[columItem] = item[i];
                            return acc;
                        }, {})
                        dataArr.push(itemArr)
                    }
                })
                setTableData(dataArr);
            }
        }, [threatList])

    const handleDownloadReport = () => {
        const params: CsvParameters = {
            type: 'QUERY_CH',
            id : "threat-kontrol-table",
            queryName: 'GET_THREATS',
            payload: {
                sites: vessels && vessels.length > 0 ? `${vessels.map((item: any) => `'${item.id}'`).join(",")}` : '',
                search: tableParams.search,
                sort: sortColumn,
                order: sortOrder,
                limit: count,
                offset: (tableParams.page-1)*tableParams.size,
                device_filter: threatFilters?.deviceName? `and device_id ilike '${threatFilters?.deviceName}'` : "",
                severity_filter: threatFilters?.severityType? `and severity ilike '${threatFilters?.severityType}'` : "",
                class_filter: threatFilters?.threatClass? `AND classes ILIKE '%${threatFilters?.threatClass}%'` : "",
                suppressed: suppress
            }    
        }
        dispatch(downloadAsCSV(params));
    }

    useEffect(() => {
        if (authReducer.csvDataDownloded && authReducer.csvDataDownloded.length > 0) {
            if (authReducer.csvDownloadId === 'threat-kontrol-table') {
                const [columns, _countRow, ...rows] = authReducer.csvDataDownloded;
                DownloadCSV(convertToCsv([columns, ...rows])
                , {
                    formatters: {
                        4: 'ESC-COMMA',
                        5: 'ESC-COMMA'
                    }
                }
                );
                return () => {
                    dispatch(clearCsvDownload())
                }
            }
        }
    }, [authReducer.csvDataDownloded])

    return (
        <Grid>
            <Grid className="threatKontrolContainer topbarWidth alignTopbarItemsCenter">
                <Grid item xs={2} sm={2} md={2} lg={2} className="searchbarMaxWidth">
                    <Box className="searchbarBox">
                        <TextField
                            id="search"
                            variant="outlined"
                            placeholder="Type something"
                            autoComplete="off"
                            size="small"
                            value={searchValue}
                            onChange={handleOnSearchChange}
                            onKeyDown={(event) => searchBoxKeyDown(event)}
                            InputLabelProps={{ className: "serachLabel" }}
                            InputProps={{
                                className: "searchbarInputs",
                                startAdornment: (
                                    <InputAdornment position="start">
                                        {!searchValue && <SearchIcon
                                            className="searchbarIconsPosition" onClick={handleOnSearchClick}
                                        />}
                                        {searchValue && <CloseIcon
                                            className="searchbarIconsPosition"
                                            onClick={handleOnSearchClear}
                                        />}
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Box>
                </Grid>
                <Button variant="outlined" onClick={handleThreatKontrol} id="threatsKontrolButton" style={{marginLeft:"16px"}}>
                    Add Threat Kontrol
                </Button>
                { count > 0 && <Grid item xs={4} sm={4} md={4} lg={4} className="marg-left-auto">
                    <div className="download-summary-btn marg-left-auto" onClick={handleDownloadReport}><img className="downloadImg" src={Download} alt="" /><Button>Download</Button></div>
                </Grid> }
                <ThreatKontrolDialog users={users} open={threatOpen} setOpen={setThreatOpen} row={row}/>
            </Grid>
            <Grid>
            <Pagination count={count} onPageChange={handleChangePage} page={tableParams.page} rowsPerPage={tableParams.size} onRowsPerPageChange={handleChangeSize} />
            <TableContainer className="usageReports-FleetDashboard loginHistory-tableContainer">
                    <Table aria-label="simple sticky table" stickyHeader>
                        <TableHead className="usageReports-tableHead config-tableRow">
                            <TableRow className="UsageReports-tableRow login-tableRow">
                                <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell new-summary-left">
                                    <span style={{ whiteSpace: "nowrap" }} className="align-items-center">
                                        <span className="login-history-dp-name font-wt-900" onClick={() => toggleSortOrder("control_name")}>THREAT KONTROL NAME</span>
                                        <Grid className="sort-icon-margin">
                                            <img src={sortColumn === "control_name" && sortOrder === "asc" ? ascSortingSelected : AscSort}
                                                className="ascendingOrderIcon sortingImages" onClick={() => handleColumnSort("control_name", "asc")}/>
                                            <img src={sortColumn === "control_name" && sortOrder === "desc" ? descSortingSelected : DescSort}
                                                className="sortingImages" onClick={() => handleColumnSort("control_name", "desc")}/>
                                        </Grid>
                                    </span>
                                </TableCell>
                                <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell new-summary-left">
                                    <span style={{ whiteSpace: "nowrap" }} className="align-items-center">
                                        <span className="login-history-dp-name font-wt-900" onClick={() => toggleSortOrder("site_name")}>SITE</span>
                                        <Grid className="sort-icon-margin">
                                            <img src={sortColumn === "site_name" && sortOrder === "asc" ? ascSortingSelected : AscSort}
                                                className="ascendingOrderIcon sortingImages" onClick={() => handleColumnSort("site_name", "asc")}/>
                                            <img src={sortColumn === "site_name" && sortOrder === "desc" ? descSortingSelected : DescSort}
                                                className="sortingImages" onClick={() => handleColumnSort("site_name", "desc")}/>
                                        </Grid>
                                    </span>
                                </TableCell>
                                <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell new-summary-left">
                                    <span style={{ whiteSpace: "nowrap" }} className="align-items-center">
                                        <span className="login-history-dp-name font-wt-900" onClick={() => toggleSortOrder("device_name")}>DEVICE</span>
                                        <Grid className="sort-icon-margin">
                                            <img src={sortColumn === "device_name" && sortOrder === "asc" ? ascSortingSelected : AscSort}
                                                className="ascendingOrderIcon sortingImages" onClick={() => handleColumnSort("device_name", "asc")}/>
                                            <img src={sortColumn === "device_name" && sortOrder === "desc" ? descSortingSelected : DescSort}
                                                className="sortingImages" onClick={() => handleColumnSort("device_name", "desc")}/>
                                        </Grid>
                                    </span>
                                </TableCell>
                                <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell new-summary-left">
                                    <span style={{ whiteSpace: "nowrap" }} className="align-items-center">
                                        <span className="login-history-dp-name font-wt-900" onClick={() => toggleSortOrder("severity")}>SEVERITY</span>
                                        <Grid className="sort-icon-margin">
                                            <img src={sortColumn === "severity" && sortOrder === "asc" ? ascSortingSelected : AscSort}
                                                className="ascendingOrderIcon sortingImages" onClick={() => handleColumnSort("severity", "asc")}/>
                                            <img src={sortColumn === "severity" && sortOrder === "desc" ? descSortingSelected : DescSort}
                                                className="sortingImages" onClick={() => handleColumnSort("severity", "desc")}/>
                                        </Grid>
                                    </span>
                                </TableCell>
                                <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell new-summary-left" >
                                    <span style={{ whiteSpace: "nowrap" }} className="align-items-center">
                                        <span className="login-history-dp-name font-wt-900" onClick={() => toggleSortOrder("threat_names_and_classes")}>CLASSES</span>
                                        <Grid className="sort-icon-margin">
                                            <img src={sortColumn === "threat_names_and_classes" && sortOrder === "asc" ? ascSortingSelected : AscSort}
                                                className="ascendingOrderIcon sortingImages" onClick={() => handleColumnSort("threat_names_and_classes", "asc")}/>
                                            <img src={sortColumn === "threat_names_and_classes" && sortOrder === "desc" ? descSortingSelected : DescSort}
                                                className="sortingImages" onClick={() => handleColumnSort("threat_names_and_classes", "desc")}/>
                                        </Grid>
                                    </span>
                                </TableCell>
                                <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell new-summary-left">
                                    <span style={{ whiteSpace: "nowrap" }} className="align-items-center">
                                        <span className="login-history-dp-name font-wt-900">THREAT NAMES</span>
                                    </span>
                                </TableCell>
                                <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell new-summary-left">
                                    <span style={{ whiteSpace: "nowrap" }} className="align-items-center">
                                        <span className="login-history-dp-name font-wt-900" onClick={() => toggleSortOrder("measurement_period")}>MEASUREMENT PERIOD</span>
                                        <Grid className="sort-icon-margin">
                                            <img src={sortColumn === "measurement_period" && sortOrder === "asc" ? ascSortingSelected : AscSort}
                                                className="ascendingOrderIcon sortingImages" onClick={() => handleColumnSort("measurement_period", "asc")}/>
                                            <img src={sortColumn === "measurement_period" && sortOrder === "desc" ? descSortingSelected : DescSort}
                                                className="sortingImages" onClick={() => handleColumnSort("measurement_period", "desc")}/>
                                        </Grid>
                                    </span>
                                </TableCell>
                                <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell new-summary-left">
                                    <span style={{ whiteSpace: "nowrap" }} className="align-items-center">
                                        <span className="login-history-dp-name font-wt-900" onClick={() => toggleSortOrder("count")}>COUNT</span>
                                        <Grid className="sort-icon-margin">
                                            <img src={sortColumn === "count" && sortOrder === "asc" ? ascSortingSelected : AscSort}
                                               className="ascendingOrderIcon sortingImages" onClick={() => handleColumnSort("count", "asc")}/>
                                            <img src={sortColumn === "count" && sortOrder === "desc" ? descSortingSelected : DescSort}
                                               className="sortingImages" onClick={() => handleColumnSort("count", "desc")}/>
                                        </Grid>
                                    </span>
                                </TableCell>
                                <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell new-summary-left">
                                    <span style={{ whiteSpace: "nowrap" }} className="align-items-center">
                                        <span className="login-history-dp-name font-wt-900">THREAT ACTION</span>
                                    </span>
                                </TableCell>
                                <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell new-summary-left">
                                    <span style={{ whiteSpace: "nowrap" }} className="align-items-center">
                                        <span className="login-history-dp-name font-wt-900">OPERATIONS</span>
                                    </span>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                    {tableData && 0 != tableData.length ? (
                        <TableBody className="tableBody usage-tableBody Table-Body-Opacity">
                            {tableData.map((row: any, rowIndex: number) => (
                            <TableRow key={rowIndex} className="loginHistory-tableRow new-summary-usage-tr Table-Row-Opacity">
                                <TableCell className="login-left-align inter-font Table-Cell-Opacity">{row.control_name}</TableCell>
                                <Tooltip title={<span style={{ fontSize: "12px" }}>{row.site_name}</span>}>
                                    <TableCell className="login-left-align inter-font Table-Cell-Opacity selectedClick elipseThreatKontrol" title={row.site_name} onClick={() => handleSiteClick({k4Id: row.site_id, x: row.site_name})}>{row.site_name}</TableCell>
                                </Tooltip>
                                <Tooltip title={<span style={{ fontSize: "12px" }}>{row.device_name}</span>}>
                                    <TableCell className="login-left-align inter-font Table-Cell-Opacity selectedClick elipseThreatKontrol" title={row.device_name} onClick={() => handleDeviceClick(row.device_id)}><a>{row.device_name}</a></TableCell>
                                </Tooltip>
                                <TableCell className="login-left-align inter-font Table-Cell-Opacity selectedClick" onClick={() => handleSeverityClick(row.severity)}><a>{row.severity}</a></TableCell>
                                <TableCell className="login-left-align inter-font Table-Cell-Opacity selectedClick elipseThreatKontrolclasses">
                                    {row.threat_names_and_classes && typeof row.threat_names_and_classes === 'string' ? (
                                        (() => {
                                            const uniqueClasses = new Set<string>(); // Scoped to this row
                                            return JSON.parse(row.threat_names_and_classes)
                                                .filter((item: { class: string }) => {
                                                    if (!uniqueClasses.has(item.class)) {
                                                        uniqueClasses.add(item.class);
                                                        return true;
                                                    }
                                                    return false;
                                                })
                                                .map((item, index, uniqueClassesArray) => (
                                                    <React.Fragment key={index}>
                                                        <Tooltip
                                                            title={
                                                                <span style={{ fontSize: "12px" }}>
                                                                    {item.class}
                                                                </span>
                                                            }
                                                        >
                                                            <span
                                                                className="elipseThreatKontrolclass"
                                                                onClick={() => handleClassClick(item.class)}
                                                                style={{ maxWidth: `${130 / uniqueClassesArray.length}px` }}
                                                            >
                                                                {item.class}
                                                            </span>
                                                        </Tooltip>
                                                        {index < uniqueClassesArray.length - 1 ? ", " : ""}
                                                    </React.Fragment>
                                                ));
                                        })()
                                    ) : (
                                        ""
                                    )}
                                </TableCell>
                                <Tooltip
                                title={
                                    <span style={{ fontSize: "12px" }}>
                                        {row.threat_names_and_classes && typeof row.threat_names_and_classes === 'string'
                                            ? JSON.parse(row.threat_names_and_classes).map(item => item.threat_name).join(', ')
                                            : "No Threat Names"}
                                    </span>
                                }
                            >
                                <TableCell
                                    className="login-left-align inter-font Table-Cell-Opacity elipseThreatKontrol"
                                    title={
                                        row.threat_names_and_classes && typeof row.threat_names_and_classes === 'string'
                                            ? JSON.parse(row.threat_names_and_classes).map(item => item.threat_name).join(', ')
                                            : "No Threat Names"
                                    }
                                >
                                    {row.threat_names_and_classes && typeof row.threat_names_and_classes === 'string'
                                        ? JSON.parse(row.threat_names_and_classes).map(item => item.threat_name).join(', ')
                                        : "No Threat Names"}
                                </TableCell>
                            </Tooltip>
                                <TableCell className="login-left-align inter-font Table-Cell-Opacity">{row.measurement_period}</TableCell>
                                <TableCell className="login-left-align inter-font Table-Cell-Opacity">{row.count}</TableCell>
                                <TableCell className="login-left-align inter-font Table-Cell-Opacity">{row.action}</TableCell>
                                <TableCell className="login-left-align inter-font Table-Cell-Opacity">
                                    <div className="threatKontrolIconsAlign">
                                    <Tooltip className="inventoryDeployedAction backup-config-now-icon" title="Edit threatKontrol"
                                        onClick={() => {
                                            setSelectedRow(row);
                                            setEditOpen(true);
                                        }}  
                                    ><EditOutlinedIcon/></Tooltip>
                                    <Tooltip className="inventoryDeployedAction backup-config-now-icon" title="Delete threatKontrol"
                                        onClick={() => {
                                            setSelectedRow(row);
                                            setDeleteOpen(true);
                                        }}
                                    ><DeleteOutlinedIcon/></Tooltip>
                                    <Tooltip className="inventoryDeployedAction backup-config-now-icon" title="Enable / Disable">
                                        <Switch
                                            size="small"
                                            checked={row.is_disabled ? false : true}
                                            color="primary"
                                            onChange={() => {handleEnableDisable(row.is_disabled ? false : true, row.site_id, row.device_id, row.rule_id)}}
                                        />
                                    </Tooltip>
                                    </div>
                                </TableCell>
                            </TableRow>
                            ))}
                        </TableBody>
                        )  :
                        <TableBody>
                            <TableRow>
                                <TableCell colSpan={8} align='center'>
                                    No data available
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    }
                </Table>
            </TableContainer >
            {editOpen && <EditThreatKontrol users={users} editOpen={editOpen} setEditOpen={setEditOpen} selectedRow={selectedRow}/>}
            {deleteOpen && <ThreatKontrolDelete deleteOpen={deleteOpen} setDeleteOpen={setDeleteOpen} selectedRow={selectedRow}/>}
            </Grid>
        </Grid>
    )
}
const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
    errorReducer: state.errorReducer,
    usersList: state.authReducer.usersList,
    threatList: state.authReducer?.threatList,
    threatFilters: state?.authReducer?.threatFilters
});

export default withRouter(
    connect(mapStateToProps, { fetchUserListAPI, getThreats, updateThreatKontrol, downloadAsCSV })(ThreatKontrolTable)
);

