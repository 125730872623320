import { Grid, TableBody, TableRow, TableCell, TableContainer, Table, TableHead, Tooltip, Paper } from "@mui/material";
import "./appUsageCard.css";
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import React from 'react';
import { readableBytesAsGB} from "../../utils/util"
import ImageWithFallback from "../ImageWithFallback";
import {getWanUsageSingleSiteNetworkUsage} from '../../actions/Users/authenticateReports';
import { withRouter } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import _ from "lodash";
import {useState, useEffect} from 'react';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 6,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.mode === 'light' ? '#1B51A7' : '#308fe8',
    },
  }));

const SingleSiteNetworkUsage = (props) => {
    const { appDownloadTableData, title, header, tab, authReducer, getVesselsListing, filteredVessels, getWanUsageSingleSiteNetworkUsage, wanUsageReportSingleSiteNetworkUsage, newSummaryStartDate, newSummaryEndDate} = props;

function GetTopAppsImage (props)  {
        const { app } = props;
        if(app) {
            let appName = app.includes(' ') ? app.split(' ')[0]: app.includes('-') ? app.split('-')[0] : ""; 
            return <ImageWithFallback src={`https://cdn.k4mobility.com/cdn/sportAssets/app_icons/${app.trim()}`} fallbackSrcs={[`https://cdn.k4mobility.com/cdn/sportAssets/app_icons/${appName.trim()}`,"https://cdn.k4mobility.com/cdn/sportAssets/fallbackImg.png"]} app={app} />
        } else {
            return <img className="logo-activity paddingLogo" src={`https://cdn.k4mobility.com/cdn/sportAssets/fallbackImg.png`} />
        }
    }
const [singleSiteNetworkTable, setSingleSiteNetworkTable] = useState<any[]>([]);
const [vessels, setVessels] = useState<any[]>([]);

useEffect(() => {
    if (_.isEmpty(getVesselsListing))
      return;
    let vessels: any[] = [];
    if (!_.isEmpty(getVesselsListing)) {
      vessels = getVesselsListing?.locations;
    }
    if (_.isArray(filteredVessels) && filteredVessels.length > 0) {
      vessels = filteredVessels;
    }
    setVessels(vessels);
  }, [getVesselsListing, filteredVessels]);

useEffect(() => {
    if (vessels.length > 0) {
        getWanUsageSingleSiteNetworkUsage({
            k4Id: `${vessels.map(v => `${v.id}`).join(',')}`,
            start: newSummaryStartDate,
            end: newSummaryEndDate
        });
    }
}, [vessels, newSummaryStartDate, newSummaryEndDate]); 

useEffect(() => {
    if(!_.isEmpty(wanUsageReportSingleSiteNetworkUsage)) {
        const data = wanUsageReportSingleSiteNetworkUsage?.hasOwnProperty('data') ? wanUsageReportSingleSiteNetworkUsage?.data : {};
        const dataArr:any = [];
        data?.rows && data?.rows?.forEach((item, i) => {
            const itemArr = data?.columns?.reduce((acc, columItem, i) => {
                acc[columItem] = item[i];
                return acc;
            }, {})
            dataArr.push(itemArr)
        })
        setSingleSiteNetworkTable(dataArr);
    }
}, [wanUsageReportSingleSiteNetworkUsage])

return (
    <div className="Form">
        <Grid className="am-table">
           <Grid container className="AppUsageContainer">
                <Grid>
                    <div className="TopApplicationData">{title}</div>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} className="AppUsageTableContainer">
                    <TableContainer className="AppUsage-FleetDashboard">
                        <Table aria-label="simple table" style={tab!=='vessel_chart' ? {tableLayout:'fixed'} : {tableLayout: 'auto'}}>
                            <TableHead className="usage-tableHead">
                                <TableRow className="appUsage-tableRow">
                                    <TableCell className={tab!=='vessel_chart' ? "appUsage-fleetDataTable activity-appUsage-fleetDataTable" : "appUsage-fleetDataTable"}>{'NETWORK'}</TableCell>
                                    { header ? <TableCell align="left" className={tab!=='vessel_chart' ? "appUsage_fleetDataTable activity-appUsage_fleetDataTable" : "appUsage_fleetDataTable"}>{`${header}`}</TableCell> : ''}
                                </TableRow>
                            </TableHead>
                            { singleSiteNetworkTable && 0 != singleSiteNetworkTable.length ? (
                                <TableBody className="tableBody appUsage-tableBody">
                                    {singleSiteNetworkTable.map((row, i) => (
                                        <TableRow className="tabelRow appUsage-tableRow">          
                                            <TableCell className={tab!=='vessel_chart' ? "appUsage-tableCell activityTableCell-logo activity-appUsage-tableCell" : 'appUsage-tableCell activityTableCell-logo'}>
                                                <Tooltip title={tab!=='vessel_chart' &&  row.name}>
                                                        <span>{row.name}</span>
                                                </Tooltip>
                                            </TableCell>
                                            <TableCell className="appUsage_tableCell">
                                                <TableRow className="usage-innerTableRow">
                                                    <TableCell className={tab!=='vessel_chart' ? "usage-innerTableCell activity-usage-innerTableCell" : 'usage-innerTableCell'}>
                                                        <div>
                                                            <BorderLinearProgress variant="determinate" value={Math.ceil(row.percentage)} />
                                                        </div>
                                                    </TableCell>
                                                    <TableCell className={tab!=='vessel_chart' ? "usage_innerTableCell activity-usage_innerTableCell" : 'usage_innerTableCell'}>
                                                        <span>{readableBytesAsGB(row.total_usage).replace(" GB", "")}</span>
                                                    </TableCell>
                                                </TableRow>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody> 
                                ) : (
                                <TableBody>
                                    <TableRow className="noData-appDetails">
                                        <TableCell colSpan={2} className="noDataAvailableCss" align="center">No data available</TableCell>
                                    </TableRow>
                                </TableBody>
                            )}                                     
                        </Table>
                    </TableContainer>
                </Grid>
           </Grid>
        </Grid>
    </div>
)
}

const mapStateToProps = (state) => ({
  authReducer: state.authReducer,
  errorReducer: state.errorReducer,
  getVesselsListing: state.authReducer?.getVesselsListing,
  filteredVessels: state.authReducer?.filteredVessels,
  wanUsageReportSingleSiteNetworkUsage: state.authReducer.wanUsageReportSingleSiteNetworkUsage,
  newSummaryStartDate: state.authReducer.newSummaryStartDate,
  newSummaryEndDate: state.authReducer.newSummaryEndDate,
});

export default withRouter(
  connect(mapStateToProps, {
    getWanUsageSingleSiteNetworkUsage
  })(SingleSiteNetworkUsage)
);