import { withRouter } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { Button, Dialog, DialogContent, DialogTitle, Divider, Grid, List, ListItemButton, Stack, Tooltip, Typography, Autocomplete, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import close from "../../../asset/image/close.svg";
import _ from "lodash";
import "./ServiceHubDialog.css";
import { CONFIRM_DIALOG_TITLE } from "../../../config";
import { deleteSite } from "../../../actions/Users/authenticateInventory";
import OrgSelection from "../AgentConfigOrgSelection";
import { moveDeviceToWarehouse, getDeviceLatestEOSversion } from "../../../actions/Users/authenticateInventory";
import { DEVICE_LATEST_EOS_VERSION, GET_LICENSE_TYPES, INVENTORY_ASSIGN_DEVICE_TO_WAREHOUSE } from "../../../actions/types";
import { compare } from 'compare-versions';
import AgentConfigPopup from "../AgentConfigSummaryPopup";
import ServiceVlansPopup from "../AccessNetworkPopup";
import AgentWan from "../AgentWanPopup";
import AgentWanBalancerPopup from "../AgentWanBalancerPopup";
import ServiceHubsPopup from "../ServiceHubPopup";

const AGENT_CONFIG = "Agent Config"
const VLANS = "VLANs"
const SERVICE_VLANS = "Service Vlans"
const WANS = "AGENT WAN"
const ROUTES = "Routes"
const WAN_PROFILES = "AGENT WAN BALANCER"
const PROFILES = "Profiles"
const HUBS = "Hubs"
const SERVICES_HUBS = "Services/Hubs"
const SERVICES = "Services"
const AGENTS = "Agents"
const DEFAULTS = "Defaults"

const ActionsPopup = (props) => {
    const { authReducer, setInventoryAssignDeviceToWarehouse, getDrawerOpen, inventoryAssignDeviceToWarehouse, moveDeviceToWarehouse, open, setOpen, row, setRow, confirmDialog, setConfirmDialog, inventoryChangeGroup, setUpgradeLicensePayload, isAuthorizedToBeta, deleteSite,
        deRegistrationReasons, handleReason, selectedReason, selectedReasonInput, setSelectedReason, setSelectedReasonInput, setDeRegistrationResons, specificErrodCode, setSpecificErrorCode, handleValidateTier, getDeviceLatestEOSversion, setIsHubActionPopup, isM6Device, isEditActionPopup, setIsEditActionPopup, existingAgentName, existingAgentConfigName
    } = props;

    const [isValidTier, setIsValidTier] = useState<boolean>(isM6Device ? false : true)
    // const actionsList = [AGENT_CONFIG, VLANS, SERVICE_VLANS, WANS, WAN_PROFILES, ROUTES, PROFILES, HUBS, SERVICES, AGENTS].filter(action => action);
    const actionsList = [WANS, WAN_PROFILES, SERVICES_HUBS]

    const [selectedAction, setSelectedAction] = useState(actionsList[0]);
    const [ou, setOu] = useState<any>({});
    const [modifyConfigData, setModifyConfigData] = useState<any>();

    const [selectedDp, setSelectedDp] = useState(null)

    const dispatch = useDispatch();

    useEffect(() => {
        if (!selectedDp) {
            setSelectedDp({ ...authReducer?.selectedOu, level: 0, hasChildren: true })
        }
    }, [authReducer?.selectedOu?.id])


    const handleChangeActionType = (actionType) => {
        setSelectedAction(actionType);
    }

    const handleConfirmClick = () => {

    }

    const changeGroup = () => {
        inventoryChangeGroup(row["k4Id"], ou?.id);
        setOpen(false);
    }

    const handleClose = () => {
        setIsHubActionPopup(false)
        setIsEditActionPopup(false)
    }

    useEffect(() => {
        if (isEditActionPopup) { setSelectedAction(AGENT_CONFIG) }
    }, [isEditActionPopup])

    const handleChangeDp = (ou: any) => {
        setSelectedDp(ou)
    }

    return (
        <div >
            <Dialog
                disableEnforceFocus
                open={open}
                onClose={handleClose}
                aria-labelledby='form-dialog-title'
                fullWidth={true}
                maxWidth={"xl"}
                // id={getDrawerOpen ? 'popup-fixed-position-400px' : 'popup-fixed-position-200px'}
                // className='config-deploy-inventory-popup menuScroll popup-fixed-position  threatKontrolDialogbox1 inventory-edit-actions-container'   //   
                className='sh--dialog-container menuScroll sh--popup-fixed-position  sh--dialog-paper-container sh--edit-actions-container'   //   
            >
                <div>
                    <DialogTitle className='sh--create-default-config-title sh--edit-actions-title' style={{ height: "22px" }}>
                        <div style={{ width: "100%", display: "flex", alignItems: "center" }}>
                            <div style={{ width: "19%", }}>
                                <Grid container >
                                    <Grid item lg={12} style={{ display: "flex", alignItems: "center", alignContent: "center", justifyContent: "flex-start", fontSize: "14px" }}><span >Service/Hub Config</span></Grid>
                                </Grid>
                            </div>
                            <div style={{ width: "81%", }}>
                                <Grid container >
                                    <Grid item lg={3} >
                                        <OrgSelection label={"Organization"} value={selectedDp} onChange={handleChangeDp} disabled={false} />
                                    </Grid>
                                    <Grid item lg={7} ></Grid>
                                    <Grid item lg={2} style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", }}>
                                        <img src={close} title="Close" onClick={handleClose} />
                                    </Grid>
                                </Grid>
                            </div>
                        </div>

                    </DialogTitle>
                    <DialogContent className="sh--edit-actions-content">
                        <Grid container spacing={1}>
                            <Grid className="menuScroll sh--device-border-right sh--config-list-menu-container sh--service-hub-list-menu-container" item>
                                <Grid className="sh--service-hub-data-body sh--service-hub-actions-data-body">
                                    <List>
                                        {actionsList && actionsList.map((identifier: any, index) => {
                                            return <ListItemButton className={identifier == selectedAction ? "sh--status-lists sh--selectedAction " : "sh--status-lists"} key={`device-${index}`} selected={identifier === selectedAction}
                                                onClick={() => handleChangeActionType(identifier)}>
                                                <Tooltip title={identifier?.isParent ? 'Parent' : null}>
                                                    <Typography className={identifier === selectedAction ? "sh--font-status-pop-up sh--selectedAction" : "sh--font-status-pop-up sh--NotselectedAction"} style={{}} >{identifier}</Typography>
                                                </Tooltip>
                                            </ListItemButton>
                                        })}
                                    </List>
                                </Grid>
                            </Grid>
                            <Grid className={`menuScroll ${selectedAction === SERVICES_HUBS ? "sh--service-hub-list-menu-content-hub" : "sh--service-hub-list-menu-content"}`}>
                                <Grid className="sh--service-hub-data-body sh--edit-actions-data-body">
                                    <Stack className="sh--sub-tab-parent" direction="column" spacing={1}>
                                        {selectedAction === WANS && <AgentWan runpup setIsActionPopup={setIsHubActionPopup} isEditActionPopup={isEditActionPopup} setIsEditActionPopup={setIsEditActionPopup} setSelectedAction={setSelectedAction} selectedAction={selectedAction} selectedDp={selectedDp}/>}
                                        {selectedAction === WAN_PROFILES && <AgentWanBalancerPopup setIsActionPopup={setIsHubActionPopup} selectedDp={selectedDp}/>}
                                        {selectedAction === SERVICES_HUBS && <ServiceHubsPopup setIsActionPopup={setIsHubActionPopup} selectedDp={selectedDp}/>}
                                    </Stack>
                                </Grid>
                            </Grid>
                        </Grid>
                    </ DialogContent>
                </div>
            </Dialog>
        </div>
    )
}

const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
    getDrawerOpen: state.authReducer?.getDrawerOpen,
    inventoryAssignDeviceToWarehouse: state.authReducer?.inventoryAssignDeviceToWarehouse,
    setInventoryAssignDeviceToWarehouse: state.authReducer.setInventoryAssignDeviceToWarehouse,
});

export default withRouter(
    connect(mapStateToProps, {
    })(ActionsPopup)
);