import { withRouter } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { Button, Dialog, DialogContent, DialogTitle, Divider, Grid, List, ListItemButton, Stack, Tooltip, Typography, Autocomplete, TextField, DialogActions } from "@mui/material";
import { useEffect, useState } from "react";
import close from "../../../asset/image/close.svg";
import _ from "lodash";
import "../AgentDialog/AgentDialog.css";

const AGENT_CONFIG = "Agent Config Summary"
const VLANS = "Access Network"

const ActionsPopup = (props) => {
    const { authReducer, setInventoryAssignDeviceToWarehouse, getDrawerOpen, inventoryAssignDeviceToWarehouse, moveDeviceToWarehouse, open, setOpen, row, setRow, confirmDialog, setConfirmDialog, inventoryChangeGroup, setUpgradeLicensePayload, isAuthorizedToBeta, deleteSite,
        deRegistrationReasons, handleReason, selectedReason, selectedReasonInput, setSelectedReason, setSelectedReasonInput, setDeRegistrationResons, specificErrodCode, setSpecificErrorCode, handleValidateTier, getDeviceLatestEOSversion, setLinkBonding, isM6Device, isEditActionPopup, setIsEditActionPopup, existingAgentName, existingAgentConfigName
        , wanNames, balancerType, handleChange, weightage
    } = props;

    const [isValidTier, setIsValidTier] = useState<boolean>(isM6Device ? false : true)
    // const actionsList = [AGENT_CONFIG, VLANS, SERVICE_VLANS, WANS, WAN_PROFILES, ROUTES, PROFILES, HUBS, SERVICES, AGENTS].filter(action => action);
    const actionsList = [VLANS, AGENT_CONFIG]

    const [selectedAction, setSelectedAction] = useState(actionsList[0]);
    const [ou, setOu] = useState<any>({});
    const [modifyConfigData, setModifyConfigData] = useState<any>();


    const dispatch = useDispatch();


    const handleChangeActionType = (actionType) => {
        setSelectedAction(actionType);
    }

    const handleConfirmClick = () => {

    }

    const changeGroup = () => {
        inventoryChangeGroup(row["k4Id"], ou?.id);
        setOpen(false);
    }

    const handleClose = () => {
        setLinkBonding(false)
    }

    useEffect(() => {
        if (isEditActionPopup) { setSelectedAction(AGENT_CONFIG) }
    }, [isEditActionPopup])

    const orgData = {
        id: "DP-0001",
        name: "K4 Mobility",
        level: 1,
        hasChildren: false,
    }






    return (
        <div className="Form">
            <Dialog
                disableEnforceFocus
                open={open}
                onClose={handleClose}
                aria-labelledby='form-dialog-title'
                fullWidth={true}
                maxWidth={"sm"}
            // id={getDrawerOpen ? 'popup-fixed-position-400px' : 'popup-fixed-position-200px'}
            // className='config-deploy-inventory-popup menuScroll inventory-warehouse-register-pop-up inventory-edit-actions-container1 popup-fixed-position threatKontrolDialogbox'
            >
                <div>
                    <DialogTitle className='create-default-config-title edit-actions-title' style={{ background: "#f5f5f5" }}  >
                        <Grid container >
                            <Grid item lg={11}>
                                <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                    <p style={{ fontFamily: "Inter", fontSize: "14px", textAlign: "center" }}>Bonding Mechanism</p>
                                </div>

                            </Grid>
                            <Grid item lg={1} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <img src={close} title="Close" onClick={handleClose} />
                            </Grid>
                        </Grid>
                    </DialogTitle>
                    <DialogContent className="edit-actions-content warehouse-edit-actions-content">
                        <div >
                            <p style={{ fontFamily: "Inter" }}>Please Select Link Bonding Mechanism</p>
                            <br />
                            <div>
                                <Button onClick={() => { handleChange("balancer_type", "dynamic") }} style={{ width: "100px", background: balancerType === "dynamic" ? "#264c86" : "#C4C4C4", color: "white", borderRadius: "0px", textTransform: "none", letterSpacing: "0.5px", fontFamily: "Inter" }}>Dynamic</Button>
                                <Button onClick={() => { handleChange("balancer_type", "static") }} style={{ width: "100px", background: balancerType === "static" ? "#264c86" : "#C4C4C4", color: "white", borderRadius: "0px", textTransform: "none", letterSpacing: "0.5px", fontFamily: "Inter" }}>Static</Button>
                            </div>
                            <p style={{ fontFamily: "Inter", color: "#a9a9a9" }}>Weighting % (applicable for Static Bonding)</p>
                            <Grid container spacing={2}>
                                {wanNames?.map(wanName => {
                                    return (
                                        <SingleWan wanName={wanName} balancerType={balancerType} weightage={weightage} handleChange={handleChange} />
                                    )
                                })}
                            </Grid>
                        </div>
                    </ DialogContent>
                    <DialogActions>
                        <Divider className="threatKontrolDivider" />
                        <div className="displayFlex threatKontrolMargin">
                            <Button className="confirm-dialogue-cancel" style={{ color: "#264c86" }} onClick={() => { handleClose() }}> Cancel </Button>
                            <Button variant="contained" style={{ background: "#264c86" }} onClick={() => { handleClose() }}>Save</Button>
                        </div>
                    </DialogActions>
                </div>
            </Dialog>
        </div>
    )
}

const SingleWan = (props: any) => {
    const { wanName, balancerType, weightage, handleChange } = props

    const [error, setError] = useState(false);
    const [helperText, setHelperText] = useState("");


    const handleWeightageChange = (event: any) => {
        let inputValue = event?.target?.value || 0;

        if (/^\d*$/.test(inputValue)) {
            setError(false);
            setHelperText("");
            handleChange("weightage", { ...weightage, [wanName]: parseInt(inputValue) })
        } else {
            setError(true);
            setHelperText("Only numeric values are allowed.");
        }
    }

    return (
        <Grid item lg={2}>
            <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "flex-start" }}>
                <p style={{ color: "#a9a9a9", textAlign: "start", fontSize: "12px" }}>{wanName}</p>
                <TextField
                    placeholder=""
                    value={balancerType === "dynamic" ? "--" : weightage?.[wanName]}
                    size="small"
                    onChange={(event) => {
                        handleWeightageChange(event)
                    }}
                    disabled={balancerType === "dynamic" ? true : false}
                    error={error}
                    helperText={helperText}
                    variant="filled"
                    sx={{ background: "#e3f3f8", width: "100%", fontFamily: "Inter" }}
                />
            </div>

        </Grid>
    )
}

const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
    getDrawerOpen: state.authReducer?.getDrawerOpen,
    inventoryAssignDeviceToWarehouse: state.authReducer?.inventoryAssignDeviceToWarehouse,
    setInventoryAssignDeviceToWarehouse: state.authReducer.setInventoryAssignDeviceToWarehouse,
});

export default withRouter(
    connect(mapStateToProps, {
    })(ActionsPopup)
);