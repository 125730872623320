import { Fragment, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux"
import _ from "lodash"
import moment from "moment-timezone";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CircleIcon from '@mui/icons-material/Circle';
import { Grid, TextField, InputAdornment, Button, Stack, Box } from "@mui/material";
import Paper from '@mui/material/Paper';
import SettingsIcon from '@mui/icons-material/Settings';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import InfoIcon from '@mui/icons-material/Info';
import Tooltip from '@mui/material/Tooltip';
import { getEdgeEventsDashBoard, getEdgeEventsFilterDashboard, downloadEdgeEventsTableDashbOard, clearEdgeEventsDownload } from "../../../actions/Users/authenticateEdgeEvents";
import { MMDDYYYYHMMSS_DATE_FORMAT_24_HRS } from "../../../utils/constants";
import AssignmentIcon from '@mui/icons-material/Assignment';
import CustomLoader from "../../../components/Loader/CustomLoaders";
import "../../../components/InventoryDeployedTable/inventoryDeployed.css"
import filter from "../../asset/image/filter.svg";
import { PER_PAGE_ITEMS, DEFAULT_PAGE_SIZE, NEW_PER_PAGE_ITEMS, } from "../../../constants/Constants";
import FilterEdgeEventsDialog from "../../../components/FilterEdgeEventsDialog";
import DateRangeTimePicker from "../../../vis/react-app/src/components/DateRangeTimePicker";
import { displayToastError } from "../../../server/request";
import { ERROR_GET_EDGE_EVENTS, ERROR_GET_EDGE_EVENTS_FILTER } from "../../../actions/error_types";
import { convertDateTimeIntoTimezone, getDecodeURI, getEncodedURI } from "../../../utils/util";
import { useHistory, useLocation } from "react-router-dom";
import JsonTooltip, { JsonDataTooltip } from "../../../components/JSONTooltip/JsonTooltip";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider, DateTimePicker } from '@mui/x-date-pickers';
import { StyledEngineProvider } from '@mui/material/styles';
import { jsPDF } from "jspdf";
import autoTable from 'jspdf-autotable';
import GetApp from "@material-ui/icons/GetApp";
import { Pagination } from "../../../components/Pagination";
import TuneIcon from '@mui/icons-material/Tune';
import Download from '../../asset/image/DownloadUsage.svg';
import { CsvParameters, downloadAsCSV } from "../../../actions/Users/authenticateCsvDownload";
import { createAgentWan, getAgents, updateAgentWan, deleteAgentWan } from "../../../actions/Users/authenticateHub";
import ascSortingSelected from "../../../asset/image/ascSortingSelected.svg";
import descSortingSelected from "../../../asset/image/descSortingSelected.svg";
import AscSort from "../../../asset/image/AscSort.svg";
import DescSort from "../../../asset/image/DescSort.svg";

const AgentTable = (props) => {

    const AGENT_ID = 'agent_id';
    const AGENT_NAME = 'agent_name';
    const AGENT_CONFIG_NAME = 'agent_config_name';
    const CREATION_DATE = 'created_at';
    const LAST_SEEN = 'updated_at';
    const ASC_ORDER = "asc";
    const DESC_ORDER = "desc";

    const { authReducer, setIsActionPopup, setIsEditActionPopup, setIsAgentActionPopup, existingAgentName, setExistingAgentName, setExistingAgentConfigName, getAgents, setCurrentAgentId, sort, setSort, sortOrder, setSortOrder, searchValue, setSearchValue, total, setTotal, page, setPage, limit, setLimit, setAgentOrganizationId, setAgentOrganizationName} = props

    const history = useHistory();
    const location = useLocation();
    let _q = getDecodeURI(location?.search);
    
    const [agents, setAgents] = useState<any>([])
    useEffect(() => {
        if (location && authReducer?.selectedOu?.id) {
            processQueryUrl()
        }
    }, [location, authReducer?.selectedOu?.id])

    useEffect(() => {
        if (!_.isEmpty(authReducer?.getAgents) && !authReducer?.getAgentsLoading) {
            setAgents(authReducer?.getAgents?.data || [])
            setTotal(authReducer?.getAgents?.totalCount)
        }
    }, [authReducer?.getAgents])

    const processQueryUrl = () => {
        let _q = getDecodeURI(location?.search);
        if (_q.ouId && authReducer?.selectedOu?.id !== _q.ouId) {
            return
        }
        
        setSearchValue(_q.search ? _q.search : '');

        let _limit = _q.limit && '' != _q.limit.trim() ? parseInt(_q.limit) : 15;
        if (_limit != limit) {
            setLimit(_limit);
        }

        let _page = _q.page && '' != _q.page.trim() ? parseInt(_q.page) : 0;
        if (_page != page) {
            setPage(_page);
        }

        if (_q.search && _q.search.trim() !== '') {
            setSearchValue(_q.search);
        }

        if (_q.sort && _q.sort != sort) {
            setSort(_q.sort)
        }

        if (_q.sortOrder && _q.sortOrder != sortOrder) {
            setSortOrder(_q.sortOrder)
        }

        getAgents(authReducer?.selectedOu?.id, { search: _q.search, page: _page + 1, pageSize: _limit, sortBy: sort, sortOrder: sortOrder })
    }

    const handlePagination = (e, value) => {
        let params: any = getDecodeURI(location?.search);
        params.page = value - 1;
        setPage(value - 1)
        doNavigate(location.url, params);
    };

    const doNavigate = (pathname, params = {}) => {
        history.push({ pathname: pathname, search: `?${getEncodedURI(params)}` });
    }

    const handlePerPageChange = (e: any) => {
        let _perPage = parseInt(e.target.value);
        if (Number.isInteger(_perPage)) {
            let params: any = getDecodeURI(location?.search);
            params.page = 0;
            params.limit = _perPage;
            setPage(0);
            setLimit(_perPage);
            doNavigate(location.url, params);
        }
    };

    const setSorting = (event: any, field: any, order: any,) => {
        if (event && event.currentTarget) {
            let params: any = getDecodeURI(location?.search);
            params.page = 0;
            params.sort = field;
            params.sortOrder = order;

            setPage(0);
            setSort(field);
            setSortOrder(order);

            doNavigate(location.url, params);
            // getAgents(authReducer?.selectedOu?.id, { search: searchValue, pageSize: limit, page: 0, sortBy: field, sortOrder: order });
        }
    };

    const switchSorting = (event: any, field: any) => {
        let order = ASC_ORDER;
        if (sort === field) {
            order = ASC_ORDER === sortOrder ? DESC_ORDER : ASC_ORDER;
        }
        let params: any = getDecodeURI(location?.search);
        params.page = 0;
        params.sort = field;
        params.sortOrder = order;

        setPage(0);
        setSort(field);
        setSortOrder(order);

        doNavigate(location.url, params);
        // getAgents(authReducer?.selectedOu?.id, { search: searchValue, pageSize: limit, page: 0, sortBy: field, sortOrder: order });
    };

    return (
        <Fragment><StyledEngineProvider injectFirst>
            <Grid container >
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <div className="menuScroll">
                        <Box>
                            <Pagination
                                rowsPerPageOptions={NEW_PER_PAGE_ITEMS}
                                count={total ? total : 0}
                                rowsPerPage={limit}
                                page={page + 1}
                                onPageChange={handlePagination}
                                onRowsPerPageChange={handlePerPageChange}
                            />
                        </Box>
                        <TableContainer className="usageReports-FleetDashboard loginHistory-tableContainer deployed-container white-bg deployed-table-headers">
                            <Table aria-label="simple table">
                                <TableHead className="usageReports-tableHead">
                                    <TableRow className="UsageReports-tableRow login-tableRow">
                                        <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell CF-cell" style={{width:"5%"}}>
                                            <span className="align-items-center">
                                                <span className="login-history-dp-name font-wt-900" >ORGANIZATION</span>
                                            </span>
                                        </TableCell>
                                        <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell CF-cell">
                                            <span className="align-items-center">
                                                <span className="login-history-dp-name font-wt-900" onClick={(event) => switchSorting(event, AGENT_ID)}>AGENT ID</span>
                                                <Grid className="sort-icon-margin">
                                                    <img src={sort === AGENT_ID && sortOrder === ASC_ORDER ? ascSortingSelected : AscSort}
                                                        className="ascendingOrderIcon sortingImages" onClick={(event) => setSorting(event, AGENT_ID, ASC_ORDER)} />
                                                    <img src={sort === AGENT_ID && sortOrder === DESC_ORDER ? descSortingSelected : DescSort}
                                                        className="sortingImages" onClick={(event) => setSorting(event, AGENT_ID, DESC_ORDER)} />
                                                </Grid>
                                            </span>
                                        </TableCell>
                                        <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell CF-cell">
                                            <span className="align-items-center">
                                                <span className="login-history-dp-name font-wt-900" onClick={(event) => switchSorting(event, AGENT_NAME)}>AGENT NAME</span>
                                                <Grid className="sort-icon-margin">
                                                    <img src={sort === AGENT_NAME && sortOrder === ASC_ORDER ? ascSortingSelected : AscSort}
                                                        className="ascendingOrderIcon sortingImages" onClick={(event) => setSorting(event, AGENT_NAME, ASC_ORDER)} />
                                                    <img src={sort === AGENT_NAME && sortOrder === DESC_ORDER ? descSortingSelected : DescSort}
                                                        className="sortingImages" onClick={(event) => setSorting(event, AGENT_NAME, DESC_ORDER)} />
                                                </Grid>
                                            </span>
                                        </TableCell>
                                        <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell CF-cell">
                                            <span className="align-items-center">
                                                <span className="login-history-dp-name font-wt-900" >AGENT CONFIG VERSION</span>
                                            </span>
                                        </TableCell>
                                        <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell CF-cell">
                                            <span className="align-items-center">
                                                <span className="login-history-dp-name font-wt-900" onClick={(event) => switchSorting(event, LAST_SEEN)}>LAST SEEN</span>
                                                <Grid className="sort-icon-margin">
                                                    <img src={sort === LAST_SEEN && sortOrder === ASC_ORDER ? ascSortingSelected : AscSort}
                                                        className="ascendingOrderIcon sortingImages" onClick={(event) => setSorting(event, LAST_SEEN, ASC_ORDER)} />
                                                    <img src={sort === LAST_SEEN && sortOrder === DESC_ORDER ? descSortingSelected : DescSort}
                                                        className="sortingImages" onClick={(event) => setSorting(event, LAST_SEEN, DESC_ORDER)} />
                                                </Grid>
                                            </span>
                                        </TableCell>
                                        <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell CF-cell">
                                            <span className="align-items-center">
                                                <span className="login-history-dp-name font-wt-900" onClick={(event) => switchSorting(event, CREATION_DATE)}>CREATION DATE</span>
                                                <Grid className="sort-icon-margin">
                                                    <img src={sort === CREATION_DATE && sortOrder === ASC_ORDER ? ascSortingSelected : AscSort}
                                                        className="ascendingOrderIcon sortingImages" onClick={(event) => setSorting(event, CREATION_DATE, ASC_ORDER)} />
                                                    <img src={sort === CREATION_DATE && sortOrder === DESC_ORDER ? descSortingSelected : DescSort}
                                                        className="sortingImages" onClick={(event) => setSorting(event, CREATION_DATE, DESC_ORDER)} />
                                                </Grid>
                                            </span>
                                        </TableCell>
                                        <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell CF-cell">
                                            <span className="align-items-center">
                                                <span className="login-history-dp-name font-wt-900" >SERVICES</span>
                                            </span>
                                        </TableCell>
                                        <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell CF-cell">
                                            <span className="align-items-center">
                                                <span className="login-history-dp-name font-wt-900" >OPERATIONS</span>
                                            </span>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                {agents?.length > 0 &&
                                    // className="tableBody alertsTable-tableBody"
                                    <TableBody className="tableBody usage-tableBody">
                                        {
                                            agents?.length > 0 && agents.map((row, index) =>
                                                <TableRow className="loginHistory-tableRow new-deployed-usage-tr">
                                                    <Tooltip title={<span className="font-size-12px">{row['organization']}</span>}>
                                                        <TableCell className="login-left-align inter-font text-center CF-cell agent-table-elipse">
                                                            {row?.organization}
                                                        </TableCell>
                                                    </Tooltip>
                                                    <Tooltip title={<span className="font-size-12px">{row['agent_id']}</span>}>
                                                        <TableCell className="login-left-align inter-font text-center CF-cell agent-table-elipse">
                                                            {row?.agent_id}
                                                        </TableCell>
                                                    </Tooltip>
                                                    <Tooltip title={<span className="font-size-12px">{row['agent_name']}</span>}>
                                                        <TableCell className="login-left-align inter-font text-center CF-cell agent-table-elipse">
                                                            {row?.agent_name}
                                                        </TableCell>
                                                    </Tooltip>
                                                    <Tooltip title={<span className="font-size-12px">{row['agent_config_version']}</span>}>
                                                        <TableCell className="login-left-align inter-font text-center CF-cell agent-table-elipse">
                                                            {row['agent_config_version']}
                                                        </TableCell>
                                                    </Tooltip>
                                                    <Tooltip title={<span className="font-size-12px">{row['last_seen'] ? convertDateTimeIntoTimezone(row['last_seen'], authReducer.userTimezone, MMDDYYYYHMMSS_DATE_FORMAT_24_HRS) : "-"}</span>}>
                                                        <TableCell className="login-left-align inter-font text-center CF-cell agent-table-elipse">
                                                            {row['last_seen'] ? convertDateTimeIntoTimezone(row['last_seen'], authReducer.userTimezone, MMDDYYYYHMMSS_DATE_FORMAT_24_HRS) : "-"}
                                                        </TableCell>
                                                    </Tooltip>
                                                    <Tooltip title={<span className="font-size-12px">{row['created_at'] ? convertDateTimeIntoTimezone(row['created_at'], authReducer.userTimezone, MMDDYYYYHMMSS_DATE_FORMAT_24_HRS) : "-"}</span>}>
                                                        <TableCell className="login-left-align inter-font text-center CF-cell agent-table-elipse">
                                                            {row['created_at'] ? convertDateTimeIntoTimezone(row['created_at'], authReducer.userTimezone, MMDDYYYYHMMSS_DATE_FORMAT_24_HRS) : "-"}
                                                        </TableCell>
                                                    </Tooltip>
                                                    <Tooltip title={<span className="font-size-12px">{row['services'] && Array.isArray(row['services']) ? row['services']?.join(", ") : row['services']}</span>}>
                                                        <TableCell className="login-left-align inter-font text-center CF-cell agent-table-elipse">
                                                            {row['services'] && Array.isArray(row['services']) ? row['services']?.join(", ") : row['services']}
                                                        </TableCell>
                                                    </Tooltip>
                                                    <TableCell className="login-left-align inter-font text-center CF-cell">
                                                        <div style={{ display: "flex", justifyContent: "flex-start", paddingLeft: "20px", alignContent: "center", alignItems: "center" }}>
                                                            <span><SettingsIcon className="agent-table-edit-icon" onClick={() => { setAgentOrganizationId(row["organization_id"]); setAgentOrganizationName(row["organization"]);; setExistingAgentConfigName(row["deviceId"]); setIsActionPopup(true); setIsEditActionPopup(true); setCurrentAgentId(row["agent_id"]) }} /></span>
                                                        </div>
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        }
                                    </TableBody>}
                                {(agents?.length === 0) && <TableBody>
                                    <TableRow>
                                        <TableCell colSpan={12} className="noDataAvailableCss" align="center">No data available</TableCell>
                                    </TableRow>
                                </TableBody>}

                            </Table>
                        </TableContainer>
                    </div>
                </Grid>

            </Grid>
        </StyledEngineProvider></Fragment>);
}

const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
    errorReducer: state.errorReducer,
    startDate: state?.authReducer?.newSummaryStartDate,
    endDate: state?.authReducer?.newSummaryEndDate,
});

export default withRouter(
    connect(mapStateToProps, {
        getEdgeEventsDashBoard,
        getEdgeEventsFilterDashboard,
        downloadEdgeEventsTableDashbOard,
        downloadAsCSV,
        clearEdgeEventsDownload,
        getAgents
    })(AgentTable)
);