// Dp Alert page
import React, { useState, useEffect, useContext } from "react";
import { Accordion, AccordionDetails, AccordionSummary, Grid, } from "@mui/material";
import { withRouter, } from "react-router-dom";
import { connect } from "react-redux";
import CustomLoader from "../Loader/CustomLoaders";
import "./dpLevelAlertConfig.css";
import { useDispatch } from 'react-redux';
import _, { result } from "lodash"
import { toast } from 'react-toastify';
import { GET_DP_ALERTS, UPDATE_DP_ALERT, UPDATE_DP_ALERT_NOTIFICATION, DELETE_DP_ALERT_LINK_AVAILABILITY, GET_DP_ALERT_AUDIT_LOGS, UPDATE_DP_ALERT_STATUS } from "../../actions/types";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Pagination } from "../Pagination/index";

import { AuditLogContent } from "../AuditLogs/AuditLogContent";
import { getDpAlerts, getDpAlertAuditLogs } from "../../actions/Users/authenticateAlert";

import AccrodionComp from './AlertAccordionComponent';
import { convertDateTimeIntoTimezone } from "../../utils/util";
import { MMDDYYYYHMMSS_DATE_FORMAT_24_HRS } from "../../utils/constants";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

const Row = (props: { row, userTimezone: string, authReducer }) => {
    const { row, authReducer } = props;
    const [open, setOpen] = React.useState(false);

    return (
        <React.Fragment>
            <TableRow>
                <TableCell className="accordian-expand no-padding">
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon className="accordian-arrow" /> : <KeyboardArrowRightIcon className="accordian-arrow" />}
                    </IconButton>
                </TableCell>

                <TableCell className="audit-logs-font" align="left">{convertDateTimeIntoTimezone(row?.create_time?.toString(), authReducer.userTimezone, MMDDYYYYHMMSS_DATE_FORMAT_24_HRS)}</TableCell>
                <TableCell className="audit-logs-font" align="left">{row?.user}</TableCell>
                <TableCell className="audit-logs-font" align="left">{row?.metadata?.dp_name}</TableCell>
                <TableCell className="audit-logs-font" align="left">{row?.metadata?.description}</TableCell>
                <TableCell className="audit-logs-font" align="left">{row?.resource}</TableCell>
                <TableCell className="audit-logs-font" align="center">{row?.action}</TableCell>
                <TableCell className="audit-logs-font" align="left" scope="row">
                    {row?.ip_address}
                </TableCell>
            </TableRow>
            <TableRow className="padding-control">
                <TableCell className="no-padding-top-btm" colSpan={12}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <AuditLogContent
                            id={row?.id}
                            after={row?.after}
                            before={row?.before}
                            metadata={row?.metadata} />
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

const DpLevelAlertsConfig = (props) => {
    const dispatch = useDispatch();

    const {
        authReducer,
        siteId,
        getDpAlerts,
        getDpAlertAuditLogs,
    } = props;

    const [dpAlertData, setDpAlertData] = useState<any>([])
    const [dpAlertAuditLogs, setDpAlertAuditLogs] = useState<any>([])
    const [currentDpId, setCurrentDpId] = useState("")
    const [toastMessage, setToastMessage] = useState("")
    const [tableParams, setTableParams] = useState({
        page: 1,
        limit: 15,
        count: 0,
        totalCount: 0
    })

    // alert config accordion
    const [isAlertConfigAccordionExpanded, setIsAlertConfigAccordionExpanded] = useState(false);
    // Handle the expansion or collapse of the accordion
    const closeAlertConfigAccordion = () => {
        setIsAlertConfigAccordionExpanded(!isAlertConfigAccordionExpanded);  // Toggle the expanded state
    };

    useEffect(() => {
        if (authReducer?.selectedOu) {
            setCurrentDpId(authReducer?.selectedOu?.id)
            getDpAlerts(authReducer?.selectedOu?.id)
            handleFetchAuditLogs(tableParams?.page, tableParams?.limit)
        }
    }, [authReducer?.selectedOu])

    useEffect(() => {
        if (_.isEmpty(authReducer?.dpAlerts)) return;

        const order = { "Link Availability": 1, "Internet Status": 2, "Login Attempt": 3 }
        const reusltData = authReducer?.dpAlerts?.result
        reusltData?.sort((a, b) => order[a.alert_name] - order[b.alert_name])
        setDpAlertData(reusltData)
        handleFetchAuditLogs(tableParams?.page, tableParams?.limit)
        dispatch({ type: GET_DP_ALERTS, payload: {} })
    }, [authReducer?.dpAlerts])

    useEffect(() => {
        if (_.isEmpty(authReducer?.dpAlertAuditLogs)) return;

        const resultInfo = authReducer?.dpAlertAuditLogs?.result_info
        setDpAlertAuditLogs(authReducer?.dpAlertAuditLogs?.result)
        setTableParams({
            page: resultInfo?.page,
            limit: resultInfo?.per_page,
            count: resultInfo?.count,
            totalCount: resultInfo?.total_count
        })
    }, [authReducer?.dpAlertAuditLogs])

    useEffect(() => {
        if (!_.isEmpty(authReducer?.updateDpAlert)) {
            getDpAlerts(authReducer?.selectedOu?.id)
            dispatch({ type: UPDATE_DP_ALERT, payload: {} })
            setToastMessage("Configuration updated successfully. Changes may take upto 10 minutes")
        };
    }, [authReducer?.updateDpAlert])

    useEffect(() => {
        if (!_.isEmpty(authReducer?.updateDpAlertNotification)) {
            getDpAlerts(authReducer?.selectedOu?.id)
            dispatch({ type: UPDATE_DP_ALERT_NOTIFICATION, payload: {} })
            setToastMessage("Notification status has been updated successfully")
        };
    }, [authReducer?.updateDpAlertNotification])

    useEffect(() => {
        if (!_.isEmpty(authReducer?.updateDpAlertStatus)) {
            getDpAlerts(authReducer?.selectedOu?.id)
            dispatch({ type: UPDATE_DP_ALERT_STATUS, payload: {} })
            setToastMessage("Alert status has been updated successfully")
        };
    }, [authReducer?.updateDpAlertStatus])

    useEffect(() => {
        if (!_.isEmpty(authReducer?.deleteDpAlertLinkAvailability)) {
            getDpAlerts(authReducer?.selectedOu?.id)
            dispatch({ type: DELETE_DP_ALERT_LINK_AVAILABILITY, payload: {} })
            setToastMessage("Alerts deleted successfully")
        };
    }, [authReducer?.deleteDpAlertLinkAvailability])

    useEffect(() => {
        if (!(authReducer?.dpAlertsLoading || authReducer?.updateDpAlertLoading || authReducer?.updateDpAlertNotificationLoading || authReducer?.updateDpAlertStatusLoading  || authReducer?.deleteDpAlertLALoading || authReducer?.dpAlertLogsLoading)) {
            if (toastMessage) {
                toast.success(toastMessage, { position: toast.POSITION.BOTTOM_LEFT })
                setToastMessage("")
            }
        }
    }, [authReducer?.dpAlertsLoading, authReducer?.updateDpAlertLoading, authReducer?.updateDpAlertNotificationLoading, authReducer?.updateDpAlertStatusLoading, authReducer?.deleteDpAlertLALoading, authReducer?.dpAlertLogsLoading])

    const rowsOption = [10, 15, 25, 50, 100];

    const handleFetchAuditLogs = (page: number, limit: number) => {
        getDpAlertAuditLogs(authReducer?.selectedOu?.id, {
            limit: limit,
            page: page
        })
    }

    const handleChangeSize = (event: any) => {
        const currentLimit = event.target.value
        setTableParams({ ...tableParams, limit: currentLimit, page: 1 });
        handleFetchAuditLogs(tableParams?.page, currentLimit)
        getDpAlertAuditLogs(authReducer?.selectedOu?.id, {
            limit: currentLimit,
            page: tableParams.page
        })
    };

    const handleChangePage = (event: any, newPage: number) => {
        setTableParams({ ...tableParams, page: newPage });
        handleFetchAuditLogs(newPage, tableParams?.limit)
        getDpAlertAuditLogs(authReducer?.selectedOu?.id, {
            limit: tableParams.limit,
            page: newPage
        })
    };

    return (
        <>
            <Grid className="dp-level-alerts-accordTree">
                <Accordion className="dp-alert-config-accordion" expanded={isAlertConfigAccordionExpanded} onChange={closeAlertConfigAccordion}>
                    <Grid item xs={12} lg={12} className="dp-alert-config-accordion-summary">
                        <AccordionSummary expandIcon={isAlertConfigAccordionExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}>
                            <span className="accordion-title">Alerts Config</span>
                        </AccordionSummary>
                    </Grid>
                    <AccordionDetails className="dp-alert-config-accordion-details">
                        <Grid container spacing={2} className="dp-alert-config-accordion-details-container">
                            <Grid item xs={12} className="dp-alert-config-accordion-details-container-child">
                                <Grid container spacing={2} className="dp-alert-config-accordion-details-container-child-child">
                                    <Grid item xs={12} sm={6} md={6} lg={6} sx={{ height: "100%", padding: "0px" }} className="link-availability-parent">
                                        {
                                            dpAlertData?.map((alert, index) => {
                                                if (alert.alert_name === "Link Availability") {
                                                    return (
                                                        <AccrodionComp
                                                            key={index}
                                                            className="item"
                                                            siteId={siteId}
                                                            alert={alert}
                                                            setMenuAnchorElement={props.setMenuAnchorElement}
                                                            currentDpId={currentDpId}
                                                        />
                                                    );
                                                }
                                                return null;
                                            })
                                        }
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6} lg={6} sx={{ height: "100%" }} >
                                        <Grid container direction="column" spacing={2} sx={{ height: "100%" }}>
                                            {
                                                dpAlertData?.map((alert, index) => {
                                                    if (alert.alert_name === "Internet Status" || alert.alert_name === "Login Attempt") {
                                                        return (
                                                            <Grid item key={index} sx={{ height: "100%" }} className="internet-status-login-parent" >
                                                                <AccrodionComp
                                                                    className="item"
                                                                    siteId={siteId}
                                                                    alert={alert}
                                                                    setMenuAnchorElement={props.setMenuAnchorElement}
                                                                    currentDpId={currentDpId}
                                                                />
                                                            </Grid>
                                                        );
                                                    }
                                                    return null;
                                                })
                                            }
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>
            </Grid>
            <Grid item xs={12} lg={12}>
                <div className="dp-alert-logs">
                    <div className="audit-data-container">
                    <div className="alerts-config-change-log-text">Alerts Config Change Log</div>
                        <Paper className="pagination-position">
                            <Pagination
                                rowsPerPageOptions={rowsOption}
                                count={tableParams?.totalCount}
                                rowsPerPage={tableParams?.limit}
                                page={tableParams?.page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeSize}
                            />
                        </Paper>
                        <Paper className="audit-container-data">
                            <TableContainer className="audit-table-root">
                                <Table size="small" className="audit-table-data" aria-label="collapsible sticky table" stickyHeader>
                                    <TableHead className="audit-data-head">
                                        <TableRow>
                                            <TableCell />
                                            <TableCell className="audit-data-header">TIME STAMP</TableCell>
                                            <TableCell className="audit-data-header">USER</TableCell>
                                            <TableCell className="audit-data-header">ORGANIZATION</TableCell>
                                            <TableCell className="audit-data-header">DESCRIPTION</TableCell>
                                            <TableCell className="audit-data-header">RESOURCE</TableCell>
                                            <TableCell align="center" className="audit-data-header">ACTION</TableCell>
                                            <TableCell className="audit-data-header">IP ADDRESS</TableCell>

                                        </TableRow>
                                    </TableHead>
                                    <TableBody className="audit-data-header audit-logs-font">
                                        {dpAlertAuditLogs?.map((row: any) => (
                                            <Row key={row?.id} row={row} userTimezone={authReducer?.userTimezone} authReducer={authReducer} />
                                        ))}
                                        {dpAlertAuditLogs?.length === 0 && (
                                            <TableRow>
                                                <TableCell colSpan={12} align='center' className="audit-logs-no-data">
                                                    No Data Available
                                                </TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>

                    </div>
                </div>
            </Grid>
        </>
    );

}

const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
});

export default withRouter(
    connect(mapStateToProps, {
        getDpAlerts,
        getDpAlertAuditLogs
    })(DpLevelAlertsConfig)
);