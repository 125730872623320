import React, { useEffect, useState } from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import { Grid, Accordion, AccordionSummary, Typography, AccordionDetails, Tooltip, TableHead, Stack, Box, Paper, Divider, Chip, ToggleButtonGroup, ToggleButton, ListItemButton, List, Button } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import _ from "lodash";
import "./TableSummary.css";
import { readableBytes, readableBits, readableBytesAsGB, convertDateTimeIntoTimezone, redirectToTopolgyTabs } from '../../utils/util';
import { getHubInternetDropoffList } from '../../actions/Users/authenticateDashboard';
import { connect } from "react-redux";
import { withRouter, useLocation, useHistory } from "react-router-dom";
import { PublishedWithChanges } from "@mui/icons-material";

const HubInternetDropoff = (props) => {
    const {k4Id, deviceId, HubName="", getHubInternetDropoffList, hubInternetDropoffList} = props;
    const [tableData, setTableData] = useState<any[]>([]);
    useEffect ( () => {
        if(k4Id && deviceId){
            getHubInternetDropoffList({
                k4Id: k4Id,
                deviceId: deviceId
            })
        }
    },[k4Id, deviceId])

    useEffect(() => {
        if(!_.isEmpty(hubInternetDropoffList)) {
            const data = hubInternetDropoffList?.hasOwnProperty('data') ? hubInternetDropoffList?.data : {};
            const dataArr:any = [];
            data?.rows && data?.rows?.forEach((item, i) => {
                if (i != 0) {
                    const itemArr = data?.columns?.reduce((acc, columItem, i) => {
                        acc[columItem] = item[i];
                        return acc;
                    }, {})
                    dataArr.push(itemArr)
                }
            })
            setTableData(dataArr);
        }
    }, [hubInternetDropoffList])
    const selectedHub = tableData.find(hub => hub.name === HubName);
    const ipList = selectedHub?.ip_port_list ? selectedHub.ip_port_list.split(",") : [];
    console.log("data:",selectedHub);
    return(
        <div>
            {!HubName && <Grid className="marg-left-auto alignTopbarItemsCenter">
                <div className="download-summary-btn marg-left-auto buttonWidth"><Button>Add New Hub Internet Dropoff</Button></div>
            </Grid> }
            {!HubName && <TableContainer className="usageReports-FleetDashboard loginHistory-tableContainer">
                    <Table aria-label="simple sticky table" stickyHeader>
                        <TableHead className="usageReports-tableHead config-tableRow">
                            <TableRow className="UsageReports-tableRow login-tableRow">
                                <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell new-summary-left">
                                    HUB INTERNET DROPOFF ALIAS
                                </TableCell>
                                <TableCell className="fleetDataTable usage-fleetDataTable login-left-align login-tableCell new-summary-left">
                                    IP:(PORTS)
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        {tableData && 0 != tableData.length ? (
                            <TableBody className="tableBody usage-tableBody Table-Body-Opacity">
                                {tableData.map((row: any, rowIndex: number) => (
                                <TableRow key={rowIndex} className="loginHistory-tableRow new-summary-usage-tr Table-Row-Opacity">
                                    <TableCell className="login-left-align inter-font Table-Cell-Opacity">{row.name}</TableCell>
                                    <TableCell className="login-left-align inter-font Table-Cell-Opacity">{row.ip_port_list}</TableCell>
                                </TableRow>))}
                            </TableBody>) : 
                            <TableBody>
                                <TableRow>
                                    <TableCell colSpan={8} align='center'>
                                        No data available
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        }

                    </Table>
            </TableContainer>}
            {HubName && <div>
                <h4 className="ipPort">IP : PORT</h4>
                <ul className="portList">
                    {ipList.map((ip, index) => (
                        <li key={index} style={{ padding: "5px", borderBottom: index !== ipList.length - 1 ? "1px solid #ddd" : "none" }}>
                        {ip.trim()}
                        </li>
                    ))}
                </ul>
            </div>}
        </div>
    )
}
const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
    errorReducer: state.errorReducer,
    hubInternetDropoffList: state.authReducer?.hubInternetDropoffList
});
export default withRouter(
    connect(mapStateToProps, { getHubInternetDropoffList })(HubInternetDropoff)
);

