import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Autocomplete, Button, DialogActions, Divider, Grid, TextField, DialogContent, Switch, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch } from 'react-redux';
import _ from "lodash";
import { toast } from 'react-toastify';
import { CONFIRM_DIALOG_TITLE, SERVICE_FUNCTION_TO_FEATURE } from "../../../config";
import ConfirmDialog from "../../ConfirmDialog";
import { backupConfigNow, applyGoldenConfig, backupConfigData, getLicenseTypes, upgradeDevicetoLatestEOSversion, rebootDevice, upgradeInventoryLicense } from "../../../actions/Users/authenticateInventory";
import ConfigDropDownWithDelete from "../ConfigDropDown/ConfigDropDownWithDelete";
import SelectTag from "../../InventoryWarehouseTable/SelectTag";
import { checkLoggedInUserAuthorizedToViewPage, convertDateTimeIntoTimezone } from "../../../utils/util";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import InfoIcon from '@mui/icons-material/Info';
import { MMDDYYYYHMMSS_DATE_FORMAT_24_HRS } from "../../../utils/constants";
import { compare } from "compare-versions";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import WanActionsPopup from "./AgentWanActions";
import { createAgentWan, getAgentWans, updateAgentWan, deleteAgentWan } from "../../../actions/Users/authenticateHub";
import { CREATE_AGENT_WAN, UPDATE_AGENT_WAN, DELETE_AGENT_WAN } from "../../../actions/types";
import "./AgentWan.css"


export const VlanPopup = (props) => {
    const dispatch = useDispatch();
    const { authReducer, createAgentWan, getAgentWans, setIsActionPopup, isEditActionPopup, setIsEditActionPopup, setSelectedAction, selectedAction,  updateAgentWan, deleteAgentWan, selectedDp } = props;

    const [wanItems, setWanItems] = useState<any[]>([])
    const [wans, setWans] = useState<any>([])
    const [wanDropdownList, setWanDropdownList] = useState<any>([])
    const [checkInputValue, setCheckInputValue] = useState(false)
    const [isCreate, setIsCreate] = useState(false)
    const [selectedValue, setSelectedValue] = useState<any>(null)

    const [confirmDialog, setConfirmDialog] = useState({
        isOpen: false,
        title: "",
        subTitle: "",
        onConfirm: {},
    });
    const [wanName, setWanName] = useState<any>("")

    useEffect(() => {
        if (selectedDp && selectedDp?.id) {
            setWans([])
            setSelectedValue(null)
            getAgentWans(selectedDp?.id)
        }
    }, [selectedDp])

    useEffect(() => {
        if (!_.isEmpty(authReducer?.getAgentWans) && !authReducer?.getAgentWansLoading) {
            setWans(authReducer?.getAgentWans?.data || [])
        }
    }, [authReducer?.getAgentWans])

    useEffect(() => {
        let dropDownList: any = []
        if (wans?.length) {
            for (let item of wans) {
                dropDownList.push({
                    label: item?.name,
                    id: item?.id
                })
            }
        }

        setWanDropdownList(dropDownList)
    }, [wans])

    useEffect(() => {
        if (selectedValue && selectedValue?.id) {
            for (let item of wans) {
                if (item?.id === selectedValue?.id) {
                    setWanItems(item?.wans)
                    break
                }
            }
        } else if (selectedValue && selectedValue?.label && !selectedValue.id) {
            const newAction = {
                id: wanItems.length,
                name: "",
                wanType: "",
                ip: "",
                gateway: "",
                vid: "",
                interface: "",
            };
            setWanItems([newAction])
        } else {
            setWanItems([])
        }
    }, [selectedValue])

    useEffect(() => {
        if (!_.isEmpty(authReducer?.createAgentWan) && !authReducer?.createAgentWanLoading) {
            getAgentWans(selectedDp?.id)
            toast.success("Wan has been created successfully", { position: toast.POSITION.BOTTOM_LEFT })
            dispatch({ type: CREATE_AGENT_WAN, payload: {} })
            getAgentWans(selectedDp?.id)

            handleClear()
            // setIsActionPopup(false)
        };
    }, [authReducer?.createAgentWanLoading, authReducer?.createAgentWan])

    useEffect(() => {
        if (!_.isEmpty(authReducer?.updateAgentWan) && !authReducer?.updateAgentWanLoading) {
            getAgentWans(selectedDp?.id)
            toast.success("Wan has been updated successfully", { position: toast.POSITION.BOTTOM_LEFT })
            dispatch({ type: UPDATE_AGENT_WAN, payload: {} })
            getAgentWans(selectedDp?.id)

            handleClear()
            // setIsActionPopup(false)
        };
    }, [authReducer?.updateAgentWanLoading, authReducer?.updateAgentWan])

    useEffect(() => {
        if (!_.isEmpty(authReducer?.deleteAgentWan) && !authReducer?.deleteAgentWanLoading) {
            getAgentWans(selectedDp?.id)
            toast.success("Wan has been deleted successfully", { position: toast.POSITION.BOTTOM_LEFT })
            dispatch({ type: DELETE_AGENT_WAN, payload: {} })
            getAgentWans(selectedDp?.id)

            handleClear()
            // setIsActionPopup(false)
        };
    }, [authReducer?.deleteAgentWanLoading, authReducer?.deleteAgentWan])

    const handleSave = () => {
        if (wanName?.id) {
            updateAgentWan(selectedDp?.id, wanName?.id, {
                name: wanName?.label,
                wans: wanItems
            })
        } else {
            createAgentWan(selectedDp?.id, {
                name: wanName?.label,
                wans: wanItems
            })
        }
    }

    const handleClose = () => {
        setIsActionPopup(false)
    }

    const handleClear = () => {
        setWanItems([])
        setWanDropdownList([])
        setCheckInputValue(false)
        setIsCreate(false)
        setSelectedValue(null)
    }

    const handleDeleteAction = (event: any, option: any) => {
        event?.stopPropagation();
        // setWanDropdownList([wanDropdownList[1]])
        // toast.success("WAN has been deleted successfully", { position: toast.POSITION.BOTTOM_LEFT })
        if (option?.id) {
            deleteAgentWan(selectedDp?.id, option?.id) 
        }
    }

    return (
        <div className="sh--agent-wan-container">
            <div style={{ minHeight: "330px" }}>
                <Grid container className="agent-wan-grid-container">
                    <Grid xs={2} sm={2} md={2} lg={3} style={{ color: "#45464E", display: "flex", alignItems: "center" }}>Agent WAN Profile</Grid>
                    <Grid xs={4} sm={4} md={4} lg={4}>
                        <ConfigDropDownWithDelete names={wanDropdownList} check={setCheckInputValue} setConfigName={setWanName} setIsCreate={setIsCreate} value={selectedValue} setValue={setSelectedValue} handleDeleteAction={handleDeleteAction}/>
                    </Grid>
                </Grid>
                <WanActionsPopup wanItems={wanItems} setWanItems={setWanItems} isEditActionPopup={isEditActionPopup} />
            </div>
            <div className="agent-wan-display-flex">
                <Button className="agent-wan-cancel-button" style={{ color: "#264c86" }} onClick={() => { handleClose() }}> Cancel </Button>
                <Button variant="contained" style={{ background: "#264c86" }} onClick={() => { handleSave() }}>Save</Button>
            </div>
        </div>
    )
}


const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
    errorReducer: state.errorReducer,
});

export default withRouter(
    connect(mapStateToProps, {
        createAgentWan,
        getAgentWans,
        updateAgentWan, 
        deleteAgentWan
    })(VlanPopup)
);