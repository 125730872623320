import React, { useEffect, useRef, useState } from "react";
import { Grid, Button, ButtonGroup, Menu, FormGroup, FormControlLabel, Checkbox, Divider } from "@mui/material";
import Paper from "@material-ui/core/Paper";
import { connect, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from '@mui/icons-material/Close';
import AlertTable from "../alertTable";
import TableSummary, { getURIParams } from "../TableSummary";
import LocationMap from "../LocationMap";
import { Fragment } from "react";
import { PER_PAGE_ITEMS, INTERVALS, NEW_PER_PAGE_ITEMS } from "../../constants/Constants";
import "./FleetSelection.css";
import Autocomplete from "@mui/material/Autocomplete";
import { DEFAULT_PAGE_SIZE } from "../../constants/Constants";
import Stack from "@mui/material/Stack";
import { MMDDYYYYHMMSS_DATE_FORMAT, MMDDYYYYHMMSS_DATE_FORMAT_24_HRS } from "../../utils/constants";
import moment from "moment-timezone";
import onlineIcon from "../../asset/image/online.svg";
import offlineIcon from "../../asset/image/offline.svg";
import { getEncodedURI, getDecodeURI, readableBytesAsGB } from "../../utils/util"
import _ from "lodash";
import MapLeaflet from "../../../src/vis/react-app/src/components/Map/MapLeafLet";
import '../../../src/vis/react-app/src/components/Map/Map.css';
import { getVesselLastLocationForSepcific } from "../../vis/react-app/src/actions/singleStoreActions";
import { getAvilWindow, getIntervalCheck, getIntervalInFormat, getIntervalInNewFormat, getVesselSummaryTableCount } from "../../actions/Users/authenticateDashboard";
import { downloadAsCSV, CsvParameters } from "../../actions/Users/authenticateCsvDownload";
import TuneIcon from '@mui/icons-material/Tune';
import close from "../../asset/image/close.svg";
import { CollapsibleSection } from "../../UserScreen/FirewallReport/FirewallFilters";
import { Pagination } from "../Pagination";
import { AddBoxSharp } from "@mui/icons-material";
import Download from '../../asset/image/DownloadUsage.svg';
import { GET_CSV_DATA_TO_DOWNLOAD, SET_VESSEL_SUMMARY_LOADING } from "../../actions/types";
import DownloadCSV, { clearCsvDownload } from "../DownloadCSV";
import {vesselSummaryDashboard, vesselLinkStatusSummaryDashboard} from '../../actions/Users/authenticateDashboard';

const convertToCsv = (data: any[][]) => {
  const [_columns, ...rows] = data;
  const csvRows: any[] = [];
  rows.forEach(row => {
    csvRows.push([row[1], row[17],row[3] ? row[3]?.replaceAll(",", "") : row[3], row[8], row[16], row[10], row[9], row[11], (row[22] == 1 || row[23] == 1) ? `${row[4]} (mpk)` : row[4]])
  })
  return [['Site', 'Organization', 'Location', 'Usage (GB)', 'Quota (%)', 'Availability (%)', 'Top Apps', 'Clients', 'Internet Status'], ...csvRows]
}


const FleetSelection = (props) => {

  const { vesselList, search, setSearch, interval, setInterval, match, history, authReducer, location, products, loader, isMapEnabled, downloadAsCSV, vesselSummaryDashboard, vesselLinkStatusSummaryDashboard, newSummaryStartDate, newSummaryEndDate } = props;
  //   const { search, setSearch,filterBy,setFilterBy, interval, setInterval, match, history, authReducer, location,filterByList } = props;
  const [selectedFleetButton, setSelectedFleetButton] = useState(match?.params?.tab ? match.params.tab : "sites");
  const [searchValue, setSearchValue] = React.useState("");
  // const [filterByValue, setFilterByValue] = React.useState("")
  const [page, setPage] = React.useState(0);
  const [limit, setLimit] = useState(DEFAULT_PAGE_SIZE);
  const [isInterValAndTime, setIsIntervalAndTime] = useState(true);
  const [isPagination, setPagination] = useState(true);
  const [timewindow, setTimewindow] = React.useState("");
  const [fromItem, setFromItem] = useState<number>(0);
  const [toItem, setToItem] = useState<number>(0);
  const [total, setTotal] = React.useState(0);
  const [pages, setPages] = React.useState(0);
  const [locationFilter, setLocationFilter] = React.useState({ alerting: true, nonAlerting: true });
  const [alertCount, setAlertCount] = React.useState({ alerting: 0, nonAlerting: 0 });
  const [currentPageItemType, setCurrentPageItemType] = React.useState("");
  const ENTER_KEY_CODE = "Enter";

  const [isSearchIconVisible, setSearchIconVisibility] = useState<Boolean>(true);
  const [k4idsList, setK4idsList] = useState([]);
  let _q = getDecodeURI(location?.search);
  const [filterPopup, setFilterPopup] = useState(false);
  const defaultStatuses = [{"label":"Online", "name": "Online", "selected":false}, {"label":"Offline", "name": "Offline", "selected":false}, {"label":"Unknown", "name": "Inactive", "selected":false}, {"label":"mpk", "name": "MPK", "selected":false}, {"label": "captive", "name": "Captive", "selected": false}];
  const [intrenetStatuses, setIntrenetStatuses] = useState([...defaultStatuses]);
  const [anchorElRef, setAnchorElRef] = useState<HTMLElement>();
  const searchTimeoutRef = useRef<NodeJS.Timeout>();
  const dispatch = useDispatch();

  useEffect(()=>{
    if (filterPopup) {
      const selectedStatuses = defaultStatuses;
      let params: any = getDecodeURI(location?.search)

      if (params.hasOwnProperty("filterApplied") && params.hasOwnProperty("internetStatus")) {
        let statuses = params.internetStatus.split(',');
        for (let i = 0; i < statuses.length; i++) {
          let label = statuses[i];
          selectedStatuses.map(status => {
            if (status.hasOwnProperty("label") && label === status.label) {
              status.selected = true;
            }
          })
        }
      }
      setIntrenetStatuses(selectedStatuses)
    }
  },[filterPopup])

  useEffect(() => {
    updateView(match?.params?.tab ? match.params.tab : "Sites");
  }, [location]);

  useEffect(() => {
    setSearchValue(search);
    setSearchIconVisibility(search == "");
  }, [search]);


  useEffect(() => {
    let list:any = [];
    if(authReducer?.vesselSummaryTableData?.data && authReducer?.vesselSummaryTableData?.data?.rows?.length > 0 ) {
      authReducer?.vesselSummaryTableData?.data?.rows?.map((item) => {
        list.push(item[0])
      })
    }
    setK4idsList(list);
  },[authReducer?.vesselSummaryTableData])

  useEffect(() => {
    if (authReducer.csvDataDownloded && authReducer.csvDataDownloded.length > 0) {
      if (authReducer.csvDownloadId === 'user-dashboard') {
        DownloadCSV(convertToCsv(authReducer.csvDataDownloded), {
          formatters: {
            0: 'ESC-COMMA',
            1: 'ESC-COMMA',
            2: 'ESC-COMMA',
            3: 'GB',
            4: 'ESC-COMMA',
            5: 'ESC-COMMA',
            6: 'ESC-COMMA'
          }
        });
        return () => {
          dispatch(clearCsvDownload())
        }
      }
    }
  }, [authReducer.csvDataDownloded])

  // useEffect(() => {
  //   getVesselLastLocationForSepcific(k4idsList);
  // },[k4idsList])

  // useEffect(() => {
  //   console.log("testFilterBy",filterBy)

  //   // setFilterByValue(filterBy);
  // }, [filterBy]);


  const updateView = (tab: string) => {
    setSelectedFleetButton(tab);
    if ('sites' === tab) {
      setPagination(true);
      setIsIntervalAndTime(true);
    } else if ('alerts' === tab) {
      setPagination(true);
      setIsIntervalAndTime(false);
    } else if ('locations' === tab) {
      setPagination(false);
      setIsIntervalAndTime(true);
    }
  }

  const updatePageInfo = (page: number, limit: number, itemCount: number, pages: number, total: number, pageItemType: string) => {
    setFromItem(itemCount > 0 ? (page * limit) + 1 : 0);
    setToItem((page * limit) + itemCount);
    setTotal(total);
    setPages(pages);
    setCurrentPageItemType(pageItemType);
  };

  const keyCallback = (updatedTimestamp) => {
    if (updatedTimestamp) {
      //let updatedTime = moment(updatedTimestamp).format(MMDDYYYYHMMSS_DATE_FORMAT);
      let updatedTime = moment(updatedTimestamp).utc().format(MMDDYYYYHMMSS_DATE_FORMAT_24_HRS);
      setTimewindow(`Last update at: ${updatedTime}`);
    } else {
      setTimewindow("");
    }
  }

  const doSearch = (value: string) => {
    let params: any = getDecodeURI(location?.search);
    // params.filterBy = filterBy ? filterBy.value ? filterBy.value : filterBy : "" ;
    value ? params.search = value : delete params.search;
    delete params.page;
    doNavigate(location.url, params);
  };

  const searchBoxKeyDown = (event: any) => {
    if (event && event.key === ENTER_KEY_CODE) {
      doSearch(searchValue);
    }
  }

  const handlePerPageChange = (e: any) => {
    let _perPage = parseInt(e.target.value);
    if (Number.isInteger(_perPage)) {
      let params: any = getDecodeURI(location?.search);
      params.page = 0;
      params.limit = _perPage;
      doNavigate(location.url, params);
    }
  };

  const handlePagination = (e, value) => {
    let params: any = getDecodeURI(location?.search);
    params.page = value - 1;
    doNavigate(location.url, params);
  };

  const handleIntervalChange = (e, _interval) => {
    let params: any = getDecodeURI(location?.search);
    params.interval = _interval.value;
    params.page = 0;
    doNavigate(location.url, params);
  };

  const doNavigate = (pathname, params = {}) => {
    history.push({ pathname: pathname, search: `?${getEncodedURI(params)}` });
  }

  const doNavigateToVessels = (pathname) => {
    let p: any = getDecodeURI(location?.search);
    let params: any = {};
    if (p.hasOwnProperty("search") && !_.isEmpty(p.search)) {
      params.search = p.search;
    }

    // if (p.hasOwnProperty("filterBy") && !_.isEmpty(p.filterBy)) {
    //   params.filterBy = p.filterBy;
    // }

    if (p.hasOwnProperty("ouName") && !_.isEmpty(p.ouName) && p.hasOwnProperty("ouId") && !_.isEmpty(p.ouId)) {
      params.ouName = p.ouName
      params.ouId = p.ouId;
    }

    if (p.hasOwnProperty("K4Ids") && !_.isEmpty(p.k4Ids)) {
      params.k4Ids = p.k4Ids;
    }

    if (p.hasOwnProperty("interval") && !_.isEmpty(p.interval)) {
      params.interval = p.interval;
    }

    doNavigate(pathname, params);
  }

  const doNavigateToAlerts = (pathname) => {
    let p: any = getDecodeURI(location?.search);
    let params: any = {};
    if (p.hasOwnProperty("search") && !_.isEmpty(p.search)) {
      params.search = p.search;
    }

    if (p.hasOwnProperty("ouName") && !_.isEmpty(p.ouName) && p.hasOwnProperty("ouId") && !_.isEmpty(p.ouId)) {
      params.ouName = p.ouName
      params.ouId = p.ouId;
    }

    if (p.hasOwnProperty("K4Ids") && !_.isEmpty(p.k4Ids)) {
      params.k4Ids = p.k4Ids;
    }

    if (p.hasOwnProperty("interval") && !_.isEmpty(p.interval)) {
      params.interval = p.interval;
    }
    doNavigate(pathname, params);
  }

  const doNavigateToLocations = (pathname) => {
    let p: any = getDecodeURI(location?.search);
    let params: any = {};

    if (p.hasOwnProperty("ouName") && !_.isEmpty(p.ouName) && p.hasOwnProperty("ouId") && !_.isEmpty(p.ouId)) {
      params.ouName = p.ouName
      params.ouId = p.ouId;
    }

    if (p.hasOwnProperty("K4Ids") && !_.isEmpty(p.k4Ids)) {
      params.k4Ids = p.k4Ids;
    }

    if (p.hasOwnProperty("interval") && !_.isEmpty(p.interval)) {
      params.interval = p.interval;
    }

    if (p.hasOwnProperty("search") && !_.isEmpty(p.search)) {
      params.search = p.search;
    }
    doNavigate(pathname, params);
  }

  const _setLocationFilter = (filter) => {
    if (filter) {
      let params: any = getDecodeURI(location?.search);
      params.alerting = filter.alerting;
      params.nonAlerting = filter.nonAlerting;
      doNavigate(location.url, params);
    }
  }

  const clearFilters = () =>{
    let params: any = getDecodeURI(location?.search);
    setIntrenetStatuses(defaultStatuses);
    if(params.hasOwnProperty("filterApplied")){
      setFilterPopup(false)
      delete  params.internetStatus;
      delete params.filterApplied;
      doNavigate(location.url, params);
    }
  }

  const handleApplyFilters = () =>{
    let _statuses: string[] = [];
    intrenetStatuses.map((status)=>{
      if(status["selected"]){
        _statuses.push(status["label"]);
      }
    })

    let params: any = getDecodeURI(location?.search);
    if(params.hasOwnProperty("filterApplied")&& _statuses.length == 0){
      delete  params.internetStatus;
      delete params.filterApplied;
      setFilterPopup(false);
      doNavigate(location.url, params);
    } else if (_statuses.length>0){
      params.internetStatus = _statuses.toString();
      params.filterApplied = true;
      params.page = 0;
      setFilterPopup(false);
      doNavigate(location.url, params);
    }
    let filter= "";
    if(_statuses.length > 0){
      let statuses = "'" + _statuses.join("','") + "'";
      filter = "AND status IN(" + statuses+")";
    } else {
      filter = '';
    }
    vesselSummaryDashboard(vesselList, search, interval.value, authReducer?.selectedOu?.name, undefined, filter, newSummaryStartDate, newSummaryEndDate);
    vesselLinkStatusSummaryDashboard(vesselList, search, interval.value, authReducer?.selectedOu?.name, undefined, filter, newSummaryStartDate, newSummaryEndDate);
  }

  const handleInternetStatusFilterChange = (index,checked: boolean) =>{
    const statues = [...intrenetStatuses];
    statues[index]["selected"] = checked;
    setIntrenetStatuses(statues);
  }

  let vesselCurrLocation = products?.vesselCurrentLocationDetails;

  const vesselLocationDetails: string[] = vesselCurrLocation !== undefined && vesselCurrLocation.length > 0 &&
  vesselCurrLocation.map((item: any) => {
      if (item[2] === null || item[3] === null) {
          return;
      }
      let lastSeenTime = moment.utc(item[1], 'YYYY-MM-DD HH:mm:ss.SSS +-HHmm').toISOString();
      return { "long": item[3], "lat": item[2], "name": item[5], "heading": null, "locationId": item[0], "lastSeen": lastSeenTime}
  })

  const filterPopClick = (e: React.MouseEvent<HTMLElement>) => {
    setAnchorElRef(e.currentTarget)
    setFilterPopup(true);
  }

  const handleOnSearchClick = () => {
    doSearch(searchValue);
  }

  const handleOnSearchClear = () => {
    setSearchValue('');
    doSearch('');
  }

  const handleOnSearchChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setSearchValue(e.target.value);
    if (searchTimeoutRef.current) {
      clearTimeout(searchTimeoutRef.current);
    }
    searchTimeoutRef.current = setTimeout(() => {
      searchTimeoutRef.current = undefined;
      doSearch(e.target.value);
    }, 1000)
  }

  const handleDownloadReport = () => {
    let _q = getDecodeURI(location?.search);

    const { _vessels, ouName } = getURIParams(_q, authReducer);
    if (ouName !== '' && !_.isEmpty(interval) && _vessels) {
      const _interval = interval.value;
      let isClickHouse = true

      const vesselMap: any = _vessels?.map((item: any) => item["id"]);
      const searchText = _q.search ? _q.search : "";
      const filter = "AND status IN('" + defaultStatuses.map(status => status.label).join("','") + "')";
      const filterData = "AND criticalAlertCount IN('" + defaultStatuses.map(status => status.label).join("','") + "')";

      getVesselSummaryTableCount(vesselMap, searchText, filter).then((res) => {
        const k4idhere = vesselMap.length > 0 ? `${"'" + vesselMap.join("','") + "'"}` : null;
        let payload: any = {
          "dp": "",
          "locationID": (k4idhere && k4idhere !== '') ? `${k4idhere}` : "",
          "sortColumn": _q.sort ? _q.sort : "criticalAlertCount",
          "sortOrder": _q.sortOrder ? _q.sortOrder : "asc",
          "pageOffset": 0,
          "pageSize": res?.data?.data?.total?.data[0],
          "search": searchText,
          "filter": filterData
        };
        if (isClickHouse) {
          const startDate = authReducer.newSummaryStartDate;
          const endDate = authReducer.newSummaryEndDate;
          payload["interval"] = getIntervalInNewFormat(_interval);
          payload['avail_window'] = getAvilWindow(_interval);
          payload['table'] = ( _interval === "15mins" || _interval === "1h" || _interval === "2h" || _interval === "6h" || _interval === "12h" || interval === "1d" ) ? "5m" : '1h';
          payload["apps_interval"] = _interval === '15mins' ? getIntervalInNewFormat("1h") : getIntervalInNewFormat(_interval);
        } else {
          payload['avail_window'] = getAvilWindow(_interval);
          payload["interval"] = getIntervalInFormat(_interval);
          payload["apps_interval"] = _interval === '15mins' ? getIntervalInFormat("1h") : getIntervalInFormat(_interval);
        }

        const getQueryName = (sortColumn) => {
          switch (sortColumn) {
            case "clients": return 'SPORTS_SITE_SUMMARY_CLIENTS';
            case "totalUsage": return "SPORTS_SITE_SUMMARY_TUSAGE";
            case "availibility": return "SPORTS_SITE_SUMMARY_AVAILIBILITY";
            case "max_quota": return "SPORTS_SITE_SUMMARY_QUOTA";
            default: return "SPORTS_SITE_SUMMARY_SYNC";
        }
      }
        const params: CsvParameters = {
          type: "QUERY_CH",
          id: "user-dashboard",
          payload,
          queryName: getQueryName( _q.sort ? _q.sort : "criticalAlertCount"),
          successHandler: SET_VESSEL_SUMMARY_LOADING
        }
        downloadAsCSV(params);
      })
    }
  }
  return (
    <Grid style={{ width: "100%",maxHeight:"76vh"}} key={location.key} className="topbarMarginReset">
      <Grid container rowSpacing={1}>
        <Grid item xs={12} sm={6} md={12} lg={12} className="pading-parent topbarPaddingReset">
          <Grid container spacing={2} className="alignTopbarItemsCenter topbarWidth">

            {/* <Grid item xs={6} sm={6} md={4} lg={5}> 
             <Autocomplete
              id="status_list" className="fliterAlertDropdown filterAction filterBy"
              options={filterByList}
              onChange={(e, newValue: any) => { setFilterBy(newValue) }}
              // defaultValue={defaultFilterSearchValue}
              value={filterBy}
              style={{marginTop:"8px"}}
              renderInput={(params) => (
                <TextField {...params} label="Filter By" InputLabelProps={{ className: "filterStateName" }}   />
              )}
            />
            </Grid> */}
            <Grid item xs={4} sm={4} md={4} lg={4} className="filterButtonMaxWidth">
              <Button variant="outlined" startIcon={<TuneIcon id="filterIcon"/>} onClick={filterPopClick} id="filterButton">
                Filter
              </Button>
              {filterPopup ?
                <Menu
                  open={filterPopup}
                  className="rc--filter_menu_container_dashboard sites-rc--filter_menu_container_dashboard menuScroll"
                  anchorEl={anchorElRef}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                >
                  <Grid className="sitesFilterHeaderMargin">
                    <div className="filter-header">Filters</div>
                    <img src={close} onClick={() => { setFilterPopup(false) }} alt="Close" className="activityReportsFilter-close"/>
                  </Grid>
                  <Grid className="filter-divider-width">
                    <Divider className="filter-border-color" />
                  </Grid>
                  <Grid className="usageFilterComponent">
                    <div className="Form">
                      <Grid className="am-table">
                        <Grid className="dash-filter-pop">
                          <Grid>
                            <Grid className="sitesFiltersPadding">
                              <Grid className="internetr-status-header-paddingBottom">
                                <span className="internet-status-header">Internet status</span>
                              </Grid>
                                <FormGroup sx={{ padding: 0 }} className="filterComponents">
                                <Grid container spacing={2}>
                                  <Grid item xs={6}>
                                    {
                                      intrenetStatuses.slice(0, 3).map((status, index) => (
                                        <FormControlLabel className="sitesInternetStatusOptions" key={`form-filter-${status}`} sx={{ padding: 0 }} control={<Checkbox style={{ padding: "0px 8px 0px 8px" }}
                                          name={status["label"]} checked={status["selected"]} />} onChange={(_, value) => handleInternetStatusFilterChange(index, value)} label={status["name"]} style={{ fontSize: "12px", fontWeight: "400", lineHeight: "14.52px", height: "16px" }} />
                                      ))
                                    }
                                  </Grid>
                                </Grid>
                                </FormGroup>
                            </Grid>
                            <Grid className="sitesFiltersPadding">
                              <Grid className="internetr-status-header-paddingBottom">
                                <span className="internet-status-header">Parameters</span>
                              </Grid>
                                <FormGroup sx={{ padding: 0 }} className="filterComponents">
                                <Grid container spacing={2}>
                                  <Grid item xs={6}>
                                    {
                                      intrenetStatuses.slice(3).map((status, index) => (
                                        <FormControlLabel className="sitesInternetStatusOptions" key={`form-filter-${status}`} sx={{ padding: 0 }} control={<Checkbox style={{ padding: "0px 8px 0px 8px" }}
                                          name={status["label"]} checked={status["selected"]} />} onChange={(_, value) => handleInternetStatusFilterChange(index + 3, value)} label={status["name"]} style={{ fontSize: "12px", fontWeight: "400", lineHeight: "14.52px", height: "16px" }} />
                                      ))
                                    }
                                  </Grid>
                                </Grid>
                                </FormGroup>
                            </Grid>
                          </Grid>
                          </Grid>
                      </Grid>
                    </div>
                  </Grid>
                  <Grid className="apply-filter-divider-width">
                    <Divider className="filter-border-color"/>
                  </Grid>
                  <div className="clearFiletrBtnDiv margin-left-filter">
                    <Button className="clearFiletrBtnUsage padding-right-0" onClick={clearFilters}>Clear Filter</Button>
                    <Grid className="dash-filter-pop">
                      <Button className="usage-applyFilterBtn" onClick={handleApplyFilters}>Apply Filter</Button>
                    </Grid>
                  </div>
                </Menu>
                : <></>
              }
            </Grid>
            <Grid item xs={3} sm={3} md={3} lg={3} className="searchbarMaxWidth">
              <Box className="searchbarBox">
                {<TextField
                  id="outlined-basic"
                  variant="outlined"
                  placeholder="Type something"
                  size="small"
                  autoComplete="off"
                  value={searchValue}
                  onChange={handleOnSearchChange}
                  onKeyDown={(event) => searchBoxKeyDown(event)}
                  InputLabelProps={{ className: "serachLabel" }}
                  InputProps={{
                    className: "searchbarInputs",
                    startAdornment: (
                      <InputAdornment position="start">
                        {!searchValue && <SearchIcon
                          className="searchbarIconsPosition"
                          onClick={handleOnSearchClick}
                        />}
                        {searchValue && <CloseIcon
                          className="searchbarIconsPosition"
                          onClick={handleOnSearchClear}
                        />}
                      </InputAdornment>
                    ),
                  }}
                />}
              </Box>
            </Grid>
            {total !== 0 && <Grid item xs={4} sm={4} md={4} lg={4} className="marg-left-auto">
              <div className="download-summary-btn marg-left-auto" onClick={handleDownloadReport}><img className="downloadImg" src={Download} alt="" /><Button>Download</Button></div>
            </Grid>}
          </Grid>


        </Grid>
        

        {"locations" === selectedFleetButton && (
          <>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <Stack>
                <Grid style={{
                  display: "flex",
                  alignItems: "right",
                  justifyContent: "flex-end"
                }}>
                  <Button className="alertFilterButton"
                    key="filterAlerting"
                    style={{
                      background: locationFilter.alerting ? "#2860B4" : "#fff",
                      color: locationFilter.alerting ? "#fff" : "#676984",
                      borderRadius: "8px  0px 0px 8px",
                      border: "1px solid #C7DAEB",
                    }}
                    onClick={(event) => {
                      _setLocationFilter({
                        alerting: !locationFilter.alerting,
                        nonAlerting: locationFilter.nonAlerting
                      });
                    }}
                  >
                    <img src={onlineIcon} alt="Non Alerting Vessels" title="Non Alerting Sites" />
                    <span style={{ paddingLeft: "5px" }}>{`${alertCount.nonAlerting} Non alerting`}</span>
                  </Button>
                  <Button className="alertFilterButton"
                    key="filterNonAlerting"
                    style={{
                      background: locationFilter.nonAlerting ? "#2860B4" : "#fff",
                      color: locationFilter.nonAlerting ? "#fff" : "#676984",
                      borderRadius: "0px 8px 8px 0px",
                      border: "1px solid #C7DAEB",
                    }}
                    onClick={(event) => {
                      _setLocationFilter({
                        alerting: locationFilter.alerting,
                        nonAlerting: !locationFilter.nonAlerting
                      });
                    }}
                  >
                    <img src={offlineIcon} alt="Alerting Vessels" title="Alerting Sites" />
                    <span style={{ paddingLeft: "5px" }}>{`${alertCount.alerting} Alerting`}</span>
                  </Button>
                </Grid>
              </Stack>
            </Grid>
          </>
        )}


      </Grid>
      
      {
        selectedFleetButton === "sites" ? (
          <Fragment >
            <Grid className="DashboardContainer">
              <Box>
                <Pagination
                  rowsPerPageOptions={NEW_PER_PAGE_ITEMS}
                  count={total ? total : 0}
                  rowsPerPage={limit}
                  page={page + 1}
                  onPageChange={handlePagination}
                  onRowsPerPageChange={handlePerPageChange}
                />
              </Box>
            <TableSummary
                updatePageInfo={updatePageInfo}
                page={page}
                setPage={setPage}
                limit={limit}
                setLimit={setLimit}
                search={search}
                setSearch={setSearch}
                // filterBy={filterBy}
                // setFilterBy={setFilterBy}
                interval={interval}
                setInterval={setInterval}
                keyCallback={keyCallback}
                loader={loader}
                isMapEnabled={isMapEnabled}
                vesselList={vesselList}
              />
             {/* {isMapEnabled && <Grid className="dashboardMapCOntainer">
                <MapLeaflet className="mapsDashboard" vesselList={k4idsList} vesselCurrentLocation={vesselLocationDetails} isAllVesselLocation={true} isMultiIcons={true} />
              </Grid>} */}
              </Grid>
            </Fragment>
          ) : selectedFleetButton === "alerts" ? (
            <Fragment>
              <AlertTable
                page={page}
                setPage={setPage}
                limit={limit}
                setLimit={setLimit}
                // filterBy={filterBy}
                // setFilterBy={setFilterBy}
                search={search}
                setSearch={setSearch}
                updatePageInfo={updatePageInfo}
              />`
        
          </Fragment>
        ) : selectedFleetButton === "locations" ? (
          <Fragment>
            <LocationMap locationFilter={locationFilter} setLocationFilter={setLocationFilter} alertCount={alertCount} search={search} setSearch={setSearch} />
          </Fragment>
        ) : null
      }
    </Grid >
  );
};

const mapStateToProps = (state) => ({
  //props made from redux store
  authReducer: state.authReducer,
  errorReducer: state.errorReducer,
  newSummaryStartDate: state.authReducer.newSummaryStartDate,
  newSummaryEndDate: state.authReducer.newSummaryEndDate,
  products: state?.products
});

// export default DataTable;
export default withRouter(
  connect(mapStateToProps, {
    getVesselLastLocationForSepcific,
    downloadAsCSV,
    vesselSummaryDashboard,
    vesselLinkStatusSummaryDashboard
})(FleetSelection)
);
