import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Dialog, DialogProps, DialogTitle, DialogContent, Grid, TextField, Box, Button, DialogActions } from "@mui/material";
import { OnSaveContentFilterParameters } from "../ContentFilterTable";
import "./ContentFilterDialog.css";
import close from "../../../asset/image/close.svg";
import ContentFilterBasicDetails from "../ContentFilterBasicDetails";
import ContentFilterBlockedCategories from "../ContentFilterBlockedCategories";
import ContentFilterAllowedDomains from "../ContentFilterAllowedDomains";
import ContentFilterBlockedDomains from "../ContentFilterBlockedDomains";
import {ContentFilter, Category, Domain} from "./../../../UserScreen/CustomContentFilter/types"
import _ from "lodash"
import { toast } from "react-toastify";

const NavBar = ({ items, selectedItem, onSelect }) => {
    return (
        <div className="content-filter-nav-bar">
            <ul>
                {items.map((item, index) => (
                    <li
                        key={index}
                        className={item === selectedItem ? "content-filter-nav-item selected": "content-filter-nav-item" }
                        onClick={() => onSelect(item)}
                    >
                        {item}
                    </li>
                ))}
            </ul>
        </div>
    );
};

interface ContentFilterDialogType {
    authReducer:any,
    errorReducer:any,
    open:any,
    onClose: ()=>{},
    contentFilterToUpdate: ContentFilter,
    onSave:(OnSaveContentFilterParameters)=>{}
}
function CreateContentFilterDialog({authReducer, errorReducer, open, onClose, contentFilterToUpdate, onSave }:ContentFilterDialogType) {
    
    const configItems = ['BASIC DETAILS', 'BLOCKED CATEGORIES', 'ALLOWED DOMAINS', 'BLOCKED DOMAINS'];

    const [scroll, setScroll] = useState<DialogProps['scroll']>('paper');
    const [selectedConfig, setSelectedConfig] = useState('BASIC DETAILS');
    const [contentFilterName, setContentFilterName] = useState<string>("");
    const [contentFilterComment, setContentFilterComment] = useState<string>("");
    const [blockedCategories, setBlockedCategories] = useState<Category[]>([]);
    const [allowedDomains, setAllowedDomains] = useState<Domain[]>([]);
    const [blockedDomains, setBlockedDomains] = useState<Domain[]>([]); 
    const [isEditContentFilter, setEditContentFilter] = useState(false)
    const [contentFilterId, setContentFilterId] = useState<string>("")
    const [defaultCF, setDefaultCF] = useState<boolean>(false)

    useEffect(()=>{
        if(!open){
            setEditContentFilter(false)
        }
    }, [open])

    useEffect(()=>{
        if(!_.isEmpty(contentFilterToUpdate)) {
            setContentFilterName(contentFilterToUpdate?.name)
            setContentFilterComment(contentFilterToUpdate?.description)
            setBlockedCategories(contentFilterToUpdate?.policy?.categories)
            setAllowedDomains(contentFilterToUpdate?.policy?.allowed_domains)
            setBlockedDomains(contentFilterToUpdate?.policy?.blocked_domains)
            setEditContentFilter(true)
            let defaultCf = contentFilterToUpdate?.default || false
            setDefaultCF(defaultCf)
        }
    }, [contentFilterToUpdate])

    const onSetContentFilterName = (name)=>{
        setContentFilterName(name)
    }

    const onSetContentFilterComment = (comment)=>{
        setContentFilterComment(comment)
    }

    const onSetBlockedCategories = (data:Category[])=>{
        setBlockedCategories(data)
    }
    
    const onSetAllowedDomains = (data)=>{
        setAllowedDomains(data)
    }
    
    const onSetBlockedDomains = (data)=>{
        setBlockedDomains(data)
    }

    const renderConfigForm = () => {
        switch (selectedConfig) {
            case 'BASIC DETAILS':
                return (
                <ContentFilterBasicDetails onSetContentFilterName={onSetContentFilterName} contentFilterName={contentFilterName} onSetContentFilterComment={onSetContentFilterComment} contentFilterComment={contentFilterComment} isEditable={isEditContentFilter}></ContentFilterBasicDetails>
                )
            case 'BLOCKED CATEGORIES':
                return (
                    <ContentFilterBlockedCategories blockedCategoriesProps={blockedCategories} onSetBlockedCategories={onSetBlockedCategories}></ContentFilterBlockedCategories>
                )
            case 'ALLOWED DOMAINS':
                return (
                    <ContentFilterAllowedDomains allowedDomainsProps={allowedDomains} onSetAllowedDomains={onSetAllowedDomains}></ContentFilterAllowedDomains>
                )
            case 'BLOCKED DOMAINS':
                return (
                    <ContentFilterBlockedDomains onSetBlockedDomains={onSetBlockedDomains} blockedDomainsProps={blockedDomains}></ContentFilterBlockedDomains>
                )

        }
    }

    const onCancel = ()=>{
        onClose()
        setSelectedConfig('BASIC DETAILS')
        setContentFilterName('')
        setContentFilterComment('')
        setBlockedCategories([])
        setAllowedDomains([])
        setBlockedDomains([])
    }

    const validateUniqueAllowedBlockedDomains = ()=>{
        let bIsUniue = true;
        for(let i=0;i<allowedDomains.length;i++){
            for(let j=i+1;j<allowedDomains.length;j++){
                if(allowedDomains[i].domain == allowedDomains[j].domain){
                    bIsUniue = false
                    break;
                }
            }
        }

        for(let i=0;i<blockedDomains.length;i++){
            for(let j=i+1;j<blockedDomains.length;j++){
                if(blockedDomains[i].domain == blockedDomains[j].domain){
                    bIsUniue = false
                    break;
                }
            }
        }

        for(let i=0;i<allowedDomains.length;i++){
            for(let j=0;j<blockedDomains.length;j++){
                if(allowedDomains[i].domain == blockedDomains[j].domain){
                    bIsUniue = false
                    break;
                }
            }
        }

        return bIsUniue
    }

    const onCreate = ()=>{
        let bIsUnique = validateUniqueAllowedBlockedDomains()
        if(!bIsUnique) {
            toast.error("Duplicate domains are present in allowed and blocked domains.", {
                position: toast.POSITION.BOTTOM_LEFT
            });
            return
        }

        let payload:OnSaveContentFilterParameters = {
            dp_id: authReducer.selectedOu.id,
            name: contentFilterName,
            description: contentFilterComment,
            categories: blockedCategories,
            allowed_domains:allowedDomains,
            blocked_domains: blockedDomains,
            enabled: true
        }
        onSave(payload)
        onCancel()
    }

    const onEdit = ()=>{
        let bIsUnique = validateUniqueAllowedBlockedDomains()
        if(!bIsUnique) {
            toast.error("Duplicate domains are present in allowed and blocked domains.", {
                position: toast.POSITION.BOTTOM_LEFT
            });
            return
        }

        let payload:OnSaveContentFilterParameters = {
            dp_id: authReducer.selectedOu.id,
            id:contentFilterId,
            name: contentFilterName,
            description: contentFilterComment,
            categories: blockedCategories,
            allowed_domains:allowedDomains,
            blocked_domains: blockedDomains,
            enabled: true
        }
        onSave(payload)
        onCancel()
    }

    return (
        <div className="Form">
            <Dialog
                disableEnforceFocus
                open={open}
                scroll={scroll}
                onClose={onClose}
                maxWidth="lg"
                fullWidth
                className='config-create-content-filter-popup'
            >
            <div>
                <DialogTitle className='create-default-config-title'>
                        {!isEditContentFilter ? 
                            <span className="content-filter-title-name"> Create Content Filter </span>
                            :
                            <span className="content-filter-title-name"> Edit Content Filter </span>
                        }
                        <img src={close} title="Close" onClick={onCancel} className="create-content-filter-close-btn" />
                </DialogTitle>
                <DialogContent dividers={scroll ==='paper'} className='create-content-filter-dialog-content'>
                    <div className="form-content-filter">
                        <Grid container xs={12} sm={12} md={12} lg={12}>
                            <Grid item xs={3} sm={3} md={3} lg={3}>
                                <NavBar items={configItems} selectedItem={selectedConfig} onSelect={setSelectedConfig} />
                            </Grid>
                            <Grid item xs={9} sm={9} md={9} lg={9}>
                                <div className="content-filter-config-details">
                                    {renderConfigForm()}
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" className="content_filter--button_outlined_filter" onClick={onCancel}>
                        Cancel
                    </Button>
                    {
                        !isEditContentFilter ?
                            <Button variant="contained" className="content_filter-footer-create-button" onClick={onCreate}>Create</Button>
                            :
                            <Button variant="contained" className="content_filter-footer-create-button" onClick={onEdit} disabled={defaultCF}>Save</Button>
                    }
                </DialogActions>
            </div>
            </Dialog>
        </div>
    )
}

const mapStateToProps = (state: any) => ({
    authReducer: state.authReducer,
    errorReducer: state.errorReducer,
    customContentFilter: state.customContentFilter,
})

export default withRouter(
    connect(mapStateToProps, {
    })(CreateContentFilterDialog)
);  