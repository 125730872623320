import { Fragment, ReactNode, SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { AddCircle } from "@material-ui/icons";
import { Autocomplete, Button, Grid, TextField, Tooltip, Typography, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { RemoveCircle } from '@mui/icons-material';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { useDispatch } from 'react-redux';
import _ from "lodash";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";


const RouteItemsPopup = (props) => {
    const { authReducer, routeItems, setRouteItems, isEditActionPopup, services } = props;
    const addthretaKontrolAction = () => {
        const newAction = {
            id: routeItems?.length,
            access_vid: null,
            source_access_net: "",
            destination_net: "",
            service_name: "",
        };
        setRouteItems([...routeItems, newAction]);
    };
    const RouteItemsPopup = (id, updatedAction) => {
        setRouteItems(
            routeItems?.map((action, index) =>
                index === id ? updatedAction : action
            )
        );
    };
    const removeRouteItem = (id: string) => {
        const updatedActions = routeItems.filter((_, index) => index !== id);
        setRouteItems(updatedActions);

        setRouteItems((prevActions) =>
            prevActions?.map((action, index) => ({ ...action, id: index }))
        );
    };

    // DRAG

    const [draggedRowId, setDraggedRowId] = useState(null);
    const [hoveredRowId, setHoveredRowId] = useState(null);

    // Drag Start (Set Dragged Row)
    const handleDragStart = (event, rowId) => {
        setDraggedRowId(rowId);
        event.dataTransfer.effectAllowed = "move";
        event.target.style.opacity = "0.4"; // Lower opacity while dragging
    };

    // Drag End (Reset Opacity)
    const handleDragEnd = (event) => {

        setDraggedRowId(null); // Reset dragged row
        setHoveredRowId(null); // Remove highlight
        event.target.style.opacity = "1"; // Restore opacity after dragging
    };

    // Drag Over (Update Hovered Row Continuously)
    const handleDragOver = (event, rowId) => {

        event.preventDefault();
        if (rowId !== draggedRowId) {
            setHoveredRowId(rowId);
        }
    };

    // Drag Leave (Remove Overlapping Highlight)
    const handleDragLeave = () => {
        setHoveredRowId(null);
    };

    const handleDrop = (event, targetRowId) => {

        event.preventDefault();
        setHoveredRowId(null);

        const draggedIndex = routeItems.findIndex((row) => row.id === draggedRowId);
        const targetIndex = routeItems.findIndex((row) => row.id === targetRowId);

        if (draggedIndex === -1 || targetIndex === -1 || draggedIndex === targetIndex) return;

        const updatedRows = [...routeItems];
        const [draggedRow] = updatedRows.splice(draggedIndex, 1); // Remove dragged row
        updatedRows.splice(targetIndex, 0, draggedRow); // Insert at new position

        // 🔹 Update `id` values to match the new order
        const reindexedRows = updatedRows.map((row, index) => ({ ...row, id: index }));

        setRouteItems(reindexedRows);
        setDraggedRowId(null);
    };

    return (
        <div style={{ width: "100%" }}>
            {/* <Typography>ROUTE Details</Typography> */}
            <div >
                {
                    routeItems?.length !== 0 ? (
                        <div>
                            <Grid container lg={12} spacing={1} style={{ marginTop: "20px", background: "#f5f5f5", }}>
                                <Grid lg={3}>
                                    <p style={{ fontFamily: "Inter", fontStyle: "normal", fontWeight: 500, fontSize: "14px", marginBottom: "10px", marginTop: "10px", textAlign: "center", color: "#45464E" }}> ACCESS VID</p>
                                </Grid>
                                <Grid lg={3}>
                                    <p style={{ fontFamily: "Inter", fontStyle: "normal", fontWeight: 500, fontSize: "14px", marginBottom: "10px", marginTop: "10px", textAlign: "center", color: "#45464E" }}> SOURCE NETWORK</p>
                                </Grid>
                                <Grid lg={3}>
                                    <p style={{ fontFamily: "Inter", fontStyle: "normal", fontWeight: 500, fontSize: "14px", marginBottom: "10px", marginTop: "10px", textAlign: "center", color: "#45464E" }}> DESTINATION NETWORK</p>
                                </Grid>
                                <Grid lg={3}>
                                    <p style={{ fontFamily: "Inter", fontStyle: "normal", fontWeight: 500, fontSize: "14px", marginBottom: "10px", marginTop: "10px", textAlign: "center", color: "#45464E" }}> SERVICE NAME</p>
                                </Grid>
                            </Grid>
                        </div>
                    ) : null
                }
                {routeItems?.map((action, index) => (
                    <RouteItem
                        key={index}
                        id={index}
                        data={action}
                        authReducer={authReducer}
                        onUpdate={RouteItemsPopup}
                        onRemove={removeRouteItem}
                        isEditActionPopup={isEditActionPopup}
                        services={services}
                        handleDragStart={handleDragStart}
                        handleDragEnd={handleDragEnd}
                        handleDragOver={handleDragOver}
                        handleDragLeave={handleDragLeave}
                        handleDrop={handleDrop}
                        hoveredRowId={hoveredRowId}
                        draggedRowId={draggedRowId}
                    />
                ))}
            </div>
            {/* <Button variant="outlined" startIcon={<AddCircle className="addActionIcon" style={{ paddingRight: "0%" }} />} style={{ marginTop: "10px", width: "170px" }} onClick={addthretaKontrolAction} >
                Add ROUTE
            </Button> */}
            <Button variant="outlined" size="small" startIcon={<AddCircle />} className="quota_management--button_outlined--primary" style={{ marginTop: "10px" }} onClick={addthretaKontrolAction} >
                ADD ROUTE
            </Button>
        </div>
    )
}

const RouteItem = (props) => {
    const { authReducer, getSeverityClassList, getThreatNameList, id, data, onUpdate, onRemove, copy, isEditActionPopup, services, handleDragStart, handleDragEnd, handleDragOver, handleDragLeave, handleDrop, hoveredRowId, draggedRowId } = props;
    const handleChange = (field: string, value: any) => {
        onUpdate(id, { ...data, [field]: value || '' });
    };
    const [error, setError] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            setError(false);
        }, 2000);
        return () => clearTimeout(timer)
    }, [error])

    const handleAccessVidChange = (value: any) => {
        if (/^\d*$/.test(value)) {
            setError(false);
            handleChange("access_vid", value ? parseInt(value) : null)
        } else {
            setError(true);
        }
    }

    const dispatch = useDispatch();

    return (
        <div
            key={id}
            onDragOver={(e) => handleDragOver(e, id)}
            onDrop={(e) => handleDrop(e, id)}
            onDragLeave={handleDragLeave}
            style={{
                display: "flex",
                alignItems: "center",
                padding: "10px",
                margin: "5px 0",
                background: hoveredRowId === id ? "#ddd" : "white", // Highlight overlapped row
                // border: "1px solid #ddd",
                // borderRadius: "5px",
                opacity: draggedRowId === id ? "0.5" : "1", // Lower opacity while dragging
                transition: "opacity 0.2s ease, background 0.2s ease",
            }}
        >
            <div
                draggable
                onDragStart={(e) => handleDragStart(e, id)}
                onDragEnd={handleDragEnd}
                style={{
                    cursor: "grab",
                    marginRight: "10px",
                    display: "flex",
                    alignItems: "center",
                }}
            >
                <DragIndicatorIcon />
            </div>
            <Grid container lg={12} spacing={1} style={{ marginTop: "5px" }}>
                {/* <Grid item lg={12} className="threatAction"> */}
                <Grid item lg={3}>
                    <TextField
                        placeholder="Access Vid"
                        value={data?.access_vid}
                        onChange={(event) => {
                           handleAccessVidChange(event?.target?.value)
                        }}
                        variant="outlined"
                        size="small"
                        sx={{ maxWidth: "80%", width: "80%",  "& .MuiFormHelperText-root": {color: "red"}}}
                        helperText={error ? "Only numbers are allowed" : ""}
                    />
                </Grid>
                <Grid item lg={3}>
                    <TextField
                        placeholder="Source Access Net"
                        value={data?.source_access_net}
                        onChange={(event) => {
                            handleChange("source_access_net", event?.target?.value)
                        }}
                        variant="outlined"
                        size="small"
                        sx={{ maxWidth: "80%", width: "80%" }}
                    />
                </Grid>
                <Grid item lg={3}>
                    <TextField
                        placeholder="Destination Net"
                        value={data?.destination_net}
                        onChange={(event) => {
                            handleChange("destination_net", event?.target?.value)
                        }}
                        variant="outlined"
                        size="small"
                        sx={{ maxWidth: "80%", width: "80%" }}
                    />
                </Grid>
                <Grid item lg={3}>
                    <div style={{ display: "flex", justifyContent: "space-around", alignItems: "center" }}>
                        <Autocomplete
                            id="select-users"
                            size='small'
                            // className={notificationMethods.includes("email") ? "" : "disbale-user-selection"}
                            value={data?.service_name}
                            onChange={(event, newValue) => {
                                handleChange("service_name", newValue);
                            }}
                            options={services}
                            getOptionLabel={(option) => option}
                            // disabled={disableActions}
                            renderInput={(params) => <TextField {...params} label="select" />}
                            renderOption={(props, option) => <li {...props} key={option}>{option}</li>}
                            sx={{ width: "80%", maxWidth: "80%" }}
                            style={{ width: "80%", maxWidth: "80%" }}
                        />

                        <RemoveCircle className="routeActionremoveIcon" onClick={() => onRemove(id)} />
                    </div>
                </Grid>
                <br></br>
            </Grid>
        </div>
    )
}

const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
    options: state?.authReducer?.threatSeverityClassList
});

export default withRouter(
    connect(mapStateToProps, {})(RouteItemsPopup)
)
