import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import customPlanIcon from "../../../asset/image/CustomPlanIcon.svg";
import starlinkPlanIcon from "../../../asset/image/StarlinkPlanIcon.svg";
import orgPoolIcon from "../../../asset/image/OrgPoolIcon.svg";
import { getStarlinkPlanUsageHierarchy } from "../../StarlinkReports/slice";
import _ from "lodash";

const PlanHeirarchy = (props) => {
    const { selectedOu, getStarlinkPlanUsageHierarchy, starlinkPlanUsageHierarchy, setParentPoolId, setPlanFilter, setSelectedRow, setExpandedRows, selectedRow, expandedRows, planData } = props;   
    
    const handleExpandClick = (id, poolId, row) => {
        setExpandedRows((prevExpandedRows) => {
            const isCurrentlyExpanded = prevExpandedRows[id] ?? false;
            let newExpandedRows = {};
            let newSelectedRow = row.uid; // Default: Select clicked row
            let rowToFilter = row; // ✅ Default row for handlePlanFilter
    
            if (row.account_type === "pooled" || row.account_type === "standard") {  
                // ✅ Handling Top-Level Rows
                if (isCurrentlyExpanded) {
                    // Collapse it and reset to the first row
                    newExpandedRows = { [planData[0]?.uid]: true };
                    setParentPoolId(planData[0]?.parent_pool_id);
                    newSelectedRow = planData[0]?.uid; // ✅ Select first row
                    rowToFilter = planData[0]; // ✅ Send first row to handlePlanFilter
                } else {
                    // Expand this row, collapse all other top-level rows
                    newExpandedRows = { [id]: true };
                    setParentPoolId(poolId);
                }
            } else {  
                // ✅ Handling Child Rows (Expand/Collapse independently)
                newExpandedRows = { ...prevExpandedRows };
    
                if (isCurrentlyExpanded) {
                    delete newExpandedRows[id]; // Collapse the child row
                } else {
                    newExpandedRows[id] = true; // Expand the child row
                }
            }
    
            setSelectedRow(newSelectedRow); // ✅ Update selected row
            handlePlanFilter(rowToFilter); // ✅ Always send the correct row
    
            return newExpandedRows;
        });
    };              

    const getAccountType = (data) => {
        if (data?.icon) {
            switch (data.icon) {
                case "CustomPlanIcon":
                    return "custom_plan";
                case "orgPoolIcon":
                    return "sub_pool";
                case "starlinkPlanIcon":
                    return "standard_plan";
                default:
                    return "";
            }
        } else if (data?.account_type) {
            return data.account_type === "pooled" ? "pooled_account" : "standard_account";
        }
    }

    const getAccountId = (data) => {
        if (data?.account_type == 'pooled' || data?.account_type == 'standard') {
            return data?.account_number;
        }
        return data?.id;
    }

    const handlePlanFilter = (row) => {
        setSelectedRow(row.uid);
        setPlanFilter({ plan_id: getAccountId(row), type: getAccountType(row), account_number: getAccountId(row) })
    }

    const renderRow = (row, level = 0) => (
        <React.Fragment key={row.id}>
            <TableRow>
                <TableCell style={{ paddingLeft: `${level * 20}px`, whiteSpace: "nowrap", display: "flex", alignItems: "center", backgroundColor: selectedRow == row.uid ? "rgb(83, 225, 174)" : "transparent" }}>
                    {row.children?.length > 0 && row?.children[0] !== null && (
                        <IconButton size="small" style={{ paddingTop: "0px", paddingBottom: "0px" }} onClick={() => {
                            handleExpandClick(row?.uid, row?.parent_pool_id, row)
                        }}>
                            {expandedRows[row.uid] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        </IconButton>
                    )}
                    <Grid style={{ maxWidth: '200px', whiteSpace: 'nowrap', overflowX: 'hidden', textOverflow: 'ellipsis', marginLeft: row.children?.length > 0 && row?.children[0] !== null ? '0px' : '15px' }}>
                        {row?.icon ? <img className="icon-cursor" style={{ paddingRight: "10px" }} src={row?.icon === "CustomPlanIcon" ? customPlanIcon : row?.icon === "orgPoolIcon" ? orgPoolIcon : starlinkPlanIcon} /> : null}
                        <Tooltip title={row?.account_type ? row?.account_type.charAt(0).toUpperCase() + row?.account_type.slice(1).toLowerCase() : ""}>
                            <span onClick={() => handlePlanFilter(row)} style={{ color: "blue", cursor: 'pointer', maxWidth: '200px', whiteSpace: 'nowrap', overflowX: 'hidden', textOverflow: 'ellipsis' }}>{row?.account_type ? row?.account_type.charAt(0).toUpperCase() + row?.account_type.slice(1).toLowerCase() + " | " : ""}</span>
                        </Tooltip>
                        <Tooltip title={row?.account_name || row?.custom_plan_name || row?.pool_name || row?.plan_name || ''}>
                            <span onClick={() => handlePlanFilter(row)} style={{ color: "blue", cursor: 'pointer', maxWidth: '200px', whiteSpace: 'nowrap', overflowX: 'hidden', textOverflow: 'ellipsis' }}>{row?.account_name || row?.custom_plan_name || row?.pool_name || row?.plan_name || ''}</span>
                        </Tooltip>
                    </Grid>
                </TableCell>
                <TableCell align="center" style={{backgroundColor: selectedRow == row.uid ? "rgb(83, 225, 174)" : "transparent"}}>{row?.unit == 'tb' ? row?.size * 1000 : row?.size}</TableCell>
                <TableCell align="center" style={{backgroundColor: selectedRow == row.uid ? "rgb(83, 225, 174)" : "transparent"}}>{row.total_usage?.toFixed(2)}</TableCell>
            </TableRow>
            {expandedRows[row?.uid] && row?.children?.length > 0 && (
                row?.children?.map((child) => renderRow(child, level + 1))
            )}
        </React.Fragment>
    );

    return (
        <div>
            <TableContainer component={Paper} className="starlink--table_container strMap-table" style={{ maxHeight: "calc(100vh - 407px)", overflow: 'auto', marginTop: '8px' }}>
                <Table stickyHeader size="small" aria-label="plan-hierarchy-table">
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <span className="font-wt-900">Plan</span>
                            </TableCell>
                            <TableCell style={{width: '2%'}}>
                                <span className="font-wt-900">Size(GB)</span>
                            </TableCell>
                            <TableCell style={{width: '2%'}}>
                                <span className="font-wt-900">Usage In Period(GB)</span>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {planData?.length > 0 ? (
                            planData?.map((row) => renderRow(row))
                        ) : (
                            <TableRow>
                                <TableCell colSpan={4} align="center">
                                    No Plans available.
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
}

const mapStateToProps = (state) => ({
    selectedOu: state.authReducer.selectedOu,
    starlinkPlanUsageHierarchy: state.starlinkReports.starlinkPlanUsageHierarchy,
});

export default withRouter(connect(mapStateToProps, {
    getStarlinkPlanUsageHierarchy
})(PlanHeirarchy));