import { Fragment, useState, useEffect } from "react";

import Highcharts, { seriesType } from "highcharts";
import HighchartsReact from 'highcharts-react-official';
import NoDataToDisplay from 'highcharts/modules/no-data-to-display';
import HighchartsTimeline from 'highcharts/modules/timeline';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import ChartsTable from "./ChartsTable";
import HighchartsMore from 'highcharts/highcharts-more'; // Import highcharts-more
import HighchartsXrange from 'highcharts/modules/xrange';
import { Dialog, DialogContent, IconButton } from "@mui/material";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import InfoIcon from '@mui/icons-material/Info';
HighchartsMore(Highcharts);
HighchartsXrange(Highcharts);

const Dash = (props) => {
    return (
        <svg width="12" height="4" viewBox="0 0 12 4" fill={props.fill} xmlns="http://www.w3.org/2000/svg" style={{paddingBottom: '3px'}}>
            <path d="M0.828613 0H7.04783V3H0.828613V0Z" fill={props.fill}></path>
        </svg>
    )
}

NoDataToDisplay(Highcharts);
HighchartsTimeline(Highcharts);

const Chart = (props: any) => {
    const PING_DROP_RATE = "Ping Drop Rate"
    const { HighchartsInstance=null, chartOptions, immutable, updateArgs, hideLegendTable, parameters, page, setPage, enableEventsTable, progressBarOptions, chartRef, hubsRef, agentSDWAN, SDWAN } = props;
    const tableHeadCellStyle = {background: '#DDE2EE', color: '#747474', fontWeight: '500', fontSize: '0.8rem'};
    const tableRowCellStyle = {fontWeight: '500', color: '#3F3F3F', fontSize: '0.8rem'};
    const [isCollapsed, setIsCollapsed] = useState(true);
    const [open, setOpen] = useState(false);

    const getTotalUsage = (series: any) => {
        const usage = series?.data?.reduce((sum, point) => sum + point.y, 0);
        return usage && series.valueUnit ? {usageValue: usage, unit: series.valueUnit} : 0;
    }

    const toggleCollapse = () => {
        setIsCollapsed((prev) => !prev);
    };

    useEffect(() => {
        if (hideLegendTable || !chartOptions?.series?.length) {
            return;
        }
    }, [hideLegendTable, chartOptions?.series]);

    const qoeTable = [
        {metric: 'Streaming/Browsing', bad: '< 10', poor: '11 - 15', average: '16 - 35', good: '36 - 60', great: '> 61', linkDown: 'Link down event'},
        {metric: 'Gaming/RTC', bad: '< 5', poor: '6 - 15', average: '16 - 20', good: '21 - 30', great: '> 31', linkDown: 'Link down event'}
    ]

    return (
        <Fragment>
            <div id="progressBarContainer">
                <HighchartsReact highcharts={HighchartsInstance ? HighchartsInstance : Highcharts} options={chartOptions} immutable={immutable ? immutable : false} updateArgs={updateArgs ? updateArgs : [true, true, true]} ref={chartRef} />
            </div>
            {(agentSDWAN || SDWAN) && <div id="progressBarContainer" className="Hub-Topology-SDWAN-Progress-Chart" style={agentSDWAN ? { marginTop: '20px' } : { marginTop: '0px' }}>
                {SDWAN && <div className="qoe-hmap-chat-title">
                    <div onClick={() => setOpen(true)}><InfoIcon id="helpIcon-reports" /></div>
                </div>}
                <div className="progress-bar-linear-chart">
                    <HighchartsReact
                        highcharts={HighchartsInstance ? HighchartsInstance : Highcharts}
                        options={progressBarOptions}
                        immutable={false}
                        updateArgs={[true, true, true]}
                        ref={hubsRef}
                    />
                </div>
            </div>}
            { !hideLegendTable && chartOptions?.series?.length > 0 ? <TableContainer component={Paper} sx={{m: 2, width: 'unset', overflowY: 'auto', boxShadow: 'unset', border: '1px solid #DDE2EE'}} className="legend-table-container">
                <Table size="small" aria-label="a dense table" stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={tableHeadCellStyle} id="legendTableCollapseHeader">
                                <IconButton onClick={toggleCollapse} size="small">
                                    {isCollapsed ? <ChevronRightIcon /> : <KeyboardArrowDownIcon />}
                                </IconButton>
                            </TableCell>
                            <TableCell sx={tableHeadCellStyle}>METRICS</TableCell>
                            <TableCell sx={tableHeadCellStyle} align="right">MIN</TableCell>
                            <TableCell sx={tableHeadCellStyle} align="right">MAX</TableCell>
                            <TableCell sx={tableHeadCellStyle} align="right">AVG</TableCell>
                            <TableCell sx={tableHeadCellStyle} align="right">TOTAL</TableCell>
                        </TableRow>
                    </TableHead>
                    {!isCollapsed && (
                    <TableBody>
                        {chartOptions?.series.filter(s => (s.name !== 'Event' && !s.name.toLowerCase().includes('hub'))).map((series) => (
                            <TableRow
                                key={series.name}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell></TableCell>
                                <TableCell sx={tableRowCellStyle} component="th" scope="row">
                                    <span>
                                        <span><Dash fill={series.color}></Dash></span> {series.name}</span>
                                </TableCell>
                                {series.name == 'SpeedTest' ?
                                    <TableCell sx={tableRowCellStyle} align="right">
                                        <span>{`Upload : ${Math.round((Math.min(...series?.data.map(point => point.upSpeed)) + Number.EPSILON) * 10000) / 10000} Mbps`}</span><br/>
                                        <span>{`Download : ${Math.round((Math.min(...series?.data.map(point => point.downSpeed)) + Number.EPSILON) * 10000) / 10000} Mbps`}</span>
                                    </TableCell>
                                    : <TableCell sx={tableRowCellStyle} align="right">{
                                        series?.name?.includes(PING_DROP_RATE) ? 
                                        Math.round((Math.min(...series?.data.map(point => point.y ? point.y : 0)) + Number.EPSILON) * 100) : 
                                        Math.round((Math.min(...series?.data.map(point => point.y ? point.y : 0)) + Number.EPSILON) * 10000) / 10000
                                    } {series.valueUnit}</TableCell>
                                }
                                {series.name == 'SpeedTest' ?
                                    <TableCell sx={tableRowCellStyle} align="right">
                                        <span>{`Upload : ${Math.round((Math.max(...series?.data.map(point => point.upSpeed)) + Number.EPSILON) * 10000) / 10000} Mbps`}</span><br/>
                                        <span>{`Download : ${Math.round((Math.max(...series?.data.map(point => point.downSpeed)) + Number.EPSILON) * 10000) / 10000} Mbps`}</span>
                                    </TableCell>
                                    : <TableCell sx={tableRowCellStyle} align="right">{
                                        series?.name?.includes(PING_DROP_RATE) ? 
                                        Math.round((Math.max(...series?.data.map(point => point.y ? point.y : 0)) + Number.EPSILON) * 100) :
                                        Math.round((Math.max(...series?.data.map(point => point.y ? point.y : 0)) + Number.EPSILON) * 10000) / 10000
                                    } {series.valueUnit}</TableCell>
                                }
                                {series.name == 'SpeedTest' ?
                                    <TableCell sx={tableRowCellStyle} align="right">
                                        <span>{`Upload : ${(((series?.data.map(point => point.upSpeed).reduce((a,b) => a + b, 0) / series?.data.length + Number.EPSILON) * 10000) / 10000).toFixed(2)} Mbps`}</span><br/>
                                        <span>{`Download : ${(((series?.data.map(point => point.downSpeed).reduce((a,b) => a + b, 0) / series?.data.length + Number.EPSILON) * 10000) / 10000).toFixed(2)} Mbps`}</span>
                                    </TableCell>
                                    : <TableCell sx={tableRowCellStyle} align="right">{
                                        series?.name?.includes(PING_DROP_RATE) ? 
                                        ((series?.data.map(point => point.y ? point.y : 0).reduce((a,b) => a + b, 0) / series?.data.length + Number.EPSILON) * 100).toFixed(2) :
                                        (((series?.data.map(point => point.y ? point.y : 0).reduce((a,b) => a + b, 0) / series?.data.length + Number.EPSILON) * 10000) / 10000).toFixed(2)
                                    } {series.valueUnit}</TableCell>
                                }
                                {series?.name?.includes('Usage') ? <TableCell sx={tableRowCellStyle} align="right">{(getTotalUsage(series)['usageValue'])?.toFixed(2)} {getTotalUsage(series)['unit']}</TableCell> : <TableCell sx={tableRowCellStyle} align="right">-</TableCell>}
                            </TableRow>
                        ))}
                    </TableBody>)}
                </Table>
            </TableContainer> : null}
            {!hideLegendTable && enableEventsTable ?
                <ChartsTable parameters={parameters} Page={page} setPage={setPage} />
             : null}
            {open && <Dialog open={open} onClose={() => setOpen(false)}>
                <DialogContent className="qoe-application-score-content">
                    <div className="custom_confirm_dialog_sub_title">
                        <TableContainer>
                            <Table className="qoe-application-score-table">
                                <TableHead>
                                    <TableCell>Metric</TableCell>
                                    <TableCell style={{background: '#ff0000'}}>Bad</TableCell>
                                    <TableCell style={{background: '#ff5500'}}>Poor</TableCell>
                                    <TableCell style={{background: '#ffcc00'}}>Average</TableCell>
                                    <TableCell style={{background: '#66ff00'}}>Good</TableCell>
                                    <TableCell style={{background: '#00ff00'}}>Great</TableCell>
                                    <TableCell style={{background: '#d9d9d9'}}>Link Down</TableCell>
                                </TableHead>
                                <TableBody>
                                    {qoeTable.map((qoe) => (
                                        <TableRow className="qoe-application-score-table-each-row">
                                            <TableCell>{qoe.metric}</TableCell>
                                            <TableCell>{qoe.bad}</TableCell>
                                            <TableCell>{qoe.poor}</TableCell>
                                            <TableCell>{qoe.average}</TableCell>
                                            <TableCell>{qoe.good}</TableCell>
                                            <TableCell>{qoe.great}</TableCell>
                                            <TableCell>{qoe.linkDown}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                </DialogContent>
            </Dialog>}
        </Fragment>
    )
}

export default Chart;