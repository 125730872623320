import moment, { Moment } from "moment-timezone";
import { connect } from 'react-redux';
import { withRouter, useLocation } from "react-router-dom";
import React, { createContext, useEffect, useLayoutEffect, useMemo, useReducer } from "react";
import { auditLogs, AuditPayload } from "../../actions/Users/authenticateAuditLogs";
import { getDecodeURI, getEncodedURI, strToK4List } from "../../utils/util";
import { DEFAULT_PAGE_SIZE } from "../../constants/Constants";
import _ from "lodash";

interface TableFiltersBase {
    page: number;
    limit: number;
    username: string;
    resource: string;
    createAction: boolean;
    deleteAction: boolean;
    updateAction: boolean;
    label: string;
}

interface ActionFilter {
    key: string;
    name: string;
    value: boolean;
}

export interface TableFilters extends TableFiltersBase {
    startDate: Moment;
    endDate: Moment;
}

export interface NavigationFilters extends TableFiltersBase {
    startDate: string;
    endDate: string;
    actions: string[];
}

export interface TableState extends TableFilters {
    items: TableData[];
    lastUsed: Moment;
    totalCount: number;
}

type TableFilterType = (state: TableFilters) => void;

export interface TableData {
    id: string;
    createTime: Moment;
    resource: string;
    ipAddress: string;
    metadata: any;
    before: any;
    after: any;
    user: string;
    action: string;
}

interface TableContextType {
    items: TableData[];
    filters: TableFilters,
    totalCount: number;
    doNavigate: (params: Partial<NavigationFilters>) => void;
    setItems: (items: TableData[]) => void;
    clearFilters: () => void;
    applyFilters: () => void;
    setFilter: <TKey extends keyof TableFilters>(key: TKey, value: TableFilters[TKey]) => void;
}

export const AuditTableContext = createContext<TableContextType>(Object.assign({}));

interface TableContextPropviderProps {
    children: React.ReactNode;
    auditLogs?: any;
    location: any;
    history: any;
    authReducer?: {
        selectedOu: any;
        auditLogs: any;
        getVesselsListing: any;
        userOu: any;
        newSummaryStartDate:any;
        newSummaryEndDate:any;
    };
}

type TableAction = { type: 'filter', filter: TableFilterType } |
{ type: 'data', items: any[], totalCount: number } |
{ type: 'process-url', onProcessUrl: (state: TableState) => void }

export const getFilterActions = (filters: TableFilters) => {
    const actions: string[] = [];
    if (filters.createAction) {
        actions.push('CREATE');
    }
    if (filters.updateAction) {
        actions.push('UPDATE');
    }
    if (filters.deleteAction) {
        actions.push('DELETE')
    }
    return actions;
}

const applyFilterAction = (action: string, state: TableFilters) => {
    switch (action.toUpperCase()) {
        case 'CREATE':
            state.createAction = true;
            break;
        case 'UPDATE':
            state.updateAction = true;
            break;
        case 'DELETE':
            state.deleteAction = true;
    }
}

const DATE_DIFF = 3;

const initialState: TableState = {
    items: [],
    createAction: false,
    deleteAction: false,
    updateAction: false,
    page: 1,
    label: '',
    resource: '',
    totalCount: 0,
    username: '',
    lastUsed: moment(),
    limit: DEFAULT_PAGE_SIZE,
    startDate: moment().subtract(DATE_DIFF, 'days'),
    endDate: moment(),
}

window.moment = moment;

const tableDataReducer = (state: TableState, action: TableAction): TableState => {
    switch (action.type) {
        case 'filter':
            action.filter(state)
            return {
                ...state
            };
        case "process-url":
            action.onProcessUrl(state);
            return state;
        case 'data':
            return {
                ...state,
                totalCount: action.totalCount,
                items: action.items.map(item => {
                    return {
                        id: item.id,
                        ipAddress: item.ip_address,
                        action: item.action,
                        after: item.after,
                        before: item.before,
                        createTime: moment(item.create_time).utc(false),
                        metadata: item.metadata,
                        resource: item.resource,
                        user: item.user,
                        label: item.label
                    }
                })
            }
        default:
            return state;
    }
}

const TableContextProvider = (props: TableContextPropviderProps) => {

    const { auditLogs, authReducer, location, history } = props;

    const processQueryUrl = (state: TableState) => {
        let _q = getDecodeURI(location?.search);

        let _page = _q.page && '' != _q.page.trim() ? parseInt(_q.page) : 1;
        if (_page != state.page) {
            state.page = _page == 0 ? 1 : _page;
        }

        let _limit = _q.limit && '' != _q.limit.trim() ? parseInt(_q.limit) : DEFAULT_PAGE_SIZE;
        if (_limit != state.limit) {
            state.limit = _limit > 0 ? _limit : DEFAULT_PAGE_SIZE;
        }

        let _endDate = _q.startDate && '' != _q.startDate.trim() ? moment(_q.endDate) : state.lastUsed;
        if (_endDate.diff(state.endDate) !== 0) {
            state.endDate = _endDate;
        }

        let _startDate = _q.startDate && '' != _q.startDate.trim() ? moment(_q.startDate) : moment(state.endDate).subtract(DATE_DIFF, 'days');
        if (_startDate.diff(state.startDate) !== 0) {
            state.startDate = _startDate;
        }

        let _resource = _q.resource && '' != _q.resource.trim() ? _q.resource : '';
        if (_resource != state.resource) {
            state.resource = _resource;
        }

        let _username = _q.username && '' != _q.username.trim() ? _q.username : '';
        if (_username != state.username) {
            state.username = _username;
        }

        let _label = _q.label && '' != _q.label.trim() ? _q.label : '';
        if (_label != state.label) {
            state.label = _label;
        }

        let _actions: string[] = _q.actions && '' != _q.actions.trim() ? _q.actions.split(',') : []
        state.createAction = false;
        state.updateAction = false;
        state.deleteAction = false;
        if (_actions.length > 0) {
            _actions.forEach(action => {
                applyFilterAction(action, state);
            });
        }
        let _vessels: any[] = [];
        if (_q.hasOwnProperty("k4Ids") && '' != _q.k4Ids.trim()) {
            _vessels = strToK4List(_q.k4Ids);
        }

        if (_.isEmpty(_vessels) && !_.isEmpty(authReducer?.getVesselsListing)) {
            _vessels = authReducer?.getVesselsListing?.locations;
        }
        const actions = getFilterActions(state);
        const payload: Partial<AuditPayload> = {
            page: state.page,
            limit: state.limit,
            filters: {
                action: actions.length ? `(${actions.map(action => `'${action}'`).join(',')})` : '',
                user: state.username,
                resource: state.resource,
                label: state.label
            },
            startTime: authReducer?.newSummaryStartDate.toISOString(),
            endTime: authReducer?.newSummaryEndDate.toISOString(),
            locationId: `(${_vessels.map(v => `'${v.id}'`).join(',')})`,
            organizationId: authReducer?.selectedOu?.id
        };
        auditLogs(payload);
    }

    const [state, dispatch] = useReducer(tableDataReducer, initialState);

    useLayoutEffect(() => {
        if (_.isEmpty(authReducer?.getVesselsListing))
            return;
        dispatch({ type: 'process-url', onProcessUrl: processQueryUrl })
    }, [location, authReducer?.getVesselsListing])

    useEffect(() => {
        const auditLogs = authReducer?.auditLogs;
        if (!auditLogs || _.isEmpty(authReducer?.auditLogs))
            return;
        if (!auditLogs.success || !auditLogs.result) {
            return;
        }
        let totalCount = auditLogs.result.length;
        if (auditLogs.result_info) {
            totalCount = auditLogs.result_info.total_count ?? totalCount;
        }
        dispatch({ type: 'data', items: auditLogs.result, totalCount });

    }, [authReducer?.auditLogs])

    const setFilter = <TKey extends keyof TableFilters>(key: TKey, value: TableFilters[TKey]) => {
        dispatch({
            type: 'filter', filter: (state) => {
                state[key] = value;
            }
        })
    }

    const clearFilters = () => {
        let urlParams: Partial<NavigationFilters> = getDecodeURI(location?.search);
        delete urlParams.actions;
        delete urlParams.resource;
        delete urlParams.username;
        delete urlParams.startDate;
        delete urlParams.endDate;
        delete urlParams.page;
        delete urlParams.label;
        history.push({ pathname: location.pathname, search: `?${getEncodedURI(urlParams)}` });
    }

    const doNavigate = (params: Partial<NavigationFilters>) => {
        let urlParams: any = getDecodeURI(location?.search);
        history.push({ pathname: location.pathname, search: `?${getEncodedURI(Object.assign(urlParams, params))}` });
    }

    const setItems = (items: TableData[]) => {
        dispatch({ type: 'data', items, totalCount: items.length });
    }

    const applyFilters = () => {
        let currentFilters: Partial<NavigationFilters> = getDecodeURI(location?.search);
        const { endDate, resource, startDate, username, label } = state;
        const startDateValue = authReducer?.newSummaryStartDate?.toISOString();
        const endDateValue = authReducer?.newSummaryEndDate?.toISOString();
        currentFilters.startDate = startDateValue;
        currentFilters.endDate = endDateValue;
        if (resource.length == 0) {
            delete currentFilters.resource;
        } else {
            currentFilters.resource = resource;
        }
        if (username.length === 0) {
            delete currentFilters.username;
        } else {
            currentFilters.username = username;
        }
        if (label.length == 0) {
            delete currentFilters.label;
        } else {
            currentFilters.label = label;
        }
        if (currentFilters.page && currentFilters.page > 0)
            currentFilters.page = 1;
        currentFilters.actions = getFilterActions(state);
        history.push({ pathname: location.pathname, search: `?${getEncodedURI(currentFilters)}` });
    }

    const value = useMemo(() => {
        return {
            filters: state,
            items: state.items,
            totalCount: state.totalCount,
            setFilter,
            doNavigate,
            applyFilters,
            clearFilters,
            setItems
        }
    }, [state]);

    return <AuditTableContext.Provider value={value}>
        {props.children}
    </AuditTableContext.Provider>
}


const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
    errorReducer: state.errorReducer
});


export default withRouter(
    connect(mapStateToProps, {
        auditLogs
    })(TableContextProvider))