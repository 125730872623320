import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {  Box, FormControlLabel, FormGroup, Grid, Radio, RadioGroup } from "@mui/material";
import _ from "lodash";
import { fetchResources } from "../../actions/Users/authenticate";

const Filters = (props) => {
    const { subTabs, subTab, setSubTab, defaultFilters, selectedFilter, setSelectedFilter } = props;

    const handleFilterChange = (filter) => {
        setSubTab(filter);
    };

    const handleFilterTabChange = (filter) => {
        setSelectedFilter(filter);
    };

    return (
        <Box className="l-shaped-filters">
            <Grid className="usageFilterComponent">
                <div className="Form">
                    <Grid className="am-table">
                        <Grid className="dash-filter-pop">
                            <Grid>
                                <Grid>
                                    <Grid className="internetr-status-header-paddingBottom">
                                        <span className="internet-status-header">Plan Type</span>
                                    </Grid>
                                    <FormGroup
                                        sx={{ padding: 0 }}
                                        className="filterComponents"
                                    >
                                        <RadioGroup>
                                            {subTabs?.map((tab: any) => (
                                                <FormControlLabel
                                                    key={`form-filter-${tab.tabName}`}
                                                    className="sitesInternetStatusOptions starlinkMap-filterPopup"
                                                    control={
                                                        <Radio
                                                            name="filterOptions"
                                                            checked={subTab === tab.tabValue}
                                                            onChange={() => handleFilterChange(tab.tabValue)}
                                                        />
                                                    }
                                                    label={tab.tabName}
                                                />
                                            ))}
                                        </RadioGroup>
                                    </FormGroup>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
            </Grid>
            <Grid className="usageFilterComponent" mt={1}>
                <div className="Form">
                    <Grid className="am-table">
                        <Grid className="dash-filter-pop">
                            <Grid>
                                <Grid>
                                    <Grid className="internetr-status-header-paddingBottom">
                                        <span className="internet-status-header">Billing Usage</span>
                                    </Grid>
                                    <FormGroup
                                        sx={{ padding: 0 }}
                                        className="filterComponents"
                                    >
                                        <RadioGroup>
                                            {defaultFilters?.map((status: any) => (
                                                <FormControlLabel
                                                    key={`form-filter-${status.label}`}
                                                    className="sitesInternetStatusOptions starlinkMap-filterPopup"
                                                    control={
                                                        <Radio
                                                            name="filterOptions"
                                                            checked={selectedFilter === status.label}
                                                            onChange={() => handleFilterTabChange(status.label)}
                                                        />
                                                    }
                                                    label={status.name}
                                                />
                                            ))}
                                        </RadioGroup>
                                    </FormGroup>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
            </Grid>
        </Box>
    );
};
const mapStateToProps = (state) => ({
    selectedOu: state.authReducer?.selectedOu,
    fetchResourcesData: state.authReducer?.fetchResources,
});

export default withRouter(
    connect(mapStateToProps, { fetchResources })(Filters)
);
