import{ useState, useEffect } from 'react';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import './clock.css';
import moment from "moment-timezone";
import _ from "lodash";
import Tooltip from '@mui/material/Tooltip';
import Preferences from '../Header/Preferences';
import { getDataFromLocalStorage } from '../../utils/util';
import { MAINTENANCE } from '../../constants/Constants';

const UTCClock = (props) => {
  const { authReducer } = props;
  const [timezone, setTimezone] = useState<string>("")
  const [timezoneAbbreviation, setTimezoneAbbreviation] = useState<string>("")

  const [isPreferencesPopUpOpen, setIsPreferencesPopUpOpen] = useState(false)

  useEffect(() => {
    if (!_.isEmpty(authReducer.userTimezone)) {
      try {
        setTimezone(authReducer.userTimezone.split(" - ")[0])
        setTimezoneAbbreviation(authReducer.userTimezone.split(" - ")[1])
      } catch (err) {

      }
    }
  }, [authReducer.userTimezone])

  const handlePreferences = () => {
    setIsPreferencesPopUpOpen(true)
  }

  return (
    <div className='clock' style={getDataFromLocalStorage(MAINTENANCE) ? {right: '220px'} : {right: '186px'}}>
      {
        timezone
        ?
          [
            
            timezoneAbbreviation
            ?
              <Tooltip title={<span style={{ fontSize: "12px" }}>{timezone}</span>}>
                <span onClick={()=>handlePreferences()}> {timezoneAbbreviation}</span>
              </Tooltip>
            :null,
            <span onClick={()=>handlePreferences()} className='current-date-time'>{moment().tz(timezone).format("MM/DD/YYYY, HH:mm")}</span>
          ]
        : null
      }
      {isPreferencesPopUpOpen ? <Preferences isPopUpOpen={isPreferencesPopUpOpen} setIsPopUpOpen={setIsPreferencesPopUpOpen} /> : null}
    </div>
  );
};

const mapStateToProps = (state) => ({
  authReducer: state.authReducer,
  errorReducer: state.errorReducer,
});

export default withRouter(
  connect(mapStateToProps, {
  })(UTCClock)
)