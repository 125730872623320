import { Autocomplete, Button, Dialog, Divider, Grid, TextField, Typography } from '@mui/material';
import { Fragment, ReactNode, SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { connect, useDispatch } from "react-redux";
import EditIcon from '@mui/icons-material/Edit';
import EditOffIcon from '@mui/icons-material/EditOff';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import close from "../../../asset/image/close.png";
import ThreatKontrolActions from "./ThreatKontrolActions";
import {createThreatKontrol} from "../../../actions/Users/authenticateThreatDashboard";
import { getSeverityClassList, getThreatNameList } from "../../../actions/Users/authenticateThreatDashboard";
import _ from "lodash";
import { withRouter } from "react-router-dom";
import axios from 'axios';
import { JsonDataTooltip } from "../../../components/JSONTooltip/JsonTooltip";
import {  FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Checkbox, ListItemText, AutocompleteChangeReason, AutocompleteChangeDetails, Chip, FormHelperText, IconButton, Tooltip, FormControlLabel, ListSubheader } from '@mui/material';

interface ThreatKontrolAddRule {
    threatOpen: boolean,
    setThreatOpen: any,
    selectedRow: any
}

const ThreatKontrolAddRule = (props) => {
    const {authReducer, options, getSeverityClassList, getThreatNameList, createThreatKontrol, users, threatOpen, setThreatOpen, selectedRow} = props;
    const Heading = "Set Threat Kontrol";
    const targetTypes = ["Device", "Site", "Managed Group"];
    const [targetType, setTargetType] = useState<string>("");
    const [email, setEmail] = useState<any[]>([]);
    const [targets, setTargets] = useState<any[]>([]);
    const [emailSelected, setEmailSelected] = useState<boolean>(false);
    const [teamsSelected, setTeamsSelected] = useState<boolean>(false);
    const [inputValue, setInputValue] = useState("");

    const handleTargetTypeChange = (event, value) => {
        setTargetType(value);
        setTargets([]);
    }
    const handleTargetsChange = (event, value) => {
        setTargets(value);
    }
    const handleClose = () => {
        setThreatOpen(false);
    }
    const isValidEmail = (emailString: string): boolean => {
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(emailString.trim());
    };
    const handleInputEmailChange = (event, newInputValue) => {
        if (newInputValue.endsWith(",")) {
            const emailString = newInputValue.slice(0, -1).trim();
            if (isValidEmail(emailString)) {
                const newEmail = { name: emailString, email: emailString };
                if (!email.some(item => item.email === newEmail.email)) {
                    setEmail(prev => [...prev, newEmail]);
                }
            }
            else{
                alert("Enter Valid Email");
            }
            setInputValue("")
        } else {
            setInputValue(newInputValue);
        }
    }
    const handleEmailChnage = (event, value) => {
        setEmail(value);
    }
    const handleEmailChange = (event) => {
        setEmailSelected(event.target.checked);
    };

    const handleTeamsChange = (event) => {
        setTeamsSelected(event.target.checked);
    };
    const handleCancel = () => {
        setThreatKontrolName("");
        setSeveity("");
        setSelectedClass([]);
        setSeletedThreatName([]);
        setCount(0);
        setMeasure("");
        setAction("");
        setEmail([]);
        setEmailSelected(false);
        setTeamsSelected(false);
        setThreatOpen(false);
    }
    
    const dispatch = useDispatch();
    const optionsSeverity = ["Low", "Medium", "High", "Critical"];
    const optionsMeas = ["15 Mins", "1 Hour", "6 Hours", "24 Hours" ];
    const optionsCount = [10, 20, 50, 100];
    const optionsAction = ["Notification", "Suppression"];
    const [classOptions, setClassOptions] = useState<string[]>([]);
    const [threatNames, setThreatNames] = useState<any[]>([]);
    const [threatKontrolName, setThreatKontrolName] = useState<string>('');
    const [severity, setSeveity] = useState<string>(selectedRow.Severity);
    const [selectedClass, setSelectedClass] = useState<string[]>([selectedRow.Type]);
    const [seletedThreatName, setSeletedThreatName] = useState<any[]>([{class: selectedRow.Type ? selectedRow.Type : "", threat_name: selectedRow.Threat_Name ? selectedRow.Threat_Name : ""}]);
    const [count, setCount] = useState<number>(0);
    const [measure, setMeasure] = useState<string>("");
    const [action, setAction] = useState<string>("");
    useEffect(() => {
        getSeverityClassList(severity);
    }, [severity]);

    useEffect(() => {
        const classList = authReducer?.threatSeverityClassList?.data?.class?.data[0];
        if (classList) {
            const parsedClassOptions = classList.split(","); 
            setClassOptions(parsedClassOptions);
        }
        else {
            setClassOptions([]);
        }
    }, [authReducer.threatSeverityClassList]);

    useEffect(() => {
        getThreatNameList(selectedClass.map(item => `'${item}'`).join(','));
    }, [selectedClass]);

    useEffect(() => {
        const nameList = authReducer?.threatNamesList?.data;
        if (nameList && Array.isArray(nameList)) {
            const parsedThreatNameOptions = nameList.map(item => item);
            setThreatNames(parsedThreatNameOptions);
        }
        else {
            setThreatNames([]);
        }
    }, [authReducer.threatNamesList]);

    const handleSeverityChange = (event, value) => {
        setSeveity(value);
        setSelectedClass([]);
        setSeletedThreatName([]);
    }
    const isPresentinClass = (threatname, classes) => {
        if(classes.includes(threatname.class)){
            return true;
        }
        else return false;
    }
    const handleClassChange = (event, cls) => {
        setSelectedClass(cls);
        const remainingThreatNames = seletedThreatName.filter((threatname, index) => {
            return isPresentinClass(threatname, cls)
        })
        setSeletedThreatName(remainingThreatNames);
    }
    const handleThreatKontrolNameChange = (event, newInputValue) => {
        setThreatKontrolName(newInputValue);
    }
    const handleThreatNameChange = (event, thrtName) => {
        setSeletedThreatName(thrtName)
    }
    const handleCountChange = (event, value) => {
        setCount(value || event.target.value);
    }
    const handleMeasureChange = (event, value) => {
        setMeasure(value);
    }
    const handleActionChange = (event, value) => {
        setAction(value);
    }
    const isFormValid = () => {
        return  threatKontrolName && severity && selectedClass.length > 0 && seletedThreatName.length > 0 && action && (action == "Suppression" || count > 0 && measure  && (teamsSelected || (emailSelected && email.length > 0)));
    };
    const handleSave = async () => {
        const notificationMethods: string[] = [];
        if (emailSelected) notificationMethods.push("email");
        if (teamsSelected) notificationMethods.push("microsoft_teams");
        try {
          const payload = {
                deviceIds: [selectedRow.deviceID],
                control_name: threatKontrolName,
                threat_actions: [{
                    threat_names_classes: seletedThreatName,
                    severity: severity,
                    ...(action !== "Suppression" ? { measurement_period: measure } : {}),
                    ...(action !== "Suppression" ? { count: Number(count) } : {}),
                    action: action
                }],
                notification_methods: notificationMethods,
                users_to_notify: email 
        };
          createThreatKontrol(payload);
          setThreatOpen(false);
        } catch (error) {
            alert('Failed to save data. Please try again.');
        }
    };

    return (
        <Dialog className="menuScroll threatKontrolDialogbox" onClose={handleClose} open={true} maxWidth="lg" fullWidth>
            <div className="displayFlex threatKontrolMargin">
                <div className="threatKontrolHeading">{Heading}</div>
                <div className='threatKontrolClose'>  <img className="threatKontrolCloseIcon"
                    src={close}
                    alt='close'
                    onClick ={handleClose}>
                    </img>
                </div>
            </div>
            <Divider className="threatKontrolDivider"/>
            <div className="threatKontrolNameMargin threatkontrolSelectorGap">
                <Autocomplete
                    id="threatKontrolName"
                    size="small"
                    options={[]}
                    value={threatKontrolName}
                    freeSolo
                    fullWidth
                    onInputChange={handleThreatKontrolNameChange}
                    renderInput={(params) => <TextField {...params} label="ThreatKontrolName *" title="ThreatKontrolName" variant="outlined"/>}
                />
            </div>
            <div className="threatKontrolMargin">
                <div className="threatSourceInfo threatKontrolSubheading">
                    <Typography>Threat Source</Typography>
                    <JsonDataTooltip data={"runs every 5 minutes"}/>
                </div>
                <div className="threatkontrolSelectorGap">
                <Autocomplete
                    id="Site"
                    disableCloseOnSelect
                    options={[]}
                    value={"Device"}
                    size="small"
                    renderInput={(params) => <TextField {...params} label="Site" variant="outlined" style={{width:"200px"}}/>}
                />
                <Autocomplete
                    id="Device"
                    multiple
                    disableCloseOnSelect
                    fullWidth
                    options={[]}
                    value={[selectedRow.Device_Name]}
                    size="small"
                    renderInput={(params) => <TextField {...params} label="Device" variant="outlined"/>}
                />
                </div>
                <br></br>
                <div >
                    <Grid>  
                        <Grid item xs={12} md={2} className="threatAction">
                            <Autocomplete
                                id="severity"
                                size="small"
                                options={optionsSeverity}
                                value={severity}
                                onChange={handleSeverityChange}
                                renderInput={(params) => <TextField {...params} label="Severity *" title="Severity" variant="outlined" className="editseveritySelector"/>}
                            />
                            <Autocomplete
                                id="class"
                                size="small"
                                multiple
                                options={classOptions}
                                value={selectedClass}
                                onChange={handleClassChange}
                                renderInput={(params) => <TextField {...params} label="Class *" title="Class" variant="outlined" className="editclassSelector"
                                sx={{
                                    '& .MuiInputBase-root': {
                                        minHeight: 40 + selectedClass.length * 30, 
                                    },
                                }}/>}
                            />
                            <Autocomplete
                                id="threatName"
                                size="small"
                                multiple
                                options={threatNames}
                                getOptionLabel={(option) => option.threat_name}
                                value={seletedThreatName}
                                onChange={handleThreatNameChange}
                                isOptionEqualToValue={(option, value) => option.threat_name === value.threat_name}
                                renderInput={(params) => <TextField {...params} label="ThreatName *" title="ThreatName" variant="outlined" className="editthreatNameSelector"
                                sx={{
                                    '& .MuiInputBase-root': {
                                        minHeight: 40 + seletedThreatName.length * 30, 
                                    },
                                }}/>}
                            />
                            <Autocomplete
                                id="action"
                                size="small"
                                options={optionsAction}
                                value={action}
                                onChange={handleActionChange}
                                renderInput={(params) => <TextField {...params} label="Threat Action *" title="Threat Action" variant="outlined" className="editactionSelector"/>}
                            />
                            <Autocomplete
                                id="meas"
                                size="small"
                                options={optionsMeas}
                                value={measure}
                                onChange={handleMeasureChange}
                                disabled={action == "Suppression"}
                                renderInput={(params) => <TextField {...params} label="Measurement *" title="Measurement period" variant="outlined" className="editmeasSelector"/>}
                            />
                            <Autocomplete
                                id="count"
                                size="small"
                                options={optionsCount}
                                value={count}
                                freeSolo 
                                onChange={handleCountChange}
                                onInputChange={handleCountChange}
                                disabled={action == "Suppression"}
                                renderInput={(params) => <TextField {...params} label="Count *" title="Count" placeholder="type number" variant="outlined" className="editcountSelector"/>}
                            /> 
                        </Grid>
                        <br></br>
                    </Grid>  
                </div>
            </div>
            <hr className="threadKontrolhr"></hr>
            <div className="threatKontrolMargin threatEmailSelect">
                <Typography className="threatKontrolSubheading">Notification *</Typography>
                <div className="threatkontrolRuleCheckbox">
                    <Checkbox size='small' name={'email'} checked={emailSelected} onChange={handleEmailChange} className="threatKontrolCheckbox" disabled={action == "Suppression"}/>
                    <Typography variant="body2" className="threatKontrolCheckbocName">
                        Email
                    </Typography>
                </div>
                <div className="threatkontrolEmailGap"></div>
                <Autocomplete
                    id="select-device"
                    size='small'
                    fullWidth
                    freeSolo
                    multiple
                    options={users}
                    getOptionLabel={(option) => option.name}
                    onChange={handleEmailChnage}
                    onInputChange={handleInputEmailChange}
                    inputValue={inputValue}
                    value={email}
                    disabled={action == "Suppression"}
                    renderInput={(params) => <TextField {...params} label="Enter Email Id*  (End with , if typed)" variant='outlined' disabled={action == "Suppression"}/>}
                />
                <div className="threatkontrolEmailGap"></div>
                <div className="threatkontrolRuleCheckbox">
                    <Checkbox size='small' name={'email'} checked={teamsSelected} onChange={handleTeamsChange} disabled={action == "Suppression"}/>
                    <Typography variant="body2" className="threatKontrolCheckbocName">
                        Microsoft Teams
                    </Typography>
                </div>
            </div>
            <Divider className="threatKontrolDivider"/>
            <div className="displayFlex threatKontrolMargin">
                <Button id="threatKontrolCancelButton" onClick ={handleCancel}>
                    Cancel
                </Button>
                <Button id={ isFormValid() ? "threatKontrolSaveButton" : "threatKontrolgreySaveButton"} onClick={handleSave} disabled={!isFormValid()}>
                    Save
                </Button>
            </div>
        </Dialog>
    )
}

const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
    errorReducer: state.errorReducer,
    options: state?.authReducer?.threatSeverityClassList
})

export default withRouter(
    connect(mapStateToProps, { getSeverityClassList, getThreatNameList, createThreatKontrol })(ThreatKontrolAddRule)
);