import { Fragment, ReactNode, SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { AddCircle } from "@material-ui/icons";
import { Autocomplete, Button, Grid, TextField, Tooltip, Typography, Chip, Select, MenuItem, Divider, Paper, Checkbox } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { RemoveCircle } from '@mui/icons-material';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { useDispatch } from 'react-redux';
import _ from "lodash";
import ModeIcon from '@mui/icons-material/Mode';
import LinkBondingPopup from './LinkBondingPopup';


const WanProfileLevels = (props) => {
    const { authReducer, wanProfileLevels, setWanProfileLevels, wansDropDownList } = props;

    const addthretaKontrolAction = () => {
        const newAction = {
            id: wanProfileLevels?.length,
            names: [],
            level: wanProfileLevels?.length + 1,
            balancer_type: "not_applicable",
            weightage: {}
        };
        setWanProfileLevels([...wanProfileLevels, newAction]);
    };
    const changeWanProfileLevel = (id, updatedAction) => {
        setWanProfileLevels(
            wanProfileLevels?.map((action, index) =>
                index === id ? updatedAction : action
            )
        );
    };
    const removeWanItem = (id: string) => {
        const updatedActions = wanProfileLevels.filter((_, index) => index !== id);
        setWanProfileLevels(updatedActions);

        setWanProfileLevels((prevActions) =>
            prevActions?.map((action, index) => ({ ...action, id: index }))
        );
    };
    return (
        <div>
            <div style={{ marginTop: "25px" }}>
                {
                    wanProfileLevels?.length !== 0 ? (
                        <div style={{ background: "#f5f5f5", marginBottom: "1.5%", }}>
                            <Grid container lg={12} >
                                <Grid item lg={2} style={{ display: "flex", justifyContent: "center", alignItems: "center", }}>
                                    <p style={{ fontFamily: "Inter", fontStyle: "normal", fontWeight: 500, fontSize: "14px", marginBottom: "10px", marginTop: "10px", marginLeft: "-10px" }}> PRIORITY</p>
                                </Grid>
                                <Grid item lg={4}>
                                    <p style={{ fontFamily: "Inter", fontStyle: "normal", fontWeight: 500, fontSize: "14px", marginBottom: "10px", marginTop: "10px", textAlign: "center", marginLeft: "-10px" }}> WAN NAME</p>

                                </Grid>
                                <Grid item lg={3}>
                                    <p style={{ fontFamily: "Inter", fontStyle: "normal", fontWeight: 500, fontSize: "14px", marginBottom: "10px", marginTop: "10px", textAlign: "center", marginLeft: "-10px" }}> BALANCER TYPE</p>
                                </Grid>
                            </Grid>
                        </div>
                    ) : null
                }
                {wanProfileLevels?.map((action, index) => (
                    <WanItem
                        key={index}
                        id={index}
                        data={action}
                        authReducer={authReducer}
                        onUpdate={changeWanProfileLevel}
                        onRemove={removeWanItem}
                        wansDropDownList={wansDropDownList}
                    />
                ))}
            </div>
            <Button variant="outlined" size="small" startIcon={<AddCircle />} className="agent-wan-balancer-action-button" style={{ marginTop: "10px" }} onClick={addthretaKontrolAction} >
                ADD WAN Priority
            </Button>
        </div>
    )
}


const WanItem = (props) => {
    const { authReducer, getSeverityClassList, getThreatNameList, id, data, onUpdate, onRemove, wansDropDownList } = props;
    const handleChange = (field: string, value: any) => {
        onUpdate(id, { ...data, [field]: value || '' });
    };

    const dispatch = useDispatch();

    const [linkBonding, setLinkBonding] = useState(false)

    useEffect(() => {
        if (data?.names?.length === 0 || data?.names?.length === 1) {
            handleChange("balancer_type", "not_applicable")
        } else {
            handleChange("balancer_type", "dynamic")
        }

    }, [data?.names])

    useEffect(() => {
        if (data?.balancer_type === "static" && data?.names?.length > 0) {
            handleWeightage(data?.names)
        }
    }, [data?.balancer_type])

    const handleWeightage = (wanNames: string[]) => {
        let totalWeight = 100;
        let count = wanNames.length;
        let baseWeight = Math.floor(totalWeight / count); // Base weight for each
        let remaining = totalWeight - (baseWeight * count); // Extra weight to distribute

        let weightage = {}
        wanNames.map((name, index) => {
            // Give extra weight to top wan names
            let weight = baseWeight + (index < remaining ? 1 : 0);
            weightage[name] = weight
        });

        handleChange("weightage", weightage)
    }

    return (
        <div >
            <Grid container spacing={0} >
                <Grid lg={2} >
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", }}>
                        <p>Priority {id + 1}</p>
                    </div>
                </Grid>
                <Grid lg={4} style={{ display: "flex", justifyContent: "center", alignItems: "center", }}>
                    <Autocomplete
                        id="select-users"
                        size="small"
                        multiple
                        value={data?.names}
                        onChange={(e, newValue: any) => { handleChange("names", newValue) }}
                        options={wansDropDownList}
                        disableCloseOnSelect // Keeps the dropdown open when selecting items
                        getOptionLabel={(option) => option}
                        limitTags={2}
                        renderInput={(params) => <TextField {...params} label="Select WANs" />}
                        renderOption={(props, option, { selected }) => (
                            <li {...props} key={option}>
                                <Checkbox size="small" checked={selected} />
                                {option}
                            </li>
                        )}
                        renderTags={(value, getTagProps) =>
                            value.map((option, index) => (
                                <Chip size="small" label={option} {...getTagProps({ index })} />
                            ))
                        }
                        sx={{ width: "80%", maxWidth: "80%" }}
                    />

                </Grid>
                <Grid lg={3} style={{ display: "flex", justifyContent: "center", alignItems: "center", }}>
                    <Button variant='outlined' disabled={data?.balancer_type === "not_applicable" ? true : false} endIcon={<ModeIcon style={{ color: data?.balancer_type === "not_applicable" ? "grey" : "#264c86" }} />}
                        style={{ height: "33px", borderColor: data?.balancer_type === "not_applicable" ? "grey" : "#264c86", color: data?.balancer_type === "not_applicable" ? "grey" : "#264c86", textTransform: "none", width: "80%" }} onClick={() => { setLinkBonding(true) }} >
                        {data?.balancer_type == "dynamic" ? "Dynamic" : data?.balancer_type == "static" ? "Static" : "Not Applicable"}
                    </Button>
                </Grid>
                <Grid item lg={1} style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                    <RemoveCircle className="wanActionremoveIcon" onClick={() => onRemove(id)} />
                </Grid>
            </Grid>
            <Grid container>
                <Grid lg={3} />
                <Grid container lg={6} >
                    {/* <Grid item lg={12} className="threatAction"> */}
                    {/* <Grid item lg={3}>
                        <TextField
                            placeholder="Level"
                            value={data?.level}
                            size="small"
                            onChange={(event) => {
                                handleChange("level", event?.target?.value)
                            }}
                            variant="outlined"
                        />
                    </Grid> */}
                    {/* {data?.balancer_type == "static" && data?.names?.map((wanName, index) => {
                        return (
                            <Grid container style={{ marginTop: index == 0 ? "5px" : "10px" }}>
                                <Grid lg={5}>
                                    <TextField
                                        placeholder="Weightage"
                                        value={wanName}
                                        size="small"
                                        // onChange={(event) => {
                                        //     handleChange("level", event?.target?.value)
                                        // }}
                                        variant="outlined"
                                        disabled={true}
                                        sx={{ width: "90%" }}
                                    />
                                </Grid>
                                <Grid item lg={5}>
                                    <TextField
                                        placeholder="Weightage"
                                        value={data?.weightage[wanName]}
                                        size="small"
                                        onChange={(event) => {
                                            handleChange("weightage", { ...data?.weightage, [wanName]: event?.target?.value })
                                        }}
                                        variant="outlined"
                                        sx={{ width: "90%" }}
                                    />
                                </Grid>
                                <Grid lg={1}></Grid>
                                <Grid lg={1}></Grid>
                            </Grid>
                        )
                    })} */}

                    {/* <Grid item lg={3}>
                        <Select
                            value={data?.balancer_type}
                            defaultValue={data?.balancer_type}
                            label="Balancer Type"
                            size="small"
                            sx={{ maxWidth: "100%", width: "100%" }}
                            onChange={(e) => { handleChange("balancer_type", e?.target?.value) }}
                        >
                            <MenuItem value={"static"}>Static</MenuItem>
                            <MenuItem value={"dynamic"}>Dynamic</MenuItem>
                        </Select>
                    </Grid> */}
                    {/* <Grid item lg={3} style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "center" }}>
                        <RemoveCircle className="wanActionremoveIcon" onClick={() => onRemove(id)} />
                    </Grid> */}
                    {/* </Grid> */}
                    <br></br>
                </Grid>
                <Grid lg={3} />
            </Grid>
            {linkBonding ? <LinkBondingPopup open={linkBonding} setLinkBonding={setLinkBonding} wanNames={data?.names} balancerType={data?.balancer_type} handleChange={handleChange} weightage={data?.weightage} /> : null}
        </div>
    )
}

const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
    options: state?.authReducer?.threatSeverityClassList
});

export default withRouter(
    connect(mapStateToProps, {})(WanProfileLevels)
)
