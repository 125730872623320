import { Autocomplete, Button, Dialog, DialogContent, DialogTitle, FormControl, FormControlLabel, Grid, IconButton, MenuItem, Paper, Select, TextField, Tooltip } from "@mui/material";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import barIcon from '../../../asset/image/bar_chart_icon.svg';
import lineIcon from '../../../asset/image/line_chart_icon.svg';
import bar_chart from '../../../asset/image/bar_chart_light.svg';
import HistogramIcon from '../../../asset/image/histogram_icon.svg';
import HistogramIconDisabled from '../../../asset/image/hist_icon_disabled.svg';
import line_chart from '../../../asset/image/line_chart.svg';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import FitbitIcon from '@mui/icons-material/Fitbit';
import ModeIcon from '@mui/icons-material/Mode';
import close from "../../../asset/image/cross.svg";
import { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { getMinutes } from "../../../utils/util";

const SiteLineChart = (props) => {
    const { title, data, setChartView, resetDate, handleResetZoom, handleDefaultResetZoom, options, handleSiteClick, colors, chartView, setIsHistogram, isHistogram, handleBucket, bucketValue, setMinBucket, setMaxBucket, minBucket, maxBucket, handleResetValue, handleLegend, vesselLength, selectedBin, handleBinChange, startDate, endDate, chartRef={} } = props;
    const initialOptions = [{ label: "0 ", value: 0 }, { label: "100 ", value: 100 }, { label: "200 ", value: 200 }, { label: "300 ", value: 300 }, { label: "400 ", value: 400 }]
    const [option, setOption] = useState(initialOptions)
    const [popupOpen, setPopupOpen] = useState(false)

    const handleHistogramClose = () => {
        setPopupOpen(false);
    };


    useEffect(()=>{

        if(vesselLength?.length == 1 && isHistogram && chartView === "column"){
            if(vesselLength?.length == 1 && isHistogram && chartView === "column"){
                setChartView("column")
                setIsHistogram(false)
            }
        }
    },[vesselLength])

    return (
        <Grid className="site-line-chart-title-div">
            <Grid className={title ? "legends-pie-new-container site-line-chart-title-text" : "legends-pie-new-container"}>
                <Grid className="title-text">{title}</Grid>
                <Grid className="legends-view-images legends-view-images-sites">
                    <Grid className="line-bar-view">
                        {
                            isHistogram && vesselLength?.length > 1 ?
                                <FormControlLabel
                                    value="Zoom Reset"
                                    control={<IconButton disabled={bucketValue === 2 ? true : false} onClick={() => { setPopupOpen(true) }}><ModeIcon style={{ height: "18px", width: "24px" }} /></IconButton>}
                                    label=""
                                    labelPlacement="start"
                                />

                                :
                                null
                        }
                        {!isHistogram && <Grid className="pieChat-selectTag">
                            <FormControl variant="standard" className='selectEntry-pie'>
                                <Select
                                    labelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-standard"
                                    value={selectedBin}
                                    onChange={handleBinChange}
                                >
                                    {getMinutes(startDate, endDate) >= 5 && <MenuItem value={'5m'}>5m</MenuItem>}
                                    {getMinutes(startDate, endDate) >= 10 && <MenuItem value={'10m'}>10m</MenuItem>}
                                    {getMinutes(startDate, endDate) >= 15 && <MenuItem value={'15m'}>15m</MenuItem>}
                                    {getMinutes(startDate, endDate) >= 30 && <MenuItem value={'30m'}>30m</MenuItem>}
                                    {getMinutes(startDate, endDate) >= 60 && <MenuItem value={'1h'}>1h</MenuItem>}
                                    {getMinutes(startDate, endDate) >= 120 && <MenuItem value={'2h'}>2h</MenuItem>} 
                                    {getMinutes(startDate, endDate) >= 180 && <MenuItem value={'3h'}>3h</MenuItem>} 
                                    {getMinutes(startDate, endDate) >= 360 && <MenuItem value={'6h'}>6h</MenuItem>}
                                    {getMinutes(startDate, endDate) >= 720 && <MenuItem value={'12h'}>12h</MenuItem>}
                                    {getMinutes(startDate, endDate) >= 1440 && <MenuItem value={'1d'}>1d</MenuItem>}
                                    {getMinutes(startDate, endDate) >= 10080 && <MenuItem value={'7d'}>7d</MenuItem>}
                                </Select>
                            </FormControl>
                        </Grid>}
                        <img onClick={() => { setChartView('column'); setIsHistogram(false) }} src={chartView == 'column' && !isHistogram ? barIcon : bar_chart} />
                        <img onClick={() => { setChartView('spline'); setIsHistogram(false) }} src={chartView == 'spline' ? line_chart : lineIcon} />
                        { vesselLength?.length > 1 && title !== "Availability" && <img onClick={() => { setChartView('column');  setIsHistogram(true) }} src={ isHistogram ? HistogramIcon : HistogramIconDisabled} /> }
                    </Grid>
                    {resetDate.length > 0 &&
                        <div>
                            <FormControlLabel
                                value="Zoom Back"
                                control={<IconButton onClick={handleResetZoom}><ZoomOutIcon /></IconButton>}
                                label=""
                                labelPlacement="start"
                            />
                            <FormControlLabel
                                value="Zoom Reset"
                                control={<IconButton onClick={handleDefaultResetZoom}><RestartAltIcon /></IconButton>}
                                label=""
                                labelPlacement="start"
                            />
                        </div>
                    }
                </Grid>
            </Grid>
            <Dialog onClose={handleHistogramClose} open={popupOpen} className="" >
                <DialogTitle className="histogramDialogTitle">
                    <Grid item container style={{ color: "#45464E" }}>
                        Filters
                        <Grid style={{ marginLeft: "auto" }}>
                            {/* <RestartAltIcon style={{height:"16px", width:"16px", cursor:"pointer"}} onClick={handleResetValue} /> */}
                            <img src={close} onClick={handleHistogramClose} className="viewUserCloseIcon" />
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent className="viewUserDialogContent menuScroll histogram-dialog" >
                    <Grid >
                        <Grid>
                            <div className="bucket-outer">
                                <div className="bucket-text">Min (GB)</div>
                                <Autocomplete
                                    disablePortal
                                    value={minBucket}
                                    id="combo-box-demo"
                                    onInputChange={(e, newValue) => {
                                        if (Number(newValue) >= 0 && option.findIndex((o) => o.value === Number(newValue)) === -1 ) {
                                            setOption((o) => [ ...o, { label: `${newValue}`, value: Number(newValue) }]);
                                        }
                                    }}
                                    ListboxProps={{ style: { maxHeight: 150 } }}
                                    options={option}
                                    sx={{ width:"150px", marginRight: "10px" }}
                                    renderInput={(params) => <TextField 
                                        variant="standard" 
                                        {...params}
                                        error={minBucket >= maxBucket }
                                        helperText={minBucket >= maxBucket ? "Min (GB) should be less than Max (GB)" : null}
                                        />}
                                    onChange={(e, newValue: any) => {
                                        if(newValue && newValue?.value){                                       
                                             setMinBucket(newValue?.value);
                                        }
                                        
                                    }}
                                />
                            </div>
                        </Grid>
                        <Grid>
                            <div className="bucket-outer">
                                <div className="bucket-text">Max (GB)</div>
                                <Autocomplete
                                    value={maxBucket}
                                    disablePortal
                                    id="combo-box-demo"
                                    onInputChange={(e, newValue) => {
                                        if (Number(newValue) >= 0 && option.findIndex((o) => o.value === Number(newValue)) === -1 ) {
                                            setOption((o) => [ ...o,  { label: `${newValue}`, value: Number(newValue)}]);
                                        }
                                    }}
                                    options={option}
                                    ListboxProps={{ style: { maxHeight: 150 } }}
                                    sx={{ width:"150px", marginRight: "10px" }}
                                    renderInput={(params) => <TextField variant="standard" {...params}
                                    error={minBucket >= maxBucket }
                                    helperText={minBucket >= maxBucket? "Max (GB) should be greater than Min (GB)" : null}
                                    />}
                                    onChange={(e, newValue: any) => {
                                        if(newValue && newValue?.value){                                       
                                            setMaxBucket(newValue?.value);
                                       }                                        
                                    }}
                                />
                            </div>
                        </Grid>
                        <Grid>
                            <div className="bucket-outer">
                                <div className="bucket-text">Bins</div>
                                <div className="bucket-padding">
                                    <FormControl variant="standard" >
                                        <Select
                                            labelId="demo-simple-select-standard-label"
                                            id="demo-simple-select-standard"
                                            value={bucketValue ? bucketValue : 5}
                                            onChange={handleBucket}
                                            sx={{ width: "150px" }}
                                        >
                                            <MenuItem value={5}>5</MenuItem>
                                            <MenuItem value={10}>10</MenuItem>
                                            <MenuItem value={15}>15</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>
                        </Grid>
                        <Grid>
                            <div className="bucket-outer">
                                <div className="bucket-text">Reset</div>
                                <FormControlLabel
                                    value="Reset"
                                    control={<IconButton sx={{ marginRight: "20px" }} onClick={handleResetValue}><RestartAltIcon /></IconButton>}
                                    label=""
                                    labelPlacement="start"
                                />
                            </div>
                        </Grid>

                    </Grid>
                    <Button
                        sx={{ float: "right" }}
                        className="audit-filter-apply"
                        onClick={handleHistogramClose}
                        variant="contained">
                        OK
                    </Button>
                </DialogContent>

            </Dialog>
            <HighchartsReact
                immutable={true}
                highcharts={Highcharts}
                options={options}
                ref={chartRef}
            />
          
                <Grid className="time-series-legends-new time-series-legends-new-sites">
                    {data && data.map((usage, i) => (
                        <Grid className="summary-pie-bar-legends summary-pie-bar-legends-sites">
                            <div className="summary-pie-usage-dot" style={{ background: `${colors[i]}` }}></div>
                            <span className={data[i]?.visible ? "barChart-legends" : 'disable-barChart-legends'} id='site-legend-filter' onClick={() => {isHistogram && bucketValue > 2 ? handleLegend(usage.name) : handleSiteClick({ x: usage.name, k4Id: usage.k4Id })}}>{usage.name}</span>
                        </Grid>
                    ))}
                </Grid>
                
        </Grid>
    )
}

const mapStateToProps = (state) => ({
    startDate: state?.authReducer?.newSummaryStartDate,
    endDate: state?.authReducer?.newSummaryEndDate
});

export default withRouter(
  connect(mapStateToProps, { })(SiteLineChart)
);